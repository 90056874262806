import { Route } from "react-router-dom";
import PendoBadge from "./pendo/pendoBadge";

interface ConsentFormRouteProps {
	component: React.ReactNode;
	path: string;
	exact?: boolean;
}

const ConsentFormRoute: React.FC<ConsentFormRouteProps> = ({
	component: Component,
	...rest
}: any) => {
	return (
		<Route
			{...rest}
			render={(props) => {
                return (
                    <>
                        <Component {...props} />
                        <PendoBadge absolute />
                    </>
                );
            }}
		/>
	);
};

export default ConsentFormRoute;
