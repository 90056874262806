import { message } from "antd";


export const closedErrorMsg = (closed: boolean | undefined) => {
    if (closed) {
        message.error("This chart is closed");
    }
}

export const openErrorMsg = (open: boolean | undefined) => {
    if (open) {
        message.error("Chart is still open");
    }
}
