import { createSlice } from '@reduxjs/toolkit';

interface VisitHeaderState {
    chatOpen: boolean;
    showInfoModalAfterClose: boolean;
    paymentModalOpen: boolean
}

const initialState: VisitHeaderState = {
    chatOpen: false,
    showInfoModalAfterClose: false,
    paymentModalOpen: false,
};

export const visitHeaderSlice = createSlice({
    name: 'visitHeader',
    initialState,
    reducers: {
        setChatOpen(state, action) {
            state.chatOpen = action.payload;
        },
        setShowInfoModalAfterClose(state, action) {
            state.showInfoModalAfterClose = action.payload;
        },
        setPaymentModalOpen(state, action) {
            state.paymentModalOpen = action.payload;
        },

    },
});

export const { setChatOpen, setShowInfoModalAfterClose, setPaymentModalOpen } =
    visitHeaderSlice.actions;
