import { ExclamationCircleOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, FormInstance, Modal, Row, Tag, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { setShowInfoModalAfterClose } from 'store/slices/visitHeaderSlice';
import { COOKIE_DURATION, VISIT_TABS_LINKS } from 'utils/constants';
import { ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { VisitStatusTransitionWidget } from './VisitStatusTransitionWidget';

interface VisitSectionProps {
	currentVisit: PatientRecordVisit;
	modalForm: FormInstance<any>;
	setModalstate: Function;
	patient: ExpandedPatient;
}

export const VisitSection = ({
	currentVisit,
	modalForm,
	setModalstate,
	patient,
}: VisitSectionProps) => {
	const [overrideTransitionMap, setOverrideTransitionMap] = useState(false);
	const [doNotShowChecked, setDoNotShowChecked] = useState(false);
	const history = useHistory();
	const dispatch = useAppDispatch();
	const showInfoModalAfterClose = useAppSelector(
		(state) => state.visitHeaderSlice.showInfoModalAfterClose,
	);

	const goToDocuments = () => {
		dispatch(setShowInfoModalAfterClose(false));
		if (doNotShowChecked) {
			document.cookie = `do_not_show_accessing_medical_chart_modal=1;max-age=${COOKIE_DURATION}`;
		}
		history.push(`${history.location.pathname}?section=${VISIT_TABS_LINKS.documents}`);
	};

	return (
		<>
			{currentVisit?.id && (
				<Row
					className='sidebar-visit-status'
					align='middle'
				>
					<Col span={12} style={{textAlign: "right", paddingRight: "16px"}}>
						Status
					</Col>
					<Col span={10}>
						{!currentVisit.finalized_at ? (
							<VisitStatusTransitionWidget
								visit_id={currentVisit.id}
								visit_status={currentVisit.status}
								hospitalization_level={currentVisit.hospitalization_level}
								overrideTransitionMap={overrideTransitionMap}
								modalForm={modalForm}
								patient={patient}
								setModalState={setModalstate}
							/>
						) : (
							<Tag
								color='error'
								style={{fontSize: "14px"}}
								data-cy="chartClosedTag"
							>
								Chart Closed
							</Tag>
						)}
					</Col>
					<Col
						span={2}
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						{!currentVisit.finalized_at && (
							<IssuesCloseOutlined
								style={overrideTransitionMap ? { color: 'red' } : { color: '#a6a6a6' }}
								onClick={() => {
									setOverrideTransitionMap(!overrideTransitionMap);
								}}
								data-cy='overrideTransitionButton'
							/>
						)}
					</Col>
				</Row>
			)}
			<Modal
				width='450px'
				open={showInfoModalAfterClose}
				maskClosable={false}
				onCancel={() => {
					dispatch(setShowInfoModalAfterClose(false));
				}}
				destroyOnClose={true}
				centered={true}
				footer={(
					<>
						<Button onClick={goToDocuments}>
							Go to Documents
						</Button>
						<Button autoFocus type="primary" onClick={() => {
							if (doNotShowChecked) {
								document.cookie = `do_not_show_accessing_medical_chart_modal=1;max-age=${COOKIE_DURATION}`;
							}
							dispatch(setShowInfoModalAfterClose(false));
						}}>
							Ok
						</Button>
					</>
				)}
			>
				<Row>
					<Col span={1}>
						<ExclamationCircleOutlined
							style={{
								"fontSize": "32px",
								"color": "var(--antd-blue)",
							}}
						/>
					</Col>
					<Col offset={2} span={20}>
						<Typography.Title level={4}>Accessing Medical Chart</Typography.Title>
						<Typography.Paragraph>
							The medical chart can be found in the Documents tab.
						</Typography.Paragraph>
					</Col>
				</Row>
				<Row>
					<Col offset={3} span={20}>
						<Checkbox
							checked={doNotShowChecked}
							onChange={(e) => {
								setDoNotShowChecked(e.target.checked);
							}}
						>
							Do not show me this again
						</Checkbox>
					</Col>
				</Row>
			</Modal>
		</>
	);
};
