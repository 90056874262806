import { Row, message } from 'antd';
import PhysicalExam from 'components/forms/PhysicalExam';
import ContactInfoPopover from 'components/popups/ContactInfoPopover';
import PatientInfoPopover from 'components/popups/PatientInfoPopover';
import RdvmInfoPopover from 'components/popups/RdvmInfoPopover';
import { ReasonForVisitPopover } from 'components/popups/ReasonForVisitPopover';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetEstimatesByVisitIdNewQuery } from 'services/estimateService';
import { useGetPatientAlertsByIdQuery, useGetPatientByIdQuery } from 'services/patientService';
import { useSearchRdvmQuery } from 'services/rdvmService';
import { useGetBreedsQuery, useGetSpeciesQuery } from 'services/specieService';
import {
	useGetDischargeNoteByVisitIdQuery,
	useGetPatientHistoryByVisitIdQuery,
	useValidateEditExamMutation,
	useValidateEditPatientHistoryMutation,
} from 'services/visitService';
import {
	BASE_QUERY_OPTIONS,
	FormName,
	MY_VET_IS_NOT_ON_THE_LIST,
	PATIENT_WEIGHT_INSTRUCTION_NAME,
	VISIT_TABS_LINKS,
} from 'utils/constants';
import {
	Exam,
	PatientRecordVisit,
	ProblemList,
	VisitVital,
} from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { checkIfVitalsAreCompleted, checkPatientInfo, getAgeYearsMonths } from 'utils/miscFuncs';
import { getPendingEstimateItems } from '../EstimatesNew/utils';
import PatientHistoryNote from '../PatientHistoryNote';
import { TocCardItem } from './TocCardItem';
import Spike3Popover from 'components/popups/Spike3Popover';
import LevelPopover from 'components/popups/LevelPopover';
import { NotesDrawerRef } from 'components/NotesDrawer';

interface TocCardProps {
	currentVisit: PatientRecordVisit;
	onComplaintSelect: (complaint_ids: number[]) => void;
	problemList: ProblemList[];
	exams: Exam[];
	vitalInstructions: VisitVital[] | undefined;
	visitOnFinish: (
		values: any,
		formName: FormName,
		additionalState?: {},
	) => void;
	openTransferEstimateDrawerFunction: any;
	openNoteDrawer?: NotesDrawerRef['openNotesDrawer'];
    openEditNotesDrawer?: NotesDrawerRef['openEditNotesDrawer'];
}

const contactInfoCheck = (visit: PatientRecordVisit): boolean =>
	visit.customer_id !== null;
const checkPresentingComplaint = (problemList: ProblemList[]): boolean =>
	problemList.some((problem) => problem.presenting_complaint);
const checkPatientHistory = (PatientHistoryContent: string): boolean =>
	PatientHistoryContent !== '';
const dischargeNoteContent = (dischargeNoteContent: string): boolean =>
	dischargeNoteContent !== '';
const checkPhysicalExam = (exams: Exam[]): boolean => exams.length !== 0;

export const TocCard = React.memo(({
	currentVisit,
	onComplaintSelect,
	problemList,
	vitalInstructions,
	visitOnFinish,
	exams,
	openTransferEstimateDrawerFunction,
	openEditNotesDrawer,
	openNoteDrawer
}: TocCardProps) => {
	const history = useHistory();

	const { addComposeBox } = useComposeBoxContext();

	const { data: allRdvms } = useSearchRdvmQuery(currentVisit?.id, BASE_QUERY_OPTIONS);

	const rdvmInfo = allRdvms?.find((rDvm) => rDvm.clinic_id === currentVisit?.rdvm_id);

	const rdvmMissingRequiredCustomData = () => {
        const rDVM = allRdvms?.find((item) => item.clinic_id == currentVisit.rdvm_id);

		if (rDVM?.display_name === MY_VET_IS_NOT_ON_THE_LIST) {
			return !(rDVM?.override_display_name && rDVM?.override_email);
		} else {
			return false;
		}
    };

	const { data: PatientHistoryContent } = useGetPatientHistoryByVisitIdQuery(
		currentVisit.id,
		BASE_QUERY_OPTIONS,
	);
	const { data: dischargeNote } = useGetDischargeNoteByVisitIdQuery(
		currentVisit.id,
		BASE_QUERY_OPTIONS,
	);
	const { data: breeds } = useGetBreedsQuery(null);
	const { data: species } = useGetSpeciesQuery(null);

	const { data: patientData } = useGetPatientByIdQuery(
		currentVisit?.pet_id as string,
		{
			...BASE_QUERY_OPTIONS,
			skip: !currentVisit?.pet_id,
		},
	);
	const { data: patientAlerts } = useGetPatientAlertsByIdQuery(
		currentVisit?.pet_id as string,
		{ ...BASE_QUERY_OPTIONS, skip: !currentVisit?.pet_id },
	);
	const [validateEditPatientHistory] = useValidateEditPatientHistoryMutation();
	const [validateEditExam] = useValidateEditExamMutation();

	const patientWeight = vitalInstructions?.find(
		(vital) => vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME,
	)?.lastAction?.value as number;

	const { data: customerData } = useGetCustomerByIdQuery(
		currentVisit.customer_id as string,
		{
			...BASE_QUERY_OPTIONS,
			skip: !currentVisit.customer_id,
		},
	);
	const isFinalizedVisit = Boolean(currentVisit.finalized_at);

	const { urlVisitId: _urlVisitId } = useParams<{ urlVisitId: string }>();
	const visitId = parseInt(_urlVisitId);
	const { data: estimates } = useGetEstimatesByVisitIdNewQuery(
		{visitId},
		BASE_QUERY_OPTIONS,
	);

	const checkApprovedQuantity = (estimates ?? []).some((estimate) => estimate.estimate_status === 'approved');
	const checkStartTimes = getPendingEstimateItems(estimates ?? []).length === 0;

	const {ageYears, ageMonths} = getAgeYearsMonths(patientData);

	const handleOpenPhysicalExam = (intakeExam?: Exam) => {
        if (intakeExam && openEditNotesDrawer) {
            openEditNotesDrawer({ formType: FormName.physical_exam, examId: +intakeExam.id });
        } else if (openNoteDrawer) {
            openNoteDrawer(FormName.physical_exam);
        }
    };

	return (
		<Row gutter={[12, 12]}>
			<TocCardItem
				clickFunction={() => closedErrorMsg(false)}
				checked={!!currentVisit.spiked_by}
				title='Spike 3'
			>
				<Spike3Popover currentVisit={currentVisit} />
			</TocCardItem>
			<TocCardItem
				clickFunction={() => closedErrorMsg(false)}
				checked={!!currentVisit.triage_level}
				title='Level'
			>
				<LevelPopover currentVisit={currentVisit} />
			</TocCardItem>
			<TocCardItem
				clickFunction={() => closedErrorMsg(false)}
				checked={contactInfoCheck(currentVisit)}
				title={'Contact Info'}
				dataCy="contactInfoChecklistItem"
			>
				<ContactInfoPopover
					currentVisit={currentVisit}
					customerData={customerData}
					visitOnFinish={visitOnFinish}
				/>
			</TocCardItem>
			<TocCardItem
				clickFunction={() => closedErrorMsg(isFinalizedVisit)}
				checked={checkPatientInfo(patientData, species, breeds)}
				title={'Patient info'}
				dataCy="patientInfoChecklistItem"
			>
				<PatientInfoPopover
					initialContent={{
						pet_id: patientData?.pet_id,
						name: patientData?.name,
						species: patientData?.species,
						breed: patientData?.breed,
						sex: patientData?.sex,
						is_intact: patientData?.is_intact,
						birthday: patientData?.birthday
							? moment(patientData?.birthday)
							: undefined,
						ageYears: ageYears,
						ageMonths: ageMonths,
						latest_weight: patientWeight || undefined,
						is_deceased: !!patientData?.deceased_at,
						deceased_at: patientData?.deceased_at
							? moment.unix(patientData?.deceased_at)
							: undefined,
						patientAlerts: patientAlerts,
						note: patientData?.note,
					}}
					disabled={isFinalizedVisit}
				/>
			</TocCardItem>
			<TocCardItem
				clickFunction={() => closedErrorMsg(false)}
				checked={!!currentVisit.rdvm_id && !rdvmMissingRequiredCustomData()}
				title={'rDVM info'}
				dataCy="rdvmInfoChecklistItem"
			>
				<RdvmInfoPopover
					currentVisit={currentVisit}
					currentRdvm={rdvmInfo && `${rdvmInfo?.display_name} ${rdvmInfo?.is_active ? '' : '(inactive)'}`}
				>
					{FormName['rdvm_info']}
				</RdvmInfoPopover>
			</TocCardItem>
			<TocCardItem
				clickFunction={() => closedErrorMsg(isFinalizedVisit)}
				checked={!!currentVisit.reason_for_visit}
				title={'Reason for Visit'}
				dataCy='reasonForVisitChecklistItem'
				data-pendo='checklistReason'
			>
				<ReasonForVisitPopover
					currentVisit={currentVisit}
					disabled={isFinalizedVisit}
				>
					Reason for Visit
				</ReasonForVisitPopover>
			</TocCardItem>
			<TocCardItem
				clickFunction={
					isFinalizedVisit
						? () => closedErrorMsg(isFinalizedVisit)
						: () => onComplaintSelect([])
				}
				checked={checkPresentingComplaint(problemList)}
				title={'Presenting Complaint'}
				dataCy="presentingComplaintChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => {
					history.push(
						`${history.location.pathname}?section=${VISIT_TABS_LINKS.treatmentSheet}`,
					);
				}}
				checked={checkIfVitalsAreCompleted(vitalInstructions) ?? false}
				title={'Record Initial Vitals'}
				dataCy="recordInitialVitalsChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => {
					history.push(
						`${history.location.pathname}?section=${VISIT_TABS_LINKS.treatmentSheet}`,
					);
				}}
				checked={!!patientWeight}
				title={'Weight'}
				dataCy="weightChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => {
					if (isFinalizedVisit) {
						closedErrorMsg(isFinalizedVisit);
					} else {
						validateEditPatientHistory({ visitId })
                            .unwrap()
                            .then(() => {
                                if (openEditNotesDrawer) {
                                    openEditNotesDrawer({
                                        formType: FormName['patient_history'],
                                        editPatientHistory: PatientHistoryContent,
                                    });
                                }
                            })
                            .catch((err) => {
                                if (err.originalStatus === 403) {
                                    message.error(err.data);
                                }
                            });
					}
				}}
				checked={checkPatientHistory(
					PatientHistoryContent?.content ?? '',
				)}
				title={'Patient History'}
				dataCy="patientHistoryChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => {
					if (isFinalizedVisit) {
						closedErrorMsg(isFinalizedVisit);
					} else {
						const intakeExam = exams.length
							? exams.reduce((prev, curr) =>
								curr.created_at < prev.created_at ? curr : prev,
							)
							: undefined;

						if (!intakeExam) {
							handleOpenPhysicalExam();
						} else {
							validateEditExam({ examId: +intakeExam.id })
							.unwrap()
							.then(() => {
								handleOpenPhysicalExam(intakeExam);
							})
							.catch((err) => {
								if (err.originalStatus === 403) {
									message.error(err.data);
								}
							});
						}
					}
				}}
				checked={checkPhysicalExam(exams)}
				title={'Intake Physical Exam'}
				dataCy="physicalExamChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => {
                    history.push(
						`${history.location.pathname}?section=${VISIT_TABS_LINKS.estimates}`,
					);
                }
                }
				checked={!!estimates?.length}
				title={'Initial Estimate Created'}
				dataCy="initialEstimateCreatedChecklistItem"
			>
			</TocCardItem>
			<TocCardItem
				clickFunction={() => {
					history.push(
						`${history.location.pathname}?section=${VISIT_TABS_LINKS.estimates}`,
					);
				}
				}
				checked={checkApprovedQuantity}
				title={FormName['initial_estimate_approved']}
				dataCy="initialEstimateApprovedChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => openTransferEstimateDrawerFunction(true)}
				checked={checkStartTimes}
				title={FormName['pending_orders_placed']}
				dataCy="pendingOrdersPlacedChecklistItem"
			/>
			<TocCardItem
				clickFunction={() => {
					history.push(
						`${history.location.pathname}?section=${VISIT_TABS_LINKS.discharge}`,
					);
				}
				}
				checked={dischargeNoteContent(dischargeNote?.content ?? '')}
				title={'Discharge Note'}
				dataCy="dischargeNoteChecklistItem"
			/>
		</Row>
	);
});
