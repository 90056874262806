import { Radio } from 'antd';

export type EstimateInvoiceRadioValues = 'estimate' | 'invoice';

interface EstimateInvoiceRadioProps {
    radioValue: EstimateInvoiceRadioValues;
    setRadioValue: Function;
}

export const EstimateInvoiceRadio = ({ radioValue, setRadioValue }: EstimateInvoiceRadioProps) => {
    const radioGroupStyle = {
        width: '100%',
        display: 'flex',
    };
    const radioButtonStyle = {
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
    };
    return (
        <Radio.Group
            buttonStyle='solid'
            style={radioGroupStyle}
            value={radioValue}
            onChange={(e) => {
                setRadioValue(e.target.value);
            }}
        >
            <Radio.Button value='estimate' style={radioButtonStyle}>
                Estimate
            </Radio.Button>
            <Radio.Button value='invoice' style={radioButtonStyle}>
                Invoice
            </Radio.Button>
        </Radio.Group>
    );
};
