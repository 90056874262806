import {
    DeleteOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import { ChangeEvent, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    useDeletePatientFileMutation,
    useGetPatientFilesQuery,
    useLazyGetFileLinkQuery,
    useUploadPatientFileMutation,
} from 'services/filesService';
import { useGetPatientByIdQuery } from 'services/patientService';
import './Documents.css';

const Documents = () => {
    let { pet_id } = useParams<{ pet_id: string }>();
    const inputRef = useRef<HTMLInputElement>(null);

    const { data: patientFiles } = useGetPatientFilesQuery({ pet_id });
    const [getFileLink, { data: fileLink }] = useLazyGetFileLinkQuery();
    const { data: patientData } = useGetPatientByIdQuery(pet_id);

    const [deletePatientFile] = useDeletePatientFileMutation();
    const [uploadPatientFile, { isLoading }] = useUploadPatientFileMutation();

    useEffect(() => {
        if (!!fileLink) {
            window.open(fileLink);
        }
    }, [fileLink]);

    const getFileName = (s3_path: string) => {
        const splitPath = s3_path.split('/');

        return splitPath[splitPath.length - 1];
    };

    const getFileType = (s3_path: string) => {
        const fileName = getFileName(s3_path);

        return fileName.split('.')[1].toUpperCase();
    };

    const showDeleteConfirm = (file_id: number) => {
        confirm({
            title: 'Delete document from patient record?',
            icon: <ExclamationCircleOutlined style={{ color: '#E7202E' }} />,
            content: (
                <>
                    <p>
                        Deleting this document will remove it from ALL visits.
                    </p>

                    <p>
                        Are you sure you want to delete this document from{' '}
                        {patientData?.name}’s record?
                    </p>
                </>
            ),
            okText: 'Yes, Delete',
            okType: 'danger',
            okButtonProps: {
                type: 'primary',
                style: { backgroundColor: '#E7202E' },
            },
            cancelText: 'Cancel',
            onOk() {
                deletePatientFile({ pet_id, file_id });
            },
        });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length && e.target.files?.length > 0) {
            const file = e.target.files[0];

            uploadPatientFile({ file_name: file.name, file_obj: file, pet_id });
        }
    };

    return (
        <section className='patient-documents-tab'>
            <h1>Documents</h1>

            <div className='add-patient-document-button-container'>
                <Button
                    onClick={() => inputRef.current?.click()}
                    loading={isLoading}
                    disabled={isLoading}
                >
                    <PlusOutlined /> Add Document
                </Button>

                <input
                    type='file'
                    max={1}
                    ref={inputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    accept='.pdf, .png, .jpg, .doc, .docx'
                />
            </div>

            <div>
                <Table
                    dataSource={patientFiles ?? []}
                    pagination={false}
                    rowKey='id'
                >
                    <Table.Column
                        title='Name'
                        dataIndex='s3_path'
                        key='file_name'
                        render={(s3_path: string) => getFileName(s3_path)}
                    />

                    <Table.Column
                        title='Type'
                        dataIndex='s3_path'
                        key='file_type'
                        render={(s3_path: string) => getFileType(s3_path)}
                    />

                    <Table.Column
                        title='Date'
                        dataIndex='created_at'
                        key='file_created_at'
                        render={(created_at: number) =>
                            moment.unix(created_at).format('L h:mma')
                        }
                    />

                    <Table.Column
                        title='Added By'
                        dataIndex='created_by'
                        key='file_created_by'
                        render={(created_by: string) => created_by}
                    />

                    <Table.Column
                        dataIndex='s3_file_id'
                        key='file_functions'
                        render={(s3_file_id: number) => {
                            return (
                                <>
                                    <Tooltip title='Download'>
                                        <Button
                                            className='file-function-button'
                                            onClick={() =>
                                                getFileLink({
                                                    pet_id,
                                                    file_id: s3_file_id,
                                                })
                                            }
                                            icon={<DownloadOutlined />}
                                        />
                                    </Tooltip>
                                    <Tooltip title='Delete'>
                                        <Button
                                            className='file-function-button'
                                            onClick={() => {
                                                showDeleteConfirm(s3_file_id);
                                            }}
                                            icon={<DeleteOutlined />}
                                        />
                                    </Tooltip>
                                </>
                            );
                        }}
                    />
                </Table>
            </div>
        </section>
    );
};

export default Documents;
