import React from 'react';
import { getAllInitials, stringToHslColor } from 'utils/formatFuncs';
import './DoctorNurseTag.css';

interface DoctorNurseTagProps {
    doctorName?: string | null;
    nurseName?: string | null;
}

const DoctorNurseTag: React.FC<DoctorNurseTagProps> = ({ doctorName, nurseName }) => {
    if (doctorName) {
        const doctorInitials = getAllInitials(doctorName);

        return (
            <div
                className='whiteboard-doctor-nurse-tag whiteboard-doctor-tag'
                style={{
                    backgroundColor: stringToHslColor(doctorInitials ?? '', 60, 60),
                }}
            >
                {doctorInitials}
            </div>
        );
    }

    if (nurseName) {
        const nurseInitials = getAllInitials(nurseName);

        return (
            <div
                className='whiteboard-doctor-nurse-tag whiteboard-nurse-tag'
                style={{
                    backgroundColor: stringToHslColor(nurseInitials ?? '', 60, 60),
                }}
            >
                {nurseInitials}
            </div>
        );
    }

    return null;
};

export default DoctorNurseTag;
