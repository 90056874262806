import { Button } from 'antd';
import React from 'react';
import { DiagnosticSearchOptionWithRefLabs } from 'utils/types/InstructionOrderTypes';

interface ReferenceLabItemProps {
    item: DiagnosticSearchOptionWithRefLabs;
    fromEstimate: boolean;
    handleClick: (item: DiagnosticSearchOptionWithRefLabs) => void;
}

const ReferenceLabItem: React.FC<ReferenceLabItemProps> = ({ item, fromEstimate, handleClick }) => {
    return (
        <div className='idexx-reference-lab-item'>
            <div className='idexx-reference-lab-item__title'>{item.name}</div>
            <div>
                <strong>Sample: </strong>
                <span>{item.specimen}</span>
            </div>
            <div className='idexx-reference-lab-item__bottom-container'>
                <span>
                    <strong>Turnaround: </strong>
                    <span>{item.turnaround}</span>
                </span>
                <Button className='idexx-reference-lab-item__order-button' type='primary' onClick={() => handleClick(item)}>
                    {fromEstimate ? 'Add to Estimate' : 'Order'}
                </Button>
            </div>
        </div>
    );
};

export default ReferenceLabItem;
