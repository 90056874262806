import { Popover } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetMyMacrosQuery } from 'services/macroService';
import { Macro } from 'utils/dataTypes';
import { Keys } from 'utils/types/enums';

interface NoteMacrosProps extends React.HTMLProps<HTMLTextAreaElement> {
    initialNoteValue?: string;
    handleChange: (values: any) => void | (() => void);
}

const NoteMacrosTextarea: React.FC<NoteMacrosProps> = ({ initialNoteValue, handleChange, ...textAreaProps }) => {
    const CLEAR_MACRO_LIST = [Keys.ENTER, Keys.SPACE, Keys.ARROW_RIGHT, Keys.ARROW_LEFT, Keys.META].map((key) => key.toString());

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [macroStartIndex, setMacroStartIndex] = useState(-1);
    const [macroEndIndex, setMacroEndIndex] = useState(-1);
    const [highlightedMacroIndex, setHighlightedMacroIndex] = useState(-1);
    const [highlightedMacro, setHighlightedMacro] = useState<Macro | void>();
    const [localValue, setLocalValue] = useState(initialNoteValue ?? '');

    const { data: macros, isSuccess: macroFetchSuccess } = useGetMyMacrosQuery();

    const focusAndScrollToEndOfTextArea = () => {
        if (textareaRef.current !== null) {
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    };

    const macroSearchQuery = useMemo<string | null>(() => {
        if (macroEndIndex > -1) {
            return textareaRef.current?.value.substring(macroStartIndex, macroEndIndex).replace('.', '') ?? null;
        } else {
            return null;
        }
    }, [macroStartIndex, macroEndIndex]);

    const filteredMacros = useMemo(() => {
        if (macroFetchSuccess && macroSearchQuery !== null && macroSearchQuery.length) {
            let loweredMacro = String(macroSearchQuery).toLowerCase();

            // Return all macros
            if (loweredMacro === '*') {
                return macros ?? [];
            }

            return macros?.filter((macro) => macro.key_word.toLowerCase().includes(loweredMacro)) ?? [];
        } else {
            return [];
        }
    }, [macroSearchQuery, macroFetchSuccess]);

    const handleHighlightStep = (down: boolean) => {
        if (filteredMacros?.length) {
            let newIndex = 0;
            // if item highlighted
            if (highlightedMacroIndex > -1) {
                // Both these conditions go to start/end when out of index.
                if (down) {
                    newIndex = highlightedMacroIndex < filteredMacros.length - 1 ? highlightedMacroIndex + 1 : 0;
                } else {
                    newIndex = highlightedMacroIndex === 0 ? filteredMacros.length - 1 : highlightedMacroIndex - 1;
                }
            }
            setHighlightedMacroIndex(newIndex);
        }
    };

    const clearMacro = () => {
        setMacroStartIndex(-1);
        setMacroEndIndex(-1);
        setHighlightedMacroIndex(-1);
        setHighlightedMacro();
    };

    const handleSelectMacro = (macro: Macro) => {
        let currentNote = textareaRef.current?.value ?? '';
        // concat the macro
        let adaptedNote = currentNote.substring(0, macroStartIndex - 1) + macro.content + currentNote.substring(macroEndIndex);
        setLocalValue(adaptedNote);
        clearMacro();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        let { key } = e;
        // Prevent moving of cursor when highlighting
        if ((key === Keys.ARROW_DOWN && macroEndIndex > -1) || (key === Keys.ARROW_UP && macroEndIndex > -1)) {
            e.preventDefault();
            handleHighlightStep(key === Keys.ARROW_DOWN);
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent) => {
        let { key } = e;
        let currentTarget = e.target as HTMLTextAreaElement;
        let cursorIndex = Number(textareaRef.current?.selectionStart);
        // Start the macro lookup process
        if (key === Keys.BACKSLASH) {
            setMacroStartIndex(cursorIndex);
        } else if (macroStartIndex > -1) {
            switch (true) {
                case key === Keys.BACKSPACE:
                    // If dot removed then clear
                    if (macroSearchQuery?.length === 0) {
                        clearMacro();
                    } else {
                        setMacroEndIndex(cursorIndex);
                    }
                    break;

                case key === Keys.ENTER && highlightedMacro !== null && !!macroEndIndex:
                    handleSelectMacro(highlightedMacro as Macro);
                    break;
                // If everything removed or a key that removes the lookup
                case !currentTarget.value.length || CLEAR_MACRO_LIST.includes(key):
                    clearMacro();
                    break;
                default:
                    setMacroEndIndex(cursorIndex);
            }
        }
    };

    const handleBlur = () => {
        // Using a set timeout here, so that
        // We can click on macro items before
        // it's cleared
        setTimeout(() => {
            clearMacro();
        }, 1000);
    };

    useEffect(() => {
        setHighlightedMacro(filteredMacros?.[highlightedMacroIndex]);
    }, [highlightedMacroIndex, filteredMacros?.length]);

    useEffect(() => {
        if (!!textareaRef.current) {
            focusAndScrollToEndOfTextArea();
        }
    }, [textareaRef.current]);

    useEffect(() => {
        handleChange(localValue);
    }, [localValue]);

    return (
        <Popover
            placement='leftTop'
            zIndex={99999}
            title={`Macro: ${macroSearchQuery ?? ''}..`}
            content={
                <ul>
                    {!filteredMacros?.length ? <li className='no-items'>No macros matching that query.</li> : null}
                    {filteredMacros?.map((macro, i) => (
                        <li
                            key={macro.is_veg ? macro.id : `${macro.id}_${macro.created_by}`}
                            onClick={() => {
                                handleSelectMacro(macro);
                            }}
                            className={`macro${highlightedMacroIndex === i ? ' selected' : ''}`}
                        >
                            <b>{macro.key_word}</b> - {macro.content}
                        </li>
                    ))}
                </ul>
            }
            trigger='click'
            visible={macroEndIndex > -1}
            overlayClassName='macro-popover'
        >
            <textarea
                ref={textareaRef}
                value={localValue}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onBlur={handleBlur}
                onChange={(e) => {
                    setLocalValue(e.target.value);
                }}
                {...textAreaProps}
            />
        </Popover>
    );
};

export default NoteMacrosTextarea;
