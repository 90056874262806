import { Button, Col, Row, Spin, Table } from 'antd';
import React, { CSSProperties, Key, MouseEventHandler, useState } from 'react';
import { useSearchCustomerByPhoneQuery } from '../../services/admissionService';
import { useAddCustomerToPetMutation } from '../../services/patientService';
import { useGetVisitByIdQuery, useModifyVisitMutation } from '../../services/visitService';
import { BASE_QUERY_OPTIONS } from '../../utils/constants';
import { CustomerInfo } from '../../utils/dataTypes';
import { PhoneNumberField } from './fields/PhoneNumberField';

const formItemStyle: CSSProperties = {
	width: '100%',
};

interface SelectOwnerProps {
	onCreateNewClick: ((n: CustomerInfo) => void);
	pet_id: string;
	visit_id: number;
	setPopoverState?: Function;
}

export const SelectOwner = (props: SelectOwnerProps) => {
	const [isFormDirty, setIsFormDirty] = useState(false); //Used to determine what message to show under customer phone number
	const [phoneInputValue, setPhoneInputValue] = useState('');
	const [selectedCustomer, setSelectedCustomer] = useState(
		{} as CustomerInfo,
	);
	const onCustomerSelect = (customer: any) => {
		setSelectedCustomer(customer);
	};

	const [modifyVisit] = useModifyVisitMutation();
	const [addCustomerToPet] = useAddCustomerToPetMutation();
	const { data: currentVisit } = useGetVisitByIdQuery(
		props.visit_id,
		BASE_QUERY_OPTIONS,
	);

	const { data: allCustomers, isLoading: isLoadingCustomers } =
		useSearchCustomerByPhoneQuery(phoneInputValue, {
			...BASE_QUERY_OPTIONS,
			skip: phoneInputValue.length !== 10,
		});

	const handleChange = (phoneNumber: string | null) => {
		if (phoneNumber && phoneNumber.length === 10) {
			setIsFormDirty(true);
		}
		if (phoneNumber) {
			setPhoneInputValue(phoneNumber);
		}
	};
	const partialCustomerInfo = (incomingPhoneNumber: string): CustomerInfo => ({
			customer_id: '',	
			phone_number: incomingPhoneNumber,
			first_name: '',
			last_name: null,
			email: null,
			street: null,
			city: null,
			state: null,
			zipcode: null,
			note: null,
			visit_count: 1,
			how_did_you_hear_about_us: null,
			additional_info: null,
			birthday: null,
			unable_to_collect_birthday: null,
			email_opt_out: null
		})
	return (
		<>
			<Row gutter={[12, 12]} style={{ width: '100%' }}>
				<Col span={24}>
					<h4>Search for existing owner, or click 'Create New'</h4>
				</Col>
				<Col span={24}>
				<PhoneNumberField
                        autoFocus={true}
						formItemProps={{
							preserve: false,
							name: 'phone_number',
							label: 'Phone number',
							style: formItemStyle,
							labelCol: { span: 6 },
							wrapperCol: { span: 12 }
						}}
						onChange={(value: string | null) => {
							handleChange(value)
						}}
					/>

				</Col>
				<Col span={24}>
					{!isLoadingCustomers && allCustomers?.length === 0 ? (
						isFormDirty ? (
							<h4>
								<u>No matching owners</u>
							</h4>
						) : (
							<></>
						)
					) : (
						isLoadingCustomers && <Spin />
					)}
					{!isLoadingCustomers &&
						allCustomers &&
						allCustomers.length > 0 && (
							<MatchingCustomersTable
								customers={allCustomers}
								onCustomerSelect={onCustomerSelect}
							/>
						)}
				</Col>

				<Col span={6} offset={12}>
					<Button onClick={() => props.onCreateNewClick(partialCustomerInfo(phoneInputValue)) } data-cy="createNewOwner">Create new</Button>
				</Col>
				<Col span={6}>
					<Button
						type='primary'
						data-cy="searchOwner"
						disabled={!selectedCustomer.customer_id}
						onClick={() => {
							//TODO conditionally add this relationship
							addCustomerToPet({
								customerId:
									selectedCustomer.customer_id as string,
								pet_id: props.pet_id,
							});

							//Attach customer to this visit
							modifyVisit({
								visitId: props.visit_id,
								body: {
									owner_id: selectedCustomer.customer_id,
									rdvm_id: currentVisit?.rdvm_id || null,
									discharge_summary_location: null,
									recheck_time: currentVisit?.recheck_time || null,
									override_display_name: null,
									override_email: null,
								},
							});

							if (props.setPopoverState) {
								props.setPopoverState(false);
							}
						}}
					>
						Submit
					</Button>
				</Col>
			</Row>
		</>
	);
};

interface MatchingcustomersTableProps {
	customers: any[];
	onCustomerSelect: Function;
}
export const MatchingCustomersTable = (props: MatchingcustomersTableProps) => {
	const [selectedRow, setSelectedRow] = useState('');

	// rowSelection object indicates the need for row selection
	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
			setSelectedRow(selectedRowKeys[0] as string);
			props.onCustomerSelect(selectedRows[0]);
		},
	};
	const tableData = props.customers.map((customer) => ({
		...customer,
		name: customer.first_name + ' ' + customer.last_name,
	}));
	return (
		<Table
			rowSelection={{
				type: 'radio',
				selectedRowKeys: [selectedRow] as Key[],
				...rowSelection,
			}}
			rowKey='customer_id'
			pagination={false}
			size='small'
			dataSource={tableData}
			columns={[
				{ title: 'Name', dataIndex: 'name', key: 'name' },
				{
					title: 'Phone number',
					dataIndex: 'phone_number',
					key: 'phone_number',
				},
			]}
		/>
	);
};
