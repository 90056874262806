import { Button, Radio, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetMedicationConsentFormQuery } from 'services/consentService';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { Header } from '../Header';
import { CheckCircleOutlined } from '@ant-design/icons';

const ApprovedMedicationConsentForm = () => {
    const { urlFormId } = useParams<any>();
    const { data: medicationConsentData } = useGetMedicationConsentFormQuery(urlFormId);
    if (!medicationConsentData) {
        return <Spin size='large' />;
    }

    return (
        <div className='single-page-element document-text approved-consent-form'>
            <div className='approved-consent-form__print-button'>
                <Button
                    onClick={() => {
                        window.print();
                    }}
                    type='primary'
                >
                    Print
                </Button>
            </div>
            <div className='document' id='printableArea'>
                <Header
                    title='Dispensed Medication Consent Form'
                    headerData={{ ...medicationConsentData, pet_name: capitalizeFirstLetter(medicationConsentData.pet_name) }}
                />

                <p>
                    For each medication prescribed or dispensed from Veterinary Emergency Group, I have been offered counseling on the
                    following:
                </p>

                <ol>
                    <li>The name and description of the prescription drug.</li>
                    <li>
                        Route of administration, dosage form, dosage, duration of drug therapy, the duration of the side effects of the
                        drug, and the common severe adverse effects.
                    </li>
                    <li>Any special directions for proper use and storage.</li>
                    <li>Actions to be taken in the event of a missed dose.</li>
                </ol>

                <p>Please choose one of the following options:</p>

                <ul style={{ listStyle: 'none', marginBottom: '64px' }}>
                    <Radio.Group value={medicationConsentData.chosen_option} disabled>
                        <li>
                            <Radio value={1}>
                                I have had the opportunity to discuss the above information with a veterinarian or technician and do not
                                have further questions.
                            </Radio>
                        </li>
                        <li>
                            <Radio value={2}>I decline counseling and do not require any additional information.</Radio>
                        </li>
                        <li>
                            <Radio value={3}>I request printed information on each medication being prescribed.</Radio>
                        </li>
                    </Radio.Group>
                </ul>

                <div className='approved-consent-form__approved-at'>
                    <CheckCircleOutlined style={{ color: 'var(--green-alert-success)', marginRight: 'var(--spacing-sm)' }} />
                    Approved: {medicationConsentData.approved_date_hospital_timezone}
                </div>
            </div>
        </div>
    );
};

export default ApprovedMedicationConsentForm;
