import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { Tooltip, Table, Checkbox, Button, Empty } from "antd";
import { PriceDisplay } from "components/lib";
import { useEffect, useState } from "react";
import { formatCurrency, roundTo } from "utils/formatFuncs";
import { EstimateFormFields, VisitDiscount } from "utils/types/billingTypes";
import { Estimate, EstimateCreate, EstimateFreeItems, EstimateItemNew } from "utils/types/estimateTypesNew";
import { generateEstimateItemTableData, generateEstimateItemTableDataFormFields, generateEstimateTableData, isFreeDisabled } from "../utils";
import { EstimateTableHeader } from "./EstimateTableHeader";

interface EstimateTableNewProps {
    estimate: Estimate;
    openEstimateModal: (estimate?: Estimate, newEstimate?: EstimateCreate) => void
    visitDiscount?: VisitDiscount;
}
export interface EstimateTableData {
    newItemId?: number
    instructionId: number | null;
    nonMedicalOrderId: number | null;
    typeId: string;
    name: string;
    frequency: string;
    quantity: number;
    unit: string
    isFree: boolean;
    unitPrice: number;
    totalPrice: number;
    serial: boolean;
    serial_hours?: number;
    free_from_package?: boolean;
}
const EstimateTable = ({ estimate, openEstimateModal, visitDiscount }: EstimateTableNewProps) => {
    const [isExpanded, setIsExpanded] = useState(estimate.estimate_status !== 'declined');
    useEffect(() => {
            setIsExpanded(estimate.estimate_status !== 'declined');
    }, [estimate.estimate_status])
    const columns: any[] = [
        {
            title: 'Order',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (name: string, row: EstimateTableData) => (
                <Tooltip placement='topLeft' title={name}>
                    {name} {row.serial && ` - Serial ${row.serial_hours} Hours`}
                </Tooltip>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: '10%',
            align: 'left',
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '15%',
            align: 'center',
            render: (text: number, row: EstimateTableData) => {
                if (row.serial && row.serial_hours){
                    return `${roundTo(row.quantity / row.serial_hours, 0)} unit`
                }
                return `${roundTo(row.quantity, 3)} ${row.unit}`
            }
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: '10%',
            align: 'right',
            render: (text: number, row: EstimateTableData) => {
                if (row.serial && row.serial_hours){
                    return formatCurrency(roundTo(row.unitPrice * row.serial_hours, 0))
                }
                return formatCurrency(text)
            }
            },
        {
            title: 'No Charge',
            dataIndex: 'isFree',
            key: 'isFree',
            width: '10%',
            align: 'center',
            render: (is_free: boolean) =>
                is_free ? (
                    <CheckOutlined style={{ fontSize: '18px' }} />
                ) : (
                    <></>
                ),
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: '12.5%',
            align: 'right',
            render: (totalPrice: number, record: EstimateTableData) => (
                <PriceDisplay price={totalPrice || 0} isFreeOrRefund={record.isFree} />
                )

        },
        {
            title: '',
            width: '4.1%',
        },
    ];
    const data = generateEstimateTableData(estimate);
    return (
        <Table
            className={isExpanded ? '' : 'minimized-estimate-table'}
            locale={{ emptyText: ' ' }}
            title={() => (
                <EstimateTableHeader
                    estimate={estimate}
                    visitDiscount={visitDiscount}
                    setIsExpanded={setIsExpanded}
                    isExpanded={isExpanded}
                    openEstimateModal={openEstimateModal}
                />
            )}
            columns={columns}
            pagination={false}
            dataSource={data}
            size='small'
            data-cy={'estimateTable'}
        />
    );
};

export default EstimateTable;

interface EstimateModalTableProps {
    estimateItems: (EstimateFormFields | EstimateItemNew)[];
    updateEstimateItem: (id: number, idType: 'timestamp' | 'instructionId' | 'nonMedicalOrderId',  update: {is_free: boolean}) => void;
    removeEstimateItem: (id: number, idType: 'timestamp' | 'instructionId' | 'nonMedicalOrderId') => void;
    freeItems: EstimateFreeItems[];
    durationHours?: number
}
export const EstimateModalTable = ({ estimateItems, removeEstimateItem, updateEstimateItem, freeItems, durationHours }: EstimateModalTableProps) => {
    const columns: any[] = [
        {
            title: 'Order',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: '30%',
            ellipsis: {
                showTitle: false,
            },
            render: (name: string, row: EstimateTableData) => (
                <Tooltip placement='topLeft' title={name}>
                    {name} {row.serial && ` - Serial ${row.serial_hours} Hours`}
                </Tooltip>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            width: '10%',
            align: 'left',
            render: (text: string) => text.toLowerCase()
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '15%',
            align: 'center',
            render: (text: number, row: EstimateTableData) => {
                if (!durationHours || durationHours <= 0 ){
                    return "-"
                }
                if (row.serial && row.serial_hours){
                    return `${roundTo(row.quantity / row.serial_hours, 0)} unit`
                }
                return `${roundTo(row.quantity, 3)} ${row.unit}`
            }
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: '10%',
            align: 'right',
            render: (text: number, row: EstimateTableData) => {
                if (row.serial && row.serial_hours){
                    return formatCurrency(roundTo(row.unitPrice * row.serial_hours, 0))
                }
                return formatCurrency(text)
            }
            },
        {
            title: 'No Charge',
            dataIndex: 'isFree',
            key: 'isFree',
            width: '10%',
            align: 'center',
            render: (is_free: boolean, record: EstimateTableData) => 
                record.free_from_package ? (
                    <Tooltip title='This order is part of a package and has no additional charge.'>
                        <CheckOutlined style={{ fontSize: '18px' }} />
                    </Tooltip>
                ) : (
                    <Checkbox
                        checked={is_free}
                        disabled={isFreeDisabled(record, freeItems) || (record.free_from_package)}
                        onChange={() => {
                            if (record.newItemId !== undefined) {
                                updateEstimateItem(record.newItemId, 'timestamp', { is_free: !is_free });
                            } else if (record.instructionId) {
                                updateEstimateItem(record.instructionId, 'instructionId', { is_free: !is_free });
                            } else if (record.nonMedicalOrderId) {
                                updateEstimateItem(record.nonMedicalOrderId, 'nonMedicalOrderId', { is_free: !is_free });
                            }
                        }}
                    />
                ),
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: '10%',
            align: 'right',
            render: (totalPrice: number, record: EstimateTableData) => {
                if (!durationHours || durationHours <= 0 ){
                    return "-"
                }
                return <PriceDisplay price={totalPrice || 0} isFreeOrRefund={record.isFree} />
            }

        },
        {
            title: '',
            align: 'right',
            width: '10%',
            render: (_: any, record: EstimateTableData) => (
                <Button 
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        if (record.newItemId !== undefined){
                            removeEstimateItem(record.newItemId, 'timestamp')
                        } else if (record.instructionId){
                            removeEstimateItem(record.instructionId, 'instructionId')
                        } else if (record.nonMedicalOrderId){
                            removeEstimateItem(record.nonMedicalOrderId, 'nonMedicalOrderId')
                        }
                        
                    }}
                /> 
            )
        },
    ];
    const data = estimateItems.map((estimateItem, index) => {
        if ('instruction_id' in estimateItem || 'non_medical_order_id' in estimateItem){
            return generateEstimateItemTableData(estimateItem)
        } else {
            return generateEstimateItemTableDataFormFields(estimateItem, index)
        }
        
    });
    return (
        <Table
            locale={{ emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            columns={columns}
            pagination={false}
            dataSource={data}
            size='small'
        />
    );
};
