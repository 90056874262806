import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9.125" fill="white" stroke="currentColor" stroke-width="1.75"/>
        <path d="M9.2869 5.52276C8.96205 4.79184 8.17021 4.40607 7.76415 4.40607C7.05351 4.40607 6.64746 5.42124 6.64746 6.03034C6.64746 6.84248 7.35808 8.06068 8.27173 8.06068C9.00265 8.06068 9.38842 7.3839 9.48993 7.04551C9.55761 6.84248 9.61175 6.25368 9.2869 5.52276Z" fill="currentColor"/>
        <path d="M13.4726 6.14485C13.4944 5.34529 12.946 4.65603 12.5795 4.48109C11.9382 4.17493 11.1344 4.91613 10.872 5.46581C10.5221 6.19871 10.6386 7.60421 11.4631 7.99782C12.1227 8.31272 12.7624 7.86816 12.9998 7.60652C13.1484 7.45245 13.4509 6.94442 13.4726 6.14485Z" fill="currentColor"/>
        <path d="M15.6682 9.43031C15.8944 8.80802 15.644 8.12324 15.402 7.88998C14.9784 7.48178 14.1532 7.85366 13.8033 8.21673C13.3368 8.70082 13.0606 9.83515 13.6052 10.36C14.0409 10.7798 14.6596 10.598 14.9145 10.4546C15.0715 10.3725 15.442 10.0526 15.6682 9.43031Z" fill="currentColor"/>
        <path d="M4.29324 9.22728C4.06702 8.60499 4.31742 7.9202 4.55947 7.68695C4.98306 7.27875 5.80823 7.65062 6.15811 8.01369C6.62461 8.49779 6.90079 9.63212 6.35618 10.1569C5.9205 10.5768 5.3018 10.395 5.04691 10.2516C4.88994 10.1694 4.51945 9.84956 4.29324 9.22728Z" fill="currentColor"/>
        <path d="M14.4639 13.6441C14.0578 10.9031 10.6062 8.56825 10.0986 8.56825C8.67734 8.56825 5.73338 12.4259 5.7334 13.6441C5.73342 14.6643 6.54556 15.4714 7.35767 15.5729C8.16978 15.6744 9.42185 14.6593 10.0986 14.1517C10.0986 14.1517 11.7229 15.5729 12.6365 15.5729C13.5502 15.5729 14.6443 14.8623 14.4639 13.6441Z" fill="currentColor"/>
    </svg>
);

export const PawIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={svg} {...props} />;
