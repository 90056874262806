import { EnvironmentOutlined } from '@ant-design/icons';
import { ClockIcon } from 'assets/img/ClockIcon';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { WhiteboardItem } from 'services/whiteboardService';
import DoctorNurseTag from '../DoctorNurseTag';
import './StatusItem.css';
import { useIsUserAuthenticated } from 'hooks';
import { Typography } from 'antd';
import { EstimateTagStatus } from 'utils/types/estimateTypesNew';
import { EstimateTag } from 'views/visit/EstimatesNew/EstimateTag';

interface StatusItemProps {
    item: WhiteboardItem;
}

const StatusItem: React.FC<StatusItemProps> = ({ item }) => {
    const checkIfSomeIsBiggerThanZero = (instructionsCount: Array<number | null>) => {
        return instructionsCount.some((i) => i && i > 0);
    };
    const [{loggedIn}] = useIsUserAuthenticated();

    const getPetInformation = () => {
        return [item.species, item.weight ? `${item.weight}kg` : ''].filter((i) => !!i).join(', ');
    };

    const getEstimateTagData = (): { status: EstimateTagStatus; customTitle?: string; customStyle?: CSSProperties } | null => {
        if (!item.estimates_statuses) {
            return { status: 'nc' };
        }
        if (item.estimates_statuses?.some((status) => status === 'approved')) {
            if (!item.has_ledger) {
                return { status: 'np' };
            } else {
                return null;
            }
        }
        if (item.estimates_statuses?.some((status) => status === 'sent')) {
            return { status: 'sent', customTitle: 'Pending' };
        }
        if (item.estimates_statuses?.some((status) => status === 'draft')) {
            return { status: 'draft' };
        }
        return null;
    };

    const estimateTagData = getEstimateTagData();

    return (
        <div className={!!item.triage_level ? `status-item status-item--${item.triage_level}` : 'status-item status-item--default'}>
            <div className='status-item__patient-infos'>
                <div>
                    <StatusItemLink item={item} loggedIn={loggedIn}/>
                    <div className='status-item__visit-infos'>
                        {estimateTagData && (
                            <EstimateTag
                                status={estimateTagData.status}
                                customTitle={estimateTagData.customTitle}
                                approvedDate={null}
                                style={{
                                    ...estimateTagData?.customStyle,
                                    marginRight: 0,
                                }}
                            />
                        )}
                        {item.spiked_at && <ClockIcon style={{ height: '21px' }} />}
                        <DoctorNurseTag doctorName={item.doctor_name} />
                        <DoctorNurseTag nurseName={item.nurse_name} />
                        <span className='status-item__arrival-time'>{moment.unix(item.arrival_time).format('hh:mm A')}</span>
                    </div>
                </div>

                <div>
                    {getPetInformation() && <span>({getPetInformation()})</span>}

                    {item.location_in_hospital && item.location_in_hospital !== 'unknown' && (
                        <span className='status-item__location-in-hospital'>
                            <EnvironmentOutlined />
                            {item.location_in_hospital}
                        </span>
                    )}
                </div>
            </div>

            {item.reason_for_visit && <div className='status-item__reason status-item__ellipsis'>{item.reason_for_visit}</div>}

            {checkIfSomeIsBiggerThanZero([
                item.rads_count,
                item.vitals_count,
                item.diagnostics_count,
                item.meds_count,
                item.tasks_count,
                item.tgh_count,
            ]) && (
                <div className='status-item-instructions'>
                    {item.vitals_count !== 0 && (
                        <>
                            <span>{item.vitals_count} </span>
                            <span className='status-item-instructions__type'>Vitals</span>
                            {checkIfSomeIsBiggerThanZero([
                                item.rads_count,
                                item.bw_count,
                                item.diagnostics_count,
                                item.meds_count,
                                item.tasks_count,
                                item.tgh_count,
                            ]) && ', '}
                        </>
                    )}
                    {item.rads_count !== 0 && (
                        <>
                            <span>{item.rads_count} </span>
                            <span className='status-item-instructions__type'>RAD</span>
                            {checkIfSomeIsBiggerThanZero([
                                item.bw_count,
                                item.diagnostics_count,
                                item.meds_count,
                                item.tasks_count,
                                item.tgh_count,
                            ]) && ', '}
                        </>
                    )}
                    {item.bw_count !== 0 && (
                        <>
                            <span>{item.bw_count} </span>
                            <span className='status-item-instructions__type'>BW</span>
                            {checkIfSomeIsBiggerThanZero([item.diagnostics_count, item.meds_count, item.tasks_count, item.tgh_count]) &&
                                ', '}
                        </>
                    )}
                    {item.diagnostics_count !== 0 && (
                        <>
                            <span>{item.diagnostics_count} </span>
                            <span className='status-item-instructions__type'>Diag</span>
                            {checkIfSomeIsBiggerThanZero([item.meds_count, item.tasks_count, item.tgh_count]) && ', '}
                        </>
                    )}
                    {item.meds_count !== 0 && (
                        <>
                            <span>{item.meds_count} </span>
                            <span className='status-item-instructions__type'>Meds</span>
                            {checkIfSomeIsBiggerThanZero([item.tasks_count, item.tgh_count]) && ', '}
                        </>
                    )}
                    {item.tasks_count !== 0 && (
                        <>
                            <span>{item.tasks_count} </span>
                            <span className='status-item-instructions__type'>Tasks</span>
                            {checkIfSomeIsBiggerThanZero([item.tgh_count]) && ', '}
                        </>
                    )}
                    {item.tgh_count !== 0 && (
                        <>
                            <span>{item.tgh_count} </span>
                            <span className='status-item-instructions__type'>TGH</span>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

interface StatusItemLinkProps {
    item: WhiteboardItem;
    loggedIn: boolean;
}

const StatusItemLink: React.FC<StatusItemLinkProps> = ({ item, loggedIn }) => {
    if (loggedIn){
        return <Link className='status-item__patient-name status-item__ellipsis' to={`/visit/${item.visit_id}`} target='_blank'>
                {item.pet_name} {item.owner_last_name ? `${item.owner_last_name?.charAt(0)}.` : ''}
            </Link>
 
    } else {
        return <Typography.Text className='status-item__patient-name status-item__ellipsis'>
                {item.pet_name} {item.owner_last_name ? `${item.owner_last_name?.charAt(0)}.` : ''}
            </Typography.Text>
    }

}

export default StatusItem;
