import { FormName } from 'utils/constants';
import { useForm } from 'antd/lib/form/Form';
import PopoverClosableForm from 'components/popups/PopoverClosableForm';
import PatientInfoForm from 'components/forms/PatientInfo';
import { ElementRef, useRef } from 'react';

interface PatientInfoPopoverProps {
	initialContent: { [key: string]: any };
	children?: React.ReactNode;
	disabled?: boolean;
}

const PatientInfoPopover: React.FC<PatientInfoPopoverProps> = ({
	initialContent,
	children,
	disabled
}) => {
	const [patientInfoFormObject] = useForm();

	const patientInfoRef = useRef<ElementRef<typeof PatientInfoForm>>(null);
	const cancelDeceasedReverted = () => {
		patientInfoRef.current?.cancelDeceasedReverted();
	};
	return (
		<PopoverClosableForm
			formName='patient_info'
			initialContent={{ ...initialContent }}
			placement='leftTop'
			form={patientInfoFormObject}
			cancelDeceasedReverted={cancelDeceasedReverted}
			formJSX={() => {
				return (
					<PatientInfoForm
						patientInfo={initialContent}
						patientAlerts={initialContent.patientAlerts ?? []}
						form={patientInfoFormObject}
						ref={patientInfoRef}
						cancelDeceasedReverted={cancelDeceasedReverted}
					/>
				);
			}}
			disabled={disabled}
			dataCy="patientInfoForm"
		>
			{children || FormName['patient_info']}
		</PopoverClosableForm>
	);
};

export default PatientInfoPopover;
