import { Spin } from 'antd';
import loadingStyles from './loading.module.css';

interface LoadingProps { }

export const Loading: React.FC<LoadingProps> = () => {

    const lovelyTips = [
        "Hugging your dog",
        "Petting your hamster",
        "Preparing the VEGolution",
        "Sitting on the floor",
    ]

    const grabTip = () => {
        return lovelyTips[Math.floor(Math.random() * lovelyTips.length)]
    }

    return (
        <div className={loadingStyles.loadingWrapper}>
            <Spin size="large" tip={`${grabTip()}..`} />
        </div>
    );
};

export default Loading;
