import { DownOutlined, LockOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Col, Dropdown, Form, FormInstance, Menu, Row } from 'antd';
import { FormName } from 'utils/constants';
import {
    CustomerInfo,
    ExpandedPatient,
    PatientRecordVisit,
    RDVM,
} from 'utils/dataTypes';
import { INITIAL_MODAL_STATE } from '../Visit';

export const openChartLockModal = (
    setModalstate: Function,
    modalForm: FormInstance,
    onFinish: (values: any, formName: FormName, additionalState?: {}) => void,
    currentVisit: PatientRecordVisit | undefined,
    patientData: ExpandedPatient | undefined,
    existingCustomer: CustomerInfo | undefined,
    showInfoModalAfterClose: Function,
    allRdvms?: RDVM[] | undefined
) => {
    if (!currentVisit) return;

    let emailRdvm = false;
    let emailCustomer = false;
    let textSurvey = false;
    let textFollowUp = false;

    const disabledRule = !['checked_out', 'noshow'].includes(
        currentVisit.status,
    );
    const rDVM: RDVM | undefined = allRdvms?.find((rdvm) => rdvm.clinic_id === currentVisit?.rdvm_id);
    const rdvmEmail = rDVM?.display_name === 'No Primary Vet' ? null : rDVM?.display_name === 'My Vet Is Not On the List' && !rDVM?.override_email ? null : rDVM?.override_email || rDVM?.email


    if (
        rdvmEmail &&
        existingCustomer?.email
    ) {
        emailRdvm = true;
        emailCustomer = true;
    } else if (rdvmEmail) {
        emailRdvm = true;
    } else if (existingCustomer?.email) {
        emailCustomer = true;
    }

    if (!!patientData?.deceased_at){
        // if patient is deceased always default to no email 
        emailCustomer = false;
    }

    if (!!existingCustomer?.phone_number && !patientData?.deceased_at) {
        textSurvey = true;
        textFollowUp = true;
    }

    const isRdvmEmailMissing = !rdvmEmail;
    const isCustomerEmailMissing = !existingCustomer?.email;
    let alertMessage = '';
    
    if (isRdvmEmailMissing && isCustomerEmailMissing) {
        alertMessage = 'No email on record for this rDVM and customer.'
    }else if (isRdvmEmailMissing) {
        alertMessage = 'No email on record for this rDVM.'
    } else if (isCustomerEmailMissing) {
        alertMessage = 'No email on record for this customer.'
    }

    const closeVisit = (shouldDownloadMedicalRecord: boolean) => {
        onFinish(
            {
                email_rdvm: currentVisit.rdvm_id ? emailRdvm : false,
                email_customer: existingCustomer?.email ? emailCustomer : false,
                text_survey: existingCustomer?.phone_number
                    ? textSurvey
                    : false,
                text_follow_up: existingCustomer?.phone_number
                    ? textFollowUp
                    : false,
            },
            FormName.close_chart,
            {
                shouldDownloadMedicalRecord,
                showInfoModalAfterClose,
            },
        );
        setModalstate({
            ...INITIAL_MODAL_STATE,
            form: modalForm,
        });
        modalForm.resetFields();
    };

    setModalstate({
        isVisible: true,
        width: 550,
        className: 'close-chart-modal',
        okText: disabledRule ? 'Ok' : 'Sign and Close',
        cancelButtonProps: disabledRule ? { style: { display: 'none' } } : {},
        okButtonProps: disabledRule
            ? {}
            : {
                  icon: <LockOutlined />,
                  danger: true,
                  disabled: disabledRule,
              },
        title: 'Sign and Close',
        content: disabledRule ? (
            'Please check out or discharge the patient before closing chart'
        ) : (
            <>
                <Form.Item
                    label={
                        <span style={{ fontWeight: 500 }}>
                            Send text messages to customer
                        </span>
                    }
                    style={{ marginBottom: '20px' }}
                >
                    <Row>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Checkbox
                                onChange={() => (textSurvey = !textSurvey)}
                                defaultChecked={
                                    !!existingCustomer?.phone_number &&
                                    !patientData?.deceased_at
                                }
                                disabled={
                                    !existingCustomer?.phone_number ||
                                    !!patientData?.deceased_at
                                }
                                data-cy={'customerSurveyCheckbox'}
                            >
                                Customer survey (now)
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox
                                onChange={() => (textFollowUp = !textFollowUp)}
                                defaultChecked={
                                    !!existingCustomer?.phone_number &&
                                    !patientData?.deceased_at
                                }
                                disabled={
                                    !existingCustomer?.phone_number ||
                                    !!patientData?.deceased_at
                                }
                                data-cy={'followUpMessageCheckbox'}
                            >
                                Follow-up message (in 24 hrs)
                            </Checkbox>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item noStyle>
                    <div style={{ fontWeight: 500, textAlign: 'left' }}>
                        Email final chart to:
                    </div>
                    {(alertMessage) && (
                        <Alert style={{ marginBottom: 'var(--spacing-sm)' }} message={alertMessage} type='warning' showIcon />
                    )}
                    <Row style={{ flexDirection: 'column' }}>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Checkbox
                                onChange={() => {
                                    emailRdvm = !emailRdvm;
                                }}
                                defaultChecked={emailRdvm}
                                disabled={
                                    !rdvmEmail
                                }
                                data-cy={'rdvmCheckbox'}
                            >
                                rDVM
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox
                                onChange={() => {
                                    emailCustomer = !emailCustomer;
                                }}
                                defaultChecked={emailCustomer}
                                disabled={!existingCustomer?.email}
                                data-cy={'customerCheckbox'}
                            >
                                Customer
                            </Checkbox>
                        </Col>
                    </Row>
                </Form.Item>
            </>
        ),
        formName: FormName.close_chart,
        form: modalForm,
        footer: (
            <>
                {/* TODO if above version 4.21.0 add AutoFocus here  */}
                <Dropdown.Button
                    data-cy="signAndCloseButton"
                    icon={<DownOutlined />}
                    onClick={() => closeVisit(false)}
                    overlay={
                        <Menu data-cy="signAndCloseMenu">
                            <Menu.Item data-cy="signAndCloseOption" onClick={() => closeVisit(false)}>
                                Sign and Close
                            </Menu.Item>
                            <Menu.Item data-cy="signCloseAndDownloadOption" onClick={() => closeVisit(true)}>
                                Sign, Close, and Download Chart
                            </Menu.Item>
                        </Menu>
                    }
                    className='close-chart-dropdown'
                >
                    <LockOutlined />
                    Sign and Close
                </Dropdown.Button>
            </>
        ),
        onFinish: () => {
            if (!disabledRule) {
                onFinish(
                    {
                        email_rdvm: currentVisit.rdvm_id ? emailRdvm : false,
                        email_customer: existingCustomer?.email
                            ? emailCustomer
                            : false,
                        text_survey: existingCustomer?.phone_number
                            ? textSurvey
                            : false,
                        text_follow_up: existingCustomer?.phone_number
                            ? textFollowUp
                            : false,
                    },
                    FormName.close_chart,
                );
            }
            setModalstate({
                ...INITIAL_MODAL_STATE,
                form: modalForm,
            });
            modalForm.resetFields();
        },
        onCancel: () => {
            setModalstate({
                ...INITIAL_MODAL_STATE,
                form: modalForm,
            });
            modalForm.resetFields();
        },
    });
};
