import React, { CSSProperties, ReactElement } from 'react';

import { Col, Row, Form, Button } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';

interface FormWrapperProps {
	onFinish: (values: any) => void;
	onFinishFailed: (values: any) => void;
	onFormChange?: (value: any) => void; //TODO maybe make this mandatory
	getFormData?: () => any; //TODO maybe make this mandatory
	style?: CSSProperties;
	submitButtonText?: string;
	children: JSX.Element | JSX.Element[];
	form?: any;
	initialValues?: any;
	onValuesChange?: (changedValues: any, values: any) => void;
	bottomBar?: JSX.Element  // Allows cancel bottom and use of the form wrapper in situation where submit isn't required
	layout?: FormLayout;
	gutter?: number;
	className?: string;
}

export const FormWrapper = (props: FormWrapperProps) => {
	const [form] = Form.useForm();
	const gutter = props.gutter ?? 16
	let enrichedChildren = React.Children.toArray(props.children).map((child) =>
		React.cloneElement(child as ReactElement, {
			onFormChange: props.onFormChange ?? form.setFieldsValue,
			getFormData: props.getFormData ?? form.getFieldsValue,
		}),
	);
	const bottomRow = props.bottomBar ?? (<Row justify='end' style={{ width: '100%' }}>
		<Col span={8}>
			<Form.Item
				wrapperCol={{ span: 16, offset: 8 }}
				style={{ marginTop: '12px' }}
			>
				<Button type='primary' htmlType='submit' style={{}} data-cy="formSubmitButton">
					{props.submitButtonText ?? 'Submit'}
				</Button>
			</Form.Item>
		</Col>
	</Row>
	)
	const layout = props.layout ?? 'horizontal'
	return (
		<Form
			labelCol={{ span: 4 }} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={{ span: 20 }} //Width of INPUT, in "columns" (like <Col span={12})
			layout={layout}
			form={props.form || form}
			preserve={false}
			labelAlign='left'
			validateTrigger={['onSubmit', 'onChange']}
			{...props} //Using passed-in `onFinish`, `onFinishFailed`, `initalValues`, etc.
		>
			<Row gutter={gutter}>{enrichedChildren}</Row>
			{bottomRow}
		</Form>
	);
};
