import { FormInstance } from "antd";
import { useEffect, useState } from "react";
import { useGetUserDataQuery } from "services/authService";
import { useLazyGetSupplementalByNameQuery } from "services/supplementalService";
import { BASE_QUERY_OPTIONS, FALLBACK_MED_ROUTES, INSTRUCTION_FREQUENCY_REGEX } from "utils/constants";
import { MedicationDose, PimsUser } from "utils/dataTypes";
import { MedicineSearchOption, PackageMedInstruction, isInstanceOfPackageMedInstruction } from "utils/types/InstructionOrderTypes";
import { MedicineEstimateFormFields } from "utils/types/billingTypes";
import { MedicineEstimateRules } from "utils/types/validations";
import { validateFieldIsGreaterThan } from "utils/validationFuncs";
import MedicationDoseAlert from "../MedicationDoseAlert";
import { MedicationFrequency, MedicationRoute, MedicationWeight, MedicineDosage, calculateDefaultDose } from "../MedicineOrder";
import { HiddenInput } from "../fields/HiddenInput";
import { checkIfSerialCheaper } from "utils/formFuncs";
import AlertSerialPrice from "components/alerts/AlertSerialPrice";


export const getMedicineEstimateDefaults = (
    dataSource: MedicineSearchOption | PackageMedInstruction,
    user?: PimsUser,
    patientWeight?: number,
    approxPatientWeight?: number
): Partial<MedicineEstimateFormFields> => {
    let defaultInstruction: Partial<MedicineEstimateFormFields>;
    if (isInstanceOfPackageMedInstruction(dataSource)) {
        defaultInstruction = {
            medication_id: dataSource.medication_id,
            type_id: dataSource.type_id,
            name: dataSource.name,
            frequency: dataSource.frequency || undefined,
            route_id: dataSource.route_id || undefined,
            is_prn: false,
            prn_condition: null,

            dose: dataSource.dose,
            dose_unit: dataSource.dose_unit,
            unit: dataSource.dose_unit,
            unit_cost_cents: dataSource.cents,
            priority: false,
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            calculator_type: dataSource.calculator_type,
            approx_patient_weight_kg: approxPatientWeight ?? null,
            latest_patient_weight_kg: patientWeight ?? null,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null



        };

    } else {
        defaultInstruction = {
            medication_id: dataSource.id,
            type_id: dataSource.type_id,
            name: dataSource.name,
            frequency: dataSource.default_frequency || undefined,
            route_id: dataSource.default_route || undefined,
            is_prn: false,
            prn_condition: null,

            unit_cost_cents: dataSource.cents,
            priority: false,
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            calculator_type: dataSource.calculator_type,
            approx_patient_weight_kg: approxPatientWeight ?? null,
            latest_patient_weight_kg: patientWeight ?? null,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null


        };

    }
    return defaultInstruction;
};

export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';

interface MedicineEstimateProps {
    dataSource: MedicineSearchOption;
    patientWeight?: number;
    approxPatientWeightPackage?: number;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    getFormData?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    medicine: MedicineSearchOption;
    durationHours?: number;
    form?: FormInstance<any>
}

export const MedicineEstimate = (props: MedicineEstimateProps) => {
    const { dataSource, patientWeight, approxPatientWeightPackage, medicine, durationHours } = props;
    const isPackageInstruction = isInstanceOfPackageMedInstruction(dataSource)

    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );


    const [approxPatientWeight, setApproxPatientWeight] = useState(approxPatientWeightPackage);
    const [routeId, setRouteId] = useState<string | undefined>();

    const [getSupplemental, {data: supplementalData}] = useLazyGetSupplementalByNameQuery()

    useEffect(() => {
        setApproxPatientWeight(approxPatientWeightPackage)
        onFormChange({
            approx_patient_weight_kg: approxPatientWeightPackage ?? null,
        });
    }, [approxPatientWeightPackage])
    const [doseObject, setDoseObject] = useState<MedicationDose>({
        dose: null,
        unit: null
    })

    useEffect(() => {
        onFormChange({ dose: doseObject.dose, dose_unit: doseObject.unit })
    }, [doseObject])

    const existingDosage = isInstanceOfPackageMedInstruction(dataSource) ? dataSource?.dose : undefined;

    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 18 };

    const [isSerialCheaper, setIsSerialCheaper] = useState(false)

    const onFormChange = (changes: any) => {  
        if (props?.onFormChange && props?.getFormData){ 
            const fields = {
                ...props?.getFormData(),
                ...changes
            };
            const serial = checkIfSerialCheaper(
                medicine, 
                fields.frequency, 
                0, 
                (durationHours ?? 0) * 3600,
                fields?.supplemental_cost_cents,
                doseObject.dose ?? 0,
                )
            setIsSerialCheaper(serial)
            let unit_cost_cents;
            let unit = fields.unit;
            if (serial){
                unit_cost_cents = medicine.price_cents_serial ?? 0;
                unit = "hours";
            }else{
                unit_cost_cents = medicine.cents;
            }
            return props?.onFormChange({...changes, serial, unit_cost_cents, unit})
        } else {
            return (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
        }
    }

    useEffect(() => {
        const defaultMedicine = getMedicineEstimateDefaults(dataSource, loggedInUserData, patientWeight, approxPatientWeight);
        if (props.form) {
            props.form.resetFields(Object.keys(defaultMedicine))
        }
        onFormChange(defaultMedicine)
        setRouteId(defaultMedicine.route_id)
    }, [dataSource]);

    useEffect(() => {
        if (routeId) {
            getSupplemental({ name: routeId, controlledMedication: dataSource.controlled_drug });
        }
    }, [routeId]);

    useEffect(() => {
        if (supplementalData) {
            onFormChange({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents
            })

        }
    }, [supplementalData])


    useEffect(() => {
        const defaultDose = calculateDefaultDose(dataSource, approxPatientWeight, patientWeight)

		if (!!defaultDose) {
			setDoseObject(defaultDose);
		}
    }, [dataSource, approxPatientWeight]);
    useEffect(() => {
        if (doseObject.unit) {
            onFormChange({
                unit: doseObject.unit
            })
        }
    }, [doseObject])

    const localWeight = patientWeight ?? approxPatientWeight ?? 0;

    const medicineEstimateRules: MedicineEstimateRules = {
        frequency: [{ required: true, pattern: INSTRUCTION_FREQUENCY_REGEX, }],
        approx_patient_weight_kg: [
            {
                required: true,
            },
            {

                validator: validateFieldIsGreaterThan('patient weight'),
            }
        ],
        dose: [
            {
                required: true,
            },
            {
                validator: validateFieldIsGreaterThan('dose'),
            }
        ],
        route_id: [{ required: true, enum: [...FALLBACK_MED_ROUTES] }],
        is_prn: [{ required: false, type: 'boolean' }],
        prn_condition: []
    }
    const showWeight = patientWeight === undefined
    return (
        <>
            {isSerialCheaper && <AlertSerialPrice serialHours={medicine.serial_hours ?? 0} inForm />}
            <MedicationWeight
                autoFocus={showWeight}
                disabled={false}
                inPackage={isPackageInstruction}
                rules={medicineEstimateRules.approx_patient_weight_kg}
                onChange={(value) => {
                    onFormChange({
                        approx_patient_weight_kg: value,
                    });
                    setApproxPatientWeight(value || undefined);
                }}
                patientWeight={isPackageInstruction ? localWeight :patientWeight}
            />
            <MedicineDosage
                autoFocus={!showWeight}
                patientWeight={localWeight}
                doseObject={doseObject}
                setDoseObject={setDoseObject}
                medication={dataSource}
                onFormChange={onFormChange} // This form field is fully controlled
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                existingDosage={existingDosage}
                rules={medicineEstimateRules.dose}
            />
            <MedicationRoute
                medication={dataSource}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                rules={medicineEstimateRules.route_id}
                onChange={(input: string) => setRouteId(input)}

            />
            <MedicationFrequency
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                rules={medicineEstimateRules.frequency}
                onFormChange={onFormChange}

            />

            <MedicationDoseAlert
                lowDoseAlert={dataSource.low_dose_alert}
                highDoseAlert={dataSource.high_dose_alert}
                patientWeight={localWeight}
                dose={doseObject.dose ?? existingDosage}
                doseUnit={dataSource.numerator_unit}
                medication={dataSource}
            />

            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='prn_condition' />
            <HiddenInput fieldName='medication_id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='unit_cost_cents' />
            <HiddenInput fieldName='unit' />
            <HiddenInput fieldName='is_free' />
            <HiddenInput fieldName='reason' />
            <HiddenInput fieldName='ordered_by' />
            <HiddenInput fieldName='notes' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='calculator_type' />
            <HiddenInput fieldName={'latest_patient_weight_kg'} />
            <HiddenInput fieldName='supplemental_cost_cents' />
            <HiddenInput fieldName='is_recurring_supplemental' />
            <HiddenInput fieldName='priority' />
            <HiddenInput fieldName="controlled_drug" initialValue={dataSource.controlled_drug} />
            <HiddenInput fieldName='serial'/>
            <HiddenInput fieldName='serial_hours' initialValue={medicine.serial_hours} />

            {/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
        </>
    );
};
