import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEV_STASH_HOST } from '../utils/constants';

export const billingApi = createApi({
	reducerPath: 'billingApi',
	tagTypes: ['Estimate'],
	baseQuery: fetchBaseQuery({
		baseUrl: DEV_STASH_HOST,
		credentials: 'include',
		prepareHeaders: (headers, { getState }) => {
			headers.set('Content-Type', 'application/json');
			return headers;
		},
	}),
	endpoints: () => ({}),
});
