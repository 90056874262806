import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, FormInstance, Tooltip, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';

import { useEffect, useState } from 'react';
import { useAddCallLogMutation, useModifyCallLogMutation } from 'services/vetDashService';
import { drawerSize } from 'styles/drawer';
import { CallLog, InboundCall } from 'utils/dataTypes';
import useWindowDimensions from 'utils/window';
import './CallLogDrawer.css';
import { CallLogForm, allSectionsComplete, requiresCheckinSection, validPhoneNumber } from './CallLogForm';

interface CallLogDrawerProps {
    formInstance: FormInstance;
    record: InboundCall | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const CallLogDrawer = ({ formInstance, record, open, setOpen }: CallLogDrawerProps) => {
    // Without this state change, rerender doesn't happen right away after a form value change
    // Which makes all formVal calls outdated
    const [formChanged, setFormChanged] = useState(false);
    useEffect(() => {}, [formChanged]);
    const [complete, setComplete] = useState<boolean>(allSectionsComplete(formInstance));

    const [addCallLog] = useAddCallLogMutation();
    const [modifyCallLog] = useModifyCallLogMutation();
    const { width } = useWindowDimensions();

    const handleCloseDrawer = () => {
        if (formInstance.isFieldsTouched()) {
            confirm({
                title: 'Unsaved Changes',
                icon: <ExclamationCircleOutlined style={{ color: 'var(--gold-6)' }} />,
                content: <p>You have unsaved changes. Select Cancel to continue editing.</p>,
                okText: 'Ok',
                style: { zIndex: 1500 },
                okButtonProps: { type: 'primary' },
                cancelText: 'Cancel',
                onOk() {
                    setOpen(false);
                },
            });
        } else {
            setOpen(false);
        }
    };

    const onFormSave = () => {
        const values = formInstance.getFieldsValue();
        let log: CallLog;
        switch (values.call_type) {
            case 'Customer':
                log = {
                    call_type: values.call_type,
                    reason: values.reason,
                    reason_other: values.reason_other,
                    patient_name: values.patient_name,
                    spoke_with_doctor: values.spoke_with_doctor,
                    doctor_id: values.doctor_id,
                    recommendation: values.recommendation,
                    referred_where: values.referred_where,
                    referred_why: values.referred_why,
                    referred_why_other: values.referred_why_other,
                    note: values.note,
                    send_check_in_link: values.send_check_in_link,
                    mobile_phone: values.mobile_phone,
                };
                break;
            case 'rDVM':
                log = {
                    call_type: values.call_type,
                    patient_name: values.patient_name,
                    spoke_with_doctor: values.spoke_with_doctor,
                    doctor_id: values.doctor_id,
                    reason: values.reason,
                    reason_other: values.reason_other,
                    note: values.note,
                };
                break;
            case 'Other':
                log = {
                    call_type: values.call_type,
                };
                break;
            default:
                throw new Error(`Unknown call type ${values.call_type} in call log form`);
        }
        const payload = {
            sessionId: values.original_call.session_id,
            log: log,
        };
        if (!!values.original_call.call_type) {
            modifyCallLog(payload)
                .unwrap()
                .then(() => {
                    setOpen(false);
                    message.success('Successfully updated call log');
                })
                .catch((err: string) => message.error(err));
        } else if (!!values.call_type) {
            addCallLog(payload)
                .unwrap()
                .then((new_log: CallLog) => {
                    setOpen(false);
                    message.success('Successfully created call log');
                })
                .catch((err: string) => message.error(err));
        }
    };

    return (
        <Drawer
            title={record?.from_name}
            className='veg-drawer call-log-drawer'
            placement='right'
            closable={true}
            width={width >= 540 ? drawerSize.md : drawerSize.extended}
            onClose={handleCloseDrawer}
            visible={open}
            key='call_log_drawer'
            destroyOnClose={true}
            mask={true}
            maskClosable={true}
            footer={
                <>
                    <Button type='ghost' onClick={handleCloseDrawer}>
                        Cancel
                    </Button>
                    <Button
                        type={formInstance.getFieldValue('call_type') === 'Other' || !complete ? 'primary' : 'default'}
                        htmlType='submit'
                        onClick={onFormSave}
                        disabled={
                            !formInstance.getFieldValue('call_type') ||
                            (requiresCheckinSection(formInstance) &&
                                formInstance.getFieldValue('send_check_in_link') === true &&
                                !validPhoneNumber(formInstance.getFieldValue('mobile_phone')))
                        }
                    >
                        {formInstance.getFieldValue('call_type') === 'Other' ? 'Save as Dropped/Spam Call' : 'Save'}
                    </Button>
                    <Tooltip placement='top' title={complete ? '' : 'All sections must be complete in order to mark as complete.'}>
                        <Button
                            type='primary'
                            htmlType='submit'
                            onClick={onFormSave}
                            disabled={!complete}
                            hidden={formInstance.getFieldValue('call_type') === 'Other'}
                        >
                            Save and Mark Complete
                        </Button>
                    </Tooltip>
                </>
            }
        >
            {!!record && (
                <CallLogForm
                    formInstance={formInstance}
                    record={record}
                    key={record.session_id}
                    setComplete={setComplete}
                    setFormChanged={setFormChanged}
                />
            )}
        </Drawer>
    );
};
