import { PaperClipOutlined } from '@ant-design/icons';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Button, Form, FormInstance, Input } from 'antd';
import RecentResultsModal from 'components/Modals/RecentResults';
import { useAppDispatch } from 'hooks/useRedux';
import { useState } from 'react';
import { setActiveTreatmentSheetAsk } from 'store/slices/treatmentSheetSlice';
import {
    ExistingDiagInstruction,
    PimsUser,
    TreatmentSheetInstructionAction,
    VCPExternalResult,
} from 'utils/dataTypes';
import { IHDResultsFile } from 'utils/types/InstructionOrderTypes';

interface WebpacsAdministerFormProps {
    action?: TreatmentSheetInstructionAction;
    instruction: ExistingDiagInstruction;
    onFormChange: Function;
    modalForm: FormInstance<any>;
    resultsFile?: IHDResultsFile;
    isFileDownloading?: boolean;
    webpacsOrderEvt: Function;
    webpacsOrderLoading: boolean;
    actionStatus?: string;
    setActionStatus?: Function;
    activeActionModal?: string;
    patientName?: string;
    isRecentResultsModalOpen?: boolean;
    setIsRecentResultsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    recentResults?: VCPExternalResult[];
    setRecentResults?: React.Dispatch<React.SetStateAction<VCPExternalResult[]>>;
    selectedRow?: string;
    setSelectedRow?: React.Dispatch<React.SetStateAction<string>>;
    completedTime?: moment.Moment | null;
    setCompletedTime?: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
    modifyAction?: any;
    loggedInUserData?: PimsUser;
    getRecentXrayResultsTrigger?: LazyQueryTrigger<QueryDefinition<void, any, any, VCPExternalResult[], any>>;
}
export const WebpacsAdministerForm = (props: WebpacsAdministerFormProps) => {
    const formItemStyle = { width: '100%' };
    const {
        action,
        instruction,
        onFormChange,
        modalForm,
        resultsFile,
        isFileDownloading,
        webpacsOrderEvt,
        webpacsOrderLoading,
        actionStatus,
        setActionStatus,
        activeActionModal,
        patientName,
        isRecentResultsModalOpen,
        setIsRecentResultsModalOpen,
        recentResults,
        setRecentResults,
        selectedRow,
        setSelectedRow,
        completedTime,
        setCompletedTime,
        modifyAction,
        loggedInUserData,
        getRecentXrayResultsTrigger,
    } = props;
    const { result_entry } = instruction;

    const appDispatch = useAppDispatch();
    const [showUrlInput, setShowInput] = useState(false);
    let orderXrayButton = (
        <>
            <Form.Item
                label={result_entry.label}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={formItemStyle}
                labelAlign='left'
            >
                <WebpacsOrderButton
                    action={action}
                    clickEvt={webpacsOrderEvt}
                    loading={webpacsOrderLoading}
                />
            </Form.Item>
        </>
    );

    let fetchResultsButtons = (
        <>
            <Form.Item
                label={'Results'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={formItemStyle}
                labelAlign='left'
            >
                {action && !action.value && actionStatus === 'complete' && <Button
                    type='primary'
                    style={{ marginTop: '5px', marginRight: '14px' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        getRecentXrayResultsTrigger?.().unwrap().then(
                            (data: any) => {
                                setRecentResults?.(data);
                                setSelectedRow?.('');
                                setIsRecentResultsModalOpen?.(true);
                            }
                        );
                    }}
                >
                    Fetch Results
                </Button>}
                <Button
                    icon={<PaperClipOutlined />}
                    onClick={() => {
                        setShowInput((show) => !show);
                    }}
                >
                    Add Link
                </Button>
            </Form.Item>
            {!!action && <RecentResultsModal
                dispatch={appDispatch}
                patientName={patientName}
                isOpen={isRecentResultsModalOpen}
                setIsOpen={setIsRecentResultsModalOpen}
                order={instruction}
                action={action}
                recentResults={recentResults}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                completedTime={completedTime}
                setCompletedTime={setCompletedTime}
                modifyAction={modifyAction}
                loggedInUserData={loggedInUserData}
            />}
        </>
    );
    let openXrayResultsButtons = (
        <>
            <Form.Item
                label={'Results'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={formItemStyle}
                labelAlign='left'
            >
                <WebpacsDownloadButton
                    resultsFile={resultsFile}
                    loading={isFileDownloading}
                />
            </Form.Item>
        </>
    );

    let customURL = (
        <>
            <Form.Item
                label={activeActionModal === 'CompleteActionModal' ? 'Result Link' : 'X-Ray Link'}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={formItemStyle}
                labelAlign='left'
                name={'value'}
                rules={
                    actionStatus === 'complete'
                        ? [
                            {
                                required: true,
                                message: 'Please enter a value',
                            },
                            {
                                pattern: new RegExp(result_entry.condition),
                            },
                            {
                                type: "url",
                                message: "This field must be a valid url."
                            }
                        ]
                        : [
                            {
                                len: 0,
                                message: 'Incomplete should not have a value',
                            },
                        ]
                }
            >
                <Input
                    prefix={<PaperClipOutlined />}
                    type='url'
                    placeholder={actionStatus === 'complete' ? 'required' : ''}
                    autoComplete={'off'}
                    autoFocus={true}
                    onChange={(e) => {
                        if (e.target.value) {
                            onFormChange({
                                value: e.target.value,
                                status: 'complete',
                            });
                        } else {
                            onFormChange({
                                value: e.target.value,
                            });
                        }
                        {
                            e.target.value &&
                                setActionStatus &&
                                setActionStatus('complete');
                        }
                    }}
                    suffix={result_entry.unit}
                />
            </Form.Item>
        </>
    );
    if (!action || actionStatus === 'scheduled' || actionStatus === 'claimed') {
        return orderXrayButton;
    } else if (activeActionModal === 'CompleteActionModal') {
        return (
            <>
                {customURL}
            </>
        )
    } else {
        return (
            <>
                {!action?.value && orderXrayButton}
                {fetchResultsButtons}
                {action?.value && openXrayResultsButtons}
                {showUrlInput && customURL}
            </>
        );
    }
};

interface WebpacsDownloadButtonProps {
    resultsFile: IHDResultsFile | undefined;
    loading: boolean | undefined;
}

const WebpacsDownloadButton = ({
    resultsFile,
    loading,
}: WebpacsDownloadButtonProps) => {
    return (
        <Button
            autoFocus
            type='primary'
            onClick={() => {
                !!resultsFile && window.open(resultsFile.url);
            }}
            loading={loading}
        >
            Open X-Ray
        </Button>
    );
};

interface WebpacsOrderButtonProps {
    action?: TreatmentSheetInstructionAction;
    clickEvt: Function;
    loading: boolean;
}

const WebpacsOrderButton = ({
    action,
    clickEvt,
    loading,
}: WebpacsOrderButtonProps) => {
    const appDispatch = useAppDispatch();
    return (
        <Button
            autoFocus
            type='primary'
            loading={loading}
            onClick={() => {
                clickEvt({ instruction_id: action?.instruction_id, due_at: action?.due_at })
                    .then((resp: any) => {
                        if (!!resp.error) {
                            console.error(
                                'Failed to request WebPACS order, got',
                                resp,
                            );
                        } else {
                            appDispatch(setActiveTreatmentSheetAsk(null));
                        }
                    });
            }}
        >
            Order X-Ray
        </Button>
    );
};
