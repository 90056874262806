import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Col, Space } from 'antd';
import { Button } from 'components/lib';

interface PopoverTitleProps {
	title: string;
	minimizeFunction: () => void;
	closeFunction: () => void;
}

const PopoverTitle: React.FC<PopoverTitleProps> = ({
	title,
	minimizeFunction,
	closeFunction,
}) => {
	return (
		<Row justify='space-between'>
			<Col>{title}</Col>
			<Col>
				<Space size='small'>
					<Button
						icon={<DownOutlined />}
						onClick={minimizeFunction}
					/>
					<Button icon={<CloseOutlined />} onClick={closeFunction} />
				</Space>
			</Col>
		</Row>
	);
};

export default PopoverTitle;
