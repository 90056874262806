import { useEffect, useState } from 'react';
import { DiscoverResult, ErrorResponse, loadStripeTerminal, Terminal, Reader } from '@stripe/terminal-js';
import { Button, Modal, Row, Col, Select, Input, Form, FormInstance, Spin } from 'antd';
import { formatCurrency, roundTo } from 'utils/formatFuncs';
import { useCapturePaymentIntentMutation, useCreatePaymentIntentMutation, useFetchConnectionTokenMutation, useSaveWebCheckoutPaymentErrorMutation } from 'services/paymentService';
import { FormWrapper } from 'components/forms/FormWrapper';
import { SUPPORTED_PAYMENT_AMOUNT_REGEX } from 'utils/constants';
import { validatePaymentAmountInput } from 'utils/miscFuncs';
import './payment_modal.css';
import { useLazyGetStripePaymentErrorsByVisitIdQuery } from 'services/billingService';
import { EstimateInvoiceRadio, EstimateInvoiceRadioValues } from './EstimateInvoiceRadio';
import { PaymentInfo, PaymentInfoProps } from './PaymentInfo';
const { Option } = Select;

const SIMULATED_MODE = process.env.REACT_APP_STRIPE_TERMINAL_SIMULATOR === 'true' ? true : false;

const testCards = {
    visa: '4242424242424242',
    mastercard: '5555555555554444',
    visa_debit: '4000056655665556',
    charge_declined: '4000000000000002',
    charge_declined_insufficient_funds: '4000000000009995',
    charge_declined_lost_card: '4000000000009987',
    charge_declined_stolen_card: '4000000000009979',
    charge_declined_expired_card: '4000000000000069',
    charge_declined_processing_error: '4000000000000119',
};

type PAYMENT_STATUS =
    | 'idle'
    | 'connecting'
    | 'connected'
    | 'waiting_for_payment'
    | 'processing'
    | 'completed'
    | 'error_reader_connection'
    | 'error_reader_disconnected'
    | 'error_min_amount'
    | 'error_invalid_amount'
    | 'error_card_declined'
    | 'error_server'
    | 'error_create_payment_intent'
    | 'error_collect_payment_intent'
    | 'error_capture_payment_intent';

type PAYMENT_STATUS_TYPE = 'default' | 'success' | 'error';

const isErrorResponse = (response: any): response is ErrorResponse => {
    return (response as ErrorResponse).error !== undefined;
};

interface TerminalPaymentModalProps {
    visitId: number;
    isVisible: boolean;
    isFetching: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    paymentInfoProps: PaymentInfoProps;
    estimateBalanceDue: number;
    invoiceBalanceDue: number;
    form: FormInstance;
}

export const TerminalPaymentModal = ({
    visitId,
    isVisible,
    isFetching,
    setIsVisible,
    paymentInfoProps,
    estimateBalanceDue,
    invoiceBalanceDue,
    form,
}: TerminalPaymentModalProps) => {
    const [finalAmountCents, setFinalAmountCents] = useState<number | null>(0);
    const [paymentStatus, setPaymentStatus] = useState<PAYMENT_STATUS>('idle');
    const [statusMessage, setStatusMessage] = useState('');
    const [statusType, setStatusType] = useState<PAYMENT_STATUS_TYPE>('default');
    const [terminal, setTerminal] = useState<Terminal | null>(null);
    const [discoveredReaders, setDiscoveredReaders] = useState<Reader[]>([]);
    const [selectedReader, setSelectedReader] = useState<Reader>();
    const [fetchConnectionTokenMutation] = useFetchConnectionTokenMutation();
    const [createPaymentIntentMutation, {data: createdPaymentIntent, error: errorCreatedPaymentIntent}] = useCreatePaymentIntentMutation();
    const [capturePaymentIntentMutation, {data: capturedPaymentIntent, isLoading: isLoadingCapturePaymentIntent, error: errorCapturedPaymentIntent}] = useCapturePaymentIntentMutation();
    const [saveWebCheckoutPaymentError] = useSaveWebCheckoutPaymentErrorMutation();
    const [getStripePaymentErrorsByVisit] = useLazyGetStripePaymentErrorsByVisitIdQuery();
    const [radioValue, setRadioValue] = useState<EstimateInvoiceRadioValues>('estimate');
    const balanceDue = radioValue === 'estimate' ? estimateBalanceDue : invoiceBalanceDue;

    useEffect(() => {
        if (isVisible) {
            initTerminal();
        }
    }, [isVisible]);

    useEffect(() => {
        if (terminal && createdPaymentIntent && createdPaymentIntent.client_secret) {
            if (SIMULATED_MODE) {
                let testCardNumber = getRandomTestCardNumber();
                terminal.setSimulatorConfiguration({ testCardNumber: testCardNumber });
            }
            terminal.collectPaymentMethod(createdPaymentIntent.client_secret).then(function(result) {
                if (isErrorResponse(result)) {
                    setPaymentStatus("error_collect_payment_intent");
                } else {
                    terminal.processPayment(result.paymentIntent).then(function(result) {
                        if (isErrorResponse(result)) {
                            setPaymentStatus("error_card_declined");
                            setStatusMessage(result.error.message);
                            if (result.error.payment_intent) {
                                saveWebCheckoutPaymentError({
                                    body: {
                                        payment_intent_id: result.error.payment_intent.id,
                                        last_error_amount_cents: result.error.payment_intent.amount,
                                        last_error_code: result.error.code ?? 'undefined_error',
                                        last_error_message: result.error.message ?? '',
                                        // result.error.payment_intent.last_payment_error.payment_method.card
                                        // doesn't exist in the response, probably we need to update Stripe to get the correct types
                                        //@ts-ignore
                                        card_brand: result.error.payment_method?.card_present?.brand ?? '',
                                        //@ts-ignore
                                        last_4_digits: result.error.payment_method?.card_present?.last4 ?? '',
                                    }
                                }).then(()=> {
                                    getStripePaymentErrorsByVisit(visitId);
                                });
                            }
                        } else if (result.paymentIntent) {
                            capturePaymentIntent(result.paymentIntent.id);
                        }
                    });
                }
            })
            .catch((error) => {
                setPaymentStatus("error_collect_payment_intent");
            });
        }
    }, [createdPaymentIntent]);

    useEffect(() => {
        if (errorCreatedPaymentIntent) {
            console.error('errorCreatedPaymentIntent', errorCreatedPaymentIntent);
            setPaymentStatus('error_create_payment_intent');
        }
    }, [errorCreatedPaymentIntent]);

    useEffect(() => {
        if (errorCapturedPaymentIntent) {
            console.error('errorCapturedPaymentIntent', errorCapturedPaymentIntent);
            setPaymentStatus('error_capture_payment_intent');
        }
    }, [errorCapturedPaymentIntent]);

    useEffect(() => {
        if (capturedPaymentIntent) {
            setPaymentStatus('completed');
        }
    }, [capturedPaymentIntent]);

    useEffect(() => {
        if (terminal) {
            discoverReaders();
        }
    }, [terminal]);

    useEffect(() => {
        if (terminal && selectedReader) {
            if (paymentStatus === 'connected') {
                terminal.disconnectReader();
            }
            setPaymentStatus('connecting');
        }
    }, [terminal, selectedReader]);

    useEffect(() => {
        if (terminal && selectedReader && paymentStatus === 'connecting') {
            terminal
                .connectReader(selectedReader)
                .then(function (connectResult) {
                    // make sure status is correct when the promise resolves
                    if (paymentStatus !== 'connecting') {
                        return;
                    }
                    if (!isErrorResponse(connectResult)) {
                        setPaymentStatus('connected');
                    } else {
                        setPaymentStatus('error_reader_connection');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setPaymentStatus('error_server');
                    setTerminal(null);
                    setSelectedReader(undefined);
                    setDiscoveredReaders([]);
                });
        }
    }, [paymentStatus]);

    useEffect(() => {
        switch (paymentStatus) {
            case 'idle':
                setStatusType('default');
                setStatusMessage('');
                break;
            case 'connecting':
                setStatusType('default');
                setStatusMessage('Connecting...');
                break;
            case 'connected':
                setStatusType('default');
                setStatusMessage('Connected');
                break;
            case 'waiting_for_payment':
                setStatusType('default');
                setStatusMessage('Waiting for payment...');
                break;
            case 'processing':
                setStatusType('default');
                setStatusMessage('Processing payment...');
                break;

            case 'completed':
                setStatusType('success');
                // setStatusMessage("Payment completed");
                setStatusMessage('');
                break;

            case 'error_reader_connection':
                setStatusType('error');
                setStatusMessage('Failed to connect terminal');
                break;
            case 'error_reader_disconnected':
                setStatusType('error');
                setStatusMessage('Disconnected from terminal');
                break;
            case 'error_create_payment_intent':
                setStatusType('error');
                setStatusMessage('Create payment intent error');
                break;
            case 'error_collect_payment_intent':
                setStatusType('error');
                setStatusMessage('Collect payment intent error');
                break;
            case 'error_capture_payment_intent':
                setStatusType('error');
                setStatusMessage('Capture payment intent error');
                break;
            case 'error_server':
                setStatusType('error');
                setStatusMessage('Server error');
                break;
            case 'error_min_amount':
                setStatusType('error');
                setStatusMessage('Amount must be at least $0.50');
                break;
            case 'error_invalid_amount':
                setStatusType('error');
                setStatusMessage('Invalid amount');
                break;
            case 'error_card_declined':
                setStatusType('error');
                break;
        }
    }, [paymentStatus]);

    const unexpectedReaderDisconnect = () => {
        setSelectedReader(undefined);
        setDiscoveredReaders([]);
        setPaymentStatus('error_reader_disconnected');
    };

    const discoverReaders = () => {
        if (!terminal) {
            return;
        }
        var config = { simulated: SIMULATED_MODE };
        terminal.discoverReaders(config).then(function (discoverResult: DiscoverResult | ErrorResponse) {
            if (!isErrorResponse(discoverResult)) {
                if (discoverResult.discoveredReaders.length > 0) {
                    if (SIMULATED_MODE) {
                        // TEST add two simulated readers for testing
                        let multipleReaders: Reader[] = [...discoverResult.discoveredReaders, { ...discoverResult.discoveredReaders[0] }];
                        setDiscoveredReaders(multipleReaders);
                    } else {
                        setDiscoveredReaders(discoverResult.discoveredReaders);
                    }
                    setSelectedReader(discoverResult.discoveredReaders[0]);
                } else {
                    setPaymentStatus('error_reader_connection');
                    setSelectedReader(undefined);
                    setDiscoveredReaders([]);
                }
            } else {
                setPaymentStatus('error_reader_connection');
                setSelectedReader(undefined);
                setDiscoveredReaders([]);
            }
        });
    };

    const initTerminal = async () => {
        setPaymentStatus('idle');
        setSelectedReader(undefined);
        setDiscoveredReaders([]);
        if (terminal) {
            terminal.disconnectReader();
        }
        const stripeTerminal = await loadStripeTerminal();
        if (stripeTerminal) {
            setTerminal(
                stripeTerminal.create({
                    onFetchConnectionToken: async () => {
                        return await fetchConnectionTokenMutation({ visitId: visitId }).unwrap();
                    },
                    onUnexpectedReaderDisconnect: unexpectedReaderDisconnect,
                }),
            );
        }
    };

    const canCollectPayment = () => {
        switch (paymentStatus) {
            case 'connected':
            case 'completed':
            case 'error_card_declined':
            case 'error_min_amount':
            case 'error_invalid_amount':
            case 'error_create_payment_intent':
            case 'error_collect_payment_intent':
            case 'error_capture_payment_intent':
                return true;
        }
        return false;
    };

    // captures the payment that is collected by the collectPaymentMethod call
    const capturePaymentIntent = (paymentIntentId: string) => {
        setPaymentStatus('processing');
        capturePaymentIntentMutation({
            visitId: visitId,
            body: { payment_intent_id: paymentIntentId }
        }).then(() => {
            getStripePaymentErrorsByVisit(visitId);
        });
    };

    const onCancel = () => {
        // payment is being processed by Stripe so you cannot cancel anymore
        if (paymentStatus === 'processing') {
            return;
        }
        setIsVisible(false);
        setFinalAmountCents(null);
        if (terminal) {
            if (paymentStatus === 'waiting_for_payment') {
                terminal.cancelCollectPaymentMethod();
            }
        }
        setSelectedReader(undefined);
        setDiscoveredReaders([]);
    };

    const disableInput = () => {
        if (!terminal || discoveredReaders.length === 0) {
            return true;
        }
        if (paymentStatus === 'connecting' || paymentStatus === 'waiting_for_payment' || paymentStatus === 'processing') {
            return true;
        }
        return false;
    };

    const getStatusColor = () => {
        if (statusType === 'success') {
            return '#008000';
        }
        if (statusType === 'error') {
            return '#f00';
        }
        return 'inherit';
    };

    // TEST gets a random simulated test card number, which returns a success or different error cases
    const getRandomTestCardNumber = () => {
        if (Math.random() < 0.5) {
            let rnd = Math.random();
            if (rnd < 0.33) return testCards.visa;
            if (rnd < 0.66) return testCards.mastercard;
            return testCards.visa_debit;
        }
        let rnd = Math.floor(Math.random() * 6);
        switch (rnd) {
            case 0:
                return testCards.charge_declined;
                break;
            case 1:
                return testCards.charge_declined_insufficient_funds;
                break;
            case 2:
                return testCards.charge_declined_lost_card;
                break;
            case 3:
                return testCards.charge_declined_stolen_card;
                break;
            case 4:
                return testCards.charge_declined_expired_card;
                break;
            default:
                return testCards.charge_declined_processing_error;
                break;
        }
    };

    const ModalContent = () => {
        return (
            <>
                {paymentStatus === 'completed' && (
                    <p style={{ marginBottom: 0 }}>Payment of {formatCurrency(finalAmountCents || 0)} is received and saved.</p>
                )}
                {paymentStatus !== 'completed' && (
                    <>
                        <EstimateInvoiceRadio radioValue={radioValue} setRadioValue={setRadioValue} />
                        <PaymentInfo {...paymentInfoProps} mode={radioValue} />
                        {balanceDue > 0 && (
                            <Row>
                                <Col span={24}>
                                    <TerminalForm />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </>
        );
    };

    const ModalFooter = () => {
        if (balanceDue <= 0) {
            return (
                <Button disabled={paymentStatus === 'processing'} onClick={onCancel}>
                    Cancel
                </Button>
            );
        }
        return (
            <div style={{ visibility: isFetching ? 'hidden' : 'visible' }}>
                <Col key={'buttons'} span={24} style={{ padding: '0 8px' }}>
                    <Row gutter={[4, 0]} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ color: getStatusColor() }}>{statusMessage}</div>
                        <div style={{ marginLeft: 'auto' }}>
                            {paymentStatus === 'completed' && (
                                <Button type='primary' autoFocus onClick={onCancel}>
                                    Close
                                </Button>
                            )}
                            {paymentStatus !== 'completed' && (
                                <>
                                    <Button disabled={paymentStatus === 'processing'} onClick={onCancel}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type='primary'
                                        autoFocus={canCollectPayment()}
                                        disabled={!canCollectPayment()}
                                        onClick={form.submit}
                                        loading={isLoadingCapturePaymentIntent}
                                    >
                                        Send
                                    </Button>
                                </>
                            )}
                        </div>
                    </Row>
                </Col>
            </div>
        );
    };

    const TerminalForm = () => {
        return (
            <Col span={24}>
                <Row align='middle' justify='start' gutter={[4, 0]}>
                    <FormWrapper
                        form={form}
                        onFormChange={form.setFieldsValue}
                        onFinishFailed={() => {}}
                        onFinish={(values: any) => {
                            const amountCents = roundTo(parseFloat(values.amount.replaceAll(',', '')) * 100, 0);
                            setFinalAmountCents(amountCents);
                            setPaymentStatus('waiting_for_payment');
                            createPaymentIntentMutation({
                                visitId: visitId,
                                body: {
                                    amount_cents: amountCents,
                                },
                            });
                        }}
                        bottomBar={<></>}
                    >
                        <Col span={24}>
                            {/* TODO: payment inputs in all payment modals can be combined into one component */}
                            <Form.Item
                                name='amount'
                                label='Amount'
                                className='payment-amount-input'
                                style={{ width: '100%' }}
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                initialValue={formatCurrency(finalAmountCents || balanceDue).replace('$', '')}
                                validateFirst={true}
                                rules={[
                                    { required: true, message: 'Please enter an amount' },
                                    { pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: 'Invalid amount' },
                                    () => ({
                                        validator(_, value: string) {
                                            return validatePaymentAmountInput(value, balanceDue, true);
                                        },
                                    }),
                                ]}
                            >
                                <Input addonBefore={'$'} disabled={disableInput()} style={{ textAlign: 'right' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='reader'
                                label='Reader'
                                className='terminal-reader-select'
                                style={{ width: '100%', paddingTop: '16px' }}
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                rules={[]}
                                initialValue={0}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    disabled={disableInput()}
                                    onChange={(value: number) => {
                                        if (discoveredReaders) {
                                            setSelectedReader(discoveredReaders[value]);
                                        }
                                    }}
                                >
                                    {discoveredReaders.length === 0 && (
                                        <Option key={'none'} value={0}>
                                            No terminal
                                        </Option>
                                    )}
                                    {discoveredReaders?.map((item, index) => {
                                        return (
                                            <Option key={index} value={index} selected={true}>
                                                {item.label}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </FormWrapper>
                </Row>
            </Col>
        );
    };

    return (
        <>
            <Modal centered={true} open={isVisible} title={'Send to Credit Card Terminal'} onCancel={onCancel} footer={<ModalFooter />}>
                <Spin spinning={isFetching}>
                    <div style={{ visibility: isFetching ? 'hidden' : 'visible' }}>
                        <ModalContent />
                    </div>
                </Spin>
            </Modal>
        </>
    );
};
