import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { PimsUser } from 'utils/dataTypes';
import { NonMedicalEstimateFormFields } from 'utils/types/billingTypes';
import { isInstanceOfPackageNonMedInstruction, NonMedicalSearchOption, PackageNonMedInstruction } from 'utils/types/InstructionOrderTypes';
import { NonMedicalEstimateRules } from 'utils/types/validations';
import { validateFieldIsGreaterThan, validateFieldIsNotDecimal } from 'utils/validationFuncs';
import { HiddenInput } from '../fields/HiddenInput';

export const getNonMedicalEstimateFormDefaults = (dataSource: NonMedicalSearchOption, user?: PimsUser): NonMedicalEstimateFormFields => {
    return {
        non_medical_id: dataSource.id,
        is_free: false,
        is_refund: false,
        type_id: 'N',
        unit: 'unit',
        name: dataSource.name,
        quantity: 1,
        unit_cost_cents: dataSource.cents,
        reason: null,
        supplemental_cost_cents: null,
        is_recurring_supplemental: null,
    };
};

export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';
interface NonMedicalEstimateFormProps {
    dataSource: NonMedicalSearchOption;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
}

export const NonMedicalEstimateForm = (props: NonMedicalEstimateFormProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const { dataSource } = props;

    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 18 };
    const unitIsDollar = props.dataSource.unit === 'dollar';

    const onFormChange = props.onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

    useEffect(() => {
        const defaultDiagnostic = getNonMedicalEstimateFormDefaults(dataSource, loggedInUserData);
        onFormChange(defaultDiagnostic);
    }, [dataSource]);

    const nonMedicalEstimateRules: NonMedicalEstimateRules = {
        quantity: [
            {
                required: true,
            },
            {
                validator: validateFieldIsGreaterThan('quantity', 0),
            },
            {
                validator: validateFieldIsNotDecimal('quantity'),
            },
        ],
    };
    return (
        <>
            <Form.Item
                preserve={false}
                name='quantity'
                label={unitIsDollar ? 'Amount' : 'Quantity'}
                style={{
                    width: '100%',
                }}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                rules={nonMedicalEstimateRules.quantity}
                data-cy={'quantityInput'}
            >
                <Input
                    autoFocus
                    prefix={unitIsDollar ? '$' : undefined}
                    style={{
                        width: '100%',
                    }}
                />
            </Form.Item>
            <HiddenInput fieldName='non_medical_id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='unit_cost_cents' />
            <HiddenInput fieldName='unit' />
            <HiddenInput fieldName='is_free' />
            <HiddenInput fieldName='reason' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='supplemental_cost_cents' />
            <HiddenInput fieldName='is_recurring_supplemental' />
        </>
    );
};
