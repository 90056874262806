import { Col, Row } from 'antd';
import { CSSProperties } from 'react';
import { useGetInstructionOptionsQuery } from 'services/instructionService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import {
	ExistingCriInstruction,
	ExistingDiagInstruction,
	ExistingFluidInstruction,
	ExistingMedInstruction,
	ExistingOxygenTherapyInstruction,
	ExistingToGoMedInstruction,
	isInstanceOfCRIInstruction,
	isInstanceOfFluidsInstruction,
	isInstanceOfMedicineInstruction,
	TreatmentSheetInstructionAction,
} from 'utils/dataTypes';
import { roundTo, weightMultiplier } from 'utils/formatFuncs';
import { getMedicationConcentration } from 'utils/miscFuncs';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import { formatFluidAdditiveNameList } from 'views/visit/TreatmentSheet';


const getMedicationDose = (
    medInstruction: ExistingMedInstruction,
    medication: MedicineSearchOption,
    patientWeight: number,
) => {
    const medConcentration = getMedicationConcentration(medication);

    if (medication?.numerator_unit === medInstruction.dose_unit) {
        return (
            roundTo(medInstruction.dose / (patientWeight as number), 3) +
            ' ' +
            medication.numerator_unit +
            '/kg'
        );
    } else if (
        medication?.denominator_unit === medInstruction.dose_unit &&
        medConcentration
    ) {
        return (
            roundTo(
                (medInstruction.dose * medConcentration) /
                    (patientWeight as number),
                3,
            ) +
            ' ' +
            medication.numerator_unit +
            '/kg'
        );
    }
};

interface InstructionDetailsProps {
	instruction:
		| ExistingMedInstruction
		| ExistingFluidInstruction
		| ExistingCriInstruction
		| ExistingDiagInstruction
		| ExistingToGoMedInstruction
		| ExistingOxygenTherapyInstruction;
	action?: TreatmentSheetInstructionAction;
    patientWeight?: number;
}

const InstructionDetails = ({ instruction, action, patientWeight }: InstructionDetailsProps) => {
	const { data: instructionOptions } = useGetInstructionOptionsQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const formItemStyle: CSSProperties = {
		width: '100%',
	};
	const valueColumnStyle: CSSProperties = {
		fontWeight: 500,
	};
	const DEFAULT_LABEL_SPAN = 6;
	const DEFAULT_VALUE_SPAN = 18;

	const getInstructionNoteComponent = (note: string) => {
		return note ? (
			<Row style={formItemStyle}>
				<Col span={DEFAULT_LABEL_SPAN}>Instructions:</Col>
				<Col span={DEFAULT_VALUE_SPAN}>{note}</Col>
			</Row>
		) : null;
	};

	if (isInstanceOfMedicineInstruction(instruction)) {
		const medication = instructionOptions?.find(
			(m: any) => m.type_id === 'M' && m.id === instruction.medication_id,
		) as MedicineSearchOption;
		const localWeight =
            patientWeight ??
			instruction.approx_patient_weight_kg ??
			instruction.latest_patient_weight_kg;

		return (
			<>
				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Total dose:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{`${roundTo(instruction.dose, 3)} ${
							instruction.dose_unit
						}`}
					</Col>
				</Row>

				{medication.calculator_type === 'complex' && localWeight && (
					<Row style={formItemStyle}>
						<Col span={DEFAULT_LABEL_SPAN}>Current Dose:</Col>
						<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
							{getMedicationDose(
								instruction,
								medication,
								localWeight,
							)}
						</Col>
					</Row>
				)}

				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Route:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{instruction.route_id}
					</Col>
				</Row>

				{getInstructionNoteComponent(instruction.notes)}
			</>
		);
	}

	if (isInstanceOfCRIInstruction(instruction)) {
		if (action && action.value) {
			return (
				<Row style={{width: "100%"}}>
					<Col span={6}>
						Action Request:
					</Col>
					<Col span={18}>
						<h4>{`Change fluid rate to ${action.value} ml/hr`}</h4>
					</Col>
				</Row>
			)
		}
		return (
			<>
				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Fluid rate:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{`${roundTo(instruction.initial_rate_ml_per_hr, 3)} ml/hr`}
					</Col>
				</Row>

				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Current Dose:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{instruction.default_cri_unit === 'mcg/kg/min'
							? `${roundTo((instruction.dose / 60 * 1000) * weightMultiplier(instruction, patientWeight), 3)} ${instruction.default_cri_unit}`
							: `${roundTo(instruction.dose * weightMultiplier(instruction, patientWeight), 3)} ${instruction.dose_unit}`}
					</Col>
				</Row>

				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Route:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						CRI
					</Col>
				</Row>

				{getInstructionNoteComponent(instruction.notes)}
			</>
		);
	}

	if (isInstanceOfFluidsInstruction(instruction)) {
		if (action && action.value) {
			return (
				<Row style={{width: "100%"}}>
					<Col span={6}>
						Action Request:
					</Col>
					<Col span={18}>
						<h4>{`Change fluid rate to ${action.value} ml/hr`}</h4>
					</Col>
				</Row>
			)
		}
		return (
			<>
				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Fluid rate:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{`${roundTo(instruction.initial_rate_ml_per_hr, 3)} ml/hr`}
					</Col>
				</Row>

				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Bag volume:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{`${roundTo(instruction.fluids_volume_ml, 3)} ml`}
					</Col>
				</Row>

				<Row style={formItemStyle}>
					<Col span={DEFAULT_LABEL_SPAN}>Route:</Col>
					<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
						{instruction.route_id}
					</Col>
				</Row>
				{instruction.fluid_additives.filter(additive => additive.initial_order).length > 0 &&
					<Row style={formItemStyle}>
						<Col span={DEFAULT_LABEL_SPAN}>Initial Additives:</Col>
						<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
							{formatFluidAdditiveNameList(
								instruction.fluid_additives.filter(additive => additive.initial_order),
								true
							)}
						</Col>
					</Row>
				}

				{getInstructionNoteComponent(instruction.notes)}
			</>
		);
	}

	return getInstructionNoteComponent(instruction.notes);
};

export default InstructionDetails;
