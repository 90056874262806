import { Form, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CSSProperties, useState, useRef } from "react";
import { ExistingCriInstruction, ExistingMedInstruction } from "utils/dataTypes";
import { EnrichedCriSearchOption, MedicineSearchOption } from "utils/types/InstructionOrderTypes";
import { CriOrder } from "./CriOrder";
import { MedicineOrder } from "./MedicineOrder";

const formItemStyle: CSSProperties = {
	width: '100%',
};

interface WrapperOrderProps {
	options: MedicineSearchOption;
	existingInstruction?: Partial<ExistingMedInstruction> | Partial<ExistingCriInstruction>;
	patientWeight?: number;
	onFormChange?: Function; //not actually optional -- always sent via FormWrapper
	getFormData?: Function; //not actually optional -- always sent via FormWrapper
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	defaultCri?: boolean;
	fromEstimate?: boolean;
}

export const MedCriOrder = (props: WrapperOrderProps) => {
	const { options, defaultCri, patientWeight, ...passThroughProps } = props;
	const [isCRI, setIsCRI] = useState(defaultCri ?? false)
	const [medForm] = useForm();
	const [criForm] = useForm();
	const CriOptions: EnrichedCriSearchOption = {
		...options,
		fluid: undefined,
		medication: options,
		default_rate_ml_per_hr: null,
		fluids_id: null,
		fluids_volume_ml: 0,
		medication_id: options.id,
		medication_name: options.name,
		type_id: "C"
	}
	const { calculator_type } = options
	return (
		<>
			{calculator_type !== 'simple' && calculator_type !== 'prepackaged' && defaultCri === undefined && (
				<Form.Item
					label={'CRI'}
					style={formItemStyle}
					labelCol={props.labelCol}
					wrapperCol={props.wrapperCol}
				>
					<Switch onChange={setIsCRI} />
				</Form.Item>
			)}
			{
				isCRI
					? <CriOrder {...passThroughProps}
                        patientWeight={patientWeight}
						options={CriOptions}
						existingInstruction={props.existingInstruction as Partial<ExistingCriInstruction>}
						form={criForm}
					/>
					: <MedicineOrder {...passThroughProps}
                        patientWeight={patientWeight}
						options={options}
						existingInstruction={props.existingInstruction as Partial<ExistingMedInstruction>}
						form={medForm}
					/>
			}
		</>
	)
}