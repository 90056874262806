import { Button, Collapse, Divider, Drawer, Form, FormInstance, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DrawerBoolGroup } from 'components/drawers';
import { TriageLevelGroup } from 'components/popups/LevelPopover';
import { CSSProperties, useEffect, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { useModifyVisitIntakeWithInitialVitalsMutation } from 'services/vetDashService';
import { drawerSize } from 'styles/drawer';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { TRIAGE_LEVELS, VetDashboardVisit } from 'utils/dataTypes';
import { USER_PERMISSIONS, checkIfUserHasNecessaryPermission } from 'utils/userPermissions';
import useWindowDimensions from 'utils/window';
import { InitialVitals } from './InitialVitals';

const formItemStyle: CSSProperties = {
    width: '100%',
    marginBottom: '.8rem',
};

interface IntakeInfoDrawerProps {
    visit: VetDashboardVisit;
    form: FormInstance;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    triageLevel: TRIAGE_LEVELS;
    setTriageLevel: React.Dispatch<React.SetStateAction<TRIAGE_LEVELS>>;
}

export const IntakeInfoDrawer = ({ visit, form, open, setOpen, triageLevel, setTriageLevel }: IntakeInfoDrawerProps) => {
    const { width } = useWindowDimensions();
    const [modifyVisitIntakeWithInitialVitalsMutation, { isLoading: isIntakeMutationLoading }] = useModifyVisitIntakeWithInitialVitalsMutation();
    const formVal = form.getFieldValue;
    const isIntakeFormPristine = () => {
        return (
            visit.triage_level === triageLevel &&
            ((formVal('spike_3') === true && !!visit.spiked_by) || (formVal('spike_3') === false && !visit.spiked_by)) &&
            formVal('reason_for_visit') === visit.reason_for_visit
        );
    };
    const getIsDisabled = () => {
        return isIntakeFormPristine() && !form.isFieldsTouched();
    };
    const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
    const userHasPermissionToAssign = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.visit_claim,
	);

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    const onFormSave = () => {
        modifyVisitIntakeWithInitialVitalsMutation({ visit_id: visit.visit_id, intakeForm: {
            ...form.getFieldsValue(),
            triage_level: triageLevel,
            should_update_intake: !isIntakeFormPristine(),
        }})
            .unwrap()
            .then(() => {
                setOpen(false);
                message.success('Successfully updated visit intake');
            })
            .catch((err: string) => message.error(err));
    };

    const [isDisabled, setIsDisabled] = useState(getIsDisabled()); 

    useEffect(() => {
        setIsDisabled(getIsDisabled());
    }, [triageLevel, visit])

    return (
        <Drawer
            title={visit?.owner_last_name ? `${visit?.pet_name} (${visit?.owner_last_name})` : visit?.pet_name}
            className='veg-drawer intake-info-drawer'
            placement='right'
            closable={true}
            width={width >= 540 ? drawerSize.md : drawerSize.extended}
            onClose={handleCloseDrawer}
            open={open}
            key='intake_info_drawer'
            destroyOnClose={true}
            mask={true}
            maskClosable={true}
            footer={
                <>
                    <Button onClick={handleCloseDrawer}>Cancel</Button>
                    <Button
                        className='intake-info-drawer-submit-button'
                        type='primary'
                        htmlType='submit'
                        disabled={isDisabled}
                        onClick={onFormSave}
                        loading={isIntakeMutationLoading}
                    >
                        Save
                    </Button>
                </>
            }
        >
            <Form form={form} labelAlign='left' onFieldsChange={() => {setIsDisabled(getIsDisabled());}}>
                <Collapse ghost bordered={false} className='veg-drawer-collapse' defaultActiveKey={['triage', 'initial_vitals']}>
                    <Collapse.Panel
                        className='veg-drawer-panel'
                        style={{ marginTop: '-12px' }}
                        key='triage'
                        header={<Divider orientation='left'>Triage</Divider>}
                    >
                        <label>Reason:</label>
                        <Form.Item name='reason_for_visit' style={formItemStyle}>
                            <TextArea allowClear />
                        </Form.Item>

                        <label>Spike 3:</label>
                        <Form.Item name='spike_3' style={formItemStyle}>
                            <DrawerBoolGroup disabled={!userHasPermissionToAssign} />
                        </Form.Item>

                        <label>Status:</label>
                        <TriageLevelGroup
                            triageLevel={triageLevel}
                            setTriageLevel={setTriageLevel}
                            gutter={8}
                            showTick={true}
                            style={{ paddingTop: '8px' }}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel
                        className='veg-drawer-panel'
                        key='initial_vitals'
                        header={<Divider orientation='left'>Initial Vitals</Divider>}
                    >
                        <>
                            <InitialVitals visitId={visit.visit_id} form={form} isOpen={open} />
                        </>
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </Drawer>
    );
};
