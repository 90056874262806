import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SortAscendingNonAlphaOutlinedSvg = () => (
    <svg viewBox="0 0 20 20" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8688 16.6077C14.8844 16.626 14.9043 16.6408 14.927 16.651C14.9498 16.6612 14.9747 16.6665 15 16.6665C15.0253 16.6665 15.0502 16.6612 15.073 16.651C15.0957 16.6408 15.1156 16.626 15.1312 16.6077L17.4634 13.8892C17.5508 13.7877 17.4717 13.6401 17.3322 13.6401L15.7496 13.6401L15.7496 3.48643C15.7496 3.40214 15.6747 3.33317 15.583 3.33317L14.417 3.33317C14.3253 3.33317 14.2504 3.40214 14.2504 3.48643L14.2504 13.6401L12.6678 13.6401C12.5283 13.6401 12.4492 13.7896 12.5366 13.8892L14.8688 16.6077Z"/>
        <rect x="2.5" y="14.9999" width="9.16667" height="1.25" rx="0.25"/>
        <rect x="2.5" y="9.1665" width="7.5" height="1.25" rx="0.25"/>
        <rect x="2.5" y="3.33325" width="4.16667" height="1.25" rx="0.25"/>
    </svg>
);

const SortDescendingNonAlphaOutlinedSvg = () => (
    <svg viewBox="0 0 20 20" width="1em" height="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1312 3.39206C15.1156 3.37374 15.0957 3.35892 15.073 3.34874C15.0502 3.33855 15.0253 3.33325 15 3.33325C14.9747 3.33325 14.9498 3.33855 14.927 3.34874C14.9043 3.35892 14.8844 3.37374 14.8688 3.39206L12.5366 6.11057C12.4492 6.2121 12.5283 6.35962 12.6678 6.35962H14.2504V16.5133C14.2504 16.5976 14.3253 16.6666 14.417 16.6666H15.583C15.6747 16.6666 15.7496 16.5976 15.7496 16.5133V6.35962H17.3322C17.4717 6.35962 17.5508 6.21019 17.4634 6.11057L15.1312 3.39206Z"/>
        <rect x="2.5" y="3.33325" width="9.16667" height="1.25" rx="0.25"/>
        <rect x="2.5" y="9.1665" width="7.5" height="1.25" rx="0.25"/>
        <rect x="2.5" y="14.9999" width="4.16667" height="1.25" rx="0.25"/>
    </svg>
);

export const SortAscendingNonAlphaOutlined = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={SortAscendingNonAlphaOutlinedSvg} {...props} />
);

export const SortDescendingNonAlphaOutlined = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={SortDescendingNonAlphaOutlinedSvg} {...props} />
);
