import { VISIT_AR_STATUSES, VISIT_STATUSES } from './dataTypes';



const getendPoints = (webHost: string,appEnv?: string, appApiUrl?: string, adminUrl?: string): {endpoint?: string, adminEndpoint?: string} => {
    let dogbyte_env: string | undefined = undefined
    const isGreen = host.includes('green');
    const isBlue = host.includes('blue');
    let endpointLocal = appApiUrl;
    let adminEndpointLocal = adminUrl;

    if (REACT_APP_ENV === 'local' || !appEnv){
        endpointLocal = appApiUrl;
        adminEndpointLocal = adminUrl;

    } else if (REACT_APP_ENV === 'prd'){
        dogbyte_env = webHost?.split('.')?.[0]?.split('www-')?.[1];
        if (dogbyte_env){
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + dogbyte_env + '.' + REACT_APP_ADMIN_URL;
        }
        else if (isGreen) {
            endpointLocal = 'https://' + 'api-' + 'green' + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + 'green' + '.' + REACT_APP_ADMIN_URL;
        } else if (isBlue) {
            endpointLocal = 'https://' + 'api-' + 'blue' + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + 'blue' + '.' + REACT_APP_ADMIN_URL;
        } else {
            endpointLocal = 'https://' + 'api' + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin' + '.' + REACT_APP_ADMIN_URL;
        }

    } else {
        dogbyte_env = webHost?.split('.')?.[0]?.split('www-')?.[1];
        if (dogbyte_env){
            endpointLocal = 'https://' + 'api-' + dogbyte_env + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + dogbyte_env + '.' + REACT_APP_ADMIN_URL;
        }
        else if (isGreen) {
            endpointLocal = 'https://' + 'api-' + 'green-' + REACT_APP_ENV + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + 'green-' + REACT_APP_ENV + '.' + REACT_APP_ADMIN_URL;
        } else if (isBlue) {
            endpointLocal = 'https://' + 'api-' + 'blue-' + REACT_APP_ENV + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + 'blue-' + REACT_APP_ENV + '.' + REACT_APP_ADMIN_URL;
        } else {
            endpointLocal = 'https://' + 'api-' + REACT_APP_ENV + '.' + REACT_APP_API_URL;
            adminEndpointLocal = 'https://' + 'admin-' + REACT_APP_ENV + '.' + REACT_APP_ADMIN_URL;
        }

    }

    return {
        endpoint: endpointLocal,
        adminEndpoint: adminEndpointLocal
    }

}


const host = window.location.host;
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;

const {endpoint, adminEndpoint} = getendPoints(host, REACT_APP_ENV, REACT_APP_API_URL, REACT_APP_ADMIN_URL)

export const API_ENDPOINT = endpoint;
export const PIMS_HOST = `${endpoint}/pims`;
export const AUTH_HOST = `${endpoint}/auth`;
export const INSTRUCTION_HOST = `${endpoint}/instruction`;
export const DEV_STASH_HOST = `${endpoint}/stash`;
export const CONSENT_HOST = `${endpoint}/consent`;
export const TASKS_HOST = `${endpoint}/tasks`;
export const ADMIN_URL = `${adminEndpoint}`;

export const LOCAL_STORAGE_USER_KEY = 'veg_user';
export const TOKEN_EXPIRE_MINUTES = 1440;
//export const TOKEN_EXPIRE_MINUTES = 5;

export const BASE_PIMS_REQUEST: RequestInit = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
    credentials: 'include' as RequestCredentials,
};

export const BASE_QUERY_OPTIONS = {
    pollingInterval: 60000000, //1000 minutes
    //pollingInterval: 5000,
};

/**
 * TODO
 * SOMETHING BETTER THAN THIS HARDCODED NUMBER WHICH WILL EVENTUALLY BREAK
 */
export const TEMP_ENCOUNTER_ROW_ID = 10001;

export const MY_VET_IS_NOT_ON_THE_LIST = 'My Vet Is Not On the List';

/**
 * The `value` is displayed as the `title` of the compose box
 * We also use it to determine how to handle form values after submissionxs
 * ^^ just a big if-else block.
 */
export enum FormName {
    med_order = 'Medicine instruction',
    diag_order = 'Diagnostic instruction',
    task_order = 'Task instruction',
    fluid_order = 'Fluid instruction',
    oxygen_therapy_order = 'Oxygen therapy instruction',
    non_medical_order = 'NonMedical Order',
    order_from_estimate = 'Order instruction from estimate',
    non_medical_order_from_estimate = 'NonMedical Order instruction from estimate',
    med_admin = 'Administer Medication',
    togo_med = 'Take-home medication',
    off_sch_med_admin = 'Off-schedule medication administration',
    discon_inst = 'Discontinue instruction',
    owner_info = 'Owner Info',
    owner_note = 'Owner Note',
    patient_info = 'Patient Info',
    rdvm_info = 'rDVM Info',
    add_complaint = 'Add complaint',
    detailed_add_multi_complaint = 'Add multiple complaints',
    edit_problem = 'Problems and Differentials',
    delete_problem = 'Delete problem',
    physical_exam = 'Physical exam',
    merge_patients = 'Possible duplicate patients found',
    initial_estimate_created = 'Initial Estimate Created',
    initial_estimate_approved = 'Initial Estimate Approved',
    pending_orders_placed = 'All New Approved Orders Placed',
    discharge_patient = 'Discharge patient', //First "form" for Visit `status` change
    close_chart = 'Close & lock chart',
    reopen_chart = 'Reopen chart',

    prog_note = 'Progress/Soap Note',
    recommendation_note = 'Recommendation Note',
    procedure_note = 'Procedure Note',
    communication_note = 'Communication Note',
    addendum_note = 'Addendum Note',
    discharge_note = 'Discharge Note',

    patient_history = 'Patient History',
    rdvm_note = 'Add rDVM Description',
    edit_note = 'Edit Note',

    ivls_confirm = 'IVLS confirm order',
    adjust_fluid_rate = 'Adjust Fluid Rate',
    discharge_togo_med = 'Discharge Togo Med',
    fluid_additive_form = 'Fluid Additive Form',
}

/**
 * Object `key` is current Visit status
 * Object `value` is an array of valid status transitions
 */
export const VISIT_STATUS_TRANSITION_MAP = {
    enroute: ['arrived', 'noshow'],
    noshow: [] as string[],
    arrived: ['inprogress'],
    inprogress: ['hospitalized', 'discharged'],
    hospitalized: ['discharged'],
    discharged: [] as string[],
    checked_out: [] as string[],
};

export const VISIT_STATUS_OPTIONS: Array<VISIT_STATUSES> = [
    'enroute',
    'arrived',
    'noshow',
    'inprogress',
    'hospitalized',
    'discharged',
    'checked_out',
];

export const AR_STATUS_OPTIONS: Array<VISIT_AR_STATUSES> = ['awaiting_payment', 'sent_to_collections', 'uncollectible'];

export const EVENT_TYPES = {
    VITAL: 'vital',
    NOTE: 'note',
    EXAM: 'exam',
};
interface PHYSICAL_EXAM_VALUES_INTERFACE {
    NORMAL: 'normal';
    ABNORMAL: 'abnormal';
    NOT_OBSERVED: null;
}
export const PHYSICAL_EXAM_VALUES: PHYSICAL_EXAM_VALUES_INTERFACE = {
    NORMAL: 'normal',
    ABNORMAL: 'abnormal',
    NOT_OBSERVED: null,
};

export const VITAL_DIAGNOSTIC_IDS = {
    1: 'temperature',
    2: 'heart rate',
    3: 'respiratory rate',
    4: 'weight',
};

export const VISIT_TABS_LINKS = {
    missionControl: 'mission_control',
    progressNotes: 'progress_notes',
    timeline: 'timeline',
    treatmentSheet: 'treatment_sheet',
    discharge: 'discharge',
    billing: 'billing',
    documents: 'documents',
    estimates: 'estimates',
};

export const ALL_INSTRUCTIONS = [
    {
        id: `M`,
        title: 'Medications',
        isRoot: true,
    },
    {
        id: `D`,
        title: 'Diagnostics',
        isRoot: true,
    },
    {
        id: 'C',
        title: 'CRI, Fluids, O2',
        isRoot: true,
    },
    { id: 'Other', title: 'Other', isRoot: true },
    {
        id: 'E',
        title: 'Doctor Hours',
        isRoot: true,
    },
    {
        id: 'T',
        title: 'Tasks',
        isRoot: true,
    },
    {
        id: 'TGH',
        title: 'To go medication',
        isRoot: true,
    },
];

export const TS_POPOVER_PARENT_IDS = [ALL_INSTRUCTIONS[0].id, ALL_INSTRUCTIONS[1].id, ALL_INSTRUCTIONS[2].id];

export const TREATMENT_SHEET_GROUPS = ALL_INSTRUCTIONS;

export const TS_ITEM_COLORS = {
    dueBgColor: 'var(--veg-tint-gold)',
    schedBgColor: 'var(--veg-tint-plum)', //if orderStatus is `null` AND `due_at` is not in the past
    missedColor: 'var(--veg-secondary-red)', //if orderStatus is `missed` or `null` AND `due_at` is in the past
    inProgressColor: 'var(--veg-blue)', //if orderStatus is `inprogress`
    earlyBgColor: `repeating-linear-gradient(
		45deg,
		#7a8af7,
		white 5px,
		#7a8af7 10px,
		white 5px
	  )`, // if order null and due_at is at least 1 hour in the future
    skippedColor: 'var(--veg-green-gray)', //if orderStatus is `skipped`
    bgColor: 'var(--veg-green)', //default color, if orderStatus is `given`
};

export const TREATMENT_SHEET_ITEM = {
    dueBgColor: '#FFE58F',
    schedBgColor: '#FFFBE6',
    bgColor: 'white',
    title: '',
};

/**
 * Represents the width of a cell in minutes
 * Set assuming an 8 hour width viewport
 */
export const TS_ITEM_TIME_WIDTH = 15;

export const FALLBACK_MED_ROUTES = ['PO', 'IM', 'IV', 'SQ'] as const;
export const FALLBACK_FLUID_ROUTES = ['IV', 'IO'] as const;
export const FALLBACK_MED_FREQUENCY = ['q1h', 'q3h'];

/*
 Never Change this value without first consulting the rest of the team this will break many systems if the pims cannot find the correct weight.
*/
export const PATIENT_WEIGHT_INSTRUCTION_NAME = 'Weight' as const;

export const DEFAULT_FLUID_OFFSET_HOURS = 24;

export const SECS_IN_STATUS_THRESHOLD = 600;

export const VET_LANDING_TABLE_TITLE = {
    calls: 'Inbound calls - last 12 hours',
    unassigned: 'Waiting Room / Unassigned',
    hospitalized: 'Hospitalized',
    inprogress: 'In-progress',
    discharged: 'Discharged',
    checked_out: 'Checked Out',
};

export const PET_SEX = {
    F: 'Female',
    M: 'Male',
};

export const ORDER_INSTRUCTION_NOUNS = {
    M: 'medicine',
    D: 'diagnostic',
    T: 'task',
    P: 'package',
    C: 'cri',
    F: 'fluids',
    OT: 'oxygen therapy',
};

export const ORDER_ACTION_NOUNS = {
    M: 'administration',
    D: 'order',
    T: 'order', //TODO need words for Task Instructions
    P: 'package', //TODO need words for Package Instructions
};

export const ORDER_VERBS_PRESENT = {
    M: 'administer',
    D: 'perform',
    T: 'perform', //TODO need words for Task Instructions
};

export const ORDER_VERBS_PAST = {
    M: 'given',
    D: 'performed',
    T: 'performed', //TODO need words for Task Instructions
};

export const MISSION_CONTROL_DATE_FORMAT = 'hh:mm a, MMM Do';

export const INITIAL_CONTEXT_MENU = {
    visible: false,
    x: 0,
    y: 0,
    groupId: -1,
    time: 0,
};

export const FLOAT_REGEX = new RegExp('^[0-9]+\\.?[0-9]*$');

// Three Days in Minutes
export const DEFAULT_INSTRUCTION_LENGTH_MINUTES = 60 * 24 * 3;

export const INSTRUCTION_FREQUENCY_REGEX: RegExp =
    /^q([1-9][0-9]*)(?:h|hr|hour)$|(^q([1-9][0-9]*)d$)|^(once|qqh|qid|tid|bid|qd|sid|qod)$/gi;
export const INSTRUCTION_FREQUENCY_REGEX_NOT_GLOBAL: RegExp =
    /^q([1-9][0-9]*)(?:h|hr|hour)$|(^q([1-9][0-9]*)d$)|^(once|qqh|qid|tid|bid|qd|sid|qod)$/i;

export const BIRTHDAY_FORMAT = 'YYYY-MM-DD';
export const DECEASED_DATE_FORMAT = 'YYYY-MM-DD';

export const NEVER_FIND_ID: string = 'thiswillneverbeanIdandexistsasafallthrough'; // Todo look into conditionally calling certain function making them be able to accept undefined calls

export const CALLER_TYPES = ['Customer', 'rDVM', 'Other'];

export const CHART_CLOSED_MESSAGE: string = 'This chart is closed';

export const SUPPORTED_ZIPCODE_REGEX = /^[0-9]{5}$/i;
export const SUPPORTED_PHONE_NUMBER_REGEX = /^[0-9]{10}$/i;
export const SUPPORTED_STATE_REGEX = /^[A-Z]{2}$/i;

export const SUPPORTED_PAYMENT_AMOUNT_REGEX: RegExp = /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d{1,2})?$/;
export const MINIMUM_STRIPE_PAYMENT_AMOUNT_IN_CENTS = 50;

export const US_STATES_MAP: { [key: string]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FM: 'Federated States of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

export const US_STATES = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District of Columbia', abbreviation: 'DC' },
    { name: 'Federated States of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

export const TEMP_TAX_AMOUNT = 0;

export const ESTIMATE_DURATION = [
    { title: 'Outpatient /Next 6 hrs', duration: 6 },
    { title: 'Next 12 hrs', duration: 12 },
    { title: 'Next 18 hrs', duration: 18 },
    { title: 'Next 24 hrs', duration: 24 },
    { title: 'Next 36 hrs', duration: 36 },
    { title: 'Next 2 days', duration: 48 },
    { title: 'Next 3 days', duration: 72 },
    { title: 'Next 4 days', duration: 96 },
];

export const VISIT_STATUS_FILTERS = [
    {
        text: 'Arrived',
        value: 'arrived',
    },
    {
        text: 'In Progress',
        value: 'inprogress',
    },
    {
        text: 'Hospitalized',
        value: 'hospitalized',
    },
    {
        text: 'Discharged',
        value: 'discharged',
    },
    {
        text: 'Checked Out',
        value: 'checked_out',
    },
    {
        text: 'Closed',
        value: 'closed',
    },
];

export const AR_STATUS_FILTERS = [
    {
        text: 'Awaiting Payment',
        value: 'awaiting_payment',
    },
    {
        text: 'Sent to Collections',
        value: 'sent_to_collections',
    },
];

export const MAX_FILE_UPLOAD_SIZE_MB = 100;

export const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS = [
    'Not provided',
    'Referral from my vet',
    'Referral from another emergency provider',
    'Referral from pet shop',
    'Referral from friend or family',
    'Internet Search',
    'Been to VEG before',
    'Passed by VEG before',
    'Social Media (Facebook, Instagram, Nextdoor, etc.)',
    'Local Event',
    'Sign or Billboard',
    'Other (Please tell us below!)',
];

export const PATIENT_LEVELS = ['critical', 'urgent', 'stable'];
export const MAX_FLUID_ADDITIVES = 4;
export const COOKIE_DURATION = 60 * 60 * 24 * 365;
export const MAX_PET_ALERTS = 4;
