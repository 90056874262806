import { useQuery } from "hooks";
import { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { JSONifyQueryString } from "utils/formatFuncs";
import { ROUTES } from "utils/types/enums";

interface IntermediaryRouteProps {
    component: React.ReactNode;
    isUserLoggedIn: boolean;
    hasAssignedHospital?: boolean;
    path: string;
    exact?: boolean;
}

const IntermediaryRoute: React.FC<IntermediaryRouteProps> = ({
    component: Component,
    isUserLoggedIn,
    hasAssignedHospital,
    path,
    ...rest
}: any) => {
    const history = useHistory();
    let { search: redirectSearch } = useLocation();
    const searchParams = useQuery();

    useEffect(() => {
        if (!isUserLoggedIn) {
            history.push({ pathname: ROUTES.sso });
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        if (isUserLoggedIn && hasAssignedHospital) {
            const queries = JSONifyQueryString<{ redirect: string }>(redirectSearch);
            if (queries.redirect) {
                searchParams.delete('redirect');
                const search = searchParams.toString();
                history.push({ pathname: queries.redirect, search });
            } else {
                history.push(ROUTES.dashboard);
            }
        }
    }, [hasAssignedHospital])

    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <Component {...props} />
                );
            }}
        />
    );
};

export default IntermediaryRoute;
