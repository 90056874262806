import { AlertFilled, InfoCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { Alert, Tooltip } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import React from 'react';
import { roundTo } from 'utils/formatFuncs';
import { getMedicationAlertDescription, getMedicationConcentration } from 'utils/miscFuncs';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import './MedicationDoseAlert.css';
import MedicationDoseConfirmation from './MedicationDoseConfirmation';

interface MedicationDoseAlertProps {
    lowDoseAlert?: number;
    highDoseAlert?: number;
    patientWeight?: number | null;
    dose?: number | null;
    doseUnit: string | null;
    hideConfirmation?: boolean;
    medication: MedicineSearchOption;
    formName?: NamePath;
    bypassDoseAlertValidation?: boolean;
    alertDirection?: 'horizontal';
}

const MedicationDoseAlert: React.FC<MedicationDoseAlertProps> = ({
    lowDoseAlert,
    highDoseAlert,
    patientWeight,
    dose,
    doseUnit,
    hideConfirmation,
    formName,
    bypassDoseAlertValidation,
    medication,
    alertDirection,
}) => {
    if (!dose || !patientWeight) return <div style={{ minHeight: '166px', width: '100%' }} />;

    const getMedicationDose = () => {
        const medConcentration = getMedicationConcentration(medication) ?? 1;

        return medication.dose_unit === medication.denominator_unit ? dose * medConcentration : dose;
    };

    const dosePerKG = roundTo(getMedicationDose() / patientWeight, 3);
    const roundedLowDoseAlert = roundTo(lowDoseAlert, 3);
    const roundedHighDoseAlert = roundTo(highDoseAlert, 3);

    const DefaultMessage = () => (
        <>
            <span>Dosage check</span>
            <Tooltip title='Recommendations are based on dogs and cats.'>
                <InfoCircleOutlined />
            </Tooltip>
        </>
    );

    const HorizontalAlert = ({ message }: { message?: string }) => (
        <span className='medication-dose-alert--horizontal'>
            <span>Dosage check: </span>
            <span>{message}</span>
        </span>
    );

    return (
        <section className='medication-dose-alert-section'>
            {(() => {
                if (!lowDoseAlert && !highDoseAlert) {
                    const message = 'Dose recommendations are not available for this medication.';

                    return (
                        <Alert
                            description={alertDirection !== 'horizontal' && message}
                            icon={<QuestionOutlined />}
                            message={alertDirection === 'horizontal' ? <HorizontalAlert message={message} /> : <DefaultMessage />}
                            type='info'
                            showIcon
                        />
                    );
                }

                const alertMessage = getMedicationAlertDescription(dosePerKG, doseUnit, roundedLowDoseAlert, roundedHighDoseAlert);

                if (!!alertMessage) {
                    return (
                        <>
                            <Alert
                                description={alertDirection !== 'horizontal' && alertMessage}
                                icon={<AlertFilled />}
                                message={alertDirection === 'horizontal' ? <HorizontalAlert message={alertMessage} /> : <DefaultMessage />}
                                type='error'
                                showIcon
                            />
                            {!hideConfirmation && (
                                <MedicationDoseConfirmation formName={formName} bypassDoseAlertValidation={bypassDoseAlertValidation} />
                            )}
                        </>
                    );
                }

                const message = 'The dose is within the recommended range.';

                return (
                    <Alert
                        description={alertDirection !== 'horizontal' && message}
                        message={alertDirection === 'horizontal' ? <HorizontalAlert message={message} /> : <DefaultMessage />}
                        type='success'
                        showIcon
                    />
                );
            })()}
        </section>
    );
};

export default MedicationDoseAlert;
