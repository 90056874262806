import { ArrowsAltOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Button, Col, Empty, FormInstance, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { ElementRef, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEstimatesByVisitIdNewQuery } from 'services/estimateService';
import { FormName, PATIENT_WEIGHT_INSTRUCTION_NAME } from 'utils/constants';
import {
    BaseExistingInstruction,
    BaseNote,
    DiagnosticCard,
    Exam,
    ExistingDiagInstruction,
    ExistingMedInstruction,
    InstructionAction,
    MedicineCard,
    PatientRecordVisit,
    ProblemList,
    VisitVital,
    instructionType,
} from 'utils/dataTypes';
import { insertGhostActionsIntoRealActions } from 'utils/ghostActionUtils';
import { BillingInfo } from 'utils/types/billingTypes';
import { RecentExamCard } from '../../../components/cards/RecentExamCard';
import {
    filterActionsByStatus,
    filterInstructionsByType,
} from '../../../utils/filterFuncs';
import { AddNoteButton } from '../Notes/AddNoteButton';
import NoteItem from '../Notes/NoteItem';
import { BillingSummary } from './BillingSummary';
import { ClosedDiagnostics } from './ClosedDiagnostics';
import { ClosedMedicines } from './ClosedMediciness';
import { DiagnosticModel } from './DiagnosticModel';
import { MedicationModel } from './MedicationModel';
import { OpenDiagnostics } from './OpenDiagnostics';
import { OpenMedicines } from './OpenMedicines';
import { OverviewCard } from './OverviewCard';
import './OverviewTab.css';
import { PendingEstimateItems } from './PendingEstimateItemsNew';
import { ResizableCard } from './ResizableCard';
import { TocCard } from './TocCard';
import OverviewTabStyles from './overview-tab.module.css';
import { TransferEstimateDrawer } from 'components/TransferEstimateDrawer';
import { useState } from 'react';
import NotesDrawer from 'components/NotesDrawer';
import { setActiveDiagnosticsModal } from 'store/slices/treatmentSheetSlice';
import { useAppDispatch } from 'hooks/useRedux';

interface OverviewTabProps {
    instructions: instructionType[];
    exams: Exam[];
    notes: BaseNote[];
    currentVisit: PatientRecordVisit;
    onComplaintSelect: (complaint_ids: number[]) => void;
    problemList: ProblemList[];
    vitalInstructions: VisitVital[] | undefined;
    visitOnFinish: (
        values: any,
        formName: FormName,
        additionalState?: {},
    ) => void;
    billingInfo: BillingInfo;
    modalForm: FormInstance<any>;
    updateIsInvoiceLocked: () => boolean;
}

const GHOST_ACTION_WINDOW = 60 * 60 * 48;

export const findPendingAction = (
    actions: InstructionAction[],
): InstructionAction | undefined => {
    const sortedActions = actions.sort(
        (a: InstructionAction, b: InstructionAction) => b.due_at - a.due_at,
    );
    // Returns the index of the latest action to have due_at time before now
    const latestCompletedActionIndex = sortedActions.findIndex(
        (action) => action.due_at < moment().unix(),
    );
    if (latestCompletedActionIndex === -1) {
        // If ALL actions take place in the future grab the first not completed actions
        return sortedActions.filter(
            filterActionsByStatus(['scheduled', 'claimed', 'inprogress']),
        )[0];
    } else if (
        // TODO account for if the future actions have reached an end state, complete, skipped, or missed.
        ['complete', 'skipped', 'missed'].includes(
            sortedActions[latestCompletedActionIndex].status,
        )
    ) {
        // If the selected action is at end state return the next action
        return sortedActions[latestCompletedActionIndex - 1];
    } else {
        // If the latest action is still not completed return the current action
        return sortedActions[latestCompletedActionIndex];
    }
};


export const OverviewTab = (props: OverviewTabProps) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const [isTransferEstimateDrawerOpen, setIsTransferEstimateDrawerOpen] = useState(false);
    const notesDrawerRef = useRef<ElementRef<typeof NotesDrawer>>(null);
    const patientWeight =
    parseFloat(
        (props?.vitalInstructions?.find(
            (vital) =>
                vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME,
        )?.lastAction?.value as string) ?? '0',
    ) || undefined;
    const instructionsWithGhostActions: instructionType[] = useMemo(() => {
        const now = moment().unix();
        const instructions: instructionType[] = (props.instructions || []).map(
            (instruction) => ({
                ...instruction,
                actions: insertGhostActionsIntoRealActions(
                    instruction,
                    now - GHOST_ACTION_WINDOW,
                    now + GHOST_ACTION_WINDOW,
                ),
            }),
        );
        return instructions;
    }, [props.instructions]);
    const closedDiagnostics = instructionsWithGhostActions
        .filter(filterInstructionsByType('D'))
        .filter((instruction) =>
            instruction.actions.some(filterActionsByStatus(['complete'])),
        )
        .flatMap((instruction: BaseExistingInstruction) => {
            return instruction.actions.reduce(
                (prev: DiagnosticCard[], action) =>
                    action.status === 'complete'
                        ? [
                            ...prev,
                            {
                                instructionId: instruction.id,
                                diagnosticId: (
                                    instruction as ExistingDiagInstruction
                                ).diagnostic_id,
                                name: instruction.name,
                                dueAt: action.due_at,
                                value: action.value,
                                action: action,
                                existingInstruction:
                                    instruction as ExistingDiagInstruction,
                            },
                        ]
                        : prev
                ,[],
            );
        })
        .sort((a, b) => b.dueAt - a.dueAt);
    const openDiagnostics: DiagnosticCard[] = instructionsWithGhostActions
        .filter(instruction =>  instruction.discontinued_at === null)
        .filter(filterInstructionsByType('D'))
        .filter((instruction) => findPendingAction(instruction.actions))
        .map((instruction: BaseExistingInstruction): DiagnosticCard => {
            const nextDiagnostic = findPendingAction(
                instruction.actions,
            ) as InstructionAction;
            return {
                instructionId: instruction.id,
                diagnosticId: (instruction as ExistingDiagInstruction)
                    .diagnostic_id,
                name: instruction.name,
                dueAt: nextDiagnostic.due_at,
                value: null,
                action: nextDiagnostic,
                existingInstruction: instruction as ExistingDiagInstruction,
            };
        })
        .sort((a, b) => a.dueAt - b.dueAt);
    const closedMedicines: MedicineCard[] = instructionsWithGhostActions
        .filter(filterInstructionsByType('M'))
        .filter((instruction) =>
            instruction.actions.some(filterActionsByStatus(['complete'])),
        )
        .flatMap((instruction: BaseExistingInstruction) => {
            return instruction.actions.reduce(
                (prev: MedicineCard[], action) =>
                    action.status === 'complete'
                        ? [
                            ...prev,
                            {
                                instructionId: instruction.id,
                                medicationId: (instruction as ExistingMedInstruction)
                                    .medication_id,
                                name: instruction.name,
                                dueAt: action.due_at,
                                action: action,
                                existingInstruction: instruction as ExistingMedInstruction,
                            },
                        ]
                        : prev
                ,[],
            );
        })
        .sort((a, b) => b.dueAt - a.dueAt);
    const openMedicines: MedicineCard[] = instructionsWithGhostActions
        .filter(instruction =>  instruction.discontinued_at === null)
        .filter(filterInstructionsByType('M'))
        .filter((instruction) => findPendingAction(instruction.actions))
        .map((instruction: BaseExistingInstruction): MedicineCard => {
            const nextMedicine = findPendingAction(
                instruction.actions,
            ) as InstructionAction;
            return {
                instructionId: instruction.id,
                medicationId: (instruction as ExistingMedInstruction)
                    .medication_id,
                name: instruction.name,
                dueAt: nextMedicine.due_at,
                action: nextMedicine,
                existingInstruction: instruction as ExistingMedInstruction,
            };
        })
        .sort((a, b) => a.dueAt - b.dueAt);
    const lastNote = [...props.notes].sort(
        (a, b) => (b.created_at || 0) - (a.created_at || 0),
    )[0];
    const isFinalizedVisit = Boolean(props.currentVisit.finalized_at);

    const { data: estimates } = useGetEstimatesByVisitIdNewQuery({visitId});

    const disabledTransferEstimateItems = useMemo(() => {
        const approvedEstimates = estimates?.filter((estimate) => estimate.estimate_status === 'approved');
        const hasItemsToTransfer = approvedEstimates?.flatMap((estimate) =>
            estimate.estimate_items.filter((item) => !item.is_ordered && item.is_shown_on_tx_sheet),
        );

        return !hasItemsToTransfer?.length;
    }, [estimates]);

    const getLatestExam = (exams: Exam[]) => {
        const sortedExams = [...exams].sort((a: Exam, b: Exam) => b.created_at - a.created_at)
        return sortedExams[0];
    }
    const appDispatch = useAppDispatch();

    return (
        <div className={OverviewTabStyles.overviewTab}>
            <Col span={24} style={{ marginBottom: '24px' }}>
            <Typography.Text
            style={{
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '28px',
            }}
        >
            Overview
        </Typography.Text>
        </Col>
        <Row gutter={[10, 0]}>
                <Col span={19}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} lg={{ span: 12 }} data-cy={'overviewCard'}>
                            <OverviewCard
                                title='New Approved Orders'
                                extra={
                                    <Tooltip title='Move multiple orders to treatment sheet.'>
                                        <Button
                                            disabled={disabledTransferEstimateItems}
                                            icon={<SwapRightOutlined />}                                          
                                            onClick={() => {setIsTransferEstimateDrawerOpen(true);}}
                                        />
                                    </Tooltip>
                                }
                            >
                                <PendingEstimateItems
                                    patientWeight={patientWeight}
                                    modalForm={props.modalForm}
                                    updateIsInvoiceLocked={props.updateIsInvoiceLocked}
                                />
                            </OverviewCard>
                        </Col>
                        <Col span={24} lg={{ span: 12 }}>
                            <OverviewCard title='Fees and Payment'>
                                <BillingSummary
                                    billingInfo={props.billingInfo}
                                />
                            </OverviewCard>
                        </Col>
                        <Col span={24}>
                            <OverviewCard
                                title='Diagnostics'
                                extra={
                                    <Button
                                        onClick={() => appDispatch(setActiveDiagnosticsModal("complete"))}
                                        data-cy={'openDiagnosticsModalButton'}
                                        icon={<ArrowsAltOutlined />}
                                    >
                                    </Button> 
                                }
                            >
                                <Row gutter={16}>
                                    <Col span={24} lg={{ span: 12 }}>
                                        <OpenDiagnostics
                                            diagnostics={openDiagnostics}
                                        />
                                    </Col>
                                    <Col span={24} lg={{ span: 12 }}>
                                        <ClosedDiagnostics
                                            diagnostics={closedDiagnostics}
                                        />
                                    </Col>
                                </Row>
                            </OverviewCard>
                        </Col>
                        <Col span={24}>
                            <OverviewCard
                                title='Medication'
                                extra={
                                    <MedicationModel
                                        openMedicines={openMedicines}
                                        closedMedicines={closedMedicines}
                                    />
                                }
                            >
                                <Row gutter={16}>
                                    <Col span={24} lg={{ span: 12 }}>
                                        <OpenMedicines
                                            medicines={openMedicines}
                                        />
                                    </Col>
                                    <Col span={24} lg={{ span: 12 }}>
                                        <ClosedMedicines
                                            medicines={closedMedicines}
                                        />
                                    </Col>
                                </Row>
                            </OverviewCard>
                        </Col>
                        <Col span={24}>
                            <OverviewCard
                                title='Latest Notes'
                                noHeight
                                extra={
                                    <AddNoteButton
                                        openNoteDrawer={notesDrawerRef.current?.openNotesDrawer}
                                        isFinalizedVisit={isFinalizedVisit}
                                        showOnlyIcon
                                    />
                                }
                            >
                                <Row gutter={16}>
                                    <Col span={24} lg={{ span: 12 }} className='overview-tab__latest-notes'>
                                        {props.exams[0] ? (
                                            <RecentExamCard
                                                isFinalizedVisit={isFinalizedVisit}
                                                exam={getLatestExam(props.exams)}
                                                openEditNotesDrawer={notesDrawerRef.current?.openEditNotesDrawer}
                                            />
                                        ) : (
                                            <>
                                                <Typography.Text className='overview-card-content-header'>
                                                    Physical Exam Note
                                                </Typography.Text>
                                                <Empty
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                    description="No Physical Exam performed"
                                                />
                                            </>
                                        )}
                                    </Col>
                                    <Col span={24} lg={{ span: 12 }} className='overview-tab__latest-notes'>
                                        {lastNote ? (
                                            <NoteItem
                                                isFinalizedVisit={isFinalizedVisit}
                                                note={lastNote}
                                                characterOverflowLimit={250}
                                                openEditNotesDrawer={notesDrawerRef.current?.openEditNotesDrawer}
                                            />
                                        ) : (
                                            <>
                                                <Typography.Text className='overview-card-content-header'>
                                                    Other Notes
                                                </Typography.Text>
                                                <Empty
                                                    image={
                                                        Empty.PRESENTED_IMAGE_SIMPLE
                                                    }
                                                    description='No notes created'
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </OverviewCard>
                        </Col>
                    </Row>
                </Col>
                <Col span={5}>
                    <ResizableCard title='' defaultCardSpan={24}>
                        <TocCard
                            currentVisit={props.currentVisit}
                            onComplaintSelect={props.onComplaintSelect}
                            problemList={props.problemList}
                            exams={props.exams}
                            vitalInstructions={props.vitalInstructions}
                            visitOnFinish={props.visitOnFinish}
                            openTransferEstimateDrawerFunction={setIsTransferEstimateDrawerOpen}
                            openNoteDrawer={notesDrawerRef.current?.openNotesDrawer}
                            openEditNotesDrawer={notesDrawerRef.current?.openEditNotesDrawer}
                        />
                    </ResizableCard>
                </Col>
            </Row>
            <DiagnosticModel
                openDiagnostics={openDiagnostics}
                closedDiagnostics={closedDiagnostics}
            />
            <TransferEstimateDrawer isOpen={isTransferEstimateDrawerOpen} setIsOpen={setIsTransferEstimateDrawerOpen} patientWeight={patientWeight} />
            <NotesDrawer ref={notesDrawerRef} isFinalizedVisit={isFinalizedVisit}/>
        </div>
    );
};
