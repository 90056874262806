import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Tooltip,
} from 'antd';
import moment from 'moment';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
	BASE_QUERY_OPTIONS,
	FALLBACK_MED_ROUTES,
	INSTRUCTION_FREQUENCY_REGEX,
} from '../../utils/constants';
import { ExistingCriInstruction, ExistingMedInstruction, MedicationDose, PimsUser } from '../../utils/dataTypes';
import { MedicineSearchOption } from '../../utils/types/InstructionOrderTypes';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import { initializeForm } from 'hooks/useOnFormInitialize';
import { roundTo } from 'utils/formatFuncs';
import { HiddenInput } from './fields/HiddenInput';
import { useGetUserDataQuery } from 'services/authService';
import { Rule } from 'antd/lib/form';
import { endTimeGreaterThanStartTime, validateFieldIsGreaterThan } from 'utils/validationFuncs';
import { MedicineOrderRules } from 'utils/types/validations';
import { getDefaultStartTime, getMedicationConcentration } from 'utils/miscFuncs';
import { useGetMedicationRoutesQuery } from 'services/instructionService';
import MedicationDoseAlert from './MedicationDoseAlert';
import { useSetFocus } from 'hooks/useSetFocus';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';
import { useLazyGetSupplementalByNameQuery } from 'services/supplementalService';
import PackageItemTitleContainer from './PackageFormModal/PackageItemTitleContainer';
import { DatePickerWithHours } from './fields/DatePickerWithHours';

const { TextArea } = Input;

const formItemStyle: CSSProperties = {
	width: '100%',
};
const getDefaults = (
	options: MedicineSearchOption,
	setDose: React.Dispatch<React.SetStateAction<MedicationDose>>,
	existingInstruction?: Partial<ExistingMedInstruction>,
	approxPatientWeight?: number,
	patientWeight?: number,
	user?: PimsUser
): Partial<ExistingMedInstruction> => {
	if (existingInstruction) {
        const approximateWeight = existingInstruction.approx_patient_weight_kg ? roundTo(existingInstruction.approx_patient_weight_kg, 3) : null 
		return {
			...existingInstruction,
			ordered_by: user?.user_id || 0,
			latest_patient_weight_kg: patientWeight ?? existingInstruction.latest_patient_weight_kg,
            approx_patient_weight_kg: approximateWeight
		};
	} else {
		const additionalTime = options.default_duration_mins;
		const startTime = getDefaultStartTime().unix();
		const endTime = additionalTime
			? moment.unix(startTime).add(additionalTime, 'minutes').unix()
			: undefined;
		const defaultInstruction: Partial<ExistingMedInstruction> = {
			medication_id: options.id,
			type_id: options.type_id,
			name: options.name,
			frequency: options.default_frequency || undefined,
			route_id: options.default_route || undefined,
			start_time: startTime,
			end_time: endTime,
			approx_patient_weight_kg: approxPatientWeight || null,
			latest_patient_weight_kg: patientWeight ?? null,
			is_prn: false,
			prn_condition: undefined,
			ordered_by: user?.user_id || 0,
            serial: options.serial,
		};
		return defaultInstruction;
	}
};

export const calculateDefaultDose = (
	options: MedicineSearchOption,
	approxPatientWeight?: number,
	patientWeight?: number,

) => {
	const localWeight = patientWeight ?? approxPatientWeight;

	if (options.default_dose) {
		// Due to complexity of Tree I think a guard statement would be  easiest way to check
		if (options.dose_unit !== options.numerator_unit && options.dose_unit !== options.denominator_unit) {
			// Todo handle this more gracefully
			alert(`${options?.name ?? 'Medication'} is misconfigured, please submit a ticket to IT Support`)
			return
		}
		if (options.default_dose_unit === options.numerator_unit) {
			if (options.dose_unit === options.numerator_unit) {
				return {
					dose: options.default_dose,
					unit: options.numerator_unit
				}
				// default_dose = options.default_dose;
			} else if (options.dose_unit === options.denominator_unit) {
				if (options.numerator_value) {
					return {
						dose: options.default_dose / options.numerator_value,
						unit: options.denominator_unit
					}
				}
			}
		} else if (options.default_dose_unit === options.denominator_unit) {
			if (options.dose_unit === options.numerator_unit) {
				if (options.numerator_value) {
					return {
						dose: options.default_dose * options.numerator_value,
						unit: options.denominator_unit
					}
				}
			} else if (options.dose_unit === options.denominator_unit) {
				return {
					dose: options.default_dose,
					unit: options.denominator_unit
				}
			}
		} else if (options.default_dose_unit === `${options.numerator_unit}/kg`) {
			if (localWeight) {
				if (options.dose_unit === options.numerator_unit) {
					return {
						dose: options.default_dose * localWeight,
						unit: options.numerator_unit
					}
				} else if (options.dose_unit === options.denominator_unit) {
					if (options.numerator_value) {
						return {
							dose: (options.default_dose * localWeight) / options.numerator_value,
							unit: options.denominator_unit
						}
					}
				}
			}
		} else if (options.default_dose_unit === `${options.denominator_unit}/kg`) {
			if (localWeight) {
				if (options.dose_unit === options.numerator_unit) {
					if (options.numerator_value) {
						return {
							dose: options.default_dose * localWeight * options.numerator_value,
							unit: options.numerator_unit
						}
					}
				} else if (options.dose_unit === options.denominator_unit) {
					if (options.numerator_value) {
						return {
							dose: (options.default_dose * localWeight),
							unit: options.denominator_unit
						}
					}
				}
			}
		}
	}
}

export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';

interface MedicineOrderProps {
	options: MedicineSearchOption;
	existingInstruction?: Partial<ExistingMedInstruction>;
	patientWeight?: number;
	onFormChange?: Function; //not actually optional -- always sent via FormWrapper
	getFormData?: Function; //not actually optional -- always sent via FormWrapper
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	form?: FormInstance<any>;
	fromEstimate?: boolean;
}

export const MedicineOrder = (props: MedicineOrderProps) => {
	const { options, patientWeight, existingInstruction, fromEstimate } = props;
	const [approxPatientWeight, setApproxPatientWeight] = useState(existingInstruction?.approx_patient_weight_kg || 0);
	const localPatientWeight =
		patientWeight ??
		approxPatientWeight ??
		existingInstruction?.latest_patient_weight_kg ??
		existingInstruction?.approx_patient_weight_kg ??
		null;
	const [doseObject, setDoseObject] = useState<MedicationDose>({
		dose: null,
		unit: null
	})
	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);

	useEffect(() => {
		onFormChange({ dose: doseObject.dose, dose_unit: doseObject.unit })
	}, [doseObject])
	const existingDosage = existingInstruction?.dose;// ?? (options?.default_dose || undefined);

	const labelCol = props.labelCol || { span: 6 };
	const wrapperCol = props.wrapperCol || { span: 18 };

    const [isSerialCheaper, setIsSerialCheaper] = useState(false);
    const [routeId, setRouteId] = useState<string | undefined>();
    const [getSupplemental, {data: supplementalData}] = useLazyGetSupplementalByNameQuery()



	const onFormChange = (changes: any) => {     
        let serial = false   
        if (props?.getFormData){
            const fields = {
                ...props?.getFormData(),
                ...changes
            };
            serial = checkIfSerialCheaper(
                options, 
                fields.frequency, 
                fields?.start_time?.unix(), 
                fields?.end_time?.unix(), 
                fields?.supplemental_cost_cents,
                doseObject.dose ?? 0,
                )
            setIsSerialCheaper(serial)
        }
        if (props?.onFormChange){ 
            return props?.onFormChange({...changes, serial})
        } else {
            return (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
        }
    }

    useEffect(() => {
        if (routeId) {
            getSupplemental({ name: routeId, controlledMedication: options.controlled_drug });
        }
    }, [routeId]);

    useEffect(() => {
        if (supplementalData) {
            onFormChange({
                supplemental_cost_cents: supplementalData.cents
            })
        }
    }, [supplementalData])



	useEffect(() => {
		const defaultMedicine = getDefaults(options, setDoseObject, existingInstruction, approxPatientWeight, patientWeight, loggedInUserData);
		if (props.form) {
			props.form.resetFields(Object.keys(defaultMedicine))
		}
		initializeForm(defaultMedicine, onFormChange);
        setRouteId(defaultMedicine.route_id)
	}, [options, existingInstruction]);

	useEffect(() => {
		const defaultDose = calculateDefaultDose(options, approxPatientWeight, patientWeight);

		if (!!defaultDose) {
			setDoseObject(defaultDose);
		}
	}, [options, existingInstruction, approxPatientWeight]);

	const medicineOrderRules: MedicineOrderRules = {
		frequency: [{ required: true, pattern: INSTRUCTION_FREQUENCY_REGEX, }],
		start_time: [{ required: true, type: "date" }],
		end_time: [
			{ type: "date" },
			endTimeGreaterThanStartTime
		],
		notes: [{ required: true }],
		approx_patient_weight_kg: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('patient weight'),
			}
		],
		dose: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('dose'),
			}
		],
		route_id: [{ required: true, enum: [...FALLBACK_MED_ROUTES] }],
		is_prn: [{ required: false, type: 'boolean' }],
		prn_condition: []
	}

    const showWeight = patientWeight === undefined
	return (
		<>
        	{isSerialCheaper && <AlertSerialPrice serialHours={options.serial_hours ?? 0} inForm />}

			<Row align='middle' style={{ width: '100%', marginTop: 'var(--spacing-md)' }}>
				<Col offset={6}>
					{existingInstruction?.is_free && <PackageItemTitleContainer no_charge={existingInstruction?.is_free} checked />}
				</Col>
			</Row>

			<MedicationWeight
                autoFocus={showWeight}
				disabled={false}
                fromEstimate={fromEstimate}
				rules={medicineOrderRules.approx_patient_weight_kg}
				onChange={(value) => {
					onFormChange({
						approx_patient_weight_kg: value,
					});
					setApproxPatientWeight(value ?? 0);
				}}
				patientWeight={patientWeight}
                existingInstruction={existingInstruction}
			/>
			<MedicationName
				medicationName={options.name}
				onFormChange={onFormChange} // Needed to set initial value
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			/>
			<MedicationType
				type="med"
				onFormChange={onFormChange} // Needed to set initial value
			/>
			<MedicineDosage
                autoFocus={!showWeight}
				disabled={false}
				patientWeight={localPatientWeight}
				doseObject={doseObject}
				setDoseObject={setDoseObject}
				medication={options}
				onFormChange={onFormChange} // This form field is fully controlled
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				existingDosage={existingDosage}
				rules={medicineOrderRules.dose}
			/>
			<MedicationRoute
				disabled={false}
				medication={options}
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				rules={medicineOrderRules.route_id}
                onChange={(input: string) => setRouteId(input)}
			/>
			<MedicationFrequency
				disabled={false}
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				rules={medicineOrderRules.frequency}
                onFormChange={onFormChange}
			/>

			<MedicationStart
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				onFormChange={onFormChange} // Only needed because we default a value for this field
				rules={medicineOrderRules.start_time}
			/>
			<MedicationEnd
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				onFormChange={onFormChange} // Only needed because we default a value for this field
				rules={medicineOrderRules.end_time}
			/>
			<MedicationNote
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				rules={medicineOrderRules.notes}
			/>

			<MedicationDoseAlert
				lowDoseAlert={options.low_dose_alert}
				highDoseAlert={options.high_dose_alert}
				patientWeight={localPatientWeight}
				dose={existingDosage ?? doseObject.dose}
				doseUnit={options.numerator_unit}
				medication={options}
			/>

			<HiddenInput fieldName='is_prn' />
			<HiddenInput fieldName='prn_condition' />
			<HiddenInput fieldName={'ordered_by'} />
			<HiddenInput fieldName={'id'} />
			<HiddenInput fieldName={'priority'} />
			<HiddenInput fieldName="userId" initialValue={loggedInUserData?.user_id} />
			<HiddenInput fieldName="fromEstimate" initialValue={fromEstimate} />
			<HiddenInput fieldName="controlled_drug" initialValue={options.controlled_drug} />
            <HiddenInput fieldName="serial" initialValue={options.serial} />
            <HiddenInput fieldName="supplemental_cost_cents" initialValue={0} />
			<HiddenInput fieldName="is_free" initialValue={existingInstruction?.is_free} />
			{/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
		</>
	);
};

interface MedicationNameProps {
	medicationName: string;
	onFormChange: Function;
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
}

export const MedicationName = (props: MedicationNameProps) => {
	useEffect(() => {
		//TODO IDK why, but we need a small Timeout for these usEffect calls
		setTimeout(() => {
			props.onFormChange({
				medication_name: props.medicationName,
			});
		}, 50);
	}, [props]);
	return (
		<Form.Item
			preserve={false}
			hidden
			name='medication_name'
			label='Medicine'
			style={formItemStyle}
			labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
		>
			<Input disabled />
		</Form.Item>
	);
};

interface MedicationTypeProps {
	type: "med" | "cri";
	onFormChange: Function;
}

export const MedicationType = (props: MedicationTypeProps) => {
	useEffect(() => {
		//TODO IDK why, but we need a small Timeout for these usEffect calls
		setTimeout(() => {
			props.onFormChange({
				medication_type: props.type,
			});
		}, 50);
	}, [props]);
	return (
		<Form.Item
			preserve={false}
			hidden
			name='medication_type'
		>
			<Input disabled />
		</Form.Item>
	);
};


interface MedicineDosageProps {
	medication: MedicineSearchOption;
	doseObject: MedicationDose;
	setDoseObject: React.Dispatch<React.SetStateAction<MedicationDose>>;
	existingDosage?: number;
	patientWeight: number; //TODO may be used in future with a fancier dosage calculator
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	onFormChange: Function;
    autoFocus?: boolean;
	disabled?: boolean; //Disables ALL inputs within this component
	rules?: Rule[];
}

/**
 Drug mass per kg is the reference state for mass and volume.
 Patient weight is required for calculator to work because
 multiplying by zero patient weight would force mass and volume to be zero.
 */

 const checkIfDrugMassPerKgIsValid =  (weight?: number, drugPerKg?:  number) => {
	if (!weight) {
		return false;
	}
	if (drugPerKg === undefined || isNaN(drugPerKg)) {
		return false;
	}

	return true;

 }

export const MedicineDosage = ({
	medication,
	setDoseObject,
	patientWeight,
	disabled,
	existingDosage,
	rules,
	doseObject,
	autoFocus
}: MedicineDosageProps) => {
	const [drugMassPerKg, setDrugMassPerKg] = useState<number>(0);

    const ref = useRef<any>(null)

    useSetFocus(ref, autoFocus)

	const medConcentration = getMedicationConcentration(medication) ?? 1;
	const drugMass = drugMassPerKg * patientWeight;
	const drugVolume = drugMass / medConcentration;

	useEffect(() => {
		if (existingDosage) {
			if (medication.dose_unit === medication.numerator_unit) {
				setDrugMassPerKg(existingDosage / patientWeight);
			} else if (medication.dose_unit === medication.denominator_unit) {
				setDrugMassPerKg((existingDosage * medConcentration) / patientWeight);
			}
		} else if (medication.default_dose) {
			if (medication.default_dose_unit === medication.numerator_unit) {
				setDrugMassPerKg(medication.default_dose / patientWeight);
			} else if (medication.default_dose_unit === medication.denominator_unit) {
				setDrugMassPerKg((medication.default_dose * medConcentration) / patientWeight);
			} else if (medication.default_dose_unit === `${medication.numerator_unit}/kg`) {
				setDrugMassPerKg(medication.default_dose);
			}
		} else if (doseObject.dose && patientWeight) {
			if (medication.dose_unit === medication.numerator_unit) {
				setDoseObject({
					dose: drugMass,
					unit: medication.numerator_unit
				});
			} else if (medication.dose_unit === medication.denominator_unit) {
				setDoseObject({
					dose: drugVolume,
					unit: medication.numerator_unit
				});
			}
		}
	}, [existingDosage, patientWeight]);

	if (medication.calculator_type === 'simple' || medication.calculator_type === 'prepackaged') {
		return (
			<>
				<Form.Item
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					label="Dose"
					style={{ width: "100%", }}
					data-cy={'doseInput'}
				>
					<Row
						align='middle'
						justify='space-between'
						wrap={false}

					>
						<Col span={18}>
							<Form.Item rules={rules}
								preserve={false}
								name='dose'
							>
								<InputNumber
                                    ref={ref}
                                    autoFocus={autoFocus}
									style={{'width': '100%'}}
									type='number'
									disabled={disabled}
									min={0}
									// value={roundTo(drugMass, 3)}
									onChange={(value) => {
										// const value = parseFloat(event.currentTarget.value)
										// If value isn't a number then don't update any field
										if (!value || isNaN(value)) {
											return;
										}
										if (medication.dose_unit === medication.numerator_unit || medication.dose_unit === medication.denominator_unit) {
											setDoseObject({
												dose: value,
												unit: medication.dose_unit
											})
										} else {
											// Todo handle this more gracefully
											alert(`${medication?.name ?? 'Medication'} is misconfigured, please submit a ticket to IT Support`)
										}
										setDrugMassPerKg(value / patientWeight);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={4} >{medication.dose_unit}</Col>
					</Row>

				</Form.Item >
				<Form.Item
					preserve={false}
					name='dose_unit'
					style={{ paddingTop: 0 }}
					hidden={true}
				>
					<Input></Input>
				</Form.Item>

			</>
		)
	} else {
		const drugMassInput = (
			<Row align='bottom' justify='start'>
				<Col span={24}>
					<InputNumber
                        ref={ref}
                        autoFocus={autoFocus}
						disabled={disabled || !patientWeight}
						min={0}
						value={roundTo(drugMass, 3)}
						onChange={(value) => {
							if (medication.dose_unit === medication.numerator_unit) {
								setDoseObject({
									dose: value,
									unit: medication.numerator_unit
								})
							} else if (medication.dose_unit === medication.denominator_unit) {
								setDoseObject({
									dose: (value ?? 0) / medConcentration,
									unit: medication.denominator_unit
								})
							} else {
								// Todo handle this more gracefully
								alert(`${medication?.name ?? 'Medication'} is misconfigured, please submit a ticket to IT Support`)
							}
							setDrugMassPerKg((value ?? 0) / patientWeight);
						}}
					/>

				</Col>
				<Col>{medication.numerator_unit}</Col>
			</Row>
		);
		const drugVolumeInput = (
			<Row align='bottom' justify='start'>
				<Col span={24}>
					<InputNumber
						disabled={disabled || !patientWeight}
						min={0}
						value={roundTo(drugVolume, 3)}
						onChange={(value) => {
							if (medication.dose_unit === medication.numerator_unit) {
								setDoseObject({
									dose: (value ?? 0) * medConcentration,
									unit: medication.numerator_unit
								})

							} else if (medication.dose_unit === medication.denominator_unit) {
								setDoseObject({
									dose: value,
									unit: medication.denominator_unit
								})
							} else {
								// Todo handle this more gracefully
								alert(`${medication?.name ?? 'Medication'} is misconfigured, please submit a ticket to IT Support`)
							}
							setDrugMassPerKg(((value ?? 0) * medConcentration) / patientWeight);
						}}
					/>
				</Col>
				<Col>{medication.denominator_unit}</Col>
			</Row>
		);
		const drugMassPerPatKgInput = (
			<Row align='bottom' justify='start'>
				<Col span={24}>
					<InputNumber
						disabled={disabled || !patientWeight}
						min={0}
						value={
							checkIfDrugMassPerKgIsValid(patientWeight, drugMassPerKg) ? roundTo(drugMassPerKg, 3) : undefined
						}
						onChange={(value) => {
							if (medication.dose_unit === medication.numerator_unit) {
								setDoseObject({
									dose: (value ?? 0) * patientWeight,
									unit: medication.numerator_unit
								})

							} else if (medication.dose_unit === medication.denominator_unit) {
								setDoseObject({
									dose: ((value ?? 0) * patientWeight) / medConcentration,
									unit: medication.denominator_unit
								})
							} else {
								// Todo handle this more gracefully
								alert(`${medication?.name ?? 'Medication'} is misconfigured, please submit a ticket to IT Support`)
							}
							setDrugMassPerKg(value ?? 0);
						}}
					/>
				</Col>
				<Col>{medication.numerator_unit}/kg</Col>
			</Row>
		);

		let wrappedMassInput, wrappedVolumeInput, wrappedMassPerKgInput;
		if (!patientWeight) {
			wrappedMassInput = (
				<Tooltip title='Patient weight required'>
					{drugMassInput}
				</Tooltip>
			)
			wrappedVolumeInput = (
				<Tooltip title='Patient weight required'>
					{drugVolumeInput}
				</Tooltip>
			)
			wrappedMassPerKgInput = (
				<Tooltip title='Patient weight required'>
					{drugMassPerPatKgInput}
				</Tooltip>
			)
		} else {
			wrappedMassInput = <>{drugMassInput}</>;
			wrappedVolumeInput = <>{drugVolumeInput}</>;
			wrappedMassPerKgInput = <>{drugMassPerPatKgInput}</>;
		}

		return (
			<>
				<Form.Item
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
					label="Dose"
					rules={rules}
					preserve={false}
					name='dose'
					data-cy={'doseInput'}
					style={{ width: "100%", }}
				>
					<Input hidden></Input>

					<Row justify='space-between'>
						<Col data-cy={'massInput'} span={6}>{wrappedMassInput}</Col>
						<Col data-cy={'volumeInput'} span={6}>{wrappedVolumeInput}</Col>
						<Col data-cy={'massPerKgInput'} span={6}>{wrappedMassPerKgInput}</Col>
					</Row>
				</Form.Item >
				<Form.Item
					preserve={false}
					name='dose_unit'
					style={{ paddingTop: 0 }}
					hidden={true}
				>
					<Input></Input>
				</Form.Item>

			</>

		);

	}

};

interface MedicationRouteProps {
	medication: MedicineSearchOption; // In future, each med will have `preferred` routes
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	disabled?: boolean;
	rules?: Rule[];
	onChange?: Function
}

interface MedicationRoute {
	id: string;
	name: string;
}

export const MedicationRoute = (props: MedicationRouteProps) => {

	const {data: routeOptions} = useGetMedicationRoutesQuery({
		BASE_QUERY_OPTIONS
	});

	return (
		<Form.Item
			preserve={false}
			name='route_id'
			label='Route'
			data-cy={'routeInput'}
			style={formItemStyle}
			labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			rules={props.rules ?? []}
		>
				<Select
					showSearch
					options={routeOptions?.map((route: MedicationRoute) => ({
						key: route.name,
						value: route.id,
						label: route.id,
					}))}
					filterOption={(input, option) =>
						(option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
						|| (option?.key as unknown as string).toLowerCase().includes(input.toLowerCase())
					}
					onChange={(input, options) => {
						if (props.onChange ){
							props.onChange(input)
						}
					}}
					disabled={props.disabled}
					getPopupContainer={(triggerNode) => triggerNode}
					listHeight={200}
				/>
		</Form.Item>
	);
};

interface MedicationFrequencyProps {
    autoFocus?: boolean;
	disabled?: boolean;
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	style?: CSSProperties; //Width of INPUT, in "columns" (like <Col span={12})
	rules?: Rule[];
    labelAlign?: 'left' | 'right';
    onFormChange?: Function;
}

export const MedicationFrequency = (props: MedicationFrequencyProps) => {
    const ref = useRef<any>(null)

    useSetFocus(ref, props.autoFocus)

	const regex: RegExp = INSTRUCTION_FREQUENCY_REGEX;
	return (
		<Form.Item
			preserve={false}
			name='frequency'
			label='Frequency'
			data-cy={'frequencyInput'}
			style={props.style ?? formItemStyle}
			labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			rules={props.rules ?? [{ pattern: regex, required: true }]}
            labelAlign={props.labelAlign}
		>
			<Input 
                onChange={(e) => {
                    if (props.onFormChange) {
                        props.onFormChange({'frequency': e.target.value})
                    }
                    }} 
                ref={ref} 
                autoFocus={props.autoFocus} 
                disabled={props.disabled} 
                autoComplete={'off'} 
                placeholder='e.g. q1h, q1d, once' 
            />
		</Form.Item>
	);
};

interface MedicationAsNeededProps {
	onFormChange: Function; //IDK WHY, but the Form is not picking up when this value changes
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	updatePrn?: Function
	disabled?: boolean
	rules?: Rule[];

}
export const MedicationAsNeeded = (props: MedicationAsNeededProps) => {
	const { onFormChange } = props;
	return (
		<Form.Item
			valuePropName='checked'
			preserve={false}
			label='PRN'
			name='is_prn'
			style={formItemStyle}
			labelCol={props.labelCol ?? { span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol ?? { span: 6 }} //Width of INPUT, in "columns" (like <Col span={12})
			rules={props.rules ?? []}
		>
			<Checkbox
				disabled={props.disabled}
				onChange={(e) => {
					if (props.updatePrn) {
						props.updatePrn(e.target.checked)
					} else {
						onFormChange(e);
					}
				}}
			/>
		</Form.Item>
	);
};

interface MedicationPrnConditionProps {
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	disabled?: boolean
	rules?: Rule[];
}
export const MedicationPrnCondition = (props: MedicationPrnConditionProps) => {
	return (
		<Form.Item
			preserve={false}
			name='prn_condition'
			label='PRN condition'
			style={formItemStyle}
			labelCol={props.labelCol ?? { span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol ?? { span: 6 }} //Width of INPUT, in "columns" (like <Col span={12})
			rules={props.rules ?? []}
		>
			<Input disabled={props.disabled} />
		</Form.Item>
	);
};

interface MedicationStartProps {
	onFormChange: Function;
	labelCol?: any;
	wrapperCol?: any;
	setStartTime?: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>
	rules?: Rule[];
}

export const MedicationStart = (props: MedicationStartProps) => {
	const { onFormChange, setStartTime } = props;
	const [newTime, setNewTime] = useState<moment.Moment | null>(getDefaultStartTime());

	useEffect(() => {
		setTimeout(() => {
			const defaultTime = getDefaultStartTime();

			setStartTime?.(defaultTime);
			onFormChange({ start_time: defaultTime });
		}, 1);
	}, [onFormChange]);

	useEffect(() => {
		if (newTime) {
			setStartTime?.(newTime || undefined);
			onFormChange({ start_time: newTime });
		}
	}, [newTime]);

	return (
		<Form.Item
			preserve={false}
			name='start_time'
			label='Start'
			style={formItemStyle}
			labelCol={props.labelCol}
			wrapperCol={props.wrapperCol}
			rules={props.rules ?? []}
		>
			<DatePickerWithHours
				fieldName='start_time'
				newTime={newTime}
				setNewTime={setNewTime}
				dateLimit={moment().startOf('hour')}
			/>
		</Form.Item>
	);
};
interface MedicationEndProps {
	onFormChange: Function;
	labelCol?: any;
	wrapperCol?: any;
	setEndTime?: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>;
	rules?: Rule[];
	disabled?: boolean;
}

export const MedicationEnd = (props: MedicationEndProps) => {
	const { onFormChange, setEndTime } = props;
	const [newTime, setNewTime] = useState<moment.Moment | null>(null);

	useEffect(() => {
		if (newTime) {
			setEndTime?.(newTime || undefined);
			onFormChange({ end_time: newTime });
		}
	}, [newTime]);

	return (
		<Form.Item
			preserve={false}
			name='end_time'
			label='End'
			style={formItemStyle}
			labelCol={props.labelCol}
			wrapperCol={props.wrapperCol}
			rules={props.rules ?? []}
		>
			<DatePickerWithHours
				fieldName='end_time'
				newTime={newTime}
				setNewTime={setNewTime}
				dateLimit={moment().startOf('hour')}
			/>
		</Form.Item>
	);
};

export const MedicationNote = (props: any) => {
	return (
		<Form.Item
			preserve={false}
			name={props.notes ?? 'notes'}
			label={props.label ?? 'Instructions'}
			style={formItemStyle}
			labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
		>
			<TextArea disabled={props.disabled} />
		</Form.Item>
	);
};

export const MedicationDispense = (props: any) => {
	return (
		<Form.Item
			preserve={false}
			name='dispense'
			label='Dispense'
			style={formItemStyle}
			labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
		>
			<Radio.Group>
				<Radio value={'dispense_hospital'}>
					Dispense and give in hospital
				</Radio>
				<Radio value={'dispense_home'}>
					Dispense and send home with patient
				</Radio>
				<Radio value={'customer_provided'}>
					Customer provided medicine
				</Radio>
				<Radio value={'print'}>Print as prescription</Radio>
			</Radio.Group>
		</Form.Item>
	);
};

interface MedicationWeightProps {
    autoFocus?: boolean
	disabled?: boolean;
    fromEstimate?: boolean;
	rules?: Rule[];
	patientWeight?: number
	inPackage?: boolean
	approxPatientWeight?: number
	onChange?: ((value: number | null) => void)
    existingInstruction?: Partial<ExistingMedInstruction> | Partial<ExistingCriInstruction>
}

export const MedicationWeight = (props: MedicationWeightProps) => {
	const {
		patientWeight,
		rules,
		inPackage,
		approxPatientWeight,
		disabled,
        fromEstimate,
        autoFocus,
		onChange,
        existingInstruction,
	} = props

    const ref = useRef<any>(null)

    useSetFocus(ref, autoFocus)

	let showWeightField = true;
	let localWeight: number | undefined;

	if (patientWeight || inPackage) {
		showWeightField = false
		localWeight = patientWeight
	} else if (fromEstimate) {
		showWeightField = true
		localWeight = patientWeight ?? approxPatientWeight ?? existingInstruction?.latest_patient_weight_kg ?? existingInstruction?.approx_patient_weight_kg ?? undefined;
    }
	if (localWeight) {
		localWeight = roundTo(localWeight, 3);
	}
	if (showWeightField) {
		return (
			<Row style={{ width: '100%' }} align='middle' justify='start'>
				<Col span={6}>Approx. weight</Col>
				<Col>
					<Form.Item
						preserve={true}
						name='approx_patient_weight_kg'
						rules={rules}
						data-cy={'approxWeightInput'}
					>
						<InputNumber
                            ref={ref}
                            autoFocus={autoFocus}
							disabled={disabled}
							min={0}
							value={approxPatientWeight}
							onChange={onChange}
						/>
					</Form.Item>
				</Col>
				<Col offset={1}>kg</Col>
			</Row>
		)
	}
	else {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col span={6}>Patient weight:</Col>
					<Col>
						<b>{localWeight}kg</b>
					</Col>
				</Row >
				<HiddenInput fieldName={'approx_patient_weight_kg'} />
				<HiddenInput fieldName={'latest_patient_weight_kg'} />
			</>

		)
	}
};
