import { FileTextOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { HiddenInput } from 'components/forms/fields/HiddenInput';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { RecentVital } from 'utils/dataTypes';
import { getRecentVitalsFromInstructions } from 'utils/miscFuncs';

interface InitialVitalsProps {
    visitId: number;
    form: FormInstance;
    isOpen: boolean;
}

export const InitialVitals = ({ visitId, form, isOpen }: InitialVitalsProps) => {
    const { data: instData, refetch: refetchInstructionData } = useGetInstructionsByVisitIdQuery({ visitId }, { ...BASE_QUERY_OPTIONS });
    const sortedInstData = _.sortBy(instData, ['sort_rank', 'name']);
    const recentVitals = getRecentVitalsFromInstructions(sortedInstData);
    const [visibleNoteFields, setVisibleNoteFields] = useState<boolean[]>([]);

    useEffect(() => {
        if (isOpen) {
            refetchInstructionData();
        }
    }, [isOpen]);

    useEffect(() => {
        if (instData) {
            const initialVitalsFormValues = form.getFieldValue(['initial_vitals']);
            if (Array.isArray(initialVitalsFormValues)) {
                initialVitalsFormValues.forEach((item, index) => {
                    Object.keys(item).forEach((key) => {
                        form.resetFields([['initial_vitals', index, key]]);
                    });
                });
            }
            setVisibleNoteFields(
                recentVitals?.map((vital) => {
                    return !!vital.initialAction?.note;
                }) || [],
            );
        }
    }, [instData]);

    const toggleNoteField = (index: number) => {
        const newVisibleNoteFields = [...visibleNoteFields];
        newVisibleNoteFields[index] = !newVisibleNoteFields[index];
        setVisibleNoteFields(newVisibleNoteFields);
    };

    const getVitalInstructionFormItem = (vital: RecentVital, index: number) => {
        const conditionRegExp = new RegExp(vital.condition);
        return (
            <>
                <HiddenInput fieldName={['initial_vitals', index, 'instruction_id']} initialValue={vital.instruction_id} />
                <HiddenInput fieldName={['initial_vitals', index, 'due_at']} initialValue={vital.initialActionDueAt} />
                <HiddenInput fieldName={['initial_vitals', index, 'scheduled_time']} initialValue={vital.initialAction?.scheduled_time || null} />
                <HiddenInput fieldName={['initial_vitals', index, 'scheduled_by']} initialValue={vital.initialAction?.scheduled_by || null} />
                <HiddenInput fieldName={['initial_vitals', index, 'current_value']} initialValue={vital.initialAction?.value || null} />
                <HiddenInput fieldName={['initial_vitals', index, 'current_note']} initialValue={vital.initialAction?.note || null} />
                <Row style={{ paddingBottom: '6px' }}>
                    <Col span={22}>
                        <Form.Item
                            preserve={false}
                            name={['initial_vitals', index, 'new_value']}
                            label={vital.name}
                            labelCol={{ span: 14 }}
                            wrapperCol={{ span: 24 }}
                            style={{ width: '100%', paddingTop: 0 }}
                            initialValue={vital.initialAction?.value || null}
                            rules={[
                                { pattern: conditionRegExp, message: `'value' does not match pattern ${conditionRegExp}` },
                                {
                                    validator: (_, value) => {
                                        const currentNote = vital.initialAction?.note;
                                        const currentValue = vital.initialAction?.value;
                                        const newNote = form.getFieldValue(['initial_vitals', index, 'new_note']);

                                        if (currentValue !== null && currentValue !== undefined && value === '') {
                                            return Promise.reject('value required to update completed vital');
                                        }
                                        if (!currentNote && !newNote) {
                                            return Promise.resolve();
                                        }
                                        if (!value && currentNote !== newNote) {
                                            return Promise.reject('value required to edit the note');
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} suffix={vital.unit} disabled={!!vital.discontinued_at} />
                        </Form.Item>
                    </Col>
                    <Col span={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Button
                            type='ghost'
                            icon={<FileTextOutlined style={{ fontSize: '14px' }} />}
                            style={{
                                marginLeft: 'auto',
                                background: '#fff',
                                opacity: visibleNoteFields[index] || vital.discontinued_at ? 0.35 : 1,
                            }}
                            onClick={() => {
                                toggleNoteField(index);
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingBottom: '6px', display: visibleNoteFields[index] ? 'inherit' : 'none' }}>
                    <Col span={24}>
                        <Form.Item
                            preserve={false}
                            name={['initial_vitals', index, 'new_note']}
                            wrapperCol={{ span: 24 }}
                            style={{ width: '100%', paddingTop: 0 }}
                            initialValue={vital.initialAction?.note || null}
                        >
                            <TextArea
                                onChange={() => {
                                    form.validateFields([['initial_vitals', index, 'new_value']]);
                                }}
                                disabled={!!vital.discontinued_at}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <Row style={{ background: '#f0f5f7', padding: '16px' }}>
            <Col span={24}>
                {recentVitals?.map((vital, index) => {
                    return getVitalInstructionFormItem(vital, index);
                })}
            </Col>
        </Row>
    );
};
