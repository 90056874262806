import { isNaN } from 'lodash';
import { useGetInstructionOptionsQuery } from 'services/instructionService';
import { roundTo } from 'utils/formatFuncs';
import { getMedicationConcentration } from 'utils/miscFuncs';
import {
    BaseSearchOption,
    isInstanceOfMedicineSearchOption,
} from 'utils/types/InstructionOrderTypes';
import OrderFrequency from '../OrderFrequency';
import './MedicationData.css';

interface MedicationValues {
    name: string;
    approx_patient_weight_kg: number | null;
    latest_patient_weight_kg: number | null;
    dose_unit: string;
    dose: number;
    route_id: string;
    frequency: string;
}

interface IMedicationDataProps {
    group: MedicationValues;
    instructionIcon: JSX.Element;
    patientWeight?: number 
}
interface MedicationDoses {
    numerator_units?: string;
    numerator_value?: number;
    numerator_per_kg_units?: string;
    numerator_per_kg_value?: number;
    denominator_units?: string;
    denominator_value?: number;
}

const getMedConcentrationElement = (
    group: MedicationValues,
    baseSearchOption?: BaseSearchOption[],
    currentPatientWeight?: number 
): MedicationDoses => {
    const returnUnits: MedicationDoses = {
        numerator_units: undefined,
        numerator_value: undefined,
        numerator_per_kg_units: undefined,
        numerator_per_kg_value: undefined,
        denominator_units: undefined,
        denominator_value: undefined,
    };
    const medicationInstruction = baseSearchOption?.find((instruction) => {
        return instruction?.name === group.name;
    });

    const patientWeight = currentPatientWeight ?? group.approx_patient_weight_kg ?? group.latest_patient_weight_kg;

    if (
        patientWeight &&
        isInstanceOfMedicineSearchOption(medicationInstruction)
    ) {
        const medConcentration = getMedicationConcentration(
            medicationInstruction,
        );

        if (medicationInstruction?.numerator_unit === group.dose_unit) {
            returnUnits.numerator_units = group.dose_unit
            returnUnits.numerator_value = group.dose;
            returnUnits.numerator_per_kg_units = `${medicationInstruction?.numerator_unit}/kg`;
            returnUnits.numerator_per_kg_value = roundTo(group.dose / patientWeight, 3);

            if (medicationInstruction?.denominator_unit && medConcentration) {
                returnUnits.denominator_value = roundTo(
                    group.dose / medConcentration,
                    2,
                );
                returnUnits.denominator_units =medicationInstruction?.denominator_unit;
            }
        } else if (
            medicationInstruction?.denominator_unit === group.dose_unit
        ) {
            returnUnits.denominator_value = roundTo(group.dose, 2);
            returnUnits.denominator_units = group.dose_unit;

            if (medicationInstruction?.numerator_unit && medConcentration){
                returnUnits.numerator_units = medicationInstruction?.numerator_unit
                returnUnits.numerator_value = roundTo(group.dose * medConcentration, 3);
                returnUnits.numerator_per_kg_units = `${medicationInstruction?.numerator_unit}/kg`;
                returnUnits.numerator_per_kg_value = roundTo(
                    (group.dose * medConcentration) / patientWeight,
                    3,
                );
    
            }
        }
    }
    return returnUnits;
};

const MedicationData = ({ group, instructionIcon, patientWeight}: IMedicationDataProps) => {
    const { data: baseSearchOption } = useGetInstructionOptionsQuery(null);

    const {
        numerator_units,
        numerator_value,
        numerator_per_kg_units,
        numerator_per_kg_value,
        denominator_units,
        denominator_value,
    } = getMedConcentrationElement(group, baseSearchOption, patientWeight);

    return (
        <div className='medication-data'>
            <div className='medication-data__dose-concentration'>
                {numerator_units && !isNaN(numerator_value) && (
                    <span>{numerator_value?.toLocaleString('en-US')} {numerator_units}</span>
                )}
                {numerator_per_kg_units && !isNaN(numerator_per_kg_value) && (
                    <span>{numerator_per_kg_value?.toLocaleString('en-US')} {numerator_per_kg_units}</span>
                )}
            </div>
            {instructionIcon}
            <div className='medication-data__route-freq'>
                {denominator_units && !isNaN(denominator_value) && (
                    <div className='medication-data__dose-administered'>
                        <span>{denominator_value?.toLocaleString('en-US')} {denominator_units}</span>
                    </div>
                )}

                {group.route_id &&
                    <div className='medication-data__route-id'>{group.route_id}</div>
                }

                {group.frequency &&
                    <OrderFrequency>{group.frequency}</OrderFrequency>
                }
            </div>
        </div>
    );
};

export default MedicationData;
