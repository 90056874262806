import { Col, Divider, Row, Space, Typography } from 'antd';
import image from 'assets/img/VEG_Marquee_CMYK_BLACK.png';
import moment from 'moment';
import { ReactElement } from 'react';
import { formatPhoneNumber, shortPetId } from 'utils/formatFuncs';
import { ConsentFormHeader } from 'utils/types/consentFormTypes';
import './consent-forms.css';


export const Header: React.FC<{
    title: string
    headerData: ConsentFormHeader
    formTime?:  string,
    hideBottomDivider?: boolean,
    formItem?: ReactElement
}> = ({ title, headerData, formTime, hideBottomDivider, formItem }) => {
    const displayTime = formTime ? formTime : moment().format('MMMM DD YYYY, h:mm:ss a')
    const isDoctorAssigned= !!headerData.doctor_first_name || !!headerData.doctor_last_name
    const doctor = (headerData.doctor_first_name || "") + " " + (headerData.doctor_last_name || "")

    return (
        <>
            <div className='document-header desktop-only'>
                <Space direction='vertical' size={16} style={{ display: 'flex' }}>
                    <Row justify='space-between'>
                        <Col>
                            <Space direction='vertical'>
                                <Col><img src={image} /></Col>
                                <Col><Typography.Title level={3}>{title}</Typography.Title></Col>

                            </Space>

                        </Col>

                        <Col span={6} className='header-info-text text-right' >
                            <Space direction='vertical' size={1}>
                                <p className='header-doctor-name wt600'>{isDoctorAssigned && <>Dr. {doctor}</>}</p>
                                <p className='veg-red'>{headerData.hospital_name}</p>
                                <p>{headerData.hospital_street}</p>
                                <p>{headerData.hospital_city} {headerData.hospital_state}, {headerData.hospital_zipcode}</p>
                                <p>{formatPhoneNumber(headerData.hospital_phone_number)}</p>
                            </Space>

                        </Col>
                    </Row>
                    <Row justify='space-between'>
                        <Space direction='vertical' size={1}>
                            <Typography.Title level={4}>{headerData.pet_name}</Typography.Title>
                            <Typography.Text className='veg-grey'>
                                <Typography.Text className='veg-grey wt600' >Pet Id:  </Typography.Text>
                                {shortPetId(headerData.pet_id)}
                            </Typography.Text>
                        </Space>
                        <Space direction='vertical' size={1} className='header-info-text text-right'>
                            {formItem ?? <Typography.Text>{displayTime}</Typography.Text>}
                        </Space>
                    </Row>
                </Space>
                {!hideBottomDivider && <Divider />}
            </div>
            <div className='document-header mobile-only'>
                <Space direction='vertical' size={8} className='header-hospital-info' style={{ display: 'flex' }}>
                    <Col><img src={image} /></Col>
                    <Col><Typography.Title level={4}>{title}</Typography.Title></Col>
                    <Col><Typography.Text className='header-doctor-name wt600'>{isDoctorAssigned && <>Dr. {doctor}</>}</Typography.Text></Col>
                    <Col><Typography.Text className='veg-red'>{headerData.hospital_name}</Typography.Text></Col>
                    <Col><Typography.Text>{displayTime}</Typography.Text></Col>
                    <Col><Typography.Title level={5}>{headerData.pet_name}</Typography.Title></Col>
                    <Col>
                        <Typography.Text className='veg-grey'>
                            <Typography.Text className='veg-grey wt600' >Pet Id:  </Typography.Text>
                            {shortPetId(headerData.pet_id)}
                        </Typography.Text>

                    </Col>
                </Space>
            </div>

        </>
    );
};

export const SectionHeader: React.FC<{
    title: string; headerData: ConsentFormHeader; formTime?:  string;
}> = ({ title, headerData, formTime }) => {
    const displayTime = formTime ? formTime : moment().format('MMMM DD YYYY, h:mm:ss a')

    const isDoctorAssigned= !!headerData.doctor_first_name || !!headerData.doctor_last_name
    const doctor = (headerData.doctor_first_name || "") + " " + (headerData.doctor_last_name || "")

    return (
        <div className='desktop-only'>
            <Space direction='vertical' size={16} style={{ display: 'flex' }}>
                <Row justify='space-between'>
                    <Col>
                        <Space direction='horizontal'>
                            <img src={image} style={{
                                width: '170px',
                                height: '12.49px',
                            }} />
                            <Divider type='vertical' />
                            <Typography.Text strong>{title}</Typography.Text>
                        </Space>

                    </Col>

                    <Col span={6} className='text-right header-doctor-name'>
                        <Typography.Text strong>{isDoctorAssigned && <>Dr. {doctor}</>}</Typography.Text>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Space direction='vertical' size={2}>
                        <Col><Typography.Text strong>{headerData.pet_name}</Typography.Text></Col>
                        <Col>
                            <Typography.Text className='veg-grey'>
                                <Typography.Text className='veg-grey wt600' >Pet Id:   </Typography.Text>
                                {shortPetId(headerData.pet_id)}
                            </Typography.Text>

                        </Col>
                    </Space>
                    <Col span={6} className='text-right header-info-text'><Typography.Text>{displayTime}</Typography.Text></Col>
                </Row>
            </Space>
            <Divider />
        </div>
    );
};
