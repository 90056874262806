import { Button, Form, FormInstance } from 'antd';
import React from 'react';
import { PackageFormInstance } from '..';
import StartEndTimeFormItems from '../StartEndTimeFormItems';
import './UpdateAllTimesFormItem.css';

interface UpdateAllTimesFormItemProps {
    form: FormInstance<PackageFormInstance>;
}

const UpdateAllTimesFormItem: React.FC<UpdateAllTimesFormItemProps> = ({ form }) => {
    const handleChangeAllTimes = () => {
        const start_time = form.getFieldValue('start_time');
        const end_time = form.getFieldValue('end_time');
        const values = form.getFieldsValue();
        const itemsToSet = { ...values };

        if (values.diagnostics) {
            itemsToSet.diagnostics = values.diagnostics.map((d: any) => ({ ...d, start_time, end_time }));
        }
        if (values.fluids) {
            itemsToSet.fluids = values.fluids.map((f: any) => ({ ...f, start_time, end_time }));
        }
        if (values.medications) {
            itemsToSet.medications = values.medications.map((m: any) => ({ ...m, start_time, end_time }));
        }
        if (values.tasks) {
            itemsToSet.tasks = values.tasks.map((t: any) => ({ ...t, start_time, end_time }));
        }

        form.setFieldsValue(itemsToSet);
    };

    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        return prev.start_time !== curr.start_time || prev.end_time !== curr.end_time;
    };

    return (
        <div className='update-all-times-container'>
            <StartEndTimeFormItems
                startTimeFormFieldName='start_time'
                startTimeLabel='Start time'
                endTimeFormFieldName='end_time'
                endTimeLabel='End time'
                isEditing
                hideMobileHeader
            />

            <Form.Item shouldUpdate={handleShouldUpdate}>
                {({ getFieldValue }) => {
                    const start_time = getFieldValue('start_time');
                    const end_time = getFieldValue('end_time');

                    return (
                        <Button type='primary' disabled={end_time && end_time < start_time} onClick={handleChangeAllTimes}>
                            Update all times
                        </Button>
                    );
                }}
            </Form.Item>
        </div>
    );
};

export default UpdateAllTimesFormItem;
