import { convertEstimateItemToLocal, convertToAPI } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import {
	backendCriEstimateItemToFrontendCriEstimateItem,
	backendFluidEstimateItemToFrontendFluidEstimateItem,
	EstimateCreateBody,
	EstimateItem,
	EstimateOrderBody,
	isInstanceOfAPICriEstimateItem,
	isInstanceOfAPIFluidEstimateItem,
	isInstanceOfCriEstimateCreate,
	isInstanceOfCriEstimateCreateAPi,
	isInstanceOfMedicalEstimateCreate,
	isInstanceOfMedicalEstimateItem,
	isInstanceOfOutOfEstimateFieldsNonMedical,
} from 'utils/types/billingTypes';
import { TagType } from 'utils/types/enums';
import { OutgoingNonMedicalOrder } from 'utils/types/InstructionOrderTypes';
import { instructionApi } from './instructionApi';

// Define a service using a base URL and expected endpoints
export const nonMedicalService = instructionApi.injectEndpoints({
	endpoints: (builder) => ({
		addNonMedOrder: builder.mutation<
			null,
			{ visitId: number; body: OutgoingNonMedicalOrder }
		>({
			query({ visitId, body }) {
				return {
					url: `visit/${visitId}/non_medical`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Instruction, id: visitId },
				{ type: TagType.Estimate, id: visitId }, { type: TagType.Estimate, id: visitId },

			],
		}),
	}),
});

export const {

	useAddNonMedOrderMutation
} = nonMedicalService;
