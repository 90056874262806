import { Tooltip } from 'antd';
import { useAppDispatch } from 'hooks/useRedux';
import React from 'react';
import { setFluidAdditiveData, setIsFluidAdditiveModalVisible } from 'store/slices/treatmentSheetSlice';
import { FluidAdditive } from 'utils/dataTypes';

interface TreatmentSheetFluidAdditiveMarkerProps {
    fluidAdditives: FluidAdditive[];
    visitId: number;
    getItemProps: any;
    itemStyle: any;
    itemContext: any;
}

export const TreatmentSheetFluidAdditiveMarker: React.FunctionComponent<TreatmentSheetFluidAdditiveMarkerProps> = ({
    fluidAdditives,
    getItemProps,
    itemStyle,
    itemContext,
}) => {
    const appDispatch = useAppDispatch();
    const { onMouseDown, onMouseUp, onTouchStart, onTouchEnd, ...itemProps } = getItemProps();
    const tooltipTitle = fluidAdditives.map((additive) => {
        return <div>{`${additive.short_name || additive.name} ${additive.discontinued_at ? 'Discontinued' : 'Added'}`}</div>;
    });
    const getContent = () => {
        return <div>({fluidAdditives.length})</div>;
    };

    const handleClick = () => {
        appDispatch(setIsFluidAdditiveModalVisible(true));
        appDispatch(setFluidAdditiveData(fluidAdditives));
    };

    return (
        <div onAnimationStart={(e) => e.stopPropagation()}>
            <div
                {...itemProps}
                style={itemStyle}
                onClick={handleClick}
                className='rct-item fluid-additive-marker'
                data-cy={'treatmentSheetMarker'}
            >
                <Tooltip title={<>{tooltipTitle}</>}>
                    <div
                        className='rct-item-content additive-marker-content'
                        style={{
                            maxHeight: `${itemContext.dimensions.height}`,
                        }}
                    >
                        {getContent()}
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};
