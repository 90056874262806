import { ExportOutlined, SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Modal, Spin } from 'antd';
import { DiagnosticOrder } from 'components/forms/DiagnosticOrder';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useGetIdexxRefLabsQuery } from 'services/instructionService';
import { FormName } from 'utils/constants';
import './IdexxReferenceLabsModal.css';
import ReferenceLabItem from './ReferenceLabItem';

interface IdexxReferenceLabsModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;

    handleSelect?: Function;
}

const IdexxReferenceLabsModal: React.FC<IdexxReferenceLabsModalProps> = ({ open, setOpen, handleSelect }) => {
    const [search, setSearch] = useState('');

    const { data, isFetching } = useGetIdexxRefLabsQuery();

    const { addComposeBox } = useComposeBoxContext();

    const filteredData = useMemo(() => {
        const searchString = search.replace(/[-[\]{}()*+?.,\\^$|#]/g, '');

        if (!searchString) return [];

        return data?.filter((refLab) => {
            if (searchString === '') return refLab;

            const regex = new RegExp(searchString, 'gi');
            const stringToSearch = refLab.name.replace(/[-[\]{}()*+?.,\\^$|#]/g, '');

            return stringToSearch.match(regex);
        });
    }, [search, data]);

    const handleClick = (fullSelectedItem: any) => {
        if (handleSelect) {
            handleSelect(fullSelectedItem);
        } else {
            addComposeBox({
                formName: FormName['diag_order'],
                content: <DiagnosticOrder options={fullSelectedItem} />,
            });
        }
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
        setSearch('');
    };

    return (
        <Modal
            bodyStyle={{ maxHeight: '68vh', overflow: 'auto' }}
            className='idexx-reference-lab-modal'
            footer={[<Button onClick={handleClose}>Cancel</Button>]}
            maskClosable={false}
            open={open}
            title='Idexx Reference Lab'
            width='520px'
            onCancel={handleClose}
            destroyOnClose
        >
            <Alert
                message={
                    <>
                        All orders have a frequency of <strong>Once</strong>.
                    </>
                }
                showIcon
            />

            <Input
                placeholder='Search'
                suffix={<SearchOutlined />}
                onChange={debounce((e) => {
                    if (e.target.value.length === 0 || e.target.value.length >= 3) {
                        setSearch(e.target.value);
                    }
                }, 500)}
            />

            <a
                className='idexx-reference-lab__details-link'
                href='https://www.idexx.com/en/veterinary/reference-laboratories/tests-and-services/'
                rel='noreferrer'
                target='_blank'
            >
                View full details of tests <ExportOutlined />
            </a>

            <span>{search ? filteredData?.length : data?.length} items</span>

            {isFetching ? (
                <Spin spinning={isFetching} />
            ) : (
                filteredData?.map((item) => (
                    <ReferenceLabItem key={item.id} item={item} fromEstimate={!!handleSelect} handleClick={handleClick} />
                ))
            )}
        </Modal>
    );
};

export default IdexxReferenceLabsModal;
