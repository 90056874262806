import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.09116 1.177H0.942846C0.669588 1.177 0.532959 1.63697 0.532959 1.7903V14.0562C0.532959 14.3629 0.532959 14.8228 0.942846 14.8228H13.786C14.3325 14.8228 14.4691 14.0562 14.4691 13.7496C14.4691 13.4429 7.36442 1.177 7.09116 1.177Z" stroke="currentColor"/>
        <path d="M6.04942 5.5321H4.59773V7.27413H2.85571V9.01615H4.59773V10.7582H6.04942V9.01615H7.79144V7.27413H6.04942V5.5321Z" stroke="currentColor"/>
        <path d="M8.08203 2.91919H14.1856C14.2802 3.08452 14.4694 3.51438 14.4694 3.91117C14.4694 4.40717 14.4694 13.5004 14.4694 13.831C14.4694 14.0955 14.0909 14.6026 13.9017 14.823" stroke="currentColor"/>
    </svg>
);

export const OrderIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={svg} {...props} />;