import { Button, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    useApproveEstimateFormMutation,
    useGetEstimateFormQuery,
} from 'services/consentService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import BurialForm from './BurialForm';
import EuthanasiaForm from './EuthanasiaForm';
import MedicationConsentForm from './MedicationConsentForm';
import PrintableEstimate from './PrintableEstimate';
import PrintableForm from './PrintableForm';
import { ConsentFormError, ConsentFormSuccess } from './Result';
import './consent-forms.css';

export interface IFormProps {
    formId: string;
}

export const TreatmentPlanForm: React.FC<IFormProps> = ({ formId }) => {
    const { data: estimateFormData, error, refetch: refetchEstimateFormData, isFetching: isFetchingEstimateFormData } = useGetEstimateFormQuery(
        formId,
        BASE_QUERY_OPTIONS,
    );
    const history = useHistory();
    const [approveEstimate] = useApproveEstimateFormMutation();
    const [approved, setApproved] = useState(false);
    const getShowOptInMarketingEmail = () => {
        if (!estimateFormData?.owner_id || estimateFormData?.has_approved_cremation_or_euthanasia) {
            return false;
        }
        return !estimateFormData?.pet_deceased_at && estimateFormData?.opt_in_marketing_email === null;
    };
    const showOptInMarketingEmail = getShowOptInMarketingEmail();
    const onApprove = (checkedOptInMarketingEmail?: boolean) => {
        approveEstimate({
            FormId: formId,
            body: {
                checked_opt_in_marketing_email: checkedOptInMarketingEmail
            },
        }).then((response) => {
            if ('data' in response) {
                refetchEstimateFormData().then(()=>{
                    setApproved(true);
                });
            }
        });
    };

    useEffect(() => {
        if (estimateFormData?.is_approved) {
            setApproved(true);
        }
    }, [estimateFormData]);

    if (error) {
        return (
            <ConsentFormError />
        );
    }

    if (!estimateFormData || isFetchingEstimateFormData) {
        return (
            <Spin
                size='large'
                style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />
        );
    }

    if (approved) {
        return (
            <ConsentFormSuccess
                title='Your consent has been received.'
                extraContent={
                    estimateFormData?.payment_link_id ?
                    (
                        <>
                            <Button
                                type='primary'
                                onClick={() => {
                                    history.push(`/checkout/${estimateFormData?.payment_link_id}`);
                                }}
                            >
                                Make Prepayment by Credit Card
                            </Button>
                            <div style={{ marginTop: '48px' }}>
                                <span style={{ fontWeight: 600 }}>Paying by check, cash, or other?</span> Please see our team for assistance.
                            </div>
                        </>
                    )
                    : undefined
                }
            />
        );
    }

    return (
        <PrintableForm
            title='Treatment Plan Estimate'
            headerData={estimateFormData}
            onApprove={onApprove}
            approved={approved}
            approveText={
                'I acknowledge that I have read the terms of this treatment plan and approve the full amount of the estimate.'
            }
            acceptButtonText={'Approve Estimate'}
            showOptInMarketingEmail={showOptInMarketingEmail}
        >
            <PrintableEstimate estimateFormData={estimateFormData} />
        </PrintableForm>
    );
};

export const PageBreaker = () => (
    <div style={{ pageBreakAfter: 'always' }}></div>
);

const ConsentForms: React.FC<{}> = (props) => {
    let { urlFormId } = useParams<{ urlVisitId: string; urlFormId: string }>();
    const formIdentifier = urlFormId.charAt(0);

    if (formIdentifier === 'a') {
        return <BurialForm formId={urlFormId} />;
    } else if (formIdentifier === 'b') {
        return <TreatmentPlanForm formId={urlFormId} />;
    } else if (formIdentifier === 'c') {
        return <EuthanasiaForm formId={urlFormId} />;
    } else if (formIdentifier === 'f') {
        return <MedicationConsentForm formId={urlFormId} />;
    }
    return <h1>No Implementation</h1>;
};

export default ConsentForms;
