import { Tag } from "antd";
import moment from "moment";
import { CSSProperties } from "react";
import { EstimateTagStatus } from "utils/types/estimateTypesNew";

interface EstimateTagProps {
    status: EstimateTagStatus;
    approvedDate: number | null;
    customTitle?: string;
    style?: CSSProperties;
}
export const EstimateTag = ({ status, approvedDate, customTitle, style }: EstimateTagProps) => {
    const tagLibrary: {
        [key in EstimateTagStatus]: {
            color: string;
            title: string;
        };
    } = {
        draft: {
            color: 'volcano',
            title: 'Draft',
        },
        created: {
            color: 'volcano',
            title: 'Draft',
        },
        sent: {
            color: 'purple',
            title: 'Pending Approval',
        },
        declined: {
            color: 'blue',
            title: 'Declined',
        },
        approved: {
            color: 'green',
            title: 'Approved',
        },
        nc: {
            color: 'red',
            title: 'NC',
        },
        np: {
            color: 'orange',
            title: 'NP',
        },
    };

    const { color, title } = tagLibrary[status] ? tagLibrary[status] : tagLibrary.draft;

    let displayTitle = title;
    if (status === 'approved' && approvedDate){
        const dateString = moment.unix(approvedDate).format("MM.DD.YYYY LT");
        displayTitle = `${title} - ${dateString}`
    }
    return <Tag data-cy={'estimateStatusTag'} color={color} style={style}>{(customTitle ?? displayTitle).toUpperCase()}</Tag>;
};
