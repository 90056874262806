import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import  './file_uploader.css'
import { useEffect, useState } from 'react';

interface FileUploaderProps {
    onChange: (info: UploadChangeParam<UploadFile<any>>) => void;
	onPreview?: (file: UploadFile<any>) => void;
    onRemove?: (file: UploadFile<any>) => boolean | void | Promise<boolean | void>;
    defaultFileList?: UploadFile[];
}

export const FileUploader: React.FC<FileUploaderProps> = ({
	onChange,
	onPreview,
    onRemove,
    defaultFileList,
}) => {
	const [istThereFile, setIsThereFile] = useState(defaultFileList!== undefined && defaultFileList?.length > 0)
	const [uploaderClass, setUploaderClass] = useState(istThereFile ? 'file-uploader-hidden' : '')

	useEffect(() => {
		setUploaderClass(istThereFile ?'file-uploader-hidden' : '')
	}, [istThereFile])

	return (

				<Upload
                    className={uploaderClass}
					maxCount={1}
					beforeUpload={file => {
						setIsThereFile(true)
						return false
					}}
					onPreview={onPreview}
					onRemove={(file) => {
						setIsThereFile(false)
						if (onRemove){
							return onRemove(file)
						}
					}}
					onChange={onChange}
					defaultFileList={defaultFileList}
					accept='.pdf, .png, .jpg, .doc, .docx'
				>
					<Button icon={<UploadOutlined />}>Click to upload</Button>
				</Upload>
	);
};
