import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { ElementRef, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { usePostCheckinTprInstructionsMutation } from 'services/instructionService';
import { useAddNewVisitMutation } from 'services/visitService';
import { appendRouteId } from 'utils/formatFuncs';
import { ROUTES } from 'utils/types/enums';
import CreatePatientNoteDrawer from './CreatePatientNoteDrawer';
import PatientItems from './PatientItems';
import './VisitsAndNotes.css';

interface VisitsProps {}

const VisitsAndNotes = (props: VisitsProps) => {
    const createPatientNoteRef = useRef<ElementRef<typeof CreatePatientNoteDrawer>>(null);
    const { pet_id } = useParams<{ pet_id: string }>();
    const history = useHistory();

    const [postCheckinTprInstructions] = usePostCheckinTprInstructionsMutation();
    const [createNewVisit] = useAddNewVisitMutation();

    return (
        <div className='patient-visits-and-notes-page'>
            <h1>Visits and Notes</h1>

            <div className='patient-visits-and-notes-page__button-container'>
                <Button
                    onClick={() => {
                        createNewVisit({ patientId: pet_id })
                            .unwrap()
                            .then((data) => {
                                postCheckinTprInstructions({ visitId: data.id });
                                history.push(appendRouteId(ROUTES.visit, String(data.id)));
                            })
                            .catch((e) => {
                                message.error('There was an error creating the visit');
                                console.error(e);
                            });
                    }}
                >
                    <PlusOutlined /> New Visit
                </Button>

                <Button
                    onClick={() => {
                        createPatientNoteRef.current?.openCreatePatientNoteDrawer();
                    }}
                >
                    <PlusOutlined /> New Note
                </Button>
            </div>

            {<PatientItems pet_id={pet_id} setNoteToUpdate={(note) => createPatientNoteRef.current?.setNoteToUpdate(note)} />}

            <CreatePatientNoteDrawer ref={createPatientNoteRef} />
        </div>
    );
};

export const Separator = () => <span className='visits-and-note-card__separator'> | </span>;

export default VisitsAndNotes;
