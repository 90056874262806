export const reasons = {
    'Customer': [
        'New Medical Inquiry',
        'Non-Medical Inquiry',
        'Inpatient Status Update',
        'Follow Up On Patient',
        'Other',
    ],
    'rDVM': [
        'Direct Referral',
        'Record Request',
        'Patient Status Update',
        'Treatment Questions',
        'Other Questions',
    ],
}

export const recommendationsOptions = [
    { label: 'Come In', value: 'Come In' },
    { label: 'Referred Elsewhere', value: 'Referred Elsewhere' },
    { label: 'Monitor at Home', value: 'Monitor at Home' },
    { label: 'Resolved on the Phone', value: 'Resolved on the Phone' },
]

export const referredWhereOptions = [
    { label: 'GP', value: 'GP' },
    { label: 'Specialty Hospital', value: 'Specialty Hospital' },
    { label: 'Another VEG', value: 'Another VEG' },
    { label: 'Another ER', value: 'Another ER' },
    { label: 'Shelter / Wildlife Facility', value: 'Shelter / Wildlife Facility' },
]

export const getReferredWhyOptions = (referredWhere: string | undefined) => {
    switch (referredWhere) {
        case 'GP':
            return [
                { label: 'Not an Emergency', value: 'Not an Emergency' },
                { label: 'Financial Concerns', value: 'Financial Concerns' },
                { label: 'Closer for customer', value: 'Closer for customer' },
                { label: 'Too busy / Unable to see patient', value: 'Too busy / Unable to see patient' },
                { label: 'Other', value: 'Other' },
            ]
        case 'Specialty Hospital':
            return [
                { label: 'Requires Specialty Care', value: 'Requires Specialty Care' },
                { label: 'Closer for customer', value: 'Closer for customer' },
                { label: 'Too busy / Unable to see patient', value: 'Too busy / Unable to see patient' },
                { label: 'Other', value: 'Other' },
            ]

        case 'Another VEG':
            return [
                { label: 'Closer for customer', value: 'Closer for customer' },
                { label: 'Too busy / Unable to see patient', value: 'Too busy / Unable to see patient' },
                { label: 'Other', value: 'Other' },
            ]

        case 'Another ER':
            return [
                { label: 'Closer for customer', value: 'Closer for customer' },
                { label: 'Too busy / Unable to see patient', value: 'Too busy / Unable to see patient' },
                { label: 'Other', value: 'Other' },
            ]

        case 'Shelter / Wildlife Facility':
            return [
                { label: 'Stray', value: 'Stray' },
                { label: 'Wildlife', value: 'Wildlife' },
                { label: 'Financial Concerns', value: 'Financial Concerns' },
                { label: 'Other', value: 'Other' },
            ]
        case undefined:
            break
    }
}

interface CheckinLinkProperties {
    enabled: boolean,
    choice?: boolean,
    default?: boolean,
}

interface CallLogFlowCallerType {
    label: string,
    reasons?: {
        label: string,
        recommendations?: {
            label: string,
            referredWhere?: {
                label: string,
                referredWhy?: {
                    label: string,
                }[],
            }[],
            overrideCheckinLinkProperties?: CheckinLinkProperties
        }[],
        checkinLinkProperties: CheckinLinkProperties
    }[],
}

export const callLogFlow: CallLogFlowCallerType[] = [
    {
        label: 'Customer',
        reasons: [
            {
                label: 'New Medical Inquiry',
                checkinLinkProperties: {
                    enabled: true,
                    choice: true,
                    default: false
                },
                recommendations: [
                    {
                        label: 'Come In',
                        overrideCheckinLinkProperties: {
                            enabled: true,
                            choice: true,
                            default: true
                        }
                    },
                    {
                        label: 'Referred Elsewhere',
                        referredWhere: [
                            {
                                label: 'GP',
                                referredWhy: [
                                    { label: 'Not an Emergency' },
                                    { label: 'Financial Concerns' },
                                    { label: 'Closer for customer' },
                                    { label: 'Too busy / Unable to see patient' },
                                    { label: 'Other' },
                                ]
                            },
                            {
                                label: 'Specialty Hospital',
                                referredWhy: [
                                    { label: 'Requires Specialty Care' },
                                    { label: 'Closer for customer' },
                                    { label: 'Too busy / Unable to see patient' },
                                    { label: 'Other' },
                                ]
                            },
                            {
                                label: 'Another VEG',
                                referredWhy: [
                                    { label: 'Closer for customer' },
                                    { label: 'Too busy / Unable to see patient' },
                                    { label: 'Other' },
                                ]
                            },
                            {
                                label: 'Another ER',
                                referredWhy: [
                                    { label: 'Closer for customer' },
                                    { label: 'Too busy / Unable to see patient' },
                                    { label: 'Other' },
                                ]
                            },
                            {
                                label: 'Shelter / Wildlife Facility',
                                referredWhy: [
                                    { label: 'Stray' },
                                    { label: 'Wildlife' },
                                    { label: 'Financial Concerns' },
                                    { label: 'Other' },
                                ]
                            }
                        ]
                    },
                    { label: 'Monitor at Home' },
                    { label: 'Resolved on the Phone' }
                ]
            },
            {
                label: 'Non-Medical Inquiry',
                checkinLinkProperties: {
                    enabled: true,
                    choice: true,
                    default: false
                }
            },
            {
                label: 'Inpatient Status Update',
                checkinLinkProperties: {
                    enabled: false
                }
            },
            {
                label: 'Follow Up On Patient',
                checkinLinkProperties: {
                    enabled: false
                }
            },
            {
                label: 'Other',
                checkinLinkProperties: {
                    enabled: true,
                    choice: true,
                    default: false
                }
            }
        ]
    },
    {
        label: 'rDVM',
        reasons: [
            { label: 'Direct Referral', checkinLinkProperties: { enabled: false } },
            { label: 'Record Request', checkinLinkProperties: { enabled: false } },
            { label: 'Patient Status Update', checkinLinkProperties: { enabled: false } },
            { label: 'Treatment Questions', checkinLinkProperties: { enabled: false } },
            { label: 'Other Questions', checkinLinkProperties: { enabled: false } },
        ]
    },
    {
        label: 'Other'
    }
]

