import { Modal } from "antd";
import { CustomerInfo } from "utils/dataTypes";

interface SendEstimateModalProps {
    contact?: CustomerInfo;
    onOk: () => void;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SendEstimateModal = ({contact, onOk, isOpen, setIsOpen}: SendEstimateModalProps) => {
    if (!contact){
        return (<></>)
    }
    return (
    <Modal
        centered={true}
        width={'475px'}
        okText={'Send'}
        title={"Send Estimate Consent Link"}
        open={isOpen}
        onOk={() => {
            onOk();
            setIsOpen(false)
        }}
        onCancel={() => {
            setIsOpen(false)
        }}
        okButtonProps={{
            autoFocus: true
        }}
        destroyOnClose
    >
        {`A text message containing a link to the Estimate Consent Form will be sent to ${contact.first_name} ${contact.last_name ?? ''} at ${contact.phone_number}`}.
    </Modal>
    )
}
