import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const NoChargeIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon
            component={() => (
                <svg
                    viewBox='0 0 22 22'
                    focusable='false'
                    data-icon='swap'
                    width='17px'
                    height='17px'
                    fill='currentColor'
                    aria-hidden='true'
                >
                    <path
                        d='M12 1.5C6.202 1.5 1.5 6.202 1.5 12 1.5 17.8 6.202 22.5 12 22.5S22.5 17.8 22.5 12c0-5.798-4.702-10.5-10.5-10.5Zm0 19.219A8.72 8.72 0 0 1 3.281 12 8.72 8.72 0 0 1 12 3.281a8.72 8.72 0 0 1 8.719 8.72A8.72 8.72 0 0 1 12 20.718Zm1.118-9.263-.595-.138V8.17c.89.122 1.441.68 1.535 1.364a.185.185 0 0 0 .185.162h1.052a.19.19 0 0 0 .188-.206c-.143-1.46-1.346-2.398-2.951-2.56v-.766a.188.188 0 0 0-.188-.187h-.658a.188.188 0 0 0-.188.187v.774c-1.659.161-2.957 1.078-2.957 2.789 0 1.584 1.167 2.348 2.393 2.641l.579.148v3.344c-1.036-.138-1.618-.691-1.737-1.436a.187.187 0 0 0-.185-.155H8.508a.188.188 0 0 0-.188.204c.106 1.289 1.083 2.475 3.17 2.627v.736c0 .103.083.188.187.188h.665a.189.189 0 0 0 .188-.19l-.005-.743c1.835-.162 3.148-1.144 3.148-2.907-.003-1.626-1.036-2.353-2.555-2.728Zm-1.608-.38a3.437 3.437 0 0 1-.351-.117c-.793-.285-1.16-.747-1.16-1.342 0-.851.644-1.336 1.511-1.447v2.907Zm1.013 4.791v-3.133c.072.02.138.037.206.051 1.109.338 1.481.806 1.481 1.526 0 .916-.689 1.467-1.687 1.556Z'
                        fill='currentColor'
                    />
                    <path stroke='currentColor' stroke-width='1.5' d='m18.55 4.51-13 14' />
                </svg>
            )}
            {...props}
        />
    );
};

export default NoChargeIcon;
