import { Select, SelectProps } from 'antd';
import React from 'react';
import { useGetDoctorsInMyHospitalQuery } from 'services/userService';

const SelectDoctor: React.FC<SelectProps<number>> = (props) => {
    const { data: usersForHospital, isFetching: fetchingUsersForHospital } = useGetDoctorsInMyHospitalQuery();

    return (
        <Select
            disabled={fetchingUsersForHospital}
            loading={fetchingUsersForHospital}
            options={usersForHospital?.map((u) => ({ label: `${u.full_name}`, value: u.user_id }))}
            filterOption={(inputValue, option) => (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            showSearch
            {...props}
        />
    );
};

export default SelectDoctor;
