import { FormInstance, Modal, Spin, Typography, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AdministerMedicationForm } from 'components/forms/AdministerMedicationForm';
import { FormWrapper } from 'components/forms/FormWrapper';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { useGetIndividualInstructionQuery } from 'services/instructionService';
import { setActiveActionModal, setActiveTreatmentSheetAsk } from 'store/slices/treatmentSheetSlice';
import { FormName } from 'utils/constants';
import {
    ExistingCriInstruction,
    ExistingDiagInstruction,
    ExistingFluidInstruction,
    ExistingMedInstruction,
    INSTRUCTION_STATUSES,
    PimsUser,
    instructionType,
    isInstanceOfExistingFluidInstruction,
} from 'utils/dataTypes';

type MarkerType = 'note' | 'action' | 'encounter' | undefined;

interface TreatmentSheetMarkerModalProps {
    allInstructions: instructionType[];
    onModalFinish: Function;
    setModalState: Function;
    modalForm: FormInstance<any>;
    loggedInUserData: PimsUser | undefined;
    isFinalizedVisit?: boolean;
    patientWeight?: number;
    patientName: string;
}

export const TreatmentSheetMarkerModal: React.FunctionComponent<TreatmentSheetMarkerModalProps> = ({
    allInstructions,
    loggedInUserData,
    onModalFinish,
    setModalState,
    modalForm,
    isFinalizedVisit,
    patientWeight,
    patientName,
}) => {
    const appDispatch = useAppDispatch();
    const { activeTreatmentSheetAsk } = useAppSelector((state) => state.treatmentSheetSlice);
    const activeActionModal = useAppSelector((state) => state.treatmentSheetSlice.activeActionModal);

    const { data: parentOrder, isFetching: isInstructionsFetching } = useGetIndividualInstructionQuery(
        {
            typeId: activeTreatmentSheetAsk?.type_id ?? '',
            instructionId: activeTreatmentSheetAsk?.instruction_id ?? 0,
        },
        { skip: !activeTreatmentSheetAsk || !activeTreatmentSheetAsk?.type_id, refetchOnMountOrArgChange: true },
    );

    useEffect(() => {
        if (!isInstructionsFetching) {
            const newAction = parentOrder?.actions.find((order) => order.due_at === activeTreatmentSheetAsk?.due_at);

            if (newAction) {
                appDispatch(setActiveTreatmentSheetAsk({ ...activeTreatmentSheetAsk, ...newAction }));
            }
        }
    }, [parentOrder, isInstructionsFetching]);

    const [statusValue, setStatusValue] = useState<INSTRUCTION_STATUSES>(activeTreatmentSheetAsk?.status || 'scheduled');

    let itemType: MarkerType;
    let modalContent: JSX.Element;
    let title: string;
    let onOk: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    let okText: string | undefined;
    let displayCancelButton: boolean;
    const [form] = useForm();

    const getOkText = () => {
        if (activeTreatmentSheetAsk?.status === 'complete') {
            return 'Modify';
        }
        if (parentOrder?.type_id === 'C' || parentOrder?.type_id === 'F') {
            if (activeActionModal === 'CompleteActionModal' || statusValue === 'complete') {
                if (activeTreatmentSheetAsk?.value) {
                    return 'Mark as Complete';
                } else {
                    return 'Start Fluids';
                }
            }
        }

        if (parentOrder?.type_id === 'OT' && (activeActionModal === 'CompleteActionModal' || statusValue === 'complete')) {
            return 'Start Oxygen';
        }

        if (statusValue === 'complete') {
            return 'Mark as Complete';
        }
        return 'Submit';
    };

    if (!activeTreatmentSheetAsk) {
        modalContent = <div>No Item Selected</div>;
        title = '';
        displayCancelButton = true;
    } else if (!activeTreatmentSheetAsk?.category) {
        itemType = 'action';
        const formDisplay = Boolean(parentOrder) ? (
            <AdministerMedicationForm
                action={activeTreatmentSheetAsk}
                allInstructions={allInstructions}
                instruction={
                    parentOrder as ExistingDiagInstruction | ExistingMedInstruction | ExistingFluidInstruction | ExistingCriInstruction
                }
                user={loggedInUserData as PimsUser}
                onModalFinish={onModalFinish}
                setModalState={setModalState}
                modalForm={modalForm}
                patientWeight={patientWeight}
                patientName={patientName}
                statusValue={statusValue}
                setStatusValue={setStatusValue}
            />
        ) : (
            <></>
        );
        modalContent = (
            <FormWrapper
                style={{ width: '100%' }}
                form={form}
                onFormChange={form.setFieldsValue}
                getFormData={form.getFieldsValue}
                onFinish={(values: any) => {
                    appDispatch(setActiveActionModal(null));
                    appDispatch(setActiveTreatmentSheetAsk(null));
                    onModalFinish(values, FormName['med_admin'], { parentOrder: parentOrder });
                }}
                onFinishFailed={() => {}}
                bottomBar={<></>}
                gutter={0}
            >
                {formDisplay}
            </FormWrapper>
        );
        title = parentOrder?.name ?? '';
        onOk = () => {
            if (isInstanceOfExistingFluidInstruction(parentOrder)) {
                if (activeTreatmentSheetAsk.value === null && activeTreatmentSheetAsk.completed_at) {
                    if (
                        parentOrder.fluid_additives.some((additive) => !additive.initial_order) &&
                        statusValue != activeTreatmentSheetAsk.status
                    ) {
                        message.error("Fluids with additives added after start can't be modified.");
                        return;
                    }
                }
            }
            form.submit();
        };

        okText = getOkText();
        displayCancelButton = true;
    } else if (activeTreatmentSheetAsk.category === 'encounter') {
        itemType = 'encounter';
        modalContent = <></>;
        title = '';
        onOk = () => {
            appDispatch(setActiveTreatmentSheetAsk(null));
        };
        displayCancelButton = true;
    } else if (
        activeTreatmentSheetAsk.category === FormName.communication_note ||
        activeTreatmentSheetAsk.category === FormName.prog_note ||
        activeTreatmentSheetAsk.category === FormName.procedure_note ||
        activeTreatmentSheetAsk.category === FormName.recommendation_note
    ) {
        itemType = 'note';
        modalContent = (
            <div
                style={{
                    maxHeight: '350px',
                    overflowY: 'auto',
                }}
            >
                <Markdown remarkPlugins={[remarkBreaks]} children={activeTreatmentSheetAsk.content ?? ''} />
            </div>
        );
        title = activeTreatmentSheetAsk.category;
        onOk = () => {
            appDispatch(setActiveTreatmentSheetAsk(null));
        };
        okText = 'Close';
        displayCancelButton = false;
    } else {
        modalContent = <></>;
        title = '';
        onOk = () => {
            appDispatch(setActiveTreatmentSheetAsk(null));
        };
        displayCancelButton = true;
    }
    const cancelButtonProps = displayCancelButton ? undefined : { style: { display: 'none' } };

    return (
        <>
            <Modal
                width='80%'
                centered
                style={{ maxWidth: '520px', minWidth: '520px' }}
                open={!!activeTreatmentSheetAsk && (!isFinalizedVisit || itemType === 'note')}
                title={
                    <Typography.Text strong style={{ fontSize: 16 }}>
                        {title}
                    </Typography.Text>
                }
                destroyOnClose={true}
                onCancel={() => {
                    appDispatch(setActiveActionModal(null));
                    appDispatch(setActiveTreatmentSheetAsk(null));
                }}
                onOk={onOk}
                okText={okText}
                okButtonProps={{ autoFocus: parentOrder?.type_id !== 'D' }}
                cancelButtonProps={cancelButtonProps}
                maskClosable={false}
                data-cy="tsMarkerModal"
                className='treatment-sheet-marker-modal'
            >
                <Spin spinning={isInstructionsFetching}>{!isInstructionsFetching && modalContent}</Spin>
            </Modal>
        </>
    );
};
