import { DownOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { PHYSICAL_EXAM_VALUES } from '../../utils/constants';
import { ExamSection } from '../../utils/dataTypes';

type PHYSICAL_EXAM_KEYS = keyof typeof PHYSICAL_EXAM_VALUES;
interface PreviousPhysicalExamProps {
    examSections: ExamSection[];
    sectionType: PHYSICAL_EXAM_KEYS;
}

export const ExamCardSection = (props: PreviousPhysicalExamProps) => {
    const sectionTypeMapping = {
        NORMAL: {
            topClass: 'physical_exam_card__normal',
            title: 'Normal',
            defaultExpanded: false,
        },
        ABNORMAL: {
            topClass: 'physical_exam_card__abnormal',
            title: 'Abnormal',
            defaultExpanded: true,
        },
        NOT_OBSERVED: {
            topClass: 'physical_exam_card__not-observed',
            title: 'Not observed',
            defaultExpanded: false,
        },
    };
    const { examSections, sectionType } = props;
    const { topClass, title, defaultExpanded } = sectionTypeMapping[sectionType];
    const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

    return (
        <div className={`physical_exam_card ${topClass}`}>
            <div
                className='physical_exam_card__title'
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
            >
                <span>
                    {title} ({examSections.length})
                </span>

                <DownOutlined />
            </div>

            {isExpanded &&
                examSections.map((examSection) => (
                    <div key={examSection.section} className='physical_exam_data'>
                        <div className='physical_exam_data__name'>{examSection.section}</div>

                        {examSection.note && (
                            <div className='physical_exam_data__note'>
                                <Markdown remarkPlugins={[remarkBreaks]} children={examSection.note} />
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};
