import { FormName } from 'utils/constants';
import { useForm } from 'antd/lib/form/Form';
import PopoverClosableForm from 'components/popups/PopoverClosableForm';
import { CustomerInfoForm } from 'components/forms/CustomerInfoForm';
import { SelectOwner } from 'components/forms/SelectOwner';
import { CustomerInfo, PatientRecordVisit } from 'utils/dataTypes';
import { Popover } from 'antd';
import { useState } from 'react';
import { escapeClose } from 'utils/formFuncs';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';

interface ContactInfoPopoverProps {
    currentVisit: PatientRecordVisit;
    customerData?: CustomerInfo;
    visitOnFinish: (values: any, formName: FormName, additionalState?: {}) => void
}

const ContactInfoPopover: React.FC<ContactInfoPopoverProps> = ({
    currentVisit,
    customerData,
    visitOnFinish,
}) => {
    const disabled = false;
    const [contactInfoForm] = useForm();
    let returnPopover;
    const [popoverState, setPopOverState] = useState(false);
    const {addComposeBox} = useComposeBoxContext();
    if (!customerData) {
        returnPopover = <Popover
            open={popoverState && !disabled}
            onOpenChange={(show) => {
                if (!disabled) {
                    setPopOverState(show)
                    escapeClose(
                        popoverState,
                        setPopOverState,
                    );
                }
            }}
            overlayClassName='owner-search-popover'
            trigger='click'
            title='Change Owner'
            content={
                <SelectOwner
                    visit_id={currentVisit.id}
                    pet_id={currentVisit.pet_id}
                    onCreateNewClick={(incomingCustomerInfo: CustomerInfo) => {
                        setPopOverState(false);
                        addComposeBox({
                            formName: FormName['owner_info'],
                            content: <CustomerInfoForm customer={ incomingCustomerInfo }/>,
                        });
                    }}
                />
            }
            placement='rightBottom'
        >
            Contact Info
        </Popover>

    } else {
        returnPopover = <PopoverClosableForm
            formName='owner_info'
            initialContent={{ ...customerData }}
            placement='rightBottom'
            additionalState={{ ...customerData }}
            form={contactInfoForm}
            onFinish={(values: any) => {
                return visitOnFinish(values, FormName['owner_info'], {
                    customerId: customerData.customer_id,
                });
            }}
            formJSX={() => {
                return <CustomerInfoForm customer={customerData} />;
            }}
            disabled={disabled}
        >
            Contact Info
        </PopoverClosableForm>
    }
    return (
        returnPopover
    );
};

export default ContactInfoPopover;
