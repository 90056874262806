import { EstimateStatus } from 'utils/types/estimateTypesNew';
import { pimsApi } from './pimsApi';

export interface WhiteboardItem {
    visit_id: number;
    pet_name: string;
    owner_last_name: string | null;
    species: string | null;
    breed: string | null;
    weight: number | null;
    reason_for_visit: string | null;
    arrival_time: number;
    doctor_name: string | null;
    nurse_name: string | null;
    vitals_count: number | null;
    rads_count: number | null;
    bw_count: number | null;
    diagnostics_count: number | null;
    meds_count: number | null;
    tasks_count: number | null;
    tgh_count: number | null;
    spiked_at: number | null;
    spiked_by: number | null;
    location_in_hospital: string | null;
    triage_level: WhiteboardItemStatus;
    estimates_statuses: EstimateStatus[];
    doctor_review: number | null;
    section: string;
    has_ledger: boolean;
}

enum WhiteboardItemStatus {
    STABLE,
    URGENT,
    CRITICAL,
}

interface Whiteboard {
    indicator_waiting_spike_3: number;
    indicator_awaiting_doctor: number;
    indicator_outpatient: number;
    indicator_inpatient: number;
    indicator_patients: number;
    visits: WhiteboardItem[];
}

const whiteboardService = pimsApi.injectEndpoints({
    endpoints: (builder) => ({
        getWhiteboardInfo: builder.query<Whiteboard, string | undefined>({
            query: (whiteBoardLoginLink?: string) => whiteBoardLoginLink ? `/whiteboard/${whiteBoardLoginLink}` : '/whiteboard',
            transformResponse: (response: { data: Whiteboard }) => {
                return response.data;
            },
        }),
    }),
});

export const { useGetWhiteboardInfoQuery } = whiteboardService;
