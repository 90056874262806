import React, { CSSProperties } from 'react';
import { Col, Form, Input } from 'antd';
import {
	ExistingDiagInstruction,
	ExistingMedInstruction,
	isInstanceOfMedicineInstruction,
} from '../../utils/dataTypes';
import { ORDER_INSTRUCTION_NOUNS } from '../../utils/constants';
const { TextArea } = Input;
const formItemStyle: CSSProperties = {
	width: '100%',
};

interface DiscontinueMedicationFormProps {
	instruction: ExistingMedInstruction | ExistingDiagInstruction;
}

export const DiscontinueMedicationForm = ({
	instruction,
}: DiscontinueMedicationFormProps) => {
	let instructionInfo;

	if (instruction && isInstanceOfMedicineInstruction(instruction)) {
		instructionInfo = (
			<>
				{' '}
				<Col span={24}>
					<h4>{`Dose: ${instruction.dose} ${instruction.dose_unit}`}</h4>
				</Col>
				<Col span={24}>
					<h4>{`Route: ${instruction.route_id}`}</h4>
				</Col>{' '}
				<Col span={24}>
					<h4>{`Frequency: ${instruction?.frequency}`}</h4>
				</Col>
			</>
		);
	}

	return (
		<>
			<Col span={24}>
				<h3 className='med-order-name-title'>
					<u>{`${
						ORDER_INSTRUCTION_NOUNS[instruction.type_id]
					} name: ${instruction?.name}`}</u>
				</h3>
			</Col>
			{instructionInfo}

			{/* {props.order?.admin_instructions && (
				<Col span={24}>
					<div>{`Additional instructions: ${props.order?.admin_instructions}`}</div>
				</Col>
			)} */}
			<Col span={24} style={{ marginTop: '12px' }}>
				<h3>
					Discontinuing this order will cancel all outstanding
					administrations. Are you sure you want to discontinue?
				</h3>
			</Col>
			<Form.Item
				name={'instructionId'}
				hidden
				initialValue={instruction?.id}
				style={formItemStyle}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<TextArea></TextArea>
			</Form.Item>
		</>
	);
};
