import { Form, InputNumber } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { useLazyGetSupplementalByIdQuery } from 'services/supplementalService';
import { BASE_QUERY_OPTIONS, FALLBACK_FLUID_ROUTES } from 'utils/constants';
import { PimsUser } from 'utils/dataTypes';
import {
    FluidSearchOption,
    PackageFluidInstruction,
    isInstanceOfFluidOption,
    isInstanceOfPackageFluidMedInstruction,
} from 'utils/types/InstructionOrderTypes';
import { FluidEstimateFormFields } from 'utils/types/billingTypes';
import { FluidEstimateRules } from 'utils/types/validations';
import { FluidRoute } from '../FluidOrder';
import { HiddenInput } from '../fields/HiddenInput';

const formItemStyle: CSSProperties = {
    width: '100%',
};

export const getEstimateFluidsDefaults = (
    dataSource: FluidSearchOption | PackageFluidInstruction,
    user?: PimsUser,
    route?: number,
): Partial<FluidEstimateFormFields> => {
    let defaultInstruction: Partial<FluidEstimateFormFields> = {};
    if (isInstanceOfPackageFluidMedInstruction(dataSource)) {
        defaultInstruction = {
            fluids_id: dataSource.fluids_id,
            type_id: dataSource.type_id,
            unit_cost_cents: dataSource.cents,
            name: dataSource.name,
            is_prn: false,
            prn_condition: null,
            priority: false,
            unit: 'minutes',
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            rate_ml_per_hr: dataSource?.rate_ml_per_hr || undefined,
            fluids_volume_ml: dataSource.fluids_volume_ml || undefined,
            route_id: dataSource.route_id,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null,
        };
    } else if (route !== undefined) {
        defaultInstruction = {
            fluids_id: dataSource.id,
            type_id: dataSource.type_id,
            unit_cost_cents: dataSource.cents,
            name: dataSource.name,
            is_prn: false,
            prn_condition: null,
            priority: false,
            unit: 'minutes',
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            rate_ml_per_hr: dataSource?.default_rate_ml_per_hr || undefined,
            fluids_volume_ml: dataSource?.default_volume_ml || undefined,
            route_id: FALLBACK_FLUID_ROUTES[route],
            supplemental_cost_cents: null,
            is_recurring_supplemental: null,
        };
    }
    return defaultInstruction;
};
export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';
interface FluidEstimateFormProps {
    dataSource: FluidSearchOption | PackageFluidInstruction;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
}
export const FluidEstimateForm = (props: FluidEstimateFormProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);
    const [trigger, { data: supplementalData }] = useLazyGetSupplementalByIdQuery();

    const { dataSource } = props;
    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 18 };

    const onFormChange = props.onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

    useEffect(() => {
        const defaultFluid = getEstimateFluidsDefaults(dataSource, loggedInUserData, 0);
        setRoute(defaultFluid.route_id ?? FALLBACK_FLUID_ROUTES[0]);
        onFormChange(defaultFluid);
        if (isInstanceOfFluidOption(dataSource) && dataSource.supplemental_id) {
            trigger({ supplemental_id: dataSource.supplemental_id });
        }
    }, [dataSource]);

    const [rate, setRate] = useState<number | undefined>(undefined);
    const [route, setRoute] = useState<string>(FALLBACK_FLUID_ROUTES[0]);

    useEffect(() => {
        if (supplementalData) {
            onFormChange({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    const fluidOrderRules: FluidEstimateRules = {
        fluids_volume_ml: [{ required: true }],
        rate_ml_per_hr: [
            { required: true },
            {
                validator: (_, value) => {
                    if (value <= 0) {
                        return Promise.reject(new Error('Fluid rate must be greater than 0'));
                    }

                    if (value < 0.01) {
                        return Promise.reject('The value cannot be smaller than 0.01');
                    }

                    return Promise.resolve();
                },
            },
        ],
        route_id: [{ required: true }],
        is_prn: [{ required: false, type: 'boolean' }],
        prn_condition: [],
    };

    return (
        <>
            <Form.Item<number>
                label='Bag Volume (mL)'
                preserve={false}
                name='fluids_volume_ml'
                style={formItemStyle}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                rules={fluidOrderRules.fluids_volume_ml}
                data-cy={'bagVolumeInput'}
            >
                <InputNumber<number>
                    autoFocus
                    type='number'
                    style={formItemStyle}
                    min={0}
                    onChange={(value) => {
                        onFormChange({
                            fluids_volume_ml: value,
                        });
                    }}
                />
            </Form.Item>
            <FluidRoute
                setRoute={setRoute}
                route={route}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFormChange={onFormChange}
                rules={fluidOrderRules.route_id}
            />
            <Form.Item
                label='Fluid rate (mL/hr)'
                preserve={false}
                name='rate_ml_per_hr'
                style={formItemStyle}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                rules={fluidOrderRules.rate_ml_per_hr}
                data-cy={'fluidRateInput'}
            >
                <InputNumber<number>
                    min={0}
                    value={rate}
                    style={formItemStyle}
                    onChange={(value) => {
                        setRate(value ?? 0);
                        onFormChange({
                            rate_ml_per_hr: value,
                        });
                    }}
                />
            </Form.Item>

            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='prn_condition' />
            <HiddenInput fieldName='fluids_id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='unit_cost_cents' />
            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='unit' />
            <HiddenInput fieldName='is_free' />
            <HiddenInput fieldName='reason' />
            <HiddenInput fieldName='ordered_by' />
            <HiddenInput fieldName='notes' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='supplemental_cost_cents' />
            <HiddenInput fieldName='is_recurring_supplemental' />
            <HiddenInput fieldName='priority' />

            {/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and FluidOrder */}
        </>
    );
};
