import { Button, Col, DatePicker, Input, Row, Space, Table, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table/Table';
import type { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useGetVisitListQuery } from 'services/visitService';
import { BASE_QUERY_OPTIONS, VISIT_STATUS_FILTERS } from '../../utils/constants';
import { capitalizeFirstLetter, DASHBOARD_STATUS_MAP } from '../../utils/stringFormatting';
import styles from './allvisits.module.css';
import { FilterFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { RangePickerProps } from 'antd/lib/date-picker';
import heartbreak from 'assets/img/heartbreak_icon_red.svg';
import { FilterOutlined } from '@ant-design/icons';
import { VisitPageVisit } from 'utils/dataTypes';

const { RangePicker } = DatePicker;

interface AllVisitsProps {}

interface DataType {
	key: React.Key;
	pet_name: string;
	signalment: string;
	vet_name: string;
	status: string;
	created_at: number;
	pet_deceased_at: number;
	finalized: boolean;
}

const AllVisits: React.FC<AllVisitsProps> = (props) => {
	const searchInput = useRef<any>(null);
	const history = useHistory();
	const [dateRange, setDateRange] = useState<moment.Moment[]>([]);
	const [selectedItems, setSelectedItems] = useState<any>([]);

	const { data: visitsList } = useGetVisitListQuery(null, {
		...BASE_QUERY_OPTIONS,
		refetchOnMountOrArgChange: true
	});

	function isDeceased(visit: any) {
		if (selectedItems.length === 0) {
			return true
		} else if (selectedItems.includes("Deceased") && visit.pet_deceased_at){
			return true
		} else if (selectedItems.includes("Alive") && !visit.pet_deceased_at) {
			return true
		} else {
			return false
		}
	  }

	const getVisitStatus = (visit: VisitPageVisit) => {
		return (visit.finalized_at && visit.status !== "noshow") 
			? "closed"
			: visit.status;
    };


	const formattedVisitList = useMemo(() => 
		visitsList?.map(visit => {
			const isDoctorAssigned = !!visit.doctor_first_name || !!visit.doctor_last_name;
			const doctor = (visit.doctor_first_name || "") + " " + (visit.doctor_last_name || "");
			const intactLetter = visit.pet_is_intact !== null && visit.pet_sex
				? visit.pet_sex === 'M'
					? visit.pet_is_intact ? 'I' : 'N'
					: visit.pet_is_intact ? 'I' : 'S'
				: '';
			const signalment = `${visit.pet_sex ?? ''}${intactLetter}${visit.pet_species ? ' '
				+ visit.pet_species : ''} ${visit.pet_breed ? ' (' + visit.pet_breed + ')' : ''}`;

			return {
				pet_name: `${visit.pet_name} ${visit.owner_last_name 
					?  `(${capitalizeFirstLetter(visit.owner_last_name)})`
					: ''}`,
				signalment,
				vet_name: isDoctorAssigned ? doctor : 'Unassigned',
				status: getVisitStatus(visit),
				created_at: visit.created_at,
				key: visit.visit_id,
				hospitalization_level: visit.hospitalization_level,
				pet_deceased_at : visit.pet_deceased_at ?? 0,
				finalized: visit.finalized_at ? true : false
			}
		}).filter(isDeceased)
	, [visitsList, selectedItems])

	const handleSearch = (
		confirm: (param?: FilterConfirmProps) => void
	) => {
		confirm();
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
	};

	const handleResetDate = (clearFilters: () => void) => {
		clearFilters();
		setDateRange([])
	};

	const disabledDate: RangePickerProps['disabledDate'] = current => {
		// Can not select days before today and today
		return current && current > moment().endOf('day');
	};
	  

	const getColumnSearchProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
			<Input
			  ref={searchInput}
			  data-cy="filterInput"
			  placeholder={'Search in filters'}
			  value={selectedKeys[0]}
			  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			  onPressEnter={() => handleSearch(confirm)}
			  style={{ marginBottom: 8, display: 'block' }}
			/>
			<Space>
				<Button
				onClick={() => clearFilters && handleReset(clearFilters)}
				type="link"
				size="small"
				color='grey'
				data-cy="resetFilterButton"
				style={{ width: 90 }}
			  >
				Reset
			  </Button>
			  <Button
				type="primary"
				onClick={() => handleSearch(confirm)}
				size="small"
				data-cy="applyFilterButton"
				style={{ width: 90 }}
			  >
				Apply
			  </Button>
			</Space>
		  </div>
		),
		filterIcon: (filtered: boolean) => (
		  <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value: any , record: DataType) =>
		  record[dataIndex]
			.toString()
			.toLowerCase()
			.includes((value as string).toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
		  if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		  }
		},
		render: text => text
	  });


	const getColumnSearchRangeProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
		filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<RangePicker
					// Todo: Check this if upgrading antd versions 
					// the type checker is wrong about the type for this field [moment.Moment] is a valid value
					// @ts-ignore: Type 'Moment[]' is not assignable to type '[EventValue<Moment>, EventValue<Moment>]'
					value={dateRange}
					disabledDate={disabledDate}
					onChange={(dates) => {
						// We need setDateRange in order to have correct filters 
						// We need setSelectedKeys in order to trigger the filters 
						const keys = dates?.map(range => {
							return range?.utc() ?? moment();
						})
						const filterKeys = dates?.map(range => {
							return range?.unix() ?? 0;
						})
						if (keys?.every(Boolean)) {
							setDateRange(keys)
						} else {
							setDateRange([])
						}
						setSelectedKeys(filterKeys ?? []);
					}}
				/>
				<Space>
					<Button
						onClick={() => clearFilters && handleResetDate(clearFilters)}
						type="link"
						size="small"
						color='grey'
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="primary"
						onClick={() => handleSearch(confirm)}
						size="small"
						style={{ width: 90 }}
					>
						Apply
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value: any, record: DataType,) => {
			if (dateRange && dateRange.length > 1) {
				return ((record.created_at - dateRange[0].unix()) * (record.created_at - dateRange[1].unix()) <= 0);
			} else {
				return true
			}
		},
	});


	const columns: ColumnsType<DataType> = [
		{
			title: 'Patient’s Name',
			dataIndex: 'pet_name',
			key: 'pet_name',
			width: 250,
			...getColumnSearchProps('pet_name'),
			render: (pet_name: string, record: DataType & { pet_deceased_at?: number }) => {
				return record.pet_deceased_at
					? <div>{record.pet_name} <img data-cy={'deceasedIcon'} src={heartbreak} width="20" style={{marginLeft:"5px"}} /></div>
					: record.pet_name;
			},
		},
		{
			title: 'Signalment',
			dataIndex: 'signalment',
			key: 'signalment',
			width: 300,
			...getColumnSearchProps('signalment'),
		},
		{
			title: 'Vet',
			dataIndex: 'vet_name',
			key: 'vet_name',
			width: 280,
			...getColumnSearchProps('vet_name'),
			sorter: (a, b) => {
				if (a.vet_name < b.vet_name) {
					return -1;
				}
				if (a.vet_name > b.vet_name) {
					return 1;
				}
				return 0;
			},
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 170,
			filters: VISIT_STATUS_FILTERS,
			render: (status: string, record: DataType & { hospitalization_level?: string }) => {
				return DASHBOARD_STATUS_MAP[status] === 'Hospitalized'
					? `${DASHBOARD_STATUS_MAP[status]} - ${record.hospitalization_level}`
					: (record.finalized && DASHBOARD_STATUS_MAP[status] !== 'No show')
					? "Closed"
					: DASHBOARD_STATUS_MAP[status];
			},
			onFilter: (value: any, record: DataType) => record.status.startsWith(value),
		},
		{
			title: 'Creation Date',
			dataIndex: 'created_at',
			key: 'created_at',
			width: 230,
			render: (date: number) => moment.unix(date).format('lll'),
			...getColumnSearchRangeProps('created_at'),
			sorter: (a, b) => b.created_at - a.created_at,
			defaultSortOrder: "ascend",
		}
	];

	const cypressTestElement = document.getElementById("cypressTag");
	let cypressTestElementClassName = cypressTestElement?.getAttribute("class");
	
	return (
		<Row>
			<Col className={styles.visitsTitle} span={24}>
				Visits
			</Col>
			<div id="cypressTag" className="normalRun" style={{display:'none'}} />
			<Col span={24}>
				<Select
					mode="multiple"
					placeholder="Filter by..."
					data-cy={'visitsFilter'}
					value={selectedItems}
					onChange={setSelectedItems}
					allowClear={true}
					style={{marginTop: '40px', marginLeft: '24px'}}
					className="ant-col-xs-12 ant-col-md-8 ant-col-lg-4"
					options={[{"label": "Alive", "value": "Alive"}, {"label": "Deceased", "value": "Deceased"}]}
					showArrow={true}
                    suffixIcon={<FilterOutlined />}
				/>
				<Table
					className={styles.visitsTable}
					rowClassName={styles.row}
					dataSource={formattedVisitList}
					columns={columns}
					data-cy={'allVisitsTable'}
					onRow={(record, rowIndex) => {
						return {
							onClick: event => {
								let selection = window.getSelection()?.toString();
								if (!selection) {
									if (cypressTestElementClassName === "cypressRun") {
										window.open(
											`/visit/${record.key}`,
											"_self"
										)
									} else {
										window.open(`/visit/${record.key}`, "_blank");
									}									
								}
							},
						};
					}}
				/>
			</Col>
		</Row>
	);
};

export default AllVisits;
