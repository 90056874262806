import { Col, Form, FormInstance, Input, Radio, Row, Select, Tooltip } from 'antd';
import PackageItemTitleContainer from 'components/forms/PackageFormModal/PackageItemTitleContainer';
import React, { useEffect, useState } from 'react';
import { useGetNonMedicalWhyTypesQuery } from 'services/instructionService';
import { NonMedicalEstimateItemNew } from 'utils/types/estimateTypesNew';
import { validateFieldIsShorterThan } from 'utils/validationFuncs';
import OrderFrequency from 'views/visit/TreatmentSheet/OrderFrequency';
import { PackageFormInstance } from '../forms/PackageFormModal';
import EditLineButton from '../forms/PackageFormModal/EditLineButton';
import { HiddenInput } from '../forms/fields/HiddenInput';
import { EstimateFormInstance } from './index';

interface EstimateNonMedicalsFormProps {
    nonMed: NonMedicalEstimateItemNew;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const EstimateNonMedicalsForm: React.FC<EstimateNonMedicalsFormProps> = ({
    form,
    index,
    nonMed,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [hasError, setHasError] = useState(false);
    const [showWhyOther, setShowWhyOther] = useState(false);
    const lineId = `N-${index}`;
    const isEditing = activeLineItem === lineId || hasError;
    const nonMedFields = [['nonMed', index, 'quantity']];
    const nonMedErrorArray = form.getFieldsError(nonMedFields);
    const handleShouldUpdate = (prev: EstimateFormInstance, curr: EstimateFormInstance) => {
        return prev.nonMed[index].quantity !== curr.nonMed[index].quantity || prev.nonMed[index].action !== curr.nonMed[index].action;
    };
    const { data: nonMedicalWhyTypes } = useGetNonMedicalWhyTypesQuery(null);

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['nonMed', index, 'quantity'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (nonMedErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, nonMedErrorArray]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const initialError = !(getFieldValue(['nonMed', index, 'quantity']) ?? nonMed.quantity);

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${nonMed.has_reason ? 'grid-table__row-non-med' : ''}`}
                        onClick={() => {
                            if (!isEditing && getFieldValue(['nonMed', index, 'action']) === 0) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['nonMed', index, 'id']} initialValue={nonMed.non_medical_id} />
                            <HiddenInput fieldName={['nonMed', index, 'unit_cost_cents']} initialValue={nonMed.unit_cost_cents} />
                            <HiddenInput fieldName={['nonMed', index, 'name']} initialValue={nonMed.name} />
                            <HiddenInput fieldName={['nonMed', index, 'unit']} initialValue={nonMed.unit} />
                            <HiddenInput fieldName={['nonMed', index, 'instruction_id']} initialValue={nonMed.non_medical_order_id} />
                            <HiddenInput fieldName={['nonMed', index, 'type_id']} initialValue={nonMed.type_id} />
                            <HiddenInput fieldName={['nonMed', index, 'is_free']} initialValue={nonMed.is_free} />
                        </div>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer no_charge={nonMed.is_free} checked>
                                <Tooltip title={nonMed.name.length > 45 ? nonMed.name : null}>
                                    <span className='treatment-sheet-side-column__title'>{nonMed.name}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>

                            {
                                <div className='diagnostic-data'>
                                    <OrderFrequency>
                                        {nonMed.unit === 'dollar' ? '$' : ''}
                                        {getFieldValue(['nonMed', index, 'quantity']) ?? nonMed.quantity}{' '}
                                        {nonMed.unit === 'dollar' ? '' : nonMed.unit}
                                    </OrderFrequency>
                                </div>
                            }
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Transfer Options
                        </div>

                        <div className='package-medication-dose__inputs'>
                            <Form.Item name={['nonMed', index, 'action']} initialValue={0}>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Transfer</Radio.Button>
                                    <Radio.Button value={1}>Exclude</Radio.Button>
                                    <Radio.Button value={2}>Save for Later</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            {nonMed.unit === 'dollar' ? 'Amount' : 'Quantity'}
                        </div>
                        <div className='non-medical-quantity'>
                            <Form.Item
                                name={['nonMed', index, 'quantity']}
                                initialValue={nonMed.quantity}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (isNaN(value)) {
                                                return Promise.reject('Required');
                                            }

                                            if (value <= 0) {
                                                return Promise.reject('Cannot be smaller or equal to 0');
                                            }

                                            if (value % 1 !== 0) {
                                                return Promise.reject(
                                                    nonMed.unit === 'dollar' ? 'Amount cannot be decimal' : 'Unit cannot be decimal',
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                {isEditing ? (
                                    <Input
                                        prefix={nonMed.unit === 'dollar' ? '$' : undefined}
                                        suffix={nonMed.unit === 'dollar' ? undefined : nonMed.unit}
                                        min={1}
                                        disabled={getFieldValue(['nonMed', index, 'action']) !== 0}
                                    />
                                ) : (
                                    <span>
                                        {nonMed.unit === 'dollar' ? '$' : ''}
                                        {form.getFieldValue(['nonMed', index, 'quantity']) ?? nonMed.quantity}{' '}
                                        {nonMed.unit === 'dollar' ? '' : 'unit'}
                                    </span>
                                )}
                            </Form.Item>
                        </div>

                        {nonMed.has_reason && (
                            <div>
                                <>
                                    <Form.Item
                                        label='Why'
                                        name={['nonMed', index, 'why']}
                                        labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 20 }}
                                        rules={[{ required: true, message: 'Why is required' }]}
                                    >
                                        <Select<string>
                                            allowClear
                                            showSearch
                                            options={nonMedicalWhyTypes?.map((item: string) => {
                                                return { label: item, value: item };
                                            })}
                                            filterOption={(inputValue, option) =>
                                                (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(value) => {
                                                setShowWhyOther(value === 'Other');
                                            }}
                                        />
                                    </Form.Item>
                                    {showWhyOther && (
                                        <Form.Item
                                            preserve={false}
                                            name={['nonMed', index, 'why_other']}
                                            label='Other'
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 20 }}
                                            rules={[{ required: true, message: 'Other is required' }]}
                                        >
                                            <Input placeholder='Please provide short answer' />
                                        </Form.Item>
                                    )}
                                    <Row className='non-medical-reason-info'>
                                        <Col span={20} offset={4}>
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>For internal use only.</span>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        preserve={false}
                                        name={['nonMed', index, 'reason']}
                                        label='Reason'
                                        labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 20 }}
                                        rules={[
                                            { required: true, message: 'Reason is required' },
                                            { validator: validateFieldIsShorterThan('reason', 151) },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Row className='non-medical-reason-info'>
                                        <Col span={20} offset={4}>
                                            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                                                Provide an explanation of cost for customer awareness and insurance purposes.
                                            </span>
                                            <strong>This will appear on the invoice.</strong>
                                        </Col>
                                    </Row>
                                </>
                            </div>
                        )}
                        {!nonMed.has_reason && <div className='empty-element' />}

                        <div className='empty-element' />

                        <div className='empty-element' />
                        <div className='empty-element' />
                        <div className='empty-element' />

                        <EditLineButton
                            disabled={isEditing}
                            hasError={hasError || initialError}
                            onClick={() => {
                                if (!hasError) setActiveLineItem(lineId);
                            }}
                        />
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default EstimateNonMedicalsForm;
