import { Button } from 'antd';
import heartbreak from 'assets/img/heartbreak_icon.svg';

  export const DeceasedTag = () => {

  	return (
      <Button
        icon={<img src={heartbreak} width="20" style={{marginRight:"5px"}} />}
        size='middle'
        danger
        style={{
          backgroundColor:"#D32027",
          color:"white",
          fontWeight: "bold",
          marginTop:"8px",
          marginBottom:"8px",
          cursor: "default",
          border: "none"
        }}
      >
        Deceased
      </Button>
  	);
};
