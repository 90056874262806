import { Skeleton, SkeletonProps, Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import { TableLayout } from 'rc-table/lib/interface';

export type SkeletonTableColumnsType = {
    key: string;
};

type SkeletonTableProps = SkeletonProps & {
    tableLayout?: TableLayout;
    rowCount?: number;
    size?: SizeType;
    columns: ColumnsType<SkeletonTableColumnsType>;
};

export default function SkeletonTable({
    tableLayout = 'auto',
    loading = false,
    active = false,
    rowCount = 5,
    size,
    columns,
    children,
    className,
}: SkeletonTableProps): JSX.Element {
    return loading ? (
        <Table
            tableLayout={tableLayout}
            size={size}
            rowKey='key'
            pagination={false}
            dataSource={[...Array(rowCount)].map((_, index) => ({
                key: `key${index}`,
            }))}
            columns={columns.map((column) => {
                return {
                    ...column,
                    render: function renderPlaceholder() {
                        return <Skeleton key={column.key} title active={active} paragraph={false} className={className} />;
                    },
                };
            })}
        />
    ) : (
        <>{children}</>
    );
}
