import { EstimateItem, isInstanceOfNonMedicalEstimateItem } from 'utils/types/billingTypes';

export const updateExistingEstimate =
    (estimateList: EstimateItem[], setEstimateList: React.Dispatch<React.SetStateAction<any[]>>) =>
    (instructionId: number, updatedEstimate: EstimateItem) => {
        const estimateListCopy = [...estimateList];
        const updateIndex = estimateList.findIndex((est) => {
            if (isInstanceOfNonMedicalEstimateItem(est)) {
                return est.non_medical_order_id === instructionId;
            } else {
                return est.instruction_id === instructionId;
            }
        });
        if (updateIndex !== -1) {
            estimateListCopy[updateIndex] = updatedEstimate;
            setEstimateList(estimateListCopy);
        }
    };
