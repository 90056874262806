import { Col, Form, InputNumber, Row } from 'antd';
import React, { useEffect } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { ExistingOxygenTherapyInstruction } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import { getDefaultStartTime } from 'utils/miscFuncs';
import { FluidSearchOption } from 'utils/types/InstructionOrderTypes';
import { endTimeGreaterThanStartTime } from 'utils/validationFuncs';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';
import { MedicationEnd, MedicationNote, MedicationStart } from './MedicineOrder';
import PackageItemTitleContainer from './PackageFormModal/PackageItemTitleContainer';
import { HiddenInput } from './fields/HiddenInput';

interface OxygenTherapyOrderProps {
    options: FluidSearchOption;
    existingInstruction?: Partial<ExistingOxygenTherapyInstruction>;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    fromEstimate?: boolean;
}

const OxygenTherapyOrder: React.FC<OxygenTherapyOrderProps> = ({ options, existingInstruction, fromEstimate, onFormChange }) => {
    const labelCol = { span: 8 };
    const wrapperCol = { span: 16 };

    const { data: loggedInUserData } = useGetUserDataQuery(null);

    const formattedOnFormChange = onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

    const getFluidRateLabel = () => {
        if (options.name.includes('Oxygen Cage')) {
            return 'Oxygen amount (%)';
        }

        return 'Oxygen rate (L/min)';
    };

    useEffect(() => {
        if (!!loggedInUserData) {
            onFormChange?.({
                ordered_by: loggedInUserData.user_id,
            });
        }
    }, [loggedInUserData]);

    useEffect(() => {
        onFormChange?.({
            start_time: getDefaultStartTime(),
        });
    }, []);

    return (
        <>
            <HiddenInput fieldName='is_prn' initialValue={existingInstruction?.is_prn ?? false} />
            <HiddenInput fieldName='prn_condition' initialValue={existingInstruction?.prn_condition ?? null} />
            <HiddenInput fieldName='ordered_by' initialValue={existingInstruction?.ordered_by ?? loggedInUserData?.user_id} />
            <HiddenInput fieldName='fluids_id' initialValue={options.id} />
            <HiddenInput fieldName='priority' initialValue={existingInstruction?.priority ?? false} />
            <HiddenInput fieldName='is_free' initialValue={existingInstruction?.is_free} />
            {existingInstruction?.id && <HiddenInput fieldName='instruction_id' initialValue={existingInstruction?.id} />}

            {fromEstimate ? (
                <>
                    <HiddenInput fieldName='name' initialValue={options.name} />
                    <HiddenInput fieldName='type_id' initialValue='OT' />
                    <HiddenInput fieldName='unit_cost_cents' initialValue={options.cents} />
                    <HiddenInput fieldName='supplemental_cost_cents' initialValue={options.supplemental_cents} />
                    <HiddenInput fieldName='is_free' initialValue={false} />
                    <HiddenInput fieldName='unit' initialValue='minutes' />
                </>
            ) : (
                <HiddenInput fieldName='unit' initialValue={getOxygenTherapyUnit(options.name)} />
            )}

            <Row align='middle' style={{ width: '100%', marginTop: 'var(--spacing-md)' }}>
                <Col offset={8}>
                    {existingInstruction?.is_free && <PackageItemTitleContainer no_charge={existingInstruction?.is_free} checked />}
                </Col>
            </Row>

            <Form.Item
                rules={[
                    {
                        required: true,
                    },
                    {
                        validator: (_, value) => {
                            if (value <= 0) {
                                return Promise.reject(new Error('The value must be greater than 0'));
                            }

                            if (value < 0.01) {
                                return Promise.reject('The value cannot be smaller than 0.01');
                            }

                            return Promise.resolve();
                        },
                    },
                ]}
                label={getFluidRateLabel()}
                name={fromEstimate ? 'oxygen_quantity' : 'quantity'}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                style={{ width: '100%' }}
                initialValue={existingInstruction?.oxygen_quantity ? roundTo(existingInstruction?.oxygen_quantity / 100, 2) : null}
            >
                <InputNumber disabled={false} type='number' min={0} style={{ width: '100%' }} />
            </Form.Item>

            {!fromEstimate && (
                <>
                    <MedicationStart
                        rules={[{ required: true, type: 'date' }]}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        onFormChange={formattedOnFormChange}
                    />
                    <MedicationEnd
                        rules={[{ type: 'date' }, endTimeGreaterThanStartTime]}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        onFormChange={formattedOnFormChange}
                    />
                    <MedicationNote labelCol={labelCol} wrapperCol={wrapperCol} />
                </>
            )}
        </>
    );
};

export default OxygenTherapyOrder;
