import { Col, Divider, Form, Input, Row, Select, Spin, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetBurialFormQuery } from 'services/consentService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { Header } from '../Header';
import { CheckCircleOutlined } from '@ant-design/icons';

const ApprovedBurialForm = () => {
	const { urlFormId } = useParams<any>();
	const { data: burialData } = useGetBurialFormQuery(
		urlFormId,
		BASE_QUERY_OPTIONS,
	);
	const showUrnSelection = burialData?.type === 'private cremation';
	
	interface IOptions {
		[x: string]: string;
	}
	const burialOptions: IOptions = {
		
			'general cremation': 'General Cremation',
			'private cremation': 'Private Cremation',
			'release remains': 'Release Remains',
			'private burial': 'Private Burial',
		
};
	const urnOptions: IOptions = {

	}
	burialData?.urns.map((urn) => (urnOptions[urn.id] = urn.name));

	if (!burialData) {
        return <Spin size='large' />;
    }
	return (
			<div className='single-page-element document-text approved-consent-form'>
				<div className='approved-consent-form__print-button'>
					<Button
						onClick={() => {
							window.print();
						}}
						type='primary'
					>
						Print
					</Button>
				</div>
				<div className='document' id='printableArea'>
					<Header
						title='Burial and Cremation Consent Form'
						headerData={{ ...burialData, pet_name: capitalizeFirstLetter(burialData.pet_name) }}
					/>
					<Form.Item label='Burial Service' name='type'>
						<Col span={10}>
							{burialOptions[burialData.type]}
						</Col>
					</Form.Item>
					{showUrnSelection && (
						<Row gutter={10}>
							{burialData?.urn_id ? (
							<Col span={12}>
								<Form.Item label='Urn' name='urn_id'>
									{burialData?.urn_id ? urnOptions[burialData.urn_id] : ""}
								</Form.Item>
							</Col>		
							):(					
							<Col span={10}>
								<Form.Item label='Urn ID' name='custom_urn_id'>
									{burialData?.custom_urn_id}
								</Form.Item>
							</Col>)}
						</Row>
					)}
					<Divider />
					<p>
						I, the undersigned, do hereby certify that I am the owner or
						the authorized agent for the owner of the pet described
						above, that I am 18 years of age or older, and that I have
						the authority to execute this consent. I do hereby give
						complete authority to humanely cremate {capitalizeFirstLetter(burialData.pet_name)}{' '}
						and release the veterinary facility and its owners,
						employees and staff from any and all claims and liability
						(including legal fees and other costs and expenses) for so
						cremating said pet.
					</p>
					<p>
						The nature of the cremation process has been explained to
						me, and I understand what will be done and that it is an
						irreversible and final process. I further acknowledge that I
						have received, read, understand and accept the price
						schedule for each aforesaid disposition alternatives, and
						where each alternative method of disposition would take
						place. If this form is not completed by the pet owner, the
						undersigned acknowledges that the same was orally authorized
						by the pet owner and witnessed by the party signing below as
						such witness.
					</p>
					<p>
						I do also certify that to the best of my knowledge said pet
						has not bitten any person or animal during the last fifteen
						(15) days and has not been exposed to rabies.
					</p>
					<div className='approved-consent-form__approved-at'>
                    	<CheckCircleOutlined style={{ color: 'var(--green-alert-success)', marginRight: 'var(--spacing-sm)' }} />
                    	Approved: {burialData.approved_date_hospital_timezone}
                	</div>
				</div>
			</div>
	);
};

export default ApprovedBurialForm;
