import { Alert, Button, FormInstance, Tooltip } from 'antd';
import useCloseVisit from 'hooks/useCloseVisit';
import usePatientChecklist from 'hooks/usePatientChecklist';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useLazyGetBillingDataQuery } from 'services/billingService';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';

interface IVisitDischargeAlertProps {
    visitId: number;
    modalForm: FormInstance<any>;
    setModalState: Function;
}

const VisitDischargeAlert = ({
    visitId,
    modalForm,
    setModalState,
}: IVisitDischargeAlertProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const visitMissingItems = usePatientChecklist({ visit_id: visitId });
    const [confirmCloseVisit] = useCloseVisit({
        visitId,
        modalForm,
        setModalState,
    });
    const [getBillingData] = useLazyGetBillingDataQuery();

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    
    const userHasClosePermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.visit_close,
    );

    const getAlertMessage = () => {
        const baseMessage = 'Patient has been checked out. ';

        if (visitMissingItems.some((missItem) => missItem.isMissing)) {
            return baseMessage.concat(
                'There may be missing or incomplete information from this visit. ',
            );
        }

        return baseMessage.concat('Visit is ready to close. ');
    };

    return (
        <Alert
            className='visit-discharge-alert'
            message={
                <>
                    <span>{getAlertMessage()}</span>
                    <Link
                        to={`/visit/${visitId}?section=mission_control`}
                        style={{ textDecoration: 'underline' }}
                    >
                        Review Checklist
                    </Link>
                </>
            }
            banner
            action={
                <Tooltip title={!userHasClosePermission ? "You do not have permission to close." : ""} placement='bottomRight'>
                    <Button
                        type='default'
                        data-cy={'signAndCloseButton'}
                        style={userHasClosePermission ? {
                            backgroundColor: '#FF4D4F',
                            color: '#fff',
                        } : {}}
                        loading={isLoading}
                        disabled={!userHasClosePermission}
                        onClick={() => {
                            setIsLoading(true);
                            getBillingData({ visitId })
                                .then(() => {
                                    setIsLoading(false);
                                    confirmCloseVisit();
                                })
                                .catch((e) => {
                                    setIsLoading(false);
                                    console.error(e);
                                });
                        }}
                    >
                        Sign and Close
                    </Button>
                </Tooltip>
            }
        />
    );
};

export default VisitDischargeAlert;
