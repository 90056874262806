import { useForm } from "antd/lib/form/Form";
import { useGetInstructionOptionsQuery } from "services/instructionService";
import { BASE_QUERY_OPTIONS, FormName } from "utils/constants";
import { VisitVital } from "utils/dataTypes";
import { BaseSearchOption } from "utils/types/InstructionOrderTypes";
import { OrderSearch } from "../../OrderSearch";

interface EstimateOrderSearchProps {
    handleSelect: (searchOption: BaseSearchOption) => void;
    vitalInstructions: VisitVital[];
}

export const EstimateOrderSearch = ({
    handleSelect,
    vitalInstructions,
}: EstimateOrderSearchProps) => {
    const [form] = useForm();
    const { data: instructionsOptions } = useGetInstructionOptionsQuery(
        null,
        BASE_QUERY_OPTIONS,
    );

    return (
        <OrderSearch
            handleSelect={handleSelect}
            searchOptions={instructionsOptions ?? []}
            setModalstate={() => {}}
            modalForm={form}
            vitals={vitalInstructions}
            placeholder='Add an order or to go item'
            style={{width: '400px'}}
        />
    );
};
