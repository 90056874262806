import { Col, DatePicker, Form, FormInstance, Modal, message, Alert, Skeleton } from 'antd';
import { FormWrapper } from 'components/forms/FormWrapper';
import moment from 'moment';
import { CSSProperties } from 'react';
import { DECEASED_DATE_FORMAT } from 'utils/constants';
import {
    BaseExistingInstruction,
    DischargeNewInstruction,
    ExistingMedInstruction,
    isInstanceOfMedicineInstruction,
} from 'utils/dataTypes';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import { validateDeceasedDate } from 'utils/validationFuncs';
import dont_get_mad_at_donkey_kong from 'assets/img/dont_get_mad_at_donkey_kong.png';
import { filterFluidsCri } from 'utils/filterFuncs';
import { getLedgerValues } from '../../../utils/miscFuncs';
import { formatCurrency } from '../../../utils/formatFuncs';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const transformTogoMed = (
    fullSelectedItem: MedicineSearchOption | ExistingMedInstruction,
): { dispense_unit: string; ratio: number } => {
    if (isInstanceOfMedicineInstruction(fullSelectedItem)) {
        return {
            dispense_unit: fullSelectedItem.dose_unit,
            ratio: 1,
        };
    } else {
        const {
            dose_unit,
            denominator_unit,
            denominator_value,
            numerator_unit,
            numerator_value,
        } = fullSelectedItem;
        if (dose_unit === denominator_unit) {
            return {
                dispense_unit: denominator_unit,
                ratio: 1,
            };
        } else {
            let ratio;
            if (numerator_value) {
                ratio = numerator_value / denominator_value;
            } else {
                ratio = 1;
            }
            return {
                dispense_unit: denominator_unit,
                ratio: ratio,
            };
        }
    }
};

interface NewToGoMedValues {
    dispense_value: number;
    next_dose: string;
    morning_doses: number;
    afternoon_doses: number;
    night_doses: number;
    instructions: string;
}

export const generateNewTogoMed = (
    fullSelectedItem: MedicineSearchOption | ExistingMedInstruction,
    visit_id: number,
    values: NewToGoMedValues,
): DischargeNewInstruction => {
    const { dispense_unit, ratio } = transformTogoMed(fullSelectedItem);
    return {
        visit_id,
        togo: true,
        medication_id: isInstanceOfMedicineInstruction(fullSelectedItem)
            ? fullSelectedItem.medication_id
            : fullSelectedItem.id,
        name: fullSelectedItem.name,
        dispense_unit: dispense_unit, // togomeds prescribed by denominator per Vijay
        dose_unit: fullSelectedItem.dose_unit,
        ratio: ratio,
        instructions: values.instructions,
        refills: 0,
        fill_at_pharmacy: false,
        dispense_value: values.dispense_value,
        next_dose: values.next_dose,
        morning_doses: values.morning_doses,
        afternoon_doses: values.afternoon_doses,
        night_doses: values.night_doses,
        related_instruction_id: isInstanceOfMedicineInstruction(
            fullSelectedItem,
        )
            ? fullSelectedItem.id
            : null,
    };
};

const ConfirmPatientIsNotDeceased = (form: FormInstance, pet_id: string, patient_name: string, markPatientDeceased: Function, birthDate?: moment.Moment) => {
    const modalConfig = () => {
        return {
            title: '',
            okButtonProps: {danger: true},
            icon: null,
            width: 525,
            okText: 'Not Deceased',
            cancelText: 'Go back',
            maskClosable: false,
            onOk: () => {},
            onCancel: () => {
                PromptPatientDeceasedDateModal(form, pet_id, patient_name, markPatientDeceased, birthDate);
            },
            content: content,
        }
    }

    const content = (
        <>
            <Col span={24}>
                <img
                    alt={'Dont get mad at donkey kong'}
                    style={{margin: 'auto', width: '80%', display: 'block', marginBottom: '18px'}}
                    src={dont_get_mad_at_donkey_kong}
                />
                <h2>Just double checking...</h2>
                <p>
                    Normally a patient with a burial consent or completed euthanasia drug are marked deceased.
                </p>
                <p>Are you sure <strong style={{fontSize: '16px'}}>{patient_name}</strong> is NOT deceased?</p>
            </Col>
        </>
    )

    Modal.confirm(modalConfig());
}

export const PromptPatientDeceasedDateModal = (form: FormInstance, pet_id: string, patient_name: string, markPatientDeceased: Function, birthDate?: moment.Moment) => {
    const formItemStyle: CSSProperties = {
        width: '100%',
    };

    const onOk = () => {
        form.validateFields().then(() => {
            form.submit();
        })
        .catch(() => {
            // TODO: avoid destroying and creating the modal again
            modal.destroy();
            PromptPatientDeceasedDateModal(form, pet_id, patient_name, markPatientDeceased, birthDate);
            form.resetFields();
        });

        return true;
    }

    const modalConfig = () => {
        return {
            title: 'Mark Patient As Deceased',
            okButtonProps: {danger: true},
            width: 525,
            okText: 'Deceased',
            cancelText: 'Skip this Step',
            maskClosable: false,
            onOk: onOk,
            onCancel: () => {
                ConfirmPatientIsNotDeceased(form, pet_id, patient_name, markPatientDeceased, birthDate);
                modal.destroy();
                return true;
            },
            content: content,
        }
    }

    const content = (
        <>
            <Col span={24} style={{marginBottom: '24px'}}>
                There is a Burial Consent Form and/or euthanasia drug order completed for this visit which usually indicates a patient is deceased.
                <br/><br/>
                Marking <strong style={{fontSize: '16px'}}>{patient_name}</strong> deceased will...
                <br/><br/>
                1. Remove checkout requirements like <strong>referral source</strong>.
                <br/>
                2. Stop the <strong>survey</strong> and <strong>follow-up text</strong> from sending.
            </Col>
            <Col span={24}>
                <FormWrapper
                    form={form}
                    gutter={0}
                    onFormChange={form.setFieldsValue}
                    onFinishFailed={() => {}}
                    onFinish={(values: any) => {
                        markPatientDeceased({
                            deceased_at: values.deceased_at.startOf('day').unix(),
                            pet_id: pet_id,
                        }).unwrap().then(() => {
                            message.success(`${patient_name} has been successfully marked deceased`);
                            modal.destroy();
                        }).catch(() => {
                            message.error('Something went wrong!');
                            // TODO: avoid destroying and creating the modal again
                            modal.destroy();
                            PromptPatientDeceasedDateModal(form, pet_id, patient_name, markPatientDeceased, birthDate);
                        });
                    }}
                    bottomBar={<></>}
                >
                    <Form.Item
                        preserve={false}
                        name='deceased_at'
                        label='Deceased Date'
                        style={formItemStyle}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        initialValue={moment()}
                        rules={[{
                            validator: validateDeceasedDate('deceased date', moment(birthDate, 'YYYY-MM-DD')),
                        }]}
                    >
                        <DatePicker
                            style={formItemStyle}
                            showTime={false}
                            format={DECEASED_DATE_FORMAT}
                            defaultValue={moment()}
                        />
                    </Form.Item>
                </FormWrapper>
            </Col>
        </>
    )

    const modal = Modal.confirm(modalConfig());
}

const relockModal = (
    instructions: BaseExistingInstruction[] | undefined,
    onFinishRelock: (ids: number[]) => Promise<any>,
    getBillingDataLazy: Function,
    getVisitDiscountLazy: Function,
    visit_id: number,
    setIsRelocking: Function,
) => {
    setIsRelocking(true);

    const ids = instructions?.map( item => item.id ) ?? [];

    const content = (total: number | null) => {
        const totalElement = total === null ? <Skeleton.Button active size={'small'} /> : formatCurrency(total);
        const alertDescription = ids.length > 0 ? "Review invoice prior to marking ready." : "Review invoice prior to relocking.";
        const discontinuingBulletPoint = ids.length > 0 ? (
            <li>
                <b>Discontinue {ids.length} orders</b> (except TGH Meds)
            </li>
        ) : (
            <></>
        )

        return (
            <>
                <Col style={{ marginBottom: 'var(--spacing-md)' }}>
                    <Alert message={<b>Invoice Total: {totalElement}</b>} description={alertDescription} type="info"
                           showIcon />
                </Col>
                <Col>
                    <ul>
                        {discontinuingBulletPoint}
                        <li>
                            <b>Relock invoice</b>
                            <ul>
                                <li>No additional charges to invoice</li>
                                <li>No removal of line items</li>
                                <li>Refunds available only when a credit is due</li>
                                <li>Discharge notes remain available for editing</li>
                            </ul>
                        </li>
                    </ul>
                </Col>
            </>
        )
    }

    const okButtonText = ids.length > 0 ? "Stop Orders & Relock Invoice" : "Relock Invoice";

    const modal = Modal.confirm({
        title: "Relock Invoice",
        centered: true,
        maskClosable: true,
        width: 525,
        okText: okButtonText,
        onOk: () => {
            return Promise.resolve(onFinishRelock(ids)).then(() => {
                setIsRelocking(false);
                message.success("Invoice has been successfully relocked.");
            });
        },
        onCancel: () => {
            setIsRelocking(false);
            modal.destroy();
        },
        content: content(null),
        okButtonProps: { disabled: true }
    })

    Promise.resolve(invoiceTotalPromise(getBillingDataLazy, getVisitDiscountLazy, visit_id, null))
        .then( total => {
            modal.update({content: content(total), okButtonProps: { disabled: false }})
        })
}

export const reviewAndFinalizeFlowRelock = (
    ongoingInstructions: BaseExistingInstruction[] | undefined,
    discontinueCriAndFluidInstructions: () => void | Promise<void>,
    onFinishRelock: (ids: number[]) => Promise<any>,
    getBillingDataLazy: Function,
    getVisitDiscountLazy: Function,
    visit_id: number,
    setIsRelocking: Function,
    patientName: string,
) => {
    const fluidCriOxygenInstructions = ongoingInstructions?.filter(
        filterFluidsCri
    ).filter(
        (item) => item.discontinued_at == null
    )

    const otherInstructions = ongoingInstructions?.filter(
        (instruction) => !filterFluidsCri(instruction)
    ).filter(
        (item) => item.discontinued_at == null
    )

    reviewAndFinalizeFlowDiscontinueFluids(
        fluidCriOxygenInstructions ?? [],
        () => {
            return Promise.resolve(discontinueCriAndFluidInstructions())
                .then(() => {
                    relockModal(
                        otherInstructions,
                        onFinishRelock,
                        getBillingDataLazy,
                        getVisitDiscountLazy,
                        visit_id,
                        setIsRelocking,
                    )
                })
        },
        patientName,
    )
}

const reviewAndFinalizeFlowReviewAndFinalize = (
    instructions: BaseExistingInstruction[] | undefined,
    onFinishReviewAndFinalize: (ids: number[]) => Promise<any>,
    total: number | Promise<number>,
    patientName: string,
) => {
    const ids = instructions?.map( item => item.id ) ?? [];

    const content = (total: number | null) => {
        const totalElement = total === null ? <Skeleton.Button active size={'small'} /> : formatCurrency(total);
        return (
            <>
                <Col style={{marginBottom: "var(--spacing-md)"}}>
                    <Alert message={<b>Invoice total: {totalElement}</b>} description="Review invoice prior to marking ready." type="info" showIcon />
                </Col>
                <Col>
                    <ul>
                        <li><b>Discontinue any open orders</b> (except TGH Meds)</li>
                    </ul>
                    <ul>
                        <li>
                            <b>Finalize invoice</b>
                            <ul>
                                <li>No additional charges to invoice</li>
                                <li>No removal of line items</li>
                                <li>Refunds available only when a credit is due</li>
                                <li>Up to 12 hrs to unlock invoice for adjustments</li>
                                <li>Discharge notes remain available for editing</li>
                            </ul>
                        </li>
                    </ul>
                    <p>Are you sure you want to mark {patientName} ready for checkout?</p>
                </Col>
            </>
        )
    }

    const modal = Modal.confirm({
        title: "Review and Finalize Invoice",
        centered: true,
        maskClosable: true,
        width: 525,
        okText: 'Stop Orders & Finalize Invoice',
        onOk: () => {
            return Promise.resolve(onFinishReviewAndFinalize(ids)).then(() => {
                message.success("Invoice has been successfully finalized.")
            });
        },
        onCancel: () => {
            modal.destroy();
        },
        content: content(null),
        okButtonProps: { disabled: true }
    })

    Promise.resolve(total)
        .then( total => {
            modal.update({content: content(total), okButtonProps: { disabled: false }})
        })
}

const reviewAndFinalizeFlowDiscontinueFluids = (
    fluidCriOxygenInstructions: BaseExistingInstruction[],
    onOk: () => void,
    patientName: string,
) => {
    if (fluidCriOxygenInstructions.length) {
        const modal = Modal.confirm({
            title: "Discontinue Fluids",
            centered: true,
            maskClosable: true,
            okButtonProps: {
                danger: true,
            },
            icon: <ExclamationCircleOutlined style={{color: 'var(--veg-red)'}} />,
            width: 525,
            okText: <>Stop Fluid/CRI/O<sub>2</sub> Orders</>,
            onOk: onOk,
            onCancel: () => {
                modal.destroy();
            },
            content: (
                <>
                    <Col span={24}>
                        <p>Stop all fluid/CRI/Oxygen orders for a more accurate invoice balance.</p>
                    </Col>

                    <Col>
                        <h4>Discontinue the following orders:</h4>
                        <ul>
                            {fluidCriOxygenInstructions?.map((inst, index) => (
                                <li key={`ongoing-instr-${index}`}>{inst.name}</li>
                            ))}
                        </ul>
                    </Col>
                    <Col span={24}>
                        <p>Are you sure you want to stop these orders for {patientName}?</p>
                    </Col>
                </>
            ),
        })
    } else {
        onOk();
    }

}

const invoiceTotalPromise = (
    getBillingDataLazy: Function,
    getVisitDiscountLazy: Function,
    visit_id: number,
    total: number | null
) => {
    if (total !== null) {
        return Promise.resolve(total);
    } else {
        return Promise.all(
            [getBillingDataLazy({ visitId: visit_id }), getVisitDiscountLazy(visit_id)],
        ).then(values => {
            const [billingData, visitDiscount] = values;
            const { total } = getLedgerValues(
                billingData.data?.subtotal,
                billingData.data?.amount_paid,
                billingData.data?.tax,
                visitDiscount.data?.basis,
                visitDiscount.data?.cap_amount_cents,
            );

            return Promise.resolve(total);
        });
    }
}

export const reviewAndFinalizeFlow = (
    ongoingInstructions: BaseExistingInstruction[] | undefined,
    discontinueCriAndFluidInstructions: () => void | Promise<void>,
    patientName: string,
    onFinishReviewAndFinalize: (ids: number[]) => Promise<any>,
    getBillingDataLazy: Function,
    getVisitDiscountLazy: Function,
    visit_id: number,
    ledgerTotal: number | null,
) => {
    const fluidCriOxygenInstructions = ongoingInstructions?.filter(
        filterFluidsCri
    ).filter(
        (item) => item.discontinued_at == null
    )

    const otherInstructions = ongoingInstructions?.filter(
        (instruction) => !filterFluidsCri(instruction)
    ).filter(
        (item) => item.discontinued_at == null
    )

    reviewAndFinalizeFlowDiscontinueFluids(
        fluidCriOxygenInstructions ?? [],
        () => {
            return Promise.resolve(discontinueCriAndFluidInstructions())
                .then(() => {
                    reviewAndFinalizeFlowReviewAndFinalize(
                        otherInstructions,
                        onFinishReviewAndFinalize,
                        invoiceTotalPromise(getBillingDataLazy, getVisitDiscountLazy, visit_id, null),
                        patientName,
                    );
                })
        },
        patientName,
    )
}

export const showNoChargeModal = (onOk: ((...args: any[]) => any) | undefined) => {
    Modal.confirm({
        title: 'Invoice is locked. No charges will apply.',
        content: (
            <>
                <p>Completing this order will result in a <strong>No Charge</strong> item on the bill.</p>
            </>
        ),
        okText: 'Continue at No Charge',
        icon: <ExclamationCircleOutlined style={{color: 'var(--veg-red)'}} />,
        okButtonProps: {
            danger: true,
        },
        centered: true,
        maskClosable: true,
        onOk: onOk,
    });
};

export const showDischargeModal = (
    ongoingInstructions: BaseExistingInstruction[] | undefined,
    onFinish: (ids: number[]) => void,
) => {
    const modal = Modal.confirm({
        title: `Confirm patient discharge`,
        centered: true,
        maskClosable: true,
        autoFocusButton: 'ok',
        okButtonProps: {
            danger: true,
        },
        width: 525,
        okText: 'Discharge',
        onOk: () => {
            modal.destroy();
            onFinish(
                ongoingInstructions?.map((instruction) => instruction.id) ?? []
            );
        },
        onCancel: () => {
            modal.destroy();
        },
        content: (
            <>
                <Col span={24}>
                    <h4>Discharging this patient will discontinue all ongoing instructions and outstanding actions.</h4>
                </Col>
                <Col>
                    {ongoingInstructions && ongoingInstructions.length > 0 &&
                        <>
                            <h4>Instructions that will be discontinued:</h4>
                            <ul>
                                {ongoingInstructions.map((inst, index) => (
                                    <li key={`ongoing-instr-${index}`}>{inst.name}</li>
                                ))}
                            </ul>
                        </>
                    }
                </Col>
                <Col span={24}>
                    <h3>
                        <b>Are you sure you want to discharge this patient?</b>
                    </h3>
                </Col>
            </>
        ),
    });
};
