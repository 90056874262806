import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import { WhiteboardItem } from 'services/whiteboardService';
import StatusItem from '../StatusItem';
import './StatusSection.css';

interface StatusSectionProps {
    isLoading: boolean;
    title: string;
    titleCounter: number;
    titleClassName?: string;
    whiteboardItems?: WhiteboardItem[];
    tooltipTitle?: string;
}

const StatusSection: React.FC<StatusSectionProps> = ({ isLoading, titleClassName, titleCounter, title, whiteboardItems, tooltipTitle }) => {
    return (
        <section className={`whiteboard__status-section ${titleClassName}`}>
            <Tooltip title={tooltipTitle}>
                <h2 className='whiteboard__status-section-title'>
                    <span>{title}</span>
                    <span style={{ fontWeight: 'normal', fontSize: '14px' }}> ({titleCounter})</span>
                </h2>
            </Tooltip>
            {isLoading ? <Skeleton active /> : whiteboardItems?.map((item) => <StatusItem item={item} />)}
        </section>
    );
};

export default StatusSection;
