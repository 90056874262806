import Modal from 'antd/lib/modal/Modal';
import { Alert, Form, Input, message } from 'antd';
import { setInstructionToReschedule } from 'store/slices/treatmentSheetSlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useModifyFrequencyMutation } from 'services/instructionService';
import moment from 'moment';
import { findPendingAction } from 'views/visit/OverviewTab';
import { DatePickerWithHours } from 'components/forms/fields/DatePickerWithHours';
import { useEffect, useState } from 'react';
import { MedicationFrequency } from 'components/forms/MedicineOrder';
import { validateFieldIsAfterDate } from 'utils/validationFuncs';
import { RuleObject } from 'antd/lib/form';
import './RescheduleFrequency.css';
import { next30Min } from 'utils/formFuncs';

interface RescheduleOrderProps {}
interface UserFormProps {
    new_time: number;
    frequency: string;
}
const RescheduleOrder: React.FC<RescheduleOrderProps> = (props) => {
    const { instructionToReschedule } = useAppSelector((state) => state.treatmentSheetSlice);
    const dispatch = useAppDispatch();

    const [form] = Form.useForm<UserFormProps>();
    const [modifyFrequency] = useModifyFrequencyMutation();
    const [newTime, setNewTime] = useState<moment.Moment | null>(next30Min(moment()));

    const actions = instructionToReschedule?.actions ? [...instructionToReschedule?.actions] : [];
    const nextAction = findPendingAction(actions);

    useEffect(() => {
        if (instructionToReschedule) {
            setNewTime(next30Min(moment()));
            form.setFieldsValue({
                frequency: instructionToReschedule.frequency,
            });
        }
    }, [instructionToReschedule]);

    const isIdexx = instructionToReschedule?.type_id === 'D' && instructionToReschedule.category === 'IDEXX_REF_LAB';

    return (
        <Modal
            width={520}
            open={!!instructionToReschedule}
            maskClosable={true}
            title='Reschedule Order'
            onOk={() => {
                form.submit();
            }}
            okText='Update Schedule Time'
            onCancel={() => {
                dispatch(setInstructionToReschedule(null));
                setNewTime(null);
            }}
            destroyOnClose={true}
        >
            <p className='reschedule-frequency-form__title'>{instructionToReschedule?.name ?? ''}</p>
            <Form
                name='reschedule_instruction'
                form={form}
                onFinish={(values) => {
                    if (newTime && instructionToReschedule) {
                        modifyFrequency({
                            visitId: instructionToReschedule.visit_id,
                            instructionId: instructionToReschedule.id,
                            instructionType: instructionToReschedule.type_id,
                            body: {
                                frequency: values.frequency,
                                started_at: newTime.unix(),
                            },
                        }).then((res) => {
                            if ('data' in res) {
                                message.success('Order has been successfully rescheduled.');
                                dispatch(setInstructionToReschedule(null));
                                setNewTime(null);
                            } else {
                                message.error('There was an error rescheduling the order.');
                                console.error(res.error);
                            }
                        });
                    }
                }}
            >
                <Form.Item label='Next Due' labelAlign='left' labelCol={{ span: 6 }}>
                    <span className='reschedule-frequency-form__scheduled-for'>
                        {nextAction ? moment.unix(nextAction.scheduled_time ?? nextAction.due_at).format('MM/DD/YYYY hh:mm a') : '~'}
                    </span>
                </Form.Item>
                <Form.Item
                    name='new_time'
                    label='New Time'
                    labelAlign='left'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    required
                    rules={[
                        {
                            validator: async (_: RuleObject, value: any) => {
                                if (!newTime) {
                                    return Promise.reject(new Error(`New time is Required`));
                                }
                                Promise.resolve();
                            },
                        },
                        {
                            validator: validateFieldIsAfterDate('new time', moment()),
                        },
                    ]}
                >
                    <DatePickerWithHours form={form} newTime={newTime} setNewTime={setNewTime} fieldName='new_time' />
                </Form.Item>
                <MedicationFrequency disabled={isIdexx} labelCol={{ span: 6 }} wrapperCol={{ span: 15 }} style={{}} labelAlign='left' />
            </Form>
            <Alert
                style={{
                    marginTop: '16px',
                }}
                message='All future scheduled and claimed orders will be rescheduled based on the new time and frequency.'
                type='info'
            />
        </Modal>
    );
};

export default RescheduleOrder;
