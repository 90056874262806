import { Image } from 'antd';
import PendoBadgeSVG from 'assets/img/pendo_badge_bone.svg';
import './pendo_badge.css';
import { useGetPendoStatusQuery } from 'services/userService';

interface PendoBadgeProps {
    absolute?: boolean;
    collapsed?: boolean;
}
const PendoBadge = ({ absolute = false, collapsed = false }: PendoBadgeProps) => {
    const { data: pendoData } = useGetPendoStatusQuery(null, { pollingInterval: 0 });

    if (!pendoData?.active || !pendoData.showbadge) {
        return null;
    }

    let className = getClass(absolute, collapsed);

    return (
        <Image
            data-cy='pendoBadge'
            className={className}
            preview={false}
            src={PendoBadgeSVG}
            alt=''
            data-pendo='pendoBadge'
            id='pendoBadge'
        />
    );
};

const getClass = (absolute: boolean, collapsed: boolean) => {
    let className = 'pendo-badge';

    if (absolute) {
        className += ' pendo-badge-absolute';
    }

    if (collapsed) {
        className += ' pendo-badge-collapse';
    }
    return className;
};
export default PendoBadge;
