import { Avatar } from 'antd';
import { NoteCategories } from 'utils/types/enums';

interface NoteCategoryAvatarProps {
    noteCategory: NoteCategories;
}

const NoteCategoryAvatar: React.FC<NoteCategoryAvatarProps> = ({
    noteCategory,
}) => {
    if (noteCategory === NoteCategories.ADDENDUM_NOTE) {
        return (
            <Avatar
                style={{
                    backgroundColor: 'var(--veg-secondary-teal)',
                }}
            >
                A
            </Avatar>
        );
    }
    if (noteCategory === NoteCategories.COMMUNICATION_NOTE) {
        return (
            <Avatar
                style={{
                    backgroundColor: 'var(--veg-secondary-plum)',
                }}
            >
                C
            </Avatar>
        );
    }
    if (noteCategory === NoteCategories.PROCEDURE_NOTE) {
        return (
            <Avatar
                style={{
                    backgroundColor: 'var(--veg-secondary-lime)',
                }}
            >
                P
            </Avatar>
        );
    }
    if (noteCategory === NoteCategories.PROGRESS_NOTE) {
        return (
            <Avatar
                style={{
                    backgroundColor: 'var(--veg-tint-blue)',
                }}
            >
                P/S
            </Avatar>
        );
    }
    if (noteCategory === NoteCategories.RECOMMENDATION_NOTE) {
        return (
            <Avatar
                style={{
                    backgroundColor: 'var(--veg-tint-teal)',
                }}
            >
                R
            </Avatar>
        );
    }
    if (noteCategory === NoteCategories.PATIENT_HISTORY_NOTE) {
        return (
            <Avatar
                style={{
                    backgroundColor: 'var(--veg-tint-plum)',
                }}
            >
                H
            </Avatar>
        );
    }

    return null;
};

export default NoteCategoryAvatar;
