import moment from 'moment';
import { SMSTextMessage } from 'utils/dataTypes';
import { formatPhoneNumber } from 'utils/formatFuncs';
import './MessageHeader.css';

interface IMessageHeaderProps {
	message: SMSTextMessage;
}

const MessageHeader = ({ message }: IMessageHeaderProps) => {
	return (
		<div className='chat-message-header'>
			<h2>
				{message.owner_first_name
					? `${message.owner_first_name} ${message.owner_last_name ?? ''}`
					: ''}
			</h2>
			<div className='chat-message__phone-number'>
				{formatPhoneNumber(message.to_number)}
			</div>
			<div className='chat-message__date'>
				{moment.unix(message.created_at).format('dddd, MMMM DD, YYYY')}
			</div>
		</div>
	);
};

export default MessageHeader;
