import { Form, FormInstance, Input, Radio, Tooltip } from 'antd';
import PackageItemTitleContainer from 'components/forms/PackageFormModal/PackageItemTitleContainer';
import React, { useEffect, useState } from 'react';
import { useLazyGetSupplementalByIdQuery } from 'services/supplementalService';
import { roundTo } from 'utils/formatFuncs';
import { FluidEstimateItemNew } from 'utils/types/estimateTypesNew';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';
import EditLineButton from '../forms/PackageFormModal/EditLineButton';
import { HiddenInput } from '../forms/fields/HiddenInput';
import EstimateInstructionFormItem from './EstimateMedicationForm/EstimateInstructionFormItem';
import EstimateStartEndTimeFormItems from './EstimateMedicationForm/EstimateStartEndTimeFormItems';
import { EstimateFormInstance } from './index';

interface EstimateOxygenTherapyFormProps {
    fluid: FluidEstimateItemNew;
    form: FormInstance<EstimateFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const EstimateOxygenTherapyForm: React.FC<EstimateOxygenTherapyFormProps> = ({
    fluid,
    form,
    index,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [trigger, { data: supplementalData }] = useLazyGetSupplementalByIdQuery();

    const [hasError, setHasError] = useState(false);
    const lineId = `OT-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const oxygenTherapyFields = [
        ['oxygen_therapy', index, 'quantity'],
        ['oxygen_therapy', index, 'end_time'],
        ['oxygen_therapy', index, 'start_time'],
        ['oxygen_therapy', index, 'instruction'],
    ];
    const oxygenTherapyErrorArray = form.getFieldsError(oxygenTherapyFields);

    const handleShouldUpdate = (prev: EstimateFormInstance, curr: EstimateFormInstance) => {
        return (
            prev.oxygen_therapy[index].start_time !== curr.oxygen_therapy[index].start_time ||
            prev.oxygen_therapy[index].end_time !== curr.oxygen_therapy[index].end_time ||
            prev.oxygen_therapy[index].quantity !== curr.oxygen_therapy[index].quantity ||
            prev.oxygen_therapy[index].action !== curr.oxygen_therapy[index].action
        );
    };

    const setOxygenTherapyFieldValue = (value: any) => {
        const fieldsValue = form.getFieldsValue();

        if (fieldsValue.oxygen_therapy) {
            form.setFieldsValue({
                ...fieldsValue,
                oxygen_therapy: fieldsValue.oxygen_therapy.map((ot: any, indx: number) => {
                    return indx === index ? { ...ot, ...value } : ot;
                }),
            });
        }
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['oxygen_therapy', index, 'quantity'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (oxygenTherapyErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, oxygenTherapyErrorArray]);

    useEffect(() => {
        if (fluid.supplemental_id) {
            trigger({ supplemental_id: fluid.supplemental_id });
        }
    }, [fluid.supplemental_id]);

    useEffect(() => {
        if (supplementalData) {
            setOxygenTherapyFieldValue({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const initialError = !getFieldValue(['oxygen_therapy', index, 'quantity']);

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing && getFieldValue(['oxygen_therapy', index, 'action']) === 0)
                                setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['oxygen_therapy', index, 'id']} initialValue={fluid.fluids_id} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'name']} initialValue={fluid.name} />
                            <HiddenInput
                                fieldName={['oxygen_therapy', index, 'supplemental_cost_cents']}
                                initialValue={fluid.supplemental_cost_cents}
                            />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'is_recurring_supplemental']} initialValue={false} />

                            <HiddenInput fieldName={['oxygen_therapy', index, 'type_id']} initialValue='OT' />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'instruction_id']} initialValue={fluid.instruction_id} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'is_prn']} initialValue={fluid.is_prn} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'prn_condition']} initialValue={fluid.prn_condition} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'priority']} initialValue={fluid.priority} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'is_free']} initialValue={fluid.is_free} />
                        </div>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer no_charge={fluid.is_free} checked>
                                <Tooltip title={fluid.name.length > 45 ? fluid.name : null}>
                                    <span className='treatment-sheet-side-column__title'>{fluid.name}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Transfer Options
                        </div>

                        <div className='package-medication-dose__inputs'>
                            <Form.Item name={['oxygen_therapy', index, 'action']} initialValue={0}>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Transfer</Radio.Button>
                                    <Radio.Button value={1}>Exclude</Radio.Button>
                                    <Radio.Button value={2}>Save for Later</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        {
                            <>
                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Quantity
                                </div>
                                <div className='fluid-dose-container'>
                                    <Form.Item
                                        name={['oxygen_therapy', index, 'quantity']}
                                        initialValue={fluid.oxygen_quantity ? roundTo(fluid.oxygen_quantity / 100, 2) : null}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Cannot be smaller or equal to 0');
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input suffix={getOxygenTherapyUnit(fluid.name)} min={0} />
                                        ) : (
                                            <span>
                                                {getFieldValue(['oxygen_therapy', index, 'quantity'])
                                                    ? `${getFieldValue(['oxygen_therapy', index, 'quantity'])}`
                                                    : '-'}
                                                {getOxygenTherapyUnit(fluid.name)}
                                            </span>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className='empty-element' />
                                <div className='empty-element' />

                                <EstimateStartEndTimeFormItems
                                    endTimeFormFieldName={['oxygen_therapy', index, 'end_time']}
                                    endTimeFormFieldValue={getFieldValue(['oxygen_therapy', index, 'end_time'])}
                                    isEditing={isEditing}
                                    startTimeFormFieldName={['oxygen_therapy', index, 'start_time']}
                                    startTimeFormFieldValue={getFieldValue(['oxygen_therapy', index, 'start_time'])}
                                    disabled={getFieldValue(['oxygen_therapy', index, 'action']) !== 0}
                                />

                                <EstimateInstructionFormItem
                                    formFieldName={['oxygen_therapy', index, 'instruction']}
                                    initialValue={null}
                                    isEditing={isEditing}
                                    formFieldValue={getFieldValue(['oxygen_therapy', index, 'instruction'])}
                                    disabled={getFieldValue(['oxygen_therapy', index, 'action']) !== 0}
                                />

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />
                            </>
                        }
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default EstimateOxygenTherapyForm;
