import { Col, Input, Form } from 'antd';
// import Form from 'antd/lib/form/Form';
import { CSSProperties, useEffect, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { useLazyGetSupplementalByIdQuery } from 'services/supplementalService';
import { BASE_QUERY_OPTIONS, INSTRUCTION_FREQUENCY_REGEX } from 'utils/constants';
import { PimsUser } from 'utils/dataTypes';
import { DiagnosticEstimateFormFields } from 'utils/types/billingTypes';
import { DiagnosticSearchOption, isInstanceOfDiagnosticOption, isInstanceOfPackageDiagnosticInstruction, PackageDiagnosticInstruction } from 'utils/types/InstructionOrderTypes';
import { DiagnosticEstimateRules } from 'utils/types/validations';
import { HiddenInput } from '../fields/HiddenInput';
import { MedicationFrequency } from '../MedicineOrder';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';

export const getDiagnosticEstimateFormDefaults = (
    dataSource: DiagnosticSearchOption | PackageDiagnosticInstruction,
    user?: PimsUser
): Partial<DiagnosticEstimateFormFields> => {
    let defaultInstruction: Partial<DiagnosticEstimateFormFields>;
    if (isInstanceOfPackageDiagnosticInstruction(dataSource)) {
        defaultInstruction = {
            diagnostic_id: dataSource.diagnostic_id,
            type_id: dataSource.type_id,
            unit_cost_cents:  dataSource.cents,
            name: dataSource.name,
            frequency: dataSource?.frequency ?? '',
            is_prn: false,
            prn_condition: null,
            priority: false,
            unit: "unit",
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null
        }
    } else {
        defaultInstruction = {
            diagnostic_id: dataSource.id,
            type_id: dataSource.type_id,
            unit_cost_cents: dataSource.cents,
            name: dataSource.name,
            frequency: dataSource?.default_frequency || '',
            is_prn: false,
            prn_condition: null,
            priority: false,
            unit: "unit",
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null

        }
    }
    return defaultInstruction;
};
export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';
interface DiagnosticEstimateFormProps {
    dataSource: DiagnosticSearchOption | PackageDiagnosticInstruction;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    getFormData?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    diagnostic: DiagnosticSearchOption;
    durationHours?: number;
}
export const DiagnosticEstimateForm = (props: DiagnosticEstimateFormProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );

    const { dataSource, diagnostic, durationHours } = props;
    const isPackageInstruction = isInstanceOfPackageDiagnosticInstruction(dataSource)

    const [trigger, {data: supplementalData}] = useLazyGetSupplementalByIdQuery()

    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 18 };

    const [isSerialCheaper, setIsSerialCheaper] = useState(false)

    const onFormChange = (changes: any) => {  
        let serial = false   
        if (props?.getFormData){
            const fields = {
                ...props?.getFormData(),
                ...changes
            };
            serial = checkIfSerialCheaper(diagnostic, fields.frequency, 0, (durationHours ?? 0) * 3600)
            setIsSerialCheaper(serial)
        }
        if (props?.onFormChange){ 
            let unit_cost_cents;
            let unit = "unit";
            if (serial){
                unit_cost_cents = diagnostic.price_cents_serial ?? 0;
                unit = "hours";
            }else{
                unit_cost_cents = diagnostic.cents;
            }
            return props?.onFormChange({...changes, serial, unit_cost_cents, unit})
        } else {
            return (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
        }
    }
    useEffect(() => {
        const defaultDiagnostic = getDiagnosticEstimateFormDefaults(dataSource, loggedInUserData);
        if (isInstanceOfDiagnosticOption(dataSource) && dataSource.supplemental_id){
            trigger({supplemental_id: dataSource.supplemental_id})
        }
        onFormChange(defaultDiagnostic)

    }, [dataSource]);

    useEffect(() => {
		if (supplementalData) {
			onFormChange({
				is_recurring_supplemental: supplementalData.recurring,
				supplemental_cost_cents: supplementalData.cents
			})

		}
	}, [supplementalData])

    const diagnosticEstimateRules: DiagnosticEstimateRules = {
		frequency: [{ required: true, pattern: INSTRUCTION_FREQUENCY_REGEX, }],
	}
    return (
        <>
            {isSerialCheaper && <AlertSerialPrice serialHours={diagnostic.serial_hours ?? 0} inForm />}
            <MedicationFrequency
                autoFocus
                rules={diagnosticEstimateRules.frequency}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                onFormChange={onFormChange}
                disabled={diagnostic.category === "IDEXX_REF_LAB"}
            />
            <HiddenInput fieldName='diagnostic_id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='unit_cost_cents' />
            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='prn_condition' />
            <HiddenInput fieldName='unit' />
            <HiddenInput fieldName='is_free' />
            <HiddenInput fieldName='reason' />
            <HiddenInput fieldName='ordered_by' />
            <HiddenInput fieldName='notes' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='supplemental_cost_cents' />
            <HiddenInput fieldName='is_recurring_supplemental' />
            <HiddenInput fieldName='priority' />
            <HiddenInput fieldName='serial'/>
            <HiddenInput fieldName='serial_hours' initialValue={diagnostic.serial_hours} />

            {/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
        </>
    );
};
