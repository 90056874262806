import { FormInstance } from 'antd';
import { AdjustFluidRateForm } from 'components/forms/AdjustFluidRate';
import { FormName } from 'utils/constants';
import {
	ExistingFluidInstruction,
	ExistingCriInstruction,
	PimsUser,
	ExistingOxygenTherapyInstruction,
} from 'utils/dataTypes';
import { INITIAL_MODAL_STATE } from '../Visit';

export const generateAdjustFluidRateModalState = (
	group: ExistingFluidInstruction | ExistingCriInstruction | ExistingOxygenTherapyInstruction,
	user: PimsUser,
	modalForm: FormInstance<any>,
	onModalFinish: (values: any, formName: FormName) => void,
	setModalstate: Function,
    patientWeight?: number,
) => {
	return {
		isVisible: true,
		okText: 'Save',
		title: group.name,
		width: 550,
		content: (
			<AdjustFluidRateForm
				instruction={
					group
				} //We know it is a real Instruction, since it is not a Root group
				modalForm={modalForm}
				user={user}
				onFinish={onModalFinish}
				setModalState={setModalstate}
                patientWeight={patientWeight}
			/>
		),
		formName: FormName['adjust_fluid_rate'],
		form: modalForm,
		onFinish: (values: any) => {
			modalForm.submit();
		},
		onCancel: () => {
			setModalstate({
				...INITIAL_MODAL_STATE,
				form: modalForm,
			});
			modalForm.resetFields();
		},
	};
};
