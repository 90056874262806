import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagType } from 'utils/types/enums';
import { CONSENT_HOST } from '../utils/constants';

export const consentApi = createApi({
    reducerPath: 'consentApi',
    tagTypes: [TagType.Visit, TagType.ConsentForm, TagType.VaccinationForm],
    baseQuery: fetchBaseQuery({
        baseUrl: CONSENT_HOST,
        credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    endpoints: () => ({}),
});
