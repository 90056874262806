import { Row, Tooltip, Table, Empty, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserNameWrapper } from 'utils/componentWrappers';
import { MISSION_CONTROL_DATE_FORMAT, VISIT_TABS_LINKS } from 'utils/constants';
import { InstructionAction, MedicineCard } from 'utils/dataTypes';

interface ClosedMedicinesProps {
    medicines: MedicineCard[];
    showFullTable?: boolean;
}

export const ClosedMedicines = React.memo(
    ({ medicines, showFullTable }: ClosedMedicinesProps) => {
        const history = useHistory();
        const columns: any[] = [
            {
                title: 'Type',
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (name: string, record: MedicineCard) => (
                    <Tooltip placement='topLeft' title={name}>
                        {name}
                    </Tooltip>
                ),
            },
            {
                title: 'Complete',
                dataIndex: 'action',
                key: 'action',
                align: 'left',
                render: (value: InstructionAction, record: MedicineCard) => {
                    if (record.action.completed_at) {
                        return moment
                            .unix(record.action.completed_at)
                            .format(MISSION_CONTROL_DATE_FORMAT);
                    }
                    return '';
                },
                sorter: (a: MedicineCard, b: MedicineCard) => (a.action.completed_at || 0) - (b.action.completed_at || 0),
                defaultSortOrder: 'ascend',
                sortDirections: ['ascend', 'descend', 'ascend']

            },
        ];
        let scroll = {
            y: 120,
        };
        if (showFullTable) {
            const completedByColumn = {
                title: 'Completed By',
                dataIndex: 'action',
                key: 'action',
                align: 'left',
                render: (value: InstructionAction, record: MedicineCard) => {
                    return (
                        <UserNameWrapper>
                            {record.action.status_transition_by}
                        </UserNameWrapper>
                    );
                },
            };

            columns.push(completedByColumn);

            scroll = {
                y: 525
            };
        }

        return (
            <Table
                className='overview-order-table'
                onRow={() => ({
                    onClick: () => {
                        if (!window.getSelection()?.toString()) {
                            history.push(
                                `${history.location.pathname}?section=${VISIT_TABS_LINKS.treatmentSheet}`,
                            );
                        }
    
                    }
                })}
                title={() => (
                    <Row>
                        <Typography.Text className='overview-card-content-header'>
                            Completed
                        </Typography.Text>
                    </Row>
                )}
                columns={columns}
                pagination={false}
                dataSource={medicines ?? []}
                size='small'
                scroll={scroll}
                rowClassName="overview-order-table-row"
            />
        );
    },
);
