import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

interface InstructionFormItemProps {
    formFieldName: NamePath;
    initialValue?: string | null;
    isEditing?: boolean;
    formFieldValue?: string;
}

const InstructionFormItem: React.FC<InstructionFormItemProps> = ({ formFieldName, formFieldValue, initialValue, isEditing }) => {
    return (
        <>
            <div className='package-modal__mobile-header'>Instructions</div>
            <Form.Item name={formFieldName} initialValue={initialValue}>
                {isEditing ? <TextArea placeholder='instructions' rows={1} autoSize /> : <span>{formFieldValue}</span>}
            </Form.Item>
        </>
    );
};

export default InstructionFormItem;
