import { useGetPimsUserQuery } from 'services/userService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { PimsUser } from 'utils/dataTypes';

// This is really just a wrapper so we don't have
// multiple entry points

const useGetPimsUser = (userId: number): [PimsUser] => {
	const { data } = useGetPimsUserQuery<{
		data: PimsUser;
	}>(userId, BASE_QUERY_OPTIONS);

	return [data];
};

export default useGetPimsUser;
