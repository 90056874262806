import './NoteSection.css';
import { Button, Col, Form, Input, Row } from 'antd';
import { useState } from 'react';

const { TextArea } = Input;

interface NoteSectionProps {
	onFormChange: Function;
	labelSpan: number;
	valueSpan: number;
	initNote?: String;
}

const NoteSection = ({
	onFormChange,
	labelSpan,
	valueSpan,
	initNote,
}: NoteSectionProps) => {
	const [displayNote, setDisplayNote] = useState(Boolean(initNote));

	return (
		<Row className='note-section'>
			<Col span={labelSpan}>Note:</Col>

			<Col span={valueSpan}>
				{!!displayNote ? (
					<Form.Item name={'note'} wrapperCol={{ span: 24 }}>
						<TextArea
							onChange={(e) => {
								onFormChange({
									note: e.target.value,
								});
							}}
						/>
					</Form.Item>
				) : (
					<Button
						className='add-note-button'
						onClick={() => setDisplayNote(true)}
					>
						Add Note
					</Button>
				)}
			</Col>
		</Row>
	);
};

export default NoteSection;
