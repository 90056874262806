import { DeleteOutlined, DownloadOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Alert, Button, Checkbox, Form, FormInstance, Input, Tooltip, Upload, UploadProps, message } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { UploadFile } from 'antd/lib/upload/interface';
import RecentResultsModal from 'components/Modals/RecentResults';
import { AppDispatch, useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moment from 'moment';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDownloadIdexxResultFileQuery, useLazyGetIdexxRecentResultsQuery, useLazyGetWebpacsRecentResultsQuery, useModifyActionMutation, useSubmitIHDOrderMutation, useSubmitXRayGenerateLinkMutation, useSubmitXRayOrderMutation } from 'services/instructionService';
import { setActiveTreatmentSheetAsk } from 'store/slices/treatmentSheetSlice';
import { BASE_QUERY_OPTIONS, FormName } from 'utils/constants';
import { roundTo } from 'utils/formatFuncs';
import { IHDResultsFile } from 'utils/types/InstructionOrderTypes';
import { INITIAL_MODAL_STATE } from 'views/visit/Visit';
import {
	ExistingCriInstruction,
	ExistingDiagInstruction,
	ExistingFluidInstruction,
	ExistingMedInstruction,
	ExistingOxygenTherapyInstruction,
	ExistingToGoMedInstruction,
	INSTRUCTION_STATUSES,
	InstructionAction,
	PimsUser,
	TreatmentSheetInstructionAction,
	VCPExternalResult,
	instructionType,
	isInstanceOfCRIInstruction,
	isInstanceOfExistingCriInstruction,
	isInstanceOfExistingDiagInstruction,
	isInstanceOfMedicineInstruction,
} from '../../utils/dataTypes';
import { CompleteStatusActionForm } from './ActionForms/CompleteStatusActionForm';
import { FullActionForm } from './ActionForms/FullActionForm';
import { WebpacsAdministerForm } from './WebpacsAdminsterForm';
import { CubexCheckbox } from './fields/CheckCubex';
import { HiddenInput } from './fields/HiddenInput';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const formItemStyle: CSSProperties = {
	width: '100%',
};

const DEFAULT_LABEL_SPAN = 6;
const DEFAULT_VALUE_SPAN = 18;

interface AdministerMedicationFormProps {
	allInstructions: instructionType[];
	action: TreatmentSheetInstructionAction;
	instruction: ExistingMedInstruction | ExistingFluidInstruction | ExistingCriInstruction | ExistingDiagInstruction | ExistingToGoMedInstruction | ExistingOxygenTherapyInstruction; //TODO allow passing in Diag and Task instruction type
	user: PimsUser;
	onModalFinish: Function;
	setModalState: Function;
	modalForm: FormInstance<any>;
	onFormChange?: Function;
	patientWeight?: number;
	patientName: string;
	statusValue: INSTRUCTION_STATUSES;
	setStatusValue: React.Dispatch<React.SetStateAction<INSTRUCTION_STATUSES>>;
}

export const AdministerMedicationForm = ({
	allInstructions,
	action,
	instruction,
	user,
	onModalFinish,
	setModalState,
	modalForm,
	onFormChange,
	patientWeight,
	patientName,
	statusValue,
	setStatusValue,
}: AdministerMedicationFormProps) => {
	const dispatch = useAppDispatch();
	const [assignedUser, setAssignedUser] = useState(action.assigned_to);
	const { data: currentVisit } = useGetVisitByIdQuery(instruction.visit_id);
	const { data: hospitalData } = useGetHospitalByIdQuery(currentVisit?.hospital_id ?? skipToken, {
        ...BASE_QUERY_OPTIONS,
		skip: !currentVisit?.hospital_id,
    });
	const { data: resultsFile, isLoading: isFileDownloading } = useDownloadIdexxResultFileQuery(
		{ actionId: action.action_id },
		{ skip: instruction.category !== "IDEXX" && instruction.category !== "WebPACS" }
	);
	const [submitIHDOrder, { isLoading: isIHDOrderLoading }] = useSubmitIHDOrderMutation();
	const [submitXRayOrder, { isLoading: isXRayOrderLoading }] = useSubmitXRayOrderMutation();
	const [submitXRayGenerateLink, { isLoading: isXRayLinkGenerationLoading }] = useSubmitXRayGenerateLinkMutation();
	const isControlledDrug =
		(isInstanceOfMedicineInstruction(instruction) || isInstanceOfCRIInstruction(instruction) || instruction.type_id === 'TGH')
		&& instruction.controlled_drug;
	const isCRIAsjustRate = isInstanceOfCRIInstruction(instruction) && action.value;
	const cubexPushForNonControlledDrugAllowed =
        hospitalData?.has_supply_bank &&
        (isInstanceOfMedicineInstruction(instruction) || isInstanceOfExistingCriInstruction(instruction)) &&
        instruction.vetspire_id !== null;
    const cubexPushAllowed = (isControlledDrug || cubexPushForNonControlledDrugAllowed) && !isCRIAsjustRate;
	const onFormChangeDef =
		onFormChange ??
		(() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

	const activeActionModal = useAppSelector((state) => state.treatmentSheetSlice.activeActionModal);
	const activeTreatmentSheetAsk = useAppSelector(state => state.treatmentSheetSlice.activeTreatmentSheetAsk);

	const [modifyAction] = useModifyActionMutation();
	const [recentResults, setRecentResults] = useState<VCPExternalResult[]>([]);
	const [isRecentResultsModalOpen, setIsRecentResultsModalOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<string>('');
	const [completedTime, setCompletedTime] = useState<moment.Moment | null>(
		action.completed_at
			? moment.unix(action.completed_at)
			: moment().startOf('minute')
	);
	const [getRecentIHDResultsTrigger] = useLazyGetIdexxRecentResultsQuery();
	const [getRecentXrayResultsTrigger] = useLazyGetWebpacsRecentResultsQuery();

	//Represents the entire form item, Form.Item wrapper + input element
	let formWidget = <> </>;

	if (isInstanceOfExistingDiagInstruction(instruction)) {
		formWidget = generateFormItem({
			dispatch,
			action,
			instruction,
			onFormChange: onFormChangeDef,
			onModalFinish,
			setModalState,
			modalForm,
			idexxSendOrderMutation: submitIHDOrder,
			idexxSendOrderLoading: isIHDOrderLoading,
			resultsFile,
			isFileDownloading,
			webpacsOrderEvt: submitXRayOrder,
			webpacsOrderLoading: isXRayOrderLoading,
			webpacsLinkGenerationEvt: submitXRayGenerateLink,
			webpacsLinkGenerationLoading: isXRayLinkGenerationLoading,
			patientName,
			isRecentResultsModalOpen,
			setIsRecentResultsModalOpen,
			recentResults,
			setRecentResults,
			selectedRow,
			setSelectedRow,
			completedTime,
			setCompletedTime,
			modifyAction,
			loggedInUserData: user,
			getRecentIHDResultsTrigger,
			getRecentXrayResultsTrigger,
			actionStatus: statusValue,
			setActionStatus: setStatusValue,
			allInstructions,
			activeActionModal: activeActionModal,
		});
	}
	const showWebpacsWarning = isInstanceOfExistingDiagInstruction(instruction) && instruction.result_entry.widget === 'webpacs' && statusValue === 'complete' && !action.value
	useEffect(() => {
		const initData = {
			status: activeActionModal === 'CompleteActionModal' ? 'complete' : action.status,
			note: action.note,
			value:
				(instruction as ExistingDiagInstruction).result_entry?.widget === 'numeric'
					? roundTo(action.value, 3)?.toString()
					: action.value,
			actionId: action.id,
			instructionId: instruction.id,
			assigned_to: user.user_id,
		};
		if (activeActionModal === 'CompleteActionModal') {
			setStatusValue('complete');
		}
		else {
			setStatusValue(action.status);
		}
		setTimeout(() => {
			onFormChangeDef(initData);
		}, 100);
	}, [action]);

	return (
		<>
			<HiddenInput fieldName='instructionId' initialValue={instruction.id} />
			<HiddenInput fieldName='actionId' initialValue={action.id} />
			<HiddenInput fieldName='due_at' initialValue={action.due_at} />
			<HiddenInput fieldName='scheduled_time' initialValue={action.scheduled_time} />
			<HiddenInput fieldName='scheduled_by' initialValue={action.scheduled_by} />
			<HiddenInput fieldName='type_id' initialValue={instruction.type_id} />
			{(instruction.type_id === 'F' || instruction.type_id === 'C' || instruction.type_id === 'OT') &&
				<HiddenInput fieldName='value' initialValue={action.value} />
			}

			{activeTreatmentSheetAsk && activeActionModal === 'CompleteActionModal' &&
				<CompleteStatusActionForm
					instruction={instruction}
					action={action}
					user={user}
					onFormChange={onFormChangeDef}
					formWidget={formWidget}
					inFullForm={false}
					setModalState={setModalState}
					modalForm={modalForm}
					patientWeight={patientWeight}
				/>
			}

			{activeTreatmentSheetAsk && activeActionModal !== 'CompleteActionModal' &&
				<FullActionForm
					action={action}
					instruction={instruction}
					user={user}
					onFormChange={onFormChangeDef}
					statusValue={statusValue}
					setStatusValue={setStatusValue}
					assignedUser={assignedUser}
					setAssignedUser={setAssignedUser}
					formWidget={formWidget}
					patientWeight={patientWeight}
				/>
			}

			{cubexPushAllowed && statusValue === 'inprogress' && (
				<CubexCheckbox
					type_id={instruction.type_id}
					status={statusValue}
					onFormChange={onFormChangeDef}
					labelSpan={DEFAULT_LABEL_SPAN}
					wrapperSpan={DEFAULT_VALUE_SPAN}
				/>
			)}

			{(activeActionModal !== 'CompleteActionModal' && showWebpacsWarning) && (
				<Alert
					showIcon
					message="Fetching results will close this window. You must re-open the order to view results."
					type="warning"
				/>
			)}
		</>
	);
};

interface generateFormItemProps {
	dispatch?: AppDispatch;
	allInstructions?: instructionType[];
	action?: TreatmentSheetInstructionAction;
	instruction: ExistingDiagInstruction;
	onFormChange: Function;
	onModalFinish: Function;
	setModalState: Function;
	modalForm: FormInstance<any>;
	idexxSendOrderMutation?: Function;
	idexxSendOrderLoading?: boolean;
	resultsFile?: IHDResultsFile;
	isFileDownloading?: boolean;
	webpacsOrderEvt?: Function;
	webpacsOrderLoading?: boolean;
	webpacsLinkGenerationEvt?: Function;
	webpacsLinkGenerationLoading?: boolean;
	patientName?: string;
	isRecentResultsModalOpen?: boolean;
	setIsRecentResultsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	recentResults?: VCPExternalResult[];
	setRecentResults?: React.Dispatch<React.SetStateAction<VCPExternalResult[]>>;
	selectedRow?: string;
	setSelectedRow?: React.Dispatch<React.SetStateAction<string>>;
	completedTime?: moment.Moment | null;
	setCompletedTime?: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
	modifyAction?: any;
	loggedInUserData?: PimsUser;
	getRecentIHDResultsTrigger?: LazyQueryTrigger<QueryDefinition<void, any, any, VCPExternalResult[], any>>;
	getRecentXrayResultsTrigger?: LazyQueryTrigger<QueryDefinition<void, any, any, VCPExternalResult[], any>>;
	actionStatus?: string;
	setActionStatus?: Function;
	activeActionModal?: string;
}

export const generateFormItem = (props: generateFormItemProps) => {
	const {
		dispatch,
		allInstructions,
		action,
		instruction,
		onFormChange,
		onModalFinish,
		setModalState,
		modalForm,
		idexxSendOrderMutation,
		idexxSendOrderLoading,
		resultsFile,
		isFileDownloading,
		webpacsOrderEvt,
		webpacsOrderLoading,
		webpacsLinkGenerationEvt,
		webpacsLinkGenerationLoading,
		patientName,
		isRecentResultsModalOpen,
		setIsRecentResultsModalOpen,
		recentResults,
		setRecentResults,
		selectedRow,
		setSelectedRow,
		completedTime,
		setCompletedTime,
		modifyAction,
		loggedInUserData,
		getRecentIHDResultsTrigger,
		getRecentXrayResultsTrigger,
		actionStatus,
		setActionStatus,
		activeActionModal,
	} = props;
	const { result_entry } = instruction;

	//JUST the input element
	let formElement = <> </>;

	const additionalProps: any = {};

	if (
		result_entry.widget === 'numeric' ||
		result_entry.widget === 'small text'
	) {
		formElement = (
			<Input
				placeholder={actionStatus === 'complete' ? 'required' : ''}
				autoComplete={'off'}
				data-cy={'formTextInput'}
				autoFocus={true}
				onChange={(e) => {
					if (e.target.value) {
						onFormChange({
							value: e.target.value,
							status: 'complete',
						});
					} else {
						onFormChange({
							value: e.target.value
						});
					}
					if (e.target.value && setActionStatus) {
						setActionStatus('complete');
					}
				}}
				suffix={result_entry.unit}
			/>
		);
	} else if (result_entry.widget === 'big text') {
		formElement = (
			<Input.TextArea
				placeholder={actionStatus === 'complete' ? 'required' : ''}
				autoComplete={'off'}
				autoFocus={true}
				rows={4}
				onChange={(e) => {
					if (e.target.value) {
						onFormChange({
							value: e.target.value,
							status: 'complete',
						});
					} else {
						onFormChange({
							value: e.target.value
						});
					}
					if (e.target.value && setActionStatus) {
						setActionStatus('complete');
					}
				}}
			/>
		);
	} else if (result_entry.widget === 'idexx' && idexxSendOrderMutation !== undefined && idexxSendOrderLoading !== undefined && action !== undefined && allInstructions !== undefined) {
		additionalProps["valuePropName"] = 'value';
		additionalProps["getValueFromEvent"] = (e: any) => {
			if (Array.isArray(e)) {
				return e;
			}
			return e && e.target.files[0];
		};
		formElement =
			action && action.value ? (
				<>
					{instruction.category !== 'IDEXX_REF_LAB' && (
						<>
							<IdexxDownloadButton
								resultsFile={resultsFile}
								loading={isFileDownloading}
							/>
							<IdexxUploadButton
								actionStatus={actionStatus}
								onFormChange={onFormChange}
							/>
						</>
					)}
					{actionStatus === 'complete' &&
						<Tooltip title='Remove result'>
							<Button
								icon={<DeleteOutlined />}
								className='idexx-remove-button'
								onClick={() => {
									onFormChange({
										value: null,
										status: 'scheduled',
									});
									if (setActionStatus) {
										setActionStatus('scheduled');
									}
								}}
							/>
						</Tooltip>
					}
				</>
			) : (
				<>
					<IdexxOrderButton
						diagnostic={instruction}
						currentAction={action}
						onModalFinish={onModalFinish}
						setModalState={setModalState}
						modalForm={modalForm}
						sendOrderMutation={idexxSendOrderMutation}
						loading={idexxSendOrderLoading}
						allInstructions={allInstructions}
					/>
					{instruction.category !== 'IDEXX_REF_LAB' && (
						<IdexxUploadButton
							actionStatus={actionStatus}
							onFormChange={onFormChange}
						/>
					)}
					{instruction.category !== 'IDEXX_REF_LAB' && actionStatus === 'complete' && <Button
						type='primary'
						style={{ marginTop: '5px' }}
						onClick={(e) => {
							e.stopPropagation();
							getRecentIHDResultsTrigger?.().unwrap().then(
								(data: any) => {
									setRecentResults?.(data);
									setSelectedRow?.('');
									setIsRecentResultsModalOpen?.(true);
								}
							);
						}}
					>
						Fetch Results
					</Button>}
					<RecentResultsModal
						dispatch={dispatch}
						patientName={patientName}
						isOpen={isRecentResultsModalOpen}
						setIsOpen={setIsRecentResultsModalOpen}
						order={instruction}
						action={action}
						recentResults={recentResults}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						completedTime={completedTime}
						setCompletedTime={setCompletedTime}
						modifyAction={modifyAction}
						loggedInUserData={loggedInUserData}
					/>
				</>
			);
	} else if (result_entry.widget === 'webpacs'
		&& webpacsOrderEvt !== undefined
		&& webpacsOrderLoading !== undefined
		&& webpacsLinkGenerationEvt !== undefined
		&& webpacsLinkGenerationLoading !== undefined
	) {

		return (<
			WebpacsAdministerForm
			action={action}
			resultsFile={resultsFile}
			isFileDownloading={isFileDownloading}
			actionStatus={actionStatus}
			setActionStatus={setActionStatus}
			instruction={instruction}
			onFormChange={onFormChange}
			modalForm={modalForm}
			webpacsOrderEvt={webpacsOrderEvt}
			webpacsOrderLoading={webpacsOrderLoading}
			activeActionModal={activeActionModal}
			patientName={patientName}
			isRecentResultsModalOpen={isRecentResultsModalOpen}
			setIsRecentResultsModalOpen={setIsRecentResultsModalOpen}
			recentResults={recentResults}
			setRecentResults={setRecentResults}
			selectedRow={selectedRow}
			setSelectedRow={setSelectedRow}
			completedTime={completedTime}
			setCompletedTime={setCompletedTime}
			modifyAction={modifyAction}
			loggedInUserData={loggedInUserData}
			getRecentXrayResultsTrigger={getRecentXrayResultsTrigger}
		/>)
	}
	return (
		<Form.Item
			label={result_entry.widget === 'idexx' ? 'Lab' : result_entry.label}
			labelCol={{ span: DEFAULT_LABEL_SPAN }}
			wrapperCol={{
				span: DEFAULT_VALUE_SPAN,
				offset: result_entry.label ? 0 : DEFAULT_LABEL_SPAN,
			}}
			style={formItemStyle}
			labelAlign='left'
			name={'value'}
			rules={actionStatus === 'complete' ?
				[{ required: true, message: 'Please enter a value' },
				{ pattern: new RegExp(result_entry.condition) }]
				:
				[{ len: 0, message: 'Incomplete should not have a value' }]
			}
			{...additionalProps}
		>
			{formElement}
		</Form.Item>
	);
};

interface IdexxDownloadButtonProps {
	resultsFile: IHDResultsFile | undefined;
	loading: boolean | undefined;
}

const IdexxDownloadButton = ({
	resultsFile,
	loading
}: IdexxDownloadButtonProps) => {
	return (
		<>
			{resultsFile?.metadata?.result_url ?
				<Button
					type='primary'
					href={resultsFile.metadata?.result_url}
					target='_blank'
					icon={<EyeOutlined />}
				>
					View results
				</Button>
				:
				<Button
					type='primary'
					onClick={() => { resultsFile?.url && window.open(resultsFile?.url) }}
					loading={loading}
					icon={<DownloadOutlined />}
				>
					Download results
				</Button>
			}
		</>
	)
}

interface IdexxOrderButtonProps {
	diagnostic: ExistingDiagInstruction;
	onModalFinish: Function;
	setModalState: Function;
	modalForm: FormInstance<any>;
	sendOrderMutation: Function;
	loading: boolean;
	currentAction: TreatmentSheetInstructionAction;
	allInstructions: instructionType[];
}

export const IdexxOrderButton = ({
	diagnostic,
	currentAction,
	onModalFinish,
	setModalState,
	modalForm,
	sendOrderMutation,
	loading,
	allInstructions,
}: IdexxOrderButtonProps) => {
	const appDispatch = useAppDispatch();
	return (
		<Button
			autoFocus
			type='primary'
			loading={loading}
			onClick={() => idexxOpenOrderModal(appDispatch, diagnostic, currentAction, allInstructions, setModalState, modalForm, onModalFinish, sendOrderMutation)}
		>
			{diagnostic.category === 'IDEXX_REF_LAB' ? 'Push Order to Idexx' : 'Send tests to IVLS'}
		</Button >
	)
}

interface IdexxUploadButtonProps {
	actionStatus: string | undefined;
	onFormChange: Function;
}

const IdexxUploadButton = ({
	actionStatus,
	onFormChange,
}: IdexxUploadButtonProps) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const uploadProps: UploadProps = {
		fileList: fileList,
		multiple: false,
		onRemove: file => {
			setFileList([]);
		},
		beforeUpload: file => {
			onFormChange({ value: file });
			setFileList([file]);
			return false;
		},
		accept: 'application/pdf'
	}

	return (
		<Upload {...uploadProps} className='idexx-upload-button'>
			<Button icon={<UploadOutlined />} style={{ marginLeft: '8px' }} disabled={actionStatus !== 'complete'}>Manual upload</Button>
		</Upload>
	)
}


export const idexxFilterSameHourInstructions = (
	currentAction: TreatmentSheetInstructionAction,
	allInstructions: instructionType[],
	currentDiagnostic: ExistingDiagInstruction,
) => {
	type localActionType = InstructionAction & { instructionName: string }

	const idexxInstructions = allInstructions?.filter(
		(instruction) => instruction.type_id === 'D'
			&& instruction.category === currentDiagnostic.category
			&& instruction.discontinued_at === null,
	);

	const scheduledTime = currentAction!.status === 'scheduled' && currentAction!.scheduled_time ? currentAction!.scheduled_time : currentAction!.due_at;


	const oneHourBefore = currentAction ? moment.unix(scheduledTime).subtract(1, 'hour').unix() : moment().unix();
	const oneHourAfter = currentAction ? moment.unix(scheduledTime).add(1, 'hour').unix() : moment().unix();


	const sameHourActions: localActionType[] = [];

	idexxInstructions?.forEach(instr => {
		instr.actions.forEach(action => {
			const scheduledTime = action!.status === 'scheduled' && action!.scheduled_time ? action!.scheduled_time : action!.due_at;
			if (scheduledTime >= oneHourBefore && scheduledTime <= oneHourAfter) {
				sameHourActions.push({
					...action,
					instructionName: instr.name,
				})
			}
		})
	});

	return sameHourActions;
}

interface OrderOption {
	label: string;
	value: number;
	instruction_id: number;
	due_at: number;
	disabled: boolean;
}

export const idexxBuildCloseOrdersMap = (
	diagnostic: ExistingDiagInstruction,
	currentAction: TreatmentSheetInstructionAction,
	allInstructions: instructionType[],
): Map<number, OrderOption> => {
	const orderOptions = idexxFilterSameHourInstructions(currentAction, allInstructions, diagnostic)
        .filter((action) => action.instruction_id !== currentAction?.instruction_id && ['scheduled', 'claimed'].includes(action.status))
        .map((action) => {
			const scheduledTime = action.status === 'scheduled' && action.scheduled_time ? action.scheduled_time : action.due_at
            return {
                label: `${moment.unix(scheduledTime).format('MM/DD/YYYY hh:mm a')} ${action.instructionName}`,
                value: action.id,
                instruction_id: action.instruction_id,
                due_at: scheduledTime,
                disabled: false,
            };
        });

	const baseOrderDueAt = currentAction.status === 'scheduled' && currentAction.scheduled_time ? currentAction.scheduled_time : currentAction.due_at;
	
	orderOptions.unshift(
		{
			label: `${moment.unix(baseOrderDueAt).format("MM/DD/YYYY hh:mm a")} ${diagnostic.name}`,
			value: currentAction!.action_id,
			instruction_id: currentAction.instruction_id,
			due_at: baseOrderDueAt,
			disabled: true,
		}
	);

	const closeOrdersMap = new Map<number, OrderOption>();
	for (let i = 0; i < orderOptions.length; i++) {
		if (
			!closeOrdersMap.has(orderOptions[i].instruction_id)
			|| (
				Math.abs(baseOrderDueAt - (closeOrdersMap.get(orderOptions[i].instruction_id) as OrderOption).due_at)
				> Math.abs(baseOrderDueAt - orderOptions[i].due_at)
			)
		) {
			closeOrdersMap.set(orderOptions[i].instruction_id, orderOptions[i]);
		}
	};
	return closeOrdersMap;
}

export const idexxOpenOrderModal = (
	dispatch: AppDispatch,
	diagnostic: ExistingDiagInstruction,
	currentAction: TreatmentSheetInstructionAction,
	allInstructions: instructionType[],
	setModalState: Function,
	modalForm: FormInstance<any>,
	onModalFinish: Function,
	sendOrderMutation: Function,
) => {
	const closeOrdersMap = idexxBuildCloseOrdersMap(diagnostic, currentAction, allInstructions);

	let ordersToSubmit: { instruction_id: number, due_at: number }[] = [];
	closeOrdersMap.forEach((order) => {
		ordersToSubmit.push({ instruction_id: order.instruction_id, due_at: order.due_at });
	})

	const onOrderCheckboxChange = (orders: CheckboxValueType[]): { instruction_id: number, due_at: number }[] => {
		const checkedOrders: { instruction_id: number, due_at: number }[] = []
		orders.forEach((val) => {
			const matchingOpt = Array.from(closeOrdersMap.values()).find((opt) => opt.value === val)
			if (!!matchingOpt) {
				checkedOrders.push({ instruction_id: matchingOpt.instruction_id, due_at: matchingOpt.due_at });
			}
		})
		return checkedOrders
	};

	if (closeOrdersMap.size > 1) {
		setModalState({
			isVisible: true,
			blank: true,
			zIndex: 1050,
			title: 'Push to Idexx Orders',
			okText: 'Push orders to Idexx',
			content: (
				<>
					<p>Do you want to run other orders while running the <b>{diagnostic.name}</b> test?</p>
					<p>Select from the upcoming orders.</p>
					<Form.Item name='orders' valuePropName="checked">
						<Checkbox.Group style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							marginBottom: '1rem',
						}}
							options={Array.from(closeOrdersMap.values())}
							defaultValue={Array.from(closeOrdersMap.values()).map((order) => order.value)}
							onChange={(orders) => { ordersToSubmit = onOrderCheckboxChange(orders) }}
						/>
					</Form.Item>
					<Alert message="All orders selected will be assigned to you and marked as complete." type="warning" />
				</>
			),
			onFinish: () => {
				idexxConfirmOrderModal(dispatch, ordersToSubmit, setModalState, modalForm, onModalFinish, sendOrderMutation);
			},
			onCancel: () => {
				setModalState({
					...INITIAL_MODAL_STATE,
					form: modalForm,
				});
				modalForm.resetFields();
			},
		})
	} else {
		idexxConfirmOrderModal(dispatch, ordersToSubmit, setModalState, modalForm, onModalFinish, sendOrderMutation);
	}
}

const idexxConfirmOrderModal = (
	dispatch: AppDispatch,
	ordersToSubmit: { instruction_id: number, due_at: number }[],
	setModalState: Function,
	modalForm: FormInstance<any>,
	onModalFinish: Function,
	sendOrderMutation: Function,
) => {

	setModalState({
		...INITIAL_MODAL_STATE,
		form: modalForm,
	});
	modalForm.resetFields();

	sendOrderMutation({ actions: ordersToSubmit }).then((resp: any) => {
		if (!!resp.error) {
			message.error(resp.error.data);
		} else {
			const payload = resp.data.data;
			dispatch(setActiveTreatmentSheetAsk(null));

			if (!!payload.ui_url) {
				let isBlank: boolean = false;
				setModalState({
					isVisible: true,
					blank: true,
					zIndex: 1050,
					okButtonProps: { style: { display: 'none' } },
					title: `Order confirmation (n°${payload.id})`,
					content: (
						<iframe
							src={payload.ui_url}
							title={`IDEXX Order confirmation (n°${payload.id})`}
							height='550px'
							width='100%'
							onLoad={() => {
								if (!isBlank) {
									isBlank = !isBlank;
								} else {
									onModalFinish(
										ordersToSubmit,
										FormName.ivls_confirm,
									);
									setModalState({
										...INITIAL_MODAL_STATE,
										form: modalForm,
									});
									modalForm.resetFields();
								}
							}}
						/>
					),
					onCancel: () => {
						setModalState({
							...INITIAL_MODAL_STATE,
							form: modalForm,
						});
						modalForm.resetFields();
					},
				});
			} else {
				console.error(
					'Could not open IDEXX order confirmation dialog: expected ui_url, got',
					payload,
					'payload',
				);
			}
		}
	})
};
