import { FormInstance } from 'antd';
import { DiagnosticOrder } from 'components/forms/DiagnosticOrder';
import { FluidOrder } from 'components/forms/FluidOrder';
import { MedCriOrder } from 'components/forms/MedCriOrder';
import OxygenTherapyOrder from 'components/forms/OxygenTherapyOrder';
import { TaskOrder } from 'components/forms/TaskOrder';
import { FormName } from 'utils/constants';
import {
	isInstanceOfExistingCriInstruction,
	isInstanceOfExistingDiagInstruction,
	isInstanceOfExistingFluidInstruction,
	isInstanceOfExistingOxygenTherapyInstruction,
	isInstanceOfExistingTaskInstruction,
	isInstanceOfMedicineInstruction,
	TsGroupType,
} from 'utils/dataTypes';
import {
	BaseSearchOption,
	DiagnosticSearchOption,
	DiagnosticSearchOptionWithRefLabs,
	FluidSearchOption,
	MedicineSearchOption,
	TaskSearchOption,
} from 'utils/types/InstructionOrderTypes';

interface IGetFormConfigurationDataParameters {
	group: TsGroupType;
	patientWeight: number;
	baseSearchOption?: BaseSearchOption[];
	modalForm: FormInstance<any>;
	idexxRefLabs?: DiagnosticSearchOptionWithRefLabs[];
}

export const getFormConfigurationData = ({
	group,
	patientWeight,
	baseSearchOption,
	modalForm,
	idexxRefLabs,
}: IGetFormConfigurationDataParameters) => {
	if (isInstanceOfExistingDiagInstruction(group)) {
		const baseDiag = [...baseSearchOption ?? [], ...idexxRefLabs ?? []]?.find(
			(option) =>
				option.id === group.diagnostic_id && option.type_id === 'D',
		);

		return {
			newInstructionFormName: FormName['diag_order'],
			newInstructionForm: (
				<DiagnosticOrder
					options={baseDiag as DiagnosticSearchOption}
					existingInstruction={group}
				/>
			),
		};
	} else if (isInstanceOfExistingTaskInstruction(group)) {
		const baseTask = baseSearchOption?.find(
			(option) => option.id === group.task_id && option.type_id === 'T',
		);

		return {
			newInstructionFormName: FormName['task_order'],
			newInstructionForm: (
				<TaskOrder
					options={baseTask as TaskSearchOption}
					existingInstruction={group}
				/>
			),
		};
	} else if (isInstanceOfMedicineInstruction(group)) {
		const baseMedicine = baseSearchOption?.find(
			(option) =>
				option.id === group.medication_id && option.type_id === 'M',
		);

		return {
			newInstructionFormName: FormName['med_order'],
			newInstructionForm: (
				<MedCriOrder
					options={baseMedicine as MedicineSearchOption}
					existingInstruction={group}
					patientWeight={patientWeight}
					defaultCri={false}
				/>
			),
		};
	} else if (isInstanceOfExistingCriInstruction(group)) {
		const baseDiag = baseSearchOption?.find(
			(med) => med.id === group.medication_id && med.type_id === 'M',
		);

		return {
			newInstructionFormName: FormName['med_order'],
			newInstructionForm: (
				<MedCriOrder
					options={baseDiag as MedicineSearchOption}
					existingInstruction={group}
					patientWeight={patientWeight}
					defaultCri={true}
				/>
			),
		};
	} else if (isInstanceOfExistingFluidInstruction(group)) {
		const fluidOption = baseSearchOption?.find(
			(option) => option.id === group.fluids_id && option.type_id === 'F',
		);

		return {
			newInstructionFormName: FormName['fluid_order'],
			newInstructionForm: (
				<FluidOrder
					options={fluidOption as FluidSearchOption}
					existingInstruction={group}
					form={modalForm}
				/>
			),
		};
	} else if (isInstanceOfExistingOxygenTherapyInstruction(group)) {
		const fluidOption = baseSearchOption?.find(
			(option) => option.id === group.fluids_id && option.type_id === 'F',
		);
		const { id, ...newBodyWithoutInstructionId } = group;

		return {
			newInstructionFormName: FormName['oxygen_therapy_order'],
			newInstructionForm: (
				<OxygenTherapyOrder
					options={fluidOption as FluidSearchOption}
					existingInstruction={newBodyWithoutInstructionId}
				/>
			),
		};
	}

	return { newInstructionForm: <></>, newInstructionFormName: '' };
};
