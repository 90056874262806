import { Typography } from 'antd';
import classes from './page-header.module.css';

interface PageHeaderProps {
	title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, children }) => {
	return (
		<div className={classes.pageHeader}>
			<Typography.Title level={2}>{title}</Typography.Title>
			<div>{children}</div>
		</div>
	);
};

export default PageHeader;
