import { Button, Popover } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { useRemoveSpikedMutation, useSetSpikedMutation } from 'services/vetDashService';
import { UserNameWrapper } from 'utils/componentWrappers';
import { PatientRecordVisit } from 'utils/dataTypes';
import { escapeClose } from 'utils/formFuncs';
import { USER_PERMISSIONS, checkIfUserHasNecessaryPermission } from 'utils/userPermissions';
import './Spike3Popover.css';

interface Spike3PopoverProps {
    currentVisit: PatientRecordVisit;
}

const Spike3Popover: React.FC<Spike3PopoverProps> = ({ currentVisit }) => {
    const [popoverState, setPopOverState] = useState(false);

    const [setSpiked] = useSetSpikedMutation();
    const [removeSpiked] = useRemoveSpikedMutation();

    const { data: loggedInUserData } = useGetUserDataQuery(null);

    const userHasPermissionToAssign = checkIfUserHasNecessaryPermission(loggedInUserData?.user_permissions, USER_PERMISSIONS.visit_claim);

    return (
        <Popover
            open={popoverState}
            destroyTooltipOnHide={true}
            onOpenChange={(show) => {
                setPopOverState(show);
                escapeClose(popoverState, setPopOverState);
            }}
            overlayClassName='spike-3-popover'
            trigger='click'
            title={
                <>
                    <span>Spike 3</span>
                    <Button
                        onClick={() => {
                            setPopOverState(false);
                        }}
                    >
                        X
                    </Button>
                </>
            }
            content={
                <>
                    <div className='spike-3-popover__content'>
                        <p className='spike-3-popover__instruction'>Spike 3: You Will See a Doctor Right Away</p>
                        <p>
                            "At VEG, you see a doctor right away, because it gives customers peace of mind that we're ready and able to give
                            their pets the care they need and eliminating long, stressful wait times."
                        </p>

                        {currentVisit.spiked_by && currentVisit.spiked_at && (
                            <p className='spike-3__doctor'>
                                Completed by <UserNameWrapper>{currentVisit.spiked_by}</UserNameWrapper> on{' '}
                                {moment.unix(currentVisit.spiked_at).format('MM/DD/YYYY HH:mm A')}
                            </p>
                        )}
                    </div>

                    <div className='spike-3-popover__footer'>
                        <Button onClick={() => setPopOverState(false)}>Cancel</Button>
                        {currentVisit.spiked_by && currentVisit.spiked_at ? (
                            <Button
                                className='spike-3-popover__unmark-button'
                                data-pendo='unspike-3-visit-page'
                                disabled={!userHasPermissionToAssign}
                                onClick={() => {
                                    removeSpiked(currentVisit.id);
                                    setPopOverState(false);
                                }}
                            >
                                Unmark Spike 3 Completed
                            </Button>
                        ) : (
                            <Button
                                type='primary'
                                data-pendo='spike-3-visit-page'
                                disabled={!userHasPermissionToAssign}
                                onClick={() => {
                                    setSpiked(currentVisit.id);
                                    setPopOverState(false);
                                }}
                            >
                                Mark Spike 3 Complete
                            </Button>
                        )}
                    </div>
                </>
            }
            placement='rightBottom'
        >
            <span>Spike 3</span>
        </Popover>
    );
};

export default Spike3Popover;
