import moment from 'moment';
import { calculateNumberOfTotalActions } from 'utils/ghostActionUtils';
import { BaseSearchOption, isInstanceOfMedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import {
    EstimateFormFields,
    isInstanceOfCriEstimateFormFields,
    isInstanceOfFluidEstimateFormFields,
    isInstanceOfMedicineEstimateFormFields,
    isInstanceOfNonMedicalEstimateFormFields,
    isInstanceOfOxygenTherapyEstimateFormFields,
    isInstanceOfToGoMedEstimateFormFields,
} from 'utils/types/billingTypes';
import { ConsentFormEstimateItem, ConsentFormEstimateItemMedical, ConsentFormEstimateItemNonMedical, isInstanceOfConsentFormEstimateItemMedical } from 'utils/types/consentFormTypes';
import {
    Estimate,
    EstimateFreeItems,
    EstimateItemNew,
    isInstanceOfCriEstimateItemNew,
    isInstanceOfDiagnosticEstimateItemNew,
    isInstanceOfFluidEstimateItemNew,
    isInstanceOfMedicalEstimateItemNew,
    isInstanceOfNonMedicalEstimateItemNew,
    isInstanceOfOxygenTherapyEstimateItemNew,
    isInstanceOfTaskEstimateItemNew,
    isInstanceOfToGoMedEstimateItemNew,
} from 'utils/types/estimateTypesNew';
import { EstimateTableData } from './EstimateTable';

export const calculateTotalEstimateCost = (cost: number, estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItem): number => {
    if (estimateItem.is_free) {
        return cost;
    } else {
        return cost + calculateTotalCost(estimateItem);
    }
};

export const calculateTotalCost = (estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical): number => {
    if (estimateItem.type_id === 'M' && estimateItem.unit_cost_cents === 0) {
        return 0;
    }
    const itemCost = calculateItemCost(estimateItem);
    return itemCost;
};
export const calculateItemQuantity = (estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical) => {
    if ('quantity' in estimateItem){
        return estimateItem.quantity;
    } else if ('present_quantity' in estimateItem) {
        return estimateItem.present_quantity;
    }
    return 0;
}

export const calculateItemCost = (estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical) => {
    if (estimateItem.unit_cost_cents === 0){
        return 0;
    }
    const quantity = calculateItemQuantity(estimateItem)
    const itemCost = (estimateItem.unit_cost_cents ?? 0) * quantity;
    const supplmentalCost = calculateSupplementalCost(estimateItem)
    return Math.floor(itemCost + supplmentalCost);
}
export const calculateUnitCost = (estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical) => {
    if (estimateItem.unit_cost_cents === 0){
        return 0;
    }
    const supplementalUnitCost = calculateSupplementalUnitCost(estimateItem);

    return Math.floor(supplementalUnitCost + (estimateItem.unit_cost_cents ?? 0))
};


export const calculateSupplementalCost = (estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical) => {
    if (estimateItem.type_id === 'N' || estimateItem.serial) {
        return 0;
    }
    const supplemental_cost_cents = calculateSupplementalUnitCost(estimateItem)

    if (estimateItem.is_recurring_supplemental){
        const quantity = calculateItemQuantity(estimateItem)
        return supplemental_cost_cents * quantity;
    }
    if (estimateItem.type_id === 'C' && estimateItem.pricing_unit) {
        const quantity = calculateItemQuantity(estimateItem);
        return supplemental_cost_cents * quantity;
    }
    return supplemental_cost_cents;

};

export const calculateSupplementalUnitCost = (estimateItem: EstimateItemNew | EstimateFormFields | ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical) => {
    if (estimateItem.type_id === 'N') {
        return 0;
    }
    const quantity = calculateItemQuantity(estimateItem)

    if (estimateItem.supplemental_cost_cents) {
        if (estimateItem.is_recurring_supplemental) {
            if (estimateItem.type_id === 'M' && estimateItem.dose) {
                if (estimateItem.pricing_unit) {
                    return (estimateItem.supplemental_cost_cents * estimateItem.supplemental_quantity) / quantity;
                } else {
                    return estimateItem.supplemental_cost_cents / estimateItem.dose;
                }
            } else {
                return estimateItem.supplemental_cost_cents;
            }
        } else if (quantity > 0) {
            if (estimateItem.type_id === 'C' && estimateItem.pricing_unit) {
                return estimateItem.supplemental_cost_cents / quantity;
            }
            return estimateItem.supplemental_cost_cents;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
};

export const generateEstimateItemTableData = (estimateItem: EstimateItemNew): EstimateTableData => {
    let instructionId: number | null;
    let nonMedicalOrderId: number | null;
    if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
        instructionId = null;
        nonMedicalOrderId = estimateItem.non_medical_order_id;
    } else {
        instructionId = estimateItem.instruction_id;
        nonMedicalOrderId = null;
    }
    let unit: string;
    if ((isInstanceOfMedicalEstimateItemNew(estimateItem) || isInstanceOfCriEstimateItemNew(estimateItem)) && estimateItem.pricing_unit) {
        unit = estimateItem.pricing_unit;
    } else {
        unit = estimateItem.unit;
    }
    let frequency: string;
    if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
        frequency = 'once';
    } else if (
        isInstanceOfFluidEstimateItemNew(estimateItem) ||
        isInstanceOfCriEstimateItemNew(estimateItem) ||
        isInstanceOfOxygenTherapyEstimateItemNew(estimateItem)
    ) {
        frequency = 'continuous';
    } else if (isInstanceOfToGoMedEstimateItemNew(estimateItem)) {
        frequency = 'once';
    } else {
        frequency = estimateItem.frequency;
    }

    const quantity = estimateItem.quantity;
    const name = estimateItem.name;
    const typeId = estimateItem.type_id;
    const isFree = estimateItem.is_free;
    const totalPrice = calculateTotalCost(estimateItem);
    const unitPrice = totalPrice / quantity;
    
    const estimateTableReturn: EstimateTableData = {
        instructionId,
        nonMedicalOrderId,
        typeId,
        name,
        frequency,
        quantity,
        unit,
        isFree,
        unitPrice,
        totalPrice,
        serial: estimateItem.serial ?? false,
        serial_hours: estimateItem.serial_hours ?? undefined,
    };

    return estimateTableReturn;
};

export const generateEstimateItemTableDataFormFields = (estimateItem: EstimateFormFields, index: number): EstimateTableData => {
    let unit: string;
    if ((isInstanceOfMedicineEstimateFormFields(estimateItem) || isInstanceOfCriEstimateFormFields(estimateItem))  && estimateItem.pricing_unit) {
        unit = estimateItem.pricing_unit;
    } else {
        unit = estimateItem.unit;
    }
    let frequency: string;
    if (isInstanceOfNonMedicalEstimateFormFields(estimateItem)) {
        frequency = 'once';
    } else if (
        isInstanceOfFluidEstimateFormFields(estimateItem) ||
        isInstanceOfCriEstimateFormFields(estimateItem) ||
        isInstanceOfOxygenTherapyEstimateFormFields(estimateItem)
    ) {
        frequency = 'continuous';
    } else if (isInstanceOfToGoMedEstimateFormFields(estimateItem)) {
        frequency = 'once';
    } else {
        frequency = estimateItem.frequency;
    }

    const quantity = estimateItem.quantity;
    const name = estimateItem.name;
    const typeId = estimateItem.type_id;
    const isFree = estimateItem.is_free;
    const totalPrice = calculateTotalCost(estimateItem);
    const unitPrice = totalPrice / quantity;

    const estimateTableReturn: EstimateTableData = {
        newItemId: estimateItem.timestamp,
        instructionId: null,
        nonMedicalOrderId: null,
        typeId,
        name,
        frequency,
        quantity,
        unit,
        isFree,
        unitPrice,
        totalPrice,
        serial: estimateItem.serial ?? false,
        serial_hours: estimateItem.serial_hours ?? undefined,
        free_from_package: estimateItem.free_from_package,
    };

    return estimateTableReturn;
};

export const generateEstimateTableData = (estimate: Estimate): EstimateTableData[] => {
    const data: EstimateTableData[] = estimate.estimate_items.map(generateEstimateItemTableData);

    return data;
};

export const generateQuantity = (
    estimate: EstimateFormFields | EstimateItemNew,
    durationHours?: number,
    searchOption?: BaseSearchOption,
): number => {
    // Ignore duration for nonmeds
    if (isInstanceOfNonMedicalEstimateItemNew(estimate) || isInstanceOfNonMedicalEstimateFormFields(estimate)) {
        return estimate.quantity;
    }

    // Guard if no duration is set
    if (!durationHours || durationHours < 0) {
        return 0;
    }
    // Normal Case
    let quantity: number;
    if (
        isInstanceOfFluidEstimateFormFields(estimate) ||
        isInstanceOfFluidEstimateItemNew(estimate) ||
        isInstanceOfOxygenTherapyEstimateFormFields(estimate) ||
        isInstanceOfOxygenTherapyEstimateItemNew(estimate)
    ) {
        quantity = durationHours * 60;
    } else if (isInstanceOfCriEstimateFormFields(estimate) || isInstanceOfCriEstimateItemNew(estimate)) {
        const weight = estimate.latest_patient_weight_kg || estimate.approx_patient_weight_kg || 0;
        const lengthOfCri = durationHours; // one day in hours
        if (estimate.dose_unit === 'mcg/kg/min') {
            quantity = (weight * lengthOfCri * estimate.dose * 60) / 1000;
        } else {
            quantity = weight * lengthOfCri * estimate.dose;
        }
        if (isInstanceOfMedicineSearchOption(searchOption)) {
            const { dose_unit, denominator_unit, numerator_value, denominator_value } = searchOption;
            if (dose_unit === denominator_unit && denominator_value && numerator_value) {
                quantity = (quantity * denominator_value) / numerator_value;
            }
        }
        if (estimate.pricing_unit && estimate.pricing_unit_size) {
            quantity = Math.ceil(quantity / estimate.pricing_unit_size);
        }
    } else if (isInstanceOfToGoMedEstimateFormFields(estimate) || isInstanceOfToGoMedEstimateItemNew(estimate)) {
        quantity = estimate.dispense_value;
    } else {
        const now = moment.now();
        const windowStart = moment(now).startOf('minute').unix();
        const windowEnd = moment(windowStart * 1000)
            .startOf('minute')
            .add(durationHours, 'h')
            .unix();
        const start = estimate?.start_time || windowStart;
        const end = estimate?.end_time || windowEnd;
        if (estimate.serial && estimate.serial_hours) {
            quantity = Math.ceil((end - start) / (estimate.serial_hours * 60 * 60)) * estimate.serial_hours;
        } else if (isInstanceOfMedicineEstimateFormFields(estimate) || isInstanceOfMedicalEstimateItemNew(estimate)) {
            const actionNumbers = calculateNumberOfTotalActions(start, end, windowStart, windowEnd, estimate.frequency);
            if (estimate.pricing_unit && estimate.pricing_unit_size) {
                quantity = Math.ceil((actionNumbers * estimate.dose) / estimate.pricing_unit_size);
            } else {
                quantity = actionNumbers * estimate.dose;
            }
        } else {
            quantity = calculateNumberOfTotalActions(start, end, windowStart, windowEnd, estimate.frequency);
        }
    }
    return quantity;
};

export const isEstimateItemInsteadOfEstimateFormFields = (item: EstimateFormFields | EstimateItemNew): item is EstimateItemNew => {
    return (
        isInstanceOfNonMedicalEstimateItemNew(item) ||
        isInstanceOfMedicalEstimateItemNew(item) ||
        isInstanceOfCriEstimateItemNew(item) ||
        isInstanceOfFluidEstimateItemNew(item) ||
        isInstanceOfOxygenTherapyEstimateItemNew(item) ||
        isInstanceOfTaskEstimateItemNew(item) ||
        isInstanceOfToGoMedEstimateItemNew(item) ||
        isInstanceOfDiagnosticEstimateItemNew(item)
    );
};

export const isFreeDisabled = (record: EstimateTableData, freeItems: EstimateFreeItems[]) => {
    if (record.instructionId !== null) {
        const isInFreeItems = freeItems.some((freeItem) => {
            if ('instruction_id' in freeItem) {
                return record.instructionId === freeItem.instruction_id;
            } else {
                return false;
            }
        });
        return !isInFreeItems && record.isFree;
    } else if (record.nonMedicalOrderId !== null) {
        const isInFreeItems = freeItems.some((freeItem) => {
            if ('non_medical_order_id' in freeItem) {
                return record.nonMedicalOrderId === freeItem.non_medical_order_id;
            } else {
                return false;
            }
        });
        return !isInFreeItems && record.isFree;
    } else {
        return false;
    }
};

export const searchForOptionWithEstimate = (
    searchOptions: BaseSearchOption[] | undefined,
    estimateItem: EstimateItemNew,
): BaseSearchOption | undefined => {
    if (!searchOptions) {
        return undefined;
    }
    return searchOptions.find((searchOption) => {
        if (
            searchOption.type_id === estimateItem.type_id ||
            (searchOption.type_id === 'M' && isInstanceOfCriEstimateItemNew(estimateItem)) ||
            (searchOption.type_id === 'M' && isInstanceOfToGoMedEstimateItemNew(estimateItem)) ||
            (searchOption.type_id === 'F' && isInstanceOfOxygenTherapyEstimateItemNew(estimateItem))
        ) {
            if (isInstanceOfDiagnosticEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.diagnostic_id;
            } else if (isInstanceOfTaskEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.task_id;
            } else if (isInstanceOfMedicalEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.medication_id;
            } else if (isInstanceOfCriEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.medication_id;
            } else if (isInstanceOfToGoMedEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.medication_id;
            } else if (isInstanceOfFluidEstimateItemNew(estimateItem) || isInstanceOfOxygenTherapyEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.fluids_id;
            } else if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
                return searchOption.id === estimateItem.non_medical_id;
            }
        }
        return false;
    });
};
export const getPendingEstimateItems = (estimates: Estimate[]): EstimateItemNew[] =>
    estimates.flatMap((estimate) => {
        if (estimate.estimate_status === 'approved') {
            return estimate?.estimate_items?.filter((estimateItem) => {
                return !estimateItem.is_ordered && estimateItem.is_shown_on_tx_sheet;
            });
        }
        return [];
    });

export function getOxygenTherapyUnit(name: string) {
    return name.includes('Cage') ? '%' : 'L/min';
}
