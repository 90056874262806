// TODO This may be generally resuable find a good place for this file to live
import { Row, Col, Card } from 'antd';
import React from 'react';

interface ResizeableCardProps {
	title?: React.ReactNode;
	defaultCardSpan: number;
	children?: React.ReactNode;
}
interface ResizeableCardTitleProps {
	children?: React.ReactNode;
}
const ResizeableCardTitle = React.memo(
	({ children }: ResizeableCardTitleProps) => {
		return (
			<Row justify='space-between'>
				<Col span={24}>{children}</Col>
			</Row>
		);
	},
);
export const ResizableCard = React.memo(
	({ title, defaultCardSpan, children }: ResizeableCardProps) => {
		return (
			<Col span={defaultCardSpan}>
				<Card
					style={{ height: '100%' }}
					title={
						title ? (
							<ResizeableCardTitle>{title}</ResizeableCardTitle>
						) : undefined
					}
				>
					{children}
				</Card>
			</Col>
		);
	},
);
