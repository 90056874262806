import { Spin } from 'antd';
import React from 'react';
import { PatientNote, useGetVisitsByPatientIdQuery, useListPatientNotesQuery } from 'services/patientService';
import NoteCard from './NoteCard';
import VisitCard from './VisitCard';

interface PatientItemsProps {
    pet_id: string;
    filterVisitBalance?: string[];
    setNoteToUpdate: (noteItem: PatientNote) => void;
    sumVisitBalance?: (visitId: number, value: number) => void;
}

const PatientItems: React.FC<PatientItemsProps> = ({ pet_id, filterVisitBalance, setNoteToUpdate, sumVisitBalance }) => {
    const { data: allVisits } = useGetVisitsByPatientIdQuery(pet_id, { refetchOnMountOrArgChange: true });
    const { data: patientNotes } = useListPatientNotesQuery(pet_id, { refetchOnMountOrArgChange: true });

    if (!allVisits || !patientNotes) return <Spin />;

    return (
        <>
            {[...allVisits, ...patientNotes]
                .sort((a, b) => {
                    if ('arrived_at' in a && 'note' in b) {
                        return b.created_at - a.arrived_at;
                    }

                    if ('note' in a && 'arrived_at' in b) {
                        return b.arrived_at - a.created_at;
                    }

                    if ('arrived_at' in a && 'arrived_at' in b) {
                        return b.arrived_at - a.arrived_at;
                    }

                    if ('note' in a && 'note' in b) {
                        return b.created_at - a.created_at;
                    }

                    return 0;
                })
                .filter((item) => {
                    // Visits
                    if ('arrived_at' in item) {
                        return (
                            !filterVisitBalance?.length ||
                            (filterVisitBalance?.includes('paid_in_full') && item.balance_due === 0) ||
                            (filterVisitBalance?.includes('balance_due') && item.balance_due > 0) ||
                            (filterVisitBalance?.includes('refund_due') && item.balance_due < 0)
                        );
                    }
                    // Notes
                    return true;
                })
                .map((item) => {
                    if ('arrived_at' in item) {
                        if (!!sumVisitBalance) {
                            sumVisitBalance(item.id, item.balance_due);
                        }

                        return <VisitCard key={`visit_${item.id}`} visit={item} />;
                    }

                    return <NoteCard key={`note_${item.id}`} noteItem={item} setNoteToUpdate={setNoteToUpdate} />;
                })}
        </>
    );
};

export default PatientItems;
