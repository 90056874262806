import moment from 'moment';
import { calculateTotalCost } from 'utils/miscFuncs';
import { BillingItem, BillingItemDisplay, EarnedRevenueLineItem } from 'utils/types/billingTypes';

export const getBillingItemsDisplay = (billingItems: BillingItem[], displayType?: string): BillingItemDisplay[] => {
    if (displayType === "datetime") {
        const billingList: BillingItemDisplay[] = []
        billingItems.forEach((billingItem) => 
            billingItem.earned_revenue_line_items.forEach((earnedRevenueLineItem) => {
                const newObject = {
                    ...billingItem,
                    instructionName: billingItem.name,
                    end_time_text: moment.unix(earnedRevenueLineItem.created_at).format('dddd, MMMM D, YYYY'),
                    end_time: earnedRevenueLineItem.created_at,
                    price: calculateTotalCost(billingItem),
                    type_id: (billingItem.type_id as String === "OT" || billingItem.type_id as String === "C") ? "F" : billingItem.type_id,
                    earned_revenue_line_items: [{
                        ...earnedRevenueLineItem,
                        instructionName: billingItem.name,
                        first_name: billingItem.doctor_first_name,
                        last_name: billingItem.doctor_last_name,
                        // type_id: billingItem.type_id,
                        type_id: (billingItem.type_id as String === "OT" || billingItem.type_id as String === "C") ? "F" : billingItem.type_id,
                        instruction_start_time: billingItem.start_time,
                        why: billingItem.why,
                        why_other: billingItem.why_other,
                        reason: billingItem.reason,
                        end_time_text: moment.unix(earnedRevenueLineItem.created_at).format('dddd, MMMM D, YYYY'),
                        end_time: earnedRevenueLineItem.created_at,
                    }],
                }
                billingList.push(newObject)
            }
        ));
        return billingList;
    } else {

        return billingItems.map((billingItem) => ({
            ...billingItem,
            instructionName: billingItem.name,
            end_time_text: moment.unix(billingItem.earned_revenue_line_items[0].created_at).format('dddd, MMMM D, YYYY'),
            end_time: billingItem.earned_revenue_line_items[0].created_at,
            price: calculateTotalCost(billingItem),
            type_id: (billingItem.type_id as String === "OT" || billingItem.type_id as String === "C") ? "F" : billingItem.type_id,
            earned_revenue_line_items: billingItem.earned_revenue_line_items.map((earnedRevenueItem) => ({
                ...earnedRevenueItem,
                instructionName: billingItem.name,
                first_name: billingItem.doctor_first_name,
                last_name: billingItem.doctor_last_name,
                // type_id: billingItem.type_id,
                type_id: (billingItem.type_id as String === "OT" || billingItem.type_id as String === "C") ? "F" : billingItem.type_id,
                instruction_start_time: billingItem.start_time,
                why: billingItem.why,
                why_other: billingItem.why_other,
                reason: billingItem.reason,

                end_time_text: moment.unix(earnedRevenueItem.created_at).format('dddd, MMMM D, YYYY'),
                end_time: earnedRevenueItem.created_at,
            })),
        }));
        
        


    }
};
