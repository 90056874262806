import { CheckOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Input, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyGetSupplementalByIdQuery } from 'services/supplementalService';
import { PackageFluidInstruction } from 'utils/types/InstructionOrderTypes';
import { PackageFormInstance } from '.';
import { HiddenInput } from '../fields/HiddenInput';
import EditLineButton from './EditLineButton';
import InstructionFormItem from './InstructionFormItem';
import PackageItemTitleContainer from './PackageItemTitleContainer';
import StartEndTimeFormItems from './StartEndTimeFormItems';

interface FluidsFormProps {
    fluid: PackageFluidInstruction;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    fromEstimate?: boolean;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const FluidsForm: React.FC<FluidsFormProps> = ({
    fluid,
    form,
    index,
    fromEstimate,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [trigger, { data: supplementalData }] = useLazyGetSupplementalByIdQuery();

    const [hasError, setHasError] = useState(false);
    const lineId = `F-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const fluidFields = [
        ['fluids', index, 'volume'],
        ['fluids', index, 'rate'],
        ['fluids', index, 'route_id'],
        ['fluids', index, 'end_time'],
        ['fluids', index, 'start_time'],
        ['fluids', index, 'instruction'],
    ];
    const fluidsErrorArray = form.getFieldsError(fluidFields);

    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        return (
            prev.fluids[index].checked !== curr.fluids[index].checked ||
            prev.fluids[index].start_time !== curr.fluids[index].start_time ||
            prev.fluids[index].end_time !== curr.fluids[index].end_time ||
            prev.fluids[index].rate !== curr.fluids[index].rate
        );
    };

    const setFluidsFieldValue = (value: any) => {
        const fieldsValue = form.getFieldsValue();

        if (fieldsValue.fluids) {
            form.setFieldsValue({
                ...fieldsValue,
                fluids: fieldsValue.fluids.map((fluid: any, indx: number) => {
                    return indx === index ? { ...fluid, ...value } : fluid;
                }),
            });
        }
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['fluids', index, 'volume'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (fluidsErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, fluidsErrorArray]);

    useEffect(() => {
        if (fluid.supplemental_id) {
            trigger({ supplemental_id: fluid.supplemental_id });
        }
    }, [fluid.supplemental_id]);

    useEffect(() => {
        if (supplementalData) {
            setFluidsFieldValue({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const checked = getFieldValue(['fluids', index, 'checked']) ?? (fluid.required ? fluid.required : fluid.toggled) ?? true;
                const disabledClass = !checked ? 'disabled-line' : '';
                const fluidRate = getFieldValue(['fluids', index, 'rate']);
                const initialError = !getFieldValue(['fluids', index, 'rate']) || !getFieldValue(['fluids', index, 'volume']);

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${disabledClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['fluids', index, 'id']} initialValue={fluid.fluids_id} />
                            <HiddenInput fieldName={['fluids', index, 'unit_cost_cents']} initialValue={fluid.cents} />
                            <HiddenInput fieldName={['fluids', index, 'name']} initialValue={fluid.name} />
                            <HiddenInput fieldName={['fluids', index, 'supplemental_cost_cents']} initialValue={fluid.supplemental_cents} />
                            <HiddenInput fieldName={['fluids', index, 'is_recurring_supplemental']} initialValue={false} />
                            <HiddenInput fieldName={['fluids', index, 'required']} initialValue={fluid.required} />
                            <HiddenInput fieldName={['fluids', index, 'no_charge']} initialValue={fluid.no_charge} />
                        </div>

                        <Form.Item
                            className='grid-table__sticky-column'
                            initialValue={fluid.required ? fluid.required : fluid.toggled}
                            valuePropName='checked'
                            name={['fluids', index, 'checked']}
                        >
                            {fluid.required ? (
                                <Tooltip title='Required order. Cannot be removed.'>
                                    <CheckOutlined />
                                </Tooltip>
                            ) : (
                                <Checkbox />
                            )}
                        </Form.Item>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer checked={checked} no_charge={fluid.no_charge}>
                                <Tooltip title={fluid.name.length > 45 ? fluid.name : null}>
                                    <span className='treatment-sheet-side-column__title'>{fluid.name}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>

                            {!fromEstimate && fluidRate && <div className='instructions-centered-container'>{fluidRate} mL/hr</div>}
                        </div>

                        {checked ? (
                            <>
                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Dose
                                </div>
                                <div className='fluid-dose-container'>
                                    <Form.Item
                                        name={['fluids', index, 'volume']}
                                        initialValue={fluid.fluids_volume_ml ?? fluid.default_volume_ml}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Cannot be smaller or equal to 0');
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input suffix='mL' min={0} />
                                        ) : (
                                            <span>
                                                {getFieldValue(['fluids', index, 'volume'])
                                                    ? `${getFieldValue(['fluids', index, 'volume'])}`
                                                    : '-'}{' '}
                                                mL
                                            </span>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        name={['fluids', index, 'rate']}
                                        initialValue={null}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Fluid rate must be greater than 0');
                                                    }

                                                    if (value < 0.01) {
                                                        return Promise.reject('The value cannot be smaller than 0.01');
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input suffix='mL/hr' min={0} />
                                        ) : (
                                            <span>
                                                {getFieldValue(['fluids', index, 'rate'])
                                                    ? `${getFieldValue(['fluids', index, 'rate'])}`
                                                    : '-'}{' '}
                                                mL/hr
                                            </span>
                                        )}
                                    </Form.Item>
                                </div>

                                <div>
                                    <div className='package-modal__mobile-header'>
                                        <span style={{ color: 'var(--veg-red)' }}>* </span>
                                        Route
                                    </div>
                                    <Form.Item name={['fluids', index, 'route_id']} initialValue='IV'>
                                        {isEditing ? (
                                            <Select
                                                options={[
                                                    {
                                                        value: 'IV',
                                                        label: 'IV',
                                                    },
                                                    {
                                                        value: 'IO',
                                                        label: 'IO',
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <span>{getFieldValue(['fluids', index, 'route_id'])}</span>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className='empty-element' />

                                {!fromEstimate && (
                                    <>
                                        <StartEndTimeFormItems
                                            endTimeFormFieldName={['fluids', index, 'end_time']}
                                            endTimeFormFieldValue={getFieldValue(['fluids', index, 'end_time'])}
                                            isEditing={isEditing}
                                            startTimeFormFieldName={['fluids', index, 'start_time']}
                                            startTimeFormFieldValue={getFieldValue(['fluids', index, 'start_time'])}
                                        />

                                        <InstructionFormItem
                                            formFieldName={['fluids', index, 'instruction']}
                                            initialValue={null}
                                            isEditing={isEditing}
                                            formFieldValue={getFieldValue(['fluids', index, 'instruction'])}
                                        />
                                    </>
                                )}

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />
                            </>
                        ) : (
                            <div className='disabled-line__form-inputs' />
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default FluidsForm;
