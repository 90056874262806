import { ExistingTaskInstruction } from 'utils/dataTypes';
import OrderFrequency from '../OrderFrequency';
import './TaskData.css';

interface ITaskDataProps {
	group: ExistingTaskInstruction;
	instructionIcon: JSX.Element;
}

const TaskData = ({ group, instructionIcon }: ITaskDataProps) => {

	return (
		<div className='task-data'>
			{instructionIcon}
			<OrderFrequency>{group.frequency}</OrderFrequency>
		</div>
	);
};

export default TaskData;
