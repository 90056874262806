import { PatientAlertTag } from "components/tags/PatientAlertTag";
import { PET_SEX } from "utils/constants";
import { ExpandedPatient, PatientAlert } from "utils/dataTypes";
import { formatPatientAge } from "utils/formatFuncs";
import { getIntactSpeciesBreedString } from "utils/miscFuncs";

interface PatientSignalmentProps {
	patientData: ExpandedPatient;
    patientWeight: number | undefined;
    patientAlerts: PatientAlert[] | undefined;
}

export const PatientSignalment = ({ patientData, patientWeight, patientAlerts }: PatientSignalmentProps) => {
	return (
        <span style={{color: "var(--gray-8)"}} data-cy="patientSignalmentData">

            {!!patientData?.birthday &&
                <span data-cy="patientBirthday">
                    {formatPatientAge(patientData?.birthday, patientData?.deceased_at)}
                    <span style={{color: "var(--gray-6)"}}> | </span>
                </span>
            }
            {patientWeight &&
                <>
                    <span data-cy="patientWeight">{patientWeight}kg</span>
                    <span style={{color: "var(--gray-6)"}}> | </span>
                </>
            }

            {patientData?.sex &&
                <>
                    <span data-cy="patientSex">
                        {patientData.sex
                            ? PET_SEX[patientData.sex]
                            : ''}
                    </span>
                    <span style={{color: "var(--gray-6)"}}> | </span>
                </>
            }

            {getIntactSpeciesBreedString(patientData)}

            {(patientData?.is_intact !== null || patientData?.species || patientData?.breed) &&
                <span style={{color: "var(--gray-6)"}}> | </span>
            }
            {!!patientAlerts?.length ?
                patientAlerts.map((alert) => {
                    return <PatientAlertTag alert={alert} direction='horizontal' />
                })
                : <span style={{color: 'var(--gray-8)', marginLeft: '4px'}}>No Alerts</span>
            }
        </span>
	);
};
