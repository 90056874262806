export type PaymentType = 
    | "stripe_terminal"
    | "stripe_link"
    | "cash"
    | "scratch_pay"
    | "care_credit"
    | "veg_cares"
	| "check"
	| "allegro";

export enum PAYMENT_TYPE_NAME {
    "stripe_terminal" = "Stripe Terminal",
    "stripe_link" = "Stripe Link",
    "cash" = "Cash",
    "scratch_pay" = "Scratch Pay",
    "care_credit" = "Care Credit",
    "veg_cares" = "VEG Cares",
    "check" = "Check",
    "allegro" = "Allegro",
}

export enum PAYMENT_MODAL_TITLE {
	"stripe_terminal" = "Send to Credit Card Terminal",
	"stripe_link" = "Send Pay Link",
	"cash" = "Cash Payment",
	"scratch_pay" = "Scratchpay Payment",
	"care_credit" = "CareCredit Payment",
	"veg_cares" = "VEG Cares Payment",
	"check" = "Check Payment",
	"allegro" = "Allegro Payment",
}

export type WEB_CHECKOUT_STEP = 
	"init"
	| "donation"
	| "payment"
	| "payment_form_ready"
	| "completed";

export interface WebCheckout {
	form_link_id: string;
	action: string;
	visit_id: number;
	amount_cents: number;
	donation_cents: number;
	owner_first_name: string | null;
	owner_last_name: string | null;
	pet_deceased_at: number | null;
    owner_id: number | null;
    opt_in_marketing_email: boolean | null;
    has_approved_cremation_or_euthanasia: boolean;
}

export interface WebCheckoutIntent {
	amount_cents: number;
	client_secret: string;
	stripe_public_key: string;
}
