import { useQuery } from 'hooks';
import { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { JSONifyQueryString } from 'utils/formatFuncs';
import { ROUTES } from 'utils/types/enums';
import Loading from './loading/Loading';

interface UnprotectedRouteProps {
	component: React.ReactNode;
	isUserLoggedIn: boolean;
	isUserFetching: boolean
	path: string;
	exact?: boolean;
}

// Redirect a user to dashboard or redirect url if they are
// authenticated and are trying to login
const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({
	component: Component,
	isUserLoggedIn,
	isUserFetching,
	...rest
}: any) => {
	const history = useHistory();
	let { search: redirectSearch } = useLocation();
	const searchParams = useQuery();

	useEffect(() => {
		if (isUserLoggedIn) {
			let queries =
				JSONifyQueryString<{ redirect: string }>(redirectSearch);
			if (queries.redirect) {
				searchParams.delete('redirect');
				const search = searchParams.toString();
				history.push({ pathname: queries.redirect, search });
			} else {
				history.push(ROUTES.dashboard);
			}
		}
	}, [isUserLoggedIn]);

	return (
		<Route
			{...rest}
			render={(props) => {
				return !isUserFetching ?
					<Component {...props} />
					: <Loading />;
			}}
		/>
	);
};

export default UnprotectedRoute;
