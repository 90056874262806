import { AutoComplete, FormInstance } from 'antd';
import { CSSProperties, ElementRef, useCallback, useMemo, useRef, useState } from 'react';
import { FormName } from 'utils/constants';
import { DiagnosticOrder } from '../../components/forms/DiagnosticOrder';
import { NonMedicalOrder } from '../../components/forms/NonMedicalOrder';
import {
	PATIENT_WEIGHT_INSTRUCTION_NAME
} from '../../utils/constants';
import {
	VisitVital,
} from '../../utils/dataTypes';

import { RefSelectProps } from 'antd/lib/select';
import IdexxReferenceLabsModal from 'components/IdexxReferenceLabsModal';
import { FluidOrder } from 'components/forms/FluidOrder';
import { MedCriOrder } from 'components/forms/MedCriOrder';
import OxygenTherapyOrder from 'components/forms/OxygenTherapyOrder';
import PackageFormModal from 'components/forms/PackageFormModal';
import { TaskOrder } from 'components/forms/TaskOrder';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { useParams } from 'react-router-dom';
import { useGetVisitByIdQuery } from 'services/visitService';
import { closedErrorMsg } from '../../utils/disableFuncs';
import {
	MedicineSearchOption,
	ORDER_SEARCH_OPTION_TYPE,
	isInstanceOfDiagnosticOption,
	isInstanceOfFluidOption,
	isInstanceOfMedicineSearchOption,
	isInstanceOfNonMedicalSearchOption,
	isInstanceOfPackageSearchOption,
	isInstanceOfTaskOption,
} from '../../utils/types/InstructionOrderTypes';
import { showNoChargeModal } from './DischargeTab/utils';

export interface OrderSearchProps {
	handleSelect?: Function; //Function to call when item is selected. Optional because it is not backward compat.
	setModalstate: Function;
	modalForm: FormInstance<any>;
	vitals: VisitVital[];
	searchOptions: ORDER_SEARCH_OPTION_TYPE;

	hideRefLabs?: boolean;
	placeholder?: string
	style?: CSSProperties
	hideToGoMeds?: boolean;
	updateIsInvoiceLocked?: () => boolean;
}

export const OrderSearch = (props: OrderSearchProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isIdexxModalOpen, setIsIdexxModalOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const {addComposeBox} = useComposeBoxContext();
	const packageFormModalRef = useRef<ElementRef<typeof PackageFormModal>>(null);

	const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

	const autoCompleteRef = useRef<RefSelectProps>(null);

	const { data: currentVisit } = useGetVisitByIdQuery(visitId);
	const isVisitFinalized = Boolean(currentVisit?.finalized_at);
	const FAKE_IDEXX_REF_LAB_ID = 999999;

	const patientWeight =
		parseFloat((props?.vitals?.find((vital) => vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME)?.lastAction?.value as string) ?? '0') ||
		undefined;

	const checkMedication = useCallback(
		(med: MedicineSearchOption) => {
			if (props.hideToGoMeds && med.calculator_type === 'prepackaged') return;

			return (
				med.name.toUpperCase().includes(inputValue.toUpperCase()) ||
				med.brand_name?.toUpperCase().includes(inputValue.toUpperCase()) ||
				med.generic_name?.toUpperCase().includes(inputValue.toUpperCase())
			);
		},
		[props.hideToGoMeds, inputValue],
	);

	const getOptionCategoryTree = useCallback(
		(
			medicationOptions,
            nonMedicalOption,
            diagnosticOptions,
            taskOptions,
            packageOptions,
            fluidOptions,
            oxygenTherapyOptions,
            inputValue,
		) => {
			const optionCategoryTree = [];

			if (!(props.hideRefLabs ?? false) && "Idexx Reference Lab".toUpperCase().includes(inputValue.toUpperCase())) {
				optionCategoryTree.push({
					label: 'External Labs',
					options: [{
						value: `D_${FAKE_IDEXX_REF_LAB_ID}`,
						label: 'Idexx Reference Lab',
					}],
				});
			}

			if (packageOptions.length) {
				optionCategoryTree.push({
					label: 'Packages',
					options: packageOptions,
				});
			}
			if (medicationOptions.length) {
				optionCategoryTree.push({
					label: 'Medications',
					options: medicationOptions,
				});
			}
			if (diagnosticOptions.length) {
				optionCategoryTree.push({
					label: 'Diagnostics',
					options: diagnosticOptions,
				});
			}

			if (taskOptions.length) {
				optionCategoryTree.push({
					label: 'Tasks',
					options: taskOptions,
				});
			}
			if (fluidOptions.length) {
				optionCategoryTree.push({
					label: 'Fluids',
					options: fluidOptions,
				});
			}
			if (oxygenTherapyOptions.length) {
				optionCategoryTree.push({
					label: 'Oxygen Therapy',
					options: oxygenTherapyOptions,
				});
			}
			if (nonMedicalOption.length) {
				optionCategoryTree.push({
					label: 'Non-Medical',
					options: nonMedicalOption,
				});
			}

			return optionCategoryTree;
		},
		[],
	);

	const orderOptions = useMemo(() => {
		const medicationOptions: any = [];
		const nonMedicalOption: any = [];
		const diagnosticOptions: any = [];
		const taskOptions: any = [];
		const packageOptions: any = [];
		const fluidOptions: any = [];
		const oxygenTherapyOptions: any = [];

		props.searchOptions.filter(option => !option.hide_item).forEach((option) => {
			const optionObj = {
				value: option.type_id + '_' + option.id,
				label: option.name,
			};

			if (isInstanceOfMedicineSearchOption(option) && checkMedication(option as MedicineSearchOption)) {
				medicationOptions.push(optionObj);
			}

			if (!option.name.toUpperCase().includes(inputValue.toUpperCase())) return;

			if (isInstanceOfNonMedicalSearchOption(option)) {
				nonMedicalOption.push(optionObj);
			} else if (isInstanceOfDiagnosticOption(option)) {
				diagnosticOptions.push(optionObj);
			} else if (isInstanceOfTaskOption(option)) {
				taskOptions.push(optionObj);
			} else if (isInstanceOfPackageSearchOption(option)) {
				packageOptions.push(optionObj);
			} else if (isInstanceOfFluidOption(option)) {
				if (option.name.includes('Oxygen')) {
					oxygenTherapyOptions.push(optionObj);
				} else {
					fluidOptions.push(optionObj);
				}
			}
		});

		return getOptionCategoryTree(medicationOptions, nonMedicalOption, diagnosticOptions, taskOptions, packageOptions, fluidOptions, oxygenTherapyOptions, inputValue);
	}, [inputValue, props.searchOptions, getOptionCategoryTree, checkMedication]);

	return (
		<>
			<AutoComplete
				value={inputValue}
				data-cy="placeOrderInput"
				onFocus={() => isVisitFinalized ? closedErrorMsg(isVisitFinalized) : setIsOpen(true)}
				onBlur={() => setIsOpen(false)}
				open={isOpen}
				ref={autoCompleteRef}
				dropdownMatchSelectWidth={400}
				onChange={setInputValue}
				getPopupContainer={(trigger) => trigger.parentElement}
				onSelect={(selectedItem) => {
					//DEF TODO - refactor this a bit so we dont end up with 1000 if statements
					const splitValue = selectedItem.split('_');
					const type_id = splitValue[0];
					const item_id = splitValue[1];

					if (parseInt(item_id) === FAKE_IDEXX_REF_LAB_ID) {
						autoCompleteRef.current?.blur();
						setInputValue('');
						setIsIdexxModalOpen(true);
						return;
					}

					const fullSelectedItem = props.searchOptions.find(
						(option) =>
							option.id === parseInt(item_id) &&
							option.type_id === type_id,
					);

					//TODO `handleSelect` is used in a few places -- `DischargeOutgoingMedications` and `CreateEstimate`, to name a few
					//May want to abstract out this OrderSearch component a bit
					if (props.handleSelect && fullSelectedItem) {
						props.handleSelect(fullSelectedItem);
					} else {
						if (isInstanceOfMedicineSearchOption(fullSelectedItem)) {
							addComposeBox({
								formName: FormName['med_order'],
								content: (
									<MedCriOrder
										patientWeight={patientWeight}
										options={fullSelectedItem}
									/>
								),
							});
						} else if (isInstanceOfDiagnosticOption(fullSelectedItem)) {
							addComposeBox({
								formName: FormName['diag_order'],
								content: <DiagnosticOrder options={fullSelectedItem} />,
							});
						} else if (isInstanceOfTaskOption(fullSelectedItem)) {
							addComposeBox({
								formName: FormName['task_order'],
								content: <TaskOrder options={fullSelectedItem} />,
							});
						} else if (isInstanceOfFluidOption(fullSelectedItem)) {
							if (fullSelectedItem.name.includes('Oxygen')) {
								addComposeBox({
									formName: FormName['oxygen_therapy_order'],
									content: <OxygenTherapyOrder options={fullSelectedItem} />,
								});
							} else {
								addComposeBox({
									formName: FormName['fluid_order'],
									content: <FluidOrder options={fullSelectedItem} form={props.modalForm} />,
								});
							}
						} else if(isInstanceOfNonMedicalSearchOption(fullSelectedItem)) {
							if (props.updateIsInvoiceLocked) {
								const isInvoiceLocked = props.updateIsInvoiceLocked();
								if (isInvoiceLocked) {
									showNoChargeModal(() => {
										addComposeBox({
											formName: FormName['non_medical_order'],
											content: <NonMedicalOrder options={fullSelectedItem} skipModal />,
										});
									});
									setInputValue('');
									return;
								}
							}
							addComposeBox({
								formName: FormName['non_medical_order'],
								content: <NonMedicalOrder options={fullSelectedItem} />,
							});
						} else if (type_id === 'P') {
							autoCompleteRef.current?.blur();
							packageFormModalRef.current?.openModal(parseInt(item_id));
						}
					}

					setInputValue('');
				}}
				options={orderOptions}
				placeholder={props.placeholder ?? 'Place an order...'}
				style={props.style ?? { width: '200px' }}
			/>

			<PackageFormModal ref={packageFormModalRef} />
			<IdexxReferenceLabsModal open={isIdexxModalOpen} setOpen={setIsIdexxModalOpen} handleSelect={props.handleSelect} />
		</>
	);
};
