import { EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Collapse,
    CollapsePanelProps,
    Divider,
    Drawer,
    Dropdown,
    Form,
    Menu,
    Row,
    Skeleton,
    Tabs,
    Tag,
    Tooltip,
    message,
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { useDeleteMyMacroMutation, useLazyGetMyMacrosQuery } from 'services/macroService';
import { drawerSize } from 'styles/drawer';
import { Macro } from 'utils/dataTypes';
import useWindowDimensions from 'utils/window';
import { MacroDetailsDrawer } from './MacroDetailsDrawer';
import './index.css';

const macroKey = (macro: Macro) => (macro.is_veg ? macro.id : `${macro.id}_${macro.created_by}`);

interface MacrosDrawerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MacrosDrawer = ({ open, setOpen }: MacrosDrawerProps) => {
    const { width } = useWindowDimensions();

    const [isMacroDetailsOpen, setIsMacroDetailsOpen] = useState(false);
    const [openedMacro, setOpenedMacro] = useState<Macro | undefined>(undefined);
    const [macroForm] = Form.useForm();

    const onSelectMacro = (macro: Macro | undefined) => {
        !!macro
            ? macroForm.setFieldsValue({
                  label: macro.label,
                  key_word: macro.key_word,
                  category: macro.category,
                  content: macro.content,
              })
            : macroForm.resetFields();
        setOpenedMacro(macro);
        setIsMacroDetailsOpen(true);
    };

    const [getMyMacros, { data: macros, isLoading: isGetMacros }] = useLazyGetMyMacrosQuery();
    const [macroCategories, setMacroCategories] = useState<string[]>([]);
    const [deleteMyMacro] = useDeleteMyMacroMutation();

    useEffect(() => {
        if (open) {
            getMyMacros();
        }
    }, [open]);

    useEffect(() => {
        if (macros?.length && macros.length > 0) {
            // extract categories from the macros list
            let categories = [...new Set(macros.map((macro) => macro.category))];
            // sort them alphabetically
            categories = categories.sort((a, b) => (a < b ? -1 : 1));
            setMacroCategories(categories);
        }
    }, [macros]);

    const deleteMyMacroConfirm = (macro: Macro | undefined) => {
        !!macro
            ? confirm({
                  icon: <ExclamationCircleOutlined style={{ color: 'var(--veg-red)' }} />,
                  okType: 'danger',
                  title: 'Delete my macro',
                  content: (
                      <p>
                          Are you sure you want to delete your macro <b>{macro.label}</b>?
                      </p>
                  ),
                  okButtonProps: {
                      type: 'primary',
                      style: { backgroundColor: 'var(--veg-red)' },
                  },
                  onOk() {
                      return deleteMyMacro({ macroId: macro.id })
                          .unwrap()
                          .then(() => {
                              setIsMacroDetailsOpen(false);
                              message.success('Successfully deleted macro');
                          })
                          .catch((err: string) => message.error(err));
                  },
              })
            : setIsMacroDetailsOpen(false);
    };

    return (
        <>
            <Drawer
                title='Macros'
                className='veg-drawer macros-drawer'
                placement='right'
                closable={true}
                width={width >= 800 ? drawerSize.extended : drawerSize.full}
                onClose={() => setOpen(false)}
                visible={open}
                key='macros_drawer'
                destroyOnClose={true}
                mask={true}
                maskClosable={true}
            >
                <Tabs defaultActiveKey='my'>
                    <Tabs.TabPane tab='My Macros' key='my'>
                        <Button
                            icon={<PlusOutlined />}
                            style={{ marginBottom: '8px' }}
                            onClick={(e) => {
                                onSelectMacro(undefined);
                                e.stopPropagation();
                            }}
                        >
                            New Macro
                        </Button>
                        <Skeleton active loading={isGetMacros}>
                            {macroCategories.length > 0 && (
                                <Collapse
                                    ghost
                                    bordered={false}
                                    className='veg-drawer-collapse'
                                    defaultActiveKey={macroCategories.map((category) => `panel_${category}`)}
                                >
                                    {macroCategories.map((category) => (
                                        <MacrosCategoryPanel
                                            key={`panel_${category}`}
                                            header={<Divider orientation='left'>{category}</Divider>}
                                            macros={macros}
                                            category={category}
                                            onSelectMacro={onSelectMacro}
                                            deleteMyMacroConfirm={deleteMyMacroConfirm}
                                        />
                                    ))}
                                </Collapse>
                            )}
                        </Skeleton>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <Tooltip title='Coming soon'>
                                <span>Community Macros</span>
                            </Tooltip>
                        }
                        key='community'
                        disabled
                    ></Tabs.TabPane>
                </Tabs>
            </Drawer>

            <MacroDetailsDrawer
                macro={openedMacro}
                existingMacros={macros}
                form={macroForm}
                open={isMacroDetailsOpen}
                setOpen={setIsMacroDetailsOpen}
                deleteConfirm={deleteMyMacroConfirm}
            />
        </>
    );
};

interface MacrosCategoryPanelProps extends CollapsePanelProps {
    macros: Macro[] | undefined;
    category: string;
    onSelectMacro: (macro: Macro | undefined) => void;
    deleteMyMacroConfirm: (macro: Macro | undefined) => void;
}

const MacrosCategoryPanel = (props: MacrosCategoryPanelProps) => {
    const { Panel } = Collapse;

    return (
        <Panel className='veg-drawer-panel' {...props}>
            <Row gutter={[8, 8]}>
                {!!props.macros &&
                    props.macros
                        .filter((macro) => macro.category === props.category)
                        .sort((a, b) => (a.key_word < b.key_word ? -1 : 1))
                        .map((macro) => (
                            <Col key={macroKey(macro)} lg={8} md={12} sm={24}>
                                <Card
                                    className='macro-card'
                                    key={macroKey(macro)}
                                    title={macro.label}
                                    size='small'
                                    extra={
                                        <Dropdown
                                            overlay={
                                                <Menu
                                                    style={{ minWidth: '130px' }}
                                                    onClick={(e) => {
                                                        e.domEvent.stopPropagation();
                                                    }}
                                                >
                                                    <Menu.Item
                                                        key='view-macro'
                                                        onClick={(e) => {
                                                            props.onSelectMacro(macro);
                                                            e.domEvent.stopPropagation();
                                                        }}
                                                    >
                                                        View
                                                    </Menu.Item>
                                                    <Menu.Divider style={{ margin: 0 }} />
                                                    <Menu.Item
                                                        key='delete-macro'
                                                        onClick={() => props.deleteMyMacroConfirm(macro)}
                                                        style={{ color: 'var(--red-5)' }}
                                                    >
                                                        Delete
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            placement='bottomRight'
                                            arrow
                                        >
                                            <Button
                                                type='text'
                                                style={{ padding: '0 var(--spacing-sm)' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <EllipsisOutlined />
                                            </Button>
                                        </Dropdown>
                                    }
                                    onClick={() => {
                                        props.onSelectMacro(macro);
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='veg-body-3' style={{ color: 'var(--veg-secondary-plum)' }}>
                                            \{macro.key_word}
                                        </div>
                                        {macro.is_veg && (
                                            <Tag
                                                color='geekblue'
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                    marginRight: 0,
                                                    maxHeight: '22px',
                                                }}
                                            >
                                                VEG
                                            </Tag>
                                        )}
                                    </div>
                                    <div className='veg-subcaption-1'>
                                        <Markdown remarkPlugins={[remarkBreaks]} children={macro.content} />
                                    </div>
                                </Card>
                            </Col>
                        ))}
            </Row>
        </Panel>
    );
};
