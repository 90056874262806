import { DeleteOutlined, DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Tooltip, Typography, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetDiscountsQuery, useSetVisitDiscountsMutation } from 'services/billingService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { BASE_QUERY_OPTIONS, SUPPORTED_PAYMENT_AMOUNT_REGEX } from 'utils/constants';
import { formatCurrency, roundTo } from 'utils/formatFuncs';
import { formatBasisAsPercentage, getLedgerValues } from 'utils/miscFuncs';
import { DiscountItem, VisitDiscount } from 'utils/types/billingTypes';
import './Ledger.css';

interface LedgerProps {
    // number of cents
    subtotal: number;
    paid: number;
    tax: number;
    visitDiscount?: VisitDiscount;
    type: 'billing' | 'estimate';
    visitId: number;
    hideTax?: boolean;
}

export const Ledger = React.memo(({ subtotal, paid, tax, visitDiscount, type, visitId, hideTax }: LedgerProps) => {
    const basis = visitDiscount?.basis ?? 0;
    const { due, total, discountAmount } = getLedgerValues(subtotal, paid, tax, basis, visitDiscount?.cap_amount_cents);
    const { data: discountItems } = useGetDiscountsQuery(null, BASE_QUERY_OPTIONS);

    const { data: currentVisit } = useGetVisitByIdQuery(visitId, BASE_QUERY_OPTIONS);

    return (
        <Space direction='vertical' className='ledger' size={0}>
            <Row className='ledger-box'>
                <Col span={12}>
                    <Typography.Text strong>SUBTOTAL</Typography.Text>
                </Col>
                <Col span={12}>
                    <Typography.Text>{formatCurrency(subtotal)}</Typography.Text>
                </Col>
            </Row>
            <DiscountSection
                discountAmount={discountAmount}
                visitDiscount={visitDiscount}
                discountItems={discountItems ?? []}
                visitId={visitId}
            />
            {!hideTax && (
                <Row className='ledger-box'>
                    <Col span={12}>
                        <Typography.Text strong>TAX</Typography.Text>
                    </Col>
                    <Col span={12}>
                        <Typography.Text>{formatCurrency(tax)}</Typography.Text>
                    </Col>
                </Row>
            )}
            <Row className={'ledger-box-bottom'}>
                <Col span={12}>
                    <Typography.Title level={5}>TOTAL</Typography.Title>
                </Col>
                <Col span={12}>
                    <Typography.Title level={5}>{formatCurrency(total)}</Typography.Title>
                </Col>
            </Row>
            {visitDiscount && visitDiscount?.basis > 0 && type === 'estimate' && (
                <Alert message={'Discount applies to ALL visit charges.'} style={{ marginTop: '12px' }} showIcon type='warning' />
            )}
        </Space>
    );
});
interface DiscountSectionProps {
    discountAmount: number;
    discountItems: DiscountItem[];
    visitDiscount?: VisitDiscount;
    visitId: number;
}

export const DiscountSection = React.memo(
    ({ discountAmount, visitDiscount, discountItems, visitId }: DiscountSectionProps) => {
        const [isDiscountOpen, setIsDiscountOpen] = useState(false);
        const [showInvoiceCapInput, setShowInvoiceCapInput] = useState(!!visitDiscount?.cap_amount_cents && isDiscountOpen);
        const [capAmount, setCapAmount] = useState<number | null>();

        const [setVisitDiscount, { isLoading: loadingDiscount }] = useSetVisitDiscountsMutation();

        let isFirstNonOtherAdded = false;
        let isFirstOtherAdded = false;
        let isFirstInvoiceCapAdded = false;

        const discountOptions = [...discountItems]
            ?.filter((discountItem) => discountItem.discontinued_at === null || discountItem.id === visitDiscount?.discount_id)
            ?.sort((itemA, itemB) => {
                // keep 'No Discount' at the beginning
                if (itemA.basis === 0) return -1;
                if (itemB.basis === 0) return 1;
        
                // separate 'Invoice Cap'
                const isInvoiceCapA = itemA.basis > 10000;
                const isInvoiceCapB = itemB.basis > 10000;
                if (isInvoiceCapA) return 1;
                if (isInvoiceCapB) return -1;
        
                // order first the ones that don't start with 'Other'
                const isOtherA = itemA.name.startsWith('Other');
                const isOtherB = itemB.name.startsWith('Other');
                if (!isOtherA && isOtherB) return -1;
                if (isOtherA && !isOtherB) return 1;
        
                // then by 'basis'
                if (itemA.basis !== itemB.basis) {
                    return itemA.basis - itemB.basis;
                }
        
                // if 'basis' is the same, sort alphabetically for items not starting with 'Other'
                if (!isOtherA && !isOtherB) {
                    return itemA.name.localeCompare(itemB.name);
                }
        
                // keep existing order if any of them starts with 'Other'
                return 0;
            })
            ?.flatMap((discountItem, index) => {
                const items = [];
                // insert a divider before 'Invoice Cap'
                // negative values are just to avoid changing the interface
                if (!isFirstInvoiceCapAdded && discountItem.basis > 10000) {
                    isFirstInvoiceCapAdded = true;
                    items.push({
                        label: <Divider />,
                        value: -3,
                        className: 'divider-item',
                    });
                }

                // handling non-'Other' and 'Other' options divider
                if (!discountItem.name.startsWith('Other') && discountItem.basis !== 0 && !isFirstNonOtherAdded) {
                    isFirstNonOtherAdded = true;
                    items.push({
                        label: <Divider />,
                        value: -1,
                        className: 'divider-item',
                    });
                } else if (discountItem.name.startsWith('Other') && !isFirstOtherAdded) {
                    isFirstOtherAdded = true;
                    items.push({
                        label: <Divider />,
                        value: -2,
                        className: 'divider-item',
                    });
                }

                let label;
                if (discountItem.basis === 0) {
                    label = 'No Discount';
                } else if (discountItem.basis > 10000) {
                    label = 'Invoice Cap';
                } else {
                    if (discountItem.name.includes('Other')) {
                        label = `Other - ${formatBasisAsPercentage(discountItem.basis)}`
                    } else {
                        label = `${discountItem.name} - ${formatBasisAsPercentage(discountItem.basis)}`;
                    }
                }

                items.push({
                    label,
                    value: discountItem.id,
                    key: discountItem.id,
                    className: 'discount-item',
                });

                return items;
            });

        const invoiceCapOption = useMemo(() => {
            return discountOptions.find((option) => option.label === 'Invoice Cap');
        }, [discountOptions]);
        const noDiscountOption = useMemo(
            () => discountOptions.find((discountOption) => discountOption?.label === 'No Discount'),
            [discountOptions],
        );

        const getDiscountText = () => {
            if (visitDiscount?.cap_amount_cents !== null && visitDiscount?.cap_amount_cents !== undefined) {
                return `Invoice Cap: ${formatCurrency(visitDiscount.cap_amount_cents)}`;
            }

            if (visitDiscount?.discount_id) {
                const selectedDiscountOption = discountOptions.find(
                    (discountOption) => discountOption?.value === visitDiscount?.discount_id,
                );
                return selectedDiscountOption?.label;
            }
            return 'No Discount';
        };

        const getSelectValue = () => {
            if (visitDiscount?.discount_id !== invoiceCapOption?.value && showInvoiceCapInput) {
                return invoiceCapOption?.value;
            }

            return visitDiscount?.discount_id ?? noDiscountOption?.value;
        };

        const isButtonDisabled = useMemo(() => {
            if (visitDiscount?.cap_amount_cents) {
                return capAmount === roundTo(visitDiscount?.cap_amount_cents / 100, 2);
            }

            return capAmount === undefined || loadingDiscount;
        }, [capAmount, visitDiscount, loadingDiscount]);

        useEffect(() => {
            if (visitDiscount?.cap_amount_cents !== null && visitDiscount?.cap_amount_cents !== undefined) {
                setCapAmount(roundTo(visitDiscount.cap_amount_cents / 100, 2));
            }
        }, [visitDiscount]);

        return (
            <Row className={`ledger-box ${isDiscountOpen ? 'ledger-box-open' : ''}`}>
                <Col span={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip title='Discount can either be a percentage or an invoice cap. If applying an invoice cap, enter the maximum amount the customer is expected to pay.'>
                        <InfoCircleOutlined />
                    </Tooltip>
                    <Button
                        style={{ padding: 0 }}
                        icon={!isDiscountOpen ? <DownOutlined /> : <UpOutlined />}
                        type='text'
                        size='small'
                        onClick={() => (setIsDiscountOpen((discountOpen) => !discountOpen))}
                    >
                        <Typography.Text strong>DISCOUNT</Typography.Text>
                    </Button>
                </Col>
                <Col span={12}>
                    <Typography.Text>
                        {discountAmount !== 0 && '-'} {formatCurrency(discountAmount)}
                    </Typography.Text>
                </Col>

                {(() => {
                    if (visitDiscount?.cap_amount_cents !== null && visitDiscount?.cap_amount_cents !== undefined && isDiscountOpen) {
                        return (
                            <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <Col span={10} style={{ textAlign: 'left' }}>
                                    Invoice Cap: {formatCurrency(visitDiscount?.cap_amount_cents_target ?? visitDiscount?.cap_amount_cents)}
                                </Col>
                                <Col span={2}>
                                    <Button
                                        className='delete-invoice-cap-button'
                                        disabled={loadingDiscount}
                                        onClick={() => {
                                            setVisitDiscount({
                                                visitId,
                                                body: { discount_id: noDiscountOption?.value ?? 1 },
                                            })
                                                .unwrap()
                                                .then(() => {
                                                    message.success('Invoice cap successfully removed');
                                                    setCapAmount(undefined);
                                                });
                                        }}
                                    >
                                        <DeleteOutlined style={{fontSize: '16px'}}/> 
                                    </Button>
                                </Col>
                                
                            </Row>
                                
                        );
                    } else {  
                        return (
                            <>
                                {isDiscountOpen && (
                                    <Col span={12}>
                                        <Select<number>
                                            showSearch
                                            onSelect={(value) => {
                                                if (invoiceCapOption?.value !== value) {
                                                    setVisitDiscount({ visitId, body: { discount_id: value } });
                                                    setShowInvoiceCapInput(false);
                                                    return;
                                                }
                                                setShowInvoiceCapInput(true);
                                            }}
                                            value={getSelectValue()}
                                            style={{ width: '100%' }}
                                            dropdownMatchSelectWidth={280}
                                            options={discountOptions}
                                            filterOption={(input, option) =>
                                                option?.label?.toString()?.toUpperCase().indexOf(input.toUpperCase()) !== -1
                                            }
                                            dropdownStyle={{ paddingBottom: 0 }}
                                        />
                                    </Col>
                                )}

                                {isDiscountOpen && showInvoiceCapInput && (
                                    <Row style={{ width: '100%', marginTop: 'var(--spacing-sm)' }}>
                                        <Col span={12}>
                                            <Input.Group compact>
                                                <Form style={{ display: 'inline' }}>
                                                    <Form.Item
                                                        name='invoice_cap_amount'
                                                        style={{
                                                            display: 'inline-block',
                                                            width: `calc(100% - 68px)`,
                                                            margin: '0 2px 0 0',
                                                            padding: 0,
                                                        }}
                                                        rules={[{ pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: 'Invalid amount' }]}
                                                    >
                                                        <InputNumber
                                                            formatter={(value) =>
                                                                !value ? '' : `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                            }
                                                            parser={(value) => parseFloat(value!.replace(/\$\s?|(,*)/g, ''))}
                                                            placeholder='$0.00'
                                                            value={capAmount}
                                                            style={{ width: `auto` }}
                                                            onChange={(value) => {
                                                                if (value && value.toString().match(SUPPORTED_PAYMENT_AMOUNT_REGEX)) {
                                                                    setCapAmount(value);
                                                                } else {
                                                                    setCapAmount(undefined);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Form>
                                                <Button
                                                    type='primary'
                                                    disabled={isButtonDisabled}
                                                    onClick={() => {
                                                        setVisitDiscount({
                                                            visitId,
                                                            body: {
                                                                discount_id: invoiceCapOption?.value ?? 1,
                                                                cap_amount_cents: Math.round(capAmount! * 100),
                                                            },
                                                        })
                                                            .unwrap()
                                                            .then(() => message.success('Invoice cap successfully set'));
                                                    }}
                                                >
                                                    Apply
                                                </Button>
                                            </Input.Group>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        );
                    }
                })()}
            </Row>
        );
    },
);
export default Ledger;
