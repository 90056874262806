import { updateEstimateType } from 'components/forms/fields/estimates/UpdateEstimateDropdown';
import { isDiscontinuedOrOver } from 'utils/filterFuncs';
import { roundTo } from 'utils/formatFuncs';
import {
    EstimateFormFields,
    EstimateItem,
    EstimateItemWithOutNonMedical,
    NonMedicalEstimateItem,
    isInstanceOfCriEstimateItem,
    isInstanceOfFluidEstimateItem,
    isInstanceOfMedicalEstimateItem,
    isInstanceOfNonMedicalEstimateFormFields,
    isInstanceOfNonMedicalEstimateItem,
} from 'utils/types/billingTypes';
import './estimate-overrides.css';

const calculateUnitCosts = (row: EstimateItemWithOutNonMedical | EstimateFormFields | NonMedicalEstimateItem | EstimateItemWithOutNonMedical, quantity: number): number => {
    let unitCost: number = row.unit_cost_cents ?? 0;
    if (isInstanceOfNonMedicalEstimateFormFields(row)|| isInstanceOfNonMedicalEstimateItem(row)){
        return unitCost;
    }
    if (row.supplemental_cost_cents){
        if (row.is_recurring_supplemental){
            if ((row.type_id === "M") && row.dose){
                // find the unit cost per dose of medication
                const supplementalCostPerMedicationDose = roundTo(row.supplemental_cost_cents / row.dose, 2)
                unitCost += supplementalCostPerMedicationDose;
            } else {
                // if recurring and not medication add to all
                unitCost += row.supplemental_cost_cents;
            }
        } else if (quantity > 0) {
            // if not recurring we assume one actions and add it to the unit by dividing by the quantity
            // if quantity is zero then we assume no supplemental add;
            const supplementalCostPerQuantity = roundTo(row.supplemental_cost_cents / quantity, 2)
            unitCost += supplementalCostPerQuantity;

        }
    }
    return unitCost;
}

export const calculateEstimateNumbers = (est: EstimateItem): {
    approvedIncomplete: number;
    pendingIncomplete: number;
    pendingComplete: number;
    completeApproved: number;
} => {
    if (isInstanceOfNonMedicalEstimateItem(est)){
        // The Non Medical Items don't do any subtraction on the backend so we need to calculate the values differently
        const {completed_quantity, approved_quantity, prior_quantity} = est;
        const priorAndNotComplete = Math.max(prior_quantity -completed_quantity, 0)
        const completeApproved =  Math.min(completed_quantity, approved_quantity);
        const pendingComplete = completed_quantity - completeApproved;
        const leftOverApproved = approved_quantity - completeApproved;
        const approvedIncomplete = Math.min(priorAndNotComplete, leftOverApproved);
        const pendingIncomplete = Math.max(priorAndNotComplete - approvedIncomplete, 0);
        return {
            approvedIncomplete
            , pendingIncomplete
            , pendingComplete
            , completeApproved
        }

    }
    const {
        approved_quantity,
        complete_and_prior_quantity,
        incomplete_and_prior_quantity,
        incomplete_and_not_prior_quantity,
        complete_and_not_prior_quantity,
        discontinueFields,
        discontinued_at
    } = est;
    let completeApproved = Math.min(complete_and_prior_quantity, approved_quantity);
    let completeNotEstimates = complete_and_not_prior_quantity;
    let leftOverApproved = approved_quantity - completeApproved;

    // if there are left over approved counts add all the completed ones first
    if (leftOverApproved > 0) {
        const toAddToApprovedComplete = Math.min(completeNotEstimates, leftOverApproved);
        completeApproved += toAddToApprovedComplete
        leftOverApproved -= toAddToApprovedComplete
        completeNotEstimates -= toAddToApprovedComplete
    }

    let approvedIncomplete = Math.min(incomplete_and_prior_quantity, leftOverApproved);
    let pendingIncomplete = Math.max((incomplete_and_prior_quantity + (incomplete_and_not_prior_quantity ?? 0)) - approvedIncomplete, 0);
    let pendingComplete = Math.max((complete_and_prior_quantity + completeNotEstimates) - completeApproved, 0);

    if (discontinueFields !== undefined || discontinued_at !== null) {
        approvedIncomplete = 0;
        pendingIncomplete = 0;
    }
    return { approvedIncomplete, pendingIncomplete, pendingComplete, completeApproved }

}

export interface EstimateTableRow {
    key: number;
    name: string;
    price: number | null;
    frequency: string | null;
    quantity: number;
    is_free: boolean;
    is_refund?: boolean;
    reason: string | null;
    notes: string | null;
    unit: string
    orderId: number;
    type_id: string;
    complete: number;
    incomplete: number;
    type: ExistingEstimateTableType;
    updateForms: updateEstimateType[];
    updateEstimate: Function;
    index: number;
    removeEstimate?: (index: number) => void;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;
    dose: null | number;

}

export type ExistingEstimateTableType = "pending" | "approved" | "new";

export const generateEstimateTableRows = (
    estimates: EstimateItem[],
    type: ExistingEstimateTableType,
    updateEstimate: (index: number, newEstimate: EstimateItem) => void
): EstimateTableRow[] => {
    type TableEstimateItem = EstimateItem & {
        complete: number;
        incomplete: number;
        type: ExistingEstimateTableType;
        updateForms: updateEstimateType[];
        removeEstimate?: (index: number) => void
    }

    let localEstimates: TableEstimateItem[] = [];
    if (type === "approved") {
        localEstimates = estimates
            .filter(
                (est) => {
                    const { approvedIncomplete, completeApproved } = calculateEstimateNumbers(est)
                    const completeChecks = completeApproved > 0;
                    let incompleteChecks;
                    if (isInstanceOfNonMedicalEstimateItem(est)){
                        incompleteChecks = approvedIncomplete > 0 &&
                        (est.discontinueFields === undefined)
                    } else {
                        incompleteChecks = (!isDiscontinuedOrOver(est)) &&
                            (est.discontinueFields === undefined) &&
                            (approvedIncomplete > 0);
                    }
                    return completeChecks || incompleteChecks;
                },
            )
            .map(
                est => {
                    const { approvedIncomplete, completeApproved } = calculateEstimateNumbers(est)
                    const updateForms: updateEstimateType[] = []
                    if (approvedIncomplete > 0) {
                        updateForms.push('discontinue')
                    }
                    return {
                        ...est,
                        type,
                        updateForms,
                        complete: completeApproved,
                        incomplete: approvedIncomplete

                    }
                }
            )

    } else if (type === "pending") {
        localEstimates = estimates
            .filter(
                (est) => {
                    const { pendingComplete, pendingIncomplete } = calculateEstimateNumbers(est)
                    let discontinuedChecks;
                    if (isInstanceOfNonMedicalEstimateItem(est)) {
                        discontinuedChecks = (est.discontinueFields === undefined)
                    } else {
                        discontinuedChecks = (!isDiscontinuedOrOver(est)) && (est.discontinueFields === undefined)
                    }
                    const incompleteChecks = discontinuedChecks && pendingIncomplete > 0
                    const completeChecks = pendingComplete > 0
                    return incompleteChecks || completeChecks
                },
            )
            .map(
                est => {
                    const { pendingIncomplete, pendingComplete } = calculateEstimateNumbers(est)
                    const updateForms: updateEstimateType[] = [];
                    if (pendingIncomplete > 0) {
                        updateForms.push('discontinue')
                    }

                    return {
                        ...est,
                        type,
                        updateForms,
                        complete: pendingComplete,
                        incomplete: pendingIncomplete
                    }
                }
            )
    }

    return localEstimates.map((est, index) => {
        // Different enough data structure to need a guard statement
        if (isInstanceOfNonMedicalEstimateItem(est)){
            return {
                orderId: est.non_medical_order_id,
                key: est.non_medical_order_id,
                name: est.name,
                price: est.unit_cost_cents,
                frequency: "-",
                quantity: Math.max(est.complete, est.incomplete),
                is_free: est.is_free,
                is_refund: est.is_refund,
                reason: null,
                notes: null,
                unit: est.unit,
                updateForms: est.updateForms,
                complete: est.complete,
                incomplete: est.incomplete,
                type: est.type,
                updateEstimate,
                index: index,
                type_id: est.type_id,
                supplemental_cost_cents: null,
                is_recurring_supplemental: null,
                dose: null
            }
        }

        let frequency: string | null;
        let quantity =  est.complete + est.incomplete;
        let unitCosts = calculateUnitCosts(est, quantity)
        if (isInstanceOfFluidEstimateItem(est) || isInstanceOfCriEstimateItem(est)) {
            frequency = null;
        } else {
            frequency = est.frequency;
        }
        let dose: number | null;
        if (isInstanceOfMedicalEstimateItem(est)) {
            dose = est.dose;
        } else {
            dose = null;
        }

        return {
            orderId: est.instruction_id,
            key: index,
            name: est.name,
            price: unitCosts,
            frequency,
            quantity,
            is_free: est.is_free,
            is_refund: est.is_refund,
            reason: est.reason,
            notes: est.notes,
            unit: est.unit,
            updateForms: est.updateForms,
            complete: est.complete,
            incomplete: est.incomplete,
            type: est.type,
            updateEstimate,
            index: index,
            supplemental_cost_cents: est.supplemental_cost_cents,
            is_recurring_supplemental: est.is_recurring_supplemental,
            dose,
            type_id: est.type_id
        };
    })
}
