import { CSSProperties, useState } from 'react';
import { Form, Radio, Space, Button, Tooltip, Divider, FormInstance, Alert } from 'antd';
import { ExistingFluidInstruction } from '../../utils/dataTypes';
import { FormWrapper } from './FormWrapper';
import { INITIAL_MODAL_STATE } from 'views/visit/Visit';
import moment from 'moment';
import { Additives } from './Additives';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useAddFluidAdditiveMutation } from 'services/instructionService';
import { MAX_FLUID_ADDITIVES } from 'utils/constants';
import DatePicker from 'design/DatePicker';

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface FluidAdditiveFormProps {
    instruction: ExistingFluidInstruction;
    modalForm: FormInstance;
    setModalState: Function;
}

export const FluidAdditiveForm = ({ instruction, modalForm, setModalState }: FluidAdditiveFormProps) => {
    const [addFluidAdditive] = useAddFluidAdditiveMutation();
    const [completedTimeRadioValue, setCompletedTimeRadioValue] = useState(1);
    const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(moment());
    const isFluidStarted = instruction.actions.some((action) => action.status === 'complete');
    const dateIsInTheFutureOrIsNotAfterFluidStart = (currentDate: moment.Moment) => {
        const fluidStartedAt = instruction.actions.find((action) => action.value === null && action.status === 'complete')?.completed_at;
        if (fluidStartedAt) {
            return currentDate > moment() || currentDate <= moment.unix(fluidStartedAt);
        }
        return currentDate > moment();
    };
    const [isPlusOneHourButtonDisabled, setIsPlusOneHourButtonDisabled] = useState(true);
    const [isMinusOneHourButtonDisabled, setIsMinusOneHourButtonDisabled] = useState(
        dateIsInTheFutureOrIsNotAfterFluidStart(moment().subtract(1, 'hour')),
    );
    const setNewTimeField = (date: moment.Moment | null) => {
        const datePlusOneHour = moment(date).add(1, 'hour');
        setIsPlusOneHourButtonDisabled(dateIsInTheFutureOrIsNotAfterFluidStart(datePlusOneHour));
        const dateMinusOneHour = moment(date).subtract(1, 'hour');
        setIsMinusOneHourButtonDisabled(dateIsInTheFutureOrIsNotAfterFluidStart(dateMinusOneHour));
        setSelectedTime(date);
        modalForm.setFieldsValue({ completed_at: date });
    };

    const plusMinusHours = (type: 'plus' | 'minus') => {
        const timeToAdd = type === 'plus' ? 1 : -1;
        const newTime = moment(selectedTime).add(timeToAdd, 'hour');
        setNewTimeField(newTime);
    };

    const maxAdditives = MAX_FLUID_ADDITIVES - instruction.fluid_additives.length;

    if (maxAdditives <= 0) {
        return (
            <Alert
                className='alert-with-icon-aligned-to-top'
                type='warning'
                showIcon
                message={`A fluid cannot have more than ${MAX_FLUID_ADDITIVES} additives.`}
                style={{ marginBottom: '16px' }}
            />
        );
    }

    return (
        <FormWrapper
            form={modalForm}
            onFinish={(values) => {
                const formattedFluidAdditives = values.fluid_additives?.map((additive: any) => {
                    return {
                        id: additive.additive_id,
                        supplemental_id: additive.supplemental_id,
                        dose_qty: additive.dose_qty,
                    };
                });
                addFluidAdditive({
                    visitId: instruction.visit_id,
                    instructionId: instruction.id,
                    body: {
                        fluid_additives: formattedFluidAdditives,
                        initial_order: !isFluidStarted,
                        created_at: moment(selectedTime).unix(),
                    },
                });
                setModalState({
                    ...INITIAL_MODAL_STATE,
                    form: modalForm,
                });
                modalForm.resetFields();
            }}
            onFinishFailed={() => {}}
            bottomBar={<></>}
        >
            <>
                {!isFluidStarted && (
                    <Alert
                        className='alert-with-icon-aligned-to-top'
                        type='warning'
                        showIcon
                        message={`The fluid has not started. The ${instruction.name} order will be updated to reflect any changes.`}
                        style={{ marginBottom: '16px' }}
                    />
                )}
                <Additives initialOrder={false} form={modalForm} maxAdditives={maxAdditives} />
                {isFluidStarted && (
                    <>
                        <Divider />
                        <Form.Item
                            className='completed-action-modal__radio'
                            label='Completed Time'
                            labelAlign='left'
                            style={formItemStyle}
                            labelCol={{ span: 6 }}
                        >
                            <Radio.Group
                                onChange={(e) => {
                                    setCompletedTimeRadioValue(e.target.value);
                                }}
                                value={completedTimeRadioValue}
                            >
                                <Space direction='vertical'>
                                    <Radio value={1}>Completed Now</Radio>
                                    <Radio value={2}>Completed Earlier</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {completedTimeRadioValue === 2 && (
                            <Form.Item
                                name='completed_at'
                                rules={[{ required: true, message: 'A new time is required' }]}
                                wrapperCol={{ span: 18, offset: 6 }}
                                style={formItemStyle}
                                initialValue={selectedTime}
                            >
                                <DatePicker
                                    value={selectedTime}
                                    onChange={setNewTimeField}
                                    suffixIcon={<ClockCircleOutlined />}
                                    disabledDate={dateIsInTheFutureOrIsNotAfterFluidStart}
                                />
                                <span className='reschedule-action-form__new-time-funcs'>
                                    <Tooltip title={isMinusOneHourButtonDisabled ? 'The date cannot be before fluid start time' : null}>
                                        <Button onClick={() => plusMinusHours('minus')} disabled={isMinusOneHourButtonDisabled}>
                                            - 1hr
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={isPlusOneHourButtonDisabled ? 'The date cannot be in the future' : null}>
                                        <Button onClick={() => plusMinusHours('plus')} disabled={isPlusOneHourButtonDisabled}>
                                            + 1hr
                                        </Button>
                                    </Tooltip>
                                </span>
                            </Form.Item>
                        )}
                    </>
                )}
            </>
        </FormWrapper>
    );
};
