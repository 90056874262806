import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Input } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetChatMessagesQuery,
    useGetVisitByIdQuery,
    useSendSMSChatMessageMutation,
} from 'services/visitService';
import { setChatOpen } from 'store/slices/visitHeaderSlice';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { SMSTextMessage } from 'utils/dataTypes';
import './Chat.css';
import MessageContent from './MessageContent';
import MessageHeader from './MessageHeader';

const Chat = () => {
    const messagesRef = useRef<HTMLDivElement>(null);
    const [message, setMessage] = useState('');
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const chatOpen = useAppSelector((state) => state.visitHeaderSlice.chatOpen);
    const dispatch = useAppDispatch();

    const { data: chatMessages } = useGetChatMessagesQuery(
        visitId,
        { ...BASE_QUERY_OPTIONS, skip: !chatOpen },
    );
    const { data: currentVisit } = useGetVisitByIdQuery(
        visitId,
        { ...BASE_QUERY_OPTIONS, skip: !chatOpen },
    );
    const [sendMessageMutation, { isLoading }] =
        useSendSMSChatMessageMutation();

    const showMessageHeader = (message: SMSTextMessage, index: number) => {
        if (index === 0) return true;

        const currentMessageDate = moment
            .unix(message.created_at)
            .format('YYYY MM DD');
        const lastMessageDate = moment
            .unix(chatMessages![index - 1].created_at)
            .format('YYYY MM DD');
        const currentMessageRecipientName =
            message.owner_first_name ?? '' + message.owner_last_name ?? '';
        const lastMessageRecipientName =
            chatMessages![index - 1].owner_first_name ??
            '' + chatMessages![index - 1].owner_last_name ??
            '';

        return (
            currentMessageDate !== lastMessageDate ||
            message.to_number !== chatMessages![index - 1].to_number ||
            currentMessageRecipientName !== lastMessageRecipientName
        );
    };

    const sendMessage = () => {
        sendMessageMutation({
            visitId,
            messageContent: message,
        });
        setMessage('');
    };

    const handleCloseChat = () => {
        if (message.length > 0) {
            confirm({
                title: 'Unsaved Changes',
                icon: (
                    <ExclamationCircleOutlined style={{ color: 'var(--gold-6)' }} />
                ),
                content: (
                    <p>
                        You have unsaved changes. Select cancel to continue editing.
                    </p>
                ),
                okText: 'Ok',
                style: { zIndex: 1500 },
                okButtonProps: {
                    type: 'primary',
                },
                cancelText: 'Cancel',
                onOk() {
                    setMessage('');
                    dispatch(setChatOpen(false));
                },
            });
        } else {
            setMessage('');
            dispatch(setChatOpen(false));
        }
    };

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.addEventListener('DOMNodeInserted', (event) => {
                messagesRef.current?.scroll({
                    top: messagesRef.current?.scrollHeight,
                    behavior: 'smooth',
                });
            });
        }
    }, []);

    return (
        <>
            {chatOpen && <div className='chat-overlay' />}
            <aside
                className={`chat-wrapper ${
                    chatOpen ? 'chat-wrapper--open' : ''
                }`}
            >
                <header className='chat-header'>
                    <h1>Text Messaging</h1>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={() => handleCloseChat()}
                    />
                </header>

                <section className='chat-messages' ref={messagesRef}>
                    <Alert
                        message='One-way messaging'
                        description='Customers cannot respond to text messages.'
                        type='info'
                        showIcon
                    />

                    {chatMessages?.map((message, index) => {
                        return (
                            <div className='chat-messages__message'>
                                {showMessageHeader(message, index) && (
                                    <MessageHeader message={message} />
                                )}

                                <MessageContent message={message} />
                            </div>
                        );
                    })}
                </section>

                <section className='chat-text-input'>
                    <Input
                        placeholder='Text Message'
                        value={message}
                        maxLength={1460}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </section>

                <footer className='chat-buttons'>
                    <Button onClick={() => handleCloseChat()}>Cancel</Button>
                    <Button
                        type='primary'
                        onClick={sendMessage}
                        loading={isLoading}
                        disabled={
                            isLoading || !currentVisit?.customer_id || !message
                        }
                    >
                        Send
                    </Button>
                </footer>
            </aside>
        </>
    );
};

export default Chat;
