import { useState } from 'react';
import { Button } from 'antd';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { formatCurrency } from 'utils/formatFuncs';
import { WEB_CHECKOUT_STEP } from 'utils/types/paymentTypes';
import { useSaveWebCheckoutPaymentErrorMutation } from 'services/paymentService';

interface CheckoutFormProps {
    amount: number;
    step: string;
    setStep: (step: WEB_CHECKOUT_STEP) => void;
    onStripeReady: () => void;
    onBack: () => void;
}

export const CheckoutForm = ({amount, step, setStep, onStripeReady, onBack}: CheckoutFormProps) => {
    const stripe = useStripe();
	const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [saveWebCheckoutPaymentError] = useSaveWebCheckoutPaymentErrorMutation();

    const handleSubmit = async () => {
        setIsLoading(true);
        if (stripe && elements) {
			const result = await stripe.confirmPayment(
				{
					elements: elements,
					confirmParams: {
						return_url: window.location.href
					},
				}
			);
            if (result.error) {
                if (
                    result.error.code === "payment_intent_unexpected_state" &&
                    result.error.payment_intent?.cancellation_reason === "abandoned"
                ) {
                    setErrorMessage("Payment failed. This pay link is discontinued.");
                }
                else {
                    setErrorMessage("Payment failed. " + result.error.message);
                    setIsLoading(false);
                    if (result.error.payment_intent) {
                        saveWebCheckoutPaymentError({
                            body: {
                                payment_intent_id: result.error.payment_intent.id,
                                last_error_amount_cents: null,
                                last_error_code: result.error.code ?? 'undefined_error',
                                last_error_message: result.error.message ?? '',
                                card_brand: result.error.payment_method?.card?.brand ?? '',
                                last_4_digits: result.error.payment_method?.card?.last4 ?? '',
                            }
                        });
                    }
                }
            }	
		}
    };

    return (
        <div style={{display: step === "payment_form_ready" ? "block" : "none", marginTop: "1em"}}>
            <PaymentElement
                onReady={()=>{
                    setStep("payment_form_ready");
                    onStripeReady();
                }}
                
            />
            <div style={{overflow: "hidden"}}>
                <Button 
                    type="primary"
                    size={"large"}
                    style={{marginTop: "1em", float: "right", width: "11em"}}
                    onClick={handleSubmit}
                    disabled={isLoading}
                    >
                        Pay {formatCurrency(amount)}
                </Button>
                <Button 
                    size={"large"}
                    style={{margin: "1em 0.5em 0 0", float: "right", width: "5em"}}
                    onClick={()=>{
                        setStep("donation");
                        onBack();
                    }}
                    disabled={isLoading}
                    >
                        Back
                </Button>
            </div>
            {errorMessage && <p style={{marginTop: "1em", marginBottom: "-1em", color: "red", textAlign: "right"}}>
                {errorMessage}
            </p>}
        </div>
    );
}
