import { ComposeBoxProvider } from 'hooks/ComposeBoxProvider';
import CustomerPets from './CustomerPets';
import CustomerSidebar from './CustomerSidebar';

const Customer = () => {
    return (
        <section>
            <ComposeBoxProvider>
                <CustomerSidebar />

                <CustomerPets />
            </ComposeBoxProvider>
        </section>
    );
};

export default Customer;
