import { ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, FormInstance, Input, Row, Select, Tag, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { CSSProperties } from 'react';
import { useCreateMyMacroMutation, useUpdateMyMacroMutation } from 'services/macroService';
import { drawerSize } from 'styles/drawer';
import { Macro } from 'utils/dataTypes';
import useWindowDimensions from 'utils/window';
import './index.css';

const formItemStyle: CSSProperties = {
    paddingBottom: '.8rem',
};

interface MacroDetailsDrawerProps {
    macro: Macro | undefined;
    existingMacros: Macro[] | undefined;
    form: FormInstance;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deleteConfirm: (macro: Macro | undefined) => any;
}

export const MacroDetailsDrawer = ({ macro, existingMacros, form, open, setOpen, deleteConfirm }: MacroDetailsDrawerProps) => {
    const { width } = useWindowDimensions();
    const [createMyMacro, { isLoading: isCreateMyMacroLoading }] = useCreateMyMacroMutation();
    const [updateMyMacro, { isLoading: isUpdateMyMacroLoading }] = useUpdateMyMacroMutation();

    const onFormSave = () => {
        if (!!macro && macro.id) {
            updateMyMacro({ macroId: macro.id, macroForm: form.getFieldsValue() })
                .unwrap()
                .then(() => {
                    setOpen(false);
                    message.success('Successfully updated macro');
                })
                .catch((err: string) => message.error(err));
        } else {
            createMyMacro({ macroForm: form.getFieldsValue() })
                .unwrap()
                .then(() => {
                    setOpen(false);
                    message.success('Successfully created macro');
                })
                .catch((err: string) => message.error(err));
        }
    };

    const handleCloseDrawer = () => {
        if (form.isFieldsTouched()) {
            confirm({
                title: 'Unsaved Changes',
                icon: <ExclamationCircleOutlined style={{ color: 'var(--gold-6)' }} />,
                content: <p>You have unsaved changes. Select Cancel to continue editing.</p>,
                okText: 'Ok',
                style: { zIndex: 1500 },
                okButtonProps: { type: 'primary' },
                cancelText: 'Cancel',
                onOk() {
                    setOpen(false);
                },
            });
        } else {
            setOpen(false);
        }
    };

    return (
        <Drawer
            title={
                <span>
                    Macro Details{' '}
                    {macro?.is_veg && (
                        <Tag color='geekblue' style={{ marginLeft: '1rem', verticalAlign: 'middle' }}>
                            VEG - read only
                        </Tag>
                    )}
                </span>
            }
            className='veg-drawer'
            placement='right'
            closable={true}
            width={width >= 800 ? drawerSize.lg : drawerSize.full}
            bodyStyle={{ padding: '8px 24px' }}
            onClose={handleCloseDrawer}
            visible={open}
            key='macro_details_Drawer'
            destroyOnClose={true}
            mask={true}
            maskClosable={true}
            footer={
                !macro?.is_veg && (
                    <>
                        {!!macro && (
                            <Button danger style={{ marginLeft: 0, marginRight: 'auto' }} onClick={() => deleteConfirm(macro)}>
                                Delete
                            </Button>
                        )}
                        <Button onClick={handleCloseDrawer}>Cancel</Button>
                        <Button
                            form='macro-details-form'
                            htmlType='submit'
                            style={{ color: 'white', backgroundColor: 'var(--veg-secondary-teal)', borderWidth: 0 }}
                            loading={isCreateMyMacroLoading || isUpdateMyMacroLoading}
                        >
                            Save
                        </Button>
                    </>
                )
            }
        >
            <Form id='macro-details-form' form={form} layout='vertical' style={{ height: '100%' }} onFinish={onFormSave}>
                <Row gutter={8} style={{ height: '100%', flexDirection: 'column' }}>
                    <Col key='label' span={24} flex='none'>
                        <label className='macro-details-required'>Name</label>
                        <Form.Item
                            name='label'
                            style={formItemStyle}
                            rules={[
                                {
                                    required: true,
                                    message: 'Name required',
                                },
                                {
                                    validator: (_, value) => {
                                        return existingMacros?.find(
                                            (existingMacro) => existingMacro.id !== macro?.id && existingMacro.label === value,
                                        )
                                            ? Promise.reject('Name already exists in your library')
                                            : Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input disabled={macro?.is_veg} suffix={macro?.is_veg && <LockOutlined />} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Row gutter={8}>
                            <Col key='key_word' xs={24} sm={12}>
                                <label className='macro-details-required'>Keyword</label>
                                <Form.Item
                                    name='key_word'
                                    style={formItemStyle}
                                    rules={[
                                        { required: true, message: 'Keyword required' },
                                        {
                                            validator: (_, value) => {
                                                return existingMacros
                                                    ?.filter((macro) => !macro.is_veg)
                                                    .find(
                                                        (existingMacro) =>
                                                            existingMacro.id !== macro?.id && existingMacro.key_word === value,
                                                    )
                                                    ? Promise.reject('Keyword already exists in your library')
                                                    : Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='letters and dashes only'
                                        disabled={macro?.is_veg}
                                        prefix={'\\'}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            // replace spaces with dashes
                                            e.target.value = e.target.value.replaceAll(' ', '-');
                                            // remove any character that's not a letter or a dash
                                            e.target.value = e.target.value.replaceAll(new RegExp('[^a-z-]', 'gi'), '');
                                            // replace uppercase letters with lowercase
                                            e.target.value = e.target.value.toLowerCase();
                                        }}
                                        suffix={macro?.is_veg && <LockOutlined />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col key='category' xs={24} sm={12}>
                                <label className='macro-details-required'>Category</label>
                                <Form.Item name='category' style={formItemStyle} rules={[{ required: true, message: 'Category required' }]}>
                                    <Select
                                        disabled={macro?.is_veg}
                                        options={[
                                            {
                                                value: 'General',
                                                label: 'General',
                                            },
                                            {
                                                value: 'Diagnostics',
                                                label: 'Diagnostics',
                                            },
                                        ]}
                                        suffixIcon={macro?.is_veg && <LockOutlined />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col key='content' span={24} flex='auto'>
                        <Row style={{ height: '100%', flexDirection: 'column' }}>
                            <Col flex='none'>
                                <label className='macro-details-required'>Content</label>
                            </Col>
                            <Col flex='auto'>
                                <Form.Item
                                    className='macro-content'
                                    name='content'
                                    style={{ height: '100%', paddingBottom: 'var(--spacing-sm)' }}
                                    rules={[{ required: true, message: 'Content required' }]}
                                >
                                    <Input.TextArea disabled={macro?.is_veg} style={{ height: '100%', resize: 'none' }}  />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    {!!macro && !macro.is_veg && macro.created_by && (
                        <Col span={24} flex='none'>
                            <div className='veg-body-1' style={{ color: 'var(--gray-8)' }}>
                                Original content created by {macro.created_by}
                            </div>
                        </Col>
                    )}
                </Row>
            </Form>
        </Drawer>
    );
};
