import { Tag } from 'antd';
import moment from 'moment';

interface WrittenPrescriptionStatusTagProps {
    status: 'draft' | 'finalized' | 'void';
    voidedAt?: number | null;
}

const WrittenPrescriptionStatusTag: React.FC<WrittenPrescriptionStatusTagProps> = ({ status, voidedAt }) => {
    if (status === 'draft') {
        return <Tag className='written-prescription-container__status-tag written-prescription-container__status-tag--draft'>Draft</Tag>;
    }

    if (status === 'void' && voidedAt) {
        return (
            <Tag className='written-prescription-container__status-tag written-prescription-container__status-tag--voided'>
                Voided {moment.unix(voidedAt).format('MM/DD/YYYY HH:mm A')}
            </Tag>
        );
    }

    return null;
};

export default WrittenPrescriptionStatusTag;
