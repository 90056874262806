import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Col, Form, Row, Tabs, Tooltip } from 'antd';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import NoteEditor from '../NoteEditor';
import { BaseNote } from 'utils/dataTypes';

const EDITOR_WIDTH = 24;

export interface MarkdownNoteProps {
	fieldName: string;
	content?: string;
	onFormChange?: (values: any) => void; //not actually optional -- always sent via FormWrapper
	getFormData?: Function; //not actually optional -- always sent via FormWrapper
	descriptionWarning?: boolean;
	note?: BaseNote;
	rowsNoteEditor?: number;
	showOnlyEditor?: boolean;
}

const MarkdownNote = React.memo(
	(props: MarkdownNoteProps) => {
		const [value, setValue] = useState(props.content ?? '');
		const [previewHeight, setPreviewHeight] = useState('0px');

		const onFormChange =
			props.onFormChange ??
			(() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

		const getFormData =
			props.getFormData ??
			(() => console.error('ERROR, getFormData NOT PASSED THROUGH'));

		return (
			<>
				<Row style={{ width: '100%', paddingLeft: 12 }} gutter={[12, 12]}>
					{
						props.descriptionWarning &&
						<Alert
							message="Updates to the description applies to all visits referred to by this DVM."
							type="warning"
						/>
					}
					<Tabs
						defaultActiveKey='write'
						style={{ width: '100%' }}
						onChange={() => {
							setValue(getFormData()?.[props.fieldName] ?? '');
							const height = document.getElementById(
								`${props.fieldName}-note-input`,
							)?.clientHeight;

							setPreviewHeight(`${height}px`);
						}}
					>
						<Tabs.TabPane tab='Write' key='write' style={props.showOnlyEditor ? { height: 'auto', width: '100%', paddingBottom: 0} : {}} >
							<Col span={EDITOR_WIDTH}>
								<Form.Item
									labelCol={{ span: 0 }}
									wrapperCol={{ span: 24 }}
									name={props.fieldName}
									preserve={false}
									style={{
										paddingTop: '0px',
									}}
									initialValue={props.content}
								>
									<NoteEditor
										defaultContent={value}
										handleChange={onFormChange}
										name={props.fieldName}
										rows={props.rowsNoteEditor}
									/>
								</Form.Item>
							</Col>
						</Tabs.TabPane>
						<Tabs.TabPane tab='Preview' key='preview' style={props.showOnlyEditor ? { height: 'auto', width: '100%'} : {}}>
							<Col
								span={EDITOR_WIDTH}
								style={{ height: previewHeight }}
							>
								<Markdown remarkPlugins={[remarkBreaks]} children={value} />
							</Col>
						</Tabs.TabPane>
					</Tabs>

					<Col span={24}>
						<p className='markdown-enabled-caption'>
							Styling with Markdown is supported
							<Tooltip title='**bold** | *italic*' getPopupContainer={(triggerNode: HTMLElement) => triggerNode}>
								<InfoCircleOutlined
									style={{ marginLeft: '4px' }}
								/>
							</Tooltip>
						</p>
					</Col>

				</Row>
			</>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.fieldName === nextProps.fieldName &&
			prevProps.note === nextProps.note
		);
	},
);

export default MarkdownNote;
