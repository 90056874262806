import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import React from 'react';
import { INSTRUCTION_FREQUENCY_REGEX } from 'utils/constants';

interface EstimateFrequencyFormItemProps {
    formFieldName: NamePath;
    initialValue?: string;
    isEditing?: boolean;
    formFieldValue?: string;
    disabled?: boolean;
    onFormChange?: (changes: { [key in string]: string }) => void;
}

const EstimateFrequencyFormItem: React.FC<EstimateFrequencyFormItemProps> = ({
    formFieldName,
    initialValue,
    isEditing,
    formFieldValue,
    disabled,
    onFormChange,
}) => (
    <Form.Item
        initialValue={initialValue}
        name={formFieldName}
        rules={[
            {
                message: 'The frequency does not match the regex.',
                pattern: INSTRUCTION_FREQUENCY_REGEX,
            },
            { required: true, message: 'Required' },
        ]}
    >
        {isEditing ? (
            <Input
                disabled={disabled}
                placeholder='e.g. q1h, q1d, once'
                onChange={(e) => {
                    if (onFormChange) {
                        onFormChange({ formFieldName: e.target.value });
                    }
                }}
            />
        ) : (
            <span>{formFieldValue}</span>
        )}
    </Form.Item>
);

export default EstimateFrequencyFormItem;
