import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PIMS_HOST } from 'utils/constants';

export const pimsApi = createApi({
	reducerPath: 'pimsApi',
	//TODO remove extra/unused tag types from each API
	tagTypes: [
		'Patient', // AKA pet
		'Visit',
		'Customer', //AKA Owner
		'CUSTOMER_PETS',
		'Hospital',
		'Exam', // Physical exam for VISIT
		'ProgNote', // Progress note for VISIT
		'DischargeNote', // Discharge note for Visit
		'Instruction', //Instruction for VISIT
		'Package', // Group of Instructions, from SEARCH endpoint
		'Problem', // Problem for VISIT
		'rDVM', // Associates with patients and visits
		'SELF', // current logged in user
		'User', // other PIMS users
		'HospitalDoctor',
		'Notification',
		'PatientHistory',
		'Macro',
		'DischargeMed',
		'Encounter',
		'EncounterNurse',
		'InboundCall',
		'Billing',
		'ConsentForm',
		'Ledger',
		'WebCheckout',
		'NoteFile',
		'Species',
		'Breed',
		'AttributionSummary',
		"Discount",
		"Invoice",
		"ChatMessages",
		"PatientFile",
		"PatientNote",
		"UserForHospital",
		"WrittenPrescription",
		"WrittenPrescriptionById",
		"StripePaymentError",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: PIMS_HOST,
		credentials: 'include',
		prepareHeaders: (headers, { getState }) => {
			if (!headers.has('Content-Type')){
				headers.set('Content-Type', 'application/json');
			} else {
				headers.delete('Content-Type')
			}
			return headers;
		},
	}),
	endpoints: () => ({}),
});
