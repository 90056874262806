import { Form, Checkbox } from 'antd';
import { useState, useEffect } from 'react';
import { INSTRUCTION_STATUSES, INSTRUCTION_TYPES } from 'utils/dataTypes';

interface CubexCheckboxProps {
	type_id: INSTRUCTION_TYPES;
	status: INSTRUCTION_STATUSES;
	onFormChange: Function;
	labelSpan: number;
	wrapperSpan: number;
}

export const CubexCheckbox = ({
	type_id,
	status,
	onFormChange,
	labelSpan,
	wrapperSpan,
}: CubexCheckboxProps) => {
	const [checked, setChecked] = useState(status === 'inprogress');
	useEffect(() => {
		setChecked(status === 'inprogress');
		onFormChange({ send_to_cubex: status === 'inprogress' });
	}, [status]);
	return (
		<Form.Item
			valuePropName='checked'
			name='send_to_cubex'
			style={{ width: '100%' }}
			wrapperCol={{
				span: wrapperSpan,
				offset: labelSpan,
			}}
			initialValue={checked}
		>
			<Checkbox
				disabled={status !== 'inprogress'}
				checked={checked}
				onClick={() => {
					setChecked((isChecked) => !isChecked);
				}}
			>
				{type_id === 'TGH' ? 'Resend to Cubex' : 'Send to Cubex'}
			</Checkbox>
		</Form.Item>
	);
};
