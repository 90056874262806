import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='10' cy='10' r='9.5' fill='white' stroke='black' />
        <rect x='9.59961' width='0.799999' height='19.6' fill='#262626' />
        <rect y='10.4' width='0.799999' height='19.6' transform='rotate(-90 0 10.4)' fill='#262626' />
        <rect x='2.40039' y='3.60001' width='0.8' height='19.6' transform='rotate(-45 2.40039 3.60001)' fill='#262626' />
        <rect x='3.2002' y='17.2' width='0.8' height='19.6' transform='rotate(-135 3.2002 17.2)' fill='#262626' />
        <circle cx='10.0004' cy='9.99999' r='7.6' fill='white' />
        <rect x='7.59961' y='3.60001' width='4.8' height='12.8' fill='#D9D9D9' />
        <rect x='16.3994' y='7.60001' width='4.8' height='12.8' transform='rotate(90 16.3994 7.60001)' fill='#D9D9D9' />
        <path d='M10 6V9.84L12.4 10.8' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
);

export const ClockIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={svg} {...props} />;
