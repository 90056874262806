import React, { useEffect, useRef } from 'react';
import './styles.css';

export interface DropdownItem {
    key: string;
    label: string;
    onClick?: () => void;
    url?: string;
    icon?: React.ReactNode;
}

interface UserDropdownMenuProps {
    items: DropdownItem[];
    visible: boolean;
    setVisible: (boolean: boolean) => void;
    position?: any;
}

const UserDropdownMenu: React.FC<UserDropdownMenuProps> = ({
    items,
    visible,
    setVisible,
    position
}) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const handleItemClick = (itemClick?: () => void) => {
        setVisible(false);
        if (itemClick) {
            itemClick();
        }
    };    

    // close dropdown if clicking outside of it except trigger
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                !event.target.closest('#dropdownTrigger')) {
                setVisible(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setVisible]);

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 2000,
                left: `${position.left}px`,
                top: `${position.top}px`,
            }}
            ref={dropdownRef}
        >
            {visible && (
                <div className='custom-dropdown'>
                    <ul className='custom-dropdown-menu'>
                        {items.map((item) => {
                            if (item.url) {
                                return (
                                    <li key={item.key} className='custom-dropdown-menu-item' data-cy={item.key}>
                                        <a href={item.url} style={{ color: 'inherit' }}>
                                            {item.icon && <span className='dropdown-item-icon'>{item.icon}</span>}
                                            {item.label}
                                        </a>
                                    </li>
                                );
                            } else {
                                return (
                                    <li key={item.key} className='custom-dropdown-menu-item' data-cy={item.key} onClick={() => handleItemClick(item.onClick!)}>
                                        {item.icon && <span className='dropdown-item-icon'>{item.icon}</span>}
                                        {item.label}
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserDropdownMenu;

