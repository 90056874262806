export const confirmDeceasedModalContent = (patientName: string) => {
    return (
        <>
            <p>Marking <strong>{patientName}</strong> as deceased will...</p>
            <li>
                1. Remove checkout requirements like <strong>referral source</strong>.
            </li>
            <li>
                2. Stop the <strong>survey</strong> and <strong>follow-up text</strong> from sending.
            </li>
        </>
    );
};
