import { AutoComplete, Col, Row, Divider, message, Tooltip, Button } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyPatientSearchQuery } from 'services/patientService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { PetGroupedSearch, PetOwner } from 'utils/dataTypes';
import { appendRouteId, formatPhoneNumber } from 'utils/formatFuncs';
import { ROUTES } from 'utils/types/enums';
import topBarStyles from './top-bar.module.css';
import { useAddNewVisitMutation } from 'services/visitService';
import { usePostCheckinTprInstructionsMutation } from 'services/instructionService';
import {ReactComponent as PawIcon} from 'assets/img/Paw.svg';
import circled_user_icon from 'assets/img/CircledUserIcon.svg';
import calendar_icon from 'assets/img/CalendarIcon.svg';
import calendar_plus_icon from 'assets/img/CalendarPlus.svg';
import { useGetUserDataQuery } from 'services/authService';
import './topbar.css';
import { SearchOutlined } from '@ant-design/icons';

interface patientDropDownProps {
    petGroupedSearchResult: PetGroupedSearch,
    index?: number,
    dataLength?: number,
    setSearchValueFunction: Function,
    patientSearchFunction: Function,
    collapsed?: boolean;
    setTempExpand: (expand: boolean) => void;
}

const PatientDropDown = ({
    petGroupedSearchResult,
    index,
    dataLength,
    setSearchValueFunction,
    patientSearchFunction,
    setTempExpand
}: patientDropDownProps) => {
    const history = useHistory();

    const getOwnerInfo = (primaryContact: PetOwner) => {
        const { first_name, last_name, phone_number } = primaryContact;
        let ownerInfo = "";
        if (first_name) {
            ownerInfo += first_name
        }
        if (last_name) {
            ownerInfo += ` ${last_name}`
        }
        ownerInfo = ownerInfo.substring(0, 30)
        if (phone_number) {
            if (last_name || first_name) {
                ownerInfo += ` | ${formatPhoneNumber(phone_number)}`
            } else {
                ownerInfo += formatPhoneNumber(phone_number)
            }
        }
        return ownerInfo;
    }

    const { hospital_display_name, pets } = petGroupedSearchResult
    let hospitalDisplayName = hospital_display_name ?? "No Hospital"

    const [createNewVisit] = useAddNewVisitMutation();
    const [postCheckinTprInstructions] = usePostCheckinTprInstructionsMutation();

    return (
        <div className='search-result' style={{
            paddingTop: index === 0 ? "24px" : "", 
            paddingBottom: dataLength && dataLength - 1 === index ? "24px" : ""
        }}>
            <Row
                className={topBarStyles['hospital-search-name']}
            >
                <Col span={24} data-cy={'searchHospitalDisplayName'}>{hospitalDisplayName}</Col>
            </Row>
            <Divider style={{marginTop: '2px', marginBottom: '10px'}}/>
            {pets?.map((pet, index) => {
                return (
                    <>
                    <Row
                    className={topBarStyles['patient-search-name']}
                    >
                        <Col span={24}>
                            <div style={{display:'flex', justifyContent: 'space-between'}}>
                                    <div
                                        style={{ display: 'flex', gap: '8px', cursor: 'pointer' }}
                                        onClick={() => {
                                            history.push(appendRouteId(ROUTES.patient, String(pet.pet_id)));
                                            setTempExpand(false);
                                        }}>
                                        <PawIcon
                                            color='#595959'
                                            width={'20px'}
                                            height={'20px'}
                                        />
                                    {pet.name.substring(0, 30)}
                                </div>
                                <div style={{display:'flex', gap: '16px'}}>
                                    <Tooltip placement='top' title={'View recent visit'}>
                                        <img
                                            alt={"calendar_icon"}
                                            src={calendar_icon}
                                            width={'20px'}
                                            height={'20px'}
                                            style={{cursor: 'pointer'}}
                                            data-cy={'viewRecentVisitButton'}
                                            onClick={() => {
                                                if (pet.last_visit_id) {
                                                    history.push(appendRouteId(ROUTES.visit, String(pet.last_visit_id)));
                                                    setTempExpand(false);
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip placement='top' title={'Create new visit'}>
                                        <img
                                            alt={"calendar_plus_icon"}
                                            src={calendar_plus_icon}
                                            width={'20px'}
                                            height={'20px'}
                                            style={{cursor: 'pointer'}}
                                            data-cy={'createNewVisitButton'}
                                            onClick={() => {
                                                createNewVisit({ patientId: pet.pet_id })
                                                    .unwrap()
                                                    .then((data) => {
                                                        postCheckinTprInstructions({ visitId: data.id });
                                                        history.push(appendRouteId(ROUTES.visit, String(data.id)));
                                                        setSearchValueFunction("");
                                                        patientSearchFunction("");
                                                        setTempExpand(false);
                                                    })
                                                    .catch((e) => {
                                                        message.error('There was an error creating the visit');
                                                        console.error(e);
                                                    });
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {pet.primary_contacts?.map((primaryContact, index) => {
                        return (
                            <Row
                            className={topBarStyles['owner-search-name']}
                            >
                                <Col span={24}>
                                    <div
                                        style={{ display: 'flex', gap: '8px', cursor: 'pointer' }}
                                        onClick={() => {
                                            if (primaryContact.customer_id) {
                                                history.push(appendRouteId(ROUTES.customer, String(primaryContact.customer_id)));
                                                setTempExpand(false);
                                            }
                                        }}
                                    >
                                        <img
                                            alt={"circled_user_icon"}
                                            src={circled_user_icon}
                                            width={'20px'}
                                            height={'20px'}
                                        />
                                        {primaryContact.customer_id ? getOwnerInfo(primaryContact) : "No customer record"}
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                    {pets?.length - 1 !== index && <Divider style={{marginTop: '2px', marginBottom: '10px'}}/>}
                    </>
                );
            })}
        </div>)
}

interface PatientSearchBoxInterface {
    collapsed?: boolean;
    setTempExpand: (expand: boolean) => void
}

export const PatientSearchBox: FC<PatientSearchBoxInterface> = ({ collapsed, setTempExpand }) => {
    const [value, setValue] = useState<string>()
    const searchBoxRef = useRef<HTMLDivElement | null>(null);
    const [getPatients, { data }] = useLazyPatientSearchQuery(BASE_QUERY_OPTIONS);

    const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);

    const hospital_name = loggedInUserData ? loggedInUserData?.hospital_name : '';

    const sortedData = data ? [
        ...data.filter(x => x.hospital_display_name === hospital_name),
        ...data.filter(x => x.hospital_display_name !== hospital_name)
    ] : data;

    const patientSearchDebounced = useMemo(
        () =>
            _.debounce((searchContent: string) => {
                getPatients({ search_term: searchContent.trim() });
            }, 500),
        [],
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;

            const dropdownEl = document.querySelector('.options-patient-search');
            const clickedInsideDropdown = dropdownEl && dropdownEl.contains(target);

            if (searchBoxRef.current && !searchBoxRef.current.contains(target) && !clickedInsideDropdown) {
                setTempExpand(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setTempExpand]);

    return (
        collapsed ? (
            <Button
                icon={<SearchOutlined style={{ color: 'white' }} />}
                type='text'
                onClick={() => {
                    if (collapsed) {
                        setTempExpand(true);
                    }
                }}
            />)
            :
            <div ref={searchBoxRef} className='patient-search-1'>
                <AutoComplete
                    autoFocus
                    allowClear
                    filterOption={false}
                    data-cy="patientSearch"
                    value={value}
                    dropdownMatchSelectWidth={390}
                    notFoundContent={(
                        <div className='search-result' style={{
                            paddingTop: "24px",
                            paddingBottom: "24px"
                        }}>
                            <Row className={topBarStyles['no-result-found']}>
                                <Col span={24}>No Results</Col>
                            </Row>
                        </div>
                    )}
                    className={`${topBarStyles['patient-search-input']}`}
                    id={"patient-search-1"}
                    options={sortedData?.map((petGroupedSearchResult, index) => ({
                        key: index,
                        label: (<PatientDropDown
                            petGroupedSearchResult={petGroupedSearchResult}
                            index={index} dataLength={sortedData?.length}
                            setSearchValueFunction={setValue}
                            patientSearchFunction={patientSearchDebounced}
                            setTempExpand={setTempExpand}
                        />
                        ),
                        value: petGroupedSearchResult?.hospital_id ?? "-"
                    })) ?? []}
                    dropdownClassName='options-patient-search'
                    placeholder={
                        <>
                            <SearchOutlined />
                            <span style={{ marginLeft: 4 }}>Search...</span>
                        </>
                    }
                    onSearch={(searchContent) => {
                        setValue(searchContent);
                        patientSearchDebounced(searchContent);
                    }}
                />
            </div>
    );
};
