import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width='inherit' height='inherit' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_34_2196)'>
            <path
                d='M10 0.833328C15.0626 0.833328 19.1667 4.93738 19.1667 9.99999C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.9374 19.1667 0.833344 15.0626 0.833344 9.99999C0.833344 4.93738 4.9374 0.833328 10 0.833328ZM10.2083 4.99999C8.6358 4.99999 7.3548 6.24823 7.3021 7.80794L7.30042 7.90791V9.59207C7.30042 10.6053 7.82203 11.5265 8.66068 12.0546L8.75516 12.1117L9.29516 12.4241C9.34659 12.4538 9.37829 12.5087 9.37836 12.5681L9.38025 13.982C9.38033 14.0436 9.34645 14.1002 9.29214 14.1292C7.76963 14.943 6.62775 15.5534 5.86649 15.9602C5.72189 16.0375 5.50499 16.1535 5.21579 16.3081C5.4959 16.5102 5.70971 16.6567 5.85721 16.7475C7.06234 17.489 8.48123 17.9167 10 17.9167C11.6061 17.9167 13.1004 17.4384 14.3485 16.6165C14.4846 16.5269 14.682 16.3834 14.9407 16.1861C14.6677 16.0389 14.463 15.9286 14.3265 15.855C13.6148 15.4715 12.5473 14.8963 11.1241 14.1293C11.07 14.1002 11.0364 14.0438 11.0364 13.9824L11.0383 12.5681C11.0384 12.5087 11.0701 12.4538 11.1215 12.4241L11.6615 12.1117C12.5234 11.613 13.0758 10.7103 13.1141 9.70426L13.1163 9.59207V7.90791C13.1163 6.30191 11.8143 4.99999 10.2083 4.99999ZM10 2.08333C5.62776 2.08333 2.08334 5.62774 2.08334 9.99999C2.08334 11.833 2.70628 13.5204 3.752 14.8623C3.83811 14.9728 3.96756 15.1254 4.14035 15.3201C4.19154 15.3777 4.27556 15.3927 4.34354 15.3564C4.57607 15.2321 4.75478 15.1366 4.87965 15.0699C5.60181 14.684 6.68505 14.1051 8.12938 13.3333L8.12919 13.1936C6.88649 12.4747 6.05042 11.131 6.05042 9.59207V7.90791C6.05042 5.61156 7.91198 3.74999 10.2083 3.74999C12.5047 3.74999 14.3663 5.61156 14.3663 7.90791V9.59207C14.3663 11.131 13.5302 12.4747 12.2875 13.1936L12.2873 13.3333C13.6457 14.0595 14.6646 14.6041 15.3438 14.9671C15.4487 15.0232 15.5973 15.1026 15.7898 15.2055C15.8593 15.2427 15.9452 15.2261 15.9959 15.1659C16.1705 14.9583 16.301 14.7957 16.3873 14.6781C17.3488 13.3675 17.9167 11.7501 17.9167 9.99999C17.9167 5.62774 14.3723 2.08333 10 2.08333Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_34_2196'>
                <rect width='20' height='20' fill='currentColor' />
            </clipPath>
        </defs>
    </svg>
);

export const ContactIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={svg} {...props} />;
