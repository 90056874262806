import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import DatePicker, { DEFAULT_DATE_TIME_FORMAT } from 'design/DatePicker';
import moment from 'moment';
import React from 'react';
import { dateIsInThePast, getDefaultStartTime } from 'utils/miscFuncs';

interface EstimateStartEndTimeFormItemsProps {
    startTimeFormFieldName: NamePath;
    endTimeFormFieldName: NamePath;
    isEditing?: boolean;
    startTimeFormFieldValue?: moment.Moment;
    endTimeFormFieldValue?: moment.Moment;
    startTimeLabel?: string;
    endTimeLabel?: string;
    hideMobileHeader?: boolean;
    disabled?: boolean;
}

const EstimateStartEndTimeFormItems: React.FC<EstimateStartEndTimeFormItemsProps> = ({
    endTimeFormFieldName,
    startTimeFormFieldName,
    endTimeFormFieldValue,
    isEditing,
    startTimeFormFieldValue,
    endTimeLabel,
    startTimeLabel,
    hideMobileHeader,
    disabled,
}) => {
    return (
        <>
            {!hideMobileHeader && (
                <div className='package-modal__mobile-header'>
                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                    Start Time
                </div>
            )}
            <Form.Item dependencies={['start_date', 'end_date']}>
                {() => (
                    <Form.Item
                        initialValue={getDefaultStartTime()}
                        label={startTimeLabel}
                        name={startTimeFormFieldName}
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        {isEditing ? (
                            <DatePicker disabledDate={dateIsInThePast} disabled={disabled} />
                        ) : (
                            <span>{(startTimeFormFieldValue ?? getDefaultStartTime())?.format(DEFAULT_DATE_TIME_FORMAT)}</span>
                        )}
                    </Form.Item>
                )}
            </Form.Item>

            {!hideMobileHeader && <div className='package-modal__mobile-header'>End Time</div>}
            <Form.Item label={endTimeLabel} dependencies={[startTimeFormFieldName, 'start_date', 'end_date']}>
                {({ getFieldValue }) => (
                    <Form.Item
                        name={endTimeFormFieldName}
                        initialValue={null}
                        rules={[
                            {
                                validator: (_, value) => {
                                    const startTime = getFieldValue(startTimeFormFieldName);

                                    if (!!value && value < startTime) {
                                        return Promise.reject(new Error('End time cannot be before start time'));
                                    }

                                    return Promise.resolve();
                                },
                            },
                        ]}
                        noStyle
                    >
                        {isEditing ? (
                            <DatePicker
                                disabledDate={(currentDate) => {
                                    const startTime = getFieldValue(startTimeFormFieldName);

                                    return dateIsInThePast(currentDate) || currentDate < startTime;
                                }}
                                disabled={disabled}
                            />
                        ) : (
                            <span>{endTimeFormFieldValue?.format(DEFAULT_DATE_TIME_FORMAT)}</span>
                        )}
                    </Form.Item>
                )}
            </Form.Item>
        </>
    );
};

export default EstimateStartEndTimeFormItems;
