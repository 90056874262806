import { Col, Form } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';
import NoteMacrosTextarea from 'components/lib/NoteEditor/NoteMacros';

interface FreeNoteProps {
    autoSaveFunction: () => void;
    name: string;
    noteRules?: Rule[];
    initialNoteValue?: string;
    form: FormInstance<any>;
}

export const FreeNote = ({ autoSaveFunction, name, noteRules, initialNoteValue, form }: FreeNoteProps) => {
    return (
        <Col span={23} style={{ padding: '0 var(--spacing-sm)', backgroundColor: '#55a2e133' }}>
            <div style={{ color: 'var(--gray-7)', paddingTop: '4px' }}>Note (optional):</div>
            <Form.Item
                key={name}
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                name={name}
                style={{ paddingTop: '4px', paddingBottom: '12px' }}
                rules={noteRules ?? []}
                initialValue={initialNoteValue}
            >
                <NoteMacrosTextarea
                    data-cy={'physicalExamNote'}
                    initialNoteValue={initialNoteValue}
                    key={name}
                    placeholder='Explain the observation'
                    rows={1}
                    style={{ width: '100%', maxWidth: '100%' }}
                    handleChange={(value) => {
                        if (value !== initialNoteValue) {
                            form.setFieldsValue({ [name]: value });
                            autoSaveFunction();
                        }
                    }}
                    autoFocus
                />
            </Form.Item>
        </Col>
    );
};
