import { convertConsentFormEstimateItemToLocal } from 'utils/dataTypes';
import {
	BurialConsentAcceptBody,
	BurialConsentForm,
	EstimateConsentForm,
	EuthanasiaConsentForm,
	MedicationConsentAcceptBody,
	MedicationConsentForm,
	RabiesVaccinationForm,
	SaveRabiesVaccinationForm,
} from 'utils/types/consentFormTypes';
import { TagType } from 'utils/types/enums';
import { consentApi } from './consentApi';
import { BurialApprovedForm } from '../utils/types/consentFormTypes';

// Define a service using a base URL and expected endpoints
export const billingService = consentApi.injectEndpoints({
	endpoints: (builder) => ({
		getEstimateForm: builder.query<EstimateConsentForm, string>({
			query: (FormId) => `form_link/b/${FormId}`,
			providesTags: (result, error, FormId) => [
				{ type: TagType.ConsentForm, FormId },
			],
			transformResponse: (response: { data: EstimateConsentForm }) => {
				const estimateData =  response.data;
                const estimateItems = estimateData.estimate_items.map(convertConsentFormEstimateItemToLocal)

				return {
					...estimateData,
					estimate_items: estimateItems
				}
			},
		}),
		getApprovedEstimateForm: builder.query<EstimateConsentForm, string>({
			query: (FormId) => `form_link/approved/b/${FormId}`,
			providesTags: (result, error, FormId) => [
				{ type: TagType.ConsentForm, FormId },
			],
			transformResponse: (response: { data: EstimateConsentForm }) => {
				const estimateData =  response.data;
                const estimateItems = estimateData.estimate_items.map(convertConsentFormEstimateItemToLocal)

				return {
					...estimateData,
					estimate_items: estimateItems
				}
			},
		}),
		getEuthanasiaForm: builder.query<EuthanasiaConsentForm, string>({
			query: (FormId) => `form_link/c/${FormId}`,
			providesTags: (result, error, FormId) => [
				{ type: TagType.ConsentForm, FormId },
			],
			transformResponse: (response: { data: EuthanasiaConsentForm }) => {
				return response.data;
			},
		}),
		getBurialForm: builder.query<BurialConsentForm, string>({
			query: (FormId) => `form_link/a/${FormId}`,
			providesTags: (result, error, FormId) => [
				{ type: TagType.ConsentForm, FormId },
			],
			transformResponse: (response: { data: BurialConsentForm }) => {
				return response.data;
			},
		}),
		getMedicationConsentForm: builder.query<MedicationConsentForm, string>({
			query: (formId) => `form_link/f/${formId}`,
			providesTags: (result, error, formId) => [
				{ type: TagType.ConsentForm, formId },
			],
			transformResponse: (response: { data: MedicationConsentForm }) => {
				return response.data;
			},
		}),
		getRabiesVaccinationForm: builder.query<RabiesVaccinationForm, string>({
			query: (formId) => `vaccination_form/${formId}`,
			providesTags: (result, error, formId) => [
				{ type: TagType.VaccinationForm, id: formId },
			],
			transformResponse: (response: { data: RabiesVaccinationForm }) => {
				return response.data;
			},
		}),
		verballyApproveEstimateForm: builder.mutation<
			null,
			{
				FormId: string
			}
		>({
			query: ({ FormId }) => {
				return {
					url: `form_link/verbally_approve/b/${FormId}`,
					method: 'POST',
					body: {},
				};
			},
			invalidatesTags: (result, error, { FormId }) => [
				{ type: TagType.ConsentForm, id: FormId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		approveEstimateForm: builder.mutation<
			null,
			{
				FormId: string;
				body: {checked_opt_in_marketing_email?: boolean}
			}
		>({
			query: ({ FormId, body }) => {
				return {
					url: `form_link/b/${FormId}`,
					method: 'POST',
					body,
				};
			},

			invalidatesTags: (result, error, { FormId }) => [
				{ type: TagType.ConsentForm, id: FormId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		approveEuthanasiaForm: builder.mutation<
			null,
			{
				FormId: string;
			}
		>({
			query: ({ FormId }) => {
				return {
					url: `form_link/c/${FormId}`,
					method: 'POST',
					body: {},
				};
			},

			invalidatesTags: (result, error, { FormId }) => [
				{ type: TagType.ConsentForm, id: FormId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		approveBurialForm: builder.mutation<
			null,
			{
				formId: string;
				body: BurialConsentAcceptBody;
			}
		>({
			query: ({ formId, body }) => {
				return {
					url: `form_link/a/${formId}`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: (result, error, { formId }) => [
				{ type: TagType.ConsentForm, id: formId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		approveMedicationConsentForm: builder.mutation<
			null,
			{
				formId: string;
				body: MedicationConsentAcceptBody;
			}
		>({
			query: ({ formId, body }) => {
				return {
					url: `form_link/f/${formId}/approve`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: (result, error, { formId }) => [
				{ type: TagType.ConsentForm, id: formId },
			],
			transformResponse: (response: { data: null }) => {
				return response.data;
			},
		}),
		getApprovedInfoBurialForms: builder.query<BurialApprovedForm[], number>({
			query: (visitId) => `/visit/${visitId}/form_link/a`,
			providesTags: (result, error, id) => [{ type: TagType.ConsentForm }],
			transformResponse: (response: { data: BurialApprovedForm[]}) => {
				return response.data;
			},
		}),
		saveRabiesVaccinationForm: builder.mutation<void, SaveRabiesVaccinationForm>({
			query: ({ form_id, ...body}) => ({
				url: `vaccination_form/${form_id}`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, { form_id }) => [
				{ type: TagType.VaccinationForm, id: form_id },
				{ type: TagType.ConsentForm, formId: form_id }
			]
		}),
	}),
});

export const {
	useApproveBurialFormMutation,
	useApproveEuthanasiaFormMutation,
	useApproveMedicationConsentFormMutation,
	useVerballyApproveEstimateFormMutation,
	useApproveEstimateFormMutation,
	useGetEstimateFormQuery,
	useGetApprovedEstimateFormQuery,
	useGetEuthanasiaFormQuery,
	useGetBurialFormQuery,
	useGetApprovedInfoBurialFormsQuery,
	useGetMedicationConsentFormQuery,
	useGetRabiesVaccinationFormQuery,
	useSaveRabiesVaccinationFormMutation,
} = billingService;
