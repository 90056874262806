import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Divider, Dropdown, Empty, Menu, Table, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetVisitByIdQuery } from 'services/visitService';
import {
    useDeleteWrittenPrescriptionMutation,
    useFinalizeAndPrintWrittenPrescriptionMutation,
    useListWrittenPrescriptionsQuery,
    usePrintWrittenPrescriptionMutation,
    useVoidWrittenPrescriptionMutation,
} from 'services/writtenPrescriptionService';
import { UserNameWrapper } from 'utils/componentWrappers';
import { COOKIE_DURATION } from 'utils/constants';
import { checkCookieExists } from 'utils/miscFuncs';
import './WrittenPrescription.css';
import WrittenPrescriptionForm from './WrittenPrescriptionForm';
import WrittenPrescriptionStatusTag from './WrittenPrescriptionStatusTag';

interface WrittenPrescriptionProps {
    isTabActive: boolean;
}

const WrittenPrescription: React.FC<WrittenPrescriptionProps> = ({ isTabActive }) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const doNotShowFinalizeAndPrint = useRef(true);
    const doNotShowVoid = useRef(true);

    const { addComposeBox } = useComposeBoxContext();

    const { data: writtenPrescriptions, refetch: refetchListWrittenPrescriptions } = useListWrittenPrescriptionsQuery(visitId);
    const { data: currentVisit } = useGetVisitByIdQuery(visitId, {
        skip: !visitId,
    });
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id || '', {
        skip: !currentVisit?.pet_id,
    });
    const { data: customer } = useGetCustomerByIdQuery(currentVisit?.customer_id || '', {
        skip: !currentVisit?.customer_id,
    });

    const [finalizeAndPrintWrittenPrescription, { isLoading: loadingFinalizeAndPrintWrittenPrescription }] =
        useFinalizeAndPrintWrittenPrescriptionMutation();
    const [voidWrittenPrescription] = useVoidWrittenPrescriptionMutation();
    const [deleteWrittenPrescription] = useDeleteWrittenPrescriptionMutation();
    const [printWrittenPrescription, { isLoading: loadingPrintWrittenPrescription }] = usePrintWrittenPrescriptionMutation();

    const openWrittenPrescriptionForm = (prescriptionId?: number, createCopy?: boolean) => {
        const composeBoxId = _.uniqueId();

        addComposeBox({
            content: <WrittenPrescriptionForm composeBoxId={composeBoxId} prescriptionId={prescriptionId} createCopy={createCopy} />,
            id: composeBoxId,
            title: 'Written Prescription',
        });
    };

    const finalizeAndPrint = (prescriptionId: number, medicationName: string) => {
        doNotShowFinalizeAndPrint.current = true;
        const doNotShowCookieName = 'do_not_show_finalize_and_print_written_prescription';
        const doNotShowCookie = `${doNotShowCookieName}=1;max-age=${COOKIE_DURATION}`;
        const successMessage = `${medicationName} prescription has been successfully finalized and downloaded.`;
        const errorMessage = `There was an error with ${medicationName}.`;

        if (checkCookieExists(doNotShowCookieName)) {
            finalizeAndPrintWrittenPrescription({
                visit_id: visitId,
                id: prescriptionId,
                petName: patientData?.name,
                ownerLastName: customer?.last_name,
            })
                .unwrap()
                .then(() => {
                    message.success(successMessage);
                })
                .catch(() => {
                    message.error(errorMessage);
                });
        } else {
            confirm({
                title: 'Finalize Written Prescription',
                icon: <ExclamationCircleOutlined style={{ color: 'var(--gold-6)' }} />,
                content: (
                    <>
                        <p>Marking a prescription as finalized will prevent any future edits.</p>
                        <Checkbox
                            defaultChecked={true}
                            onChange={(value) => {
                                doNotShowFinalizeAndPrint.current = value.target.checked;
                            }}
                        >
                            Do not show me this again
                        </Checkbox>
                    </>
                ),
                okText: 'Finalize and Download',
                okButtonProps: {
                    type: 'primary',
                },
                onOk: async () => {
                    if (doNotShowFinalizeAndPrint.current) {
                        document.cookie = doNotShowCookie;
                    }

                    const res = await finalizeAndPrintWrittenPrescription({
                        visit_id: visitId,
                        id: prescriptionId,
                        petName: patientData?.name,
                        ownerLastName: customer?.last_name,
                    });

                    if ('data' in res) {
                        message.success(successMessage);
                    } else {
                        message.error(errorMessage);
                    }
                },
            });
        }
    };

    const handleVoid = (prescriptionId: number, medicationName: string) => {
        doNotShowVoid.current = true;
        const doNotShowCookieName = 'do_not_show_void_written_prescription';
        const doNotShowCookie = `${doNotShowCookieName}=1;max-age=${COOKIE_DURATION}`;
        const successMessage = `${medicationName} prescription has been successfully voided.`;
        const errorMessage = `There was an error with ${medicationName}.`;

        if (checkCookieExists(doNotShowCookieName)) {
            voidWrittenPrescription({ visit_id: visitId, id: prescriptionId })
                .unwrap()
                .then(() => {
                    message.success(successMessage);
                })
                .catch(() => {
                    message.error(errorMessage);
                });
        } else {
            confirm({
                title: 'Void Prescription',
                icon: <ExclamationCircleOutlined style={{ color: 'var(--gold-6)' }} />,
                content: (
                    <>
                        <p>Marking “Void” indicates it is no longer your intent to provide this prescription to the customer.</p>
                        <Checkbox
                            defaultChecked={true}
                            onChange={(value) => {
                                doNotShowVoid.current = value.target.checked;
                            }}
                        >
                            Do not show me this again
                        </Checkbox>
                    </>
                ),
                okText: 'Void Prescription',
                okButtonProps: {
                    type: 'primary',
                },
                onOk: async () => {
                    if (doNotShowVoid.current) {
                        document.cookie = doNotShowCookie;
                    }

                    const res = await voidWrittenPrescription({ visit_id: visitId, id: prescriptionId });

                    if ('data' in res) {
                        message.success(successMessage);
                    } else {
                        message.error(errorMessage);
                    }
                },
            });
        }
    };

    const handleRemovePrescription = (id: number, medicationName: string) => {
        deleteWrittenPrescription({ visit_id: visitId, id })
            .unwrap()
            .then(() => {
                message.success(`${medicationName} prescription has been removed.`);
            })
            .catch(() => {
                message.success(`There was an error removing ${medicationName}.`);
            });
    };

    const handleRePrint = (id: number, medicationName: string) => {
        printWrittenPrescription({ id, visit_id: visitId, petName: patientData?.name, ownerLastName: customer?.last_name })
            .unwrap()
            .then(() => {
                message.success(`${medicationName} prescription re-downloaded.`);
            })
            .catch(() => {
                message.success(`There was an error downloading ${medicationName}.`);
            });
    };

    useEffect(() => {
        if (isTabActive) {
            refetchListWrittenPrescriptions();
        }
    }, [isTabActive]);

    return (
        <div className='written-prescription-container'>
            <Alert
                message='You are not legally allowed to write controlled drug prescriptions on this form, it is against VEG policy and DEA regulation.'
                type='error'
                showIcon
            />

            <Button
                onClick={() => {
                    openWrittenPrescriptionForm();
                }}
            >
                Add Written Prescription
            </Button>

            <Table
                tableLayout='fixed'
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No written prescriptions.' />,
                }}
                className='written-prescription-table'
                pagination={false}
                rowClassName={(record) => (record.status === 'void' ? 'written-prescription-container__voided' : '')}
                size='small'
                dataSource={writtenPrescriptions ?? []}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        width: '40%',
                        sorter: (a, b) => a.medication_name.localeCompare(b.medication_name),
                        render: (_, record) => {
                            return (
                                <div>
                                    <div>
                                        <span className='written-prescription-container__item written-prescription-container__medication-name'>
                                            {record.medication_name}
                                        </span>
                                        <WrittenPrescriptionStatusTag status={record.status} voidedAt={record.voided_at} />
                                    </div>
                                    <div className='written-prescription-container__item written-prescription-container__table-sub-header'>
                                        Instructions
                                    </div>
                                    <div className='written-prescription-container__item'>
                                        <Markdown remarkPlugins={[remarkBreaks]} children={record.instructions} />
                                    </div>
                                    <div className='written-prescription-container__item written-prescription-container__table-sub-header'>
                                        Prescribing Vet
                                    </div>
                                    <div className='written-prescription-container__item'>
                                        <UserNameWrapper>{record.prescribing_vet}</UserNameWrapper>
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        title: 'Duration',
                        dataIndex: 'duration',
                        key: 'duration_value',
                        width: '5%',
                        className: 'written-prescription-container__item',
                    },
                    {
                        title: 'Refill',
                        dataIndex: 'refills',
                        key: 'refill_value',
                        width: '5%',
                        className: 'written-prescription-container__item',
                    },
                    {
                        title: '',
                        dataIndex: '',
                        key: 'dropdown',
                        width: '5%',
                        render: (_, record) => {
                            return (
                                <Dropdown
                                    placement='bottomRight'
                                    overlay={() => {
                                        if (record.status === 'draft') {
                                            return (
                                                <Menu>
                                                    <Menu.Item
                                                        onClick={() => {
                                                            openWrittenPrescriptionForm(record.id);
                                                        }}
                                                    >
                                                        Edit
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        onClick={() => {
                                                            finalizeAndPrint(record.id, record.medication_name);
                                                        }}
                                                        disabled={loadingFinalizeAndPrintWrittenPrescription}
                                                    >
                                                        Finalize and Download
                                                    </Menu.Item>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <Menu.Item
                                                        className='written-prescription-container__danger-button'
                                                        onClick={() => {
                                                            handleRemovePrescription(record.id, record.medication_name);
                                                        }}
                                                    >
                                                        Remove
                                                    </Menu.Item>
                                                </Menu>
                                            );
                                        }

                                        if (record.status === 'finalized') {
                                            return (
                                                <Menu>
                                                    <Menu.Item
                                                        disabled={loadingPrintWrittenPrescription}
                                                        onClick={() => {
                                                            handleRePrint(record.id, record.medication_name);
                                                        }}
                                                    >
                                                        Download
                                                    </Menu.Item>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <Menu.Item
                                                        className='written-prescription-container__danger-button'
                                                        onClick={() => {
                                                            handleVoid(record.id, record.medication_name);
                                                        }}
                                                    >
                                                        Void
                                                    </Menu.Item>
                                                </Menu>
                                            );
                                        }

                                        if (record.status === 'void') {
                                            return (
                                                <Menu>
                                                    <Menu.Item
                                                        onClick={() => {
                                                            const createCopy = true;
                                                            openWrittenPrescriptionForm(record.id, createCopy);
                                                        }}
                                                    >
                                                        Copy
                                                    </Menu.Item>
                                                </Menu>
                                            );
                                        }

                                        return <Menu />;
                                    }}
                                >
                                    <MoreOutlined className='plus-dropdown' />
                                </Dropdown>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};

export default WrittenPrescription;
