import { Form, Input, Alert, FormInstance } from 'antd';


interface RdvmCustomDataProps {
    submit: (values: any) => void;
    form: FormInstance;
    override_display_name?: string | null;
    override_email?: string | null;
}

export const RdvmCustomData = (props: RdvmCustomDataProps) => {
    const { submit, form, override_display_name, override_email } = props;
    const warningText = (
        <span>
            <strong>The information provided is for this visit only.</strong>
            <br />
            Please submit a support request to include this rDVM as an option for future visits.
        </span>
    );

    return (
        <Form form={form} onFinish={submit} className='children-mt-16'>
            <div>Please provide a name and email address for medical records to be sent.</div>

            <Form.Item
                name='override_display_name'
                label='Clinic Name:'
                initialValue={override_display_name === null ? '' : override_display_name}
            >
                <Input placeholder='Please specify'/>
            </Form.Item>

            <Form.Item
                name='override_email'
                label='Clinic Email:'
                initialValue={override_email === null ? '' : override_email}
                rules={[
                    {
                        type: 'email',
                        message: 'Not a valid email',
                    },
                ]}
            >
                <Input placeholder='Please specify'/>
            </Form.Item>
            <Alert style={{ marginTop: '12px' }} message={warningText} type='warning' showIcon />
        </Form>
    );
};
