import {
    useGetBillingDataQuery,
    useGetVisitDiscountQuery,
} from 'services/billingService';
import { useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetBreedsQuery, useGetSpeciesQuery } from 'services/specieService';
import {
    useGetDischargeNoteByVisitIdQuery,
    useGetExamsByVisitIdQuery,
    useGetNotesByVisitIdQuery,
    useGetPatientHistoryByVisitIdQuery,
    useGetProblemListByVisitIdQuery,
    useGetVisitByIdQuery,
} from 'services/visitService';
import { VITAL_DIAGNOSTIC_IDS } from 'utils/constants';
import {
    checkIfVitalsAreCompleted,
    checkPatientInfo,
    checkVitalStatus,
    getLedgerValues,
    getRecentVitalsFromInstructions,
} from 'utils/miscFuncs';

const usePatientChecklist = ({ visit_id }: { visit_id: number }) => {
    const { data: progressNoteData } = useGetNotesByVisitIdQuery(visit_id);
    const { data: problemListData } = useGetProblemListByVisitIdQuery(visit_id);
    const { data: examData } = useGetExamsByVisitIdQuery(visit_id);
    const { data: dischargeNote } = useGetDischargeNoteByVisitIdQuery(visit_id);
    const { data: instData } = useGetInstructionsByVisitIdQuery({visitId: visit_id});
    const { data: billingData } = useGetBillingDataQuery({visitId: visit_id});
    const { data: patientHistory } =
        useGetPatientHistoryByVisitIdQuery(visit_id);
    const { data: visitDiscount } = useGetVisitDiscountQuery(visit_id);
    const { data: currentVisit } = useGetVisitByIdQuery(visit_id);
    const { data: patientData } = useGetPatientByIdQuery(
        currentVisit?.pet_id ?? '',
        { skip: !currentVisit?.pet_id },
    );
    const { data: species } = useGetSpeciesQuery(null);
    const { data: breeds } = useGetBreedsQuery(null);

    const { due } = getLedgerValues(
        billingData?.subtotal,
        billingData?.amount_paid,
        billingData?.tax,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents,
    );
    const vitalInstructions = getRecentVitalsFromInstructions(instData);

    const checkTPR = () => {
        const weightRegx = new RegExp(VITAL_DIAGNOSTIC_IDS[4], 'gi');

        return (
            (checkIfVitalsAreCompleted(vitalInstructions) &&
                vitalInstructions?.some(checkVitalStatus(weightRegx))) ??
            false
        );
    };

    return [
        {
            name: 'History',
            isMissing: !patientHistory?.content,
        },
        {
            name: 'Problems',
            isMissing: !problemListData?.problemList.some(
                (problem) => problem.presenting_complaint,
            ),
        },
        {
            name: 'Differentials',
            isMissing: problemListData?.diffList.length === 0,
        },
        {
            name: 'Recommendation note',
            isMissing: !progressNoteData?.some(
                (note) => note.category === 'Recommendation Note',
            ),
        },
        {
            name: 'Physical exam',
            isMissing: examData?.length === 0,
        },
        {
            name: 'Discharge note',
            isMissing: !dischargeNote?.content,
        },
        {
            name: 'TPR',
            isMissing: !checkTPR(),
        },
        {
            name: 'rDVM information',
            isMissing: !currentVisit?.rdvm_id,
        },
        {
            name: 'Patient information',
            isMissing: !checkPatientInfo(patientData, species, breeds),
        },
        {
            name: 'Customer information',
            isMissing: !currentVisit?.customer_id,
        },
        {
            name: 'Charges without doctor assigned',
            isMissing: billingData?.billing_items
                ?.filter((item) => item.unit_price_cents > 0)
                .some((item) => !item.doctor_id),
        },
        {
            name: 'Balance due',
            isMissing: due !== 0,
        },
    ];
};

export default usePatientChecklist;
