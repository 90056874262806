import { Spin, Button } from 'antd';
import { useGetEuthanasiaFormQuery } from 'services/consentService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { Header } from '../Header';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';

const ApprovedEuthanasiaForm = () => {
	const { urlFormId } = useParams<any>();
	const { data: euthanasiaData } = useGetEuthanasiaFormQuery(
		urlFormId,
		BASE_QUERY_OPTIONS,
	);
	if (!euthanasiaData) {
		return <Spin size='large' />;
	}
	return (
		<div className='single-page-element document-text approved-consent-form'>
			<div className='approved-consent-form__print-button'>
				<Button
					onClick={() => {
						window.print();
					}}
					type='primary'
				>
					Print
				</Button>
			</div>
			<div className='document' id='printableArea'>
				<Header
					title='Euthanasia Consent Form'
					headerData={{ ...euthanasiaData, pet_name: capitalizeFirstLetter(euthanasiaData.pet_name) }}
				/>
				<div className='single-page-element document-text'>
					<p>
						I, the undersigned, do hereby certify that I am the owner or
						the authorized agent for the owner of the pet described
						above, that I am 18 years of age or older, that I have the
						authority to execute this consent, and that I do hereby give
						complete authority to humanely euthanize{' '}
						{capitalizeFirstLetter(euthanasiaData.pet_name) + ' '}
						and release the veterinary facility and its owners,
						employees and staff from any and all claims and liability
						(including legal fees and other costs and expenses) for so
						euthanizing said pet. The nature of the euthanasia process
						has been explained to me, and I understand what will be done
						and that it is an irreversible and final process.
					</p>
					<p>
						I do also certify that to the best of my knowledge said pet
						has not bitten any person or animal during the last fifteen
						(15) days and has not been exposed to rabies.
					</p>
				</div>

				<div className='approved-consent-form__approved-at'>
					<CheckCircleOutlined style={{ color: 'var(--green-alert-success)', marginRight: 'var(--spacing-sm)' }} />
					Approved: {euthanasiaData.approved_date_hospital_timezone}
				</div>
			</div>
		</div>
	);
};

export default ApprovedEuthanasiaForm;
