import React, { MouseEvent, RefObject, useEffect } from 'react';

/**
 *
 * https://stackoverflow.com/a/42234988
 */
export const useOutsideClickAlert = (
	ref: RefObject<HTMLElement>,
	onOutsideClick?: () => void,
) => {
	useEffect(() => {
		const { current } = ref;
		/**
		 * Alert if clicked on outside of element
		 */
		const handleClickOutside = (event: MouseEvent) => {
			if (
				current &&
				event.target &&
				!current.contains(event.target as Node)
			) {
				//style check on `none` is for usage within TS Context-menu popovers
				if (current.style.display !== 'none' && onOutsideClick) {
					onOutsideClick();
				}
			}
		};

		// Bind the event listener
		document.addEventListener(
			'mousedown',
			handleClickOutside as any as EventListener,
		);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener(
				'mousedown',
				handleClickOutside as any as EventListener,
			);
		};
	}, [ref, onOutsideClick]);
};
