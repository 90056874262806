import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Row, Table } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
	useSearchComplaintsQuery,
	useSearchDifferentialsQuery,
} from '../../services/problemDiffService';
import { useGetProblemListByVisitIdQuery } from '../../services/visitService';
import { BASE_QUERY_OPTIONS } from '../../utils/constants';
import { Complaint, Differential, ProblemList } from '../../utils/dataTypes';
import { DifferentialMultiSelect } from './DifferentialMultiSelect';
import { SelectComplaint } from './SelectComplaint';

export const PROBLEM_DATE_FORMAT = 'ddd MMM Do';

export interface SingleComplaint {
	is_problem: boolean;
	id: number;
	complaint_id?: number; //only for existing problems
	start_date: Moment;
	end_date?: Moment; // only for existing problems
	common_name: string;
	chronic: boolean;
	presenting_complaint: boolean;
	differentials: number[];
}

interface DetailedAddMultiComplaintProps {
	complaintIds: number[];
}


const DetailedAddMultiComplaint: React.ForwardRefRenderFunction<
	HTMLInputElement,
	DetailedAddMultiComplaintProps
> = (props, ref) => {
	const { complaintIds } = props;
	const { urlVisitId } = useParams<{ urlVisitId: string }>();
	const visitId = parseInt(urlVisitId);
	const { data: complaintOptions } = useSearchComplaintsQuery(
		'',
		BASE_QUERY_OPTIONS,
	);
	const { data: differentials } = useSearchDifferentialsQuery(
		'',
		BASE_QUERY_OPTIONS,
	);

	const { data: problemListData } = useGetProblemListByVisitIdQuery(
		visitId,
		BASE_QUERY_OPTIONS,
	);
	const { problemList } = problemListData ?? {
		problemList: [],
		diffList: [],
	};
	const allProblems: ProblemList[] = problemList as ProblemList[];
	
	const [problemsIdsToAdd, setProblemsIdsToAdd] = useState<number[]>([])
	const [problemsIdsToDelete, setProblemsIdsToDelete] = useState<number[]>([])


	const setSelectedValue = (value: number) => {
		const selectedComplaints = [value]
		const newRows: SingleComplaint[] =
			selectedComplaints.map((complaintId) => ({
				is_problem: false,
				id: complaintId,
				common_name: complaintOptions?.find(
					(fullComplaint) =>
						fullComplaint.id ===
						complaintId,
				)?.common_name as string,
				start_date: moment(),
				chronic: false,
				presenting_complaint: allProblems.length === 0 ? true : false,
				differentials: complaintOptions
					?.find(
						(fullComplaint) =>
							fullComplaint.id ===
							complaintId,
					)
					?.differentials.map(
						(diff) => diff.id,
					) as number[],
			}));
		
		const toAdd = [...problemsIdsToAdd, ...newRows.map((singleComplait) => singleComplait.id)];

		setProblemsIdsToAdd(
			toAdd
		);

		const newState = [
			...complaintState,
			...newRows,
		];
		newState.sort(sortProblems);
		setComplaintState(newState);
	}

	const [complaintState, setComplaintState]: [SingleComplaint[], Function] =
		useState(
			complaintIds
				?.map((complaintId) => ({
					is_problem: false,
					id: complaintId,
					common_name: complaintOptions?.find(
						(fullComplaint) => fullComplaint.id === complaintId,
					)?.common_name as string,
					start_date: moment(),
					chronic: false,
					presenting_complaint: allProblems.length === 0 ? true : false,
					differentials: complaintOptions
						?.find(
							(fullComplaint) => fullComplaint.id === complaintId,
						)
						?.differentials.map((diff) => diff.id) as number[],
				}))
				.concat(
					allProblems?.map((problem) => ({
						is_problem: true,
						id: problem.id,
						complaint_id: problem.complaint_id,
						common_name: problem.common_name,
						start_date: moment(problem.start_date),
						end_date: problem.end_date
							? moment(problem.end_date)
							: undefined,
						chronic: problem.chronic,
						presenting_complaint: problem.presenting_complaint,
						differentials: problem?.differentials?.map(
							(diff) => diff.differential_id,
						), //TODO add DIFFERENTIALS when API gets updated
					})),
				),
		);

		const complaintsAlreadySelected = complaintState.filter(
			(complaint) => !complaint.is_problem).map((complaint) => complaint.id)
		const availableComplaints = useMemo(
			() =>
				complaintOptions?.filter(
					(complaint: Complaint) =>
						!(problemList as ProblemList[]).some(
							(curProb) => curProb.complaint_id === complaint.id,
						) &&
						!complaintsAlreadySelected?.includes(complaint.id),
				),
			[problemList, complaintOptions, complaintsAlreadySelected],
		);
	useEffect(() => {
		if (ref) {
			//@ts-ignore
			ref.current = {
				complaintState: complaintState,
				problemsIdsToDelete: problemsIdsToDelete,
				unsavedChanges: Boolean(problemsIdsToAdd.length || problemsIdsToDelete.length),
			};
		}
	}, [complaintState, ref]);

	return (
        <Row gutter={[12, 12]}>
            <Col span={12} offset={12}>
                <SelectComplaint
                    autoFocus
                    onSelect={setSelectedValue}
                    onDeselect={null}
                    onClear={null}
                    availableComplaints={availableComplaints}
                    mode={undefined}
                />
            </Col>
            <Col span={24}>
                <Table
                    tableLayout='fixed'
                    rowKey={(record) =>
                        `${record.is_problem ? 'problem' : 'complaint'}_${
                            record.id
                        }`
                    }
                    className='detailed-multi-complaint-table'
                    rowClassName={(record, index) =>
                        record.is_problem ? 'problem-row' : 'complaint-row'
                    }
                    pagination={false}
                    size='small'
                    dataSource={complaintState}
                    columns={[
                        {
                            title: 'Common name',
                            dataIndex: 'common_name',
                            key: 'common_name',
                            width: 5,
                        },
                        {
                            title: 'Presenting complaint',
                            dataIndex: 'presenting_complaint',
                            key: 'presenting_complaint',
                            width: 3,
                            render: (text, record) => (
                                <>
                                    <ChronicPresentingCheckbox
                                        text={text}
                                        record={record}
                                        complaintState={complaintState}
                                        setComplaintState={setComplaintState}
                                        fieldName='presenting_complaint'
                                    />
                                </>
                            ),
                        },
                        {
                            title: 'Chronic',
                            dataIndex: 'chronic',
                            key: 'chronic',
                            width: 2,
                            render: (text, record) => (
                                <>
                                    <ChronicPresentingCheckbox
                                        disabled={record.is_problem}
                                        text={text}
                                        record={record}
                                        complaintState={complaintState}
                                        setComplaintState={setComplaintState}
                                        fieldName='chronic'
                                    />
                                </>
                            ),
                        },
                        {
                            title: 'Start date', //TODO default to Today
                            dataIndex: 'start_date',
                            key: 'start_date',
                            width: 4,
                            render: (text, record) => {
                                return (
                                    <DatePicker
                                        allowClear={false}
                                        format={PROBLEM_DATE_FORMAT}
                                        value={record.start_date}
                                        onChange={(value) => {
                                            const modifiedComplaint =
                                                complaintState.find(
                                                    findComplaint(record),
                                                );

                                            if (modifiedComplaint) {
                                                const newState =
                                                    generateNewState(
                                                        modifiedComplaint,
                                                        'start_date',
                                                        moment(value),
                                                        complaintState,
                                                    );

                                                setComplaintState(newState);
                                            }
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: 'End date',
                            dataIndex: 'end_date',
                            key: 'end_date',
                            width: 4,
                            render: (text, record) => {
                                return (
                                    <DatePicker
                                        allowClear={false}
                                        format={PROBLEM_DATE_FORMAT}
                                        value={record.end_date}
                                        onChange={(value) => {
                                            const modifiedComplaint =
                                                complaintState.find(
                                                    findComplaint(record),
                                                );

                                            if (modifiedComplaint) {
                                                const newState =
                                                    generateNewState(
                                                        modifiedComplaint,
                                                        'end_date',
                                                        moment(value),
                                                        complaintState,
                                                    );

                                                setComplaintState(newState);
                                            }
                                        }}
                                    />
                                );
                            },
                        },

                        {
                            title: 'Differentials',
                            dataIndex: 'differentials',
                            key: 'differentials',
                            width: 10,
                            render: (text, record) => {
                                let differentialOptionList = [
                                    ...(differentials ?? []),
                                ];
                                let baseComplaint: Complaint & {
                                    existingDiffs?: Differential[];
                                } = record.is_problem
                                    ? ({
                                          ...complaintOptions?.find(
                                              (complaint) =>
                                                  complaint.id ===
                                                  record.complaint_id,
                                          ),
                                      } as Complaint)
                                    : ({
                                          ...complaintOptions?.find(
                                              (complaint) =>
                                                  complaint.id === record.id,
                                          ),
                                      } as Complaint);

                                if (record && record.id) {
                                    //Sort the `recommended` diffs to the top
                                    differentialOptionList.sort((a, b) => {
                                        return baseComplaint?.differentials?.some(
                                            (diff) => diff.id === a.id,
                                        )
                                            ? -1
                                            : 1;
                                    });
                                }
                                if (record.is_problem && record.differentials) {
                                    baseComplaint.existingDiffs =
                                        allProblems?.find(
                                            (problem) =>
                                                problem.id === record.id,
                                        )?.differentials;
                                }

                                return (
                                    <>
                                        <DifferentialMultiSelect
                                            style={{ width: '100%' }}
                                            differentialOptionList={
                                                differentialOptionList
                                            }
                                            baseComplaint={
                                                baseComplaint as Complaint
                                            }
                                            value={record.differentials}
                                            onSelect={(diff_id: number) => {
                                                const modifiedComplaint =
                                                    complaintState.find(
                                                        findComplaint(record),
                                                    );

                                                if (modifiedComplaint) {
                                                    const newDiffArray =
                                                        modifiedComplaint[
                                                            'differentials'
                                                        ]
                                                            ? [
                                                                  ...modifiedComplaint[
                                                                      'differentials'
                                                                  ],
                                                              ]
                                                            : [];

                                                    newDiffArray.push(diff_id);
                                                    const newState =
                                                        generateNewState(
                                                            modifiedComplaint,
                                                            'differentials',
                                                            newDiffArray,
                                                            complaintState,
                                                        );

                                                    setComplaintState(newState);
                                                }
                                            }}
                                            onDeselect={(diff_id: number) => {
                                                const modifiedComplaint =
                                                    complaintState.find(
                                                        findComplaint(record),
                                                    );

                                                if (modifiedComplaint) {
                                                    const newDiffArray = [
                                                        ...modifiedComplaint[
                                                            'differentials'
                                                        ],
                                                    ].filter(
                                                        (item) =>
                                                            item !== diff_id,
                                                    );

                                                    const newState =
                                                        generateNewState(
                                                            modifiedComplaint,
                                                            'differentials',
                                                            newDiffArray,
                                                            complaintState,
                                                        );

                                                    setComplaintState(newState);
                                                }
                                            }}
                                            onClear={(e: any) => {
                                                const modifiedComplaint =
                                                    complaintState.find(
                                                        findComplaint(record),
                                                    );

                                                if (modifiedComplaint) {
                                                    const newState =
                                                        generateNewState(
                                                            modifiedComplaint,
                                                            'differentials',
                                                            [],
                                                            complaintState,
                                                        );

                                                    setComplaintState(newState);
                                                }
                                            }}
                                        />
                                    </>
                                );
                            },
                        },
                        {
                            title: '',
                            dataIndex: '',
                            key: 'select',
                            width: 1,
                            render: (text, record) => (
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        const newRows = complaintState.filter(
                                            (complaint) =>
                                                complaint.id !== record.id ||
                                                (complaint.id === record.id &&
                                                    complaint.is_problem !==
                                                        record.is_problem),
                                        );

                                        if (record.is_problem) {
                                            // this is an existing problem, mark to delete
                                            const toDelete = [
                                                ...problemsIdsToDelete,
                                                ...[record.id],
                                            ];

                                            setProblemsIdsToDelete(toDelete);
                                        } else {
                                            // this is a problem to add, just remove from toAdd list
                                            setProblemsIdsToAdd(
                                                problemsIdsToAdd.filter(
                                                    (problem_id) =>
                                                        problem_id !==
                                                        record.id,
                                                ),
                                            );
                                        }

                                        newRows.sort(sortProblems);

                                        setComplaintState(newRows);
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </Col>
        </Row>
    );
};

export default React.forwardRef<any, any>(DetailedAddMultiComplaint);
interface ChronicPresentingCheckboxProps {
	text: any;
	record: SingleComplaint;
	complaintState: SingleComplaint[];
	setComplaintState: Function;
	fieldName: 'presenting_complaint' | 'chronic'; // THIS ACTUALLY is what differentiates the columns
	disabled?: boolean;
}
const ChronicPresentingCheckbox = ({
	text,
	record,
	complaintState,
	setComplaintState,
	fieldName,
	disabled,
}: ChronicPresentingCheckboxProps) => {
	return (
		<div title={disabled ? 'Cannot modify on existing problem' : ''}>
			<Checkbox
				disabled={disabled}
				checked={text}
				onChange={(e) => {
					const modifiedComplaint = complaintState.find(
						findComplaint(record),
					);

					if (modifiedComplaint) {
						const newState = generateNewState(
							modifiedComplaint,
							fieldName,
							e.target.checked,
							complaintState,
						);

						setComplaintState(newState);
					}
				}}
			/>
		</div>
	);
};

/**
 *
 * @param record Record we are trying to match against
 * @returns function that can be passed thru as the callaback to `.find()`
 */
const findComplaint =
	(record: SingleComplaint) => (complaint: SingleComplaint) =>
		complaint.id === record.id &&
		complaint.is_problem === record.is_problem;

/**
 * If NEW problem, (aka !is_problem), float to top of list
 * Then, alphabetical
 * @returns function that can be passed thru as the callback to `.sort()`
 */
const sortProblems = (a: SingleComplaint, b: SingleComplaint) => {
	if (a.is_problem && b.is_problem) {
		//Both are problems, sort alphabetically
		return a.common_name < b.common_name ? -1 : 1;
	} else if (a.is_problem) {
		//We know that ONLY A is a problem
		return 1;
	} else if (b.is_problem) {
		//We know that ONLY B is a problem
		return -1;
	} else {
		//Neither are problems, sort alphabetically
		return a.common_name < b.common_name ? -1 : 1;
	}
};

/**
 * @param modifiedComplaint Object to be modified
 * @param fieldName Field within object to be modified
 * @param newValue New value for field
 * @param complaintState Entire state of Complaints
 * @returns newState of Complaints
 */
const generateNewState = (
	modifiedComplaint: any,
	fieldName: string,
	newValue: any,
	complaintState: SingleComplaint[],
) => {
	modifiedComplaint[fieldName] = newValue;

	const itemPos = complaintState.findIndex(
		(complaint) =>
			complaint.id === modifiedComplaint.id &&
			complaint.is_problem === modifiedComplaint.is_problem,
	);

	const newState = complaintState.filter(
		(complaint) =>
			complaint.id !== modifiedComplaint.id ||
			complaint.is_problem !== modifiedComplaint.is_problem,
	);
	newState.splice(itemPos, 0, modifiedComplaint);
	return newState;
};
