import { Button, Drawer, Form, Radio, Row, Select, Space, Typography, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useModifyReferralSourceMutation } from 'services/visitService';
import { HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS } from 'utils/constants';
import { CustomerInfo, PatientRecordVisit } from 'utils/dataTypes';
import { capitalizeFirstLetter } from 'utils/stringFormatting';

interface ReferralSourceDrawerProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    currentVisit: PatientRecordVisit | undefined;
    customer: CustomerInfo | undefined;
}

export const ReferralSourceDrawer = ({ isOpen, setIsOpen, currentVisit, customer }: ReferralSourceDrawerProps) => {
    const [form] = Form.useForm();
    const [modifyReferralSource, { isLoading: isLoadingModifyReferralSource }] = useModifyReferralSourceMutation();

    const onFinish = (values: any) => {
        if (currentVisit && customer) {
            modifyReferralSource({
                visit_id: currentVisit?.id,
                body: {
                    customer_id: customer.customer_id,
                    how_did_you_hear_about_us: values.how_did_you_hear_about_us ?? null,
                    additional_info: values.additional_info ?? null,
                    referred_by_primary_vet: values.referred_by_primary_vet ?? null,
                },
            })
                .then((response: any) => {
                    if ('data' in response) {
                        message.success('Referral source successfully updated.');
                        setIsOpen(false);
                    } else {
                        message.error('There was an error editing the referral source.');
                    }
                })
                .catch(() => {
                    message.error('There was an error editing the referral source.');
                });
        }
    };

    const DrawerFooter = () => {
        return (
            <>
                <Button
                    type='default'
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    Cancel
                </Button>
                {currentVisit?.status === 'discharged' && (
                    <Button
                        type='primary'
                        onClick={() => {
                            form.submit();
                        }}
                        loading={isLoadingModifyReferralSource}
                    >
                        Save
                    </Button>
                )}
            </>
        );
    };

    const ReferralSourceForm = () => {
        if (!customer || !currentVisit) {
            return null;
        }
        return (
            <Form form={form} className='referral-source-modal' colon={false} labelCol={{ span: 24 }} onFinish={onFinish}>
                <>
                    {customer.visit_count === 1 && (
                        <>
                            <Form.Item
                                label='How did the customer hear about us?'
                                name='how_did_you_hear_about_us'
                                initialValue={customer.how_did_you_hear_about_us}
                                style={{ paddingTop: 0 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required.',
                                    },
                                ]}
                                data-cy="howDidYouHearAboutUsDropdown"
                            >
                                <Select<string>
                                    allowClear
                                    showSearch
                                    options={HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS.map((item) => {
                                        return { label: item, value: item };
                                    })}
                                    filterOption={(inputValue, option) =>
                                        (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            </Form.Item>
                            <Form.Item label='Additional info' name='additional_info' initialValue={customer.additional_info}>
                                <TextArea className='additional-info-textarea' />
                            </Form.Item>
                        </>
                    )}
                    {customer.visit_count > 1 && (
                        <Form.Item
                            label='Was the customer referred by their Primary Veterinarian for this visit?'
                            name='referred_by_primary_vet'
                            initialValue={currentVisit.referred_by_primary_vet}
                            className='referred-by-primary-vet'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required.',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value='yes'>Yes</Radio>
                                <Radio value='no'>No</Radio>
                                <Radio value='unknown'>Unknown</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                </>
            </Form>
        );
    };

    return (
        <Drawer
            title='Referral Source'
            className='veg-drawer referral-source-drawer'
            placement='right'
            open={isOpen}
            width={378}
            key='referral_source_drawer'
            onClose={() => {
                setIsOpen(false);
            }}
            footer={<DrawerFooter />}
        >
            {!customer && <Row className='primary-contact-message'>Please add a primary contact for this visit first.</Row>}
            {customer && (
                <>
                    {(currentVisit?.status === 'checked_out' || customer.visit_count > 1) && (
                        <>
                            {(customer.how_did_you_hear_about_us ||
                                customer.additional_info ||
                                (currentVisit?.status === 'checked_out' && currentVisit?.referred_by_primary_vet)) && (
                                <Space direction='vertical'>
                                    {customer.how_did_you_hear_about_us && (
                                        <>
                                            <Row>
                                                <Typography.Text strong>How did the customer hear about us?</Typography.Text>
                                            </Row>
                                            <Row>
                                                {customer.how_did_you_hear_about_us === 'Other (Please tell us below!)'
                                                    ? 'Other'
                                                    : customer.how_did_you_hear_about_us}
                                            </Row>
                                        </>
                                    )}
                                    {customer.additional_info && (
                                        <>
                                            <Row>
                                                <Typography.Text strong>Additional info</Typography.Text>
                                            </Row>
                                            <Row className='additional-info'>{customer.additional_info}</Row>
                                        </>
                                    )}
                                    {currentVisit?.status === 'checked_out' && currentVisit?.referred_by_primary_vet && (
                                        <>
                                            <Row>
                                                <Typography.Text strong>
                                                    Was the customer referred by their Primary Veterinarian for this visit?
                                                </Typography.Text>
                                            </Row>
                                            <Row>{capitalizeFirstLetter(currentVisit?.referred_by_primary_vet ?? '')}</Row>
                                        </>
                                    )}
                                </Space>
                            )}
                        </>
                    )}
                    {currentVisit?.status !== 'checked_out' && <ReferralSourceForm />}
                </>
            )}
        </Drawer>
    );
};
