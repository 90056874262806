import { CSSProperties, useEffect, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { BASE_QUERY_OPTIONS, INSTRUCTION_FREQUENCY_REGEX } from 'utils/constants';
import { PimsUser } from 'utils/dataTypes';
import { TaskEstimateFormFields } from 'utils/types/billingTypes';
import { isInstanceOfPackageTaskInstruction, PackageTaskInstruction, TaskSearchOption } from 'utils/types/InstructionOrderTypes';
import { TaskEstimateRules } from 'utils/types/validations';
import { HiddenInput } from '../fields/HiddenInput';
import { MedicationFrequency } from '../MedicineOrder';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';






export const getTaskEstimateDefaults = (
    dataSource: TaskSearchOption | PackageTaskInstruction,
    user?: PimsUser
): Partial<TaskEstimateFormFields> => {
    let defaultInstruction: Partial<TaskEstimateFormFields>;
    if (isInstanceOfPackageTaskInstruction(dataSource)) {
        defaultInstruction = {
            task_id: dataSource.task_id,
            type_id: dataSource.type_id,
            unit_cost_cents: dataSource.cents,
            name: dataSource.name,
            frequency: dataSource?.frequency ?? '',
            is_prn: false,
            prn_condition: null,
            priority: false,
            unit: "unit",
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null
        }
    } else {
        defaultInstruction = {
            task_id: dataSource.id,
            type_id: dataSource.type_id,
            unit_cost_cents: dataSource.cents,
            name: dataSource.name,
            frequency: dataSource?.default_frequency || '',
            is_prn: false,
            prn_condition: null,
            priority: false,
            unit: "unit",
            is_free: false,
            reason: null,
            ordered_by: user?.user_id || 0,
            notes: null,
            supplemental_cost_cents: null,
            is_recurring_supplemental: null

        }
    }
    return defaultInstruction;
};
export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';
interface TaskEstimateFormProps {
    dataSource: TaskSearchOption | PackageTaskInstruction;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    getFormData?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    task: TaskSearchOption;
    durationHours?: number;
}
export const TaskEstimateForm = (props: TaskEstimateFormProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );

    const { dataSource, task, durationHours } = props;

    const isPackageInstruction = isInstanceOfPackageTaskInstruction(dataSource)


    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 18 };

    const [isSerialCheaper, setIsSerialCheaper] = useState(false)

    const onFormChange = (changes: any) => {  
        let serial = false   
        if (props?.getFormData){
            const fields = {
                ...props?.getFormData(),
                ...changes
            };
            serial = checkIfSerialCheaper(task, fields.frequency, 0, (durationHours ?? 0) * 3600)
            setIsSerialCheaper(serial)
        }
        if (props?.onFormChange){ 
            let unit_cost_cents;
            let unit = "unit";
            if (serial){
                unit_cost_cents = task.price_cents_serial ?? 0;
                unit = "hours";
            }else{
                unit_cost_cents = task.cents;
            }
            return props?.onFormChange({...changes, serial, unit_cost_cents, unit})
        } else {
            return (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
        }
    }

    useEffect(() => {
        const defaultTask = getTaskEstimateDefaults(dataSource, loggedInUserData);
        onFormChange(defaultTask)
    }, [dataSource]);

    const taskEstimateRules: TaskEstimateRules = {
        frequency: [{ required: true, pattern: INSTRUCTION_FREQUENCY_REGEX, }],
    }

    return (
        <>
            {isSerialCheaper && <AlertSerialPrice serialHours={task.serial_hours ?? 0} inForm />}
            <MedicationFrequency
                autoFocus
                rules={taskEstimateRules.frequency}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                onFormChange={onFormChange}
            />
            <HiddenInput fieldName='task_id' />
            <HiddenInput fieldName='type_id' />
            <HiddenInput fieldName='unit_cost_cents' />
            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='prn_condition' />
            <HiddenInput fieldName='unit' />
            <HiddenInput fieldName='is_free' />
            <HiddenInput fieldName='reason' />
            <HiddenInput fieldName='ordered_by' />
            <HiddenInput fieldName='notes' />
            <HiddenInput fieldName='name' />
            <HiddenInput fieldName='supplemental_cost_cents' />
            <HiddenInput fieldName='is_recurring_supplemental' />
            <HiddenInput fieldName='priority' />
            <HiddenInput fieldName='serial'/>
            <HiddenInput fieldName='serial_hours' initialValue={task.serial_hours} />

            {/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and TaskOrder */}
        </>
    );
};
