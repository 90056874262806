import { useState } from 'react'
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { Button, Table, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { formatCurrency } from "utils/formatFuncs"
import { LedgerItem } from "utils/types/billingTypes"
import moment from 'moment';
import './billing_panel.css'
import { PaymentType, PAYMENT_TYPE_NAME } from "utils/types/paymentTypes"
import { convertBasisToDecimal } from 'utils/miscFuncs'

export const generateLedgerDisplayData = (ledgerItems: LedgerItem[]): {
    key: string | number,
    date: string,
    form_of_payment: PaymentType,
    card_brand: string | null,
    last_4_digits: string | null,
    amount_cents: number,
    children: {
        key: string | number,
        id: number,
        date: string,
        payment_type_display: string,
        form_of_payment: PaymentType,
        card_brand: string | null,
        last_4_digits: string | null,
        amount_cents: number,
    }[]
}[] => {
    return ledgerItems.filter(item => item.refunded_from === null && item.donation_basis && item.donation_basis > 0).map((parentItem, index) => {        
        const donationAmount = convertBasisToDecimal(parentItem.donation_basis) * parentItem.amount_cents;
        const paymentRow = {
            key: index,
            id: parentItem.id,
            date: moment.unix(parentItem.created_at).format('MM/DD/YYYY hh:mm a'),
            payment_type_display: "Payment",
            form_of_payment: parentItem.form_of_payment,
            card_brand: parentItem.last_4_digits,
            last_4_digits: parentItem.last_4_digits,
            amount_cents: donationAmount,
        };
        
        return {
            key: index,
            id: undefined,
            date: "",
            form_of_payment: parentItem.form_of_payment,
            card_brand: parentItem.card_brand,
            last_4_digits: parentItem.last_4_digits,
            amount_cents: donationAmount,
            children: [paymentRow]
        }
    });
}

const generateLedgerColumns = (
   
): ColumnsType<{
    key: string | number,
    date: string,
    form_of_payment: PaymentType,
    card_brand: string | null,
    last_4_digits: string | null,
    amount_cents: number,
    children: {
        key: string | number,
        id: number,
        date: string,
        payment_type_display: string,
        form_of_payment: PaymentType,
        card_brand: string | null,
        last_4_digits: string | null,
        amount_cents: number,
    }[]
}> => {
    return [
        {
            title: '',
            width: '5%',
            key: 'select',
            render: (_, record) => <></>
        },
        {
            title: 'Payment Type',
            width: '35%',
            key: 'payment_type_display',
            dataIndex: 'payment_type_display',
            render(value, record, index) {
                if (value) {
                    return value;
                }
                if (record.form_of_payment === "stripe_terminal" || record.form_of_payment === "stripe_link") {
                    return (
                        <>
                            <span style={{textTransform: "uppercase"}}>{record.card_brand || "Stripe"}</span> ...{record.last_4_digits}
                            <Typography.Text type='secondary' style={{fontWeight: "normal"}}> ({record.form_of_payment === "stripe_terminal" ? "Terminal" : "Link"})</Typography.Text>
                        </>
                    )
                }
                return PAYMENT_TYPE_NAME[record.form_of_payment];
            }
        },
        {
            title: 'Date/Time',
            width: '45%',
            key: 'date',
            dataIndex: 'date',
        },
        {
            title: 'Amount',
            width: '10%',
            key: 'amount_cents',
            dataIndex: 'amount_cents',
            align: 'right',
            render(value) {
                return formatCurrency(value)
            }
        },
        {
            title: '',
            width: '5%',
            key: 'refund',
            dataIndex: 'refund',
            align: 'center',
            render(value, record, index) {
                return <></>
            }
        },
        
    ]
}

interface DonationsTableProps {
    visitId: number;
    ledgerItems: LedgerItem[];
}
export const DonationsTable = ({ visitId, ledgerItems }: DonationsTableProps) => {
    const columns = generateLedgerColumns();
    const dataSource = generateLedgerDisplayData(ledgerItems);

    return (
        <>
            <Table
                className="billing-table"
                columns={columns}
                dataSource={dataSource}
                scroll={{
                    x: true,
                }}
                pagination={false}
                expandIcon={({ expanded, onExpand, record }) => {
                    if (!record.children) {
                        return <></>
                    }
                    if (expanded) {
                        return <UpOutlined
                            onClick={(e) => onExpand(record, e)}
                        />
                    }
                    else {
                        return <DownOutlined
                            onClick={(e) => onExpand(record, e)}
                        />
                    }
                }}
            >
            </Table>
        </>
    )
}
