import { EllipsisOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Dropdown, Menu, Typography, message } from 'antd';
import { NotesDrawerRef } from 'components/NotesDrawer';
import { warningConfirm } from 'components/confirmModals';
import moment from 'moment';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useDeletePhysicalExamMutation, useGetVisitByIdQuery, useValidateEditExamMutation } from 'services/visitService';
import { FormName, PHYSICAL_EXAM_VALUES } from '../../utils/constants';
import { Exam } from '../../utils/dataTypes';
import { ExamCardSection } from './ExamSection';
import './RecentExamCard.css';

const VITAL_RECORDING_FORMAT = 'ddd MMM Do, h:mma';
interface PreviousPhysicalExamProps {
    exam: Exam;
    isFinalizedVisit?: boolean;
    isNotesPage?: boolean;
    openEditNotesDrawer?: NotesDrawerRef['openEditNotesDrawer'];
}

export const RecentExamCard = ({ exam, isFinalizedVisit, openEditNotesDrawer }: PreviousPhysicalExamProps) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const abnormalSections = exam.sections.filter((examSection) => examSection.status === PHYSICAL_EXAM_VALUES['ABNORMAL']);
    const normalSections = exam.sections.filter((examSection) => examSection.status === PHYSICAL_EXAM_VALUES['NORMAL']);
    const noObservationSections = exam.sections.filter((examSection) => examSection.status === PHYSICAL_EXAM_VALUES['NOT_OBSERVED']);

    const { data: currentVisit } = useGetVisitByIdQuery(visitId, { skip: !visitId });
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? '', { skip: !currentVisit?.pet_id });
    const [deletePhysicalExam] = useDeletePhysicalExamMutation();
    const [validateEditExam] = useValidateEditExamMutation();

    const handleEdit = () => {
        validateEditExam({ examId: +exam.id })
            .unwrap()
            .then(() => {
                if (openEditNotesDrawer) {
                    openEditNotesDrawer({
                        formType: FormName.physical_exam,
                        examId: +exam.id,
                    });
                }
            })
            .catch((err) => {
                if (err.originalStatus === 403) {
                    message.error(err.data);
                }
            });
    };

    const handleDelete = () => {
        warningConfirm(
            {
                title: 'Delete Physical Exam',
                content: `Are you sure you want to remove this exam from ${patientData?.name}'s visit?`,
                onOk: async () => {
                    const res = await deletePhysicalExam({ visitId, id: parseInt(exam.id) });

                    if ('data' in res) {
                        message.success('The exam has been successfully removed.');
                    } else {
                        if ('originalStatus' in res.error && res.error.originalStatus === 403) {
                            message.error(res.error.data);
                        } else {
                            message.error('There was an error deleting the exam.');
                        }
                    }
                },
            },
            { okText: 'Remove' },
        );
    };

    return (
        <Card
            title={
                <div className='physical-exam-card-title'>
                    <Avatar
                        style={{
                            backgroundColor: 'var(--veg-secondary-gold)',
                        }}
                    >
                        PE
                    </Avatar>

                    <div className='physical-exam-details'>
                        <Typography.Text>Physical Exam</Typography.Text>

                        <div>
                            <span>{exam.author_name}</span>
                            <span className='physical-exam-details__separator'>{' | '}</span>
                            <span className='physical-exam-details__time'>
                                {moment.unix(exam.note_date_time).format(VITAL_RECORDING_FORMAT)}
                            </span>
                        </div>
                    </div>
                </div>
            }
            extra={
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item onClick={handleEdit} disabled={isFinalizedVisit}>
                                Edit
                            </Menu.Item>
                            <Menu.Item
                                onClick={handleDelete}
                                disabled={isFinalizedVisit}
                                style={{ color: 'var(--red-5)', opacity: isFinalizedVisit ? '.5' : '1' }}
                            >
                                Remove
                            </Menu.Item>
                        </Menu>
                    }
                    placement='bottomRight'
                    arrow
                >
                    <Button style={{ padding: '0 var(--spacing-sm)' }}>
                        <EllipsisOutlined style={{ transform: 'rotate(90deg)', height: '14px' }} />
                    </Button>
                </Dropdown>
            }
        >
            <div className='physical-exam-content'>
                {exam.note && exam.note !== '' && (
                    <div className='observation_header' style={{ marginBottom: '4px' }}>
                        <div className='phys-exam-note'>
                            <b>Comments:</b>
                            <Markdown remarkPlugins={[remarkBreaks]} children={exam.note} />
                        </div>
                    </div>
                )}

                <ExamCardSection examSections={normalSections} sectionType={'NORMAL'} />
                <ExamCardSection examSections={noObservationSections} sectionType={'NOT_OBSERVED'} />
                <ExamCardSection examSections={abnormalSections} sectionType={'ABNORMAL'} />
            </div>
        </Card>
    );
};
