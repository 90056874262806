import { Radio, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useApproveMedicationConsentFormMutation, useGetMedicationConsentFormQuery } from 'services/consentService';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { IFormProps } from './ConsentForms';
import PrintableForm from './PrintableForm';
import { ConsentFormSuccess } from './Result';

const MedicationConsentForm: React.FC<IFormProps> = ({ formId }) => {
    const [approved, setApproved] = useState(false);
    const checkedValue = useRef(1);

    const { data: medicationConsentData } = useGetMedicationConsentFormQuery(formId);
    const [approveMedicationConsentForm] = useApproveMedicationConsentFormMutation();

    const onApprove = () => {
        approveMedicationConsentForm({ formId, body: { chosen_option: checkedValue.current } })
            .unwrap()
            .then(() => {
                setApproved(true);
            });
    };

    useEffect(() => {
        if (medicationConsentData?.is_approved) {
            setApproved(true);
        }
    }, [medicationConsentData]);

    if (!medicationConsentData) {
        return <Spin size='large' />;
    }

    if (approved) {
        return <ConsentFormSuccess subTitle='We have successfully received your signed consent form.' />;
    }

    const petNameUppercase = capitalizeFirstLetter(medicationConsentData.pet_name);

    return (
        <PrintableForm
            title='Dispensed Medication Consent Form'
            headerData={{ ...medicationConsentData, pet_name: petNameUppercase }}
            onApprove={onApprove}
            approved={medicationConsentData?.is_approved || approved}
            approveText='I acknowledge that I have read the terms of this agreement.'
            acceptButtonText='I agree'
            printMessage='For a copy of this agreement, please print prior to accepting the terms.'
        >
            <div className='single-page-element document-text'>
                <p>
                    For each medication prescribed or dispensed from Veterinary Emergency Group, I have been offered counseling on the
                    following:
                </p>

                <ol>
                    <li>The name and description of the prescription drug.</li>
                    <li>
                        Route of administration, dosage form, dosage, duration of drug therapy, the duration of the side effects of the
                        drug, and the common severe adverse effects.
                    </li>
                    <li>Any special directions for proper use and storage.</li>
                    <li>Actions to be taken in the event of a missed dose.</li>
                </ol>

                <p>Please choose one of the following options:</p>

                <ul style={{ listStyle: 'none', marginBottom: '64px' }}>
                    <Radio.Group disabled={medicationConsentData.is_approved} onChange={(e) => (checkedValue.current = e.target.value)}>
                        <li>
                            <Radio value={1}>
                                I have had the opportunity to discuss the above information with a veterinarian or technician and do not
                                have further questions.
                            </Radio>
                        </li>
                        <li>
                            <Radio value={2}>I decline counseling and do not require any additional information.</Radio>
                        </li>
                        <li>
                            <Radio value={3}>I request printed information on each medication being prescribed.</Radio>
                        </li>
                    </Radio.Group>
                </ul>

                <div className='medication-consent-form__signature-container'>
                    <span style={{ marginRight: '200px' }}>Owner Signature</span>
                    <span>Date</span>
                </div>
            </div>
        </PrintableForm>
    );
};

export default MedicationConsentForm;
