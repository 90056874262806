import { useEffect, useState } from 'react'
import { Row, Col, Modal, Input, Form, Button, Spin } from "antd"
import { formatCurrency, roundTo } from 'utils/formatFuncs'
import { FormWrapper } from 'components/forms/FormWrapper';
import { SUPPORTED_PAYMENT_AMOUNT_REGEX } from 'utils/constants';
import { useRefundPaymentMutation } from 'services/paymentService';
import { validatePaymentAmountInput } from 'utils/miscFuncs';
interface RefundPaymentModalProps {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    maxAmountCents: number;
    visitId: number;
    refundFrom: number | undefined;
    isFetchingBillingData: boolean;
}

export const RefundPaymentModal = ({
    isVisible,
    setIsVisible,
    maxAmountCents,
    visitId,
    refundFrom,
    isFetchingBillingData
}: RefundPaymentModalProps) => {
    const [amountCents, setAmountCents] = useState(0);
    const [isSent, setIsSent] = useState(false);
    const [isError, setIsError] = useState(false);
    const [refundPayment, { isLoading, isSuccess, isError: _isError, data }] = useRefundPaymentMutation();
    const [form] = Form.useForm();
    
    useEffect(() => {
        if(isSuccess) {
            setIsSent(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        setIsError(_isError);
    }, [_isError]);

    const ModalContent = () => {
        return (
            <>
                {isError &&
                    <p>Payment can not be refunded.</p>
                }
                {!isError &&
                    <>
                       {isSent &&
                        <p>Payment refunded.</p>
                       }
                       {!isSent &&
                            <Row>
                                <Col>
                                    <RefundForm />
                                </Col>
                            </Row>
                       }
                    </>
                }
            </>
        )
    };

    const ModalFooter = () => {
        return (
            <>
                {!isError && !isSent &&
                    <>
                        <Button type="default" onClick={() => {
                                setIsVisible(false);
                                setAmountCents(0);
                            }}>
                                Cancel
                            </Button>
                        <Button type="primary" autoFocus onClick={form.submit} loading={isLoading}>
                            Refund
                        </Button>
                    </>
                }
                
                {isError &&
                    <>
                        <Button type="default" autoFocus onClick={() => {
                            setIsError(false);
                        }}>
                            Retry
                        </Button>
                        <Button type="primary" autoFocus onClick={() => {
                            setIsVisible(false);
                            setAmountCents(0);
                        }}>
                            Close
                        </Button>
                    </>
                }
                
                {isSent &&
                    <>
                        <Button type="primary" autoFocus onClick={() => {
                            setIsVisible(false);
                            setIsSent(false);
                            setAmountCents(0);
                        }}>
                            Close
                        </Button>
                    </>
                }
            </>
        )
    };

    const RefundForm = () => {
        return (
            <FormWrapper
                form={form}
                onFormChange={form.setFieldsValue}
                onFinishFailed={() => {
                    
                }}
                onFinish={(values: any) => {
                    if (!refundFrom) {
                        return;
                    }
                    const amountCents = roundTo(parseFloat(values.amount.replaceAll(",", "")) * 100, 0);
                    setAmountCents(amountCents);
                    refundPayment({
                        visitId: visitId,
                        body: {
                            ledger_id: refundFrom,
                            amount_cents: amountCents,
                        }
                    });
                }}
                bottomBar={<></>}
            >
                <Form.Item
                    name='amount'
                    label='Amount'
                    className="payment-amount-input"
                    style={{width: '100%'}}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 12 }}
                    initialValue={formatCurrency(amountCents || 0).replace('$', '')}
                    validateFirst={true}
                    rules={[
                        { required: true, message: 'Please enter an amount' },
                        { pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: "Invalid amount" },
                        () => ({
                            validator(_, value: string) {
                                return validatePaymentAmountInput(value, maxAmountCents, false)                                      
                            }
                        })
                    ]}
                >
                    <Input 
                        addonBefore={"$"}
                        disabled={false}
                        style={{textAlign: "right"}}
                    />
                </Form.Item>
            </FormWrapper>
        )
    };

    return(
        <Modal
            confirmLoading={isFetchingBillingData}
            centered={true}
            open={isVisible}
            title={"Refund Payment"}
            footer={<ModalFooter />}
            destroyOnClose
            onCancel={() => {
                if(isSent) {
                  setIsSent(false);
                }
                setIsVisible(false);
                setAmountCents(0);
            }}
            onOk={form.submit}>
            <Spin spinning={isFetchingBillingData}>
                <div style={{visibility: isFetchingBillingData ? 'hidden' : 'visible'}}>
                    <ModalContent />
                </div>
            </Spin>
        </Modal>
    )
}
