import { Modal, Typography, Button, Row, Col, Select } from 'antd';
import { useState, useEffect } from 'react';
import { DiagnosticCard } from 'utils/dataTypes';
import { ClosedDiagnostics } from './ClosedDiagnostics';
import { OpenDiagnostics } from './OpenDiagnostics';
import OverviewTabStyles from './overview-tab.module.css';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActiveDiagnosticsModal } from 'store/slices/treatmentSheetSlice';


interface DiagnosticModelProps {
    openDiagnostics?: DiagnosticCard[];
    closedDiagnostics: DiagnosticCard[];
}

interface Diagnostic {
    label: string;
    value: string;
}
export const DiagnosticModel = ({
    openDiagnostics,
    closedDiagnostics,
}: DiagnosticModelProps) => {
    const appDispatch = useAppDispatch();
    const { activeDiagnosticsModal } = useAppSelector((state) => state.treatmentSheetSlice);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const showCompleteModal = activeDiagnosticsModal === "complete" && openDiagnostics
    const tableClosedDiagnostics = showCompleteModal ? closedDiagnostics : closedDiagnostics.filter(diagnostic => diagnostic.existingInstruction.category !== "Vitals")
    const diagnosticsList = showCompleteModal ? [...openDiagnostics, ...tableClosedDiagnostics] : [...tableClosedDiagnostics]
    const allDiagnostics = diagnosticsList.reduce<Diagnostic[]>((prev, curr) => {
            return !prev.some((item) => item.label === curr?.name)
                ? [
                      ...prev,
                      {
                          value: curr?.name,
                          label: curr?.name,
                      },
                  ]
                : prev;
        }, []);

    useEffect(() => {
      setSelectedItems([])
    }, [activeDiagnosticsModal]);

    return (
        <>
            <Modal
                title={
                    <Typography.Text
                        className={OverviewTabStyles['ModalTitle']}
                    >
                        Diagnostics
                    </Typography.Text>
                }
                onOk={() => appDispatch(setActiveDiagnosticsModal(null))}
                visible={!!activeDiagnosticsModal}
                width={showCompleteModal ? '90%' : '50%'}
                onCancel={() => appDispatch(setActiveDiagnosticsModal(null))}
                destroyOnClose
                footer={
                    <Button autoFocus type='primary' onClick={() => appDispatch(setActiveDiagnosticsModal(null))}>
                        Close
                    </Button>
                }
                data-cy={'diagnosticsModal'}
                className='diagnostics-modal'
            >

                <Row>
                  <Select
                    mode="multiple"
                    placeholder="Filter by diagnostic"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    allowClear={true}
                    style={{ width: showCompleteModal ? '25%' : '50%' }}
                    options={allDiagnostics}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </Row>

                <Row gutter={60} style={{ height: '450px' }}>
                    {showCompleteModal && (
                        <Col span={24} lg={{ span: 12 }}>
                            <OpenDiagnostics
                                diagnostics={
                                selectedItems.length > 0
                                ? openDiagnostics.filter(diagnostic =>
                                    selectedItems.includes(diagnostic?.name))
                                : openDiagnostics
                                }
                                showFullTable
                            />
                        </Col>
                    )}
                    <Col span={24} lg={{ span: showCompleteModal ? 12 : 24 }}>
                        <ClosedDiagnostics
                            diagnostics={
                              selectedItems.length > 0
                              ? tableClosedDiagnostics.filter(diagnostic =>
                                selectedItems.includes(diagnostic?.name))
                              : tableClosedDiagnostics
                            }
                            showFullTable
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
