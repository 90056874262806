import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, FormInstance, Tooltip } from 'antd';
import DatePicker from 'design/DatePicker';
import moment from 'moment';
import { useRef, useState } from 'react';

interface DatePickerWithHoursProps {
    newTime: moment.Moment | null;
    setNewTime: React.Dispatch<React.SetStateAction<moment.Moment | null>> | ((date: moment.Moment | null) => void);
    fieldName: string;
    form?: FormInstance;
    dateLimit?: moment.Moment;
}

export const DatePickerWithHours = ({ form, newTime, setNewTime, fieldName, dateLimit }: DatePickerWithHoursProps) => {
    const [isMinusOneHourButtonDisabled, setIsMinusOneHourButtonDisabled] = useState(true);

    const plusMinusButtonContainerRef = useRef<HTMLSpanElement | null>(null);
    const BORDER_WIDTH = '2px';
    const DEFAULT_BUTTON_CONTAINER_WIDTH = 93;

    const plusMinusHours = (type: 'plus' | 'minus', previousTime: moment.Moment) => {
        const timeToAdd = type === 'plus' ? 1 : -1;
        const newTime = moment(previousTime).add(timeToAdd, 'hour');

        setNewTimeField(newTime);
    };

    const dateIsInThePast = (currentDate: moment.Moment) => {
        return currentDate.isBefore(dateLimit ?? moment());
    };

    const setNewTimeField = (date: moment.Moment | null) => {
        const dateMinusOneHour = moment(date).subtract(1, 'hour');
        setIsMinusOneHourButtonDisabled(dateIsInThePast(dateMinusOneHour));

        setNewTime(date);
        form?.setFieldsValue({ newTime: date });
        form?.validateFields([fieldName]);
    };

    return (
        <>
            <DatePicker
                disabledDate={dateIsInThePast}
                style={{
                    width: `calc(100% - var(--spacing-sm) - ${BORDER_WIDTH} - ${plusMinusButtonContainerRef.current?.offsetWidth ?? DEFAULT_BUTTON_CONTAINER_WIDTH}px)`,
                }}
                suffixIcon={<ClockCircleOutlined />}
                value={newTime}
                onChange={setNewTimeField}
                autoFocus
            />

            <span className='reschedule-action-form__new-time-funcs' ref={plusMinusButtonContainerRef}>
                <Tooltip title={isMinusOneHourButtonDisabled ? 'New time cannot be in the past' : null}>
                    <Button
                        onClick={() => plusMinusHours('minus', newTime || moment())}
                        disabled={isMinusOneHourButtonDisabled || !newTime}
                    >
                        - 1hr
                    </Button>
                </Tooltip>
                <Button onClick={() => plusMinusHours('plus', newTime || moment())} disabled={!newTime}>
                    + 1hr
                </Button>
            </span>
        </>
    );
};
