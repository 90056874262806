import { AlertFilled } from '@ant-design/icons';
import { Form, FormInstance, Input, Radio, Select, Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';
import MedicationDoseConfirmation from 'components/forms/MedicationDoseAlert/MedicationDoseConfirmation';
import PackageItemTitleContainer from 'components/forms/PackageFormModal/PackageItemTitleContainer';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetInstructionOptionsQuery, useGetMedicationRoutesQuery } from 'services/instructionService';
import { useLazyGetSupplementalByNameQuery } from 'services/supplementalService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import { roundTo } from 'utils/formatFuncs';
import { getMedicationAlertDescription, getMedicationConcentration, getDenominatorValue } from 'utils/miscFuncs';
import { BaseSearchOption, MedicineSearchOption, isInstanceOfMedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import { MedicalEstimateItemNew } from 'utils/types/estimateTypesNew';
import MedicationData from 'views/visit/TreatmentSheet/MedicationData';
import MedicationDoseAlert from '../../forms/MedicationDoseAlert';
import EditLineButton from '../../forms/PackageFormModal/EditLineButton';
import { HiddenInput } from '../../forms/fields/HiddenInput';
import { EstimateFormInstance } from '../index';
import EstimateFrequencyFormItem from './EstimateFrequencyFormItem';
import EstimateInstructionFormItem from './EstimateInstructionFormItem';
import './EstimateMedicationForm.css';
import EstimateStartEndTimeFormItems from './EstimateStartEndTimeFormItems';

interface EstimateMedicationFormProps {
    medication: MedicalEstimateItemNew;
    form: FormInstance<EstimateFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
    currentPatientWeight?: number;
}

const getMedicineOption = (medication: MedicalEstimateItemNew, searchOptions?: BaseSearchOption[]): MedicineSearchOption | undefined => {
    if (!searchOptions) {
        return undefined;
    }

    return searchOptions?.find((option) => {
        return option.id === medication.medication_id && isInstanceOfMedicineSearchOption(option);
    }) as MedicineSearchOption | undefined;
};

const EstimateMedicationForm: React.FC<EstimateMedicationFormProps> = ({
    medication,
    form,
    index,
    hiddenSectionClass,
    setActiveLineItem,
    activeLineItem,
    currentPatientWeight,
}) => {
    const { data: searchOptions } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);
    const medicineOption = getMedicineOption(medication, searchOptions);

    const [hasError, setHasError] = useState(false);
    const lineId = `M-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const { data: routeOptions } = useGetMedicationRoutesQuery(null);

    const [getSupplemental, { data: supplementalData }] = useLazyGetSupplementalByNameQuery();

    const medConcentration = useMemo(() => (medicineOption ? getMedicationConcentration(medicineOption) ?? 1 : 1), [medicineOption]);

    const medFields = [
        ['medications', index, 'numerator_value'],
        ['medications', index, 'denominator_value'],
        ['medications', index, 'dose_per_kg'],
        ['medications', index, 'dose_acknowledge'],
        ['medications', index, 'route_id'],
        ['medications', index, 'frequency'],
        ['medications', index, 'end_time'],
        ['medications', index, 'start_time'],
        ['medications', index, 'instruction'],
    ];
    const medErrorArray = form.getFieldsError(medFields);

    const [isSerialCheaper, setIsSerialCheaper] = useState(false);

    const setSerial = (changes: { [key in string]: string | SelectValue | number }) => {
        if (!medicineOption) {
            return;
        }
        const fieldsValue = form.getFieldsValue();
        const medicationFields = {
            ...fieldsValue.medications[index],
            ...changes,
        };
        const dose = getDose(medicationFields.numerator_value);

        const startTime = (medicationFields?.start_time ?? fieldsValue.start_time)?.unix();
        const endTime = (medicationFields?.end_time ?? fieldsValue.end_time)?.unix();

        const serial = checkIfSerialCheaper(
            medicineOption,
            medicationFields.frequency,
            startTime,
            endTime,
            medicationFields.supplemental_cost_cents,
            dose,
        );
        const unit_cost_cents = serial ? medicineOption.price_cents_serial ?? 0 : medicineOption.cents ?? 0;
        setIsSerialCheaper(serial);
        form.setFieldsValue({
            ...fieldsValue,
            medications: fieldsValue.medications.map((diag: any, indx: number) => {
                return indx === index ? { ...diag, ...changes, serial, unit_cost_cents } : diag;
            }),
        });
    };

    const hasMedicationDoseAlert = (patientWeight: number, dose: number) => {
        if (!patientWeight || patientWeight <= 0) return;

        const { dose_unit, low_dose_alert, high_dose_alert } = medication;

        const dosePerKG = roundTo(dose / patientWeight, 3);
        const roundedLowDoseAlert = roundTo(low_dose_alert, 3);
        const roundedHighDoseAlert = roundTo(high_dose_alert, 3);
        return getMedicationAlertDescription(dosePerKG, dose_unit, roundedLowDoseAlert, roundedHighDoseAlert);
    };

    const getDose = (numeratorValue: number) => {
        return medication.dose_unit === medicineOption?.numerator_unit ? numeratorValue : numeratorValue / medConcentration;
    };

    const getDosePerKg = (numerator_value: number, dose_unit: string, patientWeight: number) => {
        const dose = getDose(numerator_value);

        if (dose_unit === medicineOption?.numerator_unit) {
            return roundTo(dose / patientWeight, 3);
        } else if (dose_unit === medicineOption?.denominator_unit) {
            return roundTo((dose * medConcentration) / patientWeight, 3);
        } else {
            return roundTo(dose, 3);
        }
    };


    const setMedicationFieldValue = (value: any) => {
        const fieldsValue = form.getFieldsValue();

        if (fieldsValue.medications) {
            form.setFieldsValue({
                ...fieldsValue,
                medications: fieldsValue.medications.map((med: any, indx: number) => {
                    return indx === index ? { ...med, ...value } : med;
                }),
            });
        }
        setSerial({});
    };

    const handleShouldUpdate = (prev: EstimateFormInstance, curr: EstimateFormInstance) => {
        if (prev.patient_weight !== curr.patient_weight) {
            handleUpdateDose();
        }

        return (
            prev.patient_weight !== curr.patient_weight ||
            prev.medications[index].frequency !== curr.medications[index].frequency ||
            prev.medications[index].route_id !== curr.medications[index].route_id ||
            prev.medications[index].numerator_value !== curr.medications[index].numerator_value ||
            prev.medications[index].start_time !== curr.medications[index].start_time ||
            prev.medications[index].end_time !== curr.medications[index].end_time ||
            prev.medications[index].action !== curr.medications[index].action
        );
    };

    const handleUpdateDose = () => {
        if (!medicineOption) {
            return;
        }
        const patientWeight = currentPatientWeight ?? medication.approx_patient_weight_kg ?? medication.latest_patient_weight_kg;

        if (!patientWeight) {
            return;
        }

        const { dose, dose_unit } = medication;
        const { numerator_unit, denominator_unit } = medicineOption;

        const numeratorValue = numerator_unit === dose_unit ? dose : dose * medConcentration;
        const denominatorValue = denominator_unit === dose_unit ? dose : dose / medConcentration;
        const dosePerKg = numeratorValue / patientWeight;

        setMedicationFieldValue({
            numerator_value: roundTo(numeratorValue, 3),
            denominator_value: roundTo(denominatorValue, 3),
            dose_per_kg: roundTo(dosePerKg, 3),
        });
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['medications', index, 'numerator_value'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (medErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, medErrorArray]);

    useEffect(() => {
        if (currentPatientWeight) {
            handleUpdateDose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPatientWeight]);

    useEffect(() => {
        if (medicineOption) {
            handleUpdateDose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicineOption]);

    useEffect(() => {
        if (supplementalData) {
            setMedicationFieldValue({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    useEffect(() => {
        if (medication.route_id) {
            getSupplemental({ name: medication.route_id, controlledMedication: medication.controlled_drug });
        }
    }, [medication.route_id]);

    if (!medicineOption) {
        return null;
    }

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const patientWeight =
                    currentPatientWeight ?? medication.approx_patient_weight_kg ?? medication.latest_patient_weight_kg ?? 0;
                const numeratorValue = roundTo(getFieldValue(['medications', index, 'numerator_value']), 3);
                const dose = getDose(numeratorValue);
                const complexMedFieldsValidation =
                    medicineOption?.calculator_type === 'complex'
                        ? !getFieldValue(['medications', index, 'denominator_value']) ||
                          !getFieldValue(['medications', index, 'dose_per_kg'])
                        : false;
                const initialError =
                    !getFieldValue(['medications', index, 'numerator_value']) ||
                    complexMedFieldsValidation ||
                    !(getFieldValue(['medications', index, 'route_id']) ?? medication.route_id) ||
                    !(getFieldValue(['medications', index, 'frequency']) ?? medication.frequency);
                const hasMedicationAlert = hasMedicationDoseAlert(patientWeight, dose);
                const serialName = isSerialCheaper ? `${medication.name} - Serial ${medicineOption?.serial_hours} Hours` : medication.name;

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing && getFieldValue(['medications', index, 'action']) === 0) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['medications', index, 'id']} initialValue={medication.medication_id} />
                            <HiddenInput fieldName={['medications', index, 'dose_unit']} initialValue={medication.dose_unit} />
                            <HiddenInput
                                fieldName={['medications', index, 'calculator_type']}
                                initialValue={medicineOption?.calculator_type}
                            />
                            <HiddenInput
                                fieldName={['medications', index, 'numerator_unit']}
                                initialValue={medicineOption?.numerator_unit}
                            />
                            <HiddenInput
                                fieldName={['medications', index, 'denominator_unit']}
                                initialValue={medicineOption?.denominator_unit}
                            />
                            <HiddenInput fieldName={['medications', index, 'concentration']} initialValue={medConcentration} />
                            <HiddenInput fieldName={['medications', index, 'unit_cost_cents']} initialValue={medicineOption?.cents} />
                            <HiddenInput fieldName={['medications', index, 'type_id']} initialValue={medication.type_id} />
                            <HiddenInput fieldName={['medications', index, 'name']} initialValue={medication.name} />
                            <HiddenInput fieldName={['medications', index, 'controlled_drug']} initialValue={medication.controlled_drug} />
                            <HiddenInput fieldName={['medications', index, 'supplemental_cost_cents']} initialValue={null} />
                            <HiddenInput fieldName={['medications', index, 'is_recurring_supplemental']} initialValue={null} />
                            <HiddenInput fieldName={['medications', index, 'required']} initialValue={false} />
                            <HiddenInput fieldName={['medications', index, 'instruction_id']} initialValue={medication.instruction_id} />
                            <HiddenInput fieldName={['medications', index, 'is_prn']} initialValue={medication.is_prn} />
                            <HiddenInput fieldName={['medications', index, 'prn_condition']} initialValue={medication.prn_condition} />

                            <HiddenInput fieldName={['medications', index, 'serial']} initialValue={isSerialCheaper} />
                            <HiddenInput fieldName={['medications', index, 'serial_hours']} initialValue={medication.serial_hours} />
                            <HiddenInput fieldName={['medications', index, 'is_free']} initialValue={medication.is_free} />

                            <HiddenInput fieldName={['medications', index, 'priority']} initialValue={false} />
                            <HiddenInput
                                fieldName={['medications', index, 'approx_patient_weight_kg']}
                                initialValue={medication.approx_patient_weight_kg}
                            />
                            <HiddenInput
                                fieldName={['medications', index, 'latest_patient_weight_kg']}
                                initialValue={medication.latest_patient_weight_kg}
                            />
                        </div>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer no_charge={medication.is_free} checked>
                                <Tooltip title={serialName.length > 45 ? serialName : null}>
                                    <span className='treatment-sheet-side-column__title'>{serialName}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>

                            <div className='package-medication-container'>
                                <MedicationData
                                    group={{
                                        ...medication,
                                        frequency: getFieldValue(['medications', index, 'frequency']) ?? medication.frequency ?? '',
                                        route_id: getFieldValue(['medications', index, 'route_id']) ?? medication.route_id ?? '',
                                        dose: dose ?? medicineOption?.default_dose ?? medication.dose,
                                    }}
                                    instructionIcon={<></>}
                                    patientWeight={patientWeight > 0 ? patientWeight : undefined}
                                />

                                {!!dose && !!hasMedicationAlert && (
                                    <Tooltip
                                        className='package-medication__dose-alert-icon'
                                        title={'The dose is not within the recommended range.'}
                                    >
                                        <AlertFilled style={{ color: 'var(--veg-secondary-red)' }} />
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Transfer Options
                        </div>

                        <div className='package-medication-dose__inputs'>
                            <Form.Item name={['medications', index, 'action']} initialValue={0}>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Transfer</Radio.Button>
                                    <Radio.Button value={1}>Exclude</Radio.Button>
                                    <Radio.Button value={2}>Save for Later</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Dose
                        </div>
                        <div>
                            {medicineOption?.calculator_type === 'complex' ? (
                                <div className='package-medication-dose__inputs'>
                                    <Tooltip title={(!patientWeight || patientWeight <= 0) && isEditing ? 'Patient weight required' : null}>
                                        <Form.Item
                                            name={['medications', index, 'numerator_value']}
                                            initialValue={medication.dose}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (isNaN(value)) {
                                                            return Promise.reject('Required');
                                                        }

                                                        if (value <= 0) {
                                                            return Promise.reject('Cannot be smaller or equal to 0');
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            {isEditing ? (
                                                <Input
                                                    disabled={
                                                        !patientWeight ||
                                                        patientWeight <= 0 ||
                                                        getFieldValue(['medications', index, 'action']) !== 0
                                                    }
                                                    suffix={medicineOption?.numerator_unit}
                                                    min={0}
                                                    onChange={(e) => {
                                                        const value = parseFloat(e.target.value);
                                                        if (value === numeratorValue  || isNaN(value)) {
                                                            return;
                                                        }


                                                        setMedicationFieldValue({
                                                            numerator_value: e.target.value,
                                                            denominator_value: roundTo(value / medConcentration, 3),
                                                            dose_per_kg: roundTo(value / patientWeight, 3),
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    {numeratorValue ?? '-'}
                                                    {medicineOption?.numerator_unit}
                                                </span>
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            name={['medications', index, 'denominator_value']}
                                            initialValue={medication.dose}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (isNaN(value)) {
                                                            return Promise.reject('Required');
                                                        }

                                                        if (value <= 0) {
                                                            return Promise.reject('Cannot be smaller or equal to 0');
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            {isEditing ? (
                                                <Input
                                                    disabled={
                                                        !patientWeight ||
                                                        patientWeight <= 0 ||
                                                        getFieldValue(['medications', index, 'action']) !== 0
                                                    }
                                                    suffix={medicineOption?.denominator_unit}
                                                    min={0}
                                                    onChange={(e) => {
                                                        const value = parseFloat(e.target.value);

                                                        if (value === getDenominatorValue(numeratorValue, medConcentration)   || isNaN(value)) {
                                                            return;
                                                        }
                                                        setMedicationFieldValue({
                                                            numerator_value: roundTo(value * medConcentration, 3),
                                                            denominator_value: e.target.value,
                                                            dose_per_kg: roundTo((value * medConcentration) / patientWeight, 3),
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    {roundTo(getFieldValue(['medications', index, 'denominator_value']), 3) ?? '-'}{' '}
                                                    {medicineOption?.denominator_unit}
                                                </span>
                                            )}
                                        </Form.Item>

                                        <Form.Item
                                            name={['medications', index, 'dose_per_kg']}
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (isNaN(value)) {
                                                            return Promise.reject('Required');
                                                        }

                                                        if (value <= 0) {
                                                            return Promise.reject('Cannot be smaller or equal to 0');
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            {isEditing ? (
                                                <Input
                                                    disabled={
                                                        !patientWeight ||
                                                        patientWeight <= 0 ||
                                                        getFieldValue(['medications', index, 'action']) !== 0
                                                    }
                                                    suffix={`${medicineOption?.numerator_unit}/kg`}
                                                    min={0}
                                                    onChange={(e) => {
                                                        const value = parseFloat(e.target.value);
                                                        if (value === getDosePerKg(numeratorValue, medication.dose_unit, patientWeight)  || isNaN(value)) {
                                                            return;
                                                        }

                                                        setMedicationFieldValue({
                                                            numerator_value: roundTo(value * patientWeight, 3),
                                                            denominator_value: roundTo((value * patientWeight) / medConcentration, 3),
                                                            dose_per_kg: e.target.value,
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    {getFieldValue(['medications', index, 'dose_per_kg']) ?? '-'}
                                                    {`${medicineOption?.numerator_unit}/kg`}
                                                </span>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </div>
                            ) : (
                                <Form.Item
                                    name={['medications', index, 'numerator_value']}
                                    initialValue={medication.dose}
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (isNaN(value)) {
                                                    return Promise.reject('Required');
                                                }

                                                if (value <= 0) {
                                                    return Promise.reject('Cannot be smaller or equal to 0');
                                                }

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    {isEditing ? (
                                        <Input
                                            suffix={medication.dose_unit}
                                            disabled={getFieldValue(['medications', index, 'action']) !== 0}
                                        />
                                    ) : (
                                        <span>
                                            {numeratorValue ?? '-'}
                                            {medication.dose_unit}
                                        </span>
                                    )}
                                </Form.Item>
                            )}
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Route
                        </div>
                        <Form.Item
                            name={['medications', index, 'route_id']}
                            initialValue={medication.route_id}
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            {isEditing ? (
                                <Select
                                    showSearch
                                    options={routeOptions?.map((route: any) => ({
                                        key: route.name,
                                        value: route.id,
                                        label: route.id,
                                    }))}
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().includes(input.toLowerCase()) ||
                                        (option?.key as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        getSupplemental({ name: `${value}` });
                                    }}
                                    disabled={getFieldValue(['medications', index, 'action']) !== 0}
                                />
                            ) : (
                                <span>{getFieldValue(['medications', index, 'route_id']) ?? medication.route_id}</span>
                            )}
                        </Form.Item>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Frequency
                        </div>
                        <EstimateFrequencyFormItem
                            formFieldName={['medications', index, 'frequency']}
                            initialValue={medication.frequency}
                            isEditing={isEditing}
                            formFieldValue={getFieldValue(['medications', index, 'frequency']) ?? medication.frequency}
                            disabled={getFieldValue(['medications', index, 'action']) !== 0}
                            onFormChange={(changes) => {
                                setSerial(changes);
                            }}
                        />

                        <EstimateStartEndTimeFormItems
                            endTimeFormFieldName={['medications', index, 'end_time']}
                            startTimeFormFieldName={['medications', index, 'start_time']}
                            isEditing={isEditing}
                            endTimeFormFieldValue={getFieldValue(['medications', index, 'end_time'])}
                            startTimeFormFieldValue={getFieldValue(['medications', index, 'start_time'])}
                            disabled={getFieldValue(['medications', index, 'action']) !== 0}
                        />

                        <EstimateInstructionFormItem
                            formFieldName={['medications', index, 'instruction']}
                            initialValue={null}
                            isEditing={isEditing}
                            formFieldValue={getFieldValue(['medications', index, 'instruction'])}
                            disabled={getFieldValue(['medications', index, 'action']) !== 0}
                        />

                        {isEditing && isSerialCheaper ? (
                            <div
                                className={`serial-alert-column-transfer-medication ${
                                    !isEditing || !isSerialCheaper ? 'serial-alert-column-transfer--hide' : ''
                                }`}
                            >
                                <AlertSerialPrice serialHours={medicineOption?.serial_hours ?? 0} />
                            </div>
                        ) : (
                            <div className='package-medication__empty-column' />
                        )}
                        <div className={`package-medication__dose-alert ${!isEditing ? 'package-medication__dose-alert--hide' : ''}`}>
                            <MedicationDoseAlert
                                formName={['medications', index, 'dose_acknowledge']}
                                doseUnit={medication.dose_unit}
                                dose={getDose(numeratorValue)}
                                highDoseAlert={medication.high_dose_alert}
                                lowDoseAlert={medication.low_dose_alert}
                                patientWeight={patientWeight > 0 ? patientWeight : null}
                                medication={medicineOption}
                                alertDirection='horizontal'
                                hideConfirmation
                            />
                            {hasMedicationAlert && <MedicationDoseConfirmation formName={['medications', index, 'dose_acknowledge']} />}
                        </div>
                        <EditLineButton
                            disabled={isEditing}
                            hasError={hasError || initialError}
                            onClick={() => {
                                if (!hasError) setActiveLineItem(lineId);
                            }}
                        />
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default EstimateMedicationForm;
