import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoginMutation } from 'services/authService';
import { JSONifyQueryString } from 'utils/formatFuncs';
import { ROUTES } from 'utils/types/enums';
import loginStyles from './login.module.css';

interface LoginPageProps { }

export const LoginPage: React.FC<LoginPageProps> = () => {
	const history = useHistory();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [login, { error: loginError, isLoading, isSuccess }] = useLoginMutation();
	let { search } = useLocation();

	useEffect(() => {
		if (isSuccess) {
			let queries = JSONifyQueryString<{ redirect: string }>(search);
			history.push(queries.redirect ?? ROUTES.dashboard);
		}
	}, [isSuccess]);

	return (
		<div className={loginStyles.loginWrapper}>
			<Form
				className={loginStyles.form}
				onFinish={() => {
					login({ username, password });
				}}
			>
				<div className={loginStyles.userAvatar}>
					<UserOutlined />
				</div>
				<Form.Item
					name='Username'
					label='Username'
					labelCol={{ span: 7 }}
					wrapperCol={{ span: 17 }}
				>
					<Input
						data-cy="username"
						placeholder='Username'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					name='Password'
					label='Password'
					labelCol={{ span: 7 }}
					wrapperCol={{ span: 17 }}
				>
					<Input.Password
						data-cy="password"
						placeholder='Password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</Form.Item>
				{loginError && (
					<h3 style={{ color: 'red' }}>Invalid credentials</h3>
				)}
				<p>{isLoading}</p>
				<Form.Item>
					<Button data-cy="submitLoginButton" type='primary' htmlType='submit' loading={isLoading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
