import { Collapse, Select, Spin, Tooltip } from 'antd';
import CustomCollapse from 'components/CustomCollapse';
import { ElementRef, useCallback, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetCustomerPetsQuery } from 'services/admissionService';
import { formatCurrency } from 'utils/formatFuncs';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import CreatePatientNoteDrawer from 'views/patient/VisitsAndNotes/CreatePatientNoteDrawer';
import PatientItems from 'views/patient/VisitsAndNotes/PatientItems';
import './CustomerPets.css';
import {ReactComponent as PawIcon} from 'assets/img/Paw.svg';

const { Panel } = Collapse;

const CustomerPets = () => {
    const { customerId } = useParams<{ customerId: string }>();
    const createPatientNoteRef = useRef<ElementRef<typeof CreatePatientNoteDrawer>>(null);
    const [balance, setBalance] = useState<{ [key: number]: number }>({});
    const [selectedPets, setSelectedPets] = useState<string[]>([]);
    const [selectedBalanceFilter, setSelectedBalanceFilter] = useState<string[]>([]);

    const { data: pets } = useGetCustomerPetsQuery(customerId, { skip: !customerId, refetchOnMountOrArgChange: true });

    const getTotalBalance = () => Object.values(balance).reduce((prev, curr) => prev + curr, 0);

    const defaultActiveKey = useMemo(() => pets?.map((pet) => pet.pet_id), [pets]);
    const petItems = useMemo(
        () =>
            pets
                ?.filter((pet) => !selectedPets.length || selectedPets.includes(pet.name))
                ?.map((pet) => (
                    <Panel
                        header={
                            <div className='customer-pets__pet-name'>
                                <span>{capitalizeFirstLetter(pet.name)}</span>
                                <Tooltip title='View Patient Profile'>
                                    <Link to={`/patient/${pet.pet_id}`} className='customer-pets__pet-page-link'>
                                        <PawIcon
                                            color='#595959'
                                            width={'16px'}
                                            height={'16px'}
                                        />
                                    </Link>
                                </Tooltip>
                            </div>
                        }
                        key={pet.pet_id}
                    >
                        <PatientItems
                            pet_id={pet.pet_id}
                            filterVisitBalance={selectedBalanceFilter}
                            setNoteToUpdate={(note) => createPatientNoteRef.current?.setNoteToUpdate(note)}
                            sumVisitBalance={(visitId: number, value: number) => {
                                setBalance((bal) => ({ ...bal, [visitId]: value }));
                            }}
                        />
                    </Panel>
                )),
        [pets, selectedPets, selectedBalanceFilter],
    );
    const filterOptions = useMemo(
        () =>
            pets?.map((pet) => ({
                value: pet.name,
                label: pet.name,
            })),
        [pets],
    );

    if (!pets) return <Spin />;

    return (
        <section className='customer-pets'>
            <div className='customer-pets__header'>
                <h1 data-cy={"customerHeaderTitle"}>Patients</h1>

                <span>
                    <span>Balance due </span>
                    <span
                        style={{
                            color: getTotalBalance() === 0 ? 'inherit' : getTotalBalance() < 0 ? 'var(--veg-green)' : 'var(--veg-red)',
                            fontWeight: 600,
                            fontSize: '20px',
                        }}
                    >
                        {formatCurrency(Math.abs(getTotalBalance()))} {getTotalBalance() < 0 ? 'CR' : ''}
                    </span>
                </span>
            </div>

            <div className='customer-pets__filters'>
                <Select
                    className='customer-pets__pet-filter'
                    mode='multiple'
                    allowClear={true}
                    value={selectedPets}
                    placeholder='Filter by Patient'
                    options={filterOptions}
                    onChange={(value) => {
                        setSelectedPets(value);
                        setBalance({});
                    }}
                />

                <Select
                    className='customer-pets__balance-filter'
                    mode='multiple'
                    allowClear={true}
                    placeholder='Filter by Balance'
                    onChange={(value: string[]) => {
                        setSelectedBalanceFilter(value);
                        setBalance({});
                    }}
                >
                    <Select.Option value='balance_due'>Balance Due</Select.Option>
                    <Select.Option value='paid_in_full'>Paid in Full</Select.Option>
                    <Select.Option value='refund_due'>Refund Due</Select.Option>
                </Select>
            </div>

            <CustomCollapse ghost defaultActiveKey={defaultActiveKey}>
                {petItems}
            </CustomCollapse>

            <CreatePatientNoteDrawer ref={createPatientNoteRef} />
        </section>
    );
};

export default CustomerPets;
