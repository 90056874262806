import { CheckOutlined } from '@ant-design/icons';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { CSSProperties } from 'react';

const formItemStyle: CSSProperties = {
    width: '100%',
    marginBottom: '.8rem',
};

interface DrawerRadioGroupProps {
    options: string[] | Map<string, string | boolean>;
    optionsPerRow?: 2 | 3;
    value?: string;
    onChange?: (value: string | boolean) => void;
    disabled?: boolean;
}

export const DrawerRadioGroup: React.FC<DrawerRadioGroupProps> = ({ options, optionsPerRow = 2, value, onChange, disabled }) => {
    const md = 24 / optionsPerRow;

    const onSelection = (e: RadioChangeEvent) => {
        onChange?.(e.target.value);
    };

    return (
        <>
            <Radio.Group style={formItemStyle} buttonStyle='solid' onChange={onSelection} value={value}>
                <Row gutter={[8, 8]} style={{ wordWrap: 'initial' }}>
                    {options instanceof Array
                        ? options.map((option, index) => {
                              return (
                                  <Col key={index} xs={24} md={md}>
                                      <Radio.Button value={option} style={{ width: '100%', textAlign: 'center' }} disabled={disabled}>
                                          {value === option && <CheckOutlined style={{ marginRight: '5px' }} />}
                                          {option}
                                      </Radio.Button>
                                  </Col>
                              );
                          })
                        : Array.from(options).map((entry, index) => {
                              return (
                                  <Col key={index} xs={24} md={md}>
                                      <Radio.Button value={entry[1]} style={{ width: '100%', textAlign: 'center' }} disabled={disabled}>
                                          {value === entry[1] && <CheckOutlined style={{ marginRight: '5px' }} />}
                                          {entry[0]}
                                      </Radio.Button>
                                  </Col>
                              );
                          })}
                </Row>
            </Radio.Group>
        </>
    );
};

export default DrawerRadioGroup;
