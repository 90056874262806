import { TagType } from 'utils/types/enums';
import { pimsApi } from './pimsApi';

export interface Species {
	id: number;
	name: string;
}

export interface Breed {
	id: number;
	species_id: number;
	name: string;
}

export const specieService = pimsApi.injectEndpoints({
	endpoints: (builder) => ({
		getSpecies: builder.query<Species[], null>({
			query: () => 'species',
			providesTags: (result, error) => [{ type: TagType.Species }],
			transformResponse: (response: { data: Species[] }) => {
				return response.data;
			},
		}),
		getBreeds: builder.query<Breed[], null>({
			query: () => 'breed',
			providesTags: (result, error) => [{ type: TagType.Breed }],
			transformResponse: (response: { data: Breed[] }) => {
				return response.data;
			},
		}),
	}),
});

export const { useGetSpeciesQuery, useGetBreedsQuery } = specieService;
