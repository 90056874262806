import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AUTH_HOST } from '../utils/constants';

export const authApi = createApi({
	reducerPath: 'authApi',
	//TODO remove extra/unused tag types from each API
	tagTypes: [
		'Patient', // AKA pet
		'Visit',
		'Customer', //AKA Owner
		'Hospital',
		'Exam', // Physical exam for VISIT
		'ProgNote', // Progress note for VISIT
		'Instruction', //Instruction for VISIT
		'Package', // Group of Instructions, from SEARCH endpoint
		'Problem', // Problem for VISIT
		'rDVM', // Associates with patients and visits
		'SELF', // current logged in user
		'User', // other PIMS users
		'Notification',
		'PatientHistory',
	],
	baseQuery: fetchBaseQuery({
		baseUrl: AUTH_HOST,
		credentials: 'include',
		prepareHeaders: (headers, { getState }) => {
			headers.set('Content-Type', 'application/json');
			return headers;
		},
	}),
	endpoints: () => ({}),
});
