import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Checkout, Dashboard, Inbox, MainTs, Mobile, Patient } from 'views';
import { Admission } from 'views/home/admission/Admission';
import { LoginPage } from 'views/home/login/Login';
import { SSOPage } from 'views/home/sso/SSO';

import { ROUTES } from 'utils/types/enums';
import { Visit } from 'views/visit/Visit';

import 'antd/dist/antd.css';
import 'react-calendar-timeline/lib/Timeline.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'App.css';
import ConsentFormRoute from 'components/ConsentFormRoute';
import IntermediaryRoute from 'components/IntermediaryRoute';
import ProtectedRoute from 'components/ProtectedRoute';
import UnprotectedRoute from 'components/UnprotectedRoute';
import 'components/forms/EditableTable.css';
import 'components/forms/Forms.css';
import 'components/forms/PreviousPhysicalExam.css';
import { useHasAssignedHospital, useIsUserAuthenticated } from 'hooks';
import { useEffect } from 'react';
import { useGetPendoStatusQuery } from 'services/userService';
import 'styles/drawer.css';
import 'styles/typography.css';
import { pendoInit } from 'utils/pendoInit';
import AllVisits from 'views/AllVisits';
import ConsentForms from 'views/ConsentForms/ConsentForms';
import PrintEstimatePage from 'views/ConsentForms/PrintEstimatePage';
import VaccinationForm from 'views/ConsentForms/VaccinationForm';
import ViewApprovedConsentForm from 'views/ConsentForms/ViewApprovedConsentForm';
import Contacts from 'views/Contacts';
import Customer from 'views/Customer';
import AccountsReceivableReport from 'views/Report/AccountsReceivable';
import ControlledDrugsReport from 'views/Report/ControlledDrugs';
import DoctorProductionReport from 'views/Report/DoctorProduction';
import PaymentsReport from 'views/Report/PaymentsReport';
import Whiteboard, { WhiteboardLoginLink } from 'views/Whiteboard';
import { HospitalSelectionPage } from 'views/home/hospitalSelection/HospitalSelection';
import 'views/visit/Notes/ProgressNotes.css';
import 'views/visit/visit.css';

const App = () => {
    let [isUserAuthenticated] = useIsUserAuthenticated();
    let [hasAssignedHospital] = useHasAssignedHospital();
    const { data: pendoData } = useGetPendoStatusQuery(null, { pollingInterval: 0, skip: !isUserAuthenticated || !hasAssignedHospital });

    useEffect(() => {
        if (
            isUserAuthenticated.loggedInUserData &&
            hasAssignedHospital &&
            isUserAuthenticated.loggedIn &&
            !isUserAuthenticated.isFetching &&
            pendoData?.active
        ) {
            pendoInit(isUserAuthenticated.loggedInUserData, pendoData?.active);
        }
    }, [hasAssignedHospital, isUserAuthenticated, pendoData]);

    return (
        <Router>
            <div className='App'>
                <Switch>
                    <UnprotectedRoute
                        exact
                        path={ROUTES.sso}
                        component={SSOPage}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        isUserFetching={isUserAuthenticated.isFetching}
                    />
                    <UnprotectedRoute
                        exact
                        path={ROUTES.login}
                        component={LoginPage}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        isUserFetching={isUserAuthenticated.isFetching}
                    />
                    <IntermediaryRoute
                        exact
                        path={ROUTES.hospitalSelection}
                        component={HospitalSelectionPage}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ConsentFormRoute exact path={ROUTES.consentForms} component={ConsentForms} />
                    <ConsentFormRoute exact path={ROUTES.approvedConsentForms} component={ViewApprovedConsentForm} />
                    <ConsentFormRoute exact path={'/printEstimate/:visitId/:formId'} component={PrintEstimatePage} />
                    <ProtectedRoute
                        exact
                        path={ROUTES.outpatientTS}
                        component={() => <MainTs mainTsType='outpatient' />}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.inpatientTS}
                        component={() => <MainTs mainTsType='inpatient' />}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        path={ROUTES.visit}
                        component={Visit}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        path={ROUTES.patient}
                        component={Patient}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.dashboard}
                        component={Dashboard}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.admission}
                        component={Admission}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.inbox}
                        component={Inbox}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.mobile}
                        component={Mobile}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                        removeNav={true}
                    />
                    <ProtectedRoute
                        path={ROUTES.allvisits}
                        component={AllVisits}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <Route exact path={ROUTES.checkout} component={Checkout} />
                    <ProtectedRoute
                        exact
                        path={ROUTES.controlled_drug_report}
                        component={ControlledDrugsReport}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.accounts_receivable_report}
                        component={AccountsReceivableReport}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.doctor_production_report}
                        component={DoctorProductionReport}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.payments_report}
                        component={PaymentsReport}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.contacts}
                        component={Contacts}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.customer}
                        component={Customer}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                    />
                    <ProtectedRoute
                        exact
                        path={ROUTES.whiteboard}
                        component={Whiteboard}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                        removeNav={true}
                    />

                    <ConsentFormRoute
                        exact
                        path={ROUTES.whiteboard_link}
                        component={WhiteboardLoginLink}
                    />

                    <ProtectedRoute
                        exact
                        path={ROUTES.vaccination_form}
                        component={VaccinationForm}
                        isUserLoggedIn={isUserAuthenticated.loggedIn}
                        hasAssignedHospital={hasAssignedHospital}
                        removeNav={true}
                    />
                </Switch>
            </div>
        </Router>
    );
};

export default App;
