import InstructionDetails from '../InstructionDetails';
import NoteSection from '../NoteSection';
import './BonusActionModal.css';
import { Col, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { WastedControlledDrugs } from 'components/forms/ActionForms/WastedControlledDrugs';
import { generateFormItem } from 'components/forms/AdministerMedicationForm';
import { FormWrapper } from 'components/forms/FormWrapper';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import {
	useAddOffScheduleActionMutation,
	useDownloadIdexxResultFileQuery,
	useSubmitIHDOrderMutation,
	useSubmitXRayGenerateLinkMutation,
	useSubmitXRayOrderMutation,
} from 'services/instructionService';
import { setIsBonusActionModalVisible } from 'store/slices/treatmentSheetSlice';
import { UserNameWrapper } from 'utils/componentWrappers';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { isInstanceOfExistingDiagInstruction, isInstanceOfMedicineInstruction } from 'utils/dataTypes';

const BonusActionModal = ({ patientWeight }: { patientWeight?: number }) => {
	const { urlVisitId } = useParams<{ urlVisitId: string }>();
	const { isBonusActionModalVisible, bonusActionModalData, activeActionItem } = useAppSelector(
		({ treatmentSheetSlice }) => treatmentSheetSlice,
	);
	const dispatch = useAppDispatch();
	const [form] = useForm();
	const [submitIHDOrder, { isLoading: isIHDOrderLoading }] =
		useSubmitIHDOrderMutation();
	const [submitXRayOrder, { isLoading: isXRayOrderLoading }] =
		useSubmitXRayOrderMutation();
	const [submitXRayGenerateLink, { isLoading: isXRayLinkGenerationLoading }] =
		useSubmitXRayGenerateLinkMutation();
	const { data: resultsFile, isLoading: isFileDownloading } =
		useDownloadIdexxResultFileQuery(
			{ actionId: activeActionItem?.action_id || 0 },
			{
				skip:
					(bonusActionModalData?.category !== 'IDEXX' &&
						bonusActionModalData?.category !== 'WebPACS') ||
					!activeActionItem
			},
		);
	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const [addOffScheduleAction] = useAddOffScheduleActionMutation();

	const closeModal = () => {
		dispatch(setIsBonusActionModalVisible(false));
		form.resetFields();
	};

	const isDiagnostic = bonusActionModalData && isInstanceOfExistingDiagInstruction(bonusActionModalData);
	const isControlledDrug =
		bonusActionModalData &&
		isInstanceOfMedicineInstruction(bonusActionModalData) &&
		bonusActionModalData.controlled_drug;
	const getDiagnosticField = () => {
		if (
			bonusActionModalData &&
			isInstanceOfExistingDiagInstruction(bonusActionModalData)
		) {
			return generateFormItem({
				instruction: bonusActionModalData,
				onFormChange: form.setFieldsValue,
				onModalFinish: closeModal,
				setModalState: closeModal,
				modalForm: form,
				idexxSendOrderMutation: submitIHDOrder,
				idexxSendOrderLoading: isIHDOrderLoading,
				resultsFile,
				isFileDownloading,
				webpacsOrderEvt: submitXRayOrder,
				webpacsOrderLoading: isXRayOrderLoading,
				webpacsLinkGenerationEvt: submitXRayGenerateLink,
				webpacsLinkGenerationLoading: isXRayLinkGenerationLoading,
				actionStatus: 'complete',
			});
		}
	};

	const getWastedControlledDrugsFields = () => {
		if (isControlledDrug && isInstanceOfMedicineInstruction(bonusActionModalData)) {
			return (
				<WastedControlledDrugs
					drawn_only={false}
					initial_numerator_dose={bonusActionModalData.dose}
					numerator_value={bonusActionModalData.numerator_value}
					denominator_value={bonusActionModalData.denominator_value}
					denominator_unit={bonusActionModalData.denominator_unit}
				/>
			);
		}
	};

	const handleFormSubmit = (values: any) => {
		const currentDate = Math.floor(new Date().getTime() / 1000);
		const dateMinutes = currentDate - (currentDate % 60);
		const drawnAmount = parseFloat(values.drawn_amount);
		const administeredAmount = parseFloat(values.administered_amount);

		closeModal();
		addOffScheduleAction({
			visitId: parseInt(urlVisitId),
			body: {
				due_at: dateMinutes,
				instructionId: bonusActionModalData!.id,
				note: values.note ?? '',
				value: values.value || null,
				userId: loggedInUserData!.user_id,
				drawn_amount: isNaN(drawnAmount) ? null : drawnAmount,
				administered_amount: isNaN(administeredAmount) ? null : administeredAmount,
			},
		});
	};

	return (
		<Modal
			className='bonus-action-modal'
			style={{
				maxWidth: '700px',
				minWidth: '520px',
			}}
			okButtonProps={{ autoFocus: !isDiagnostic }}
			destroyOnClose
			title={bonusActionModalData?.name}
			open={isBonusActionModalVisible}
			onCancel={closeModal}
			onOk={form.submit}
			okText='Submit'
		>
			<FormWrapper
				className='bonus-action-form'
				form={form}
				onFormChange={form.setFieldsValue}
				getFormData={form.getFieldsValue}
				onFinish={handleFormSubmit}
				onFinishFailed={() => { }}
				bottomBar={<></>}
				gutter={10}
			>
				<InstructionDetails instruction={bonusActionModalData!} patientWeight={patientWeight} />

				{getDiagnosticField() ?? <></>}
				{getWastedControlledDrugsFields() ?? <></>}

				<Row style={{ width: '100%' }}>
					<Col span={6}>Claimed By</Col>
					<Col span={16} style={{ fontWeight: 500 }}>
						<UserNameWrapper>
							{loggedInUserData?.user_id}
						</UserNameWrapper>
					</Col>
				</Row>

				<NoteSection
					onFormChange={(value: any) =>
						form.setFieldsValue({ note: value.note })
					}
					labelSpan={6}
					valueSpan={18}
				/>
			</FormWrapper>

			<div className='bonus-action-modal--warning'>
				<h2>Bonus Actions</h2>
				<p>
					By clicking submit, treatment records will show an action
					performed with the current timestamp in addition to the
					normal order frequency.
				</p>
			</div>
		</Modal>
	);
};

export default BonusActionModal;
