import { DownloadOutlined, LockOutlined, SendOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { BASE_QUERY_OPTIONS, TASKS_HOST } from 'utils/constants';
import { PatientRecordVisit } from 'utils/dataTypes';
const VISIT_START_DATE_FORMAT = 'MMM Do, hh:mm a';
interface VisitSectionProps {
	currentVisit: PatientRecordVisit;
}

export const VisitSection = ({
	currentVisit,
}: VisitSectionProps) => {
	const { data: hospitalData } = useGetHospitalByIdQuery(
		currentVisit.hospital_id,
		{ ...BASE_QUERY_OPTIONS, skip: !currentVisit.hospital_id },
	);

	const visitDurationUnit = 'hours';
	const displayVisitStartTime = currentVisit.arrived_at
		? moment.unix(currentVisit.arrived_at).format(VISIT_START_DATE_FORMAT)
		: moment().format(VISIT_START_DATE_FORMAT);

	const displayVisitDuration = currentVisit.arrived_at && currentVisit.discharged_at
		? `${moment.unix(currentVisit.discharged_at).diff(moment.unix(currentVisit.arrived_at), visitDurationUnit)} hours`
		: currentVisit.arrived_at
		? moment.unix(currentVisit.arrived_at).toNow(true)
		: "-";

	return (
		<>
			<Row  align='middle' style={{marginTop:'8px'}}>
					<Col md={{ span: 24}}  lg={{ span: 10}}>
					<span className='sidebar-visit-details'>Hospital:</span>
					</Col>
					<Col md={{ span: 24 }}  lg={{ span: 14}} style={{color: "var(--gray-8)"}}>
					{hospitalData?.display_name}, {hospitalData?.state}
					</Col>
				</Row>
			<Row>
					<Col md={{ span: 24}}  lg={{ span: 10}}>
					<span className='sidebar-visit-details'>Arrived:</span>
					</Col>
					<Col md={{ span: 24}}  lg={{ span: 14 }} style={{color: "var(--gray-8)"}}>
					 {displayVisitStartTime}
				</Col>
			</Row>
			<Row>
					<Col md={{ span: 24 }}  lg={{ span: 10}}>
					<span className='sidebar-visit-details'>Duration:</span>
					</Col>
					<Col md={{ span: 24 }}  lg={{ span: 14}} style={{color: "var(--gray-8)"}}>
					{["noshow", "enroute"].includes(currentVisit.status) ? '-' : displayVisitDuration}
				</Col>
			</Row>
		</>
	);
};
