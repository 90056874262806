import { Tag } from 'antd';
import React from 'react';
import './PackageItemTitleContainer.css';

interface PackageItemTitleContainerProps {
    no_charge: boolean;
    checked: boolean;
}

const PackageItemTitleContainer: React.FC<PackageItemTitleContainerProps> = ({ checked, no_charge, children }) => {
    return (
        <div className='treatment-sheet-side-column__title-container'>
            {children}

            {checked && no_charge && (
                <Tag className='no-charge-tag' color='processing'>
                    No Charge
                </Tag>
            )}
        </div>
    );
};

export default PackageItemTitleContainer;
