import { Collapse as AntdCollapse, CollapseProps } from 'antd';
import React from 'react';
import './CustomCollapse.css';

const CustomCollapse: React.FC<CollapseProps> = ({ children, ...props }) => {
    return (
        <AntdCollapse className='custom-collapse' {...props}>
            {children}
        </AntdCollapse>
    );
};

export default CustomCollapse;
