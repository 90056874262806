import { instructionApi } from './instructionApi';

// Define a service using a base URL and expected endpoints
export const nonMedicalService = instructionApi.injectEndpoints({
	endpoints: (builder) => ({
		addCubexOrder: builder.mutation<
			null,
			{
				visitId: number;
				instructionId: number;
				actionId: number;
				dueAt: number;
			}
		>({
			query({ visitId, instructionId, actionId, dueAt }) {
				return {
					url: `visit/${visitId}/instruction/${instructionId}/action/${actionId}/cubex`,
					method: 'POST',
					body: { due_at: dueAt },
				};
			},
		}),
		closeCubexForVisit: builder.mutation<null, { visitId: number }>({
			query({ visitId }) {
				return {
					url: `visit/${visitId}/cubex`,
					method: 'POST',
				};
			},
		}),
	}),
});

export const { useAddCubexOrderMutation, useCloseCubexForVisitMutation } =
	nonMedicalService;
