import { UserAddOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Row, Tag, message } from 'antd';
import SelectDoctor from 'components/SelectDoctor';
import { Avatar } from 'components/lib';
import { useGetUserDataQuery } from 'services/authService';
import { usePostCheckinTprInstructionsMutation } from 'services/instructionService';
import { useGetPimsUserQuery } from 'services/userService';
import {
    useAssignSelfToPatientMutation,
    useAssignUserToPatientMutation,
    useUnassignDoctorFromPatientMutation,
} from 'services/vetDashService';
import { useTransitionStatusMutation } from 'services/visitService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { PatientRecordVisit } from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { changeStatusWhenAssigningDoctorIfStatusArrived } from 'utils/triggerFuncs';
import {
    USER_PERMISSIONS,
    checkIfUserHasNecessaryPermission,
} from 'utils/userPermissions';

interface AssignedVetSectionProps {
    currentVisit: PatientRecordVisit;
}

export const AssignedVetSection = ({
    currentVisit,
}: AssignedVetSectionProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const isVisitAssigned = !!currentVisit.doctor_id;
    const [assignSelf] = useAssignSelfToPatientMutation();
    const [assignUser] = useAssignUserToPatientMutation();
    const [unassignDoctor] = useUnassignDoctorFromPatientMutation();
    const [transitionStatus] = useTransitionStatusMutation();
    const isFinalizedVisit = Boolean(currentVisit.finalized_at);

    const [postCheckinTprInstructions] =
    usePostCheckinTprInstructionsMutation();

    const userHasPermissionToAssign = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.visit_claim,
    );
    
    const { data: assignedUser } = useGetPimsUserQuery(
		currentVisit.doctor_id ?? skipToken,
		{
			...BASE_QUERY_OPTIONS,
			skip: currentVisit.doctor_id === null
		},
	);

    return (
        <Row align='middle' style={{ paddingTop: '4px' }}>
            <Col span={12} style={{ textAlign: 'right', paddingRight: '16px' }}>
                Vet
            </Col>
            <Col span={10}>
                {(() => {
                    if (isVisitAssigned) {
                        return (
                            <>
                                {assignedUser &&
                                    <Row>
                                        
                                        <Col span={5}>
                                            <Avatar
                                                size='small'
                                                firstName={assignedUser?.first_name}
                                                lastName={assignedUser?.last_name}
                                                marginRight='0'
                                            />
                                        </Col>
                                        <Col span={19}>
                                            <Tag
                                                className='assigned-vet-tag'
                                                data-cy='assignedDoctorTag'
                                                closable={true}
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    if (isFinalizedVisit) {
                                                        closedErrorMsg(isFinalizedVisit);
                                                        return;
                                                    }
                                                    if (isVisitAssigned) {
                                                        unassignDoctor({
                                                            visitId: currentVisit.id,
                                                        });
                                                    }
                                                }}
                                            >
                                                <span>{`${capitalizeFirstLetter(assignedUser?.first_name ?? '')} ${capitalizeFirstLetter(
                                                    assignedUser?.last_name ?? '',
                                                )}`}</span>
                                            </Tag>
                                        </Col>
                                        
                                    </Row>
                                }
                            </>
                        );
                    } else {
                        return (
                            <Row>
                                <Col span={5}>
                                    <Button
                                        icon={<UserAddOutlined />}
                                        shape='circle'
                                        size='small'
                                        style={{ fontSize: '12px' }}
                                        disabled={isFinalizedVisit || !userHasPermissionToAssign}
                                        data-cy="assignDoctorButton"
                                        onClick={() => {
                                            if (isFinalizedVisit) {
                                                closedErrorMsg(isFinalizedVisit);
                                                return;
                                            }
                                            if (!isVisitAssigned) {
                                                if (currentVisit.status === "enroute") {
                                                    postCheckinTprInstructions({ visitId: currentVisit.id });
                                                }
                                                assignSelf({
                                                    visitId: currentVisit.id,
                                                }).unwrap().then((response: any) => {
                                                changeStatusWhenAssigningDoctorIfStatusArrived(
                                                    currentVisit.id,
                                                    currentVisit.status,
                                                    transitionStatus,
                                                )
                                                });
                                            }
                                        }}
                                    />
                                </Col>
                                <Col span={19}>
                                    <SelectDoctor
                                        size='small'
                                        style={{width: '100%'}}
                                        placeholder='Select a vet'
                                        onSelect={(value) => {
                                            if (isFinalizedVisit) {
                                                closedErrorMsg(isFinalizedVisit);
                                                return;
                                            }
                                            if (!value) {
                                                return;
                                            }
                                            if (!isVisitAssigned) {
                                                if (currentVisit.status === 'enroute') {
                                                    postCheckinTprInstructions({ visitId: currentVisit.id });
                                                }
                                                assignUser({
                                                    visitId: currentVisit.id,
                                                    userId: value,
                                                })
                                                    .unwrap()
                                                    .then(() => {
                                                        changeStatusWhenAssigningDoctorIfStatusArrived(
                                                            currentVisit.id,
                                                            currentVisit.status,
                                                            transitionStatus,
                                                        );
                                                    })
                                                    .catch((error) => message.error(error.data));
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    }
                })()}
            </Col>
        </Row>
    );
};
