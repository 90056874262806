import { Col, Divider, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { HiddenInput } from 'components/forms/fields/HiddenInput';
import { useEffect, useState } from 'react';
import {
	useApproveBurialFormMutation,
	useGetBurialFormQuery,
} from 'services/consentService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { BurialConsentAcceptBody } from 'utils/types/consentFormTypes';
import { IFormProps } from './ConsentForms';
import PrintableForm from './PrintableForm';
import { ConsentFormSuccess } from './Result';

const BurialForm: React.FC<IFormProps> = ({ formId }) => {
	const { data: burialData } = useGetBurialFormQuery(
		formId,
		BASE_QUERY_OPTIONS,
	);
	const [form] = useForm<BurialConsentAcceptBody>();
	const [approveBurial] = useApproveBurialFormMutation();
	const [approved, setApproved] = useState(false);
	const [burialType, setBurialType] = useState<string | null>();
	const [urn, setUrn] = useState<string | null>(null);
	const [customUrn, setCustomUrn] = useState<string | null>(null);
	const showUrnSelection = burialType === 'private cremation';
	const checkCustomUrn = burialType !== 'private cremation' || !!urn;
	const onApprove = () => {
		form.submit();
	};

	useEffect(() => {
		if (burialData?.is_approved) {
            setApproved(true);
        }
		form.setFieldsValue({
			crematorium_id: burialData?.crematorium_id,
			urn_id: null,
			custom_urn_id: null,
		});
	}, [burialData]);

	useEffect(() => {
		if (burialType !== 'private cremation') {
			form.setFieldsValue({
				urn_id: null,
				custom_urn_id: null,
			});
			setUrn(null);
			setCustomUrn(null);
		}
	}, [burialType]);

	if (!burialData) {
		return <Spin size='large' />;
	}

	if (approved) {
		return (
			<ConsentFormSuccess subTitle='We have successfully received your signed consent form.' />
		);
	}

	const { pet_name, has_private_burial, urns } = burialData;
	const petNameUppercase = capitalizeFirstLetter(pet_name);

	const burialOptions = [
		{
			label: 'General Cremation',
			value: 'general cremation',
		},
		{
			label: 'Private Cremation',
			value: 'private cremation',
		},
		{
			label: 'Release Remains',
			value: 'release remains',
		},
	];

	if (has_private_burial) {
		burialOptions.splice(2, 0, {
			label: 'Private Burial',
			value: 'private burial',
		});
	}

	const urnOptions = urns.map((urn) => ({
		label: urn.name,
		value: urn.id,
	}));

	return (
		<PrintableForm
			title='Burial and Cremation Consent Form'
			headerData={burialData}
			approved={approved}
			approveText='I acknowledge that I have read the terms of this agreement.'
			acceptButtonText='I agree'
			printMessage='For a copy of this agreement, please print prior to accepting the terms.'
			onApprove={onApprove}
		>
			<div className='single-page-element document-text'>
				<Form
					requiredMark={false}
					form={form}
					onFinish={(values: BurialConsentAcceptBody) => {
						const urn_id = values.urn_id ?? null;
						const custom_urn_id = values.custom_urn_id ?? null;
						const crematorium_id =
							burialType === 'release remains'
								? null
								: values.crematorium_id;
						const body: BurialConsentAcceptBody = {
							type: burialType ?? "",
							crematorium_id,
							urn_id,
							custom_urn_id,
						};
						approveBurial({ formId, body });
						setApproved(true);
					}}
				>
					<HiddenInput fieldName='crematorium_id' />

					<Form.Item label='Burial Service' name='type' required>
						<Col span={10}>
							<Select
								placeholder='Select a Burial Method'
								options={burialOptions}
								allowClear
								onClear={() => setBurialType(null)}
								onSelect={(value) => {
									setBurialType(value as string);
								}}
							/>
						</Col>
					</Form.Item>

					{showUrnSelection && (
						<Row gutter={10}>
							<Col span={12}>
								<Form.Item label='Urn' name='urn_id'>
									<Select
										disabled={!!customUrn}
										placeholder='Select an Urn'
										options={urnOptions}
										allowClear
										onSelect={(value) => {
											setUrn(value as string);
										}}
										onClear={() => setUrn(null)}
									/>
								</Form.Item>
							</Col>
							<Col span={10}>
								<Form.Item name='custom_urn_id'>
									<Input
										placeholder='Input a custom Urn Id'
										disabled={checkCustomUrn}
										onChange={(event) => {
											if (event.target.value) {
												setCustomUrn(
													event.target.value,
												);
											} else {
												setCustomUrn(null);
											}
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</Form>

				<Divider />

				<p>
					I, the undersigned, do hereby certify that I am the owner or
					the authorized agent for the owner of the pet described
					above, that I am 18 years of age or older, and that I have
					the authority to execute this consent. I do hereby give
					complete authority to humanely cremate {petNameUppercase}{' '}
					and release the veterinary facility and its owners,
					employees and staff from any and all claims and liability
					(including legal fees and other costs and expenses) for so
					cremating said pet.
				</p>
				<p>
					The nature of the cremation process has been explained to
					me, and I understand what will be done and that it is an
					irreversible and final process. I further acknowledge that I
					have received, read, understand and accept the price
					schedule for each aforesaid disposition alternatives, and
					where each alternative method of disposition would take
					place. If this form is not completed by the pet owner, the
					undersigned acknowledges that the same was orally authorized
					by the pet owner and witnessed by the party signing below as
					such witness.
				</p>
				<p>
					I do also certify that to the best of my knowledge said pet
					has not bitten any person or animal during the last fifteen
					(15) days and has not been exposed to rabies.
				</p>
			</div>
		</PrintableForm>
	);
};

export default BurialForm;
