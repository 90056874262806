import { useParams } from 'react-router-dom';
import ApprovedMedicationConsentForm from './ApprovedMedicationConsentForm';
import ApprovedBurialForm from './ApprovedBurialForm';
import ApprovedEuthanasiaForm from './ApprovedEuthanasiaForm';
import './ViewApprovedConsentForm.css';

const ViewApprovedConsentForm = () => {
    const { urlFormId } = useParams<any>();

    if (urlFormId.charAt(0) === 'f') {
        return <ApprovedMedicationConsentForm />;
    } else if (urlFormId.charAt(0) === 'a') {
        return <ApprovedBurialForm />;
    } else if (urlFormId.charAt(0) === 'c') {
        return <ApprovedEuthanasiaForm />;
    }

    return null;
};

export default ViewApprovedConsentForm;
