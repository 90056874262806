import { Row, Col, Empty, Tooltip } from 'antd';
import { formatCurrency } from 'utils/formatFuncs';
import { BillingInfo } from 'utils/types/billingTypes';
import OverviewTabStyles from './overview-tab.module.css';

interface BillingSummaryProps {
	billingInfo: BillingInfo;
}
export const BillingSummary = (props: BillingSummaryProps) => {

	const { invoiceTotal, paid, approvedEstimateTotal } = props.billingInfo;

	if (invoiceTotal === 0 && approvedEstimateTotal === 0)
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

	return (
		<>
			<Row className={OverviewTabStyles.billingInvoiceTotal} justify='space-between'>
				<Tooltip title="Excludes pending and yet to be completed approved treatments">
					<Col>Invoice Total</Col>
				</Tooltip>
				<Col className={OverviewTabStyles.billingInvoiceTotalValue}>{formatCurrency(invoiceTotal)}</Col>
			</Row>
			<Row className={OverviewTabStyles.billingPaymentsEstimates} justify='space-between'>
				<Col>Payments</Col>
				<Col>{formatCurrency(paid)}</Col>
			</Row>
			<Row className={OverviewTabStyles.billingPaymentsEstimates} justify='space-between'>
				<Col>Estimates - Approved</Col>
				<Col>{formatCurrency(approvedEstimateTotal)}</Col>
			</Row>
		</>
	);
};
