import { Moment } from 'moment';
import { TimelineItemBase } from 'react-calendar-timeline';
import { roundTo } from './formatFuncs';
import { BillingItem, CriEstimateItem, EarnedRevenueLineItem, MedicalEstimateItem } from './types/billingTypes';
import { BurialApprovedForm, ConsentFormEstimateItem, isInstanceOfConsentFormEstimateItemMedical } from './types/consentFormTypes';
import { NoteCategories } from './types/enums';
import { CriEstimateItemNew, MedicalEstimateItemNew, isInstanceOfMedicalEstimateItemNew } from './types/estimateTypesNew';
import {
	CriSearchOption,
	isInstanceOfPackageMedInstruction,
	MedicineSearchOption,
	PackageCriInstruction,
	PackageMedInstruction,
} from './types/InstructionOrderTypes';
import { USER_PERMISSIONS } from './userPermissions';

export type INSTRUCTION_TYPES = 'M' | 'D' | 'T' | 'P' | 'C' | 'F' | "N" | "TGH" | 'OT';

export interface PimsUser {
	user_id: number;
	email: string;
	first_name: string;
	last_name: string;
	last_login_at: number;
	hospital_id: number;
	hospital_name: string;
	allowed_hospitals: { [key: string]: string };
	user_permissions: USER_PERMISSIONS[];
	can_receive_production?: boolean;
	role_name?: string;
	okta_subject?: string;
}

export interface HospitalDoctor {
	user_id: number;
	full_name: string;
	vet_license_number?: string;
}

export interface UserState {
	user_id: number;
	email: string;
	first_name: string;
	last_name: string;
	isUserLoggedIn: boolean;
	loginError: boolean;
	notifications: Notification[];
	allUsers: { [key: string]: PimsUser };
}

export interface PatientAlertCategory {
	id: number;
	name: string;
}

export interface PatientAlert {
	id: number;
	alert: string;
	category_id: number;
}

export interface OutgoingPatientAlert {
	alert_id: number | null;
	alert_delete: boolean | null;
	alert_name: string | null;
	alert_category_id: number | null;
}

export interface BasePatient {
	pet_id: string;
	name: string;
	is_deceased?: boolean;
	is_intact?: boolean;
	deceased_at?: number;
	species?: string;
	breed?: string;
	latest_weight?: number | null;
	sex?: 'M' | 'F';
	birthday?: string;
	note?: string;
}

export const isInstanceOfBasePatient = (item: any): item is BasePatient =>
	(item as BasePatient).pet_id !== undefined &&
	(item as BasePatient).name !== undefined;

export interface ExpandedPatient extends BasePatient {
	ageYears: number;
	ageMonths: number;
}

export interface PatientInfo extends Omit<ExpandedPatient, 'deceased_at'> {
	deceased_at?: Moment;
}

export const isInstanceOfExpandedPatient = (
	item: any,
): item is ExpandedPatient =>
	(item as ExpandedPatient).pet_id !== undefined &&
	(item as ExpandedPatient).ageYears !== undefined &&
	(item as ExpandedPatient).ageMonths !== undefined;

export interface RdvmPatient extends BasePatient {
	relationship_id: number;
}

export interface PetOwner {
	customer_id: string | null,
    first_name: string | null,
    last_name: string | null,
    phone_number: string | null,
    email: string | null
}

export interface PetResult {
    pet_id: string,
    name: string,
	last_visit_id: number | null,
    last_visit_created_at: number | null,
    primary_contacts: PetOwner[]
}

export interface PetGroupedSearch {
	hospital_id:  number | null,
    hospital_display_name: string | null,
    pets: PetResult[]
}

//TODO separate out RDVM from patient/visit type and generic RDVM (more info)
export interface RDVM {
	is_active: boolean;
	clinic_id: string;
	display_name: string; // display_name OR rdvm_name
	override_display_name: string | null;

	email: string;
	override_email: string | null;
	phone_number: string; //rdvm_phone_number OR phone_number
	fax_number: string;
	street: string;
	city: string;
	state: string;
	zipcode: string;
	description: string;
	section_group: number;
}

export interface Display_Rdvm {
	rdvm_id: number;
	rdvm_name: string;
	rdvm_phone_number: string;
	rdvm_note: string;
}

export interface Hospital {
	id: number;
	display_name: string;
	email: string;
	phone_number: string;
	street: string;
	city: string;
	state: string;
	zipcode: string;
	fflags: any;
	require_customer_birthday: boolean;
	has_supply_bank: boolean;
}
export interface WhiteboardURL {
	url: string;
}

/**
 * Used for gathering info about owner/customer
 *
 */
export interface CustomerInfoUpdateBody {
	phone_number: string;
	first_name: string;
	last_name: string | null;
	email: string | null;
	street: string | null;
	city: string | null;
	state: string | null;
	zipcode: string | null;
	note: string | null;
	how_did_you_hear_about_us: string | null;
	additional_info: string | null;
	birthday: string | null;
	unable_to_collect_birthday: boolean | null;
	email_opt_out: boolean | null;
}


export interface CustomerPet {
	pet_id: string;
	pet_name: string;
	hospital_id: number;
	hospital_name: string;
}

export interface CustomerInfo extends CustomerInfoUpdateBody {
	customer_id: string;
	visit_count: number;
	pets?: CustomerPet[];
}

export interface HospitalCustomers {
	hospital_id: number;
	hospital_name: string;
	customers: CustomerInfo[];
}

export const isInstanceOfCustomerInfo = (item: any): item is CustomerInfo =>
	(item as CustomerInfo).customer_id !== undefined &&
	(item as CustomerInfo).email !== undefined;

export interface PatientRecordPatient extends ExpandedPatient {
	owner: string;
	notes: any[]; // todo flesh out this data type, will come when APIs are up
	owners: CustomerInfo[];
	rdvm: RDVM; // todo flesh out this data type, will come when APIs are up
	visit: PatientRecordVisit;
	current_visit_id: string;
	treatmentSheet?: TimelineItemBase<any>[];
	allVisits: BaseVisit[];
}

export interface BaseVisit {
	id: number;
	pet_id: string;
	sex: 'M' | 'F';
	hospital_id: string;
	customer_id: string;
	doctor_id: number;
	rdvm_id: string;
	secs_in_status: number;
	status: string;
}

export type VISIT_STATUSES =
	| 'enroute'
	| 'arrived'
	| 'noshow'
	| 'inprogress'
	| 'hospitalized'
	| 'discharged'
	| 'checked_out';

export enum HOSPITALIZATION_LEVELS {
	'Observation',
	'Standard',
	'Critical',
}

export type VISIT_AR_STATUSES =
	| 'awaiting_payment'
	| 'sent_to_collections'
	| 'uncollectible';

export type TRIAGE_LEVELS =
	| "critical"
	| "urgent"
	| "stable"
	| null;

export interface ListVisit {
	id: number;
	pet_id: string;
	hospital_id: number;
	customer_id: string | null;
	doctor_id: number | null;
	rdvm_id: string | null;
	rdvm_email: string | null;
	secs_in_status: number;
	recheck_time: string | null;
	status: VISIT_STATUSES;
	last_status_transition_at: number;
	last_status_transition_by: number;
	hospitalization_level?: HOSPITALIZATION_LEVELS;
	spiked_by: number | null;
	spiked_at: number | null;
	triage_level: TRIAGE_LEVELS;
	triage_level_updated_at: number | null;
	triage_level_updated_by: number | null;
}

export interface ModifyVisitBody {
	owner_id: string | null;
	rdvm_id: string | null;
	discharge_summary_location: string | null;
	recheck_time: string | null;
	override_display_name: string | null;
	override_email: string | null;
}

export interface PatientRecordVisit extends ListVisit {
	diffLists: Differential[];
	discharge: Discharge;
	arrived_at: number;
	discharged_at: number | null;
	finalized_at: number | null;
	finalized_by: number | null;
	balance_due: number;
	referred_by_primary_vet: string | null;
	reason_for_visit: string | null;
	nurse_id: number | null;
	invoice_finalized_at: number | null;
	invoice_unlocked_at: number | null;
}

export interface VetDashboardVisit {
	visit_id: number;
	pet_name: string;
	pet_species: string;
	pet_breed: string;
	pet_sex: string;
	pet_is_intact: boolean;
	pet_deceased_at: number;
	pet_weight: number;
	doctor_id: number;
	doctor_first_name: string;
	doctor_last_name: string;
	nurse_id: number;
	nurse_first_name: string;
	nurse_last_name: string;
	owner_last_name: string;
	rdvm_name: string;
	status: VISIT_STATUSES;
	arrived_at: number;
	discharged_at: number | null;
	reason_for_visit: string | null;
	spiked_by: number | null;
	spiked_at: number | null;
	triage_level: TRIAGE_LEVELS;
	triage_level_updated_at: number | null;
	triage_level_updated_by: number | null;
}

export interface VetDashState {
	unassigned: VetDashboardVisit[];
	inprogress: VetDashboardVisit[];
	hospitalized: VetDashboardVisit[];
	discharged: VetDashboardVisit[];
	checked_out: VetDashboardVisit[];
}

interface CallContact {
	name: string;
	owner_id: string;
}

export interface InboundCall {
	session_id: number;
	from_number: string;
	submitted_at: number;
	status_code: string;
	from_name?: string;
	call_type?: string;
	patient_name?: string;
	spoke_with_doctor?: boolean;
	doctor_id?: number;
	reason?: string;
	reason_other?: string;
	recommendation?: string;
	referred_where?: string;
	referred_why?: string;
	referred_why_other?: string;
	send_check_in_link?: boolean;
	mobile_phone?: string;
	note?: string;
	form_link_id?: string;
	contacts?: CallContact[];
}

export interface CallLog {
	call_type: string;
	patient_name?: string;
	spoke_with_doctor?: boolean;
	doctor_id?: number;
	reason?: string;
	reason_other?: string;
	recommendation?: string;
	referred_where?: string;
	referred_why?: string;
	referred_why_other?: string;
	send_check_in_link?: boolean;
	mobile_phone?: string;
	note?: string;
}

export interface IntakeForm {
	reason_for_visit: string;
	spike_3: boolean;
	triage_level: TRIAGE_LEVELS;
}

export interface IntakeFormWithInitialVitals extends IntakeForm {
	initial_vitals: {
		current_note: string | null;
		current_value: string | null;
		due_at: number;
		instruction_id: number;
		new_note: string | null;
		new_value: string | null;
	},
	should_update_intake: boolean;	
}

export interface MacroForm {
    label: string;
    key_word: string;
    category: string;
    content: string;
}

export const isInstanceOfCallLog = (item: any): item is CallLog =>
	(item as CallLog).call_type !== undefined;

export interface VetAssignResponse {
	doctor_id: number;
	encounter_id: number;
	end_time: number;
	start_time: number;
	visit_id: number;
}

export interface NurseAssignResponse {
	nurse_id: number;
	encounter_id: number;
	end_time: number;
	start_time: number;
	visit_id: number;
}

export interface VisitTransitionRequest {
	status: VISIT_STATUSES;
	hospitalization_level?: HOSPITALIZATION_LEVELS;
}

export interface VisitArTransitionRequest {
	status: VISIT_AR_STATUSES | null;
}

export interface SendEmailRequest {
	email_type: string;
}

export interface SendSmsRequest {
	sms_type: string;
}

export interface SMSTextMessage {
	twilio_sid: string
	category: string
	created_by: number
	created_at: number
	updated_at: number
	status_: string
	from_number: string
	to_number: string
	hospital_id: number
	visit_id: number
	content?: string
	owner_first_name?: string
	owner_last_name?: string
}

//TODO this will likely change at some point
//This is just the shape we use temporarily to pass Vital data around
export interface VisitVital {
	name: string;
	diagnostic_id: number;
	lastAction: InstructionAction;
	unit: string | null;
}

export interface OutgoingProblem {
	complaint_id: string;
	chronic: boolean;
	presenting_complaint: boolean;
	differentials: number[];
}

export interface OutgoingAction {
	actionId: number;
	instructionId: number;
	note?: string;
	status: INSTRUCTION_STATUSES;
	assigned_to?: number; //Only required for some statuses -- e.g. 'claimed'
	value?: string | File;
	due_at: number;
	scheduled_time?: number;
	scheduled_by?: number;
	completed_at?: number;
	drawn_amount?: number;
	administered_amount?: number;
}

export interface ActionUpdate {
	note: string | null;
	status: INSTRUCTION_STATUSES;
	assigned_to: number | null;
	value: string | File | null;
	due_at: number;
	scheduled_time: number | null;
	scheduled_by: number | null;
	completed_at: number | null;
	drawn_amount: number | null;
	administered_amount: number | null;
}

export interface FrequencyUpdate {
	started_at: number;
	frequency: string;
}

export interface FrequencyUpdateMultiple {
	started_at: number;
	frequency: string;
	items: {
		id: number;
		type_id: INSTRUCTION_TYPES;
	}[];
}

export interface OutgoingOffSchedAction {
	instructionId: number;
	note: string;
	due_at: number;
	value: any;
	userId: number;
	drawn_amount: number | null;
	administered_amount: number | null;
}
/**
 * medicine, diagnostic, task, package, cri
 */
export interface BaseExistingInstruction {
	id: number;
	visit_id: number;
	type_id: INSTRUCTION_TYPES;
	name: string;
	start_time: number;
	end_time: number;  // Todo change this to number | null
	frequency?: string;
	is_prn: boolean;
	prn_condition: string;
	priority: boolean;
	notes: string;
	created_at: number;
	created_by: number;
	ordered_by: number;
	discontinued_at: number | null;
	discontinued_by: number | null;
	discontinued_on_behalf_of: number | null;
	discontinued_reason: number | null;
	actions: InstructionAction[];
	category: string | null;
	sort_rank: number;
	started_at?: number;
    serial?: boolean;
    serial_hours?: boolean;
	is_free?: boolean;
}

export interface DischargeNewInstruction {
	togo: boolean; //this is CLIENT SIDE ONLY
	visit_id: number;
	related_instruction_id: number | null;
	medication_id: number;
	name: string;
	dispense_value: number;
	dispense_unit: string;
	instructions: string;
	next_dose: string;
	morning_doses: number | null;
	afternoon_doses: number | null;
	night_doses: number | null;
	refills: number;
	fill_at_pharmacy: boolean;
	action_status?: string;
	dose_unit: string;
	ratio: number;
	userId?: number;
	is_shown_on_tx_sheet?: boolean;
	controlled_drug?: boolean;
}

export interface DischargeExistingInstruction extends DischargeNewInstruction {
	dose_value: number;
	related_instruction_id: number;
	id: number;
	ordered_by: number;
	created_at: number;
	created_by: number;
	discontinued_at: number | null;
	discontinued_reason: string | null;
	discontinued_by: string | null;
	discontinued_on_behalf_of: number | null;
	instruction_status?: string;
	start_time?: number;
	vetspire_id: number | null;
}

export const isInstanceOfDischargeExistingInstruction = (
	inst: any,
): inst is DischargeExistingInstruction =>
	!!inst &&
	!!(inst as DischargeExistingInstruction).medication_id &&
	!!inst.related_instruction_id;

/**
 * TODO
 * Do a google for "How to use TS type as a value"
 * So you can map over this to make dropdowns, radio buttons, etc.
 * SAME WITH RESULT_ENTRY_WIDGETS
 */
export type INSTRUCTION_STATUSES =
	| 'missed'
	| 'skipped'
	| 'scheduled'
	| 'claimed'
	| 'inprogress'
	| 'complete';
export interface InstructionAction {
	id: number; //Unique ID for this Action
	instruction_id: number; //Underlying "Order" AKA underlying "Instruction"
	due_at: number;
	status: INSTRUCTION_STATUSES;
	status_transition_at: number;
	status_transition_by: number;
	value: number | string | boolean | null;
	observed_at?: number;
	observed_by?: number;
	assigned_to: number | null;
	note: string;
	scheduled_time?: number;
	scheduled_by?: number;
	completed_at?: number;
	isGhostAction?: boolean
	started_at?: number | null;
}

export type INSTRUCTION_CALCULATOR_TYPES = 'complex' | 'simple' | 'prepackaged';

export interface ExistingMedInstruction
	extends BaseExistingInstruction {
	frequency: string;
	medication_id: number;
	route_id: string;
	dose: number;
	dose_unit: string;
	numerator_value: number;
	denominator_value: number;
	denominator_unit: string;
	approx_patient_weight_kg: number | null;
	latest_patient_weight_kg: number | null;
	controlled_drug: boolean;
    serial: boolean;    

	type_id: 'M';
	low_dose_alert?: number;
	high_dose_alert?: number;

	required_before_discharge: boolean;
	vetspire_id: number | null;
}

export interface ExistingDiagInstruction extends BaseExistingInstruction {
	frequency: string;
	diagnostic_id: number;
	result_entry: ResultEntry;
    serial: boolean;
	turnaround: string | null;
    specimen: string | null;
	idexx_ref_lab_files: [{ action_id: number; ref_lab_pdf_url: string }] | null;

	type_id: 'D';
}

export const isInstanceOfExistingDiagInstruction = (
	item: any,
): item is ExistingDiagInstruction =>
	(item as ExistingDiagInstruction).diagnostic_id !== undefined;

export interface ExistingTaskInstruction extends BaseExistingInstruction {
	frequency: string;
	task_id: number;
    serial: boolean;

	type_id: 'T';
}

export const isInstanceOfExistingTaskInstruction = (
	item: any,
): item is ExistingTaskInstruction =>
	(item as ExistingTaskInstruction).task_id !== undefined;


export interface NonMedicalExistingOrder {
	id: number;
	non_medical_id: number;
	name: string;
	type_id: "N";
	unit: string;
	quantity: number;
	cents: number | null;
	is_free?: boolean;
}

export interface ExistingToGoMedInstruction extends BaseExistingInstruction {
	medication_id: number;
	tgh_id: number;
	frequency: string;
	controlled_drug: boolean;
	is_shown_on_tx_sheet: boolean;

	type_id: 'TGH';
}
export const isInstanceOfExistingToGoMedInstruction = (
	item: any,
): item is ExistingToGoMedInstruction =>
	item.medication_id !== undefined && item.type_id === "TGH";

/**
 * TODO
 * SEE INSTRUCTION_STATUSES COMMENT
 */
export type RESULT_ENTRY_WIDGETS = 'numeric' | 'small text' | 'big text' | 'idexx' | 'webpacs'; //determines what form element to render
export interface ResultEntry {
	widget: RESULT_ENTRY_WIDGETS;
	condition: string; //regex for field validation
	unit: string; //unit to display for form field
	label: string; //Label for form field
}

/**
 * TODO look up stuff with inheritence/mixins... should not have to duplicate all these fields for `Admins`
 */

export interface MedAdmin {
	id: number;
	order_id: number;
	due_at: number;
	status: INSTRUCTION_STATUSES;
	status_changed_at: number;
	status_changed_by: number;
	note: string;
}
export interface TreatmentSheetInstructionAction extends TimelineItemBase<any> {
	id: string;
	type_id: string;
	action_id: number;
	assigned_to: number | null;
	instruction_id: number;
	due_at: number;
	scheduled_time?: number;
	scheduled_by?: number;
	completed_at?: number;
	status: INSTRUCTION_STATUSES;
	status_transition_at: number;
	status_transition_by: number;
	note: string;

	value: number | string | boolean | null;
	content?: string; //ONLY FOR Progress Notes
	category?: string;
	isGhostAction?: boolean

	start_time: number;
	end_time: number;
	bgColor: string;
	dueBgColor: string;
	earlyBgColor: string;
	schedBgColor: string;
	missedColor: string;
	inProgressColor: string;
	skippedColor: string;
	created_at?: number;
}

export interface TreatmentSheetFluidAdditiveGroup extends TimelineItemBase<any> {
	type_id: 'FluidAdditive';
	created_at: number;
	fluidAdditives: FluidAdditive[];
	instruction_id: number;
}

export const isInstanceOfTreatmentSheetInstructionAction = (item: any): item is TreatmentSheetInstructionAction =>
	!!item && item.type_id !== 'FluidAdditive';

export const isInstanceOfTreatmentSheetFluidAdditiveGroup = (item: any): item is TreatmentSheetFluidAdditiveGroup =>
	!!item && item.type_id === 'FluidAdditive';

export interface TsGroup {
	id: number;
	title: string;
	isRoot: boolean;
}

export interface BaseMedicineFields {
	dose_unit: string;

	denominator_unit: string;
	denominator_value: number;
	numerator_unit: string | null;
	numerator_value: number | null;

	form: string;
}

interface APIFluids {
	route_id: string;
	rate_mcl_per_hr: number; //Rate of FLUID, sent to database
	initial_rate_mcl_per_hr: number;
}

interface FrontendFluids {
	route_id: string;
	rate_ml_per_hr: number; //Rate of FLUID infusion, set on pump
	initial_rate_ml_per_hr: number;
}

export interface FluidAdditive {
	id: number;
	additive_id: number;
	name: string;
	short_name: string | null;
	dose_qty: string;
	created_at: number;
	started_at: number | null;
	discontinued_at: number | null;
	initial_order: boolean;
}

export interface APIExistingFluidInstruction extends BaseExistingInstruction, APIFluids {
	type_id: 'F';
	fluids_id: number | null; //If running STRAIGHT medication from cabinet, this is null
	fluids_volume_ml: number; //Amount of fluid that starts in bag
	fluid_additives: FluidAdditive[];
}

export interface ExistingFluidInstruction extends BaseExistingInstruction, FrontendFluids {
	type_id: 'F';
	fluids_id: number | null; //If running STRAIGHT medication from cabinet, this is null
	fluids_volume_ml: number; //Amount of fluid that starts in bag
	fluid_additives: FluidAdditive[];
}

export const isInstanceOfAPIExistingFluidInstruction = (
	item: any,
): item is APIExistingFluidInstruction =>
	(item as APIExistingFluidInstruction).type_id === 'F' &&
	!!(item as APIExistingFluidInstruction).fluids_id &&
	(item as APIExistingFluidInstruction).rate_mcl_per_hr !== undefined;

export const isInstanceOfExistingFluidInstruction = (
	item: any,
): item is ExistingFluidInstruction =>
	(item as ExistingFluidInstruction).type_id === 'F' &&
	!!(item as ExistingFluidInstruction).fluids_id &&
	(item as ExistingFluidInstruction).rate_ml_per_hr !== undefined;

export const backEndFluidToFrontendFluid = (fluid: APIExistingFluidInstruction): ExistingFluidInstruction => {
	const { initial_rate_mcl_per_hr, rate_mcl_per_hr, actions, ...restofFluid } = fluid;
	const convertedActions = actions.map((item) => {
		const { value, ...restOfItem } = item;
		return {
			value: typeof value === "string" ? roundTo(parseFloat(value) / 1000, 2) : value,
			...restOfItem
		};
	});
	return {
		...restofFluid,
		actions: convertedActions,
		rate_ml_per_hr: roundTo(rate_mcl_per_hr / 1000, 2),
		initial_rate_ml_per_hr: roundTo(initial_rate_mcl_per_hr / 1000, 2),
	}
}

export interface ExistingOxygenTherapyInstruction extends BaseExistingInstruction {
	type_id: 'OT';
	fluids_id: number | null;
	oxygen_quantity: number;
	oxygen_unit: number;
	initial_oxygen_quantity: number;
}

export const isInstanceOfExistingOxygenTherapyInstruction = (
	item: any,
): item is ExistingOxygenTherapyInstruction =>
	(item as ExistingOxygenTherapyInstruction).type_id === 'OT';

interface APICri {
	rate_mcl_per_hr: number; //Rate of FLUID, sent to database
	initial_rate_mcl_per_hr: number;
}

interface FrontendCri {
	rate_ml_per_hr: number; //Rate of FLUID infusion, set on pump
	initial_rate_ml_per_hr: number;
}

export interface APIExistingCriInstruction extends BaseExistingInstruction, APICri {
	type_id: 'C';

	fluid?: CriSearchOption; //Full entry for fluid
	medication: MedicineSearchOption; //Full entry for medication

	label: string; // same as name
	name: string; //same as label

	fluids_id: number | null; //If running STRAIGHT medication from cabinet, this is null
	medication_id: number | null; //If running straight fluids, this is null
	fluids_volume_ml?: number;

	controlled_drug: boolean;

	dose: number;
	dose_unit: string;

	latest_patient_weight_kg: number | null;
	approx_patient_weight_kg: number | null;
	default_cri_unit: string | null;
	vetspire_id: number | null;
}

export interface ExistingCriInstruction extends BaseExistingInstruction, FrontendCri {
	type_id: 'C';

	fluid?: CriSearchOption; //Full entry for fluid
	medication: MedicineSearchOption; //Full entry for medication

	label: string; // same as name
	name: string; //same as label

	fluids_id: number | null; //If running STRAIGHT medication from cabinet, this is null
	medication_id: number | null; //If running straight fluids, this is null
	fluids_volume_ml?: number;

	controlled_drug: boolean;

	dose: number;
	dose_unit: string;

	latest_patient_weight_kg: number | null;
	approx_patient_weight_kg: number | null;
	default_cri_unit: string | null;
	vetspire_id: number | null;
}

export const isInstanceOfAPIExistingCriInstruction = (
	item: any,
): item is APIExistingCriInstruction =>
	(item as APIExistingCriInstruction).type_id === 'C' &&
	(item as APIExistingCriInstruction).medication_id !== null &&
	(!!(item as APIExistingCriInstruction).rate_mcl_per_hr ||
		!!(item as APIExistingCriInstruction).dose);

export const isInstanceOfExistingCriInstruction = (
	item: any,
): item is ExistingCriInstruction =>
	(item as ExistingCriInstruction).type_id === 'C' &&
	(item as ExistingCriInstruction).medication_id !== null &&
	(!!(item as ExistingCriInstruction).rate_ml_per_hr ||
		!!(item as ExistingCriInstruction).dose);

export const backEndCriToFrontendCri = (cri: APIExistingCriInstruction): ExistingCriInstruction => {
	const { initial_rate_mcl_per_hr, rate_mcl_per_hr, actions, ...restofCri } = cri;
	const convertedActions = actions.map((item) => {
		const { value, ...restOfItem } = item;
		return {
			value: typeof value === "string" ? roundTo(parseFloat(value) / 1000, 2) : value,
			...restOfItem
		};
	});
	return {
		...restofCri,
		actions: convertedActions,
		rate_ml_per_hr: roundTo(rate_mcl_per_hr / 1000, 2),
		initial_rate_ml_per_hr: roundTo(initial_rate_mcl_per_hr / 1000, 2),
	}
}

/**
 * Minimum fields for all "notes"
 */
export interface BaseNote {
	id: number; // note ID
	author_id: number; //may want author_name in addition
	visit_id: number;
	created_at: number | null; //Timestamp of note
	created_by: number | null; // user id
	note_date_time: number | null;
	recorded_at: any; //TODO RMEOVE
	content: string; //AKA content
	category: NoteCategories;
	internal?: boolean;
	file_name?: string | null
	author_name: string;
}

export interface DischargeNote {
	visit_id: number;
	content: string;
	locked: boolean;
}

export interface Macro {
    id: number;
	label: string;
    key_word: string;
	category: string;
    content: string;
    description: string | undefined;
    is_hidden: boolean;
    is_veg: boolean;
    created_by: string | undefined;
    created_at: number | undefined;
}

export enum ExamSectionLabel {
	'ears' = 'ears',
	'eyes' = 'eyes',
	'nose' = 'nose',
	'mouth' = 'mouth',
	'integument' = 'integument',
	'musculoskeletal' = 'musculoskeletal',
	'heart' = 'heart',
	'lungs' = 'lungs',
	'abdomen' = 'abdomen',
	'pulses' = 'pulses',
	'neurologic' = 'neurologic',
	'urogenital' = 'urogenital',
	'lymphnodes' = 'lymphnodes',
	'rectal' = 'rectal',
}

export interface PatientHistory {
	visit_id: number;
	created_at: number | null;
	created_by: number | null;
	content: string;
	note_date_time: number | null;
	author_name: string;
}

/**
 * Essentially just a data type for pre-submission Exam and each Exam Section
 * We don't have an id, created_at, etc. before we actually POST it
 */
export interface NewExam {
	sections: NewExamSection[];
}

/**
 * TODO
 * Exam statuses are kinda all over the place... there is an enum, a constant...  clean this up
 */
export type PHYSICAL_EXAM_STATUSES = 'normal' | 'abnormal' | null;

export interface NewExamSection {
	section: ExamSectionLabel;
	status: PHYSICAL_EXAM_STATUSES;
	note: string;
}

export interface Exam {
	created_at: number;
	created_by: number;
	note_date_time: number;
	id: string;
	sections: ExamSection[];
	note: string;
	category?: string;
	author_name?: string;
}

//Had a tough time thinking of a validator function, this prob works fine but anything is possible
export const isInstanceOfPhysicalExam = (item: any): item is Exam =>
	!!item && !!item.sections && item.sections.length > 0;

export interface ExamSection extends NewExamSection {
	exam_id: string;
}

export interface Complaint {
	id: number;
	common_name: string;
	medical_name: string;
	differentials: BaseDifferential[];
}

export interface Encounter {
	encounter_id: number;
	visit_id: number;
	doctor_id: number;
	start_time: number;
	end_time: number | null;
}

export interface ProblemList {
	id: number;
	start_date?: number;
	end_date?: number;
	created_at: number;
	created_by: number;
	complaint_id: number;
	common_name: string;
	medical_name?: string;
	chronic: boolean;
	presenting_complaint: boolean;
	differentials: Differential[]; //TODO maybe add in "| null", since it can be null...
}

export enum DifferentialStatuses {
	'confirmed' = 'confirmed',
	'suspected' = 'suspected',
	'ruled-out' = 'ruled-out',
	null = 'suspected',
}

export interface BaseDifferential {
	id: number;
	name: string;
	alternative_name: string;
}

export interface Differential {
	created_at: Date;
	created_by: string;
	differential_id: number;
	status: DifferentialStatuses;
	visit_id: number;
	name: string;
	count: number; //Not in original response, frontend adds this
}

export interface DischargeCourtesyFollowUp {
	type: 'text' | 'email';
	message: string;
	sendDate: number;
	attachSurvey: boolean;
	attachChart: boolean;
}

export interface DischargeRdvmFollowUp {
	date?: number; //Date for Owner to schedule rDVM visit, optional (don't ALWAYS need follow-up)
	attachChart: boolean; //Send chart to rdvm?
	message?: string;
}

export interface Discharge {
	note: string; //The ACTUAL discharge note
	followUp: Boolean;
	followUpTime?: string;
	followUpNote?: string;
	recorded_at: Date;
	recorded_by: string;
}

export interface EstimateOption {
	value: string;
	price: number;
}

export type NOTIFICATION_SENDER_TYPES = 'rdvm' | 'owner' | 'result'; //What entity sent the notification?

//TODO when API gets written, reconcile differences
export interface Notification {
	id: number; //Notification ID
	pet_id: string;
	sender_id: number; //What is the ID of the sender? I think it can be either number or string
	sender_type: NOTIFICATION_SENDER_TYPES;
	received_at: number; //When was the note received?
	read: boolean; //Has the note been read yet?
	read_by?: number; //Which doctor read this note?
	read_at?: number;
	message: string;
}

export enum TimelineItemType {
	Instruction = 'instruction',
	Action = 'action',
	Result = 'result',
	ProgressNote = 'prog_note',
}

export interface PimsTimelineItem {
	item_id?: number; //i.e. diagnostic_id for the base item. Not all TimelineItems will have one.
	event_id: number; //i.e. unique ID for that item
	item_type: TimelineItemType;

	timeline_date: number; //What date/time to use/show for the timeline

	title: string; //Name of Event to display on timeline
	content: string; //More details of Event to display on timeline
	author_id: number; //Author, created_by, etc.
}

export interface DiagnosticCard {
	instructionId: number;
	diagnosticId: number;
	name: string;
	dueAt: number;
	value: number | string | boolean | null;
	action: InstructionAction;
	existingInstruction: ExistingDiagInstruction;
}

export interface MedicineCard {
	instructionId: number;
	medicationId: number;
	name: string;
	dueAt: number;
	action: InstructionAction;
	existingInstruction: ExistingMedInstruction;
}

export interface VisitPageVisit {
	visit_id: number;
	pet_id: string;
	pet_name: string;
	pet_species?: string;
	pet_breed?: string;
	pet_sex?: string;
	pet_is_intact?: boolean;
	pet_deceased_at?: number;
	doctor_id?: number;
	doctor_first_name?: string;
	doctor_last_name?: string;
	owner_last_name?: string;
	status: string;
	hospitalization_level?: HOSPITALIZATION_LEVELS;
	created_at: number;
	finalized_at?: number;
}

export interface APIAccountsReceivableVisit {
	visit_id: number;
	pet_id: string;
	pet_name: string;
	owner_first_name: string;
	owner_last_name: string;
	created_at: number;
	invoice_total_cents: number;
	amount_total_cents: number;
	ar_status: Exclude<VISIT_AR_STATUSES, 'awaiting_payment'> | null;
}

export interface AccountsReceivableVisit {
	visit_id: number;
	pet_id: string;
	pet_name: string;
	owner_name: string;
	created_at: number;
	invoice_total: number;
	amount_due: number;
	ar_status: VISIT_AR_STATUSES;
}

export interface VCPRunSummary {
	code: string;
	ivlsSerialNumber: string;
	name: string;
}

export interface VCPExternalResult {
	key: React.Key;
	clientLastName: string;
	diagnosticSetId: string;
	modality: string;
	orderedDate: string;
	patientName: string;
	requisitionId?: string;
	resultId: string;
	runSummaries: Array<VCPRunSummary>;
	status: string;
	updatedDate: string;
	veterinarian: string;
}

/**
 * Type checks
 */

export const isInstanceOfMedicineInstruction = (
	item: any,
): item is ExistingMedInstruction =>
	!!item &&
	!!(item as ExistingMedInstruction).dose &&
	(item as ExistingMedInstruction).type_id === 'M';

export const isInstanceOfCRIInstruction = (
	item: any,
): item is ExistingCriInstruction =>
	!!item &&
	!!(item as ExistingCriInstruction).dose &&
	item.type_id === 'C';

export const isInstanceOfFluidsInstruction = (
	item: any,
): item is ExistingFluidInstruction =>
	!!item &&
	!!(item as ExistingFluidInstruction).rate_ml_per_hr &&
	item.type_id === 'F';

export const isInstanceOfBaseInstruction = (
	item: any,
): item is BaseExistingInstruction =>
	isInstanceOfMedicineInstruction(item) ||
	isInstanceOfExistingDiagInstruction(item) ||
	isInstanceOfExistingFluidInstruction(item) ||
	isInstanceOfExistingTaskInstruction(item) ||
	isInstanceOfExistingToGoMedInstruction(item) ||
	isInstanceOfExistingCriInstruction(item) ||
	isInstanceOfExistingOxygenTherapyInstruction(item);

export const isInstanceOfProblemList = (item: any): item is ProblemList =>
	(item as ProblemList).complaint_id !== undefined &&
	(item as ProblemList).id !== undefined;

export type TsGroupType = | (BaseExistingInstruction & {
	group: number;
	id: number;
	title: string;
	isRoot: boolean;
	parent: string;
})
	| {
		id: number | string;
		title: string;
		isRoot: boolean;
		group?: number | string;
		parent?: string;
	}

export type instructionType =
	| ExistingMedInstruction
	| ExistingDiagInstruction
	| ExistingFluidInstruction
	| ExistingCriInstruction
	| ExistingTaskInstruction
	| ExistingToGoMedInstruction
	| ExistingOxygenTherapyInstruction;

export interface MedicationDose {
	dose: number | null;
	unit: string | null;
}
// Todo investigate all type definitions
export const isInstanceOfMedicineInstructionCorrect = ( // Todo Replace isInstanceOfMedicineInstruction with this after testing
	item: any,
): item is ExistingMedInstruction =>
	!!item &&
	(item as ExistingMedInstruction).type_id === 'M';

export interface ConsentDocumentsList {
	id: string;
	created_at: number;
	status: string;
	prefix_char: string;
	approvedBurialForm?: BurialApprovedForm;
}

export interface ModifyReferralSourceBody {
	customer_id: string;
	how_did_you_hear_about_us: string | null;
	additional_info: string | null;
	referred_by_primary_vet: string | null;
}

export interface isPendoActive {
	active: boolean;
	showbadge: boolean;
}

export interface RecentVital {
	instruction_id: number;
	diagnostic_id: number;
	name: string;
	unit: string | null;
	initialAction: InstructionAction;
	initialActionDueAt: number;
	lastAction: InstructionAction;
	condition: string;
	discontinued_at: number | null;
}

export const UnitConversions: [string, string, number][] = [
	['tube', 'micro-tube', 1000],
    ['hours', 'micro-hours', 1000],
	['mEq', 'micro-mEq', 1000],
	['mg', 'micro-mg', 1000],
	['g', 'micro-g', 1000],
	['puff', 'micro-puff', 1000],
	['spray', 'micro-spray', 1000],
	['drop', 'micro-drop', 1000],
	['ml', 'micro-ml', 1000],
	['u', 'micro-u', 1000],
	['syringe', 'micro-syringe', 1000],
	['mcg', 'micro-mcg', 1000],
	['dose', 'micro-dose', 1000],
	['capsule', 'micro-capsule', 1000],
	['tablet', 'micro-tablet', 1000],
	['wipe', 'micro-wipe', 1000],
	['packet', 'micro-packet', 1000],
	['kit', 'micro-kit', 1000],
	['bottle', 'micro-bottle', 1000],
	['tube/kg', 'micro-tube/kg', 1000],
	['bottle/kg', 'micro-bottle/kg', 1000],
	['mEq/kg', 'micro-mEq/kg', 1000],
	['mg/kg', 'micro-mg/kg', 1000],
	['g/kg', 'micro-g/kg', 1000],
	['puff/kg', 'micro-puff/kg', 1000],
	['spray/kg', 'micro-spray/kg', 1000],
	['drop/kg', 'micro-drop/kg', 1000],
	['ml/kg', 'micro-ml/kg', 1000],
	['u/kg', 'micro-u/kg', 1000],
	['syringe/kg', 'micro-syringe/kg', 1000],
	['mcg/kg', 'micro-mcg/kg', 1000],
	['dose/kg', 'micro-dose/kg', 1000],
	['capsule/kg', 'micro-capsule/kg', 1000],
	['tablet/kg', 'micro-tablet/kg', 1000],
	['wipe/kg', 'micro-wipe/kg', 1000],
	['packet/kg', 'micro-packet/kg', 1000],
	['kit/kg', 'micro-kit/kg', 1000],
	['tube/kg/hr', 'micro-tube/kg/hr', 1000],
	['bottle/kg/hr', 'micro-bottle/kg/hr', 1000],
	['mEq/kg/hr', 'micro-mEq/kg/hr', 1000],
	['mg/kg/hr', 'micro-mg/kg/hr', 1000],
	['g/kg/hr', 'micro-g/kg/hr', 1000],
	['puff/kg/hr', 'micro-puff/kg/hr', 1000],
	['spray/kg/hr', 'micro-spray/kg/hr', 1000],
	['drop/kg/hr', 'micro-drop/kg/hr', 1000],
	['ml/kg/hr', 'micro-ml/kg/hr', 1000],
	['u/kg/hr', 'micro-u/kg/hr', 1000],
	['syringe/kg/hr', 'micro-syringe/kg/hr', 1000],
	['mcg/kg/hr', 'micro-mcg/kg/hr', 1000],
	['dose/kg/hr', 'micro-dose/kg/hr', 1000],
	['capsule/kg/hr', 'micro-capsule/kg/hr', 1000],
	['tablet/kg/hr', 'micro-tablet/kg/hr', 1000],
	['wipe/kg/hr', 'micro-wipe/kg/hr', 1000],
	['packet/kg/hr', 'micro-packet/kg/hr', 1000],
	['kit/kg/hr', 'micro-kit/kg/hr', 1000],
	['bag', 'micro-bag', 1000],
	['vial', 'micro-vial', 1000],
];

export const convertToAPI = (
	dose: number,
	dose_unit: string,
	pricing_unit?: string | null,
    pricing_unit_size?: number | null,
): {
	dose: number;
	dose_unit: string;
	conversionFactor: number;
	pricing_unit: string | null;
    pricing_unit_size: number | null;
    pricingUnitConversionFactor: number;
} => {
	let doseReturn = dose;
    let doseUnitReturn = dose_unit;
    let conversionFactorReturn = 1;
    let pricingUnitReturn = null;
    let pricingUnitSizeReturn = null;
    let pricingUnitConversionFactor = 1;
	const conversion = UnitConversions.find(conversion => conversion[0] === dose_unit)
	if (conversion) {
		const [localUnit, apiUnit, conversionFactor] = conversion
		doseReturn = Math.round(roundTo(dose, 3) * conversionFactor);
		doseUnitReturn = apiUnit;
		conversionFactorReturn = conversionFactor;
	} else {
		alert(`WARNING conversion factor to Api not set for ${dose_unit}`);
	}

	if (pricing_unit && pricing_unit_size) {
		const pricingUnitConversions = UnitConversions.find((conversion) => conversion[0] === pricing_unit);
		if (pricingUnitConversions) {
			const [localPricingUnit, apiPricingUnit, conversionFactor] = pricingUnitConversions;
			pricingUnitReturn = apiPricingUnit;
			pricingUnitSizeReturn = Math.round(roundTo(pricing_unit_size, 3) * conversionFactor);
			pricingUnitConversionFactor = conversionFactor;
		} else {
			alert(`WARNING conversion factor to Frontend not set for ${pricing_unit}`);
		}
	}
	
	return {
		dose: doseReturn,
		dose_unit: doseUnitReturn,
		conversionFactor: conversionFactorReturn,
		pricing_unit: pricingUnitReturn,
        pricing_unit_size: pricingUnitSizeReturn,
        pricingUnitConversionFactor: pricingUnitConversionFactor,
	}
}

export const convertToLocal = (
    dose: number | null,
    dose_unit: string,
    pricing_unit?: string | null,
    pricing_unit_size?: number | null,
): {
    dose: number;
    dose_unit: string;
    conversionFactor: number;
    pricing_unit: string | null;
    pricing_unit_size: number | null;
    pricingUnitConversionFactor: number;
} => {
	let doseReturn = 0;
    let doseUnitReturn = dose_unit;
    let conversionFactorReturn = 1;
    let pricingUnitReturn = null;
    let pricingUnitSizeReturn = null;
    let pricingUnitConversionFactor = 1;
    const conversion = UnitConversions.find((conversion) => conversion[1] === dose_unit);
    if (conversion) {
        const [localUnit, apiUnit, conversionFactor] = conversion;
        doseReturn = dose ? roundTo(dose / conversionFactor, 3) : 0;
        doseUnitReturn = localUnit;
        conversionFactorReturn = conversionFactor;
    } else {
        alert(`WARNING conversion factor to Frontend not set for ${dose_unit}`);
    }

	if (pricing_unit && pricing_unit_size) {
		const pricingUnitConversions = UnitConversions.find((conversion) => conversion[1] === pricing_unit);
		if (pricingUnitConversions) {
			const [localPricingUnit, apiPricingUnit, conversionFactor] = pricingUnitConversions;
			pricingUnitReturn = localPricingUnit;
			pricingUnitSizeReturn = roundTo(pricing_unit_size / conversionFactor, 3);
			pricingUnitConversionFactor = conversionFactor;
		} else {
			alert(`WARNING conversion factor to Frontend not set for ${pricing_unit}`);
		}
	}

    return {
        dose: doseReturn,
        dose_unit: doseUnitReturn,
        conversionFactor: conversionFactorReturn,
        pricing_unit: pricingUnitReturn,
        pricing_unit_size: pricingUnitSizeReturn,
        pricingUnitConversionFactor: pricingUnitConversionFactor,
    };
};


export const convertBillingItemToLocal = (billingItem: BillingItem): BillingItem => {
	if (billingItem.type_id === 'M' || billingItem.type_id === 'C' || billingItem.type_id === 'TGH') {
		const newBillingItem = { ...billingItem };
		const { dose, dose_unit, conversionFactor, pricing_unit, pricing_unit_size } =
			convertToLocal(0, billingItem.unit, billingItem.pricing_unit, billingItem.pricing_unit_size);
		newBillingItem.earned_revenue_line_items =
			newBillingItem.earned_revenue_line_items.map(
				(earnedRevenueItem): EarnedRevenueLineItem => {
					if (earnedRevenueItem.is_supplement === false) {
						return {
							doctor_id: earnedRevenueItem.doctor_id,
							id: earnedRevenueItem.id,
							instruction_id: earnedRevenueItem.instruction_id,
							non_medical_id: earnedRevenueItem.non_medical_id,
							is_supplement: earnedRevenueItem.is_supplement,
							price_cents: earnedRevenueItem.price_cents,
							quantity: roundTo(
								earnedRevenueItem.quantity /
								conversionFactor,
								3,
							),
							unit: dose_unit === 'hours' ? dose_unit : pricing_unit || dose_unit,
							is_comped: earnedRevenueItem.is_comped,
							is_comped_created_at: earnedRevenueItem.is_comped_created_at,
							discount_basis: earnedRevenueItem.discount_basis,
							visit_discount_created_at: earnedRevenueItem.visit_discount_created_at,
							created_at: earnedRevenueItem.created_at,
						};

					}
					return earnedRevenueItem;
				},
			);
		newBillingItem.unit = dose_unit;
		newBillingItem.quantity = roundTo(
			newBillingItem.quantity / conversionFactor,
			3,
		);
		newBillingItem.pricing_unit = pricing_unit;
		newBillingItem.pricing_unit_size = pricing_unit_size;
		return newBillingItem;
	}
	return billingItem;
}


export const convertConsentFormEstimateItemToLocal = (consentFormEstimateItem: ConsentFormEstimateItem): ConsentFormEstimateItem => {
	if (consentFormEstimateItem.type_id === 'M' || consentFormEstimateItem.type_id === 'C' || consentFormEstimateItem.type_id === 'TGH') {
		const newEstimateItem = { ...consentFormEstimateItem }
		const ratio = consentFormEstimateItem.ratio ?? 1;

		const { dose, dose_unit, conversionFactor, pricing_unit, pricing_unit_size, pricingUnitConversionFactor } = convertToLocal(
            consentFormEstimateItem.dose ?? 0,
            consentFormEstimateItem.unit,
            consentFormEstimateItem.pricing_unit,
            consentFormEstimateItem.pricing_unit_size,
        );
		newEstimateItem.unit = dose_unit
		if (newEstimateItem.pricing_unit) {
			newEstimateItem.pricing_unit = pricing_unit;
            newEstimateItem.present_quantity = roundTo(newEstimateItem.present_quantity / pricingUnitConversionFactor, 3);
        } else {
			newEstimateItem.present_quantity = roundTo(newEstimateItem.present_quantity / conversionFactor, 3);
		}
		newEstimateItem.dose = dose;
		newEstimateItem.unit_cost_cents = newEstimateItem.unit_cost_cents * ratio;

		return newEstimateItem;
	}

	return consentFormEstimateItem;
}


export const convertEstimateItemToLocal = (estimateItem: CriEstimateItem | MedicalEstimateItem): CriEstimateItem | MedicalEstimateItem => {
	const newMedication = { ...estimateItem }

	const { dose, dose_unit, conversionFactor } = convertToLocal(estimateItem.dose, estimateItem.dose_unit);
	const { dose_unit: unit } = convertToLocal(estimateItem.dose, estimateItem.unit);
	newMedication.dose = dose
	newMedication.dose_unit = dose_unit
	newMedication.unit = unit
	newMedication.approved_quantity = roundTo((newMedication.approved_quantity / conversionFactor), 3)
	newMedication.complete_and_not_prior_quantity = roundTo((newMedication.complete_and_not_prior_quantity / conversionFactor), 3)
	newMedication.complete_and_prior_quantity = roundTo((newMedication.complete_and_prior_quantity / conversionFactor), 3)
	newMedication.completed_since_last_estimate_quantity = roundTo((newMedication.completed_since_last_estimate_quantity / conversionFactor), 3)
	newMedication.incomplete_and_not_prior_quantity = roundTo(((newMedication.incomplete_and_not_prior_quantity ?? 0) / conversionFactor), 3)
	newMedication.incomplete_and_prior_quantity = roundTo((newMedication.incomplete_and_prior_quantity / conversionFactor), 3)

	return newMedication;
}

export const convertEstimateItemToLocalNew = (estimateItem: CriEstimateItemNew | MedicalEstimateItemNew): CriEstimateItemNew | MedicalEstimateItemNew => {
	const newMedication = { ...estimateItem }

	const { dose, dose_unit, conversionFactor, pricing_unit, pricing_unit_size } = convertToLocal(
        estimateItem.dose,
        estimateItem.dose_unit,
        estimateItem.pricing_unit,
        estimateItem.pricing_unit_size,
    );
	const { dose_unit: unit } = convertToLocal(
        estimateItem.dose,
        estimateItem.unit,
        estimateItem.pricing_unit,
        estimateItem.pricing_unit_size,
    );
	newMedication.dose = dose
	newMedication.dose_unit = dose_unit
	newMedication.unit = unit
	newMedication.quantity = roundTo((newMedication.quantity / conversionFactor), 3)
	if (newMedication.pricing_unit && newMedication.pricing_unit_size) {
		newMedication.pricing_unit = pricing_unit;
		newMedication.pricing_unit_size = pricing_unit_size;
	}

	return newMedication;
}

export const convertInstructionSearchOptionToLocal = (instructionSearchOption: MedicineSearchOption): MedicineSearchOption => {
	const newMedication = { ...instructionSearchOption }

	const { dose, dose_unit, conversionFactor, pricing_unit, pricing_unit_size, pricingUnitConversionFactor } = convertToLocal(
        instructionSearchOption.default_dose,
        instructionSearchOption.dose_unit,
        instructionSearchOption.pricing_unit,
        instructionSearchOption.pricing_unit_size,
    );
	newMedication.default_dose = dose
	newMedication.dose_unit = dose_unit
	if (instructionSearchOption.denominator_unit === instructionSearchOption.dose_unit) {
		newMedication.denominator_unit = dose_unit
	}
	else if (instructionSearchOption.numerator_unit === instructionSearchOption.dose_unit) {
		newMedication.numerator_unit = dose_unit
	}
	if (newMedication.default_dose_unit) {
		const { dose_unit: defaultDoseUnit } = convertToLocal(0, newMedication.default_dose_unit);
		newMedication.default_dose_unit = defaultDoseUnit;
	}
	if (newMedication.pricing_unit && newMedication.pricing_unit_size) {
		newMedication.pricing_unit = pricing_unit;
		newMedication.pricing_unit_size = pricing_unit_size;
	}
	return newMedication;

}

export const convertMainTsItemToLocal = (mainTsItem: ExistingCriInstruction | ExistingMedInstruction): ExistingCriInstruction | ExistingMedInstruction => {
	const newMedication = { ...mainTsItem }

	const { dose, dose_unit } = convertToLocal(mainTsItem.dose, mainTsItem.dose_unit);
	newMedication.dose = dose;
	newMedication.dose_unit = dose_unit;

	return newMedication;

}


export const convertInstructionItemToLocal = (instructionItem: ExistingCriInstruction | ExistingMedInstruction): ExistingCriInstruction | ExistingMedInstruction => {
	const newMedication = { ...instructionItem }

	const { dose, dose_unit } = convertToLocal(instructionItem.dose, instructionItem.dose_unit);
	newMedication.dose = dose
	newMedication.dose_unit = dose_unit

	if (isInstanceOfMedicineInstruction(instructionItem) && isInstanceOfMedicineInstruction(newMedication)) {
		if (instructionItem.denominator_unit === instructionItem.dose_unit) {
			newMedication.denominator_unit = dose_unit
		}
	}

	return newMedication;

}


export const convertPackageInstructionItemToLocal = (instructionPackageItem: PackageMedInstruction | PackageCriInstruction): PackageMedInstruction | PackageCriInstruction => {
	if (isInstanceOfPackageMedInstruction(instructionPackageItem)) {
		const newMedication = { ...instructionPackageItem }
		const { dose, dose_unit, pricing_unit, pricing_unit_size } = convertToLocal(
			newMedication.dose,
			newMedication.dose_unit,
			newMedication.pricing_unit,
			newMedication.pricing_unit_size,
		);
		newMedication.dose = dose;
		newMedication.dose_unit = dose_unit;
		newMedication.pricing_unit = pricing_unit;
		newMedication.pricing_unit_size = pricing_unit_size;

		if (newMedication.default_dose_unit) {
			const { dose: defaultDose, dose_unit: defaultDoseUnit } = convertToLocal(newMedication.default_dose, newMedication.default_dose_unit);
			newMedication.default_dose = defaultDose;
			newMedication.default_dose_unit = defaultDoseUnit;
		}
		if (newMedication.denominator_unit === instructionPackageItem.dose_unit) {
			newMedication.denominator_unit = dose_unit;
		}
		else if (newMedication.numerator_unit === instructionPackageItem.dose_unit) {
			newMedication.numerator_unit = dose_unit;
		}
		return newMedication;
	}
	else {
		const newMedication = { ...instructionPackageItem }
		const { dose, dose_unit } = convertToLocal(instructionPackageItem.dose, instructionPackageItem.dose_unit);
		newMedication.dose = dose;
		newMedication.dose_unit = dose_unit;
		return newMedication;
	}
}
