
import { Result } from 'antd'
import result from './result.module.css'
import image from 'assets/img/VEG_Marquee_CMYK_BLACK.png'
import React from 'react';

interface ConsentFormSuccessProps {
  title?: string;
  subTitle?: string;
  extraContent?: JSX.Element;
}

export const ConsentFormSuccess: React.FC<ConsentFormSuccessProps> = ({ title, subTitle, extraContent }) => {
  return (
    <div className={result.resultWrapper}>
      <Result
        status='success'
        title={
          <div data-cy={'successHeadingTitle'} className={result.successTitle}>
            {title ?? 'All set!'}
          </div>
        }
        subTitle={
          <div className={result.resultSubTitle}>
            {subTitle ?? 'We have successfully received your signed estimate.'}
          </div>
        }
        extra={[
          <>
            {extraContent}
            <div className={result.vegMarquee}>
                <img src={image} />
            </div>
          </>
        ]}
      />
    </div>
  )
}

interface ConsentFormErrorProps { }

export const ConsentFormError: React.FC<ConsentFormErrorProps> = () => {
  return (
    <div className={result.resultWrapper}>
      <Result
        status='error'
        title={<div data-cy={'errorHeadingTitle'} className={result.successHeading}>The form cannot be loaded.</div>}
        subTitle={
          <div className={result.resultSubTitle}>
            Please see our team for assistance.
          </div>
        }
        extra={[
          <div className={result.vegMarquee}>
            <img src={image} />
          </div>
        ]}
      />
    </div>
  )
}
