import { RuleObject } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import moment from "moment";
import {capitalizeFirstLetter} from "./stringFormatting"

export const validateFieldIsGreaterThan =  (name: string, numberToCompareAgainst: number = 0) => async (_: RuleObject, value: any) => {
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined || value === ''){
        return Promise.resolve();
    }
    if (isNaN(value)){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a number`));
    }
    const numberValue = parseFloat(value);
    if (numberValue > numberToCompareAgainst){
        return Promise.resolve();
    }
    else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be greater than ${numberToCompareAgainst}`));
    }

}

export const validateFieldIsLessThan =  (name: string, numberToCompareAgainst: number = 0) => async (_: RuleObject, value: any) => {
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined || value === ''){
        return Promise.resolve();
    }
    if (isNaN(value)){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a number`));
    }
    const numberValue = parseFloat(value);
    if (numberValue < numberToCompareAgainst){
        return Promise.resolve();
    }
    else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be less than ${numberToCompareAgainst}`));
    }

}

export const validateFieldIsNotDecimal =  (name: string) => async (_: RuleObject, value: any) => {
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined || value === ''){
        return Promise.resolve();
    }
    const numberValue = parseFloat(value);
    if (!Number.isInteger(numberValue)) {
      return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a whole number`));
    } else {
      return Promise.resolve();
    }
}

export const validateFieldIsAfterDate =  (name: string, dateToCompareAgainst?: moment.Moment) => async (_: RuleObject, value: any) => {
    if (dateToCompareAgainst === undefined){
        dateToCompareAgainst = moment();
    }
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined){
        return Promise.resolve();
    }
    let dateValue = moment(value);
    if (!moment.isMoment(dateValue) || !dateValue.isValid()){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a valid Date`));
    }
    if (dateValue.isAfter(dateToCompareAgainst)){
        return Promise.resolve();
    }
    else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be after ${dateToCompareAgainst.format('YYYY-MM-DD, h:mma')}`));
    }

}

// This is somewhat specific but will be used all over so it should get its own function

export const endTimeGreaterThanStartTime =
    ({ getFieldValue }: {getFieldValue: (name: NamePath) => any}) => ({
        validator(_: RuleObject, value: any) {
            const startTime = getFieldValue('start_time');
            if (moment.isMoment(startTime) && startTime.isValid()){
                const validatorFunction =  validateFieldIsAfterDate('end time', startTime);
                return validatorFunction(_, value)
            }else  {
                return Promise.resolve();
            }
        },
    })

export const validateFieldIsBeforeDate =  (name: string, dateToCompareAgainst?: moment.Moment) => async (_: RuleObject, value: any) => {
    if (dateToCompareAgainst === undefined){
        dateToCompareAgainst = moment();
    }
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined){
        return Promise.resolve();
    }
    let dateValue = moment(value);
    if (!moment.isMoment(dateValue) || !dateValue.isValid()){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a valid Date`));
    }
    if (dateValue.isBefore(dateToCompareAgainst)){
        return Promise.resolve();
    }
    else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be before ${dateToCompareAgainst.format('YYYY-MM-DD, h:mma')}`));
    }

}


export const validateBirthDate =  (name: string, deceasedAtDate?: moment.Moment) => async (_: RuleObject, value: any) => {
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined){
        return Promise.resolve();
    }

    let dateValue = moment(value);
    if (!moment.isMoment(dateValue) || !dateValue.isValid()){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a valid Date`));
    }

    if (!!deceasedAtDate && dateValue.isAfter(deceasedAtDate)){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be before ${deceasedAtDate.format('YYYY-MM-DD, h:mma')}`));
    }

    const actualDate = moment();
    if (dateValue.isBefore(actualDate)){
        return Promise.resolve();
    } else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be before ${actualDate.format('YYYY-MM-DD, h:mma')}`));
    }
}

export const validateNoteDateTime =  (name: string) => async (_: RuleObject, value: any) => {
    if (value === null || value === undefined){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a valid date time`));
    }

    let dateTimeValue = moment(value);
    if (!moment.isMoment(dateTimeValue) || !dateTimeValue.isValid()){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a valid date time`));
    }

    const actualDateTime = moment();
    if (dateTimeValue.isBefore(actualDateTime)){
        return Promise.resolve();
    } else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be before ${actualDateTime.format('YYYY-MM-DD, h:mma')}`));
    }
}



export const validateDeceasedDate =  (name: string, birthDate?: moment.Moment) => async (_: RuleObject, value: any) => {
    // This isn't a required check so if the value is not set then we shouldn't fail
    if (value === null || value === undefined){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be entered`));
    }

    let dateValue = moment(value);
    if (!moment.isMoment(dateValue) || !dateValue.isValid()){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be a valid Date`));
    }

    if (!!birthDate && dateValue.isBefore(birthDate)){
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be on or after ${birthDate.format('YYYY-MM-DD')}`));
    }

    const actualDate = moment();
    if (dateValue.isBefore(actualDate)){
        return Promise.resolve();
    } else {
        return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must be on or before ${actualDate.format('YYYY-MM-DD')}`));
    }
}

export const validateFieldIsShorterThan = (name: string, lengthToCompareAgainst: number) => async (_: RuleObject, value: any) => {
    if (value === null || value === undefined) {
        return Promise.resolve();
    }
    if (value.length < lengthToCompareAgainst) {
        return Promise.resolve();
    }
    return Promise.reject(new Error(`${capitalizeFirstLetter(name)} must not be longer than ${lengthToCompareAgainst - 1} characters`));
}
