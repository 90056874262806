import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { setShowInfoModalAfterClose } from 'store/slices/visitHeaderSlice';
import { checkCookieExists } from 'utils/miscFuncs';
import { openChartLockModal } from 'views/visit/VisitHeader/modals';
import { useFormSubmission } from './useFormSubmission';
import usePatientChecklist from './usePatientChecklist';
import { useAppDispatch } from './useRedux';
import { useSearchRdvmQuery } from 'services/rdvmService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';

interface IUseCloseVisit {
    visitId: number;
    modalForm: FormInstance<any>;
    setModalState: Function;
}

const useCloseVisit = ({
    visitId,
    setModalState,
    modalForm,
}: IUseCloseVisit) => {
    const { data: currentVisit } = useGetVisitByIdQuery(visitId);
    const { data: allRdvms } = useSearchRdvmQuery(visitId, BASE_QUERY_OPTIONS);
    const { data: patientData } = useGetPatientByIdQuery(
        currentVisit?.pet_id ?? '',
        { skip: !currentVisit?.pet_id },
    );
    const { data: existingCustomer } = useGetCustomerByIdQuery(
        currentVisit?.customer_id ?? '',
        { skip: !currentVisit?.customer_id },
    );
    const visitMissingItems = usePatientChecklist({ visit_id: visitId });
    const [onFinishHook] = useFormSubmission();
    const dispatch = useAppDispatch();

    const showInfoModalAfterClose = () => {
        if (!checkCookieExists('do_not_show_accessing_medical_chart_modal')) {
            dispatch(setShowInfoModalAfterClose(true));
        }
    };

    const confirmCloseVisit = () => {
        if (!visitMissingItems.some(item => item.isMissing)) {
            openChartLockModal(
                setModalState,
                modalForm,
                onFinishHook,
                currentVisit,
                patientData,
                existingCustomer,
                showInfoModalAfterClose,
                allRdvms,
            );
            return;
        }
        confirm({
            title: 'Visit may have missing information',
            icon: <ExclamationCircleOutlined style={{ color: '#E7202E' }} />,
            content: (
                <>
                    <p>
                        The following information is missing or incomplete from{' '}
                        {patientData?.name}`s visit:
                    </p>

                    <ul>
                        {visitMissingItems.map((item) =>
                            item.isMissing ? <li>{item.name}</li> : null,
                        )}
                    </ul>

                    <p>
                        Are you sure you want to close this visit for{' '}
                        {patientData?.name}?
                    </p>
                </>
            ),
            okText: 'Yes, Continue',
            okType: 'danger',
            okButtonProps: {
                type: 'primary',
                style: { backgroundColor: '#E7202E' },
            },
            cancelText: 'No, Cancel',
            onOk() {
                openChartLockModal(
                    setModalState,
                    modalForm,
                    onFinishHook,
                    currentVisit,
                    patientData,
                    existingCustomer,
                    showInfoModalAfterClose,
                    allRdvms
                );
            },
        });
    };

    return [confirmCloseVisit];
};

export default useCloseVisit;
