import { Form, FormInstance, Input } from 'antd';
import { DischargeExistingInstruction } from 'utils/dataTypes';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import morning_icon from 'assets/img/morning_icon.png';
import afternoon_icon from 'assets/img/afternoon_icon.png';
import night_icon from 'assets/img/night_icon.png';
import { transformTogoMed } from './utils';
import { ToGoMedRules } from 'utils/types/validations';
import {
    validateFieldIsGreaterThan,
    validateFieldIsShorterThan,
} from 'utils/validationFuncs';
import { roundTo } from 'utils/formatFuncs';

interface ToGoMedFormProps {
    form: FormInstance<any>;
    existingTogoMed?: DischargeExistingInstruction;
    fullSelectedItem?: MedicineSearchOption;
}

export const ToGoMedForm = ({
    existingTogoMed,
    fullSelectedItem,
}: ToGoMedFormProps) => {
    const { dispense_unit } =
        fullSelectedItem || fullSelectedItem
            ? transformTogoMed(fullSelectedItem || existingTogoMed)
            : { dispense_unit: '' };

    const labelCol = { span: 6 };
    const wrapperCol = { span: 18 };

    const toGoMedRules: ToGoMedRules = {
        dispense_value: [
            { required: true, message: 'Quantity is required' },
            { validator: validateFieldIsGreaterThan('quantity') },
            {
                validator: (_, value) => {
                    return value % 1 !== 0 && fullSelectedItem?.calculator_type === 'prepackaged'
                        ? Promise.reject('Quantity cannot be decimal')
                        : Promise.resolve();
                },
            },
        ],
        next_dose: [{ required: true, message: 'Next Dose is required' }],
        instructions: [
            { required: true, message: 'Instructions are required' },
            { validator: validateFieldIsShorterThan('instructions', 136) },
        ],
        morning_doses: [
            { validator: validateFieldIsGreaterThan('morning dose') },
        ],
        afternoon_doses: [
            { validator: validateFieldIsGreaterThan('afternoon dose') },
        ],
        night_doses: [
            { validator: validateFieldIsGreaterThan('night dose') },
        ],
    };

    return (
        <>
            <Form.Item
                preserve={false}
                name='dispense_value'
                label='Quantity'
                data-cy={'quantityInput'}
                style={{ width: '100%' }}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                initialValue={existingTogoMed?.dispense_value}
                rules={toGoMedRules.dispense_value}
            >
                <Input
                    disabled={false}
                    autoComplete={'off'}
                    placeholder=''
                    suffix={dispense_unit}
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='next_dose'
                label='Next Dose'
                data-cy={'nextDoseInput'}
                style={{ width: '100%' }}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                initialValue={existingTogoMed?.next_dose}
                rules={toGoMedRules.next_dose}
            >
                <Input
                    disabled={false}
                    autoComplete={'off'}
                    placeholder='6 hours'
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='morning_doses'
                label='Morning'
                data-cy={'morningInput'}
                style={{ width: '100%' }}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                initialValue={roundTo(existingTogoMed?.morning_doses, 3)}
                rules={toGoMedRules.morning_doses}
            >
                <Input
                    disabled={false}
                    autoComplete={'off'}
                    suffix={
                        <img
                            src={morning_icon}
                            style={{
                                width: '18px',
                                pointerEvents: 'none',
                            }}
                        />
                    }
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='afternoon_doses'
                label='Afternoon'
                data-cy={'afternoonInput'}
                style={{ width: '100%' }}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                initialValue={roundTo(existingTogoMed?.afternoon_doses, 3)}
                rules={toGoMedRules.afternoon_doses}
            >
                <Input
                    disabled={false}
                    autoComplete={'off'}
                    suffix={
                        <img
                            src={afternoon_icon}
                            style={{
                                width: '18px',
                                pointerEvents: 'none',
                            }}
                        />
                    }
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='night_doses'
                label='Night'
                data-cy={'nightInput'}
                style={{ width: '100%' }}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                initialValue={roundTo(existingTogoMed?.night_doses, 3)}
                rules={toGoMedRules.night_doses}
            >
                <Input
                    disabled={false}
                    autoComplete={'off'}
                    suffix={
                        <img
                            src={night_icon}
                            style={{
                                width: '12px',
                                pointerEvents: 'none',
                            }}
                        />
                    }
                />
            </Form.Item>
            <Form.Item
                preserve={false}
                name='instructions'
                label='Instructions'
                data-cy={'instructionsInput'}
                style={{ width: '100%' }}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                initialValue={existingTogoMed?.instructions}
                rules={toGoMedRules.instructions}
            >
                <Input disabled={false} autoComplete={'off'} placeholder='' />
            </Form.Item>
        </>
    );
};
