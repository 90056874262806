import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.7657 5.43745H9.76565C9.6969 5.43745 9.64065 5.4937 9.64065 5.56245V6.31245C9.64065 6.3812 9.6969 6.43745 9.76565 6.43745H13.7657C13.8344 6.43745 13.8907 6.3812 13.8907 6.31245V5.56245C13.8907 5.4937 13.8344 5.43745 13.7657 5.43745ZM11.6719 7.56245H9.76565C9.6969 7.56245 9.64065 7.6187 9.64065 7.68745V8.43744C9.64065 8.5062 9.6969 8.56244 9.76565 8.56244H11.6719C11.7407 8.56244 11.7969 8.5062 11.7969 8.43744V7.68745C11.7969 7.6187 11.7407 7.56245 11.6719 7.56245ZM6.45784 4.03901H5.78128C5.6844 4.03901 5.60628 4.11714 5.60628 4.21401V8.08901C5.60628 8.14526 5.63284 8.19682 5.67815 8.22963L8.00471 9.92651C8.08284 9.98276 8.19221 9.96713 8.24847 9.88901L8.65003 9.34057V9.33901C8.70628 9.26088 8.68909 9.15151 8.61096 9.09526L6.63128 7.66401V4.21401C6.63284 4.11714 6.55315 4.03901 6.45784 4.03901Z'
            fill='#262626'
        />
        <path
            d='M11.5749 9.52958H10.6718C10.5843 9.52958 10.5015 9.57489 10.4546 9.64989C10.2562 9.96395 10.0249 10.2546 9.75932 10.5202C9.30151 10.978 8.7687 11.3374 8.17651 11.5874C7.56245 11.8468 6.91089 11.978 6.23901 11.978C5.56557 11.978 4.91401 11.8468 4.30151 11.5874C3.70932 11.3374 3.17651 10.978 2.7187 10.5202C2.26089 10.0624 1.90151 9.52958 1.65151 8.93739C1.39214 8.32489 1.26089 7.67333 1.26089 6.99989C1.26089 6.32645 1.39214 5.67645 1.65151 5.06239C1.90151 4.4702 2.26089 3.93739 2.7187 3.47958C3.17651 3.02177 3.70932 2.66239 4.30151 2.41239C4.91401 2.15302 5.56714 2.02177 6.23901 2.02177C6.91245 2.02177 7.56401 2.15302 8.17651 2.41239C8.7687 2.66239 9.30151 3.02177 9.75932 3.47958C10.0249 3.7452 10.2562 4.03583 10.4546 4.34989C10.5015 4.42489 10.5843 4.4702 10.6718 4.4702H11.5749C11.6828 4.4702 11.7515 4.3577 11.7031 4.26239C10.6843 2.23583 8.6187 0.918641 6.31245 0.892078C2.93589 0.849891 0.1312 3.61395 0.12495 6.98739C0.1187 10.3671 2.85776 13.1093 6.23745 13.1093C8.57339 13.1093 10.6734 11.7874 11.7031 9.73739C11.7515 9.64208 11.6812 9.52958 11.5749 9.52958Z'
            fill='#262626'
        />
    </svg>
);

export const QuickActionIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={svg} {...props} style={{ lineHeight: 0 }} />;
