import { CheckCircleOutlined, IssuesCloseOutlined, ClockCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useGetPimsUserQuery } from '../services/userService';
import { BASE_QUERY_OPTIONS } from './constants';
import { displayUser } from './formatFuncs';

interface IconProps {
    dataCy?: string;
}

export const ItemCompleteIcon = ({ dataCy }: IconProps) => {
	return (
		<CheckCircleOutlined
			data-cy={dataCy}
			style={{ marginRight: '4px', fontSize: '16px', color: '#52C41A' }}
		/>
	);
};

export const ItemIncompleteIcon = ({ dataCy }: IconProps) => {
	return (
		<ExclamationCircleFilled
			data-cy={dataCy}
			style={{
				marginRight: '4px',
				fontSize: '16px',
				color: 'var(--gold-6)',
			}}
		/>
	);
};

export const ItemPastDueIcon = () => {
	return (
		<ClockCircleFilled
			style={{
				marginRight: '4px',
				fontSize: '16px',
				color: 'var(--veg-red)',
			}}
		/>
	);
};

interface AddedByProps {
	children: number;
}

export const AddedBy = (props: AddedByProps) => {
	return (
		<div className='added-by-container'>
			Added by{' '}
			<span>
				<UserNameWrapper>{props.children}</UserNameWrapper>
			</span>
		</div>
	);
};

interface UserNameWrapperProps {
	children?: number | null;
}
export const UserNameWrapper = (props: UserNameWrapperProps) => {
	const userId = props.children;
	const { data: pimsUserData } = useGetPimsUserQuery(
		userId ?? -1,
		{
			...BASE_QUERY_OPTIONS,
			skip: userId === null
		},
	);
	if (userId === null){
		return(<>-</>)
	}
	return (
		<>
			{pimsUserData
				? `${displayUser(pimsUserData)}`
				: props.children}
		</>
	);
};

