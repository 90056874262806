import { Button, DatePicker, Form, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { FileUploader } from 'components/forms/fields/FileUploader';
import { HiddenInput } from 'components/forms/fields/HiddenInput';
import { Avatar, MarkdownNote } from 'components/lib';
import { MarkdownNoteProps } from 'components/lib/MarkdownNote';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { useDebounce } from 'hooks/useDebounce';
import useIsMounted from 'hooks/useIsMounted';
import _ from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useDeleteNoteFileMutation, useGetNoteFileURLQuery, useUploadNoteFileDirectMutation } from 'services/filesService';
import { useAddProgressNoteMutation, useCreateEditNoteCacheMutation, useGetNoteByIdQuery, useModifyProgressNoteMutation, useRemoveEditNoteCacheMutation } from 'services/visitService';
import { BaseNote } from 'utils/dataTypes';
import { NoteRules } from 'utils/types/validations';
import { validateNoteDateTime } from 'utils/validationFuncs';
import { fileUploadRules, validateFile } from './utils';
import { getInitials } from 'utils/formatFuncs';

interface AddNoteProps extends Omit<MarkdownNoteProps, 'fieldName'> {
	composeBoxId?: string;
	formType: string;
	noteId?: number;
	isInDrawer?: boolean;
	rowsNoteEditor?: number;
}

interface AddNoteRef {
    getNoteId: () => number | undefined;
	submitNote: () => void;
}

const AddNote: React.ForwardRefRenderFunction<AddNoteRef, AddNoteProps> = ({composeBoxId, formType, noteId, isInDrawer, rowsNoteEditor}, ref) => {
	const [note, setNote] = useState<BaseNote | undefined>();
	const [autosavedAt, setAutosavedAt] = useState<moment.Moment>();
	const [justAutosaved, setJustAutosaved] = useState(false);

	const { boxState, removeComposeBox } = useComposeBoxContext();
	const getIsMounted = useIsMounted();

	const { urlVisitId } = useParams<{ urlVisitId: string }>();
	const visitId = parseInt(urlVisitId);

	const [form] = Form.useForm();

	const { data: loggedInUserData } = useGetUserDataQuery(null);
	const { data: editNote } = useGetNoteByIdQuery(noteId ?? 1, { skip: !noteId, refetchOnMountOrArgChange: true });
	const { data: fileUrl, refetch: refetchFileURL } = useGetNoteFileURLQuery(
		{
			visitId,
			noteId: note?.id ?? 1
		},
		{ skip: !note },
	);
	const [addProgressNote, { isLoading: loadingAddNote }] = useAddProgressNoteMutation();
	const [modifyNote, { isLoading: loadingModifyNote }] = useModifyProgressNoteMutation();
	const [uploadNoteFileDirectMutation, { isLoading: loadingNoteFileUpload }] = useUploadNoteFileDirectMutation();
	const [deleteNoteFile] = useDeleteNoteFileMutation();
	const [createEditNoteCache] = useCreateEditNoteCacheMutation();
	const [removeEditNoteCache] = useRemoveEditNoteCacheMutation();

	const initialFileList: UploadFile[] | undefined = note?.file_name
		? [{
			uid: '1',
			name: note.file_name ?? '',
			status: 'done',
			percent: 100
		}]
		: undefined

	const [currentFileList, setCurrentFileList] = useState<UploadFile[] | undefined>(initialFileList);

	const noteRules: NoteRules = {
		note_date_time: [{
			validator: validateNoteDateTime('note_date_time')
		}],
	}

	const autoSaveFunction = useDebounce(() => {
		form.validateFields().then(() => {
			handleFinish(form.getFieldsValue(), true);
		});
	}, [note], 3000);


	useImperativeHandle(ref, () => ({
        getNoteId: () => {
           return note?.id
        },
		submitNote: () => form.submit()
    }));

	const handleFinish = (values: any, autosave?: boolean) => {
		const noteTime = values?.note_date_time?.unix();
		const note_date_time = noteTime - (noteTime % 60);

		if (note) {
			modifyNote({
				content: values.content,
				visit_id: visitId,
				note_date_time,
				category: formType,
				id: values.note_id ?? note.id,
				internal: false,
				author_id: loggedInUserData?.user_id || null,
			})
			.unwrap()
			.then((savedNote) => {
				setNote(savedNote);
				
				if (autosave) {
					setAutosavedAt(moment());
					message.success('Note successfully autosaved.');
					setJustAutosaved(true);
					_.delay(() => {
						setJustAutosaved(false);
					}, 1000);

				} else {
					removeCurrentComposeBox();
					message.success('Note successfully saved.');
				}
			})
			.catch((err) => {
				if (err.originalStatus === 403) {
					message.error(err.data);
				}
			});
		} else {
			handleCreateNote(values.content, note_date_time, undefined, autosave);
		}
	}

	const handleCreateNote = (content: string, noteDateTime: number, callback?: (noteId: number) => void, autosave?: boolean) => {
		addProgressNote({
			visitId,
			content,
			category: formType,
			internal: false,
			note_date_time: noteDateTime,
			author_id: loggedInUserData?.user_id || null,
		})
		.unwrap()
		.then((savedNote) => {
			setNote(savedNote);
			if (callback) callback(savedNote.id);
			
			if (autosave) {
				setAutosavedAt(moment());
				message.success('Note successfully autosaved.');
			} else {
				removeCurrentComposeBox();
				message.success('Note successfully saved.');
			}
		});
	}

	const handleUploadFile = (noteId: number, file: UploadFile) => {
		if (file.originFileObj) {
			uploadNoteFileDirectMutation({
				visitId,
				noteId: noteId,
				body: {
					file_name: file.name,
					file_obj: file.originFileObj
				},
			})
			.unwrap()
			.then(() => message.success('File successfully uploaded'));
		}
	}

	const removeCurrentComposeBox = () => {
        if (composeBoxId) {
            const boxItem = boxState.find((box) => box.id === composeBoxId);

            if (!!boxItem) {
                removeComposeBox(boxItem.slot);
            }
        }
    };

	useEffect(() => {
		if (noteId) {
			createEditNoteCache({ note_id: noteId });
		}
	}, []);

	useEffect(() => {
		return () => {
			if (!getIsMounted() && note) {
				removeEditNoteCache({ note_id: note.id });
			}
		}
	}, [note, getIsMounted]);

	useEffect(() => {
		if (editNote) {
            setNote(editNote);
            editNote.note_date_time && form.setFieldsValue({ note_date_time: moment.unix(editNote.note_date_time) });
        }
	}, [editNote]);

	useEffect(() => {
		const handleClose = (event: any) => {
			event.preventDefault();

			if (note) {
				removeEditNoteCache({ note_id: note.id });
			}

			return (event.returnValue = '');
		};

		window.addEventListener('beforeunload', handleClose);

		return () => {
			window.removeEventListener('beforeunload', handleClose);
		};
	}, [note]);
		
	if (noteId && !editNote) {
		return null;
	}

	return (
		<Form form={form} onFinish={handleFinish}>
			<HiddenInput fieldName={'note_id'} initialValue={noteId ?? note?.id} />

			<MarkdownNote
				fieldName='content'
				content={note?.content ?? editNote?.content}
				getFormData={form.getFieldsValue}
				note={note}
				onFormChange={(values) => {
					form.setFieldsValue(values);
					
					if (editNote) {
						if (note && (values.content === '' || (!!values.content && values.content !== note.content))) {
							autoSaveFunction();
						}
					} else if (!!values.content && values.content !== note?.content) {
						autoSaveFunction();
					}
				}}
				rowsNoteEditor={rowsNoteEditor}
			/>
			<Form.Item
				name="files"
				label="Attachments"
				valuePropName="fileList"
				getValueFromEvent={event => {
					if (Array.isArray(event)) {
						return event;
					}

					return event?.fileList;
				}}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				style={{ width: "100%" }}
				rules={fileUploadRules}
			>
				<FileUploader
					onPreview={() => {
						window.open(fileUrl)
						refetchFileURL()
					}}
					onRemove={() => {
						if (note?.id) {
							deleteNoteFile({ visitId: visitId, noteId: note?.id });
							setCurrentFileList(undefined);
						}
					}}
					onChange={(event) => {
						validateFile(event.fileList.flatMap((file) => file.originFileObj ?? [])).then(() => {							
							if (currentFileList && currentFileList[0].percent === 100 && note?.id) {
								deleteNoteFile({ visitId: visitId, noteId: note.id });
							}

							const file = event?.fileList?.[0];

							if (file && file.name && file.originFileObj) {
								if (note) {
									handleUploadFile(note.id, file);
								} else {
									const now = moment().unix();
									const noteDateTime = now - (now % 60);

									handleCreateNote('', noteDateTime, (noteId: number) => handleUploadFile(noteId, file));
								}
							}
						});

						return event.fileList;
					}}
					defaultFileList={currentFileList}
				/>
			</Form.Item>
			<Form.Item
				preserve={false}
				name='note_date_time'
				label="Date/Time"
				rules={noteRules.note_date_time}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				style={{ width: "100%" }}
				initialValue={moment()}
			>
				<DatePicker
					showTime={true}
					format={'YYYY-MM-DD, hh:mm a'}
					style={{width:'100%'}}
					onChange={(date) => {
						if (!!date) {
							autoSaveFunction();
						}
					}}
					getPopupContainer={(triggerNode) => {
						return triggerNode.parentNode as HTMLElement; 
					}}
				/>
			</Form.Item>

			<div className='note__submit-container'>
				{autosavedAt &&
					<>
						<Avatar
							firstName={loggedInUserData?.first_name ?? ''}
							lastName={loggedInUserData?.last_name ?? ''}
							className={loggedInUserData?.role_name === 'Nurse' ? 'nurse-avatar' : undefined}
						/>
						<span
							style={{
								color: justAutosaved ? 'var(--veg-secondary-blue)' : 'inherit',
								fontWeight: justAutosaved ? 600 : 'inherit',
							}}
						>
							Autosaved {moment(autosavedAt).format('YYYY-MM-DD, hh:mm a')}
						</span>
					</>
				}
				{!isInDrawer && <Form.Item shouldUpdate noStyle>
					{({ getFieldsError }) => (
						<Button
							disabled={
								getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
								loadingNoteFileUpload || loadingAddNote || loadingModifyNote
							}
							loading={loadingNoteFileUpload || loadingAddNote || loadingModifyNote}
							type='primary'
							onClick={form.submit}
						>
							Submit
						</Button>
					)}
				</Form.Item>}
			</div>
		</Form>
	);
};

export default forwardRef(AddNote);
