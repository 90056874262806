import { Select } from "antd";
import { PimsUser } from "utils/dataTypes";
import { displayUser } from "utils/formatFuncs";

interface VetSearchProps {
    usersList: PimsUser[];
    user: PimsUser | null;
    setUser: React.Dispatch<React.SetStateAction<PimsUser | null>>;
    disabled: boolean
}
const VetSearch = (props: VetSearchProps) => {    interface UserOptions {
        label: string;
        value: number;
        user: PimsUser
    }
    const userOptions: UserOptions[] = props.usersList.map(user => ({ label: displayUser(user ?? null), value: user.user_id, user: user }))

    return (
        <Select
            autoFocus={!props.disabled}
            disabled={props.disabled}
            allowClear
            onSelect={(data: string, option: any) => {
                const user = props.usersList.find(user => user.user_id === option?.value)
                props.setUser(user ?? null)
            }}
            onClear={() => props.setUser(null)}
            value={displayUser(props.user)}
            options={userOptions}
            style={{ width: '100%' }}
            showSearch
            filterOption={(input, optionData) => {
                const firstName = optionData?.user?.first_name || "";
                const lastName = optionData?.user?.last_name || "";
                const firstNameCheck = firstName.toUpperCase().includes(input.toUpperCase())
                const lastNameCheck = lastName.toUpperCase().includes(input.toUpperCase())
                return firstNameCheck || lastNameCheck;
            }}
        >

        </Select>
    )
}

export default VetSearch;
