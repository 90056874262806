import { useMemo } from "react";
import { useGetUserDataQuery } from "services/authService";
import { BASE_QUERY_OPTIONS } from "utils/constants";

const useHasAssignedHospital = () => {
    let { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );

    let hasAssignedHospital = useMemo(() => {
        return !!loggedInUserData?.hospital_name
    }, [loggedInUserData]);

    return [hasAssignedHospital];
}

export default useHasAssignedHospital;
