import { FormInstance } from 'antd';
import { DiscontinueMedicationForm } from 'components/forms/DiscontinueMedication';
import { FormName, ORDER_INSTRUCTION_NOUNS } from 'utils/constants';
import {
	BaseExistingInstruction,
	ExistingDiagInstruction,
	ExistingMedInstruction,
	isInstanceOfExistingToGoMedInstruction,
	PimsUser,
} from 'utils/dataTypes';
import { INITIAL_MODAL_STATE } from '../Visit';

export const generateDiscontinueModalState = (
	group: BaseExistingInstruction,
	user: PimsUser,
	modalForm: FormInstance<any>,
	onModalFinish: (values: any, formName: FormName) => void,
	setModalstate: Function,
) => {
	return {
		isVisible: true,
		okButtonProps: { danger: true, autoFocus: true },
		okText: 'Discontinue',
		title: `Discontinue ${
			ORDER_INSTRUCTION_NOUNS[(group as ExistingMedInstruction).type_id]
		}`,
		content: (
			<DiscontinueMedicationForm
				instruction={
					group as ExistingDiagInstruction | ExistingMedInstruction
				}
			/>
		),
		formName: FormName['discon_inst'],
		form: modalForm,
		onFinish: () => {
			onModalFinish(
				{
					instructionId: group.id,
					userId: user.user_id,
					isTogoMed: isInstanceOfExistingToGoMedInstruction(group)
				},
				FormName['discon_inst'],
			);

			setModalstate({
				...INITIAL_MODAL_STATE,
				form: modalForm,
			});
		},
		onCancel: () => {
			setModalstate({
				...INITIAL_MODAL_STATE,
				form: modalForm,
			});
		},
	};
};
