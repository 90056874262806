import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import PatientInfoPopover from 'components/popups/PatientInfoPopover';
import moment from 'moment';
import { useGetPatientAlertsByIdQuery, useGetPatientByIdQuery } from 'services/patientService';
import { BASE_QUERY_OPTIONS, TASKS_HOST } from 'utils/constants';
import { CustomerInfo, ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { PatientSignalment } from './PatientSignalment';
import { PawIcon } from 'assets/img/Paw';
import { Link } from 'react-router-dom';
import { getAgeYearsMonths } from 'utils/miscFuncs';

interface PatientSectionProps {
    currentVisit: PatientRecordVisit;
    customerInfo: CustomerInfo | undefined;
    patientWeight: number | undefined;
}

export const PatientSection = ({ currentVisit, customerInfo, patientWeight }: PatientSectionProps) => {
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id as string, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.pet_id,
    });
    const { data: patientAlerts } = useGetPatientAlertsByIdQuery(currentVisit?.pet_id as string, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.pet_id,
    });
    const isFinalizedVisit = Boolean(currentVisit.finalized_at);
    const { ageYears, ageMonths } = getAgeYearsMonths(patientData);

    const printPatientLabelOnClick = () => {
        fetch(`${TASKS_HOST}/visit/${currentVisit.id}/patient_label/print`);
    };

    return (
        <>
            <Row align='middle' justify='start'>
                <Col span={24} style={{ display: 'flex' }}>
                    <PatientInfoPopover
                        initialContent={{
                            pet_id: patientData?.pet_id,
                            name: patientData?.name,
                            species: patientData?.species,
                            breed: patientData?.breed,
                            sex: patientData?.sex,
                            is_intact: patientData?.is_intact,
                            birthday: patientData?.birthday ? moment(patientData?.birthday) : undefined,
                            ageYears: ageYears,
                            ageMonths: ageMonths,
                            is_deceased: !!patientData?.deceased_at,
                            deceased_at: patientData?.deceased_at ? moment.unix(patientData?.deceased_at) : undefined,
                            latest_weight: patientWeight || undefined,
                            patientAlerts: patientAlerts,
                            note: patientData?.note,
                        }}
                        disabled={isFinalizedVisit}
                    >
                        <Col span={24} className='sidebar-pet-name edit-sidebar-icon' data-cy='patientName'>
                            {patientData?.name}
                            {customerInfo?.last_name ? ` (${customerInfo?.last_name})` : ``}
                            <EditOutlined
                                data-cy='editPatient'
                                onClick={() => closedErrorMsg(isFinalizedVisit)}
                                style={{ fontSize: '16px', margin: '0 5px 0 10px', borderRight: '1px solid var(--gray-6)', paddingRight: 8 }}
                            />
                        </Col>

                        <Tooltip placement='top' title='View patient profile'>
                            <Link to={`/patient/${currentVisit.pet_id}`} data-cy='patientProfileButton'>
                                <Button style={{ border: 'none', boxShadow: 'none' }}  icon={<PawIcon
                                    data-cy='patientName'
                                />} />
                            </Link>
                        </Tooltip>

                        <Tooltip placement='top' title='Print patient label' className='sidebar-pet-name'>
                            <Button
                                style={{ border: 'none', boxShadow: 'none' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    printPatientLabelOnClick();
                                }}
                                icon={<PrinterOutlined
                                    style={{ fontSize: '20px', margin: '0 5px' }}
                                />}
                            />
                        </Tooltip>
                    </PatientInfoPopover>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{ display: 'inline-flex', marginTop: '4px' }}>
                    <PatientSignalment
                        patientData={patientData as ExpandedPatient}
                        patientWeight={patientWeight}
                        patientAlerts={patientAlerts}
                    />
                </Col>
            </Row>
        </>
    );
};
