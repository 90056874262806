import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

interface EstimateInstructionFormItemProps {
    formFieldName: NamePath;
    initialValue?: string | null;
    isEditing?: boolean;
    formFieldValue?: string;
    disabled?: boolean;
}

const EstimateInstructionFormItem: React.FC<EstimateInstructionFormItemProps> = ({ formFieldName, formFieldValue, initialValue, isEditing, disabled }) => {
    return (
        <>
            <div className='package-modal__mobile-header'>Instructions</div>
            <Form.Item name={formFieldName} initialValue={initialValue}>
                {isEditing ? <TextArea placeholder='instructions' rows={1} disabled={disabled} autoSize /> : <span>{formFieldValue}</span>}
            </Form.Item>
        </>
    );
};

export default EstimateInstructionFormItem;
