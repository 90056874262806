import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Radio, Row } from 'antd';
import { 
    ExistingCriInstruction,
    ExistingDiagInstruction,
    ExistingFluidInstruction,
    ExistingMedInstruction,
    ExistingOxygenTherapyInstruction,
    ExistingToGoMedInstruction,
    INSTRUCTION_STATUSES,
    PimsUser,
    TreatmentSheetInstructionAction,
    isInstanceOfExistingDiagInstruction,
    isInstanceOfMedicineInstruction,
} from "utils/dataTypes";
import InstructionDetails from 'views/visit/TreatmentSheet/InstructionDetails';
import { CSSProperties, Dispatch, useEffect, useState } from 'react';
import NoteSection from 'views/visit/TreatmentSheet/NoteSection';
import { UserNameWrapper } from 'utils/componentWrappers';
import moment from 'moment';
import { CompleteStatusActionForm } from './CompleteStatusActionForm';
import MedicationDoseAlert from '../MedicationDoseAlert';
import { useGetInstructionOptionsQuery } from 'services/instructionService';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';

const formItemStyle: CSSProperties = {
    width: '100%',
};

const valueColumnStyle: CSSProperties = {
    fontWeight: 500
}

const DEFAULT_LABEL_SPAN = 6;
const DEFAULT_VALUE_SPAN = 18;

interface FullActionFormProps {
    action: TreatmentSheetInstructionAction;
    instruction: ExistingMedInstruction | ExistingFluidInstruction | ExistingCriInstruction | ExistingDiagInstruction | ExistingToGoMedInstruction | ExistingOxygenTherapyInstruction; 
    user: PimsUser;
    onFormChange: Function;
    statusValue: INSTRUCTION_STATUSES;
    setStatusValue: React.Dispatch<React.SetStateAction<INSTRUCTION_STATUSES>>;
    assignedUser: number | null;
    setAssignedUser: React.Dispatch<React.SetStateAction<number | null>>;
    formWidget: JSX.Element;
	patientWeight?: number;
}

export const FullActionForm = ({
    action,
    instruction,
    user,
    onFormChange,
    statusValue,
    setStatusValue,
    assignedUser,
    setAssignedUser,
    formWidget,
	patientWeight,
}: FullActionFormProps) => {
	const { data: instructionOptions } = useGetInstructionOptionsQuery(null);

	const getMedicationAlert = () => {
		if (isInstanceOfMedicineInstruction(instruction)) {
			const medication = instructionOptions?.find(
				(m: any) => m.type_id === 'M' && m.id === instruction.medication_id,
			) as MedicineSearchOption;

			return (
				<MedicationDoseAlert
					doseUnit={medication.numerator_unit}
					patientWeight={patientWeight ?? instruction.latest_patient_weight_kg ?? instruction.approx_patient_weight_kg}
					dose={instruction.dose}
					highDoseAlert={medication.high_dose_alert}
					lowDoseAlert={medication.low_dose_alert}
					medication={medication}
					hideConfirmation
				/>
			);
		}
	};

    return (
        <>
            <InstructionDetails
                instruction={instruction}
                action={action}
				patientWeight={patientWeight}
            />
            
            {
				StatusField({
					instruction: instruction,
					initialStatus: action.status,
					currentValue: statusValue,
					currentResultValue: action.value,
					setCurrentValue: setStatusValue,
					onFormChange: onFormChange,
					currentUserId: user.user_id,
					setAssignedUser: setAssignedUser,
					assignId: assignedUser,
				})
			}

			{instruction.type_id === 'D' && instruction.category === 'IDEXX_REF_LAB' && (
				<Form.Item
					label='Sample'
					style={formItemStyle}
					labelCol={{ span: DEFAULT_LABEL_SPAN }}
					wrapperCol={{ span: DEFAULT_VALUE_SPAN }}
				>
					<span>{instruction.specimen}</span>
				</Form.Item>
			)}

			{(() => {
                if (instruction.type_id === 'D') {
                    const fileLink = instruction.idexx_ref_lab_files?.find(item => item.action_id === action.action_id)?.ref_lab_pdf_url;

					return fileLink ? (
                        <Form.Item
                            label='File'
                            style={formItemStyle}
                            labelCol={{ span: DEFAULT_LABEL_SPAN }}
                            wrapperCol={{ span: DEFAULT_VALUE_SPAN }}
                        >
                            <Button icon={<DownloadOutlined />} type='primary' onClick={() => window.open(fileLink, '_blank')}>
                                Download Form
                            </Button>
                        </Form.Item>
                    ) : (
                        formWidget
                    );
                }
            })()}

			{(action.status !== 'complete' && statusValue === 'complete') &&
				<CompleteStatusActionForm
					instruction={instruction}
					action={action}
					user={user}
					onFormChange={onFormChange}
					formWidget={formWidget}
					inFullForm={true}
					patientWeight={patientWeight}
					hideMedicationDoseAlert
				/>
			}

            <NoteSection
                onFormChange={onFormChange}
                labelSpan={DEFAULT_LABEL_SPAN}
                valueSpan={DEFAULT_VALUE_SPAN}
                initNote={action.note}
            />

			{(action.status === 'scheduled' || action.status === 'inprogress' || action.status === 'claimed') && getMedicationAlert()}

            <ClaimField
                assignId={assignedUser}
                actionStatus={action.status}
                actionTransitionedAt={action.completed_at || action.status_transition_at}
            />

            <Row
                style={{
                    ...formItemStyle,
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Col span={DEFAULT_LABEL_SPAN}>Scheduled For:</Col>
                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                    {moment.unix(action.scheduled_time ?? action.due_at).format('MM/DD/YYYY hh:mm a')}
                </Col>
            </Row>

            <Row
                style={{
                    ...formItemStyle,
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Col span={DEFAULT_LABEL_SPAN}>Created By:</Col>
                <Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
                    <UserNameWrapper>{instruction.ordered_by}</UserNameWrapper>
                    {' '}
                    <span>
                        {moment.unix(instruction.created_at).format('MM/DD/YYYY hh:mm a')}
                    </span>
                </Col>
            </Row>        
        </>
    )
};

interface statusFieldProps {
	instruction: ExistingMedInstruction | ExistingFluidInstruction | ExistingCriInstruction | ExistingDiagInstruction | ExistingToGoMedInstruction | ExistingOxygenTherapyInstruction;
	initialStatus: string;
	onFormChange: Function;
	setCurrentValue: Dispatch<React.SetStateAction<INSTRUCTION_STATUSES>>;
	currentValue: string;
	currentResultValue: number | string | boolean | null;
	currentUserId: number;
	assignId: number | null;
	setAssignedUser: Dispatch<React.SetStateAction<number | null>>;
}
export const StatusField = ({
	instruction,
	initialStatus,
	onFormChange,
	setCurrentValue,
	currentValue,
	currentResultValue,
	currentUserId,
	assignId,
	setAssignedUser,
}: statusFieldProps) => {
	const defaultStyle: CSSProperties = { width: '33.33%', marginTop: '5px' };
	const normalStyle: CSSProperties = {
		backgroundColor: '#73c64c33',
		...defaultStyle,
	};
	const abnormalStyle: CSSProperties = {
		backgroundColor: '#E1555533',
		...defaultStyle,
	};
	const [error, SetError] = useState(false);
	const help = error
        ? isInstanceOfExistingDiagInstruction(instruction)
            ? 'When changing the status from Complete, remove all results. Charges will remain on the invoice.'
            : 'You are changing this task from complete'
        : undefined;
	const validateStatus = error ? 'warning' : '';
	useEffect(() => {
		if (initialStatus === 'complete' && currentValue !== 'complete') {
			SetError(true);
		} else {
			SetError(false);
		}
	}, [currentValue]);

	let userAssignedOption: 'claim' | 'unclaim' | 'steal' = 'claim';
	if (assignId === null) {
		userAssignedOption = 'claim';
	} else if (assignId === currentUserId) {
		userAssignedOption = 'unclaim';
	} else {
		userAssignedOption = 'steal';
	}
	const CLAIM_OPTIONS = {
		claim: 'Claim',
		unclaim: 'Unclaim',
		steal: 'Steal',
	};
	const updateAssignedFunction = () => {
		if (userAssignedOption === 'unclaim') {
			onFormChange({ assigned_to: null, status: 'scheduled' });
			setAssignedUser(null);
			setCurrentValue('scheduled');
		} else {
			onFormChange({ assigned_to: currentUserId, status: 'claimed' });
			setAssignedUser(currentUserId);
			setCurrentValue('claimed');
		}
	};

	return (
		<Form.Item
			name='status'
			label='Status'
			style={formItemStyle}
			labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
			wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
			validateStatus={validateStatus}
			help={help}
		>
			<Radio.Group
				value={currentValue}
				onChange={(event) => {
					const claimingOptions = [
						'claimed',
						'inprogress',
						'complete',
					];
					if (
						userAssignedOption === 'unclaim' &&
						event.target.value === 'claimed'
					) {
						onFormChange({
							status: 'scheduled',
						});
						setCurrentValue('scheduled');
					} else if (
						claimingOptions.some(
							(option) => option === event.target.value,
						) &&
						userAssignedOption === 'claim'
					) {
						onFormChange({
							status: event.target.value,
							assigned_to: currentUserId,
						});
						setAssignedUser(currentUserId);
						setCurrentValue(event.target.value);
					} else {
						onFormChange({
							status: event.target.value,
						});
						setCurrentValue(event.target.value);
					}

					if (event.target.value === 'complete') {
						if (isInstanceOfExistingDiagInstruction(instruction) && instruction.result_entry.widget === 'idexx') {
							onFormChange({
								value: currentResultValue,
							});
						}
					}
				}}
				style={{ width: '100%' }}
			>
				<Radio.Button
					style={
						currentValue === 'scheduled'
							? normalStyle
							: defaultStyle
					}
					value='scheduled'
				>
					Scheduled
				</Radio.Button>
				<Radio.Button
					style={
						currentValue === 'claimed' ? normalStyle : defaultStyle
					}
					value='claimed'
					onClick={updateAssignedFunction}
				>
					{CLAIM_OPTIONS[userAssignedOption]}
				</Radio.Button>

				<Radio.Button
					style={
						currentValue === 'inprogress'
							? normalStyle
							: defaultStyle
					}
					value='inprogress'
				>
					In-progress
				</Radio.Button>
				<Radio.Button
					style={
						currentValue === 'complete' ? normalStyle : defaultStyle
					}
					value='complete'
					data-cy={'completeStatus'}
				>
					Complete
				</Radio.Button>

				<Radio.Button
					style={
						currentValue === 'skipped'
							? abnormalStyle
							: defaultStyle
					}
					value='skipped'
				>
					Skipped
				</Radio.Button>
				<Radio.Button
					style={
						currentValue === 'missed' ? abnormalStyle : defaultStyle
					}
					value='missed'
				>
					Missed
				</Radio.Button>
			</Radio.Group>
		</Form.Item>
	);
};


interface ClaimFieldProps {
	assignId: number | null;
	actionStatus: string;
	actionTransitionedAt?: number;
}

export const ClaimField = ({
	assignId,
	actionStatus,
	actionTransitionedAt,
}: ClaimFieldProps) => {
	return (
		<Form.Item
			noStyle={assignId === null}
			name='assigned_to'
			label={actionStatus === 'complete' ? 'Completed By' : 'Claimed By'}
			style={formItemStyle}
			labelCol={{ span: DEFAULT_LABEL_SPAN }}
			wrapperCol={{ span: DEFAULT_VALUE_SPAN }}
		>
			<Col span={DEFAULT_VALUE_SPAN} style={valueColumnStyle}>
				{assignId && (
					<UserNameWrapper>{assignId}</UserNameWrapper>
				)}
				{(actionStatus === 'complete' || actionStatus === 'claimed') &&
					actionTransitionedAt && (
						<>
							{' '}
							{moment(moment.unix(actionTransitionedAt)).format(
								'MM/DD/YYYY hh:mm a',
							)}
						</>
					)}
			</Col>
		</Form.Item>
	);
};
