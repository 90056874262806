import { EARNABLE_TYPES } from './billingTypes';

export interface ConsentFormHeader {
    pet_id: string;
    pet_name: string;
    doctor_first_name: string | null;
    doctor_last_name: string | null;
    hospital_name: string;
    hospital_street: string;
    hospital_city: string;
    hospital_state: string;
    hospital_zipcode: string;
    hospital_phone_number: string;
    is_approved?: boolean;
}

export interface ConsentFormEstimateItemMedical {
    estimate_id: number;
    instruction_id: number;
    present_quantity: number;
    unit: string;
    name: string;
    unit_cost_cents: number;
    unit_cost_cents_with_supp?: number;
    type_id: EARNABLE_TYPES;
    is_free: boolean;
    reason: string | null;
    dose?: number | null;
    ratio?: number | null;
    supplemental_cost_cents?: number | null;
    is_recurring_supplemental?: boolean | null;
    dose_unit: string;
    pricing_unit: string | null;
    pricing_unit_size: number | null;
    supplemental_quantity: number;
    serial?: boolean;  
    serial_hours?: number | null;  

}

export const isInstanceOfConsentFormEstimateItemMedical = (item: any): item is ConsentFormEstimateItemMedical =>
    item.type_id !== 'N';

export interface ConsentFormEstimateItemNonMedical {
    estimate_id: number;
    non_medical_order_id: number;
    present_quantity: number;
    unit: string;
    name: string;
    unit_cost_cents: number;
    unit_cost_cents_with_supp?: number;
    type_id: 'N';
    is_free: boolean;
    reason: string | null;
    supplemental_cost_cents?: number | null;
    serial?: boolean;  
    serial_hours?: number | null;  

}
export type ConsentFormEstimateItem = ConsentFormEstimateItemNonMedical | ConsentFormEstimateItemMedical;

export interface EstimateConsentForm extends ConsentFormHeader {
    estimate_id: number;
    visit_id: number;
    estimate_name: string | null;
    duration_hours: number;
    estimate_items: ConsentFormEstimateItem[];
    amount_paid: number | null;
    discount_basis?: number;
    cap_amount_cents?: number;
    prior_approved_cost_cents: number;
    approved_date_hospital_timezone?: string;
    pet_deceased_at: number | null;
    owner_id: number | null;
    opt_in_marketing_email: boolean | null;
    has_approved_cremation_or_euthanasia: boolean;
    tax_cents: number;
    payment_link_id?: string;
}

export interface EuthanasiaConsentForm extends ConsentFormHeader {
    species: string | null;
    birthday: string | null;
    approved_date_hospital_timezone: string | null;
}

interface BurialConsentFormUrn {
    crematorium_id: number;
    id: number;
    manufacturer_id: string;
    name: string;
    has_picture: boolean;
}
export interface BurialConsentForm extends ConsentFormHeader {
    crematorium_id: number;
    name: string;
    street: string | null;
    city: string | null;
    state: string | null;
    zipcode: string | null;
    phone_number: string | null;
    has_private_burial: boolean;
    type: string;
    urn_id: number | null;
    custom_urn_id: string | null
    approved_date_hospital_timezone: string | null;
    urns: BurialConsentFormUrn[];
}

export interface MedicationConsentForm extends ConsentFormHeader {
    chosen_option: number | null;
    approved_date_hospital_timezone: string | null;
}

export interface BurialConsentAcceptBody {
    type: string;
    crematorium_id: number | null;
    urn_id: string | null;
    custom_urn_id: string | null;
}

export interface MedicationConsentAcceptBody {
    chosen_option: number;
}

export interface BurialApprovedForm extends BurialConsentAcceptBody {
    crematorium_name: string | null;
    id: string;
    urn_name: string | null;
}

export interface RabiesVaccinationForm extends ConsentFormHeader {
    visit_id: number;
    rabies_tag_id: string | null;
    vaccinated_at: number | null;
    next_vaccination_at: number | null;
    name: string | null;
    manufacturer_initials: string | null;
    usda_licensed_vaccine_type: string | null;
    dose_type: string | null;
    vaccine_serial_id: string | null;
    pet_color: string | null;
    pet_microchip_id: string | null;
    veterinarian_name: string | null;
    veterinarian_id: number | null;
    vet_license_number: string | null;
}

export interface SaveRabiesVaccinationForm {
    pet_id: string;
    form_id: string;
    rabies_tag_id: string | null;
    vaccinated_at: number | null;
    next_vaccination_at: number | null;
    name: string | null;
    manufacturer_initials: string | null;
    usda_licensed_vaccine_type: string | null;
    dose_type: string | null;
    vaccine_serial_id: string | null;
    veterinarian_id: number | null;
    vet_license_number: string | null;
    pet_color: string | null;
    pet_microchip_id: string | null;
    finalize: boolean;
}
