import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApprovedEstimateFormQuery } from 'services/consentService';
import { Header } from '../Header';
import PrintableEstimate from '../PrintableEstimate';
import './PrintEstimatePage.css';

const PrintEstimatePage = () => {
    const { visitId, formId } = useParams<any>();
    const { data: estimateFormData } = useGetApprovedEstimateFormQuery(formId);
    
    // TODO: see if this can be done better
    useEffect(() => {
        if (estimateFormData){
            document.title = `estimate_${estimateFormData.estimate_id}_visit_${visitId}`;
        }
    }, [estimateFormData]);
    if (!estimateFormData) {
        return (
            <Spin
                size='large'
                style={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />
        );
    }

    return (
        <div className='print-estimate-page'>
            <div className='print-estimate-page__print-button-container'>
                <Button
                    onClick={() => {
                        window.print();
                    }}
                    type='primary'
                >
                    Print
                </Button>
            </div>

            <div className='document' id='printableArea'>
                <Header
                    title='Treatment Plan Estimate'
                    headerData={estimateFormData}
                    formTime={estimateFormData?.approved_date_hospital_timezone}
                />

                <PrintableEstimate estimateFormData={estimateFormData} />

                <div className='print-estimate-page__approved-container'>
                    <CheckCircleOutlined
                        style={{ color: '#73C64C', marginRight: '10px' }}
                    />
                    Approved: {estimateFormData.approved_date_hospital_timezone}
                </div>
            </div>
        </div>
    );
};

export default PrintEstimatePage;
