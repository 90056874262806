import moment from 'moment';
import { UserNameWrapper } from 'utils/componentWrappers';
import { SMSTextMessage } from 'utils/dataTypes';
import './MessageContent.css';

interface IMessageContentProps {
	message: SMSTextMessage;
}

const MessageContent = ({ message }: IMessageContentProps) => {
	return (
		<div className='message-content'>
			<div className='message-sender'>
				<UserNameWrapper>{message.created_by}</UserNameWrapper>
			</div>
			<div className='message-content__text'>{message.content}</div>
			<div className='message-time'>
				{moment.unix(message.created_at).format('HH:mm A')}
			</div>
		</div>
	);
};

export default MessageContent;
