import { EstimateConsentForm } from 'utils/types/consentFormTypes';
import { UserEstimateContent } from 'views/visit/Estimates/EstimatesUserScreen';
import { PageBreaker } from './ConsentForms';
import { SectionHeader } from './Header';

interface IPrintableEstimate {
    estimateFormData: EstimateConsentForm;
}

const PrintableEstimate: React.FC<IPrintableEstimate> = ({ estimateFormData }) => {
    const {
        estimate_id,
        estimate_items,
        prior_approved_cost_cents,
        estimate_name,
        amount_paid,
        discount_basis,
        cap_amount_cents,
        visit_id,
        tax_cents,
        ...headerData
    } = estimateFormData;

    return (
        <>
            <UserEstimateContent
                estimate_items={estimate_items}
                estimate_name={estimate_name}
                amountPaid={amount_paid ?? 0}
                estimateId={estimate_id}
                visitId={visit_id}
                discountBasis={discount_basis}
                cap_amount_cents={cap_amount_cents}
                prior_approved_cost_cents={prior_approved_cost_cents}
                tax_cents={tax_cents}
            />
            <PageBreaker />
            <div className='document-section'>
                <div className='document-section-desktop'>
                    <SectionHeader title={''} headerData={headerData} formTime={headerData.approved_date_hospital_timezone} />
                </div>

                <div className='single-page-element document-text'>
                    <p>
                        This estimate lists procedures and fees for veterinary service on{' '}
                        <span className='document-text-bold'>{headerData.pet_name}</span> at{' '}
                        <span className='document-text-bold'>{headerData.hospital_name}</span>.
                    </p>
                    <p>
                        By signing this document I certify that I have read this document, understand it, and have had all of my questions
                        answered to my satisfaction and I agree to the conditions of treatment, including paying the full estimated amount.
                        I understand that treatment will not begin until I pay a prepayment of 100% of the estimated fees.
                    </p>

                    <p>
                        I understand that this estimate of the fees for veterinary services is provided to me and that I am encouraged to
                        discuss all fees related to such care before services are rendered and during my pet’s ongoing medical treatment.
                    </p>

                    <p>
                        I, the below signed owner, authorized agent of the owner, or Good Samaritan responsible for seeking veterinary care
                        for the pet identified, certify that I am eighteen years of age or older and that I have the authority to execute
                        this consent.
                    </p>

                    <p>This estimate is valid for 30 days from its creation.</p>

                    <p>
                        I also understand that this facility may not have an enclosed, outdoor space for pets to exercise and relieve
                        themselves. Accordingly, my pet may be taken off facility property during its admission. In the event that I choose
                        to leave the pet’s personal harness/leash to be used during its admission, I understand that Veterinary Emergency
                        Group will not be liable if this leash/harness fails.
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrintableEstimate;
