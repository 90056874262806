import { Checkbox, Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';

interface MedicationDoseConfirmationProps {
    formName?: NamePath;
    bypassDoseAlertValidation?: boolean;
}

const MedicationDoseConfirmation: React.FC<MedicationDoseConfirmationProps> = ({ formName, bypassDoseAlertValidation }) => (
    <Form.Item
        name={formName ?? 'dose_acknowledge'}
        rules={[
            {
                validator(_, value) {
                    if (!!value || bypassDoseAlertValidation) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please acknowledge the dose is out of the recommended amount.'));
                },
            },
        ]}
        valuePropName='checked'
    >
        <Checkbox>I acknowledge the dose entered is outside the recommended range and I choose to proceed.</Checkbox>
    </Form.Item>
);

export default MedicationDoseConfirmation;
