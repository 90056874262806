import { EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Badge, Button, Tooltip } from 'antd';
import React from 'react';
import './EditLineButton.css';

interface EditLineButtonProps {
    hasError: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const EditLineButton: React.FC<EditLineButtonProps> = ({ disabled, hasError, onClick }) => {
    return (
        <div className='edit-button-container grid-table__sticky-column'>
            <Badge
                count={
                    hasError ? (
                        <Tooltip title={hasError ? 'Missing required information' : null}>
                            <ExclamationCircleFilled style={{ color: 'var(--veg-red)' }} />
                        </Tooltip>
                    ) : (
                        0
                    )
                }
            >
                <Button icon={<EditOutlined />} onClick={onClick} disabled={disabled} />
            </Badge>
        </div>
    );
};

export default EditLineButton;
