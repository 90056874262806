import { InfoCircleFilled, UserOutlined } from '@ant-design/icons';
import { Alert, Col, Collapse, Divider, Form, FormInstance, Input, Progress, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DrawerBoolGroup, DrawerRadioGroup } from 'components/drawers';
import { PhoneNumberField } from 'components/forms/fields/PhoneNumberField';
import React, { CSSProperties, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { useGetDoctorsInMyHospitalQuery } from 'services/userService';
import { CALLER_TYPES, SUPPORTED_PHONE_NUMBER_REGEX } from 'utils/constants';
import { InboundCall } from 'utils/dataTypes';
import { formatPhoneNumber } from 'utils/formatFuncs';
import { getReferredWhyOptions, reasons, recommendationsOptions, referredWhereOptions } from './constants';

const compactFormItemStyle: CSSProperties = {
    width: '100%',
};

const formItemStyle: CSSProperties = {
    width: '100%',
    marginBottom: '.8rem',
};

interface CallLogFormProps {
    formInstance: FormInstance;
    record: Partial<InboundCall>;
    setComplete: React.Dispatch<React.SetStateAction<boolean>>;
    setFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CallLogForm: React.FC<CallLogFormProps> = ({ formInstance, record, setComplete, setFormChanged }) => {
    const { Panel } = Collapse;
    const formVal = formInstance.getFieldValue;

    // Recommendation selects won't rerender after the first select
    const [_, setRecommendation] = useState<string | undefined>();

    const { data: doctors } = useGetDoctorsInMyHospitalQuery(undefined, { skip: formVal('reason') === 'Other' });
    const { data: currentUser } = useGetUserDataQuery(null, { skip: formVal('reason') === 'Other' });

    const handleFormValuesChange = (changedValues: any) => {
        setFormChanged((prev) => !prev);
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName === 'call_type') {
            const [oldPatientName, oldSpokeWithDoctor, oldDoctorId] = [
                formVal('patient_name'),
                formVal('spoke_with_doctor'),
                formVal('doctor_id'),
            ];
            const note = formInstance.getFieldValue('note')
            formInstance.resetFields();
            formInstance.setFieldsValue({ note })
            formInstance.setFieldsValue({ call_type: changedValues[fieldName] });
            if (changedValues[fieldName] !== 'Other') {
                // Restore call summary fields if for Customer and rDVM call logs
                formInstance.setFieldsValue({ patient_name: oldPatientName });
                formInstance.setFieldsValue({ spoke_with_doctor: oldSpokeWithDoctor });
                formInstance.setFieldsValue({ doctor_id: oldDoctorId });
            }
        } else if (fieldName === 'spoke_with_doctor') {
            // if no doctor is selected yet and current user is a doctor, assign him by default
            if (!formVal('doctor_id') && currentUser?.role_name === 'Doctor') {
                formInstance.setFieldsValue({ doctor_id: currentUser.user_id });
            }
        } else if (fieldName === 'reason') {
            // Clear 'Other' justification if the selected reason isn't Other
            if (changedValues[fieldName] !== 'Other') {
                formInstance.setFieldsValue({ reason_other: undefined });
            }
        } else if (fieldName === 'recommendation') {
            const is_come_in = changedValues[fieldName] === 'Come In';
            formInstance.setFieldsValue({ send_check_in_link: is_come_in });
            handleFormValuesChange({ send_check_in_link: is_come_in });
            // Clear details fields if the selected recommendation isn't Referred Elsewhere
            if (changedValues[fieldName] !== 'Referred Elsewhere') {
                formInstance.setFieldsValue({
                    referred_where: undefined,
                    referred_why: undefined,
                    referred_why_other: undefined,
                });
            }
        } else if (fieldName === 'referred_where') {
            formInstance.setFieldsValue({ referred_why: undefined });
        } else if (fieldName === 'referred_why') {
            // Clear 'Other' justification if the selected Why isn't Other
            if (changedValues[fieldName] !== 'Other') {
                formInstance.setFieldsValue({ referred_why_other: undefined });
            }
        } else if (fieldName === 'send_check_in_link') {
            // Set default phone number to the caller number
            if (changedValues[fieldName] === true && formVal('mobile_phone') === undefined) {
                formInstance.setFieldsValue({ mobile_phone: record.from_number?.slice(-10) });
            }
        }
        setComplete(allSectionsComplete(formInstance));
    };

    return (
        <Row>
            <span style={{ marginBottom: '.2em' }}>Number:</span>
            <span className='veg-subtitle-2' style={{ width: '100%' }}>
                {formatPhoneNumber(record.from_number)}
            </span>

            <Divider style={{ margin: '.5rem 0 1rem 0' }} />

            <>
                <Alert
                    type='info'
                    message='Information can be entered and saved as it becomes available. However,
                    all calls details must be provided in order to mark the call "Complete".'
                    icon={<InfoCircleFilled />}
                    showIcon
                    closable={true}
                    style={{ padding: '8px 16px' }}
                />
            </>

            <Form form={formInstance} onValuesChange={handleFormValuesChange} layout='vertical' style={formItemStyle}>
                <Form.Item preserve={false} hidden name='original_call' initialValue={record}>
                    <Input disabled />
                </Form.Item>

                <Collapse ghost bordered={false} className='veg-drawer-collapse' defaultActiveKey={['call_summary', 'reason', 'check_in']}>
                    <Panel
                        className='veg-drawer-panel'
                        key='call_summary'
                        header={<Divider orientation='left'>Call Summary</Divider>}
                        extra={<Progress type='circle' width={30} percent={callSummarySectionProgress(formInstance)} />}
                    >
                        <label className='call-log-required'>Call Type</label>
                        <Form.Item name='call_type' required={true} style={compactFormItemStyle}>
                            <DrawerRadioGroup options={CALLER_TYPES} optionsPerRow={3} />
                        </Form.Item>

                        <div style={{ display: formVal('call_type') === 'Other' ? 'none' : 'initial' }}>
                            <label>Patient Name:</label>
                            <Form.Item name='patient_name' style={formItemStyle}>
                                <Input prefix={<UserOutlined />} placeholder='First name only' />
                            </Form.Item>

                            <label>Spoke with Doctor:</label>
                            <Form.Item name='spoke_with_doctor' style={compactFormItemStyle}>
                                <DrawerBoolGroup />
                            </Form.Item>

                            <label style={{ display: formVal('spoke_with_doctor') ? 'initial' : 'none' }}>Doctor:</label>
                            <Form.Item name='doctor_id' style={formItemStyle} hidden={!formVal('spoke_with_doctor')}>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder='Select Doctor'
                                    options={doctors?.map((doctor) => {
                                        return {
                                            label: doctor.full_name,
                                            value: doctor.user_id,
                                        };
                                    })}
                                    filterOption={(input, option) =>
                                        ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </div>
                    </Panel>

                    {!!formVal('call_type') && (formVal('call_type') === 'rDVM' || formVal('call_type') === 'Customer') && (
                        <>
                            <Panel
                                className='veg-drawer-panel'
                                key='reason'
                                header={<Divider orientation='left'>Reason</Divider>}
                                extra={<Progress type='circle' width={30} percent={reasonSectionProgress(formInstance)} />}
                            >
                                <Form.Item name='reason' style={compactFormItemStyle}>
                                    <DrawerRadioGroup options={reasons[formVal('call_type') as keyof typeof reasons]} />
                                </Form.Item>

                                <Form.Item
                                    name='reason_other'
                                    style={compactFormItemStyle}
                                    hidden={formVal('reason') !== 'Other' && formVal('reason') !== 'Other Questions'}
                                >
                                    <Input placeholder='Please specify..' />
                                </Form.Item>

                                <label style={{ display: formVal('reason') === 'New Medical Inquiry' ? 'initial' : 'none' }}>
                                    Recommendation:
                                </label>
                                <Form.Item
                                    name='recommendation'
                                    style={compactFormItemStyle}
                                    hidden={formVal('reason') !== 'New Medical Inquiry'}
                                >
                                    <Select
                                        placeholder='Select Recommendation'
                                        options={recommendationsOptions}
                                        onChange={(v: string) => setRecommendation(v)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='referred_where'
                                    style={compactFormItemStyle}
                                    dependencies={['recommendation']}
                                    hidden={
                                        formVal('reason') !== 'New Medical Inquiry' || formVal('recommendation') !== 'Referred Elsewhere'
                                    }
                                >
                                    <Select placeholder='Where' options={referredWhereOptions} />
                                </Form.Item>

                                <Form.Item
                                    name='referred_why'
                                    style={compactFormItemStyle}
                                    hidden={
                                        formVal('reason') !== 'New Medical Inquiry' || formVal('recommendation') !== 'Referred Elsewhere'
                                    }
                                >
                                    <Select
                                        placeholder='Why'
                                        disabled={!formVal('referred_where')}
                                        options={getReferredWhyOptions(formVal('referred_where'))}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='referred_why_other'
                                    required={true}
                                    style={compactFormItemStyle}
                                    hidden={formVal('reason') !== 'New Medical Inquiry' || formVal('referred_why') !== 'Other'}
                                >
                                    <Input placeholder='Please specify..' />
                                </Form.Item>
                            </Panel>

                            {requiresCheckinSection(formInstance) && (
                                <Panel
                                    className='veg-drawer-panel'
                                    key='check_in'
                                    header={<Divider orientation='left'>Check-in</Divider>}
                                    extra={<Progress type='circle' width={30} percent={checkInSectionProgress(formInstance)} />}
                                >
                                    <Form.Item name='send_check_in_link' style={formItemStyle}>
                                        <DrawerBoolGroup />
                                    </Form.Item>

                                    {formVal('send_check_in_link') && (
                                        <>
                                            <PhoneNumberField
                                                formItemProps={{
                                                    name: 'mobile_phone',
                                                    label: 'Mobile Phone',
                                                    style: formItemStyle,
                                                    validateStatus: validPhoneNumber(formVal('mobile_phone'))
                                                        ? 'success'
                                                        : formVal('mobile_phone') === null
                                                        ? ''
                                                        : 'error',
                                                    help:
                                                        validPhoneNumber(formVal('mobile_phone')) || formVal('mobile_phone') === null
                                                            ? ''
                                                            : '10 digits required',
                                                }}
                                                onChange={(value: string | null) => {
                                                    formInstance.setFieldsValue({ mobile_phone: value });
                                                }}
                                            />
                                            <Row style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                                                <Col style={{ color: '#8c8c8c' }}>
                                                    {record.form_link_id ? (
                                                        <span>
                                                            <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                                                                Previous link will be discontinued
                                                            </span>{' '}
                                                            and a text message containing a link to the check-in form will be sent to the
                                                            mobile number provided.
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            A text message containing a link to the check-in form will be sent to the mobile
                                                            number provided.
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Panel>
                            )}
                        </>
                    )}
                </Collapse>

                <div style={{ display: formVal('call_type') === 'Other' ? 'none' : 'initial' }}>
                    <Divider style={{ margin: '0 0 .8rem 0' }} />

                    <label className='veg-subtitle-1'>Notes</label>
                    <Form.Item name='note' style={formItemStyle}>
                        <TextArea allowClear />
                    </Form.Item>
                </div>
            </Form>
        </Row>
    );
};

export const validPhoneNumber = (phone_number: any): boolean => new RegExp(SUPPORTED_PHONE_NUMBER_REGEX).test(phone_number);

export const callSummarySectionProgress = (formInstance: FormInstance) => {
    let numerator: number = 0;
    let denominator: number = 3;
    const [callType, patientName, spokeWithDoctor, doctorId] = ['call_type', 'patient_name', 'spoke_with_doctor', 'doctor_id'].map(
        (field: string) => formInstance.getFieldValue(field),
    );
    if (callType === 'Other') {
        return 100;
    } else if (!!callType) {
        numerator++;
    }
    if (!!patientName) {
        numerator++;
    }
    if (spokeWithDoctor === true) {
        denominator++;
        numerator++;
        if (!!doctorId) {
            numerator++;
        }
    } else if (spokeWithDoctor === false) {
        numerator++;
    }
    return Math.floor((numerator / denominator) * 100);
};

export const reasonSectionProgress = (formInstance: FormInstance) => {
    let numerator: number = 0;
    let denominator: number = 1;
    const [reason, reasonOther, recommendation, referredWhere, referredWhy, referredWhyOther] = [
        'reason',
        'reason_other',
        'recommendation',
        'referred_where',
        'referred_why',
        'referred_why_other',
    ].map((field: string) => formInstance.getFieldValue(field));
    if (reason === 'Other' || reason === 'Other Questions') {
        numerator++;
        denominator++;
        if (!!reasonOther) {
            numerator++;
        }
    } else if (reason === 'New Medical Inquiry') {
        numerator++;
        denominator++;
        if (!!recommendation) {
            numerator++;
            if (recommendation === 'Referred Elsewhere') {
                denominator += 2;
                if (!!referredWhere) {
                    numerator++;
                    if (!!referredWhy) {
                        numerator++;
                        if (referredWhy === 'Other') {
                            denominator++;
                            if (!!referredWhyOther) {
                                numerator++;
                            }
                        }
                    }
                }
            }
        }
    } else if (!!reason) {
        return 100;
    }
    return Math.floor((numerator / denominator) * 100);
};

export const checkInSectionProgress = (formInstance: FormInstance) => {
    const [sendCheckInLink, mobilePhone] = [formInstance.getFieldValue('send_check_in_link'), formInstance.getFieldValue('mobile_phone')];
    if (sendCheckInLink === true) {
        if (validPhoneNumber(mobilePhone)) {
            return 100;
        } else {
            return 50;
        }
    } else if (sendCheckInLink === false) {
        return 100;
    } else {
        return 0;
    }
};

export const requiresCheckinSection = (formInstance: FormInstance) => {
    const formVal = formInstance.getFieldValue;
    return (
        formVal('call_type') === 'Customer' &&
        (formVal('reason') === 'New Medical Inquiry' || formVal('reason') === 'Non-Medical Inquiry' || formVal('reason') === 'Other')
    );
};

export const allSectionsComplete = (formInstance: FormInstance) => {
    return (
        callSummarySectionProgress(formInstance) === 100 &&
        reasonSectionProgress(formInstance) === 100 &&
        (requiresCheckinSection(formInstance) ? checkInSectionProgress(formInstance) === 100 : true)
    );
};
