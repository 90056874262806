import { downloadFile } from 'utils/triggerFuncs';
import { taskApi } from './taskApi';

const taskService = taskApi.injectEndpoints({
	endpoints: (builder) => ({
		downloadDischargeDoc: builder.query<any, { visitId: number; isDraft?: boolean; }>({
			query: ({ visitId, isDraft }) => ({
				url: `visit/${visitId}/export/discharge${isDraft ? '?draft=true' : ''}`,
				cache: 'no-cache',
				responseHandler: async (response) => {
					const fileName = `export_visit_${visitId}.pdf`;
					await downloadFile(response, fileName);
				},
			}),
			providesTags: () => [{ type: 'Discharge' }],
		}),
		downloadMedicalRecordDoc: builder.query<any, { visitId: number; isDraft?: boolean; }>({
			query: ({ visitId, isDraft }) => ({
				url: `visit/${visitId}/export/record${isDraft ? '?draft=true' : ''}`,
				cache: 'no-cache',
				responseHandler: async (response) => {
					if (response.ok) {
						const fileName = response.headers.get("content-disposition")?.split("filename=")[1].trim().replaceAll("\"", "");
						if (fileName) {
							await downloadFile(response, fileName);
						} else {
							console.error("Could not determine record filename: missing Content-Disposition header in response");
							await downloadFile(response, 'medical_record.pdf')
						}
					}
				},
			}),
			providesTags: () => [{ type: 'MedicalRecord' }],
		}),
	}),
});

export const {
	useLazyDownloadDischargeDocQuery,
	useLazyDownloadMedicalRecordDocQuery,
} = taskService;
