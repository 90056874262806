import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Divider, Drawer, Form, message } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { MedicationFrequency } from 'components/forms/MedicineOrder';
import { DatePickerWithHours } from 'components/forms/fields/DatePickerWithHours';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useModifyMultipleFrequencyMutation } from 'services/instructionService';
import { drawerSize } from 'styles/drawer';
import { INSTRUCTION_TYPES } from 'utils/dataTypes';
import { next30Min } from 'utils/formFuncs';
import { validateFieldIsAfterDate } from 'utils/validationFuncs';
import useWindowDimensions from 'utils/window';

interface UserFormProps {
    new_time: number;
    frequency: string;
}

interface BulkRescheduleDrawerProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectedItems: {
        id: number;
        type_id: INSTRUCTION_TYPES;
        name: string;
    }[];
    deselectItems: Function;
    hasInProgressActions: boolean;
}

export const BulkRescheduleDrawer = ({
    isOpen,
    setIsOpen,
    selectedItems,
    deselectItems,
    hasInProgressActions,
}: BulkRescheduleDrawerProps) => {
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const [newTime, setNewTime] = useState<moment.Moment | null>(next30Min(moment()));
    const [form] = Form.useForm<UserFormProps>();
    const [modifyMultipleFrequency] = useModifyMultipleFrequencyMutation();
    const { width } = useWindowDimensions();
    const pluralSuffix = selectedItems?.length > 1 ? 's' : '';

    useEffect(() => {
        if (isOpen) {
            setNewTime(next30Min(moment()));
        }
    }, [isOpen]);

    const DrawerFooter = () => {
        return (
            <>
                <Button
                    type='default'
                    onClick={() => {
                        setIsOpen(false);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    type='primary'
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Update {selectedItems.length} Order{pluralSuffix}
                </Button>
            </>
        );
    };

    return (
        <Drawer
            title='Bulk Edit Orders'
            className='veg-drawer bulk-reschedule-drawer'
            placement='right'
            closable={true}
            width={width >= 540 ? drawerSize.md : drawerSize.extended}
            onClose={() => {
                setIsOpen(false);
                form.resetFields();
            }}
            destroyOnClose={true}
            visible={isOpen}
            key='bulk_reschedule_drawer'
            mask={true}
            maskClosable={true}
            footer={<DrawerFooter />}
        >
            <Collapse
                ghost
                bordered={false}
                className='veg-drawer-collapse'
                defaultActiveKey='edit_fields'
                expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
            >
                <Collapse.Panel
                    className='veg-drawer-panel'
                    style={{ marginTop: '-12px' }}
                    key='selected_orders'
                    header={<Divider orientation='left'>Selected Order{pluralSuffix} ({selectedItems.length})</Divider>}
                >
                    <ul style={{ margin: 0, padding: '0 0 0 30px' }}>
                        {selectedItems?.map((item: any) => {
                            return <li>{item.name}</li>;
                        })}
                    </ul>
                </Collapse.Panel>
                <Collapse.Panel className='veg-drawer-panel' key='edit_fields' header={<Divider orientation='left'>Edit Fields</Divider>}>
                    Check the order details to update for {selectedItems?.length > 1 ? 'all' : ''} {selectedItems.length} order{pluralSuffix}.
                    <Form
                        name='reschedule_instruction'
                        form={form}
                        onFinish={(values) => {
                            if (newTime) {
                                modifyMultipleFrequency({
                                    visitId: visitId,
                                    body: {
                                        frequency: values.frequency,
                                        started_at: newTime.unix(),
                                        items: selectedItems.map((item) => {
                                            const { name, ...rest } = item;
                                            return rest;
                                        }),
                                    },
                                }).then((res) => {
                                    if ('data' in res) {
                                        message.success(
                                            'Order' + pluralSuffix + (selectedItems?.length > 1 ? ' have' : ' has') + ' been successfully rescheduled.',
                                        );
                                        setNewTime(null);
                                        setIsOpen(false);
                                        deselectItems();
                                    } else {
                                        message.error(
                                            'There was an error rescheduling the order' + pluralSuffix + '.',
                                        );
                                        console.error(res.error);
                                    }
                                });
                            }
                        }}
                    >
                        <Form.Item
                            name='new_time'
                            label='Time'
                            labelAlign='left'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 18 }}
                            required
                            preserve={false}
                            rules={[
                                {
                                    validator: async (_: RuleObject, value: any) => {
                                        if (!newTime) {
                                            return Promise.reject(new Error(`New time is Required`));
                                        }
                                        Promise.resolve();
                                    },
                                },
                                {
                                    validator: validateFieldIsAfterDate('new time', moment()),
                                },
                            ]}
                        >
                            <DatePickerWithHours form={form} newTime={newTime} setNewTime={setNewTime} fieldName='new_time' />
                        </Form.Item>
                        <MedicationFrequency labelCol={{ span: 24 }} wrapperCol={{ span: 15 }} style={{}} labelAlign='left' />
                    </Form>
                </Collapse.Panel>
            </Collapse>
            {hasInProgressActions &&
                <Alert
                    className='alert-with-icon-aligned-to-top'
                    type='warning'
                    showIcon
                    message='Changes do not apply to in progress actions. However, it will apply to the next scheduled action.'
                />
            }
        </Drawer>
    );
};
