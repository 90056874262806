import { Macro, MacroForm } from 'utils/dataTypes';
import { TagType } from 'utils/types/enums';
import { pimsApi } from './pimsApi';

// Define a service using a base URL and expected endpoints
export const macroApi = pimsApi.injectEndpoints({
    endpoints: (builder) => ({
        getMyMacros: builder.query<Macro[], void>({
            query: () => `my/macros`,
            providesTags: (result, error, id) => [{ type: TagType.Macro, id: 'MY_MACROS' }],
            transformResponse: (response: { data: Macro[] }) => response.data,
        }),
        createMyMacro: builder.mutation<Macro, { macroForm: MacroForm }>({
            query({ macroForm }) {
                return {
                    url: `my/macros`,
                    method: 'POST',
                    body: macroForm,
                };
            },
            invalidatesTags: (result, error, macroId) => [{ type: TagType.Macro }],
        }),
        updateMyMacro: builder.mutation<null, { macroId: number; macroForm: MacroForm }>({
            query({ macroId, macroForm }) {
                return {
                    url: `my/macros/${macroId}`,
                    method: 'PUT',
                    body: macroForm,
                };
            },
            invalidatesTags: (result, error, macroId) => [{ type: TagType.Macro, macroId }],
        }),
        deleteMyMacro: builder.mutation<null, { macroId: number }>({
            query({ macroId }) {
                return {
                    url: `my/macros/${macroId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, error, macroId) => [{ type: TagType.Macro, macroId }],
        }),
    }),
});

export const {
    useGetMyMacrosQuery,
    useLazyGetMyMacrosQuery,
    useCreateMyMacroMutation,
    useUpdateMyMacroMutation,
    useDeleteMyMacroMutation,
} = macroApi;
