import { MessageOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Col, Form, FormInstance, Modal, Row, Tabs } from 'antd';
import Chat from 'components/Chat';
import DetailedAddMultiComplaint, { SingleComplaint } from 'components/forms/DetailedAddMultiComplaint';
import { useQuery } from 'hooks';
import { useFormSubmission } from 'hooks/useFormSubmission';
import { useAppDispatch } from 'hooks/useRedux';
import moment from 'moment';
import { ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAddNewCustomerMutation, useGetCustomerByIdQuery, useModifyCustomerMutation } from 'services/admissionService';
import { useGetBillingDataQuery, useGetVisitDiscountQuery } from 'services/billingService';
import { useGetEstimatesByVisitIdNewQuery } from 'services/estimateService';
import { useGetInstructionOptionsQuery, useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { useGetPatientByIdQuery } from 'services/patientService';
import {
    useGetExamsByVisitIdQuery,
    useGetNotesByVisitIdQuery,
    useGetProblemListByVisitIdQuery,
    useGetVisitByIdQuery,
} from 'services/visitService';
import { setChatOpen } from 'store/slices/visitHeaderSlice';
import { ItemPastDueIcon } from 'utils/componentWrappers';
import { BASE_QUERY_OPTIONS, BIRTHDAY_FORMAT, FormName, PATIENT_WEIGHT_INSTRUCTION_NAME, TS_ITEM_TIME_WIDTH, VISIT_TABS_LINKS } from 'utils/constants';
import { InstructionAction, instructionType, PatientRecordVisit, VisitVital } from 'utils/dataTypes';
import { insertGhostActionsIntoRealActions } from 'utils/ghostActionUtils';
import { calculateTotalCost, getIsInvoiceLocked, getLedgerValues, getRecentVitalsFromInstructions, twelveHourWindowHasPassed } from 'utils/miscFuncs';
import { BillingInfo, BillingItem, EstimateItem } from 'utils/types/billingTypes';
import { BaseSearchOption, isInstanceOfMedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import { BillingTab } from './BillingTab';
import { PaymentSelect } from './BillingTab/Payment/PaymentSelect';
import { ComposeBoxContainer } from './ComposeBoxContainer';
import { DischargeTab } from './DischargeTab';
import { DocumentsTab } from './DocumentsTab';
import { updateExistingEstimate } from './Estimates/EstimateSummary';
import { generateEstimateTableRows } from './Estimates/EstimateTables';
import EstimateTab from './EstimatesNew';
import { calculateTotalEstimateCost } from './EstimatesNew/utils';
import { Notes } from './Notes';
import { OrderSearch } from './OrderSearch';
import { OverviewTab } from './OverviewTab/index';
import { TreatmentSheet } from './TreatmentSheet';
import { VisitHeader } from './VisitHeader';
import { VisitSidebar } from './VisitSidebar';
import { ComposeBoxProvider } from 'hooks/ComposeBoxProvider';

const { TabPane } = Tabs;

export const INITIAL_MODAL_STATE = {
    isVisible: false,
    className: '',
    okButtonProps: {} as ButtonProps,
    okText: 'Submit',
    title: '',
    content: <></>,
    formName: '',
    onFinish: () => null,
    onCancel: () => null,
    width: '75%',
};
interface modalStateProps {
    isVisible: boolean;
    className: string;
    okButtonProps: ButtonProps;
    okText: string;
    title: string;
    content: ReactNode;
    formName: string;
    onFinish: Function;
    onCancel: Function;
    cancelButtonProps?: ButtonProps;
    width?: string;
    form: FormInstance<any>;
    blank?: boolean; // Disables buttons: for modals used as information mediums
    zIndex?: number;
    footer?: ReactNode | null;
}

export const Visit = () => {
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const defaultTab = 'mission_control';
    const currentTab = useQuery().get('section') ?? defaultTab;
    const history = useHistory();
    let visitId = parseInt(urlVisitId);
    const patientRef = useRef<HTMLDivElement>(null); // patientRef - Used to set initial focus, so that hotkeys work on page load
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [previousTab, setPreviousTab] = useState('none');
    const dispatch = useAppDispatch();

    const [incomingEstimateList, setIncomingEstimateList] = useState<EstimateItem[]>([]);
    const approvedRows = generateEstimateTableRows(
        incomingEstimateList,
        'approved',
        updateExistingEstimate(incomingEstimateList, setIncomingEstimateList),
    );

    const { data: previousEstimates, refetch: refetchEstimates, isUninitialized: isUninitializedEstimates } = useGetEstimatesByVisitIdNewQuery({visitId}, BASE_QUERY_OPTIONS);
    const { data: visitDiscount, refetch: refetchDiscount, isUninitialized: isUninitializedDiscount } = useGetVisitDiscountQuery(visitId, { ...BASE_QUERY_OPTIONS });

    const { data: billingData, refetch: refetchBilling, isUninitialized: isUninitializedBilling } = useGetBillingDataQuery({visitId});

    const billingItemsNotInApproved = (billingData?.billing_items || []).filter((item: BillingItem) => {
        if (item.instruction_id === null) {
            return !approvedRows.find((row) => row.orderId === item.non_medical_order_id);
        }
        return !approvedRows.find((row) => row.orderId === item.instruction_id);
    });

    const estimateTaxes = previousEstimates?.reduce((a, b) => {
        if (b.estimate_status === 'approved') {
            return a + b.tax_amount_cents;
        }
        return a;
    }, 0);

    const approvedEstimateTotal = previousEstimates
        ?.filter((estimate) => {
            return estimate?.estimate_status === 'approved';
        })
        ?.map((estimate) => {
            return estimate.estimate_items.map((item) => {
                return calculateTotalEstimateCost(0, item);
            });
        })
        .flat()
        .reduce((a: number, b: number) => {
            return a + b;
        }, 0);

    const { total: approvedEstimateTotalCents } = getLedgerValues(
        approvedEstimateTotal,
        billingData?.amount_paid,
        estimateTaxes,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents_target,
        true,

    );

    const { total: invoiceTotal } = getLedgerValues(
        billingData?.subtotal,
        billingData?.amount_paid,
        billingData?.tax,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents,
    );
    const billingInfo: BillingInfo = {
        invoiceTotal,
        paid: billingData?.amount_paid ?? 0,
        approvedEstimateTotal: approvedEstimateTotalCents ?? 0,
    };
    const notInApprovedSubtotal = (billingItemsNotInApproved || []).reduce((price, billingItem) => {
        return price + calculateTotalCost(billingItem);
    }, 0);
    const { total: notInApprovedTotalCents } = getLedgerValues(
        notInApprovedSubtotal,
        billingData?.amount_paid,
        billingData?.tax,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents,
    );

    useLayoutEffect(() => {
        // patientRef - Used to set initial focus, so that hotkeys work on page load
        if (patientRef.current !== null) {
            patientRef.current.focus();
        }
    }, []);

    const { data: currentVisit } = useGetVisitByIdQuery(visitId, BASE_QUERY_OPTIONS);

    const [isInvoiceLocked, setIsInvoiceLocked] = useState(
        getIsInvoiceLocked(currentVisit?.invoice_finalized_at, currentVisit?.invoice_unlocked_at),
    );
    const [canUnlockInvoice, setCanUnlockInvoice] = useState(
        !currentVisit?.invoice_unlocked_at && !twelveHourWindowHasPassed(currentVisit?.invoice_finalized_at),
    );

    const updateIsInvoiceLocked = () => {
        const isLocked = getIsInvoiceLocked(currentVisit?.invoice_finalized_at, currentVisit?.invoice_unlocked_at);
        setIsInvoiceLocked(isLocked);
        return isLocked;
    };

    const invoiceIsLockedInterval = useRef<any>(null);

    useEffect(() => {
        clearInterval(invoiceIsLockedInterval.current);
        if (currentVisit) {
            const isLocked = getIsInvoiceLocked(currentVisit.invoice_finalized_at, currentVisit.invoice_unlocked_at);
            const canUnlock = !currentVisit.invoice_unlocked_at && !twelveHourWindowHasPassed(currentVisit.invoice_finalized_at);
            setIsInvoiceLocked(isLocked);
            setCanUnlockInvoice(canUnlock);
        }        
    }, [currentVisit]);

    useEffect(() => {
        if (currentVisit?.invoice_finalized_at) {
            invoiceIsLockedInterval.current = setInterval(() => {
                const isLocked = getIsInvoiceLocked(currentVisit?.invoice_finalized_at, currentVisit?.invoice_unlocked_at);
                const canUnlock = !currentVisit?.invoice_unlocked_at && !twelveHourWindowHasPassed(currentVisit?.invoice_finalized_at);
                setIsInvoiceLocked(isLocked);
                setCanUnlockInvoice(canUnlock);
                if (invoiceIsLockedInterval && twelveHourWindowHasPassed(currentVisit?.invoice_finalized_at)) {
                    clearInterval(invoiceIsLockedInterval.current);
                }
            }, 5000);
        }
        return () => clearInterval(invoiceIsLockedInterval.current);
    }, [currentVisit, isInvoiceLocked]);

    /**
     * TODO temporarily these 3 are handled by `Visit.tsx` ONLY
     */
    const [addNewCustomer] = useAddNewCustomerMutation();
    const [modifyCustomer] = useModifyCustomerMutation();

    const [modalForm] = Form.useForm();
    const [modalState, setModalstate] = useState<modalStateProps>({
        ...INITIAL_MODAL_STATE,
        form: modalForm,
    });
    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1200 && currentTab !== VISIT_TABS_LINKS.billing);

    const multiComplaintRef = useRef<any>();

    /**
     * Can pass an empty array to open up the modal with only existing Problems
     * @param complaint_ids array of ids corresponding to NEW complaints for the Modal
     */
    const onComplaintSelect = (complaint_ids: number[]) => {
        setModalstate({
            isVisible: true,
            className: 'complaint-modal',
            okButtonProps: { danger: false },
            okText: 'Submit',
            title: complaint_ids.length ? FormName['detailed_add_multi_complaint'] : FormName['edit_problem'],
            content: <DetailedAddMultiComplaint ref={multiComplaintRef} complaintIds={complaint_ids} />,
            formName: FormName['detailed_add_multi_complaint'], //not used for add/mod complaints
            form: modalForm, //not used for add/mod complaints
            onFinish: () => {
                multiComplaintRef.current.complaintState.forEach((complaint: SingleComplaint) => {
                    //Table items have slightly different fields than what the API wants
                    //So we gotta rejigger the data a bit

                    onFinish(
                        {
                            chronic: complaint.chronic,
                            start_date: complaint.start_date,
                            end_date: complaint.end_date ?? undefined,
                            presenting_complaint: complaint.presenting_complaint,
                            differentials: complaint.differentials ?? [],
                            id: complaint.is_problem ? complaint.id : undefined,
                            complaint_id: complaint.is_problem ? undefined : complaint.id,
                        },
                        complaint.is_problem ? FormName['edit_problem'] : FormName['add_complaint'],
                    );
                });

                multiComplaintRef.current.problemsIdsToDelete.forEach((problem_id: number) => {
                    onFinish({ id_to_delete: problem_id }, FormName.delete_problem);
                });

                setModalstate({
                    ...INITIAL_MODAL_STATE,
                    form: modalForm,
                });
                modalForm.resetFields();
            },
            onCancel: () => {
                const dismissModal = () => {
                    setModalstate({
                        ...INITIAL_MODAL_STATE,
                        form: modalForm,
                    });
                    modalForm.resetFields();
                };

                if (multiComplaintRef.current.unsavedChanges) {
                    Modal.confirm({
                        autoFocusButton: 'ok',
                        title: 'Unsaved Changes',
                        content: 'You have unsaved changes. Select cancel to continue editing.',
                        onOk: dismissModal,
                    });
                } else {
                    dismissModal();
                }
            },
        });
    };

    const { data: progressNoteData, refetch: refetchNoteData, isUninitialized: isUninitializedNoteData } = useGetNotesByVisitIdQuery(visitId, BASE_QUERY_OPTIONS);

    const { data } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);
    const medicineOptions: BaseSearchOption[] | undefined = data;

    const {
        data: instData,
        isLoading: isVisitInstructionsLoading,
        refetch: refetchInstructionData,
        isUninitialized: isUninitializedInstructionData,
    } = useGetInstructionsByVisitIdQuery({ visitId }, BASE_QUERY_OPTIONS);

    const { data: examData, refetch: refetchExamData, isUninitialized: isUninitializedExamData } = useGetExamsByVisitIdQuery(visitId as number, BASE_QUERY_OPTIONS);

    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id as string, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.pet_id,
    });

    // Todo see if this can be done cleaner
    const { data: existingCustomer } = useGetCustomerByIdQuery(currentVisit?.customer_id ?? '', {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.customer_id,
    });

    const { data: problemListData } = useGetProblemListByVisitIdQuery(visitId, BASE_QUERY_OPTIONS);

    const { problemList } = problemListData ?? {
        problemList: [],
    };

    useEffect(() => {
        setActiveTab(currentTab);
        if (currentTab !== previousTab && currentTab === VISIT_TABS_LINKS.missionControl) {
            if (!isUninitializedBilling) {
                refetchBilling();
            }
            if (!isUninitializedDiscount) {
                refetchDiscount();
            }
            if (!isUninitializedEstimates) {
                refetchEstimates();
            }
            if (!isUninitializedInstructionData) {
                refetchInstructionData();
            }
            if (!isUninitializedExamData) {
                refetchExamData();
            }
            if (!isUninitializedNoteData) {
                refetchNoteData();
            }
        }
        setPreviousTab(currentTab);
        if (currentTab !== VISIT_TABS_LINKS.billing) {
            setSidebarOpen(true)
        } else {
            setSidebarOpen(false)
        }
    }, [currentTab]);

    const [onFinishHook] = useFormSubmission();
    // Todo think about ways to simplify form submission
    const onFinish = (values: any, formName: FormName, additionalState: any = {}) => {
        /**
         * TODO temporarily these 2 are handled by `Visit.tsx` ONLY
         * TODO need to move these to useFormSubmission hook somehow
         */
        if (formName === FormName.discharge_togo_med) {
            return Promise.resolve();
        }
        if (formName === FormName['owner_info']) {
            //If the user never selected a customer, then make a new customer
            const customer_id = additionalState?.customerId ?? additionalState?.customer_id;
            if (!customer_id) {
                //Creates a new customer
                //Also attaches customer to this visit (from a subsequent callback)
                //AND attaches customer to this pet (from a subsequent callback)

                return addNewCustomer({
                    visit_id: visitId,
                    pet_id: currentVisit?.pet_id as string,
                    body: {
                        first_name: values.first_name,
                        last_name: values.last_name || null,
                        phone_number: values.phone_number,
                        email: values.email || null,
                        street: values.street || null,
                        city: values.city || null,
                        state: values.state || null,
                        zipcode: values.zipcode || null,
                        note: values.note || null,
                        how_did_you_hear_about_us: null,
                        additional_info: null,
                        birthday: values.birthday ? values.birthday.format(BIRTHDAY_FORMAT) : null,
                        unable_to_collect_birthday: values.birthday ? false : null,
                        email_opt_out: values.email_opt_out || null
                    },
                }).unwrap();
            } else {
                return modifyCustomer({
                    body: {
                        first_name: values.first_name,
                        last_name: values.last_name || null,
                        phone_number: values.phone_number,
                        email: values.email || null,
                        street: values.street || null,
                        city: values.city || null,
                        state: values.state || null,
                        zipcode: values.zipcode || null,
                        note: existingCustomer?.note || null, // Note will not be sent by this form
                        how_did_you_hear_about_us: existingCustomer?.how_did_you_hear_about_us || null,
                        additional_info: existingCustomer?.additional_info || null,
                        birthday: values.birthday ? values.birthday.format(BIRTHDAY_FORMAT) : null,
                        unable_to_collect_birthday: values.birthday ? false : existingCustomer?.unable_to_collect_birthday || null,
                        email_opt_out: values.email_opt_out || null
                    },
                    customer_id: customer_id,
                }).unwrap();
            }
        } else {
            onFinishHook(values, formName, additionalState);
        }

        return Promise.resolve();
    };

    /**
     * TODO
     * Maybe in the distant future, we keep a copy of Vitals directly in the redux store
     * For now, we are going to handle them here and pass them around
     */
    const vitalInstructions = getRecentVitalsFromInstructions(instData);

    const dischargeOptions = medicineOptions?.filter((option) => isInstanceOfMedicineSearchOption(option) && option.pricing_unit === null);

    const orderSearchProps = {
        vitals: vitalInstructions as VisitVital[],
        searchOptions: dischargeOptions ?? [],
        setModalstate: setModalstate,
        modalForm: modalForm,
        visitId,
    };
    const allActions: InstructionAction[] = useMemo(() => {
        return (instData || []).flatMap((instruction) =>
            insertGhostActionsIntoRealActions(
                instruction,
                Math.floor(moment().subtract(12, 'hours').unix() / 1000),
                Math.floor(moment.now() / 1000),
            ),
        );
    }, [instData]);
    const treatmentSheetIcon = useMemo(() => generateTreatmentSheetIcon(allActions ?? []), [allActions]);
    const patientWeight = vitalInstructions?.find((vital) => vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME)?.lastAction?.value as number;

    return (
        <ComposeBoxProvider>
            <div ref={patientRef} tabIndex={-1}>
                <VisitHeader
                    currentVisit={currentVisit ?? ({} as PatientRecordVisit)}
                    customerInfo={existingCustomer}
                    patientData={patientData}
                    patientWeight={patientWeight}
                    setModalstate={setModalstate}
                    modalForm={modalForm}
                    isInvoiceLocked={isInvoiceLocked}
                    canUnlockInvoice={canUnlockInvoice}
                />
                <div className={`visit-wrapper ${sidebarOpen ? 'sidebar-open' : ''}`}>
                    <div className='visit-main-content'>
                        <Tabs
                            className='visit-tabs'
                            id='visit-tabs'
                            defaultActiveKey={defaultTab}
                            size='small'
                            tabBarStyle={{
                                boxShadow: '0px 4px 8px rgba(123, 123, 123, 0.25)',
                                zIndex: 101,
                            }}
                            tabBarExtraContent={{
                                right: (
                                    <Row gutter={[4, 4]}>
                                        <Col>
                                            <OrderSearch
                                                vitals={vitalInstructions as VisitVital[]}
                                                searchOptions={medicineOptions ?? []}
                                                setModalstate={setModalstate}
                                                modalForm={modalForm}
                                                hideToGoMeds
                                                updateIsInvoiceLocked={updateIsInvoiceLocked}
                                            />
                                        </Col>
                                        <Col>
                                            <PaymentSelect
                                                visitId={visitId}
                                                customerId={currentVisit?.customer_id || ''}
                                                approvedEstimateTotalCents={approvedEstimateTotalCents}
                                                invoiceTotal={invoiceTotal}
                                                notInApprovedTotalCents={notInApprovedTotalCents}
                                                paidCents={billingData?.amount_paid ?? 0}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                data-cy='textMessageButton'
                                                className='open-chat-button'
                                                icon={<MessageOutlined />}
                                                onClick={() => dispatch(setChatOpen(true))}
                                            />
                                        </Col>
                                    </Row>
                                ),
                            }}
                            onChange={(activeKey) => {
                                history.replace(`${history.location.pathname}?section=${activeKey}`);
                                setActiveTab(activeKey);
                            }}
                            activeKey={activeTab}
                            destroyInactiveTabPane={false}
                        >
                            <TabPane
                                tab='sidebar-tab-pane'
                                key='sidebar-tab-pane'
                                className='visit-sidebar'
                                forceRender
                            >
                                <VisitSidebar
                                    vitals={vitalInstructions as VisitVital[]}
                                    currentVisit={currentVisit ?? ({} as PatientRecordVisit)}
                                    visitOnFinish={onFinish}
                                    onComplaintSelect={onComplaintSelect}
                                    setSidebarOpen={setSidebarOpen}
                                />
                            </TabPane>

                            <TabPane data-cy='overviewTab' tab={'Overview'} key={VISIT_TABS_LINKS.missionControl}>
                                {!!instData && !!currentVisit && (
                                    <OverviewTab
                                        instructions={instData as instructionType[]}
                                        exams={examData ?? []}
                                        notes={progressNoteData ?? []}
                                        currentVisit={currentVisit as PatientRecordVisit}
                                        onComplaintSelect={onComplaintSelect}
                                        problemList={problemList}
                                        vitalInstructions={vitalInstructions}
                                        visitOnFinish={onFinish}
                                        billingInfo={billingInfo}
                                        modalForm={modalForm}
                                        updateIsInvoiceLocked={updateIsInvoiceLocked}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab='Notes' key={VISIT_TABS_LINKS.progressNotes}>
                                <Notes
                                    currentVisit={currentVisit as PatientRecordVisit}
                                    isActive={activeTab === VISIT_TABS_LINKS.progressNotes}
                                />
                            </TabPane>
                            <TabPane tab='Documents' key={VISIT_TABS_LINKS.documents}>
                                <DocumentsTab
                                    visitId={visitId}
                                    customerInfo={existingCustomer}
                                    isActive={activeTab === VISIT_TABS_LINKS.documents}
                                />
                            </TabPane>
                            <TabPane tab='Estimates' key={VISIT_TABS_LINKS.estimates}>
                                <EstimateTab isActive={activeTab === VISIT_TABS_LINKS.estimates} />
                            </TabPane>
                            <TabPane tab={<span>{treatmentSheetIcon} Treatment Sheet</span>} key={VISIT_TABS_LINKS.treatmentSheet}>
                                {!!currentVisit && !!patientData?.name && !isVisitInstructionsLoading && (
                                    <TreatmentSheet
                                        currentVisit={currentVisit}
                                        patientName={patientData.name}
                                        vitals={vitalInstructions as VisitVital[]}
                                        setModalstate={setModalstate}
                                        modalForm={modalForm}
                                        onModalFinish={onFinish}
                                        isActive={activeTab === VISIT_TABS_LINKS.treatmentSheet}
                                        updateIsInvoiceLocked={updateIsInvoiceLocked}
                                    />
                                )}
                            </TabPane>
                            <TabPane tab='To Go Home' key={VISIT_TABS_LINKS.discharge}>
                                <DischargeTab
                                    orderSearchProps={orderSearchProps}
                                    patient={patientData!}
                                    isTabActive={activeTab === VISIT_TABS_LINKS.discharge}
                                    updateIsInvoiceLocked={updateIsInvoiceLocked}
                                />
                            </TabPane>
                            <TabPane tab='Billing' key={VISIT_TABS_LINKS.billing} forceRender>
                                <BillingTab
                                    visitId={visitId}
                                    currentVisit={currentVisit ?? ({} as PatientRecordVisit)}
                                    patientData={patientData}
                                    isActive={activeTab === VISIT_TABS_LINKS.billing}
                                    isInvoiceLocked={isInvoiceLocked}
                                    canUnlockInvoice={canUnlockInvoice}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <ComposeBoxContainer onFinish={onFinish} />
                <Modal
                    centered={true}
                    className={modalState.className}
                    width={modalState.width || '75%'}
                    maskClosable={false}
                    title={modalState.title}
                    open={modalState.isVisible}
                    okButtonProps={{
                        ...modalState.okButtonProps,
                    }}
                    cancelButtonProps={{ ...modalState.cancelButtonProps }}
                    okText={modalState.okText}
                    onOk={(e) => {
                        modalState.onFinish();
                    }}
                    onCancel={() => {
                        modalState.onCancel();
                    }}
                    destroyOnClose={true}
                    {...(!!modalState.blank && { footer: null })}
                    {...(!!modalState.zIndex && { zIndex: modalState.zIndex })}
                    footer={modalState.footer}
                >
                    {modalState.content}
                </Modal>
                <Chat />
            </div>
        </ComposeBoxProvider>
    );
};

const generateTreatmentSheetIcon = (actions: InstructionAction[]) => {
    const isActionPastDue = actions.some(
        (action) =>
            (action.status === null || action.status === 'scheduled') &&
            moment().add(TS_ITEM_TIME_WIDTH, 'minute').isAfter(moment.unix(action.due_at)),
    );

    return isActionPastDue ? <ItemPastDueIcon /> : null;
};
