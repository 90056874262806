import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useLazyGetFileLinkQuery } from 'services/filesService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { TASKS_HOST } from 'utils/constants';

interface IDownloadDocButtonProps {
    prefix_char: string;
    doc_status?: string;
    visitId: number;
    record_key: string;
}

const DownloadDocButton = ({ prefix_char, doc_status, visitId, record_key }: IDownloadDocButtonProps) => {
    const [getFileLink, { data: fileLink }] = useLazyGetFileLinkQuery();
    const { data: currentVisit } = useGetVisitByIdQuery(visitId);

    const getDownloadLink = (prefix_char: string, doc_status?: string) =>
        ({
            mr: `${TASKS_HOST}/visit/${visitId}/export/record`,
            ds: `${TASKS_HOST}/visit/${visitId}/export/discharge`,
        }[prefix_char] + (doc_status === 'Draft' ? '?draft=true' : ''));

    const canDownloadFile = (prefix_char: string) => prefix_char === 'mr' || prefix_char === 'ds' || prefix_char === 'pf';

    const getDownloadButtonColor = (prefix_char: string) => {
        return canDownloadFile(prefix_char) ? 'var(--gray-7)' : 'var(--gray-4)';
    };

    useEffect(() => {
        if (!!fileLink && prefix_char === 'pf') {
            window.open(fileLink);
        }
    }, [fileLink, prefix_char]);

    return (
        <Tooltip title='Download'>
            {prefix_char === 'pf' || prefix_char === 'g' ? (
                <Button
                    className='file-function-button'
                    onClick={() => {
                        if (prefix_char === 'pf') {
                            getFileLink({
                                pet_id: currentVisit?.pet_id ?? '',
                                file_id: +record_key.split('_')[1],
                            });
                        }
                        if (prefix_char === 'g') {
                            window.open(`/vaccination_form/${record_key}_print`);
                        }
                    }}
                    icon={<DownloadOutlined />}
                />
            ) : (
                <Button
                    style={{ color: getDownloadButtonColor(prefix_char) }}
                    type='text'
                    disabled={!canDownloadFile(prefix_char)}
                    icon={<DownloadOutlined />}
                    href={getDownloadLink(prefix_char, doc_status)}
                    download
                />
            )}
        </Tooltip>
    );
};

export default DownloadDocButton;
