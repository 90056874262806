import moment from 'moment';
import { CustomerInfo, ExistingCriInstruction, FluidAdditive } from './dataTypes';
import { capitalizeFirstLetter } from './stringFormatting';
import { ROUTES } from './types/enums';
import { AdditiveSearchOption } from './types/InstructionOrderTypes';
//TODO this has US and USD hardcoded
//Prob will be fine, VEG is US only
export const formatCurrency = (numberToFormat: number) => {
	return isNaN(numberToFormat)
		? '--'
		: new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
		  }).format(Math.max(numberToFormat / 100));
};

/**
 *
 * https://stackoverflow.com/a/8358141
 */
// export const formatPhoneNumber = (phoneNumberString: string) => {
// 	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
// 	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
// 	if (match) {
// 		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
// 	}
// 	return null;
// };
export const formatPhoneNumber = (phoneNumber: string | undefined): string => {
	if (typeof phoneNumber === 'undefined') {
		return '';
	};
	const currentValue = `${phoneNumber}`.replace(/[^\d]/g, '');
	const cvLength = currentValue.length;
	let phoneNumberFormatted = currentValue;
	if ((cvLength > 10)) {
		const countryCodeLength = cvLength - 10;
		const countryCode = currentValue.substring(0, countryCodeLength);
		const areaCode = currentValue.substring(countryCodeLength, countryCodeLength + 3);
		const middle = currentValue.substring(countryCodeLength + 3, countryCodeLength + 6);
		const last = currentValue.substring(countryCodeLength + 6, countryCodeLength + 10);
		phoneNumberFormatted = countryCode === "1"
			? `(${areaCode}) ${middle}-${last}`
			: `+${countryCode} (${areaCode}) ${middle}-${last}`

	} else if (cvLength > 6) {
		const areaCode = currentValue.substring(0, 3);
		const middle = currentValue.substring(3, 6);
		const last = currentValue.substring(6, 10);
		phoneNumberFormatted =  `(${areaCode}) ${middle}-${last}`
	} else if (cvLength > 3) {
		const areaCode = currentValue.substring(0, 3);
		const middle = currentValue.substring(3, 6);
		phoneNumberFormatted =  `(${areaCode}) ${middle}`
	}
	else if (cvLength > 0) {
		const areaCode = currentValue.substring(0, 3);
		phoneNumberFormatted =  `${areaCode}`
	}
	return phoneNumberFormatted;
};

export const stringToHslColor = (
	str: string,
	saturation: number,
	lightness: number,
) => {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	var h = hash % 360;
	return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
};

const getFirstLetter = (word: string = ''): string => {
	if (word.length) {
		return word[0];
	}
	return '';
};

export const getInitials = (name: string = '') => {
	let resName = '';
	let splitName = name.split(' ');
	resName = getFirstLetter(splitName[0]);

	if (resName.length) {
		getFirstLetter(splitName[1]);
	}
	return resName;
};

export const JSONifyQueryString = <T>(qs: string) => {
	let queries = {};

	if (qs.length) {
		qs = decodeURIComponent(qs);
		queries = JSON.parse(
			'{"' +
				qs
					.replace(/\?/g, '')
					.replace(/&/g, '","')
					.replace(/=/g, '":"') +
				'"}',
		);
	}

	return queries as T;
};

export const appendRouteId = (route: ROUTES, id: string) => {
	let moddedRoute = route.split(':');
	return `${moddedRoute[0]}${id}`;
};

export function roundTo (value: number | string, digits: number): number
export function roundTo (value: number | null | undefined, digits: number): number | null | undefined
export function roundTo (value: number | string | boolean | null | undefined, digits: number): number | boolean | null | undefined
export function roundTo (value: number | string | boolean | null | undefined, digits: number): number | boolean | null | undefined {
	if(typeof value === "number") {
		return Math.round(value * 10 ** digits) / 10 ** digits;
	}
	if(typeof value === "string") {
		return Math.round(parseFloat(value) * 10 ** digits) / 10 ** digits;
	}
	return value;
};

export interface DisplayUser {
	first_name: string | null;
	last_name: string | null;
}
export const displayUser = (user?: DisplayUser | null | CustomerInfo): string => {
	if (!user){
		return "Unassigned"
	} else if (!user.first_name && !user.last_name){
		return "Unassigned"
	}
	else {
		return `${capitalizeFirstLetter(user.first_name ?? '')} ${capitalizeFirstLetter(user.last_name ?? "")}`
	}
} 

export const formatPatientAge = (birthday?: string, deceased_at?: number) => {
	if (!!birthday) {
		let months = 0;
		let years = 0;

		if (!!deceased_at) {
			years = moment
				.unix(deceased_at)
				.diff(moment(birthday), 'years');
			const monthsWithYears = moment
				.unix(deceased_at)
				.diff(moment(birthday), 'month');
			months = monthsWithYears - years * 12;
		} else {
			years = moment().diff(moment(birthday), 'years');
			const monthsWithYears = moment().diff(
				moment(birthday),
				'month',
			);
			months = monthsWithYears - years * 12;
		}

		return `${years} ${_formatYears(
			years,
		)} ${months} ${_formatMonths(months)}`;
	}

	return '';
};

const _formatYears = (years: number) => {
	return years === 1 ? 'yr' : 'yrs';
}

const _formatMonths = (months: number) => {
	return months === 1 ? 'mth' : 'mths';
}

export const shortPetId = (id: string | undefined) => {
	if (!id) return "";
	return id.slice(0, 6);
}

export const weightMultiplier = (criOrder: ExistingCriInstruction, patientWeight?: number ) => {
    if (!patientWeight){
        return 1;
    }
    return (
        (criOrder.approx_patient_weight_kg ?? criOrder.latest_patient_weight_kg ?? patientWeight) / 
        (patientWeight ?? criOrder.approx_patient_weight_kg ?? criOrder.latest_patient_weight_kg)
        )
}

export function formatDateWithMinuteRounding(date: moment.Moment)  {
	const unixDate = date.unix();

	return unixDate - (unixDate % 60)
}

export const getFullAdditiveName = (additive: AdditiveSearchOption | FluidAdditive) => {
	return additive.name + (additive.short_name ? ` (${additive.short_name})` : '');
}

export function getAllInitials(item: string | null | undefined) {
    if (!item) return;

    return item
        .split(' ')
        .map((n) => n[0])
        .join('');
}
