import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useGetWhiteboardInfoQuery } from 'services/whiteboardService';
import CPRStatusSubtitle from './CPRStatusSubtitle';
import Indicator from './Indicator';
import StatusSection from './StatusSection';
import './Whiteboard.css';
import { useParams } from 'react-router-dom';

interface WhiteboardProps {
    whiteBoardLoginLink?: string;
}
const Whiteboard = ({ whiteBoardLoginLink }: WhiteboardProps) => {
    const {
        data: whiteboardInfo,
        isLoading: loadingGetWhiteboardInfo,
        isError,
        error,
    } = useGetWhiteboardInfoQuery(whiteBoardLoginLink, {
        pollingInterval: 60000,
    });

    const whiteboardInfoGrouped = useMemo(() => {
        if (isError) {
            // If there is an error, clear the whiteboard
            return _.groupBy([], 'section');
        }
        return _.groupBy(whiteboardInfo?.visits, 'section');
    }, [whiteboardInfo?.visits, isError]);

    useEffect(() => {
        if (isError && 'data' in error) {
            if ('data' in error) {
                alert(`Whiteboard failed to load with error.   ${error.data}`);
            } else {
                alert(`Whiteboard failed to  load unknown error.`);
            }
        }
    }, [isError, error]);

    return (
        <section className='whiteboard-container'>
            <header className='whiteboard__header'>
                <picture className='whiteboard__VEG-logo'>
                    <source srcSet='/img/VEGtangle.svg' media='(max-width: 1320px)' />
                    <img srcSet='/img/VEGLogo.svg' alt='VEG logo' />
                </picture>

                <CPRStatusSubtitle />

                <h1>
                    <div className='whiteboard__title'>Emergency is our middle name</div>
                    <div className='whiteboard__sub-title'>It's all we do. So we do it best.</div>
                </h1>

                <div className='whiteboard__indicators-container'>
                    <Indicator
                        information={`${whiteboardInfo?.indicator_waiting_spike_3 ?? 0}`}
                        isLoading={loadingGetWhiteboardInfo}
                        title='Waiting spike 3'
                    />
                    <Indicator
                        information={`${whiteboardInfo?.indicator_awaiting_doctor ?? 0}`}
                        isLoading={loadingGetWhiteboardInfo}
                        title='Awaiting doctor'
                    />
                    <Indicator
                        information={`${whiteboardInfo?.indicator_outpatient ?? 0}/${whiteboardInfo?.indicator_inpatient ?? 0}`}
                        isLoading={loadingGetWhiteboardInfo}
                        title='Outpatient / Inpatient'
                    />
                    {/* 
                        Keep commented out for now, till this issue is fixed https://github.com/vegolution/issues/issues/2841#issuecomment-1843017918
                    <Indicator
                        information={`${whiteboardInfo?.indicator_patients ?? 0}`}
                        isLoading={loadingGetWhiteboardInfo}
                        title={`Today's Patients`}
                    /> 
                    */}
                </div>
            </header>

            <section className='whiteboard__board'>
                <div className='whiteboard__board-first-column'>
                    <StatusSection
                        isLoading={loadingGetWhiteboardInfo}
                        titleClassName='whiteboard__status-section--waiting-spike-3'
                        title='Waiting spike 3'
                        titleCounter={whiteboardInfoGrouped['waiting_spike_3']?.length ?? 0}
                        whiteboardItems={whiteboardInfoGrouped['waiting_spike_3']}
                    />
                    <StatusSection
                        isLoading={loadingGetWhiteboardInfo}
                        titleClassName='whiteboard__status-section--assessment'
                        title='Assessment'
                        titleCounter={whiteboardInfoGrouped['assessment']?.length ?? 0}
                        whiteboardItems={whiteboardInfoGrouped['assessment']}
                    />
                    <StatusSection
                        isLoading={loadingGetWhiteboardInfo}
                        titleClassName='whiteboard__status-section--no-whiteboard-status'
                        title='No Whiteboard Status'
                        titleCounter={whiteboardInfoGrouped['no_whiteboard_status']?.length ?? 0}
                        whiteboardItems={whiteboardInfoGrouped['no_whiteboard_status']}
                        tooltipTitle='These visits currently do not appear in their normal section. Go to visit to see visit status and work to be done.'
                    />
                </div>
                <StatusSection
                    isLoading={loadingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--plan-and-consent'
                    title='Plan & Consent'
                    titleCounter={whiteboardInfoGrouped['plan_and_consent']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['plan_and_consent']}
                />
                <StatusSection
                    isLoading={loadingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--treatment'
                    title='Treatment'
                    titleCounter={whiteboardInfoGrouped['treatment']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['treatment']}
                />
                <StatusSection
                    isLoading={loadingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--tgh-review'
                    title='TGH Review'
                    titleCounter={whiteboardInfoGrouped['tgh_review']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['tgh_review']}
                />
                <StatusSection
                    isLoading={loadingGetWhiteboardInfo}
                    titleClassName='whiteboard__status-section--checkout'
                    title='Checkout'
                    titleCounter={whiteboardInfoGrouped['checkout']?.length ?? 0}
                    whiteboardItems={whiteboardInfoGrouped['checkout']}
                />
            </section>
        </section>
    );
};

export default Whiteboard;

export const WhiteboardLoginLink = () => {
    let { whiteBoardLoginLink } = useParams<{ whiteBoardLoginLink: string }>();

    return <Whiteboard whiteBoardLoginLink={whiteBoardLoginLink} />;
};
