import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useListCustomerQuery } from 'services/admissionService';
import { CustomerInfo } from 'utils/dataTypes';
import { formatPhoneNumber } from 'utils/formatFuncs';
import './Contacts.css';

const Contacts = () => {
    const [search, setSearch] = useState('');

    const { data } = useListCustomerQuery();

    const filteredData = data?.filter((contact) => {
        if (search === '') return contact;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return (
            contact.first_name.match(regex) ||
            contact.last_name?.match(regex) ||
            contact.phone_number.match(regex) ||
            contact.email?.match(regex)
        );
    });

    const columns: ColumnsType<CustomerInfo> = [
        {
            title: 'Customer',
            dataIndex: 'first_name',
            key: 'first_name',
            defaultSortOrder: 'ascend',
            render: (_: any, customer) => (
                <span>
                    {customer.first_name} {customer.last_name}
                </span>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            ellipsis: true,
            render: (number) => formatPhoneNumber(number),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            responsive: ['sm'],
        },
        {
            title: 'Address',
            dataIndex: 'street',
            key: 'street',
            ellipsis: true,
            render: (_: any, { street, city, state, zipcode }) =>
                `${street ? `${street},` : ''} ${city ?? ''} ${state ?? ''} ${zipcode ?? ''}`,
            responsive: ['sm'],
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'id',
            width: 100,
            render: (_: any, customer) => (
                <Button
                    data-cy='contactsPageViewButton'
                    onClick={() => {
                        window.open(`/customer/${customer.customer_id}`, '_blank');
                    }}
                >
                    View
                </Button>
            ),
        },
    ];

    return (
        <section className='contacts-page'>
            <h1 data-cy='contactsPageTitle'>Contacts</h1>

            <Input
                placeholder='Search Contact'
                suffix={<SearchOutlined />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                data-cy='contactsPageSearchInput'
            />

            <section>
                <Table columns={columns} dataSource={filteredData ?? []} data-cy='contactsPageTable' />
            </section>
        </section>
    );
};

export default Contacts;
