import { useParams } from 'react-router-dom';
import RabiesVaccinationForm from './RabiesVaccinationForm';
import './VaccinationForm.css';

const VaccinationForm = () => {
    const { formId } = useParams<any>();

    if (formId.charAt(0) === 'g') {
        return <RabiesVaccinationForm />;
    }

    return null;
};

export default VaccinationForm;
