/**
 * Keys map to statuses received from API
 * we gotta clean 'em up a bit
 */
export const DASHBOARD_STATUS_MAP: { [key: string]: string } = {
	arrived: 'Arrived',
	enroute: 'En route',
	noshow: 'No show',
	inprogress: 'In-progress',
	hospitalized: 'Hospitalized',
	discharged: 'Discharged',
	checked_out: 'Checked Out',
	closed: 'Closed'
};

export const AR_STATUS_MAP: { [key: string]: string } = {
	awaiting_payment: 'Awaiting Payment',
	sent_to_collections: 'Sent to Collections',
	uncollectible: 'Uncollectible',
};

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
  