import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TASKS_HOST } from '../utils/constants';

export const taskApi = createApi({
	reducerPath: 'taskApi',
	tagTypes: [
        'Discharge',
		'MedicalRecord',
		'AttributionSummary',
    ],
	baseQuery: fetchBaseQuery({
		baseUrl: TASKS_HOST,
		credentials: 'include',
		prepareHeaders: (headers) => {
			headers.set('Content-Type', 'application/json');
			return headers;
		},
	}),
	endpoints: () => ({}),
});
