import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { Complaint } from '../../utils/dataTypes';

const { Option } = Select;

interface SelectComplaintProps {
  autoFocus?: boolean
  onSelect: Function;
  onDeselect?: Function | null;
  onClear?: Function | null;
  availableComplaints?: Complaint[];
  mode: "multiple" | "tags" | undefined;
}

export const SelectComplaint = ({ availableComplaints, mode, onSelect, onDeselect, autoFocus }: SelectComplaintProps) => {
  const [filteredComplaints, setFilteredComplaints] = useState(availableComplaints);

  useEffect(() => {
    if (!!availableComplaints && availableComplaints?.length > 0) {
      setFilteredComplaints(availableComplaints)
    }
  }, [availableComplaints]);

  const handleSearch = (value: string) => {
    setFilteredComplaints(() => {
      if (!value) return availableComplaints;

      const valueRegExp = new RegExp(value, 'gi');

      return availableComplaints
				?.filter(
					(comp) =>
						comp.common_name.match(valueRegExp) ||
						comp.medical_name?.match(valueRegExp),
				)
				.sort((a, b) => a.common_name.length - b.common_name.length);
    })
  }

	return (
    <Select
      autoFocus={autoFocus}
      style={{ width: '100%' }}
      placeholder={mode === "multiple" ? "" : "Add a problem..."}
      // @ts-ignore
      value={mode === "multiple" ? this?.value : null}
      onSelect={(value) => {
        onSelect(value)
      }}
      onDeselect={(value) => {
        if (onDeselect) {
          onDeselect(value)
        }
      }}
      data-cy="problemInput"
      allowClear={true}
      mode={mode}
      showSearch
      onSearch={handleSearch}
      filterOption={(input, option) => {
        return (
          option?.title
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      }}
    >
      {filteredComplaints?.map((complaint: Complaint) => (
        <Option
          className='rdvm-select'
          value={complaint.id}
          key={`complaint_${complaint.id}`}
          title={`${complaint.common_name} ${complaint.medical_name}`}
        >
          {complaint.common_name}
        </Option>
      ))}
    </Select>
	);
};
