import { VISIT_STATUSES } from './dataTypes';

export const changeStatusWhenAssigningDoctorIfStatusArrived = (
	visitId: number,
	visitStatus: VISIT_STATUSES,
	transitionStatus: ({
		visitId,
		status,
	}: {
		visitId: number;
		status: VISIT_STATUSES;
	}) => any,
) => {
	const arrivedStatus: VISIT_STATUSES = 'arrived';
	const enrouteStatus: VISIT_STATUSES = 'enroute';
	const inProgressStatus: VISIT_STATUSES = 'inprogress';

	if (visitStatus === arrivedStatus || visitStatus === enrouteStatus) {
		transitionStatus({
			status: inProgressStatus,
			visitId,
		});
	}
};

export const downloadFile = async (response: any, fileName: string) => {
	const url = window.URL.createObjectURL(
		await response.blob(),
	);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);

	document.body.appendChild(link);

	link.click();

	link?.parentNode?.removeChild(link);
}
