import { CSSProperties } from 'react';
import NoteMacrosTextarea from './NoteMacros';
import './note-editor.css';

interface NoteEditorProps {
    handleChange: (values: any) => void;
    name: string;
    defaultContent?: string;
    rows?: number;
}

const NoteEditor: React.FC<NoteEditorProps> = ({ handleChange, defaultContent, name, rows = 8 }) => {
    return (
        <div className='note-editor'>
            <NoteMacrosTextarea
                data-cy='noteField'
                initialNoteValue={defaultContent}
                rows={rows}
                handleChange={(value) => handleChange({ [name]: value })}
            />
        </div>
    );
};

export default NoteEditor;
