import { AlertFilled, FireFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { useGetPatientAlertCategoriesQuery } from 'services/patientService';
import { PatientAlert } from 'utils/dataTypes';
import './patient-alert-tag.css';

interface PatientAlertTagProps {
    alert: PatientAlert;
    direction: 'horizontal' | 'vertical';
    noText?: boolean;
}

export const PatientAlertTag = ({ alert, direction, noText }: PatientAlertTagProps) => {
    const { data: patientAlertCategories } = useGetPatientAlertCategoriesQuery(null);
    const category = patientAlertCategories?.find((category: any) => category.id === alert.category_id);
    const getTagData = () => {
        switch (category?.name) {
            case 'Information':
                return {
                    className: 'information',
                    icon: <InfoCircleOutlined className='option-icon' style={{ color: 'var(--veg-blue)' }} />,
                };
            case 'Use Caution - Staff':
                return {
                    className: 'use-caution-staff',
                    icon: <FireFilled className='option-icon' style={{ color: 'var(--veg-secondary-gold)' }} />,
                };
            case 'Medical Risk - Patient':
                return {
                    className: 'medical-risk-patient',
                    icon: <AlertFilled className='option-icon' style={{ color: 'var(--veg-red)' }} />,
                };
            default:
                return {
                    className: 'no-category',
                    icon: <span className=''></span>,
                };
        }
    };
    const tagData = getTagData();

    return (
        <Tooltip title={alert.alert.length > 30 || noText ? alert.alert : null}>
            <Tag className={`patient-alert-tag ${direction} ${tagData.className} ${noText ? 'no-text' : null}`} data-cy="patientAlertTag">
                <span className={alert.alert.length > 30 ? 'ellipsis' : ''}>
                    {tagData.icon} {!noText && alert.alert}
                </span>
            </Tag>
        </Tooltip>
    );
};
