import { Alert, Tooltip } from 'antd';
import './AlertSerialPrice.css';
import { InfoCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';



interface AlertSerialPriceProps {
    serialHours: number;
    inForm?: boolean;
}

export const AlertSerialPrice = ({serialHours, inForm}: AlertSerialPriceProps) => {

    if (!inForm){
        const message = 
        <span className='serial-alert-message'>
            <span>Serial Pricing: </span>
            <span>A flat cost for unlimited actions for up to {serialHours} hrs for this service.</span>
        </span>;
        return (
            <section className={'serial-alert-section'}>
                <Alert
                    icon={<ExclamationCircleFilled />}
                    message={message}
                    type='warning'
                    showIcon
                />
            </section>
        );
    
    }

    return (
        <section className={'serial-alert-section serial-alert-section-in-form'}>
            <Alert 
                type='warning' 
                icon={<ExclamationCircleFilled />}
                message={<span>Serial Pricing</span>}
                description={`A flat cost for unlimited actions for up to ${serialHours} hrs for this service.`}
                showIcon
            />  
        </section>  
    );
};
export default AlertSerialPrice;
