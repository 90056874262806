import { ExceptionOutlined, StopOutlined } from '@ant-design/icons';
import { Checkbox, FormInstance, Tooltip } from 'antd';
import NoChargeIcon from 'assets/img/NoChargeIcon';
import { useEffect, useRef, useState } from 'react';
import { FormName } from 'utils/constants';
import {
    ExistingDiagInstruction,
    ExistingMedInstruction,
    PimsUser,
    TsGroupType,
    isInstanceOfBaseInstruction,
    isInstanceOfExistingCriInstruction,
    isInstanceOfExistingDiagInstruction,
    isInstanceOfExistingFluidInstruction,
    isInstanceOfExistingOxygenTherapyInstruction,
    isInstanceOfExistingTaskInstruction,
    isInstanceOfExistingToGoMedInstruction,
    isInstanceOfMedicineInstruction,
} from 'utils/dataTypes';
import { roundTo, weightMultiplier } from 'utils/formatFuncs';
import { BaseSearchOption } from 'utils/types/InstructionOrderTypes';
import { formatFluidAdditiveNameList } from 'views/visit/TreatmentSheet';
import DiagnosticData from '../DiagnosticData';
import MedicationData from '../MedicationData';
import OrderContextMenu from '../OrderContextMenu';
import TaskData from '../TaskData';
import './TreatmentSheetSideColumn.css';


const onSelect = (
    item: TsGroupType,
    selected: (number | string)[],
    setSelected: React.Dispatch<React.SetStateAction<(number | string)[]>>,
) => {
    if (selected.includes(item.id)) {
        const newSelectedList = selected.filter((selectedId) => selectedId !== item.id);
        setSelected(newSelectedList);
    } else {
        const newSelectedList = [...selected, item.id];
        setSelected(newSelectedList);
    }
};

const isSelected = (id: number | string, selected: (number | string)[]) => selected.some((selectedId) => selectedId === id);

interface ITreatmentSheetSideColumnProps {
	group: TsGroupType;
	modalForm: FormInstance<any>;
	onModalFinish: (values: any, formName: FormName) => void;
	patientWeight: number;
	setContextMenuState: Function;
	setModalState: Function;
	user?: PimsUser;
	baseSearchOption?: BaseSearchOption[];
	updateIsInvoiceLocked: () => boolean;
	selected: (number | string)[];
    setSelected: React.Dispatch<React.SetStateAction<(number | string)[]>>;
}

const TreatmentSheetSideColumn = ({
	group,
	modalForm,
	onModalFinish,
	patientWeight,
	setContextMenuState,
	setModalState,
	user,
	baseSearchOption,
	updateIsInvoiceLocked,
	selected,
	setSelected,
}: ITreatmentSheetSideColumnProps) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const titleRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (titleRef.current) {
			setShowTooltip(
				titleRef.current.scrollWidth > titleRef.current.clientWidth,
			);
		}
	}, [titleRef]);

	const showContextMenu = () => {
		if (isInstanceOfBaseInstruction(group)) {
			return group.type_id !== 'TGH';
		}
	};

	const instructionIcon = isInstanceOfBaseInstruction(group) && group.notes ?
		<Tooltip title={group.notes} overlayClassName='treatment-sheet-side-column__instruction-tooltip'>
			<ExceptionOutlined className='treatment-sheet-side-column__instruction-icon' />
		</Tooltip>
		: <></>;
    
    const enableBulkRescheduleCheckbox =
        (group.parent === 'Vitals' ||
            group.parent === 'Monitoring' ||
            group.parent === 'D' ||
            group.parent === 'M' ||
            group.parent === 'T') &&
        !(isInstanceOfBaseInstruction(group) && group.discontinued_at);

	return (
        <div className='treatment-sheet-side-column'>
            <div className='treatment-sheet-side-column__title-wrapper'>
                {group.parent !== 'Other' && group.parent !== 'E' && (
                    <Checkbox
                        style={{ marginRight: '8px', fontSize: '14px' }}
                        checked={isSelected(group.id, selected)}
                        onClick={(e) => {
                            onSelect(group, selected, setSelected);
                        }}
                        disabled={!enableBulkRescheduleCheckbox}
                    />
                )}
                <Tooltip title={showTooltip ? group.title : null}>
                    <span ref={titleRef} className='treatment-sheet-side-column__title'>
                        {group.title}
                    </span>
                </Tooltip>

                <div style={{ marginLeft: 'auto' }}>
                    {isInstanceOfBaseInstruction(group) && group.is_free && (
                        <Tooltip title='No Charge'>
                            <NoChargeIcon className='no-charge-icon' />
                        </Tooltip>
                    )}

                    {isInstanceOfBaseInstruction(group) && !!group.discontinued_at && (
                        <Tooltip title='Discontinued'>
                            <StopOutlined className='discontinued-icon' />
                        </Tooltip>
                    )}
                </div>

				{showContextMenu() && (
					<OrderContextMenu
						{...{
							group,
							modalForm,
							onModalFinish,
							patientWeight,
							setContextMenuState,
							setModalState,
							user,
							baseSearchOption,
							updateIsInvoiceLocked,
						}}
					/>
				)}
			</div>

			{isInstanceOfExistingOxygenTherapyInstruction(group) && (
				<div className='instructions-centered-container'>
					{roundTo(group.oxygen_quantity / 100, 2)}{group.oxygen_unit}
					{instructionIcon}
				</div>
			)}

            <div style={{marginLeft: '24px'}}>
            {isInstanceOfExistingFluidInstruction(group) && (
                <div className='instructions-centered-container'>
                    {group.rate_ml_per_hr ? group.rate_ml_per_hr + ' mL/hr' : group.fluids_volume_ml + ' ml'}
                    {isInstanceOfExistingFluidInstruction(group) && group.fluid_additives.length > 0 && (
                        <div className='fluid-additives'>
                            <span style={{ padding: '0 4px' }}>{'|'}</span>
                            <span>{formatFluidAdditiveNameList(group.fluid_additives)}</span>
                        </div>
                    )}
                    {instructionIcon}
                </div>
            )}

            {isInstanceOfMedicineInstruction(group) && (
                <MedicationData group={group as ExistingMedInstruction} instructionIcon={instructionIcon} patientWeight={patientWeight} />
            )}

            {isInstanceOfExistingCriInstruction(group) && (
                <div className='instructions-centered-container'>
                    {group.default_cri_unit === 'mcg/kg/min'
                        ? `${roundTo((group.dose / 60) * 1000 * weightMultiplier(group, patientWeight), 3)} ${group.default_cri_unit}`
                        : `${roundTo(group.dose * weightMultiplier(group, patientWeight), 3)} ${group.dose_unit}`}
                    {instructionIcon}
                </div>
            )}
            {isInstanceOfExistingDiagInstruction(group) && (
                <DiagnosticData group={group as ExistingDiagInstruction} instructionIcon={instructionIcon} />
            )}
            {isInstanceOfExistingTaskInstruction(group) && <TaskData group={group} instructionIcon={instructionIcon} />}
            {isInstanceOfExistingToGoMedInstruction(group) && <div style={{ marginLeft: 'auto' }}>{instructionIcon}</div>}
            </div>
        </div>
    );
};

export default TreatmentSheetSideColumn;
