
import { TagType } from 'utils/types/enums';
import { WebCheckout, WebCheckoutIntent } from 'utils/types/paymentTypes';
import { ConnectionToken, IPaymentIntent } from '@stripe/terminal-js';
import { pimsApi } from './pimsApi';

const paymentService = pimsApi.injectEndpoints({
	endpoints: (builder) => ({
		getCurrentFormLinkId: builder.query<string | null, number>({
			query: (visitId) => `visit/${visitId}/current_form_link_id`,
			providesTags: (result, error, id) => [
				{ type: TagType.WebCheckout, id },
			],
			transformResponse: (response: { data: string | null }) => {
				return response.data;
			},
			
		}),
		addWebCheckout: builder.mutation<
			string,
			{ visitId: number; body: {amount_cents: number, phone_number: string } }
		>({
			query({ visitId, body }) {
				return {
					url: `visit/${visitId}/web_checkout`,
					method: "POST",
					body: body
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.WebCheckout, id: visitId },
			],
		}),
		deactivateCheckout: builder.mutation<
			null,
			{ visitId: number; }
		>({
			query({ visitId }) {
				return {
					url: `visit/${visitId}/web_checkout/deactivate`,
					method: "PUT",
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.WebCheckout, id: visitId },
			],
		}),
		putWebCheckout: builder.mutation<
			WebCheckout,
			{ urlFormId: string; }
		>({
			query({ urlFormId }) {
				return {
					url: `form_link/e/${urlFormId}`,
					method: "PUT",
				};
			},
			transformResponse: (response: { data: WebCheckout }) => {
				return response.data;
			},
		}),
		addWebCheckoutIntent: builder.mutation<
			WebCheckoutIntent,
			{ urlFormId: string; body: {donation_cents: number, checked_opt_in_marketing_email: boolean | null} }
		>({
			query({ urlFormId, body }) {
				return {
					url: `form_link/e/${urlFormId}`,
					method: "POST",
					body: body
				};
			},
			transformResponse: (response: { data: WebCheckoutIntent }) => {
				return response.data;
			},
		}),
		addDirectPayment: builder.mutation<
			boolean,
			{
				visitId: number;
				body: {payment_type: string, amount_cents: number}
			}
		>({
			query({ visitId, body }) {
				return {
					url: `visit/${visitId}/direct_payment`,
					method: "POST",
					body: body
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Ledger, id: visitId },
				{ type: TagType.Billing, id: visitId },
			],
			transformResponse: (response: { data: boolean }) => {
				return response.data;
			},
		}),
		fetchConnectionToken: builder.mutation<
			ConnectionToken,
			{visitId: number}
		>({
			query({visitId}) {
				return {
					url: `visit/${visitId}/connection_token`,
					method: "POST",
				};
			},
			transformResponse: (response: {data: {secret: ConnectionToken}}) => {
				return response.data.secret;
			},
		}),
		createPaymentIntent: builder.mutation<
			IPaymentIntent,
			{visitId: number, body: {amount_cents: number}}
		>({
			query({visitId, body}) {
				return {
					url: `visit/${visitId}/create_payment_intent`,
					method: "POST",
					body: body,
				};
			},
			transformResponse: (response: {data: IPaymentIntent}) => {
				return response.data;
			},
		}),
		capturePaymentIntent: builder.mutation<
			IPaymentIntent,
			{visitId: number, body: { payment_intent_id: string }}
		>({
			query({visitId, body}) {
				return {
					url: `visit/${visitId}/capture_payment_intent`,
					method: "POST",
					body: body,
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Ledger, id: visitId },
				{ type: TagType.Billing, id: visitId },
			],
			transformResponse: (response: {data: IPaymentIntent}) => {
				return response.data;
			},
		}),
		refundPayment: builder.mutation<
			boolean,
			{
				visitId: number;
				body: {ledger_id: number, amount_cents: number}
			}
		>({
			query({ visitId, body }) {
				return {
					url: `visit/${visitId}/refund_payment`,
					method: "POST",
					body: body
				};
			},
			invalidatesTags: (result, error, { visitId }) => [
				{ type: TagType.Ledger, id: visitId },
				{ type: TagType.Billing, id: visitId },
			],
			transformResponse: (response: { data: boolean }) => {
				return response.data;
			},
		}),
		saveWebCheckoutPaymentError: builder.mutation<
			null,
			{ 
				body: {
					payment_intent_id: string,
					last_error_amount_cents: number | null,
					last_error_code: string,
					last_error_message: string,
					card_brand: string,
					last_4_digits: string,
				}
			}
		>({
			query({ body }) {
				return {
					url: `web_checkout_save_payment_error`,
					method: "POST",
					body
				};
			},
		}),
	}),
});

export const {
	useGetCurrentFormLinkIdQuery,
	useAddWebCheckoutMutation,
	useDeactivateCheckoutMutation,
	usePutWebCheckoutMutation,
	useAddWebCheckoutIntentMutation,
	useAddDirectPaymentMutation,
	useFetchConnectionTokenMutation,
	useCreatePaymentIntentMutation,
	useCapturePaymentIntentMutation,
	useRefundPaymentMutation,
	useSaveWebCheckoutPaymentErrorMutation,
} = paymentService;
