import {
    PlusOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Empty,
    List,
    Row,
    Typography,
} from 'antd';
import './index.css';

import EstimateTable from './EstimateTable'
import { useEffect, useState } from 'react';
import EstimateModal  from './EstimateModal';
import { Estimate, EstimateCreate } from 'utils/types/estimateTypesNew';
import { useParams } from 'react-router-dom';
import { useGetVisitByIdQuery } from 'services/visitService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { closedErrorMsg } from 'utils/disableFuncs';
import { useGetEstimatesByVisitIdNewQuery, useGetEstimatesByVisitIdQuery } from 'services/estimateService';
import { useAppSelector } from 'hooks/useRedux';
import { useGetVisitDiscountQuery } from 'services/billingService';


interface EstimateTabProps {
    isActive: boolean
}

const EstimateTab = ({isActive}: EstimateTabProps) => {
    const paymentModalOpen = useAppSelector((state) => state.visitHeaderSlice.paymentModalOpen);


    const [isOpen, setIsOpen] = useState(false)
    
    const [editEstimate, setEditEstimate] = useState<Estimate | undefined>()
    const [newEstimate, setNewEstimate] = useState<EstimateCreate | undefined>()
    
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { data: visitDiscount } = useGetVisitDiscountQuery(visitId);

    const [pollingInterval, setPollingInterval] = useState(60000);
    useEffect(() => {
        if (!paymentModalOpen && isActive) {
            setPollingInterval(60000);
        } else {
            setPollingInterval(0);
        }
    }, [paymentModalOpen, isActive]);

    const [estimates, setEstimates] = useState<Estimate[]>([]);
    const [isRefresh, setIsRefresh] = useState(false);
    const { data: localEstimates, refetch: refetchEstimates,  isFetching: isFetchingEstimates, isSuccess: isSuccessEstimates  } = useGetEstimatesByVisitIdNewQuery({visitId, isRefresh}, { pollingInterval });


    useEffect(() => {
        setIsRefresh(false);
        setEstimates([]);
        if (isActive){
            refetchEstimates();
        }
    }, [isActive]);

    useEffect(() => {
        if (localEstimates && !isFetchingEstimates && isSuccessEstimates) {
            if (localEstimates?.length > 0) {
                setIsRefresh(true);
                setEstimates(localEstimates);
            }
        }
    }, [estimates, isFetchingEstimates, isSuccessEstimates]);

    useEffect(() => {
        if (paymentModalOpen) {
            refetchEstimates();
        }
    }, [paymentModalOpen]);

    const { data: currentVisit } = useGetVisitByIdQuery(
		visitId,
		BASE_QUERY_OPTIONS,
	);
	const isVisitFinalized = Boolean(currentVisit?.finalized_at);

    const openEstimateModal = (editEstimate?: Estimate, newEstimate?: EstimateCreate) => {
        if (isVisitFinalized){
            return closedErrorMsg(isVisitFinalized)
        }
        setEditEstimate(editEstimate);
        setNewEstimate(newEstimate);
        setIsOpen(true);
    }
    return (
        <div className='estimate-section'>
            <Row style={{marginBottom:"24px"}}>
            <Col span={24}>
                    <Typography.Text
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}
                    >
                        Estimates
                    </Typography.Text>
                </Col>
                </Row>
            <Row gutter={[24, 8]}>
                <Col span={24}>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => openEstimateModal()}
                        data-cy={'newEstimateButton'}
                    >
                        New Estimate
                    </Button>
                    {isOpen && (
                        <EstimateModal 
                            isOpen={isOpen} 
                            setIsOpen={setIsOpen} 
                            estimate={editEstimate} 
                            newEstimate={newEstimate} 
                            setNewEstimate={setNewEstimate} 
                            setEditEstimate={setEditEstimate}
                            isFirstEstimate={estimates?.length === 0}
                        />
                    )}
                </Col>
                <div id="cypressTag" className="normalRun" style={{display:'none'}} />
                <Col span={24}>
                    <List
                        locale={{
                            emptyText: (
                                <Empty
                                    description='No Estimate Created'
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                        dataSource={estimates}
                        renderItem={(estimate) => {
                            return (
                                <div className='estimate-table-list'>
                                    <EstimateTable estimate={estimate} visitDiscount={visitDiscount} openEstimateModal={openEstimateModal}/>
                                </div>
                            );
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};



export default EstimateTab;
