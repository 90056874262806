import { DownOutlined, EllipsisOutlined, ExclamationCircleOutlined, FormOutlined, PaperClipOutlined, UpOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PatientNote, useDeletePatientNoteMutation, useGetPatientByIdQuery } from 'services/patientService';
import { UserNameWrapper } from 'utils/componentWrappers';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { Separator } from '.';

interface NoteCardProps {
    noteItem: PatientNote;
    setNoteToUpdate: (noteItem: PatientNote) => void;
}

const NoteCard: FC<NoteCardProps> = ({ noteItem, setNoteToUpdate }) => {
    const [viewMore, setViewMore] = useState(false);
    const { pet_id } = useParams<{ pet_id: string }>();

    const { data: patientData } = useGetPatientByIdQuery(pet_id, { skip: !pet_id });
    const [deletePatientNote] = useDeletePatientNoteMutation();

    const confirmDeleteNote = () => {
        confirm({
            title: 'Remove Note',
            icon: <ExclamationCircleOutlined style={{ color: 'var(--veg-red)' }} />,
            content: `Are you sure you want to remove this note from ${capitalizeFirstLetter(patientData?.name ?? '')}'s visit?`,
            okText: 'Remove',
            okType: 'default',
            okButtonProps: {
                style: { backgroundColor: 'var(--veg-red)', color: '#fff' },
            },
            cancelText: 'Cancel',
            onOk: async () => {
                const res = await deletePatientNote({ pet_id, noteId: noteItem.id });

                if ('data' in res) {
                    message.success('Note successfully deleted');
                } else {
                    message.error('There was an error deleting this note');
                    console.error(res.error);
                }
            },
        });
    };

    return (
        <div className='visits-and-note-card'>
            <div className='visits-and-note-card__header'>
                <span>
                    <Avatar style={{ backgroundColor: 'var(--veg-tint-lime)' }} icon={<FormOutlined />} />
                </span>
                <span>
                    <div className='visits-and-note-card__title'>Note</div>
                    <div>
                        <span>{moment.unix(noteItem.created_at).format('MM/DD/YYYY hh:mm a')}</span>

                        <Separator />

                        <span>
                            <UserNameWrapper>{noteItem.created_by}</UserNameWrapper>
                        </span>
                    </div>
                </span>
                <span className='visits-and-note-card__function-button'>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key='edit-patient-note'
                                    onClick={() => {
                                        setNoteToUpdate(noteItem);
                                    }}
                                >
                                    Edit
                                </Menu.Item>
                                <Menu.Item
                                    key='delete-patient-note'
                                    onClick={() => {
                                        confirmDeleteNote();
                                    }}
                                    style={{ color: 'var(--red-5)' }}
                                >
                                    Remove
                                </Menu.Item>
                            </Menu>
                        }
                        placement='bottomRight'
                        arrow
                    >
                        <Button>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </span>
            </div>

            <div className='visits-and-note-card__content'>
                {noteItem.note.length > 380 && (
                    <Button icon={viewMore ? <UpOutlined /> : <DownOutlined />} onClick={() => setViewMore((oldValue) => !oldValue)} />
                )}

                <p style={{ maxHeight: viewMore ? 'initial' : '44px' }}>{noteItem.note}</p>
            </div>

            {noteItem.file_link && (
                <div className='visits-and-note-card__attachment'>
                    <a href={noteItem.file_link} rel='noreferrer' target='_blank'>
                        <PaperClipOutlined /> {!!noteItem.file_name && decodeURI(noteItem.file_name)}
                    </a>
                </div>
            )}
        </div>
    );
};

export default NoteCard;
