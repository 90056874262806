import { BasePackageItem, BaseSearchOption } from './types/InstructionOrderTypes';

/**
 * Generator function (currying or something? IDK) for sorting by date/time
 * @param fieldName defaults to `recorded_at` name of field that contains the date to be sorted by
 * @param direction defaults to `asc` sort direction
 * @returns function to be passed into native JS sort
 */
export const generateDateSort = (
	fieldName: string = 'recorded_at',
	direction: 'asc' | 'desc' = 'asc',
) => {
	return (a: any, b: any) => {
		var dateA = new Date(a[fieldName]) as any;
		var dateB = new Date(b[fieldName]) as any;
		if (direction === 'asc') {
			return dateA - dateB;
		} else {
			return dateB - dateA;
		}
	};
};

/**
 * Meds, then diagnostics, then tasks
 * Then, alphabetical
 * @returns function that can be passed thru as the callback to `.sort()`
 */
export const sortInstructions = (a: BaseSearchOption | BasePackageItem , b: BaseSearchOption | BasePackageItem ) => {
	if (
		(a.type_id === 'M' && b.type_id === 'M') ||
		(a.type_id === 'D' && b.type_id === 'D') ||
		(a.type_id === 'T' && b.type_id === 'T') ||
		(a.type_id === 'C' && b.type_id === 'C')
	) {
		//Both are same type, sort alphabetically
		return a.name < b.name ? -1 : 1;
	} else if (a.type_id === 'M') {
		//We know that ONLY A is a med
		return 1;
	} else if (b.type_id === 'M') {
		//We know that ONLY B is a med
		return -1;
	} else if (a.type_id === 'D') {
		//We know that ONLY A is a diag
		return 1;
	} else if (b.type_id === 'D') {
		//We know that ONLY B is a diag
		return -1;
	} else if (a.type_id === 'T') {
		//We know that ONLY A is a task
		return 1;
	} else if (b.type_id === 'T') {
		//We know that ONLY B is a task
		return -1;
	} else if (a.type_id === 'C') {
		//We know that ONLY A is a CRI
		return 1;
	} else {
		//We know that ONLY B is a CRI
		return -1;
	}
};
