import { Select, FormInstance, Row, Col, InputNumber, Form, Input, Divider } from "antd";
import { CSSProperties, useState, useEffect, useMemo } from "react";
import { useGetUserDataQuery } from "services/authService";
import { useGetFluidOptionsQuery } from "services/instructionService";
import { useLazyGetSupplementalByIdQuery } from "services/supplementalService";
import { BASE_QUERY_OPTIONS } from "utils/constants";
import { PimsUser } from "utils/dataTypes";
import { roundTo } from "utils/formatFuncs";
import { CriEstimateFormFields } from "utils/types/billingTypes";
import { EnrichedCriSearchOption, isInstanceOfEnrichedCriSearchOption } from "utils/types/InstructionOrderTypes";
import { CriEstimateRules } from "utils/types/validations";
import { validateFieldIsGreaterThan } from "utils/validationFuncs";
import { BLANK_FLUID_OPTION, CriOrderReview } from "../CriOrder";
import { HiddenInput } from "../fields/HiddenInput";


const { Option } = Select;

const formItemStyle: CSSProperties = {
	width: '100%',
};

const getDefaults = (
	options: EnrichedCriSearchOption,
	label: string,
	user?: PimsUser,
	approxPatientWeight?: number,
	patientWeight?: number
): Partial<CriEstimateFormFields> => {
	let fluidRate: number | undefined;
	let dose: number | null | undefined = roundTo(options.default_dose, 3);
	const localWeight = patientWeight ?? approxPatientWeight;
	if (localWeight && options.default_dose) {
		fluidRate =
			(options.default_dose *
				(localWeight)) /
			(options.medication
				.numerator_value ?? 0);
	}

	if (options.default_cri_unit === 'mcg/kg/min' && options.default_dose) {
		dose = roundTo(options.default_dose * 1000 / 60 , 3);
	}
	
	return {
		medication_id: options.medication_id,
		fluids_id: options.fluids_id,
		type_id: options.type_id,
		fluids_volume_ml: options.fluids_volume_ml,
		name: options.name,
		dose: dose || undefined,
		dose_unit: options.default_cri_unit ?? options.medication.numerator_unit + "/kg/hr",
		rate_ml_per_hr: options.default_rate_ml_per_hr || fluidRate,
		approx_patient_weight_kg: approxPatientWeight || null,
		latest_patient_weight_kg: patientWeight || null,
		is_prn: false,
		prn_condition: null,
		unit: options.medication.dose_unit, // Todo look into Cri Units
		unit_cost_cents: options.cents,
		priority: false,
		is_free: false,
		reason: null,
		ordered_by: user?.user_id || 0,
		notes: null,
		label,
		supplemental_cost_cents: null,
		is_recurring_supplemental: null
	};
};

interface CriEstimateProps {
	options: EnrichedCriSearchOption;
	patientWeight?: number;
	onFormChange?: Function; //not actually optional -- always sent via FormWrapper
	getFormData?: Function; //not actually optional -- always sent via FormWrapper
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	form?: FormInstance<any>
}
export const CriEstimate = (props: CriEstimateProps) => {
	const { options, patientWeight } = props;

	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);

	const { data: apiFluidOptions } = useGetFluidOptionsQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const [trigger, {data: supplementalData}] = useLazyGetSupplementalByIdQuery()

	let fluidOptions = apiFluidOptions ? [...apiFluidOptions] : [];
	fluidOptions?.unshift(BLANK_FLUID_OPTION);

	const [approxPatientWeight, setApproxPatientWeight] = useState(0);

	const [fluidRate, setFluidRate] = useState(options.default_rate_ml_per_hr || 0);
	const [dose, setDose] = useState(options.default_dose || 0);
	const [doseUnit, setDoseUnit] = useState(options.default_cri_unit || null);


	const updateDose = (newDose: number) => {
		setDose(newDose);
		onFormChange({ dose: newDose })
	}

	const [fluidsId, setFluidsId] = useState(options.fluids_id ?? null);
	const [fluidVolume, setFluidVolume] = useState(options.fluids_volume_ml || null);

	useEffect(() => {
		//this means fluid rate is LOCKED to dosage
		//Change rate to match new dose
		if (
			options.medication.numerator_value &&
			(!fluidsId ||
				fluidsId === BLANK_FLUID_OPTION.id)
		) {
			let localDose = dose || 0;

			if (doseUnit === 'mcg/kg/min') {
				localDose = dose / 1000 * 60;
			}

			const localWeight = patientWeight ?? approxPatientWeight;
			const newFluidRate =
				roundTo(
					(localDose * localWeight) /
					options.medication.numerator_value *
					options.medication.denominator_value
					, 2);

			setFluidRate(newFluidRate);
			setFluidVolume(null);
			onFormChange({
				rate_ml_per_hr: newFluidRate,
				fluids_volume_ml: null,
			});
		}

	}, [dose, approxPatientWeight, fluidsId])

	const [bagLabel, setBagLabel] = useState(
		`${options.medication_name} ${fluidVolume ? 'qs ' + fluidVolume + 'mL' : ''
		} ${options.fluid?.abbreviation ?? ''}`,
	);
	const updateBagLabel = (newLabel: string) => {
		setBagLabel(newLabel);
		onFormChange({ label: newLabel })
	}

	const onFormChange = useMemo(
		() =>
			props.onFormChange ??
			(() => console.error('ERROR, onFormChange NOT PASSED THROUGH')),
		[props.onFormChange],
	);

	const getFormData =
		props.getFormData ??
		(() => console.error('ERROR, getFormData NOT PASSED THROUGH'));

	//If we have a PatientWeight on file, LOCK that in
	//Otherwise, we are beholden to the "approx weight"

	useEffect(() => {
		const defaultsMedicines = getDefaults(options, bagLabel, loggedInUserData, approxPatientWeight, patientWeight);
		if (props.form) {
			props.form.resetFields(Object.keys(defaultsMedicines))
		}
		if (isInstanceOfEnrichedCriSearchOption(options) && options.supplemental_id){
			trigger({supplemental_id: options.supplemental_id})
		}
		onFormChange(defaultsMedicines)
		updateDose(defaultsMedicines.dose || 0)

	}, [options]);

	useEffect(() => {
		if (supplementalData) {
			onFormChange({
				is_recurring_supplemental: supplementalData.recurring,
				supplemental_cost_cents: supplementalData.cents
			})
		}
	}, [supplementalData])

	// The null option for the CRI is set to an ID of -1
	const isFluidIdSet = fluidsId !== null && fluidsId >= 0;
	const fluidVolumeRules = isFluidIdSet
		? [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('diluent volume') ,
			}
		]
		: [];
	const criEstimateRules: CriEstimateRules = {
		fluids_id: [],
		fluids_volume_ml:fluidVolumeRules,
		rate_ml_per_hr: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('fluid rate'),
			}
		],
		label: [{ required: true }],
		approx_patient_weight_kg: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('patient weight'),
			}
		],
		dose: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('dose'),
			}
		],
		route_id: [{ required: true }],
		is_prn: [{ required: false, type: 'boolean' }],
		prn_condition: []
	}

	const showWeight = patientWeight === undefined;

	const getReviewDose = () => {
		if (options.default_cri_unit === doseUnit) {
			return dose;
		}

		return doseUnit === 'mcg/kg/min' ? dose / 60 * 1000 : dose;
	}

	return (
		<>
			{!patientWeight ? (
				<Row style={{ width: '100%' }} align='middle' justify='start'>
					<Col span={6}>Approx. weight</Col>

					<Col>
						<Form.Item
							preserve={true}
							name='approx_patient_weight_kg'
							rules={criEstimateRules.approx_patient_weight_kg}
							data-cy={'approxWeightInput'}
						>
							<InputNumber
								autoFocus={showWeight}
								name="approx_patient_weight_kg"
								min={0}
								value={approxPatientWeight}
								onChange={(value) => {
									onFormChange({
										approx_patient_weight_kg: value,
									});
									setApproxPatientWeight(value ?? 0);
								}}
							/>
						</Form.Item>
					</Col>
					<Col offset={1}>kg</Col>
				</Row>
			) : (
				<Row style={{ width: '100%' }}>
					<HiddenInput fieldName='approx_patient_weight_kg' />
					<Col span={6}>Patient weight:</Col>
					<Col>
						<b>{patientWeight}kg</b>
					</Col>
				</Row>
			)}
			<Row style={{ width: '100%' }} align='middle'>
				<Col span={6}>Concentration:</Col>
				<Col>
					<b>
						{roundTo(options.medication.numerator_value, 3)}
						{options.medication.numerator_unit}/
						{options.medication.denominator_value}
						{options.medication.denominator_unit}
					</b>
				</Col>
			</Row>
			<>
				<Row
					justify='start'
					align='middle'
					style={{ width: '100%' }}
					gutter={[8, 8]}
				>
					<Col span={6}>Dose:</Col>
					<Col>
						<Form.Item
							name='dose'
							preserve={false}
							style={formItemStyle}
							wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
							rules={criEstimateRules.dose}
							data-cy={'doseInput'}
						>
							<InputNumber
								autoFocus={!showWeight}
								style={formItemStyle}
								onChange={(value) => {
									const zeroedValue = value || 0;
									updateDose(zeroedValue)
								}}
								min={0}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item name='dose_unit'>
							{doseUnit ? (
								<Select
									options={[
										{ label: 'mg/kg/hour (Standard)', value: 'mg/kg/hr' },
										{ label: 'mcg/kg/min', value: 'mcg/kg/min' },
									]}
									value={doseUnit}
									onChange={(value) => {
										setDoseUnit(oldState => {
											if (dose) {
												if (oldState === 'mcg/kg/min') {
													updateDose(dose / 1000 * 60);
												} else {
													updateDose(dose * 1000 / 60);
												}
											}
											
											return value
										});
									}}
								/>
							) : (
								`${options.medication.numerator_unit}/kg/hour`
							)}
						</Form.Item>
					</Col>
				</Row>
			</>

			<Form.Item
				preserve={false}
				name='fluids_id'
				label='Diluent'
				style={formItemStyle}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 10 }} //Width of INPUT, in "columns" (like <Col span={12})
				rules={criEstimateRules.fluids_id}
				data-cy={'diluentInput'}
			>
				<Select
					onSelect={(value: number) => {
						setFluidsId(value);
						const newLabel = `${options.medication_name} ${fluidVolume ? 'qs ' + fluidVolume + 'mL' : ''
							}  ${fluidOptions.find((fluid) => fluid.id === value)
								?.abbreviation ??
							fluidOptions.find((fluid) => fluid.id === value)
								?.name
							}`;

						updateBagLabel(newLabel);
						onFormChange({})
					}}
				>
					{fluidOptions?.filter(option => !option.name.includes('Oxygen')).map((fluidOption) => (
						<Option
							className='rdvm-select'
							value={fluidOption.id}
							key={`complaint_${fluidOption.id}`}
						>
							{fluidOption.name}
						</Option>
					))}
				</Select>
			</Form.Item>

			<>
				<Row
					justify='start'
					align='middle'
					style={{ width: '100%' }}
					gutter={[8, 8]}
				>
					<Col span={6}>Diluent volume:</Col>
					<Col>
						<Form.Item
							preserve={false}
							name='fluids_volume_ml'
							style={formItemStyle}
							wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
							rules={criEstimateRules.fluids_volume_ml}
							data-cy={'diluentVolumeInput'}
						>
							<InputNumber
								style={formItemStyle}
								onChange={(value) => {
									const zeroedValue = value || 0;
									setFluidVolume(zeroedValue);

									const newLabel = `${options.medication_name
										} ${value ? 'qs ' + value + 'mL' : ''}  ${fluidOptions.find(
											(fluid) => fluid.id === fluidsId,
										)?.abbreviation ??
										fluidOptions.find(
											(fluid) => fluid.id === fluidsId,
										)?.name
										}`;

									updateBagLabel(newLabel);
								}}
								disabled={
									!fluidsId ||
									fluidsId === BLANK_FLUID_OPTION.id
								}
								min={0}
							/>
						</Form.Item>
					</Col>
					<Col>mL / bag</Col>
				</Row>
			</>

			<>
				<Row
					justify='start'
					align='middle'
					style={{ width: '100%' }}
					gutter={[8, 8]}
				>
					<Col span={6}>Fluid rate:</Col>
					<Col>
						<Form.Item
							preserve={false}
							name='rate_ml_per_hr'
							style={formItemStyle}
							wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
							rules={criEstimateRules.rate_ml_per_hr}
							data-cy={'fluidRateInput'}
						>
							<InputNumber
								style={formItemStyle}
								onChange={(value) => {
									const zeroedValue = value || 0;
									setFluidRate(zeroedValue);
								}}
								min={0}
								disabled={
									!fluidsId ||
									fluidsId === BLANK_FLUID_OPTION.id
								}
							/>
						</Form.Item>
					</Col>
					<Col>mL/hour</Col>
				</Row>
			</>

			<Form.Item
				preserve={false}
				name='label'
				label='Label'
				style={formItemStyle}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={12})
				rules={criEstimateRules.label}
			>
				<Input
					onChange={(e) => {
						updateBagLabel(e.target.value);
					}}
				/>
			</Form.Item>

			<HiddenInput fieldName='is_prn' />
			<HiddenInput fieldName='prn_condition' />
			<HiddenInput fieldName='medication_id' />
			<HiddenInput fieldName='type_id' />
			<HiddenInput fieldName='unit_cost_cents' />
			<HiddenInput fieldName='is_prn' />
			<HiddenInput fieldName='unit' />
			<HiddenInput fieldName='is_free' />
			<HiddenInput fieldName='reason' />
			<HiddenInput fieldName='ordered_by' />
			<HiddenInput fieldName='notes' />
			<HiddenInput fieldName='name' />
			<HiddenInput fieldName='latest_patient_weight_kg' />
			<HiddenInput fieldName='supplemental_cost_cents' />
			<HiddenInput fieldName='is_recurring_supplemental' />
			<HiddenInput fieldName='priority' />
            <HiddenInput fieldName="controlled_drug" initialValue={options.controlled_drug} />

			<Divider className='cri-form-divider' />
			<h3>Review:</h3>
			<CriOrderReview
				numerator_unit={options.medication.numerator_unit}
				numerator_value={options.medication.numerator_value}
				denominator_unit={options.medication.denominator_unit}
				fluidRate={fluidRate}
				dose_unit={doseUnit}
				dose={getReviewDose()}
				patientWeight={patientWeight ?? approxPatientWeight}
				startTime={getFormData().start_time}
				endTime={getFormData().end_time}
				fluidVolume={fluidVolume}
				fluidsId={fluidsId}
			/>
			<Form.Item
				preserve={false}
				hidden
				name={
					patientWeight
						? 'latest_patient_weight_kg'
						: 'approx_patient_weight_kg'
				}
				style={formItemStyle}
				labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<InputNumber min={0} disabled />
			</Form.Item>

			<Form.Item
				preserve={false}
				hidden
				name='id'
				label='id'
				style={formItemStyle}
				labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input disabled />
			</Form.Item>
			<Form.Item
				preserve={false}
				hidden
				name='medication_id'
				label='medication_id'
				style={formItemStyle}
				labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input disabled />
			</Form.Item>
			{/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
		</>
	);
};
