import { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

export const useDebounce = (callback: () => void, values: any[], wait: number) => {
    const ref = useRef<() => void>();

    useEffect(() => {
        ref.current = callback;
    }, [...values]);

    const debouncedCallback = useMemo(
        () =>
            _.debounce(() => {
                ref.current?.();
            }, wait),
        [],
    );

    return debouncedCallback;
};
