import React, { CSSProperties } from 'react';
import { formatCurrency } from 'utils/formatFuncs';

interface PriceDisplayProps {
    price: number;
    isFreeOrRefund: boolean;
    negative?: boolean;

}

const PriceDisplay = React.memo(({price, isFreeOrRefund, negative = false}: PriceDisplayProps) => {
    const priceToDisplay = isFreeOrRefund ? 0 : price
	return (
		<span>
            <span>{negative ? `-${formatCurrency(priceToDisplay)}` : formatCurrency(priceToDisplay)}</span>
		</span>
	);
});

export default PriceDisplay;
