import { Col, Form, Input, Row } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { ExistingDiagInstruction, PimsUser } from '../../utils/dataTypes';
import { initializeForm } from '../../hooks/useOnFormInitialize';
import { DiagnosticSearchOption } from '../../utils/types/InstructionOrderTypes';
import {
	MedicationEnd,
	MedicationFrequency,
	MedicationNote,
	MedicationStart,
} from './MedicineOrder';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import moment from 'moment';
import { BASE_QUERY_OPTIONS, INSTRUCTION_FREQUENCY_REGEX } from 'utils/constants';
import { HiddenInput } from './fields/HiddenInput';
import { useGetUserDataQuery } from 'services/authService';
import { DiagnosticOrderRules } from 'utils/types/validations';
import { endTimeGreaterThanStartTime } from 'utils/validationFuncs';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice/index';
import PackageItemTitleContainer from './PackageFormModal/PackageItemTitleContainer';
import { getDefaultStartTime } from 'utils/miscFuncs';

const formItemStyle: CSSProperties = {
	width: '100%',
};

const getDefaults = (
	options: DiagnosticSearchOption,
	existingInstruction?: Partial<ExistingDiagInstruction>,
	user?: PimsUser,
): Partial<ExistingDiagInstruction> => {
	if (existingInstruction) {
		return {
			...existingInstruction,
			ordered_by: user?.user_id || 0,
		};
	} else {
        const isIdexx = options.widget === 'webpacs' || options.widget === 'idexx';

        const frequency = isIdexx ? 'once' : options?.default_frequency ?? undefined
		const additionalTime = options.default_duration_mins;
		const startTime = getDefaultStartTime().unix();
		const endTime = additionalTime
			? moment.unix(startTime).add(additionalTime, 'minutes').unix()
			: undefined;
		const defaultInstruction: Partial<ExistingDiagInstruction> = {
			diagnostic_id: options.id,
			type_id: options.type_id,
			name: options.name,
			frequency,
			start_time: startTime,
			end_time: endTime,
			ordered_by: user?.user_id || 0,
		};
		return defaultInstruction;
	}
};




export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';
interface DiagnosticOrderProps {
	options: DiagnosticSearchOption;
	existingInstruction?: Partial<ExistingDiagInstruction>;
	onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    getFormData?: Function; //not actually optional -- always sent via FormWrapper
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	fromEstimate?: boolean;
}
export const DiagnosticOrder = (props: DiagnosticOrderProps) => {
	const { options, existingInstruction, fromEstimate } = props;
	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const labelCol = props.labelCol || { span: 6 };
	const wrapperCol = props.wrapperCol || { span: 18 };
    const [isSerialCheaper, setIsSerialCheaper] = useState(false)

	const onFormChange = (changes: any) => {     
        let serial = false   
        if (props?.getFormData){
            const fields = {
                ...props?.getFormData(),
                ...changes
            };
            serial = checkIfSerialCheaper(options, fields.frequency, fields?.start_time?.unix(), fields?.end_time?.unix())
            setIsSerialCheaper(serial)
        }
        if (props?.onFormChange){ 
            return props?.onFormChange({...changes, serial})
        } else {
            return (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));
        }
    }

	useEffect(() => {
		const defaultDiagnostic = getDefaults(options, existingInstruction, loggedInUserData);
		initializeForm(defaultDiagnostic, onFormChange);
	}, [options, existingInstruction]);
	const diagnosticOrderRules: DiagnosticOrderRules = {
		frequency: [{ required: true, pattern: INSTRUCTION_FREQUENCY_REGEX, }],
		start_time: [{ required: true, type: "date" }],
		end_time: [
			{ type: "date" },
			endTimeGreaterThanStartTime
		],
		notes: [],
	}
    const isIdexx = options.widget === 'webpacs' || options.widget === 'idexx' || options.category === 'IDEXX_REF_LAB';

	return (
		<>
            {isSerialCheaper && <AlertSerialPrice serialHours={options.serial_hours ?? 0} inForm />}

			<Row align='middle' style={{ width: '100%', marginTop: 'var(--spacing-md)' }}>
				<Col offset={6}>
					{existingInstruction?.is_free && <PackageItemTitleContainer no_charge={existingInstruction?.is_free} checked />}
				</Col>
			</Row>

			<MedicationFrequency
                autoFocus
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				disabled={isIdexx}
				rules={diagnosticOrderRules.frequency}
                onFormChange={onFormChange}
			/>
			<MedicationStart
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				onFormChange={onFormChange} // Only needed because we default a value for this field
				rules={diagnosticOrderRules.start_time}
			/>
			<MedicationEnd
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				onFormChange={onFormChange} // Only needed because we default a value for this field
				rules={diagnosticOrderRules.end_time}
				disabled={existingInstruction?.category === 'IDEXX_REF_LAB'}
			/>
			<MedicationNote
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
				rules={diagnosticOrderRules.notes}
			/>
			<Form.Item
				preserve={false}
				hidden
				name='diagnostic_id'
				label='diagnostic_id'
				style={formItemStyle}
				labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input disabled />
			</Form.Item>
			<HiddenInput
				fieldName={'ordered_by'}
			></HiddenInput>
			<HiddenInput
				fieldName={'id'}
			></HiddenInput>
			<HiddenInput
				fieldName={'priority'}
			></HiddenInput>
			<HiddenInput fieldName="userId" initialValue={loggedInUserData?.user_id} />
			<HiddenInput fieldName="fromEstimate" initialValue={fromEstimate} />
            <HiddenInput fieldName="serial" />
			<HiddenInput fieldName="is_free" initialValue={existingInstruction?.is_free} />
			{/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
		</>
	);
};
