import { useEffect, useState } from 'react';
import { Col, Row, Button, Input, Form, Checkbox } from 'antd';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './checkout.css';
import image from 'assets/img/logo.png';
import { CheckoutForm } from "./CheckoutForm";
import { formatCurrency } from 'utils/formatFuncs';
import { WEB_CHECKOUT_STEP } from 'utils/types/paymentTypes';
import { useAddWebCheckoutIntentMutation, usePutWebCheckoutMutation } from 'services/paymentService';
import { SUPPORTED_PAYMENT_AMOUNT_REGEX } from 'utils/constants';

const Checkout = () => {
	const { urlFormId } = useParams<{ urlFormId: string }>();
	const [clientSecret, setClientSecret] = useState<undefined | string>(undefined);
	const [step, setStep] = useState<WEB_CHECKOUT_STEP>("init");
	const [donation, setDonation] = useState<undefined | number>(undefined);
	const [amount, setAmount] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [getWebCheckout, { data: webCheckout, isSuccess: isSuccessWebCheckout }] = usePutWebCheckoutMutation();
	const [addWebCheckoutIntent, { data: webCheckoutIntentData, isSuccess: isSuccessWebCheckoutIntent }] = useAddWebCheckoutIntentMutation();
	const [stripePublicKey, setStripePublicKey] = useState<string | undefined>();
	const stripePromise = stripePublicKey ? loadStripe(stripePublicKey) : null;

	const getShowOptInMarketingEmail = () => {
		if (!webCheckout?.owner_id || webCheckout?.has_approved_cremation_or_euthanasia) {
            return false;
        }
        return !webCheckout?.pet_deceased_at && webCheckout?.opt_in_marketing_email === null;
    };
	const showOptInMarketingEmail = getShowOptInMarketingEmail();
	const [checkedOptInMarketingEmail, setCheckedOptInMarketingEmail] = useState<boolean | null>(null);
	
	useEffect(() => {
		getWebCheckout({urlFormId: urlFormId});
	}, []);

	useEffect(() => {
		if (webCheckout) {
			if (webCheckout.action === "approved") {
				setAmount(webCheckout.amount_cents);
				setDonation(Math.round(webCheckout.donation_cents * 100) / 10000);
				setStep("completed");
			}
			else {
				setAmount(webCheckout.amount_cents);
				setCheckedOptInMarketingEmail(showOptInMarketingEmail ? true : null);
				setStep("donation");
			}
		}
	}, [isSuccessWebCheckout]);

	useEffect(() => {
		if (webCheckoutIntentData) {
			setClientSecret(webCheckoutIntentData.client_secret);
			setStripePublicKey(webCheckoutIntentData.stripe_public_key);
			setTotalAmount(webCheckoutIntentData.amount_cents);
			setStep("payment");
		}
	}, [isSuccessWebCheckoutIntent]);

	useEffect(() => {
		setTotalAmount(amount + (donation ? donation * 100 : 0));
	}, [amount, donation]);

	const onContinue = async () => {
		setIsLoading(true);
		addWebCheckoutIntent({
			urlFormId: urlFormId,
			body: {
				donation_cents: donation ? Math.round(donation * 100) : 0,
				checked_opt_in_marketing_email: checkedOptInMarketingEmail,
			}
		});
	};

	const onStripeReady = () => {
		setIsLoading(false);
	}

	const onBack = () => {
		setClientSecret(undefined);
	}

	if (step === "init") {
		return <></>
	}

	return (
		<div className='web-checkout-container'>
			<Row className="web-checkout-container-inner">
				<Col span={24}>
					<img src={image} width="120" />
					<h2 style={{margin: "1em 0 0.5em"}}>
						{webCheckout?.owner_first_name} {webCheckout?.owner_last_name}
					</h2>
					<h2 style={{margin: 0}}>
						{formatCurrency(totalAmount)}
					</h2>
					<p className="payment-info">
						Payment for invoice #{webCheckout?.visit_id} {donation ? `and ${formatCurrency(donation * 100)} donation` : ""}
					</p>
					{step === "completed" &&
							<div style={{fontSize: "1em", color: "green", marginTop: "1em"}}>Payment completed</div>
					}
					{(step !== "completed" && (step === "donation" || step === "payment")) &&
						
						<Form 
							onValuesChange={({donation}, allValues) => {
								setDonation(donation.replaceAll(",", ""));
							}}
							onFinish={onContinue}
						>
							<Row className="donation">
								<Col span={24}>
									<Row>
										<Col xs={24} md={18}>
											<h3 style={{fontSize: "16px"}}>Donate to VEG Cares</h3>
										</Col>
										<Col xs={10} md={6}>
											<Form.Item
												name="donation"
												style={{paddingTop: 0}}
												initialValue={donation}
												rules={[
													() => ({
														validator(_, value) {
														if (!value || value.match(SUPPORTED_PAYMENT_AMOUNT_REGEX)) {
															return Promise.resolve();
														}
														return Promise.reject(new Error('Invalid value'));													
														}
													})
												]}
											>
												<Input addonBefore="$" />
											</Form.Item>
										</Col>
									</Row>
								</Col>

								<Col xs={24} md={16} className="donation-info">
									VEG collects donations for under-served pets in our community. Every penny goes directly to pets in need.
									<br/>
									<a href="https://veterinaryemergencygroup.com/veg-cares/" target="_blank">Learn more</a>
								</Col>
							</Row>
							
							{showOptInMarketingEmail &&
								<Checkbox
									checked={checkedOptInMarketingEmail ?? true}
									onChange={() => setCheckedOptInMarketingEmail((isChecked) => !isChecked)}
									style={{ fontSize: '12px', marginTop: '10px' }}
								>
									Please keep me updated on VEG and help keep my pet safe from emergencies.
								</Checkbox>
							}
							
							<Button 
								type="primary"
								htmlType="submit"
								size={"large"}
								style={{display: "flex", marginTop: "1em", marginLeft: "auto"}}
								disabled={isLoading}
								>
									Continue
							</Button>
						</Form>
					}
					{stripePromise && clientSecret &&
						<>	
							<Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
								<CheckoutForm
									amount={totalAmount}
									step={step} setStep={setStep}	
									onStripeReady={onStripeReady}
									onBack={onBack}
									
								/>
							</Elements>
						</>
					}
				</Col>
			</Row>
		</div>
	)
};

export default Checkout;
