import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	Modal,
	Row,
	Select,
	Space,
	Table,
	Typography,
} from 'antd';
import { AR_STATUS_FILTERS, AR_STATUS_OPTIONS, BASE_QUERY_OPTIONS, COOKIE_DURATION, SUPPORTED_PAYMENT_AMOUNT_REGEX } from 'utils/constants';
import {
	useArTransitionStatusMutation,
	useGetAccountsReceivableVisitListQuery,
} from 'services/visitService';
import type { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import styles from './visits_table.module.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ExclamationCircleOutlined, FilterFilled } from '@ant-design/icons';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table/Table';
import { formatCurrency, shortPetId } from 'utils/formatFuncs';
import { useHistory } from 'react-router-dom';
import { AccountsReceivableVisit, VISIT_AR_STATUSES } from 'utils/dataTypes';
import { FormWrapper } from 'components/forms/FormWrapper';
import { checkCookieExists } from 'utils/miscFuncs';
import { AR_STATUS_MAP } from 'utils/stringFormatting';

const { RangePicker } = DatePicker;

const AccountsReceivableReport = () => {
    const searchInput = useRef<any>(null);
	const history = useHistory();
	const [arTransitionStatus] = useArTransitionStatusMutation();
    const [dateRange, setDateRange] = useState<moment.Moment[]>([]);
	const [formInvoiceTotal] = Form.useForm();
	const [formAmountDue] = Form.useForm();
	const [showModal, setShowModal] = useState(false);
	const [doNotShowChecked, setDoNotShowChecked] = useState(false);
	const [arTransitionStatusData, setArTransitionStatusData] = useState<{status: VISIT_AR_STATUSES, visitId: number} | undefined>();
    const { data: visitsList } = useGetAccountsReceivableVisitListQuery(null, {
		...BASE_QUERY_OPTIONS,
		refetchOnMountOrArgChange: true
	});
	const [currentDataSourceLength, setCurrentDataSourceLength] = useState(0);
	const statusTransitionOptions = AR_STATUS_OPTIONS.map((status) => (
		<Select.Option
			value={status}
			key={`ar_status_transition_${status}`}
		>
			{AR_STATUS_MAP[status]}
		</Select.Option>
	));

	useEffect(() => {
        setCurrentDataSourceLength(visitsList?.length || 0);
    }, [visitsList]);

    const handleSearch = (
		confirm: (param?: FilterConfirmProps) => void
	) => {
		confirm();
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
	};

	const handleResetDate = (clearFilters: () => void) => {
		clearFilters();
		setDateRange([])
	};

	const disabledDate: RangePickerProps['disabledDate'] = current => {
		// Can not select days before today and today
		return current && current > moment().endOf('day');
	};

	const getColumnSearchProps = (dataIndex: keyof AccountsReceivableVisit): ColumnType<AccountsReceivableVisit> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
		  <div style={{ padding: 8 }}>
			<Input
			  ref={searchInput}
			  placeholder={'Search in filters'}
			  value={selectedKeys[0]}
			  onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			  onPressEnter={() => handleSearch(confirm)}
			  style={{ marginBottom: 8, display: 'block' }}
			/>
			<Space>
				<Button
				onClick={() => clearFilters && handleReset(clearFilters)}
				type="link"
				size="small"
				color='grey'
				style={{ width: 90 }}
			  >
				Reset
			  </Button>
			  <Button
				type="primary"
				onClick={() => handleSearch(confirm)}
				size="small"
				style={{ width: 90 }}
			  >
				Apply
			  </Button>
			</Space>
		  </div>
		),
		filterIcon: (filtered: boolean) => (
		  <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value: any , record: AccountsReceivableVisit) =>
		  record[dataIndex]
			.toString()
			.toLowerCase()
			.includes((value as string).toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
		  if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		  }
		},
		render: text => text
	  });

	const getColumnSearchDateRangeProps = (dataIndex: keyof AccountsReceivableVisit): ColumnType<AccountsReceivableVisit> => ({
		filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<RangePicker
					// Todo: Check this if upgrading antd versions 
					// the type checker is wrong about the type for this field [moment.Moment] is a valid value
					// @ts-ignore: Type 'Moment[]' is not assignable to type '[EventValue<Moment>, EventValue<Moment>]'
					value={dateRange}
					disabledDate={disabledDate}
					onChange={(dates) => {
						// We need setDateRange in order to have correct filters 
						// We need setSelectedKeys in order to trigger the filters 
						const keys = dates?.map(range => {
							return range?.utc() ?? moment();
						})
						const filterKeys = dates?.map(range => {
							return range?.unix() ?? 0;
						})
						if (keys?.every(Boolean)) {
							setDateRange(keys)
						} else {
							setDateRange([])
						}
						setSelectedKeys(filterKeys ?? []);
					}}
				/>
				<Space>
					<Button
						onClick={() => clearFilters && handleResetDate(clearFilters)}
						type="link"
						size="small"
						color='grey'
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="primary"
						onClick={() => handleSearch(confirm)}
						size="small"
						style={{ width: 90 }}
					>
						Apply
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (value: any, record: AccountsReceivableVisit) => {
			if (dateRange && dateRange.length > 1) {
				return ((record.created_at - dateRange[0].unix()) * (record.created_at - dateRange[1].unix()) <= 0);
			} else {
				return true
			}
		},
	});

	const getColumnSearchCurrencyRangeProps = (dataKey: keyof AccountsReceivableVisit, form: FormInstance): ColumnType<AccountsReceivableVisit> => ({
		filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8, maxWidth: "300px" }}>
				<FormWrapper
					form={dataKey === "invoice_total" ? formInvoiceTotal : formAmountDue}
					onFinishFailed={() => {}}
					onFinish={(values: any) => {
						let keys = [];
						keys.push(values.min_amount || "-1");
						keys.push(values.max_amount || "-1");
						setSelectedKeys([keys.toString()]);
						handleSearch(confirm);
					}}
					bottomBar={<></>}
				>
					<Form.Item
						name='min_amount'
						style={{width: "50%", marginBottom: "8px", padding: "0 4px 0 8px"}}
						wrapperCol={{ span: 24 }}
						rules={[
							{ pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: "Invalid amount" }
						]}
						validateFirst={true}
					>	
						<Input
							addonBefore={"$"}
							placeholder={'Min'}
							style={{ textAlign: "right"}}
							onPressEnter={() => form.submit()}	
						/>
					</Form.Item>
					<Form.Item
						name='max_amount'
						style={{width: "50%", marginBottom: "8px", padding: "0 8px 0 4px"}}
						wrapperCol={{ span: 24 }}
						rules={[
							{ pattern: SUPPORTED_PAYMENT_AMOUNT_REGEX, message: "Invalid amount" }
						]}
						validateFirst={true}
					>	
						<Input
							addonBefore={"$"}
							placeholder={'Max'}
							style={{ textAlign: "right"}}
							onPressEnter={() => form.submit()}
							
						/>
					</Form.Item>
				</FormWrapper>
				<Space style={{display: "flex", justifyContent: "flex-end"}}>
					<Button
						onClick={() => {
							form.resetFields();
							clearFilters && handleResetDate(clearFilters);
						}}
						type="link"
						size="small"
						color='grey'
						style={{ width: 90, alignSelf: "flex-end" }}
					>
						Reset
					</Button>
					<Button
						type="primary"
						onClick={() => form.submit()}
						size="small"
						style={{ width: 90, alignSelf: "flex-end" }}
					>
						Apply
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
		),
		onFilter: (values: any, record: AccountsReceivableVisit) => {
			const [min, max] = values.split(",");
			const minAmount = min === "-1" ? -1 : Math.round(parseFloat(min) * 100);
			const maxAmount = max === "-1" ? -1 : Math.round(parseFloat(max) * 100);
			return (
				(minAmount === -1 || minAmount <= record[dataKey]) &&
				(maxAmount === -1 || maxAmount >= record[dataKey])
			)
		},
	});

	const columns: ColumnsType<AccountsReceivableVisit> = [
        {
			title: 'Visit Creation Date',
			dataIndex: 'created_at',
			key: 'created_at',
			width: 200,
			render: (date: number) => {
                return(
                    <>
                        {moment.unix(date).format('ll')} <br />
                        <span style={{color: "var(--gray-7)"}}>
							{moment.unix(date).toNow(true)}
						</span>
                    </>
                )
            },
			...getColumnSearchDateRangeProps('created_at'),
			sorter: (a, b) => b.created_at - a.created_at,
			defaultSortOrder: "ascend",
		},
		{
			title: 'Patient',
			dataIndex: 'pet_name',
			key: 'pet_name',
			width: 250,
			...getColumnSearchProps('pet_name'),
            render: (value, record) => {
                return (
					<>
						{value}<br />
						<span style={{color: "var(--gray-7)"}}>
							#{shortPetId(record.pet_id)}
						</span>
					</>
				)
            },
		},
        {
			title: 'Customer Name',
			dataIndex: 'owner_name',
			key: 'owner_name',
			width: 250,
			...getColumnSearchProps('owner_name'),
		},
        {
			title: 'Invoice Total',
			dataIndex: 'invoice_total',
			key: 'invoice_total',
			width: 160,
			...getColumnSearchCurrencyRangeProps('invoice_total', formInvoiceTotal),
			sorter: (a, b) => b.invoice_total - a.invoice_total,
			className: styles.visitsTable_currency,
			render: value => formatCurrency(value),
		},
		{
			title: 'Amt. Due',
			dataIndex: 'amount_due',
			key: 'amount_due',
			width: 140,
			...getColumnSearchCurrencyRangeProps('amount_due', formAmountDue),
			sorter: (a, b) => b.amount_due - a.amount_due,
			className: styles.visitsTable_currency,
			render: value => formatCurrency(value),
		},
		{
			title: 'Status',
			dataIndex: 'ar_status',
			key: 'ar_status',
			width: 100,
			className: styles.visitsTable_status,
			filters: AR_STATUS_FILTERS,
			onFilter: (value: any, record: AccountsReceivableVisit) => record.ar_status.startsWith(value),
			render: (ar_status, record) => {
				return (
					<Select
						className={'visit-transition-select'}
						onChange={(status) => {
							if (status === "uncollectible") {
								if (!checkCookieExists("do_not_show_uncollectible_modal")) {
									setArTransitionStatusData({
										status: status,
										visitId: record.visit_id,
									});
									setShowModal(true);
									return;
								}	
							}
							else {
								setArTransitionStatusData(undefined);
							}
							arTransitionStatus({
								status: status === "awaiting_payment" ? null : status,
								visitId: record.visit_id,
							});
						}}
						value={ar_status}
						style={{width: "100%"}}
					>
						{statusTransitionOptions}
					</Select>
				);
			},

		},
		{
			title: '',
			dataIndex: 'visit_id',
			key: 'visit_id',
			width: 100,
			render: (value, record) => {
				return (
					<Button
						onClick={() => {
							window.open(`/visit/${value}`, "_blank");
						}}
					>
						View Visit
					</Button>
				);
			},
		},
	];

	
	

	return (
		<>
			<Row>
				<Col className={styles.visitsTitle} span={24}>
					Accounts Receivable
				</Col>
				
				<Col span={24}>
					{currentDataSourceLength > 0 && visitsList?.length &&
						<div className={styles.rowCount}>
							Showing: {currentDataSourceLength} out of {visitsList?.length}
						</div>
					}
					<Table
						className={styles.visitsTable}
						rowClassName={styles.row}
						dataSource={visitsList}
						columns={columns}
						scroll={{x: true}}
						rowKey={"visit_id"}
						onChange={(pagination, filters, sorter, extra) =>
							setCurrentDataSourceLength(extra.currentDataSource.length)
						}
					/>
				</Col>
			</Row>
			<Modal
				width='450px'
				open={showModal}
				maskClosable={true}
				onCancel={() => {
					setShowModal(false);
				}}
				onOk={() => {
					setShowModal(false);
				}}
				destroyOnClose={true}
				centered={true}
				footer={(
					<>
						<Button onClick={() => setShowModal(false)}>
							Cancel
						</Button>
						<Button autoFocus type="primary" onClick={() => {
							if (doNotShowChecked) {
								document.cookie = `do_not_show_uncollectible_modal=1;max-age=${COOKIE_DURATION}`;
							}
							setShowModal(false);
							if (arTransitionStatusData) {
								arTransitionStatus({
									status: arTransitionStatusData.status === "awaiting_payment" ?
										null : arTransitionStatusData.status,
									visitId: arTransitionStatusData.visitId,
								});
							}
						}}>
							Mark as Uncollectible
						</Button>
					</>
				)}
			>
				<Row>
					<Col span={1}>
						<ExclamationCircleOutlined
							style={{
								"fontSize": "32px",
								"color": "#FAAD14"
							}}
						/>
					</Col>
					<Col offset={2} span={20}>
						<Typography.Title level={4}>Status: Uncollectible</Typography.Title>
						<Typography.Paragraph>
							Marking a visit as uncollectible will remove the visit from this list.
						</Typography.Paragraph>
						<Typography.Paragraph>
							A payment can still be applied by viewing the visit directly.
						</Typography.Paragraph>
					</Col>
				</Row>
				<Row>
					<Col offset={3} span={20}>
						<Checkbox
							checked={doNotShowChecked}
							onChange={(e) => {
								setDoNotShowChecked(e.target.checked);
							}}
						>
							Do not show me this again
						</Checkbox>
					</Col>
				</Row>
				

			</Modal>
		</>
	);
};

export default AccountsReceivableReport;
