import moment from 'moment';
import { next30Min, setPackageInstructionTimes } from 'utils/formFuncs';
import {
    CallLog,
    CustomerInfo,
    ExistingCriInstruction,
    ExistingDiagInstruction,
    ExistingFluidInstruction,
    ExistingMedInstruction,
    ExistingTaskInstruction,
    ExpandedPatient,
    ProblemList,
    isInstanceOfBasePatient,
    isInstanceOfCallLog,
    isInstanceOfCustomerInfo,
    isInstanceOfExistingCriInstruction,
    isInstanceOfExistingDiagInstruction,
    isInstanceOfExistingFluidInstruction,
    isInstanceOfExistingTaskInstruction,
    isInstanceOfExpandedPatient,
    isInstanceOfMedicineInstructionCorrect,
    isInstanceOfProblemList,
} from '../utils/dataTypes';
import { sortInstructions } from '../utils/sortFuncs';
import {
    DiagnosticSearchOption,
    EnrichedCriSearchOption,
    EnrichedPackageSearchOption,
    FluidSearchOption,
    OutgoingCriOrder,
    OutgoingFluidOrder,
    isInstanceOfDiagnosticOption,
    isInstanceOfEnrichedPackageSearchOption,
    isInstanceOfFluidOption,
    isInstanceOfOutgoingCriOrder,
    isInstanceOfOutgoingFluidOrder,
    isInstanceOfTaskOption,
} from '../utils/types/InstructionOrderTypes';

// We needed to seperate out the logic of the useOnFormInitialize since it was resseting the already entered values on forms
// We should probably rework this is in it entirety
// TODO Look into better way of initializing forms
export const initializeForm = (
    initialFormData:
        | EnrichedPackageSearchOption
        | DiagnosticSearchOption
        | Partial<ExistingMedInstruction>
        | Partial<ExistingDiagInstruction>
        | Partial<ExistingTaskInstruction>
        | EnrichedCriSearchOption
        | Partial<ExistingCriInstruction>
        | OutgoingCriOrder
        | (FluidSearchOption & { start_time: number; end_time: number })
        | (OutgoingFluidOrder & { id: number })
        | Partial<ExistingFluidInstruction>
        | Partial<OutgoingFluidOrder>
        | ProblemList
        | CustomerInfo
        | ExpandedPatient
        | Partial<CallLog>,
    setStateFunction: Function,
) => {
    let initData: any = {};
    if (initialFormData) {
        if (isInstanceOfMedicineInstructionCorrect(initialFormData)) {
            //Existing medicine instruction

            //Initializes form fields with all the data we have
            //Not really sure why, but have to do some moment shenanigans
            //Otherwise you get an error about `date.clone is not a function`
            initData = {
                ...initialFormData,
                medication_name: initialFormData.name,
                start_time: initialFormData.start_time ? moment.unix(initialFormData.start_time) : undefined,
                end_time: initialFormData.end_time ? moment.unix(initialFormData.end_time) : undefined,
            };
        } else if (isInstanceOfExistingCriInstruction(initialFormData) || isInstanceOfOutgoingCriOrder(initialFormData)) {
            //New CRI Order

            initData = {
                ...initialFormData,
                start_time: initialFormData.start_time ? moment.unix(initialFormData.start_time) : undefined,
                end_time: initialFormData.end_time ? moment.unix(initialFormData.end_time) : undefined,
            };
        } else if (
            isInstanceOfExistingFluidInstruction(initialFormData) ||
            isInstanceOfOutgoingFluidOrder(initialFormData) ||
            isInstanceOfFluidOption(initialFormData)
        ) {
            //New Fluid Order
            let fluidId;
            if (isInstanceOfExistingFluidInstruction(initialFormData) || isInstanceOfOutgoingFluidOrder(initialFormData)) {
                fluidId = initialFormData.fluids_id;
            } else {
                fluidId = initialFormData.id;
            }
            initData = {
                ...initialFormData,
                start_time: initialFormData.start_time ? moment.unix(initialFormData.start_time) : undefined,
                end_time: initialFormData.end_time ? moment.unix(initialFormData.end_time) : undefined,
                fluids_id: fluidId,
            };
        } else if (isInstanceOfEnrichedPackageSearchOption(initialFormData)) {
            //New Package Instruction
            initData = [
                ...initialFormData.instructions.map((instruction) => {
                    const { start_time, end_time } = setPackageInstructionTimes({
                        duration_mins: instruction.duration_mins,
                        start_delay_mins: instruction.start_delay_mins,
                        baseTime: next30Min(moment()),
                    });
                    return {
                        ...instruction,
                        start_time: start_time,
                        end_time: end_time,
                    };
                }),
            ].sort(sortInstructions);
        } else if (isInstanceOfProblemList(initialFormData)) {
            //Add complaint
            initData = {
                ...initialFormData,
                start_date: initialFormData.start_date ? moment(initialFormData.start_date) : undefined,
                end_date: initialFormData.end_date ? moment(initialFormData.end_date) : undefined,
                differentials: initialFormData.differentials?.map((differential) => differential.differential_id),
            };
        } else if (isInstanceOfExistingDiagInstruction(initialFormData)) {
            //Existing Diagnostic Instruction
            initData = {
                ...initialFormData,
                diagnostic_id: initialFormData.diagnostic_id,
                start_time: initialFormData.start_time ? moment.unix(initialFormData.start_time) : undefined,
                end_time: initialFormData.end_time ? moment.unix(initialFormData.end_time) : undefined,
            };
        } else if (isInstanceOfDiagnosticOption(initialFormData)) {
            //Diagnostic search option
            initData = { diagnostic_id: initialFormData.id };
        } else if (isInstanceOfExistingTaskInstruction(initialFormData)) {
            //Existing Task Instruction
            initData = {
                ...initialFormData,
                task_id: initialFormData.task_id,
                start_time: initialFormData.start_time ? moment.unix(initialFormData.start_time) : undefined,
                end_time: initialFormData.end_time ? moment.unix(initialFormData.end_time) : undefined,
            };
        } else if (isInstanceOfTaskOption(initialFormData)) {
            //Task search option
            initData = { task_id: initialFormData.id };
        } else if (isInstanceOfExpandedPatient(initialFormData)) {
            initData = {
                ...initialFormData,
                birthday: initialFormData.birthday ? moment(initialFormData.birthday) : undefined,
            };
        } else if (isInstanceOfBasePatient(initialFormData)) {
            initData = initialFormData;
        } else if (isInstanceOfCustomerInfo(initialFormData)) {
            //Edit customer info
            initData = {
                ...initialFormData,
                birthday: initialFormData.birthday ? moment(initialFormData.birthday) : undefined,
            };
        } else if (isInstanceOfCallLog(initialFormData)) {
            initData = initialFormData;
        }

        //If we try to setState here, and initData is blank, a bunch of forms get REALLY mad
        if (Object.keys(initData).length) {
            setTimeout(() => {
                setStateFunction(initData);
            }, 100);
        }
    }
    return null;
};
