import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PriceDisplay from 'components/lib/PriceDisplay';
import moment from 'moment';
import { displayUser, formatCurrency, roundTo } from 'utils/formatFuncs';
import { calculateTotalCost } from 'utils/miscFuncs';
import {
    BillingDisplayType,
    BillingItem,
    GroupedBillingItem,
    MultiGroupedBillingItem,
    BillingItemDisplay,
    BillingTableEarnedRevenueItem,
    BillingTableEarnedRevenueItemGroup,
    BillingTableRow,
    EARNABLE_TYPES,
    isInstanceOfBillingTableEarnedRevenueItemGroup,
    isInstanceOfBillingTableParentRow,
    EarnedRevenueLineItem,
    GroupedMedicationIds,
    EarnedRevenueLineItemDisplay,
} from 'utils/types/billingTypes';
import './billing_panel.css';
import { getBillingItemsDisplay } from './getBillingItemsDisplay';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/stringFormatting';

const OnSelect = (item: BillingTableEarnedRevenueItem, selected: number[], setSelected: React.Dispatch<React.SetStateAction<number[]>>) => {
    let newSelectedList = [];
    if (selected.includes(item.id)) {
        if (Object.keys(groupedMedicationIds).includes(String(item.id))) {
            newSelectedList = selected.filter((selectedId) => !groupedMedicationIds[String(item.id)].includes(selectedId));
        } else {
            newSelectedList = selected.filter((selectedId) => selectedId !== item.id);
        }
        setSelected(newSelectedList);
    } else {
        if (Object.keys(groupedMedicationIds).includes(String(item.id))) {
            newSelectedList = [...selected, ...groupedMedicationIds[String(item.id)]];
        } else {
            newSelectedList = [...selected, item.id];
        }
        
        setSelected(newSelectedList);
    }
};

const OnSelectGroup = (item: BillingTableEarnedRevenueItemGroup, selected: number[], setSelected: React.Dispatch<React.SetStateAction<number[]>>) => {
    const groupSelected = isGroupSelected(item, selected);
    let newSelectedList: number[] = [];
    if (groupSelected) {
        newSelectedList = [...selected]
        item.ids.forEach(id => {
            if (Object.keys(groupedMedicationIds).includes(String(id))) {
                newSelectedList = newSelectedList.filter((selectedId) => !groupedMedicationIds[String(id)].includes(selectedId));
            } else {
                newSelectedList = newSelectedList.filter((selectedId) => selectedId !== id);
            }
        });
    } else {
        newSelectedList = [...selected];
        item.ids.forEach(id => {
            if (Object.keys(groupedMedicationIds).includes(String(id))) {
                newSelectedList = newSelectedList.concat(groupedMedicationIds[String(id)]);
            } else {
                newSelectedList.push(id);
            }
        });
    }
    setSelected(newSelectedList);
};
const OnSelectMany = (row: BillingTableRow, selected: number[], setSelected: React.Dispatch<React.SetStateAction<number[]>>) => {
    const allSelected = isSelectedAll(row, selected);
    const newSelected: number[] = row.children.reduce((selectedItems: number[], item: BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup) => {
        if (allSelected) {
            if (isInstanceOfBillingTableEarnedRevenueItemGroup(item)){
                let newSelectedList = [...selectedItems]
                item.ids.forEach(id => {
                    if (Object.keys(groupedMedicationIds).includes(String(id))) {
                        newSelectedList = newSelectedList.filter((selectedId) => !groupedMedicationIds[String(id)].includes(selectedId));
                    } else {
                        newSelectedList = newSelectedList.filter((selectedId) => selectedId !== id);
                    }
                });
                return newSelectedList
            } else {
                let newSelectedList = [...selectedItems]
                if (Object.keys(groupedMedicationIds).includes(String(item.id))) {
                    newSelectedList = newSelectedList.filter((selectedId) => !groupedMedicationIds[String(item.id)].includes(selectedId));
                } else {
                    newSelectedList = newSelectedList.filter((selectedId) => selectedId !== item.id);
                }
                return newSelectedList
            }
        } else {
            if (isInstanceOfBillingTableEarnedRevenueItemGroup(item)) {
                let newSelectedList = [...selectedItems];
                item.ids.forEach(id => {
                    if (Object.keys(groupedMedicationIds).includes(String(id))) {
                        newSelectedList = newSelectedList.concat(groupedMedicationIds[String(id)]);
                    } else {
                        newSelectedList.push(id);
                    }
                });
                return newSelectedList
            } else {
                let newSelectedList = [...selectedItems];
                if (Object.keys(groupedMedicationIds).includes(String(item.id))) {
                    newSelectedList = newSelectedList.concat(groupedMedicationIds[String(item.id)]);
                } else {
                    newSelectedList.push(item.id);
                }
                return newSelectedList
            }
        }
    }, selected as number[]);
    setSelected(newSelected);
};

const isSelected = (id: number, selected: number[]) => selected.some((selectedId) => selectedId === id);
const isGroupSelected = (row: BillingTableEarnedRevenueItemGroup, selected: number[]) => row.ids.every((id) => isSelected(id, selected));
const isSelectedAll = (row: BillingTableRow, selected: number[]) => row.children.every((item) => {
    if (isInstanceOfBillingTableEarnedRevenueItemGroup(item)) {
        return isGroupSelected(item, selected);
    }else {
        return isSelected(item.id, selected)
    }
    
});

const reduceEarnedRevenueItems = (
    earnedRevenueItems: (BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup)[],
    earnedRevenueItem: BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup,
) => {
    if (earnedRevenueItem.serial && earnedRevenueItem.serial_hours) {
        const isAlreadyOnListIndex = earnedRevenueItems.findIndex((item) => item.created_at === earnedRevenueItem.created_at);
        if (isAlreadyOnListIndex !== -1) {
            const earnedRevenueItemGroupExisting = earnedRevenueItems[isAlreadyOnListIndex];
            const quantity = earnedRevenueItemGroupExisting.quantity + earnedRevenueItem.quantity;
            if (isInstanceOfBillingTableEarnedRevenueItemGroup(earnedRevenueItemGroupExisting)) {
                earnedRevenueItemGroupExisting.ids.push(earnedRevenueItem.id);
                earnedRevenueItemGroupExisting.quantity = quantity;
                earnedRevenueItemGroupExisting.displayQuantity = `${roundTo(quantity / earnedRevenueItem.serial_hours, 0)}`;
            } else {
                const newEarnedRevenueItemGroupExisting: BillingTableEarnedRevenueItemGroup = {
                    ...earnedRevenueItemGroupExisting,
                    quantity,
                    displayQuantity: `${roundTo(quantity / earnedRevenueItem.serial_hours, 0)} ${earnedRevenueItemGroupExisting.displayUnit}`,
                    ids: [earnedRevenueItemGroupExisting.id, earnedRevenueItem.id],
                };
                earnedRevenueItems[isAlreadyOnListIndex] = newEarnedRevenueItemGroupExisting;
            }
        } else {
            earnedRevenueItems.push(earnedRevenueItem);
        }
    } else {
        earnedRevenueItems.push(earnedRevenueItem);
    }
    return earnedRevenueItems;
};

const getDisplayUnit = (unit: string) => {
    if (unit !== "unit") {
        return unit
    } else {
        return ""
    }
}

const getSupplementsCost = (earnedRevenueItem: EarnedRevenueLineItemDisplay, earnedRevenueLineItems: EarnedRevenueLineItemDisplay[]) => {
    const orderedEarnedRevenueLineItems = earnedRevenueLineItems.filter((erli) => erli.instruction_id === earnedRevenueItem.instruction_id).sort(function(a,b) {return a.id - b.id});
    const startPosition = orderedEarnedRevenueLineItems.indexOf(earnedRevenueItem);
    const endPosition = orderedEarnedRevenueLineItems.findIndex((erli) => erli.is_supplement === false && erli.id !== earnedRevenueItem.id && erli.created_at >= earnedRevenueItem.created_at)
    const supplements = endPosition <= 0 ? orderedEarnedRevenueLineItems.slice(startPosition + 1) : orderedEarnedRevenueLineItems.slice(startPosition + 1, endPosition)
    if (supplements) {
        const combinedSupplementsCost = supplements.reduce(
            (accumulator, currentValue) => accumulator + (currentValue.price_cents * currentValue.quantity),0,
        );
        return combinedSupplementsCost
    } else {
        return null
    }
}

const getCost = (earnedRevenueItem: EarnedRevenueLineItemDisplay, billingItemDisplay: BillingItemDisplay) => {
    if ((earnedRevenueItem.type_id === "M" || earnedRevenueItem.type_id === "TGH") && !earnedRevenueItem.is_supplement) {
        const combinedSupplementsCost = getSupplementsCost(earnedRevenueItem, billingItemDisplay.earned_revenue_line_items)
        if (combinedSupplementsCost) {
            return (earnedRevenueItem.price_cents * earnedRevenueItem.quantity + combinedSupplementsCost)
        }
    }
    return earnedRevenueItem.price_cents    
}

const getTotalCost = (earnedRevenueItem: EarnedRevenueLineItemDisplay, billingItemDisplay: BillingItemDisplay) => {
    if ((earnedRevenueItem.type_id === "M" || earnedRevenueItem.type_id === "TGH") && !earnedRevenueItem.is_supplement) {
        const combinedSupplementsCost = getSupplementsCost(earnedRevenueItem, billingItemDisplay.earned_revenue_line_items)
        if (combinedSupplementsCost) {
            return Math.floor((earnedRevenueItem.price_cents * earnedRevenueItem.quantity + combinedSupplementsCost))
        }
    }
    return Math.floor(earnedRevenueItem.price_cents * earnedRevenueItem.quantity)
}

const getDisplayQuantity = (earnedRevenueItem: EarnedRevenueLineItem) => {
    if (earnedRevenueItem.unit === "minutes") {
        const hours = Math.floor(earnedRevenueItem.quantity/60);
        const minutes = earnedRevenueItem.quantity % 60;
        if (hours !== 0 && minutes !== 0) {
            return `${hours} hr ${minutes} min`
        } else if (hours !== 0 && minutes === 0) {
            return `${hours} hours`
        } else {
            return `${minutes} minutes`
        }
    } else {
        return `${earnedRevenueItem.quantity} ${getDisplayUnit(earnedRevenueItem.additive_name || earnedRevenueItem.unit)}`
    } 
}

const subcategoriesList = [
    "N", 
    "D", 
    "M", 
    "F",
    "T",
    "TGH",
]

export const groupMedicationIds = (billingItem: BillingItem) => {
    if (billingItem.type_id === "M" || billingItem.type_id === "TGH") {
        const matchedBillingItemEarnedRevenueLineItems = [...billingItem.earned_revenue_line_items]
        const matchedEarnedRevenueLineItems = matchedBillingItemEarnedRevenueLineItems.sort(function(a,b) {return a.id - b.id})
        const nonSupplements = matchedEarnedRevenueLineItems.filter(earnedRevenueItem => (!earnedRevenueItem.is_supplement))
        nonSupplements.forEach((nonSupplement) => {
            const startPosition = matchedEarnedRevenueLineItems.indexOf(nonSupplement);
            const endPosition = matchedEarnedRevenueLineItems.findIndex((erli) => erli.is_supplement === false && erli.id !== nonSupplement.id && erli.created_at >= nonSupplement.created_at)
            const supplements = endPosition <= 0 ? matchedEarnedRevenueLineItems.slice(startPosition + 1) : matchedEarnedRevenueLineItems.slice(startPosition + 1, endPosition)
            groupedMedicationIds[nonSupplement.id] = [nonSupplement.id]
            if (supplements) {
                supplements.forEach((supplement) => groupedMedicationIds[nonSupplement.id].push(supplement.id))
            }
        })            
    }
}

export const generateBillingDisplayData = (billingItems: BillingItem[], displayType: BillingDisplayType): BillingTableRow[] => {
    billingItems.forEach((billingItem) => groupMedicationIds(billingItem))
    const billingItemsDisplay = getBillingItemsDisplay(billingItems, displayType);
    if (displayType === 'order') {
        return billingItemsDisplay
            .sort((a, b) => {
                return subcategoriesList.indexOf(a.type_id!) - subcategoriesList.indexOf(b.type_id!)
            })
            .reduce((billingItemsByDoctor: BillingItemDisplay[], billingItemDisplay) => {
                const alreadyAddedIndex = billingItemsByDoctor.findIndex((lineItemsByOrder) => {
                    if (billingItemDisplay.instruction_id) {
                        return lineItemsByOrder.instruction_id === billingItemDisplay.instruction_id;
                    } else if (billingItemDisplay.non_medical_order_id) {
                        return lineItemsByOrder.non_medical_order_id === billingItemDisplay.non_medical_order_id;
                    } else {
                        return false;
                    }
                });
                if (alreadyAddedIndex === -1) {
                    billingItemsByDoctor.push(billingItemDisplay);
                    return billingItemsByDoctor;
                } else {
                    billingItemsByDoctor[alreadyAddedIndex] = {
                        ...billingItemsByDoctor[alreadyAddedIndex],
                        earned_revenue_line_items: [
                            ...billingItemsByDoctor[alreadyAddedIndex].earned_revenue_line_items,
                            ...billingItemDisplay.earned_revenue_line_items,
                        ],
                    };
                    return billingItemsByDoctor;
                }
            }, [])
            .map((billingItemDisplay, index) => {
                const localId: number = billingItemDisplay.instruction_id ?? billingItemDisplay.non_medical_order_id ?? -100;
                return {
                    key: `${localId}-${index}`,
                    id: localId,
                    name: billingItemDisplay.name,
                    instruction_id: billingItemDisplay.instruction_id,
                    type_id: billingItemDisplay.type_id,
                    non_medical_order_id: billingItemDisplay.non_medical_order_id,
                    price: calculateTotalCost(billingItemDisplay),
                    children: billingItemDisplay.earned_revenue_line_items
                    .filter(earnedRevenueItem => ((earnedRevenueItem.type_id !== "M" && earnedRevenueItem.type_id !== "TGH") || (earnedRevenueItem.type_id === "M" || earnedRevenueItem.type_id === "TGH") && !earnedRevenueItem.is_supplement))
                    .map(
                        (earnedRevenueItem): BillingTableEarnedRevenueItem => ({
                            key: earnedRevenueItem.id,
                            id: earnedRevenueItem.id,
                            name: displayUser(earnedRevenueItem),
                            instructionName: earnedRevenueItem.instructionName,
                            end: moment.unix(earnedRevenueItem.created_at).format('MM/DD/YYYY hh:mm a'),
                            quantity: earnedRevenueItem.quantity,
                            unit: billingItemDisplay.unit,
                            unit_price: `${formatCurrency(getCost(earnedRevenueItem, billingItemDisplay))}`,
                            price: getTotalCost(earnedRevenueItem, billingItemDisplay),
                            is_comped: earnedRevenueItem.is_comped,
                            is_supplement: earnedRevenueItem.is_supplement,
                            why: null,
                            why_other: null,
                            reason: null,
                            created_at: earnedRevenueItem.created_at,
                            displayQuantity: getDisplayQuantity(earnedRevenueItem),
                            displayUnit: getDisplayUnit(earnedRevenueItem.additive_name || earnedRevenueItem.unit),
                            serial: billingItemDisplay.serial,
                            serial_hours: billingItemDisplay?.serial_hours,
                        }),
                    )
                    .reduce(reduceEarnedRevenueItems, [])
                    .sort((a, b) => {
                        return a.created_at -  b.created_at;
                    }),
                    why: billingItemDisplay.why,
                    why_other: billingItemDisplay.why_other,
                    reason: billingItemDisplay.reason,
                    pricing_unit: billingItemDisplay.pricing_unit,
                    pricing_unit_size: billingItemDisplay.pricing_unit_size,
                    unit: billingItemDisplay.unit,
                    serial: billingItemDisplay.serial,
                    serial_hours: billingItemDisplay?.serial_hours,
                };
            });
    } else if (displayType === 'doctor') {
        return billingItemsDisplay
            .reduce((billingItemsByDoctor: BillingItemDisplay[], billingItemDisplay) => {
                billingItemDisplay.earned_revenue_line_items = billingItemDisplay.earned_revenue_line_items
                .map((earnedRevenueItem) => {
                    return {
                        ...earnedRevenueItem,
                        serial: billingItemDisplay.serial,
                        serial_hours: billingItemDisplay?.serial_hours,
                    }
                })
                const alreadyAddedIndex = billingItemsByDoctor.findIndex(
                    (lineItemsByOrder) => lineItemsByOrder.doctor_id === billingItemDisplay.doctor_id,
                );
                if (alreadyAddedIndex === -1) {
                    billingItemsByDoctor.push(billingItemDisplay);
                    return billingItemsByDoctor;
                } else {
                    billingItemsByDoctor[alreadyAddedIndex] = {
                        ...billingItemsByDoctor[alreadyAddedIndex],
                        earned_revenue_line_items: [
                            ...billingItemsByDoctor[alreadyAddedIndex].earned_revenue_line_items,
                            ...billingItemDisplay.earned_revenue_line_items,
                        ],
                    };
                    return billingItemsByDoctor;
                }
            }, [])
            .map((billingItemDisplay, index) => {
                const localId: number = billingItemDisplay.instruction_id ?? billingItemDisplay.non_medical_order_id ?? -100;
                return {
                    key: `${localId}-${index}`,
                    id: localId,
                    name: displayUser({
                        first_name: billingItemDisplay.doctor_first_name,
                        last_name: billingItemDisplay.doctor_last_name,
                    }),
                    price: calculateTotalCost(billingItemDisplay),
                    instruction_id: billingItemDisplay.instruction_id,
                    type_id: null,
                    non_medical_order_id: billingItemDisplay.non_medical_order_id,
                    doctor_id: billingItemDisplay.doctor_id,
                    doctor_first_name: billingItemDisplay.doctor_first_name,
                    doctor_last_name: billingItemDisplay.doctor_last_name,
                    children: billingItemDisplay.earned_revenue_line_items
                    .filter(earnedRevenueItem => ((earnedRevenueItem.type_id !== "M" && earnedRevenueItem.type_id !== "TGH") || (earnedRevenueItem.type_id === "M" || earnedRevenueItem.type_id === "TGH") && !earnedRevenueItem.is_supplement))
                    .map(
                        (earnedRevenueItem): BillingTableEarnedRevenueItem => ({
                            key: earnedRevenueItem.id,
                            id: earnedRevenueItem.id,
                            name: `${earnedRevenueItem.instructionName}`,
                            instructionName: earnedRevenueItem.instructionName,
                            end: moment.unix(earnedRevenueItem.created_at).format('MM/DD/YYYY hh:mm a'),
                            quantity: earnedRevenueItem.quantity,
                            unit: billingItemDisplay.unit,
                            unit_price: `${formatCurrency(getCost(earnedRevenueItem, billingItemDisplay))}`,
                            price: getTotalCost(earnedRevenueItem, billingItemDisplay),
                            is_comped: earnedRevenueItem.is_comped,
                            is_supplement: earnedRevenueItem.is_supplement,
                            why: earnedRevenueItem.why,
                            why_other: earnedRevenueItem.why_other,
                            reason: earnedRevenueItem.reason,
                            created_at: earnedRevenueItem.created_at,
                            displayQuantity: getDisplayQuantity(earnedRevenueItem),
                            displayUnit: getDisplayUnit(earnedRevenueItem.additive_name || earnedRevenueItem.unit),
                            serial: earnedRevenueItem.serial,
                            serial_hours: earnedRevenueItem?.serial_hours,
                            type_id: (earnedRevenueItem?.type_id as String === "OT" || earnedRevenueItem?.type_id as String === "C") ? "F" : earnedRevenueItem?.type_id

                        }),
                    )
                    .reduce(reduceEarnedRevenueItems, [])
                    .sort((a, b) => {
                        return a.created_at -  b.created_at;
                    })
                    .sort((a, b) => {
                        return subcategoriesList.indexOf(a.type_id!) - subcategoriesList.indexOf(b.type_id!)
                    }),
                    why: null,
                    why_other: null,
                    reason: null,
                    pricing_unit: billingItemDisplay.pricing_unit,
                    pricing_unit_size: billingItemDisplay.pricing_unit_size,
                    unit: billingItemDisplay.unit,
                    serial: billingItemDisplay.serial,
                    serial_hours: billingItemDisplay?.serial_hours,

                };
            })
    } else if (displayType === "datetime") {
        return billingItemsDisplay
        .sort((a, b) => {
            return a.end_time! -  b.end_time!;
        })
        .reduce((billingItemsByDate: BillingItemDisplay[], billingItemDisplay) => {
            billingItemDisplay.earned_revenue_line_items = billingItemDisplay.earned_revenue_line_items.map((earnedRevenueItem) => {
                return {
                    ...earnedRevenueItem,
                    serial: billingItemDisplay.serial,
                    serial_hours: billingItemDisplay?.serial_hours,
                }
            })
            const alreadyAddedIndex = billingItemsByDate.findIndex(
                (lineItemsByOrder) => lineItemsByOrder.end_time_text === billingItemDisplay.end_time_text,
            );
            if (alreadyAddedIndex === -1) {
                billingItemsByDate.push(billingItemDisplay);
                return billingItemsByDate;
            } else {
                billingItemsByDate[alreadyAddedIndex] = {
                    ...billingItemsByDate[alreadyAddedIndex],
                    earned_revenue_line_items: [
                        ...billingItemsByDate[alreadyAddedIndex].earned_revenue_line_items,
                        ...billingItemDisplay.earned_revenue_line_items,
                    ],
                };
                return billingItemsByDate;
            }
        }, [])                
        .map((billingItemDisplay, index) => {
            const localId: number = billingItemDisplay.instruction_id ?? billingItemDisplay.non_medical_order_id ?? -100;
            return {
                key: `${localId}-${index}`,
                id: localId,
                name: billingItemDisplay.end_time_text!,
                end_time: billingItemDisplay.end_time_text,
                price: calculateTotalCost(billingItemDisplay),
                instruction_id: billingItemDisplay.instruction_id,
                type_id: null,
                non_medical_order_id: billingItemDisplay.non_medical_order_id,
                children: billingItemDisplay.earned_revenue_line_items
                .filter(earnedRevenueItem => ((earnedRevenueItem.type_id !== "M" && earnedRevenueItem.type_id !== "TGH") || (earnedRevenueItem.type_id === "M" || earnedRevenueItem.type_id === "TGH") && !earnedRevenueItem.is_supplement))
                .map(
                    (earnedRevenueItem): BillingTableEarnedRevenueItem => ({
                        key: earnedRevenueItem.id,
                        id: earnedRevenueItem.id,
                        name: `${earnedRevenueItem.instructionName}`,
                        instructionName: earnedRevenueItem.instructionName,
                        end: moment.unix(earnedRevenueItem.created_at).format('MM/DD/YYYY hh:mm a'),
                        quantity: earnedRevenueItem.quantity,
                        unit: billingItemDisplay.unit,
                        unit_price: `${formatCurrency(getCost(earnedRevenueItem, billingItemDisplay))}`,
                        price: getTotalCost(earnedRevenueItem, billingItemDisplay),
                        is_comped: earnedRevenueItem.is_comped,
                        is_supplement: earnedRevenueItem.is_supplement,
                        why: earnedRevenueItem.why,
                        why_other: earnedRevenueItem.why_other,
                        reason: earnedRevenueItem.reason,
                        created_at: earnedRevenueItem.created_at,
                        displayQuantity: getDisplayQuantity(earnedRevenueItem),
                        displayUnit: getDisplayUnit(earnedRevenueItem.additive_name || earnedRevenueItem.unit),
                        serial: earnedRevenueItem.serial,
                        serial_hours: earnedRevenueItem?.serial_hours,
                        type_id: (earnedRevenueItem?.type_id as String === "OT" || earnedRevenueItem?.type_id as String === "C") ? "F" : earnedRevenueItem?.type_id,
                        doctor_name: displayUser({
                            first_name: earnedRevenueItem.first_name, 
                            last_name: earnedRevenueItem.last_name
                        })
                    }),
                )
                .reduce(reduceEarnedRevenueItems, [])
                .sort((a, b) => {
                    return a.created_at -  b.created_at;
                }),
                // COMMENTED TEMPORARILY UNTIL THE LABELS ARE READDED
                // .sort((a, b) => {
                //     return subcategoriesList.indexOf(a.type_id!) - subcategoriesList.indexOf(b.type_id!)
                // })
                // .sort((a, b) => {
                //     return a.doctor_name!.localeCompare(b.doctor_name!);
                // }),
                why: null,
                why_other: null,
                reason: null,
                pricing_unit: billingItemDisplay.pricing_unit,
                pricing_unit_size: billingItemDisplay.pricing_unit_size,
                unit: billingItemDisplay.unit,
                serial: billingItemDisplay.serial,
                serial_hours: billingItemDisplay?.serial_hours,
            };
        })
    }
    return [];
};

const generateBillingColumns = (
    displayType: BillingDisplayType,
    selected: number[],
    setSelected: React.Dispatch<React.SetStateAction<number[]>>,
): ColumnsType<BillingTableRow | BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup> => {
    if (displayType === 'order') {
        return [
            {
                title: '',
                width: '0%',
                key: 'select',
                render: (_, record) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if(isInstanceOfBillingTableEarnedRevenueItemGroup(record)){
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isGroupSelected(record, selected)}
                                    onClick={() => {
                                        OnSelectGroup(record, selected, setSelected);
                                    }}
                                />
                            );
                        } else {
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isSelected(record.id, selected)}
                                    onClick={() => {
                                        OnSelect(record, selected, setSelected);
                                    }}
                                />
                            );

                        }
                    } 
                    else {
                        return (
                            <Checkbox
                                checked={isSelectedAll(record, selected)}
                                onClick={() => {
                                    OnSelectMany(record, selected, setSelected);
                                }}
                            />
                        );
                    }
                },
            },
            {
                title: 'Order',
                width: '26%',
                key: 'name',
                dataIndex: 'name',
                align: 'left',
                render: (value, record) => {
                    let name = value;
                    let serialTitle = '';
                    if (!record.serial && isInstanceOfBillingTableParentRow(record) && record.pricing_unit_size) {
                        name += ` - ${record.pricing_unit_size} ${record.unit}`;
                    } 
                    if (record.serial && record.serial_hours){
                        serialTitle = `- Serial ${record.serial_hours} Hours`
                    }
                    return {
                        props: {
                            colSpan: 1,
                            style: {
                                whiteSpace: record.why ? 'initial' : undefined,
                            },
                        },
                        children: isInstanceOfBillingTableParentRow(record) ? (
                            record.why ? (
                                <>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <div>{`${name} ${serialTitle} `}</div>
                                        <div style={{ marginLeft: '5px', fontWeight: 'normal', fontSize: '12px', color: 'var(--gray-8)' }}>
                                            ({getChildActionsCount(record.type_id, record.children)})
                                        </div>
                                    </div>

                                    <div style={{ fontWeight: 'normal' }}>Why: {record.why}</div>
                                    {record.why_other && <div style={{ fontWeight: 'normal' }}>Other: {record.why_other}</div>}
                                    <div style={{ fontWeight: 'normal' }}>Reason: {record.reason}</div>
                                </>
                            ) : (
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div>{`${name} ${serialTitle} `}</div>
                                    <div style={{ fontWeight: 'normal', fontSize: '12px', color: 'var(--gray-8)' }}>
                                        ({getChildActionsCount(record.type_id, record.children)})
                                    </div>
                                </div>
                            )
                        ) : (
                            (name as String) === "-" ? 'Unassigned': name
                        ),
                    }
                },
            },
            {
                title: 'Completed',
                width: '20%',
                key: 'end',
                dataIndex: 'end',
                align: 'left',
            },
            {
                title: 'Qty',
                width: '24%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'left',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Cost',
                width: '10%',
                key: 'unit_price',
                dataIndex: 'unit_price',
                align: 'right',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return `${formatCurrency(roundTo(record.price * record.serial_hours, 0))}`
                        }

                    }
                    return value;
                },
            },
            {
                title: 'Adjustment',
                width: '10%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'right',
                render(value, record, index) {
                    if (!isInstanceOfBillingTableParentRow(record) && record.is_comped) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={!record.is_comped} negative={true} />;
                        }
                        return <PriceDisplay price={record.price} isFreeOrRefund={!record.is_comped} negative={true} />;
                    }
                },
            },
            {
                title: 'Line Cost',
                width: '10%',
                key: 'price',
                dataIndex: 'price',
                align: 'right',
                render(value, record, index) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={record.is_comped} />;
                        }
                        return <PriceDisplay price={record.price} isFreeOrRefund={record.is_comped} />;
                    }
                    return <PriceDisplay price={record.price} isFreeOrRefund={false} />;
                },
            },
        ];
    } else if (displayType === "doctor") {
        return [
            {
                title: '',
                width: '0%',
                key: 'select',
                render: (_, record) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if(isInstanceOfBillingTableEarnedRevenueItemGroup(record)){
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isGroupSelected(record, selected)}
                                    onClick={() => {
                                        OnSelectGroup(record, selected, setSelected);
                                    }}
                                />
                            );
    

                        } else {
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isSelected(record.id, selected)}
                                    onClick={() => {
                                        OnSelect(record, selected, setSelected);
                                    }}
                                />
                            );

                        }
                    } 
                    else {
                        return (
                            <Checkbox
                                checked={isSelectedAll(record, selected)}
                                onClick={() => {
                                    OnSelectMany(record, selected, setSelected);
                                }}
                            />
                        );
                    }
                },
            },
            {
                title: 'Order',
                width: '26%',
                key: 'name',
                dataIndex: 'name',
                align: 'left',
                render: (value, record) => {
                    let serialTitle = '';
                    if (record.serial && record.serial_hours){
                        serialTitle = `- Serial ${record.serial_hours} Hours`
                    }
                    return {
                        props: {
                            colSpan: 1,
                        },
                        children: isInstanceOfBillingTableParentRow(record) ? (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div>{`${(value as String) === "-" ? 'Unassigned': value} `}</div>
                                <div style={{ fontWeight: 'normal', fontSize: '12px', color: 'var(--gray-8)' }}>
                                    ({getChildActionsCount(record.type_id, record.children)})
                                </div>
                            </div>
                        ) : record.why ? (
                            <>
                                <div>{(value as String) === "-" ? 'Unassigned': value}</div>
                                <div>Why: {record.why}</div>
                                {record.why_other && <div>Other: {record.why_other}</div>}
                                <div>Reason: {record.reason}</div>
                            </>
                        ) : (
                            (value as String) === "-" ? 'Unassigned': `${value} ${serialTitle}`
                        ),
                    }
            },
            },
            {
                title: 'Completed',
                width: '20%',
                key: 'end',
                dataIndex: 'end',
                align: 'left',
            },
            {
                title: 'Qty',
                width: '24%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'left',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Cost',
                width: '10%',
                key: 'unit_price',
                dataIndex: 'unit_price',
                align: 'right',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return `${formatCurrency(roundTo(record.price * record.serial_hours, 0))}`
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Adjustment',
                width: '10%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'right',
                render(value, record, index) {
                    if (!isInstanceOfBillingTableParentRow(record) && record.is_comped) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={!record.is_comped} negative={true} />;
                        }
                        return <PriceDisplay price={record.price} isFreeOrRefund={!record.is_comped} negative={true} />;
                    }
                },
            },
            {
                title: 'Line Cost',
                width: '10%',
                key: 'price',
                dataIndex: 'price',
                align: 'right',
                render(value, record) {
                    if (isInstanceOfBillingTableParentRow(record)) {
                        return <PriceDisplay price={record.price} isFreeOrRefund={false} />;
                    }
                    if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                        return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={record.is_comped} />;
                    }
                    return <PriceDisplay price={record.price} isFreeOrRefund={record.is_comped} />;
                },
            },
        ];
    } else {
        return [
            {
                title: '',
                width: '0%',
                key: 'select',
                render: (_, record) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if(isInstanceOfBillingTableEarnedRevenueItemGroup(record)){
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isGroupSelected(record, selected)}
                                    onClick={() => {
                                        OnSelectGroup(record, selected, setSelected);
                                    }}
                                />
                            );
    

                        } else {
                            return (
                                <Checkbox
                                    style={{ paddingLeft: '5px' }}
                                    checked={isSelected(record.id, selected)}
                                    onClick={() => {
                                        OnSelect(record, selected, setSelected);
                                    }}
                                />
                            );

                        }
                    } 
                    else {
                        return (
                            <Checkbox
                                checked={isSelectedAll(record, selected)}
                                onClick={() => {
                                    OnSelectMany(record, selected, setSelected);
                                }}
                            />
                        );
                    }
                },
            },
            {
                title: 'Completed',
                width: '20%',
                key: 'end',
                dataIndex: 'end',
                align: 'left',
                render: (value, record) => {
                    return {
                        children: isInstanceOfBillingTableParentRow(record) ? (
                            record.name
                        ) : (
                            value
                        ),
                    }
            },
            },
            {
                title: 'Order',
                width: '26%',
                key: 'name',
                dataIndex: 'name',
                align: 'left',
                render: (value, record) => {
                    if (isInstanceOfBillingTableParentRow(record)) {
                        return '';
                    }
                    return value;
            },
            },
            {
                title: 'Qty',
                width: '24%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'left',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (record.unit === 'dollar') {
                            return '';
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Cost',
                width: '10%',
                key: 'unit_price',
                dataIndex: 'unit_price',
                align: 'right',
                render(value, record) {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return `${formatCurrency(roundTo(record.price * record.serial_hours, 0))}`
                        }
                    }
                    return value;
                },
            },
            {
                title: 'Adjustment',
                width: '10%',
                key: 'displayQuantity',
                dataIndex: 'displayQuantity',
                align: 'right',
                render(value, record, index) {
                    if (!isInstanceOfBillingTableParentRow(record) && record.is_comped) {
                        if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                            return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={!record.is_comped} negative={true} />;
                        }
                        return <PriceDisplay price={record.price} isFreeOrRefund={!record.is_comped} negative={true} />;
                    }
                },
            },
            {
                title: 'Line Cost',
                width: '10%',
                key: 'price',
                dataIndex: 'price',
                align: 'right',
                render(value, record) {
                    if (isInstanceOfBillingTableParentRow(record)) {
                        return <PriceDisplay price={record.price} isFreeOrRefund={false} />;
                    }
                    if (isInstanceOfBillingTableEarnedRevenueItemGroup(record) && record.serial_hours) {
                        return <PriceDisplay price={roundTo(record.price * record.serial_hours, 0)} isFreeOrRefund={record.is_comped} />;
                    }
                    return <PriceDisplay price={record.price} isFreeOrRefund={record.is_comped} />;
                },
            },
        ];
    }
};

const getChildActionsCount = (type_id: EARNABLE_TYPES | null, children: BillingTableEarnedRevenueItem[]) => {
    if (type_id === 'C' || type_id === 'F') {
        return 1;
    }
    return children.reduce((total: number, item: BillingTableEarnedRevenueItem) => {
        return item.is_supplement ? total : total + 1;
    }, 0);
};

interface BillingTableProps {
    billingItems: BillingItem[];
    isFetching: boolean;
    type: 'order' | 'doctor' | 'datetime';
    selected: number[];
    setSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

let groupedMedicationIds: GroupedMedicationIds = {}

export const BillingTable = ({ billingItems, isFetching, type, selected, setSelected }: BillingTableProps) => {
    const columns = generateBillingColumns(type, selected, setSelected);
    const dataSource = generateBillingDisplayData(billingItems, type);
    const subcategoriesObject = {
        "N": "GENERAL SERVICES AND FEES", 
        "D": "DIAGNOSTICS", 
        "M": "MEDICATIONS", 
        "F": "FLUIDS/CRI/OXYGEN", 
        "T": "TASKS",
        "TGH": "TO GO MEDICATION"
    }
    let groupedBillingItems: GroupedBillingItem | MultiGroupedBillingItem = {} 

    const groupBillingItems = () => {
        if (type === "order") {
            groupedBillingItems =  dataSource
            .reduce((groupedBillingItems: GroupedBillingItem, billingItem: BillingTableRow) => {
                const billingItemTypeId = (billingItem.type_id as String === "OT" || billingItem.type_id as String === "C") ? "F" : billingItem.type_id
                const group = (groupedBillingItems[billingItemTypeId!] || []);
                group.push(billingItem);
                groupedBillingItems[billingItemTypeId!] = group;
                return groupedBillingItems;
            }, {});
        } else if (type === "doctor") {
            groupedBillingItems =  dataSource
            .reduce((groupedBillingItems: MultiGroupedBillingItem, billingItem: BillingTableRow) => {
                const billingItemDoctorId = billingItem.doctor_id ? `${capitalizeFirstLetter(billingItem.doctor_first_name ?? '')} ${capitalizeFirstLetter(billingItem.doctor_last_name ?? '')}` : "Unassigned"
                groupedBillingItems[billingItemDoctorId] = {}
                billingItem.children.forEach((bic) => {
                    const itemTypeGroup = (groupedBillingItems[billingItemDoctorId]?.[bic.type_id!] || []);
                    itemTypeGroup.push(bic);
                    groupedBillingItems[billingItemDoctorId][bic.type_id!] = itemTypeGroup;
                })
                return groupedBillingItems;
            }, {});    
        } else if (type === "datetime") {
            groupedBillingItems =  dataSource
            .reduce((groupedBillingItems: MultiGroupedBillingItem, billingItem: BillingTableRow) => {
                const billingItemDate = billingItem.end_time
                groupedBillingItems[billingItemDate!] = {}
                billingItem.children.forEach((bic) => {
                    const itemTypeGroup = (groupedBillingItems[billingItemDate!]?.[bic.doctor_name!] || []);
                    itemTypeGroup.push(bic);
                    groupedBillingItems[billingItemDate!][bic.doctor_name!] = itemTypeGroup;
                })
                return groupedBillingItems;
            }, {}); 
        }
    }

    useEffect(() => {
        groupBillingItems()
        // refreshTitle()
    }, [billingItems, type])

    useEffect(() => {
        setExpandedRowsNames([])
        setExpandedRowsKeys([])
    }, [type])

    // Note: This function manipulates the DOM to be able to dynamically insert the subcategory rows in the right position.
    // Antd's table doesn't have any way to add the subcategory rows in the exact way the requirement asked for, so
    // this was the only way to achieve the desired result.
    const appendSubcategoryRow = (elements: NodeListOf<Element>, indexer: number, subcategoryTitle: string) => {
        const newNode = document.createElement("tr");
        const newChildNode1 = document.createElement("td");
        const newChildNode2 = document.createElement("td");
        newChildNode2.innerHTML = subcategoryTitle
        newNode.appendChild(newChildNode1)
        newNode.appendChild(newChildNode2)
        newNode.classList.add("subcategory-container");
        elements[indexer]?.before(newNode)
    }

    const refreshTitle = () => {
        let indexer = 0
        document.querySelectorAll(".subcategory-container").forEach(e => e.remove());
        if (type === "order") {
            const elements = document.querySelectorAll('.table-container .ant-table-row.ant-table-row-level-0');
            if (elements) {
                const orderedKeys = Object.keys(groupedBillingItems)            
                .sort((a, b) => {
                    return subcategoriesList.indexOf(a) - subcategoriesList.indexOf(b)
                })
                orderedKeys.forEach((key) => {
                    const value = groupedBillingItems[key]
                    appendSubcategoryRow(elements, indexer, subcategoriesObject[key as keyof typeof subcategoriesObject])
                    indexer = indexer + (value as BillingTableRow[]).length
                });   
            }
        } else if (type === "doctor") {
            const elements = document.querySelectorAll('.table-container .ant-table-row.ant-table-row-level-1');
            if (elements) {
                Object.entries(groupedBillingItems).forEach(([primaryKey, primaryValue], i) => {
                    if (expandedRowsNames.includes(primaryKey)) {
                        const orderedKeys = Object.keys(primaryValue)            
                        .sort((a, b) => {
                            return subcategoriesList.indexOf(a) - subcategoriesList.indexOf(b)
                        })
                        orderedKeys.forEach((key) => {
                            const value = primaryValue[key]
                            appendSubcategoryRow(elements, indexer, subcategoriesObject[key as keyof typeof subcategoriesObject])
                            indexer = indexer + (value as BillingItem[]).length
                        });  
                    }
 

                }) 
            }  
        } else if (type === "datetime") {
            const elements = document.querySelectorAll('.table-container .ant-table-row.ant-table-row-level-1');
            if (elements) {
                Object.entries(groupedBillingItems).forEach(([primaryKey, primaryValue], i) => {
                    if (expandedRowsNames.includes(primaryKey)) {
                        const orderedKeys = Object.keys(primaryValue)            
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        })
                        orderedKeys.forEach((key) => {
                            const value = primaryValue[key]
                            appendSubcategoryRow(elements, indexer, key.toUpperCase())
                            indexer = indexer + (value as BillingItem[]).length
                        });  
                    }
                }) 
            }  
        }
    }

    const [expandedRowsKeys, setExpandedRowsKeys] = useState<string[]>([])
    const [expandedRowsNames, setExpandedRowsNames] = useState<string[]>([])


    useEffect(() => {
        groupBillingItems()
        // refreshTitle()
    }, [expandedRowsNames])

    return (
        <div className={'table-container'}>
            <Table
                className='billing-table'
                columns={columns}
                loading={isFetching}
                dataSource={dataSource}
                expandable={{expandedRowKeys: expandedRowsKeys}}
                onExpand={(expanded, record) => {
                    let name = record.name
                    let key = record.key
                    if (expanded) {
                        const names = [...expandedRowsNames, name]
                        setExpandedRowsNames(names)
                        const keys = [...expandedRowsKeys, String(key)]
                        setExpandedRowsKeys(keys)
                    } else {
                        const names = expandedRowsNames.filter((row) => row !== name);
                        setExpandedRowsNames(names)
                        const keys = expandedRowsKeys.filter((row) => row !== key);
                        setExpandedRowsKeys(keys)
                    }
                }}
                scroll={{
                    x: true,
                }}
                data-cy={'billingTable'}
                pagination={false}
                expandIcon={({ expanded, onExpand, record }) => {
                    if (!isInstanceOfBillingTableParentRow(record)) {
                        return <div></div>;
                    }
                    if (expanded) {
                        return (
                            <UpOutlined
                                style={{
                                    width: '20px',
                                    paddingRight: '4px',
                                }}
                                onClick={(e) => onExpand(record, e)}
                            />
                        );
                    } else {
                        return (
                            <DownOutlined
                                style={{
                                    width: '20px',
                                    paddingRight: '4px',
                                }}
                                onClick={(e) => onExpand(record, e)}
                            />
                        );
                    }
                }}
            ></Table>
        </div>
    );
};
