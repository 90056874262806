import { Modal } from "antd";
import { RuleObject } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import { MAX_FILE_UPLOAD_SIZE_MB } from "utils/constants";

export const fileUploadRules = [
	() => ({
		validator(_: RuleObject, files: RcFile[]) {
			return validateFile(files);
		}
	})
];

export async function validateFile(files: RcFile[]) {
	if (!files || files.length === 0) {
		return Promise.resolve();
	}
	if (files[0].size > MAX_FILE_UPLOAD_SIZE_MB * 1024 * 1024) {
		Modal.warning({
			autoFocusButton: "ok",
			title: "An error has occurred.",	
			content: (
				<>
					The file is too large to upload.
					The maximum file size is {MAX_FILE_UPLOAD_SIZE_MB} MB.
				</>
			),
			centered: true,
		});
		return Promise.reject();
	}
	return Promise.resolve();
}
