import { Skeleton } from 'antd';
import React from 'react';
import './Indicator.css';

interface IndicatorProps {
    title: string;
    information: string;
    isLoading: boolean;
}

const Indicator: React.FC<IndicatorProps> = ({ information, isLoading, title }) => (
    <div className='whiteboard__indicator'>
        <div className='whiteboard__indicator-info'>
            {isLoading ? <Skeleton.Button active shape='square' style={{ display: 'flex', width: '32px' }} /> : information}
        </div>
        <div className='whiteboard__indicator-description'>{title}</div>
    </div>
);
export default Indicator;
