import { useMemo } from 'react';
import moment from 'moment';
import {
	BASE_QUERY_OPTIONS,
	LOCAL_STORAGE_USER_KEY,
	TOKEN_EXPIRE_MINUTES,
} from 'utils/constants';
import { PimsUser } from 'utils/dataTypes';
import { useGetUserDataQuery } from 'services/authService';

const useIsUserAuthenticated = () => {
	let { data: loggedInUserData, isFetching } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);

	let isUserAuthenticated = useMemo(() => {
		// This has been temporarily commented out as this does not work as we want it to.
		// let localStorageUserData: PimsUser = {} as PimsUser;

		// if (window.localStorage.getItem(LOCAL_STORAGE_USER_KEY)) {
		// 	localStorageUserData = JSON.parse(
		// 		window.localStorage.getItem(LOCAL_STORAGE_USER_KEY) ?? '',
		// 	);
		// }

		// const minutesSinceLogin = moment().diff(
		// 	moment.unix(localStorageUserData.last_login_at),
		// 	'minutes',
		// );

		// const isLoginStillValid = minutesSinceLogin < TOKEN_EXPIRE_MINUTES;

		//Only use localstorage data while initial req is still pending
		// if (isLoginStillValid && isFetching) {
		// 	loggedInUserData = localStorageUserData;
		// }

		// return isLoginStillValid && !!loggedInUserData;
		return { loggedIn: typeof loggedInUserData !== 'undefined', isFetching, loggedInUserData };
	}, [loggedInUserData, isFetching]);

	return [isUserAuthenticated];
};

export default useIsUserAuthenticated;
