import moment from 'moment';
import {
	INSTRUCTION_TYPES,
	InstructionAction,
	BaseExistingInstruction,
	isInstanceOfExistingCriInstruction,
	isInstanceOfExistingFluidInstruction,
	isInstanceOfExistingDiagInstruction,
	isInstanceOfMedicineInstruction,
	isInstanceOfProblemList,
	INSTRUCTION_STATUSES,
} from '../utils/dataTypes';
import { isInstructionOver } from './miscFuncs';

/**
 * @param  {INSTRUCTION_TYPES} instructionType // The type of instruction
 */
export const filterInstructionsByType =
	(instructionType: INSTRUCTION_TYPES) =>
	(instruction: BaseExistingInstruction): boolean => {
		const instructionTypeMapping = {
			M: isInstanceOfMedicineInstruction,
			D: isInstanceOfExistingDiagInstruction,
			P: isInstanceOfProblemList,
			C: isInstanceOfExistingCriInstruction,
			F: isInstanceOfExistingFluidInstruction,
			N: () => false,
			// TODO impliment Task Deffintion Type and Type Guard
			T: () => false,
			TGH: () => false,
			OT: isInstanceOfExistingFluidInstruction,
		};
		return instructionTypeMapping[instructionType](instruction);
	};

/**
 * @param  {number} startTime
 * @param  {number} endTime
 * @returns boolean
 */
 export const filterActionsByDateTime =
 (startTime: number, endTime: number | null) =>
 (action: InstructionAction): boolean => {
	 if (endTime === null){
		 return action.due_at >= startTime;
	 } else {
		 return action.due_at <= endTime && action.due_at >= startTime;

	 }
 };

/**
 * @param  {INSTRUCTION_STATUSES[]} statuses
 * @returns boolean
 */
export const filterActionsByStatus =
	(statuses: INSTRUCTION_STATUSES[]) =>
	(action: InstructionAction): boolean => {
		return statuses.includes(action.status);
	};

/**
 * @param  {INSTRUCTION_STATUSES[]} statuses
 * @returns boolean
 */
export const filterClosedInstructions = (
	instruction: BaseExistingInstruction,
): boolean => {
	if (isDiscontinuedOrOver(instruction)) {
		return false;
	} else {
		return true;
	}
};

export const isDiscontinuedOrOver = (instruction: {discontinued_at: number | null, end_time: number | null}) => {
	const isDiscontinued = instruction.discontinued_at !== null;
	const isOver = isInstructionOver(instruction);
		return isDiscontinued || isOver;
}

export const filterDiscontinuedInstructions = (
	instruction: BaseExistingInstruction,
): boolean => {
	return instruction.discontinued_at === null;
}

export const filterFluidsCri = (
	instruction: BaseExistingInstruction
): boolean => instruction.type_id === 'C' || instruction.type_id === 'F' || instruction.type_id === "OT";
