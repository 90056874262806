// @ts-nocheck

import { PimsUser } from "./dataTypes";
import { REACT_APP_ENV } from 'utils/constants';

export const pendoInit = (user: PimsUser, isOn: boolean) => {
    if (isOn && user.okta_subject && REACT_APP_ENV){
        (function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
                pendo.initialize({
                    'visitor': {
                        'id': user.okta_subject,
                        'email': user.email,
                        'first_name': user.first_name,
                        'last_name': user.last_name,
                        'role': user.role_name
                    },
                    'account': {
                        'id': `${REACT_APP_ENV}-${user.hospital_id}`,
                        'name': user.hospital_name, 
                    }
                });
        })('59b33e90-a9bd-4aef-77a7-36e419721d9f');
    
    }
}
