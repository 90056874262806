import {
	BarChartOutlined,
	CalendarOutlined,
	DashboardOutlined,
	DollarOutlined,
	DownOutlined,
	LogoutOutlined,
	MedicineBoxOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PlusOutlined,
	SettingOutlined,
	ThunderboltOutlined,
	UpOutlined,
	DesktopOutlined
} from '@ant-design/icons';
import { Button, Col, Image, Layout, Menu, Row, Typography } from 'antd';
import { ContactIcon } from 'assets/img/ContactIcon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useGetUserDataQuery, useLogoutMutation } from 'services/authService';
import { useGetHospitalByIdQuery, useLazyGetWhiteboardLinkQuery } from 'services/hospitalService';
import { ROUTES } from 'utils/types/enums';
import {
	USER_PERMISSIONS,
	checkIfUserHasNecessaryPermission,
} from 'utils/userPermissions';
import DogBytePaw from '../../assets/img/DogByte_Paw.svg';
import DogByteName from '../../assets/img/DogByteName.svg';
import './side-bar.css';
import { PatientSearchBox } from 'components/TopBar/PatientSearch';
import { EarningsDrawer } from 'components/EarningsDrawer';
import AdmitPatient from 'components/Modals/AdmitPatient';
import { MacrosDrawer } from 'components/drawers/Macros';
import { ADMIN_URL, BASE_QUERY_OPTIONS } from 'utils/constants';
import { Avatar } from 'components/lib';
import PendoBadge from 'components/pendo/pendoBadge';
import UserDropdownMenu, { DropdownItem } from 'components/lib/UserDropdownMenu';
import { createPortal } from 'react-dom';
import { getAllInitials } from 'utils/formatFuncs';

const SideBar: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const [collapsed, setCollapsed] = useState(window.innerWidth > 1200 ? false : true);
	const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
	const [isAdmitPatientModalOpen, setIsAdmitPatientModalOpen] = useState(false);
	const [isEarningsDrawerOpen, setIsEarningsDrawerOpen] = useState(false);
	const [isMacrosDrawerOpen, setIsMacrosDrawerOpen] = useState(false);
	const [tempExpand, setTempExpand] = useState(false);
	const [userMenuVisible, setUserMenuVisible] = useState(false);
	const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
	const avatarRef = useRef<HTMLDivElement | null>(null);

	const [logout] = useLogoutMutation();

	const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);
	const { data: hospitalData } = useGetHospitalByIdQuery(loggedInUserData?.hospital_id ?? 0);
	const [getWhiteboardLink] = useLazyGetWhiteboardLinkQuery()
	const userHasPermissionToViewEarnings = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.earnings_view,
	);
	const userHasAccessToAdminPanel = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.admin_panel_access,
	);
	const viewMacros = checkIfUserHasNecessaryPermission(loggedInUserData?.user_permissions, USER_PERMISSIONS.macros_view);

	const handleSidebarToggle = () => {
		if (!tempExpand) {
			setCollapsed(!collapsed);
		}
		setTempExpand(false);
	};

	useEffect(() => {
		if (location.pathname.startsWith(ROUTES.outpatientTS)) {
			setSelectedMenuItem('outpatient_ts');
		} else if (location.pathname.startsWith(ROUTES.inpatientTS)) {
			setSelectedMenuItem('inpatient_ts');
		} else if (
			location.pathname === '/' ||
			location.pathname.startsWith('/visit')
		) {
			setSelectedMenuItem('dashboard');
		} else if (location.pathname.startsWith(ROUTES.allvisits)) {
			setSelectedMenuItem('all_visits');
		} else if (location.pathname.startsWith(ROUTES.contacts) || location.pathname.startsWith('/customer')) {
			setSelectedMenuItem('all_contacts');
		} else if (
			location.pathname.startsWith(ROUTES.controlled_drug_report)
		) {
			setSelectedMenuItem('controlled_drugs_report');
		} else if (
			location.pathname.startsWith(ROUTES.accounts_receivable_report)
		) {
			setSelectedMenuItem('accounts_receivable_report');
		} else if (
			location.pathname.startsWith(ROUTES.doctor_production_report)
		) {
			setSelectedMenuItem('doctor_production_report');
		} else if (
			location.pathname.startsWith(ROUTES.payments_report)
		) {
			setSelectedMenuItem('payments_report');
		}
	}, [location]);

	const getDefaultOpenKeys = () => {
		if (
			location.pathname.startsWith(ROUTES.controlled_drug_report) ||
			location.pathname.startsWith(ROUTES.accounts_receivable_report) ||
			location.pathname.startsWith(ROUTES.doctor_production_report)
		) {
			return ['reports'];
		} else if (
			location.pathname.startsWith(ROUTES.outpatientTS) ||
			location.pathname.startsWith(ROUTES.inpatientTS)
		) {
			return ['treatmentSheets'];
		}
		return [];
	};

	const userHasPermissionToViewReports = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.reports_view,
	);

	const isSidebarCollapsed = useMemo(() => {
		return !tempExpand && collapsed;
	}, [collapsed, tempExpand]);

	const userDropdownItems: DropdownItem[] = [
		...(userHasPermissionToViewEarnings ? [{
			key: 'earnings',
			label: 'Earnings',
			onClick: () => setIsEarningsDrawerOpen(true),
			icon: <DollarOutlined />
		}] : []),
		...(viewMacros ? [{
			key: 'macros',
			label: 'Macros',
			onClick: () => setIsMacrosDrawerOpen(true),
			icon: <ThunderboltOutlined />
		}] : []),
		...(userHasAccessToAdminPanel ? [
			{
				key: 'admin',
				label: 'Admin Panel',
				url: ADMIN_URL,
				icon: <SettingOutlined />
			},
			{
				key: 'whiteboard',
				label: 'TV Whiteboard Link',
				onClick: () => { getWhiteboardLink(loggedInUserData?.hospital_id ?? 0).then((res) => {
					if (res?.error) {
						console.error(res.error)
						return
					}
					window.open(res?.data?.url, "_blank")
				}) },
				icon: <DesktopOutlined rotate={180}/>
			}
		] : []),
		{
			key: 'signout',
			label: 'Sign Out',
			onClick: () => { logout(null); },
			icon: <LogoutOutlined />
		}
	];

	useEffect(() => {
		if (avatarRef.current) {
			const rect = avatarRef.current.getBoundingClientRect();
			setDropdownPosition({
				top: rect.top - (42.5 * userDropdownItems.length),
				left: collapsed ? rect.left + 35 : rect.width - 25
			});
		}
	}, [collapsed, userMenuVisible]);

	return (
		<>
			<Layout.Sider
				collapsible
				collapsed={isSidebarCollapsed}
				onCollapse={handleSidebarToggle}
				className='sidebar'
				data-cy="sidebar"
				width={240}
				collapsedWidth={64}
				trigger={null}
			>
				<Row
					className="sidebar-collapse-button"
					justify={isSidebarCollapsed ? 'center' : 'end'}
					onClick={handleSidebarToggle}
				>
					{isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined style={{ marginRight: 14 }} />}
				</Row>
				<div style={{ height: 140 }}>
					<Row justify="center" style={{ flexDirection: 'column', alignItems: 'center' }}>
						<Link to={'/'} className='sidebar-header'>
							<Image
								data-cy="dogbytePawLogo"
								className='dogbyte-paw-logo'
								preview={false}
								src={DogBytePaw}
								alt=''
							/>
							<div
								style={{
									opacity: isSidebarCollapsed ? 0 : 1,
									transition: 'opacity 0.3s ease',
									marginTop: 8,
									height: 27
								}}>
								<Image
									data-cy="dogbyteMainLogo"
									preview={false}
									src={DogByteName}
									alt=''
								/>
							</div>
						</Link>
					</Row>
					<Row justify="center">
						<Typography.Text className='hospital-name' data-cy='topbarHospitalName'>
							{isSidebarCollapsed ? getAllInitials(hospitalData?.display_name) : hospitalData?.display_name || 'No Hospital Select'}
						</Typography.Text>
					</Row>
				</div>
				<Menu
					className='sidebar-menu'
					selectedKeys={[selectedMenuItem]}
					data-cy="sidebarContainer"
					mode='inline'
					defaultOpenKeys={getDefaultOpenKeys()}
				>
					<div className='sidebar-search-container'>
						<PatientSearchBox collapsed={collapsed && !tempExpand} setTempExpand={setTempExpand} />
						<Button
							className='new_patient_button'
							shape={isSidebarCollapsed ? "circle" : "round"}
							data-cy={'newPatientButton'}
							icon={<PlusOutlined className='new_patient_text' />}
							onClick={() => {
								setIsAdmitPatientModalOpen(true);
							}}						>
							{
								!isSidebarCollapsed &&
								<span className='new_patient_text'>Add New Visit</span>
							}
						</Button>
					</div>
					<Menu.Divider style={{ margin: isSidebarCollapsed ? '14px 11px' : '14px 22px', background: '#595959' }} />
					<Menu.Item
						onClick={() => {
							history.push('/');
						}}
						key='dashboard'
						data-cy="sidebarDashboardLink"
					>
						<DashboardOutlined />
						<span>Dashboard</span>
					</Menu.Item>
					<Menu.SubMenu
						title='Treatment Sheets'
						icon={<MedicineBoxOutlined />}
						key='treatmentSheets'
						data-cy="sidebarTreatmentSheetsLink"
					>
						<Menu.Item
							onClick={() => {
								history.push(ROUTES.outpatientTS);
							}}
							key='outpatient_ts'
							data-cy="sidebarOutpatient"
						>
							<span>Outpatient</span>
						</Menu.Item>
						<Menu.Item
							onClick={() => {
								history.push(ROUTES.inpatientTS);
							}}
							key='inpatient_ts'
						>
							<span>Inpatient</span>
						</Menu.Item>
					</Menu.SubMenu>
					<Menu.Item
						onClick={() => {
							history.push(ROUTES.allvisits);
						}}
						key='all_visits'
						data-cy="sidebarVisitsLink"
					>
						<CalendarOutlined />
						<span>Visits</span>
					</Menu.Item>
					<Menu.Item
						onClick={() => {
							history.push(ROUTES.contacts);
						}}
						key='all_contacts'
						data-cy="sidebarContactList"
					>
						<ContactIcon style={{ width: '16px', height: '16px' }} />
						<span>Contacts</span>
					</Menu.Item>
					{userHasPermissionToViewReports && (
						<>
							<Menu.SubMenu
								title='Reports'
								icon={<BarChartOutlined style={{ width: '16px', height: '16px' }} />}
								key='reports'
								data-cy="sidebarReportsLink"
							>
								<Menu.Item
									onClick={() => {
										history.push(ROUTES.accounts_receivable_report);
									}}
									key='accounts_receivable_report'
								>
									<span>Accounts Receivable</span>
								</Menu.Item>
								<Menu.Item
									onClick={() => {
										history.push(ROUTES.controlled_drug_report);
									}}
									key='controlled_drugs_report'
								>
									<span>Controlled Drugs</span>
								</Menu.Item>
								<Menu.Item
									onClick={() => {
										history.push(ROUTES.doctor_production_report);
									}}
									key='doctor_production_report'
								>
									<span>Doctor Production</span>
								</Menu.Item>
								<Menu.Item
									onClick={() => {
										history.push(ROUTES.payments_report);
									}}
									key='payments_report'
								>
									<span>Payments</span>
								</Menu.Item>
							</Menu.SubMenu>
							{hospitalData?.fflags?.whiteboard &&
								<Menu.Item
									onClick={() => {
										history.push(ROUTES.whiteboard);
									}}
									key='whiteboard'
									data-cy="whiteboardLink"
								>
									<CalendarOutlined style={{ width: '16px', height: '16px' }} />
									<span>Whiteboard</span>
								</Menu.Item>
							}
						</>
					)}
				</Menu>
				<div className='pendo-container'>
					<PendoBadge absolute={false} collapsed={collapsed} />
				</div>
				<Row
					justify="space-around"
					align="middle"
					style={{ width: '100%' }}
					id="dropdownTrigger"
					onClick={(e) => {
						e.stopPropagation();
						setUserMenuVisible(prev => !prev);
					}}
				>
					<Row
						data-cy='loggedInUserDropdown'
						justify={isSidebarCollapsed ? "center" : "space-between"}
						align="middle"
						className='avatar-container'
						style={{ padding: isSidebarCollapsed ? 0: '0 14px'}}
						ref={avatarRef}
					>
						<Col>
							{
								createPortal(
									<UserDropdownMenu
										items={userDropdownItems}
										visible={userMenuVisible}
										setVisible={setUserMenuVisible}
										position={dropdownPosition}
									/>,
									document.body
								)
							}
							<Avatar
								size={'large'}
								firstName={loggedInUserData?.first_name ?? ''}
								lastName={loggedInUserData?.last_name ?? ''}
								className={loggedInUserData?.role_name === 'Nurse' ? 'nurse-avatar' : undefined}
								style={{ border: '3px solid white' }}
							/>
							{!isSidebarCollapsed &&
								<span className='avatar-user-name'>
									{loggedInUserData?.first_name ?? ''}
								</span>
							}
						</Col>
						{!isSidebarCollapsed &&
							<Col>
								{
									userMenuVisible ? <UpOutlined style={{ color: 'white' }} />
										: <DownOutlined style={{ color: 'white' }} />
								}
							</Col>
						}
					</Row>
				</Row>
			</Layout.Sider>
			<AdmitPatient isOpen={isAdmitPatientModalOpen} setIsOpen={setIsAdmitPatientModalOpen} hospitalData={hospitalData} />
			<EarningsDrawer open={isEarningsDrawerOpen} setOpen={setIsEarningsDrawerOpen} />
			<MacrosDrawer open={isMacrosDrawerOpen} setOpen={setIsMacrosDrawerOpen} />
		</>
	);
};

export default SideBar;
