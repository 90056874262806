import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { INSTRUCTION_HOST } from '../utils/constants';

export const instructionApi = createApi({
	reducerPath: 'instructionApi',
	//TODO remove extra/unused tag types from each API
	tagTypes: [
		'Patient', // AKA pet
		'Visit',
		'Customer', //AKA Owner
		'Hospital',
		'Exam', // Physical exam for VISIT
		'ProgNote', // Progress note for VISIT
		'Instruction', //Instruction for VISIT
		'Package', // Group of Instructions, from SEARCH endpoint
		'Problem', // Problem for VISIT
		'rDVM', // Associates with patients and visits
		'SELF', // current logged in user
		'User', // other PIMS users
		'Notification',
		'PatientHistory',
		'MainTs', // represents entire hospital treatment sheet
		'Estimate', // Physical exam for VISIT
		'InstructionId',
		'ActionId',
	],
	baseQuery: fetchBaseQuery({
		baseUrl: INSTRUCTION_HOST,
		credentials: 'include',
		prepareHeaders: (headers, { getState }) => {
			if (!headers.has('Content-Type')) {
				headers.set('Content-Type', 'application/json');
			} else {
				headers.delete('Content-Type');
			}
			return headers;
		},
	}),
	endpoints: () => ({}),
});
