import { EllipsisOutlined, FileTextOutlined } from '@ant-design/icons';
import {
	Button,
	Divider,
	Dropdown,
	FormInstance,
	Menu,
	Modal,
	Tooltip,
	message
} from 'antd';
import { RecentResultsModal } from 'components/Modals/RecentResults';
import { idexxOpenOrderModal } from 'components/forms/AdministerMedicationForm';
import { useAppDispatch } from 'hooks/useRedux';
import moment from 'moment';
import React, { useState } from 'react';
import { useAddCubexOrderMutation } from 'services/cubexService';
import {
	useLazyGetIdexxRecentResultsQuery,
	useLazyGetWebpacsRecentResultsQuery,
	useModifyActionMutation,
	useSubmitIHDOrderMutation,
	useSubmitXRayOrderMutation,
} from 'services/instructionService';
import {
	setActiveActionItem,
	setActiveActionModal,
	setActiveTreatmentSheetAsk
} from 'store/slices/treatmentSheetSlice';
import { BASE_QUERY_OPTIONS, FormName, TASKS_HOST } from 'utils/constants';
import {
	INSTRUCTION_TYPES,
	PimsUser,
	TreatmentSheetInstructionAction,
	VCPExternalResult,
	instructionType,
	isInstanceOfExistingCriInstruction,
	isInstanceOfMedicineInstruction
} from 'utils/dataTypes';
import { errorMessage } from '../DischargeTab';
import { showNoChargeModal } from '../DischargeTab/utils';
import { useGetHospitalByIdQuery } from 'services/hospitalService';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetVisitByIdQuery } from 'services/visitService';


interface TreatmentSheetMarkerProps {
	patientName: string;
	item: TreatmentSheetInstructionAction;
	itemGroup: INSTRUCTION_TYPES;
	visitId: number;
	allInstructions: instructionType[];
	getItemProps: any;
	itemStyle: any;
	itemContext: any;
	patientWeight?: number;
	parentOrder?: instructionType;
	onModalFinish: Function;
	setModalState: Function;
	modalForm: FormInstance<any>;
	loggedInUserData: PimsUser | undefined;
	hideContextMenu: boolean;
	isFinalizedVisit: boolean;
	updateIsInvoiceLocked: () => boolean;
}

export const TreatmentSheetMarker: React.FunctionComponent<TreatmentSheetMarkerProps> = ({
	patientName,
	item,
	itemGroup,
	visitId,
	allInstructions,
	getItemProps,
	itemStyle,
	itemContext,
	parentOrder,
	onModalFinish,
	setModalState,
	modalForm,
	loggedInUserData,
	hideContextMenu,
	isFinalizedVisit,
	updateIsInvoiceLocked,
}) => {
	const appDispatch = useAppDispatch();
	const [modifyAction] = useModifyActionMutation();
	const [addCubexOrder] = useAddCubexOrderMutation();
	const [submitXRayOrder] = useSubmitXRayOrderMutation();
	const [submitIHDOrder] = useSubmitIHDOrderMutation();
	const [getRecentIHDResultsTrigger] = useLazyGetIdexxRecentResultsQuery();
	const [getRecentXrayResultsTrigger] = useLazyGetWebpacsRecentResultsQuery();
	const [recentResults, setRecentResults] = useState<VCPExternalResult[]>();
	const [isRecentResultsModalOpen, setIsRecentResultsModalOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<string>('');
	const [selectedTime, setSelectedTime] = useState<moment.Moment | null>(
		item.completed_at
			? moment.unix(item.completed_at)
			: moment().startOf('minute')
	);
	const { data: currentVisit } = useGetVisitByIdQuery(visitId);
	const { data: hospitalData } = useGetHospitalByIdQuery(currentVisit?.hospital_id ?? skipToken, {
        ...BASE_QUERY_OPTIONS,
        skip: !currentVisit?.hospital_id,
    });
	const cubexPushForNonControlledDrugAllowed = !!(
        hospitalData?.has_supply_bank &&
        parentOrder &&
        (isInstanceOfMedicineInstruction(parentOrder) || isInstanceOfExistingCriInstruction(parentOrder)) &&
        parentOrder.vetspire_id !== null
    );

	const handleClick = () => {
		if (
			!item?.category ||
			item.category === FormName.communication_note ||
			item.category === FormName.prog_note ||
			item.category === FormName.procedure_note ||
			item.category === FormName.recommendation_note
		) {
			const isInvoiceLocked = updateIsInvoiceLocked();
			if (isInvoiceLocked && item.type_id !== 'TGH' && item.status !== 'complete') {
				showNoChargeModal(showTreatmentSheetMarkerModal);
			} else {
				showTreatmentSheetMarkerModal();
			}
		}
	}

	const showCompleteActionModal = () => {
		appDispatch(setActiveTreatmentSheetAsk(item));
		appDispatch(setActiveActionModal('CompleteActionModal'));
	};

	const showTreatmentSheetMarkerModal = () => {
		appDispatch(setActiveTreatmentSheetAsk(item));
	};

	const {onMouseDown, onMouseUp, onTouchStart, onTouchEnd, ...itemProps} = getItemProps();
	let title = itemContext?.title;
	if (typeof itemContext.title === 'string') {
		title = itemContext?.title?.length > 40 ? itemContext?.title?.substring(0, 10) + '...' : itemContext?.title + '';
	}
	return (
		<div onAnimationStart={(e) => e.stopPropagation()}>
			<div {...itemProps} style={itemStyle} onClick={handleClick} data-cy={'treatmentSheetMarker'}>
				{!item.category && item.status !== 'complete' && !hideContextMenu && !isFinalizedVisit &&
					<Dropdown
						trigger={['hover']}
						overlay={() => {
							return (
								<Menu>
									{item.status === 'scheduled'
										&& (
											(
												parentOrder?.type_id === 'D'
												&& (parentOrder.category === 'Vitals' || parentOrder.category === 'Monitoring')
											)
											|| (parentOrder?.type_id === 'M' && parentOrder.controlled_drug === false)
										)
										&& <Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												modifyAction({
													visitId,
													body: {
														instructionId: item.instruction_id,
														actionId: item.action_id,
														status: 'inprogress',
														assigned_to: loggedInUserData?.user_id,
														due_at: item.due_at
													}
												})
													.then(() => message.success(`${parentOrder.name} marked In Progress`))
											}}
										>
											In Progress
										</Menu.Item>}
									{parentOrder?.type_id === 'TGH' && (
										<Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												fetch(`${TASKS_HOST}/visit/${visitId}/togomed/print/${parentOrder.tgh_id}`)
													.then((resp) => {
														return resp.json();
													})
													.then((respJson) => {
														if (typeof respJson.data.print_label_code === 'number') {
															message.success(`${parentOrder?.name} label has been successfully reprinted.`);
														} else {
															return Promise.reject();
														}
													})
													.catch(() => {
														message.error({
															className: 'multi-line-message',
															content: errorMessage(`Unable to print ${parentOrder?.name}.`),
														});
													});
											}}
										>
											Reprint Rx Label
										</Menu.Item>
									)}
									{item.status !== 'skipped' &&
										<Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												const isInvoiceLocked = updateIsInvoiceLocked();
												if (isInvoiceLocked) {
													showNoChargeModal(showCompleteActionModal);
												} else {
													showCompleteActionModal();
												}
											}}
										>
											{((itemGroup === 'C' || itemGroup === 'F') && !item.value)
												? 'Start Fluids'
												: 'Complete'
											}
										</Menu.Item>
									}
									{(item.status === 'scheduled' || item.status === 'inprogress')
										&& parentOrder?.type_id === 'D'
										&& (parentOrder.category === 'IDEXX' || parentOrder.category === 'WebPACS')
										&& <Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												let trigger;
												if (parentOrder.category === 'IDEXX') {
													trigger = getRecentIHDResultsTrigger;
												} else {
													trigger = getRecentXrayResultsTrigger;
												}
												trigger().unwrap().then(
													(data) => {
														setRecentResults(data);
														setSelectedRow('');
														setIsRecentResultsModalOpen(true);
													}
												);
											}}>
											Fetch Results
										</Menu.Item>
									}
									{item.status === 'scheduled'
										&& (parentOrder?.type_id === 'M' || (parentOrder?.type_id === 'C' && !item.value))
										&& (parentOrder.controlled_drug === true || cubexPushForNonControlledDrugAllowed)
										&&
										<Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												if (item.isGhostAction) {
													modifyAction({
														visitId,
														body: {
															instructionId: item.instruction_id,
															actionId: item.action_id,
															status: 'inprogress',
															assigned_to: loggedInUserData?.user_id,
															due_at: item.due_at,
														},
													}).then((response) => {
														if ('data' in response) {
															return addCubexOrder({
																visitId,
																instructionId: item.instruction_id,
																actionId: response.data.id,
																dueAt: item.due_at,
															})
																.unwrap()
																.then(() => message.success(`${parentOrder.name} was sent to Cubex.`))
																.catch((error) => {
																	message.error(`There was an error sending the ${parentOrder.name} to Cubex`)
																}

																);
														} else {
															message.error(`There was an error sending the ${parentOrder.name} to Cubex`);
														}
													});
												} else {
													addCubexOrder({
														visitId,
														instructionId: item.instruction_id,
														actionId: item.action_id,
														dueAt: item.due_at,
													}).then((response) => {
														if ('data' in response) {
															modifyAction({
																visitId,
																body: {
																	instructionId: item.instruction_id,
																	actionId: item.action_id,
																	status: 'inprogress',
																	assigned_to: loggedInUserData?.user_id,
																	due_at: item.due_at,
																},
															})
																.unwrap()
																.then(() => message.success(`${parentOrder.name} was sent to Cubex.`))
																.catch((error) => message.error(`There was an error sending the ${parentOrder.name} to Cubex`));
														} else {
															message.error(`There was an error sending the ${parentOrder.name} to Cubex`);
														}
													});
												}
											}}
										>
											Send to Cubex
										</Menu.Item>
									}
									{item.status === 'scheduled'
										&& parentOrder?.type_id === 'D'
										&& parentOrder.category === 'WebPACS'
										&& <Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												submitXRayOrder({ instruction_id: item.instruction_id, due_at: item.due_at })
													.unwrap()
													.then(() =>
														modifyAction({
															visitId,
															body: {
																instructionId: item.instruction_id,
																actionId: item.action_id,
																status: 'inprogress',
																assigned_to: loggedInUserData?.user_id,
																due_at: item.due_at
															}
														})
															.then(() => message.success(`${parentOrder.name} was sent for X-rays.`))
													)
													.catch((error) => console.error('rejected xray order', error))
											}}>
											Send X-Ray Order
										</Menu.Item>
									}
									{item.status === 'scheduled'
										&& parentOrder !== undefined
										&& parentOrder?.type_id === 'D'
										&& (parentOrder.category === 'IDEXX' || parentOrder.category === 'IDEXX_REF_LAB')
										&& <Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												idexxOpenOrderModal(appDispatch, parentOrder, item, allInstructions, setModalState, modalForm, onModalFinish, submitIHDOrder);
											}}>
											Send Idexx Order
										</Menu.Item>
									}
									<Divider style={{ margin: '2px 0' }} />
									<Menu.Item
										onClick={(e) => {
											e.domEvent.stopPropagation();
											appDispatch(setActiveActionItem(item));
											appDispatch(setActiveActionModal('RescheduleActionModal'));
										}}
									>
										Reschedule
									</Menu.Item>
								</Menu>
							);
						}}
					>
						<Button className='reschedule-action-button' onClick={(e) => e.stopPropagation()}>
							<EllipsisOutlined key='ellipsis' />
						</Button>
					</Dropdown>
				}

				<div
					className='rct-item-content'
					style={{
						maxHeight: `${itemContext.dimensions.height}`,
					}}
				>
					{title}
				</div>

				{item.note &&
					<Tooltip title={item.note}>
						<FileTextOutlined className="note-icon" style={{ bottom: itemStyle.height < 60 ? '2px' : '4px' }}/>
					</Tooltip>
				}

			</div >

			{!!parentOrder && !!recentResults && <RecentResultsModal
				dispatch={appDispatch}
				patientName={patientName}
				isOpen={isRecentResultsModalOpen}
				setIsOpen={setIsRecentResultsModalOpen}
				order={parentOrder}
				action={item}
				recentResults={recentResults}
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				completedTime={selectedTime}
				setCompletedTime={setSelectedTime}
				modifyAction={modifyAction}
				loggedInUserData={loggedInUserData}
			/>}
		</div >
	)



};
