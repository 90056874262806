import { ExistingDiagInstruction } from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import OrderFrequency from '../OrderFrequency';
import './DiagnosticData.css';

interface IDiagnosticDataProps {
	group: ExistingDiagInstruction;
	instructionIcon: JSX.Element;
}

const DiagnosticData = ({ group, instructionIcon }: IDiagnosticDataProps) => {
	const lastActionValue = (group: ExistingDiagInstruction) => {
		const value = [...group.actions]
			.reverse()
			.find((action) => action.status === 'complete')?.value;

		if (group.result_entry.widget === 'numeric') {
			return roundTo(value, 3);
		}

		return value;
	};

	const checkForIdexxAndWebpacks = (group: ExistingDiagInstruction) => {
		return (
			group.result_entry.widget === 'webpacs' ||
			group.result_entry.widget === 'idexx'
		);
	};

	return (
		<div className='diagnostic-data'>
			{
				!checkForIdexxAndWebpacks(group) &&
				group.actions?.some((action) => action.status === 'complete') && (
					<div>
						<span className='diagnostic-data__last-value'>
							Last value:{' '}
						</span>
						{`${lastActionValue(group)} ${group?.result_entry?.unit || ''}`}
					</div>
				)
			}
			{instructionIcon}
			<OrderFrequency>{group.frequency}</OrderFrequency>
		</div>
	);
};

export default DiagnosticData;
