import { Row, Col, Alert, Button, Divider } from 'antd';
import { CustomerInfo, ExpandedPatient } from 'utils/dataTypes';
import { formatCurrency } from 'utils/formatFuncs';
import './payment_select.css';
import { EstimateInvoiceRadioValues } from './EstimateInvoiceRadio';

export interface PaymentInfoProps {
    paidCents: number;
    approvedEstimateTotalCents: number;
    estimateBalanceDue: number;
    invoiceBalanceDue: number;
    invoiceTotal: number;
    changeAmountInForm: Function;
    mode?: EstimateInvoiceRadioValues;
}

export const PaymentInfo = ({
    paidCents,
    approvedEstimateTotalCents,
    estimateBalanceDue,
    invoiceBalanceDue,
    invoiceTotal,
    changeAmountInForm,
    mode = 'estimate',
}: PaymentInfoProps) => {
    const estimate = {
        balanceDue: estimateBalanceDue,
        totalLabel: 'Estimate Total',
        totalAmount: approvedEstimateTotalCents,
        balanceLabel: 'Estimate Balance Due',
    };
    const invoice = {
        balanceDue: invoiceBalanceDue,
        totalLabel: 'Invoice Total',
        totalAmount: invoiceTotal,
        balanceLabel: invoiceBalanceDue <= 0 ? 'Invoice Balance' : 'Invoice Balance Due',
    };

    const obj = mode === 'estimate' ? estimate : invoice;

    return (
        <Row style={{ marginBottom: '2em' }}>
            <Col span={24} className='payment-info-rows' style={{ marginTop: '2em' }}>
                <Row>
                    <Col span={12}>{obj.totalLabel}</Col>
                    <Col span={12}>
                        <Button
                            type='link'
                            className='payment-info-inline-button'
                            onClick={() => {
                                changeAmountInForm(obj.totalAmount);
                            }}
                        >
                            {formatCurrency(obj.totalAmount)}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>Pre-Payments Received</Col>
                    <Col span={12}>
                        <Button
                            type='link'
                            className='payment-info-inline-button'
                            onClick={() => {
                                changeAmountInForm(paidCents);
                            }}
                        >
                            {formatCurrency((paidCents > 0) ? -paidCents : paidCents)}
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <Row className='veg-subtitle-3'>
                    <Col span={12}>
                        <strong>{obj.balanceLabel}</strong>
                    </Col>
                    <Col span={12}>
                        <Button
                            type='link'
                            className='payment-info-inline-button'
                            onClick={() => {
                                changeAmountInForm(Math.abs(obj.balanceDue));
                            }}
                        >
                            <strong>
                                {formatCurrency(Math.abs(obj.balanceDue))}
                                {obj.balanceDue < 0 && ' CR'}
                            </strong>
                        </Button>
                    </Col>
                </Row>
                {obj.balanceDue < 0 && (
                    <Row>
                        <Alert
                            type='info'
                            message={
                                <>
                                    There is a credit on the visit. Refunds can be issued by visiting the{' '}
                                    <a href='?section=billing'>Billing tab</a>.
                                </>
                            }
                        />
                    </Row>
                )}
            </Col>
        </Row>
    );
};
