import React, { createContext, useContext, useState } from 'react';
import { FormName } from 'utils/constants';
import { closedErrorMsg } from 'utils/disableFuncs';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';

const INITIAL_DRAWER_STATE: ComposeBoxState[] = [];
export interface ComposeBoxState {
    id: FormName;
    mini: boolean;
    formName: string;
    bottomBar: JSX.Element;
    slot: number;
    content: JSX.Element;
    options?: { [key: string]: any } | MedicineSearchOption;
    additionalState?: {};
}

interface ComposeBoxContextProps {
    boxState: ComposeBoxState[];
    addComposeBox: (content: any, isVisitFinalized?: boolean, finalizedOverride?: boolean) => void;
    removeComposeBox: (id: number) => void;
    toggleMiniComposeBox: (id: number) => void;
    removeCurrentComposeBox: (composeBoxId: string) => void;
}

const ComposeBoxContext = createContext<ComposeBoxContextProps>({
    boxState: INITIAL_DRAWER_STATE,
    addComposeBox: () => {},
    removeComposeBox: () => {},
    toggleMiniComposeBox: () => {},
    removeCurrentComposeBox: () => {},
});

export const useComposeBoxContext = (): ComposeBoxContextProps => {
    return useContext(ComposeBoxContext);
};

export const ComposeBoxProvider: React.FC = ({ children }) => {
    const [boxState, setBoxState] = useState(INITIAL_DRAWER_STATE);

    const addComposeBox = (composeBoxState: ComposeBoxState, isVisitFinalized?: boolean, finalizedOverride?: boolean) => {
        const content = {
            ...composeBoxState,
            id: composeBoxState.id ?? composeBoxState.formName,
        };
        if (isVisitFinalized && content.formName !== 'Owner Info' && !finalizedOverride) {
            closedErrorMsg(true);
            return;
        }

        if (boxState.length >= 4) {
            //TODO better solution than just doing nothing... for now, max boxes is 4.
            return;
        }

        if (content.formName) {
            //don't allow more than one of these boxes to exist at once
            //If new box is one of these forms, check to see if we already have one up
            if (
                content.formName === FormName['patient_info'] ||
                content.formName === FormName['owner_info'] ||
                content.formName === FormName['patient_history'] ||
                content.formName === FormName.discharge_togo_med
            ) {
                if (boxState.some((box) => box.formName === content.formName)) {
                    //If we already have one of these, don't add another of the same type
                    return;
                }
            }
        }

        let newBoxState: ComposeBoxState[];

        //If we are adding EXACTLY the 3rd box, minimize the first two
        if (boxState.length === 2) {
            newBoxState = [...boxState.map((box) => ({ ...box, mini: true }))];
        } else {
            newBoxState = [...boxState];
        }

        const newBox = { ...content, mini: false };

        newBoxState.push(newBox);

        setBoxState(newBoxState.map((box, index) => ({ ...box, slot: index })));
    };

    const removeComposeBox = (id: number) => {
        const newBoxState = [...boxState];
        newBoxState.splice(id, 1);
        setBoxState(newBoxState.map((box, index) => ({ ...box, slot: index })));
    };

    const removeCurrentComposeBox = (composeBoxId: string) => {
		const boxItem = boxState.find(box => box.id === composeBoxId);

		if (!!boxItem) {
			removeComposeBox(boxItem.slot);
		}
	}

    const toggleMiniComposeBox = (id: number) => {
        const newBoxState = [...boxState];
        newBoxState[id].mini = !boxState[id].mini;
        setBoxState(newBoxState);
    };

    return (
        <ComposeBoxContext.Provider
            value={{
                boxState,
                addComposeBox,
                removeComposeBox,
                toggleMiniComposeBox,
                removeCurrentComposeBox,
            }}
        >
            {children}
        </ComposeBoxContext.Provider>
    );
};
