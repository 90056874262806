import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import React from 'react';
import { DiagnosticEstimateItemNew, EstimateItemNew } from 'utils/types/estimateTypesNew';
import { CollapseSection } from '.';
import EstimateDiagnosticsForm from './EstimateDiagnosticsForm';

interface EstimateDiagsSectionProps {
    activeLineItem: string;
    collapseSection: CollapseSection;
    form: FormInstance<any>;
    title: string;
    setActiveLineItem: (activeLineItem: string) => void;
    setCollapseSection: () => void;
    typeId: 'IRL' | 'D';

    items?: EstimateItemNew[];
}

const EstimateDiagsSection: React.FC<EstimateDiagsSectionProps> = ({
    activeLineItem,
    collapseSection,
    form,
    items,
    title,
    typeId,
    setActiveLineItem,
    setCollapseSection,
}) => {
    return (
        <>
            <section className='grid-table__full-width-row'>
                <div className='instruction-type-section' onClick={setCollapseSection}>
                    {collapseSection[typeId] ? <DownOutlined /> : <UpOutlined />}
                    <span>{title}</span>
                </div>
            </section>

            {items?.map((diag, index) => (
                <EstimateDiagnosticsForm
                    form={form}
                    diagnostic={diag as DiagnosticEstimateItemNew}
                    index={index}
                    key={`${typeId}_${diag.name}_${index}`}
                    hiddenSectionClass={collapseSection[typeId] ? 'hidden-section' : ''}
                    setActiveLineItem={setActiveLineItem}
                    activeLineItem={activeLineItem}
                    typeId={typeId}
                />
            ))}
        </>
    );
};

export default EstimateDiagsSection;
