import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, DatePicker, Divider, Form, Input, Modal, Skeleton, message } from 'antd';
import SelectDoctor from 'components/SelectDoctor';
import { DrawerRadioGroup } from 'components/drawers';
import { HiddenInput } from 'components/forms/fields/HiddenInput';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetRabiesVaccinationFormQuery, useSaveRabiesVaccinationFormMutation } from 'services/consentService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetDoctorsInMyHospitalQuery } from 'services/userService';
import { useGetVisitByIdQuery } from 'services/visitService';
import { formatPhoneNumber, roundTo } from 'utils/formatFuncs';
import { getAgeYearsMonths } from 'utils/miscFuncs';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { Header } from '../Header';
import { ConsentFormError } from '../Result';

const RabiesVaccinationForm = () => {
    const { formId } = useParams<{ formId: string }>();

    const [finalizeForm, setFinalizeForm] = useState(false);

    const [form] = Form.useForm();

    const { data: rabiesVaccinationData, error: isErrorRabiesVaccinationData } = useGetRabiesVaccinationFormQuery(formId.split('_')[0]);
    const { data: currentVisit } = useGetVisitByIdQuery(rabiesVaccinationData?.visit_id ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });
    const { data: existingCustomer } = useGetCustomerByIdQuery(currentVisit?.customer_id ?? skipToken, { refetchOnMountOrArgChange: true });
    const { data: usersForHospital } = useGetDoctorsInMyHospitalQuery();

    const [saveRabiesVaccinationForm] = useSaveRabiesVaccinationFormMutation();

    const { ageYears, ageMonths } = getAgeYearsMonths(patientData);

    const neuteredStatus = (() => {
        if (patientData?.is_intact === true) {
            return 'Intact';
        } else if (patientData?.is_intact === false) {
            if (patientData.sex === 'M') {
                return 'Neutered';
            } else {
                return 'Spayed';
            }
        }
    })();

    const handleSaveAndFinalize = () => {
        Modal.confirm({
            title: 'Finalize Form',
            content: (
                <>
                    <p>No changes can be made once the form is finalized.</p>
                    <p>Are you sure you want to finalize the Rabies Vaccination form?</p>
                </>
            ),
            okText: 'Finalize Form',
            onOk: () => {
                setFinalizeForm(true);
                form.submit();
            },
        });
    };

    const handleSubmit = (values: any) => {
        saveRabiesVaccinationForm({
            ...values,
            vaccinated_at: moment(values.vaccinated_at).unix(),
            next_vaccination_at: moment(values.next_vaccination_at).unix(),
            finalize: finalizeForm,
        })
            .unwrap()
            .then(() => {
                if (finalizeForm) {
                    message.success('Vaccination form successfully saved and finalized');
                } else {
                    message.success('Vaccination form successfully saved');
                }
                setFinalizeForm(false);
            });
    };

    const setVetLicenseNumber = (userId: number) => {
        const vet_license_number = usersForHospital?.find((u) => u.user_id === userId)?.vet_license_number;

        form.setFieldsValue({ vet_license_number });
    };

    useEffect(() => {
        if (formId.split('_')?.[1] === 'print' && !!rabiesVaccinationData && !!currentVisit && !!patientData && !!existingCustomer) {
            window.print();
        }
    }, [rabiesVaccinationData, currentVisit, patientData, existingCustomer, formId]);

    if (isErrorRabiesVaccinationData) {
        return <ConsentFormError />;
    }

    if (!rabiesVaccinationData || !currentVisit || !patientData || !existingCustomer) {
        return <Skeleton active />;
    }

    return (
        <div className={`rabies-vaccination-container ${rabiesVaccinationData.is_approved ? 'rabies-vaccination__approved' : ''}`}>
            <Form form={form} labelAlign='left' labelCol={{ className: 'rabies-vaccination__label' }} onFinish={handleSubmit}>
                <div className='document' id='printableArea'>
                    <Header
                        title='Rabies Vaccination Certificate'
                        headerData={{ ...rabiesVaccinationData, pet_name: capitalizeFirstLetter(rabiesVaccinationData.pet_name) }}
                        formItem={
                            rabiesVaccinationData.is_approved ? (
                                <>
                                    <div className='rabies-vaccination__header-label-container'>
                                        <span>Rabies Tag # </span>
                                        <span>{rabiesVaccinationData.rabies_tag_id}</span>
                                    </div>
                                    <div className='rabies-vaccination__header-label-container'>
                                        <span>Microchip # </span>
                                        <span>{rabiesVaccinationData.pet_microchip_id}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Form.Item
                                        label='Rabies Tag #:'
                                        labelCol={{ span: rabiesVaccinationData.is_approved ? 11 : 8 }}
                                        name='rabies_tag_id'
                                        initialValue={rabiesVaccinationData.rabies_tag_id}
                                        rules={[{ required: finalizeForm, message: 'Rabies Tag is required to finalize' }]}
                                        style={{ marginBottom: 'var(--spacing-sm)' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label='Microchip #:'
                                        labelCol={{ span: rabiesVaccinationData.is_approved ? 11 : 8 }}
                                        name='pet_microchip_id'
                                        initialValue={rabiesVaccinationData.pet_microchip_id}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            )
                        }
                        hideBottomDivider
                    />

                    <Divider className='rabies-vaccination-container__divider' orientation='left'>
                        Owner Information
                    </Divider>

                    <HiddenInput fieldName='form_id' initialValue={formId.split('_')[0]} />
                    <HiddenInput fieldName='pet_id' initialValue={patientData.pet_id} />

                    <div>
                        <span className='rabies-vaccination__label'>Name: </span>
                        <span>
                            {existingCustomer.first_name} {existingCustomer.last_name}
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <span className='rabies-vaccination__label'>Address: </span>
                        <span>
                            <span>{existingCustomer.street}</span>
                            <div>
                                {existingCustomer.city}, {existingCustomer.state} {existingCustomer.zipcode}
                            </div>
                        </span>
                    </div>
                    <div>
                        <span className='rabies-vaccination__label'>Phone: </span>
                        <span>{formatPhoneNumber(existingCustomer.phone_number)}</span>
                    </div>

                    <Divider className='rabies-vaccination-container__divider' orientation='left'>
                        Pet Information
                    </Divider>

                    <div>
                        <span className='rabies-vaccination__label'>Name: </span>
                        <span>{patientData.name}</span>
                    </div>
                    <div>
                        <span className='rabies-vaccination__label'>Species: </span>
                        <span>{patientData.species}</span>
                    </div>
                    <div>
                        <span className='rabies-vaccination__label'>Age: </span>
                        <span>
                            {ageYears}y {ageMonths}m
                        </span>
                    </div>
                    <div>
                        <span className='rabies-vaccination__label'>Sex: </span>
                        <span>{[patientData.sex, neuteredStatus].filter((i) => !!i).join(', ')}</span>
                    </div>
                    <div>
                        <span className='rabies-vaccination__label'>Weight: </span>
                        <span>{roundTo(patientData.latest_weight, 3)} kg</span>
                    </div>
                    <div>
                        <span className='rabies-vaccination__label'>Breed: </span>
                        <span>{patientData.breed}</span>
                    </div>
                    {rabiesVaccinationData.is_approved ? (
                        <div>
                            <span className='rabies-vaccination__label'>Colors/Markings: </span>
                            <span>{rabiesVaccinationData.pet_color}</span>
                        </div>
                    ) : (
                        <Form.Item
                            label='Colors/Markings'
                            name='pet_color'
                            initialValue={rabiesVaccinationData.pet_color}
                            style={{ marginBottom: rabiesVaccinationData.is_approved ? 0 : '16px' }}
                            rules={[{ required: finalizeForm, message: 'Color/Markings is required to finalize' }]}
                        >
                            <Input />
                        </Form.Item>
                    )}

                    <Divider className='rabies-vaccination-container__divider' orientation='left'>
                        Vaccination Information
                    </Divider>

                    <div className='vaccination-data-container'>
                        {rabiesVaccinationData.is_approved && rabiesVaccinationData.vaccinated_at ? (
                            <div>
                                <span className='rabies-vaccination__label'>Date Vaccinated: </span>
                                <span>{moment.unix(rabiesVaccinationData.vaccinated_at).format('MM/DD/YYYY')}</span>
                            </div>
                        ) : (
                            <Form.Item
                                label='Date Vaccinated'
                                name='vaccinated_at'
                                initialValue={
                                    rabiesVaccinationData.vaccinated_at ? moment.unix(rabiesVaccinationData.vaccinated_at) : moment()
                                }
                                rules={[{ required: finalizeForm, message: 'Date Vaccinated is required to finalize' }]}
                            >
                                <DatePicker format='MM/DD/YYYY' />
                            </Form.Item>
                        )}
                        {rabiesVaccinationData.is_approved && rabiesVaccinationData.next_vaccination_at ? (
                            <div>
                                <span className='rabies-vaccination__label'>Next Vaccination Due By: </span>
                                <span>{moment.unix(rabiesVaccinationData.next_vaccination_at).format('MM/DD/YYYY')}</span>
                            </div>
                        ) : (
                            <Form.Item
                                colon={false}
                                className='white-space-wrap'
                                label='Next Vaccination Due By:'
                                name='next_vaccination_at'
                                initialValue={
                                    rabiesVaccinationData.next_vaccination_at
                                        ? moment.unix(rabiesVaccinationData.next_vaccination_at)
                                        : null
                                }
                                rules={[{ required: finalizeForm, message: 'Next Vaccination Due By is required to finalize' }]}
                            >
                                <DatePicker format='MM/DD/YYYY' />
                            </Form.Item>
                        )}
                        {rabiesVaccinationData.is_approved ? (
                            <div>
                                <span className='rabies-vaccination__label'>Product Name: </span>
                                <span>{rabiesVaccinationData.name}</span>
                            </div>
                        ) : (
                            <Form.Item
                                label='Product Name'
                                name='name'
                                initialValue={rabiesVaccinationData.name}
                                rules={[{ required: finalizeForm, message: 'Product Name is required to finalize' }]}
                            >
                                <Input />
                            </Form.Item>
                        )}
                        {rabiesVaccinationData.is_approved ? (
                            <div>
                                <span className='rabies-vaccination__label'>Manufacturer: </span>
                                <span>{rabiesVaccinationData.manufacturer_initials}</span>
                            </div>
                        ) : (
                            <Form.Item
                                colon={false}
                                className='white-space-wrap'
                                label='Manufacturer Initials:'
                                name='manufacturer_initials'
                                initialValue={rabiesVaccinationData.manufacturer_initials}
                                rules={[{ required: finalizeForm, message: 'Manufacturer Initials is required to finalize' }]}
                            >
                                <Input maxLength={3} />
                            </Form.Item>
                        )}
                        {rabiesVaccinationData.is_approved ? (
                            <div>
                                <span className='rabies-vaccination__label'>Type: </span>
                                <span>{`${rabiesVaccinationData.usda_licensed_vaccine_type} USDA Licensed Vaccine`}</span>
                            </div>
                        ) : (
                            <Form.Item
                                colon={false}
                                className='white-space-wrap'
                                label='USDA Licensed Vaccine Type:'
                                name='usda_licensed_vaccine_type'
                                initialValue={rabiesVaccinationData.usda_licensed_vaccine_type}
                                rules={[{ required: finalizeForm, message: 'USDA Licensed Vaccine Type is required to finalize' }]}
                            >
                                <DrawerRadioGroup options={['1 Yr', '3 Yr', '4 Yr']} optionsPerRow={3} />
                            </Form.Item>
                        )}
                        {rabiesVaccinationData.is_approved ? (
                            <div>
                                <span className='rabies-vaccination__label'>Dose: </span>
                                <span>{rabiesVaccinationData.dose_type}</span>
                            </div>
                        ) : (
                            <Form.Item
                                label='Dose Type'
                                name='dose_type'
                                initialValue={rabiesVaccinationData.dose_type}
                                rules={[{ required: finalizeForm, message: 'Dose Type is required to finalize' }]}
                            >
                                <DrawerRadioGroup options={['Initial Dose', 'Booster Dose']} />
                            </Form.Item>
                        )}
                        {rabiesVaccinationData.is_approved ? (
                            <div>
                                <span className='rabies-vaccination__label'>Vaccine Serial (lot) #: </span>
                                <span>{rabiesVaccinationData.vaccine_serial_id}</span>
                            </div>
                        ) : (
                            <Form.Item
                                colon={false}
                                className='white-space-wrap'
                                label='Vaccine Serial/Lot #:'
                                name='vaccine_serial_id'
                                initialValue={rabiesVaccinationData.vaccine_serial_id}
                                rules={[{ required: finalizeForm, message: 'Vaccine Serial/Lot is required to finalize' }]}
                            >
                                <Input />
                            </Form.Item>
                        )}
                    </div>

                    {rabiesVaccinationData.is_approved ? (
                        <div className='approved-form__veterinarian-data-container'>
                            <span>
                                <div>&nbsp;</div>
                                <div>{rabiesVaccinationData.veterinarian_name}</div>
                            </span>
                            <span>
                                <div>{rabiesVaccinationData.vet_license_number}</div>
                                <div>License #</div>
                            </span>
                        </div>
                    ) : (
                        <>
                            <Divider className='rabies-vaccination-container__divider' orientation='left'>
                                Veterinarian
                            </Divider>

                            <div className='veterinarian-data-container'>
                                <Form.Item
                                    label='Name'
                                    name='veterinarian_id'
                                    initialValue={rabiesVaccinationData.veterinarian_id}
                                    rules={[{ required: finalizeForm, message: 'Name is required to finalize' }]}
                                >
                                    <SelectDoctor onSelect={setVetLicenseNumber} />
                                </Form.Item>

                                <Form.Item
                                    label='License #'
                                    name='vet_license_number'
                                    initialValue={rabiesVaccinationData.vet_license_number}
                                    rules={[{ required: finalizeForm, message: 'License # is required to finalize' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                        </>
                    )}
                </div>

                <div className='rabies-vaccination__buttons'>
                    <Button onClick={() => window.close()}>Cancel</Button>
                    <Button onClick={window.print}>Print</Button>

                    {!rabiesVaccinationData.is_approved && (
                        <>
                            <Button
                                onClick={() => {
                                    form.submit();
                                    setFinalizeForm(false);
                                }}
                            >
                                Save
                            </Button>
                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldsError }) => (
                                    <Button
                                        type='primary'
                                        onClick={handleSaveAndFinalize}
                                        disabled={getFieldsError()?.some(({ errors }) => !!errors.length)}
                                    >
                                        Save and Finalize
                                    </Button>
                                )}
                            </Form.Item>
                        </>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default RabiesVaccinationForm;
