import { Card, Typography } from 'antd';
import React from 'react';
import OverviewTabStyles from './overview-tab.module.css';

interface OverviewCardProps {
    title: string;
    children?: React.ReactNode;
    extra?: React.ReactNode;
    noHeight?: boolean;
}

export const OverviewCard = React.memo(
    ({ title, children, extra, noHeight }: OverviewCardProps) => {
        let className = `${OverviewTabStyles['OverviewCard']} overview-card`;
        if (!noHeight) {
            className += ` overview-card-height`;
        }
        return (
            <Card
                className={className}
                headStyle={{ background: '#F0F5F7' }}
                title={
                    <Typography.Text className='overview-card-title '>
                        {title}
                    </Typography.Text>
                }
                extra={extra}
            >
                {children}
            </Card>
        );
    },
);
