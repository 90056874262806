import { Col } from 'antd';
import React from 'react';
import { ItemIncompleteIcon, ItemCompleteIcon } from 'utils/componentWrappers';

interface TocCardItemProps {
    clickFunction?: Function;
    checked: boolean;
    title: string;
    children?: React.ReactNode;
    dataCy?: string;
}
export const TocCardItem = React.memo((props: TocCardItemProps) => {
    const clickFunction = () => {
        if (props.clickFunction) return props.clickFunction();
        else return;
    };
    return (
        <Col span={24}>
            {!props.checked ? (
                <ItemIncompleteIcon dataCy={`${props.dataCy}Incomplete`} />
            ) : (
                <ItemCompleteIcon dataCy={`${props.dataCy}Complete`} />
            )}
            <span className='clickable-text' onClick={clickFunction} data-cy={props.dataCy}>
                {props.children || props.title}
            </span>
        </Col>
    );
});
