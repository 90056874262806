import { Form, Radio, RadioChangeEvent } from "antd";
import { Rule } from "antd/lib/form";
import { CSSProperties } from "react";

interface RadioBooleanProps {
	onFormChange: Function;
    name: string;
    label: string;
		labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
		wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    formItemStyle?: CSSProperties;
    includeUnknown?: boolean;
    invert?: boolean; // e.g. Yes -> false
    rules?: Rule[]
    initialValue?: boolean;
    required?: boolean;
		dataCy?: string;
}

export const RadioBoolean = (props: RadioBooleanProps) => {
	const onChange = (e: RadioChangeEvent) => {
		props.onFormChange(e.target.value);
	};
	return (
		<Form.Item
            preserve={false}
            name={props.name}
            label={props.label}
            style={props.formItemStyle}
            labelCol={props.labelCol}
            wrapperCol={props.wrapperCol}
            rules={props.rules ?? []}
            initialValue={props.initialValue}
            required={props.required}
						data-cy={props.dataCy}
        >
            <Radio.Group onChange={onChange}>
                <Radio data-cy="radioYes" value={props.invert ? false : true}>Yes</Radio>
                <Radio data-cy="radioNo" value={props.invert ? true : false}>No</Radio>
                {props.includeUnknown && <Radio value={null}>Unknown</Radio>}
            </Radio.Group>
        </Form.Item>
	)
};
