import {
} from 'utils/types/billingTypes';
import { Supplemental } from 'utils/types/InstructionOrderTypes';
import { instructionApi } from './instructionApi';

export const nonMedicalService = instructionApi.injectEndpoints({
	endpoints: (builder) => ({
		getSupplementalById: builder.query<Supplemental, {supplemental_id: number}>({
			query: ({supplemental_id}) => {
				return {
					url: `supplemental/${supplemental_id}`,
				}
			},
			transformResponse: (response: { data: Supplemental }) => {
				return response.data;
			},
		}),
		getSupplementalByName: builder.query<Supplemental, { name: string; controlledMedication?: boolean; }>({
			query: ({ name, controlledMedication }) => {
				return {
					url: 'supplemental/name',
					params: {
						supplemental: name,
						controlledMedication: controlledMedication ?? false
					}
				}
			},
			transformResponse: (response: { data: Supplemental }) => {
				return response.data;
			},
		}),

	}),
});

export const {
    useGetSupplementalByNameQuery,
	useLazyGetSupplementalByIdQuery,
	useLazyGetSupplementalByNameQuery
} = nonMedicalService;
