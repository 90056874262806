import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { taskApi } from 'services/taskApi';
import { authApi } from '../services/authApi';
import { billingApi } from '../services/billingApi';
import { consentApi } from '../services/consentApi';
import { instructionApi } from '../services/instructionApi';
import { pimsApi } from '../services/pimsApi';
import { treatmentSheetSlice } from './slices/treatmentSheetSlice';
import { visitHeaderSlice } from './slices/visitHeaderSlice';

export const store = configureStore({
	reducer: {
		[pimsApi.reducerPath]: pimsApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[instructionApi.reducerPath]: instructionApi.reducer,
		[billingApi.reducerPath]: billingApi.reducer,
		[consentApi.reducerPath]: consentApi.reducer,
		[taskApi.reducerPath]: taskApi.reducer,
		treatmentSheetSlice: treatmentSheetSlice.reducer,
		visitHeaderSlice: visitHeaderSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableStateInvariant: false,
			immutableStateInvariant: false,
		})
			//TODO is there a better way to do this than a bunch of `.concat()` calls??
			.concat(pimsApi.middleware)
			.concat(authApi.middleware)
			.concat(instructionApi.middleware)
			.concat(billingApi.middleware)
			.concat(consentApi.middleware)
			.concat(taskApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
