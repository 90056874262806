import { UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag } from 'antd';
import { useGetUserDataQuery } from 'services/authService';
import {
    useAssignSelfToPatientAsNurseMutation,
    useUnassignNurseFromPatientMutation,
} from 'services/vetDashService';
import { UserNameWrapper } from 'utils/componentWrappers';
import { PatientRecordVisit } from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import {
    checkIfUserHasNecessaryPermission,
    USER_PERMISSIONS,
} from 'utils/userPermissions';

interface AssignedNurseSectionProps {
    currentVisit: PatientRecordVisit;
}

export const AssignedNurseSection = ({
    currentVisit,
}: AssignedNurseSectionProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const isVisitAssignedToNurse = !!currentVisit.nurse_id;
    const [assignSelfAsNurse] = useAssignSelfToPatientAsNurseMutation();
    const [unassignNurse] = useUnassignNurseFromPatientMutation();
    const isFinalizedVisit = Boolean(currentVisit.finalized_at);

    const userHasPermissionToAssignAsNurse = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.visit_claim_as_nurse,
    );

    return (
        <Row align='middle' style={{ paddingBottom: '4px' }}>
            <Col span={12} style={{ textAlign: 'right', paddingRight: '16px' }}>
                Nurse
            </Col>
            <Col span={10}>
                {(() => {
                    if (!userHasPermissionToAssignAsNurse && !isVisitAssignedToNurse) {
                        return <span>Unassigned</span>;
                    } else {
                        if (isVisitAssignedToNurse) {
                            return (
                                <Tag
                                    style={{
                                        fontSize: '14px',
                                        margin: '5px 0',
                                    }}
                                    data-cy="assignedNurseTag"
                                    closable={userHasPermissionToAssignAsNurse}
                                    onClose={(e) => {
                                        
                                        if (!userHasPermissionToAssignAsNurse) return;

                                        e.preventDefault();
                                        if (isFinalizedVisit) {
                                            closedErrorMsg(isFinalizedVisit);
                                            return;
                                        }
                                        if (isVisitAssignedToNurse) {
                                            unassignNurse({
                                                visitId: currentVisit.id,
                                            });
                                        }
                                    }}
                                >
                                    <UserNameWrapper>
                                        {currentVisit.nurse_id}
                                    </UserNameWrapper>
                                </Tag>
                            );
                        } else {
                            return (
                                <Button
                                    icon={<UserAddOutlined />}
                                    type={'link'}
                                    style={{ padding: 0 }}
                                    disabled={isFinalizedVisit}
                                    data-cy="assignNurseButton"
                                    onClick={() => {
                                        if (isFinalizedVisit) {
                                            closedErrorMsg(isFinalizedVisit);
                                            return;
                                        }
                                        if (!isVisitAssignedToNurse) {
                                            assignSelfAsNurse({
                                                visitId: currentVisit.id,
                                            });
                                        }
                                    }}
                                >
                                    Assign to me
                                </Button>
                            );
                        }
                    }
                })()}
            </Col>
        </Row>
    );
};
