import { UpOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert, Button, Dropdown, Form, Input, Menu, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import SelectDoctor from 'components/SelectDoctor';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useGetDoctorsInMyHospitalQuery } from 'services/userService';
import { useGetVisitByIdQuery } from 'services/visitService';
import {
    useCreateWrittenPrescriptionMutation,
    useFinalizeAndPrintWrittenPrescriptionMutation,
    useGetWrittenPrescriptionsQuery,
    useUpdateWrittenPrescriptionMutation,
} from 'services/writtenPrescriptionService';

interface WrittenPrescriptionFormProps {
    composeBoxId: string;
    prescriptionId?: number;
    createCopy?: boolean;
}

const WrittenPrescriptionForm: React.FC<WrittenPrescriptionFormProps> = ({ composeBoxId, prescriptionId, createCopy }) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { removeCurrentComposeBox } = useComposeBoxContext();

    const { data: usersForHospital } = useGetDoctorsInMyHospitalQuery();
    const { data: currentVisit } = useGetVisitByIdQuery(visitId, { skip: !visitId });
    const { data: writtenPrescription, isFetching: fetchingWrittenPrescription } = useGetWrittenPrescriptionsQuery(
        prescriptionId ?? skipToken,
    );
    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id || '', {
        skip: !currentVisit?.pet_id,
    });
    const { data: customer } = useGetCustomerByIdQuery(currentVisit?.customer_id || '', {
        skip: !currentVisit?.customer_id,
    });

    const [createWrittenPrescription, { isLoading: loadingCreateWrittenPrescription }] = useCreateWrittenPrescriptionMutation();
    const [updateWrittenPrescription, { isLoading: loadingUpdateWrittenPrescription }] = useUpdateWrittenPrescriptionMutation();
    const [finalizeAndPrintWrittenPrescription, { isLoading: loadingFinalizeAndPrintWrittenPrescription }] =
        useFinalizeAndPrintWrittenPrescriptionMutation();

    const [form] = Form.useForm();

    const handleCloseComposeBox = () => {
        removeCurrentComposeBox(composeBoxId);
        form.resetFields();
    };

    const handleSubmit = (values: any, finalizeAndPrint?: boolean) => {
        if (prescriptionId && !createCopy) {
            updateWrittenPrescription({ ...values, visit_id: visitId, id: prescriptionId })
                .unwrap()
                .then(() => {
                    if (finalizeAndPrint) {
                        finalizeAndPrintWrittenPrescription({
                            visit_id: visitId,
                            id: prescriptionId,
                            petName: patientData?.name,
                            ownerLastName: customer?.last_name,
                        })
                            .unwrap()
                            .then(() => {
                                message.success(
                                    `${values.medication_name} prescription has been successfully updated, finalized and downloaded.`,
                                );
                                handleCloseComposeBox();
                            });
                    } else {
                        message.success(`${values.medication_name} prescription has been successfully updated.`);
                        handleCloseComposeBox();
                    }
                })
                .catch(() => {
                    message.error(`There was an error saving ${values.medication_name}.`);
                });
        } else {
            createWrittenPrescription({ ...values, visit_id: visitId })
                .unwrap()
                .then((response: any) => {
                    if (finalizeAndPrint) {
                        finalizeAndPrintWrittenPrescription({
                            visit_id: visitId,
                            id: response.data,
                            petName: patientData?.name,
                            ownerLastName: customer?.last_name,
                        })
                            .unwrap()
                            .then(() => {
                                message.success(
                                    `${values.medication_name} prescription has been successfully saved, finalized and downloaded.`,
                                );
                                handleCloseComposeBox();
                            });
                    } else {
                        message.success(`${values.medication_name} prescription has been successfully saved.`);
                        handleCloseComposeBox();
                    }
                })
                .catch(() => {
                    message.error(`There was an error saving ${values.medication_name}.`);
                });
        }
    };

    const setVetLicenseNumber = useCallback(
        (userId: number) => {
            const vet_license_number = usersForHospital?.find((u) => u.user_id === userId)?.vet_license_number;

            form.setFieldsValue({ vet_license_number });
        },
        [usersForHospital, form],
    );

    useEffect(() => {
        if (writtenPrescription?.prescribing_vet) {
            setVetLicenseNumber(writtenPrescription?.prescribing_vet);
        } else if (currentVisit?.doctor_id) {
            setVetLicenseNumber(currentVisit?.doctor_id);
        }
    }, [writtenPrescription, currentVisit, setVetLicenseNumber]);

    if (fetchingWrittenPrescription) {
        return null;
    }

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            className='written-prescription-form'
            labelAlign='left'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
        >
            <Form.Item
                label='Medication Name'
                name='medication_name'
                rules={[{ required: true }]}
                initialValue={writtenPrescription?.medication_name}
            >
                <Input />
            </Form.Item>

            <Form.Item label='Dose' name='dose' rules={[{ required: true }]} initialValue={writtenPrescription?.dose}>
                <Input />
            </Form.Item>

            <Form.Item label='Unit' name='unit' rules={[{ required: true }]} initialValue={writtenPrescription?.unit}>
                <Input />
            </Form.Item>

            <Form.Item label='Route' name='route' rules={[{ required: true }]} initialValue={writtenPrescription?.route}>
                <Input />
            </Form.Item>

            <Form.Item label='Frequency' name='frequency' rules={[{ required: true }]} initialValue={writtenPrescription?.frequency}>
                <Input />
            </Form.Item>

            <Form.Item label='Duration' name='duration' rules={[{ required: true }]} initialValue={writtenPrescription?.duration}>
                <Input />
            </Form.Item>

            <Form.Item label='Quantity' name='quantity' rules={[{ required: true }]} initialValue={writtenPrescription?.quantity}>
                <Input />
            </Form.Item>

            <Form.Item label='Refills' name='refills' rules={[{ required: true }]} initialValue={writtenPrescription?.refills}>
                <Input />
            </Form.Item>

            <Form.Item
                label='Instructions'
                name='instructions'
                tooltip='Include written instructions referencing at minimum dose, unit, frequency, and route.'
                rules={[{ required: true }]}
                initialValue={writtenPrescription?.instructions}
            >
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item
                label='Prescribing Vet'
                name='prescribing_vet'
                rules={[{ required: true }]}
                initialValue={writtenPrescription?.prescribing_vet ?? currentVisit?.doctor_id}
            >
                <SelectDoctor onSelect={setVetLicenseNumber} />
            </Form.Item>

            <Form.Item
                label='License Number'
                name='vet_license_number'
                rules={[{ required: true }]}
                initialValue={writtenPrescription?.vet_license_number}
            >
                <Input />
            </Form.Item>

            <Alert message='A signature is required on the print version of this prescription.' type='info' showIcon />

            <div className='ant-modal-footer'>
                <Button onClick={() => handleCloseComposeBox()}>Cancel</Button>
                <Dropdown.Button
                    type='primary'
                    disabled={
                        loadingCreateWrittenPrescription || loadingUpdateWrittenPrescription || loadingFinalizeAndPrintWrittenPrescription
                    }
                    icon={<UpOutlined style={{ fontSize: '10px' }} />}
                    placement='topRight'
                    htmlType='submit'
                    data-cy='dropdownButton'
                    overlay={
                        <Menu>
                            <Menu.Item onClick={form.submit}>Save</Menu.Item>
                            <Menu.Item
                                onClick={() => {
                                    form.validateFields().then((values) => {
                                        const finalizeAndPrint = true;

                                        handleSubmit(values, finalizeAndPrint);
                                    });
                                }}
                            >
                                Save, Finalize, and Download
                            </Menu.Item>
                        </Menu>
                    }
                >
                    Save
                </Dropdown.Button>
            </div>
        </Form>
    );
};

export default WrittenPrescriptionForm;
