import { ClockIcon } from 'assets/img/ClockIcon';
import './CPRStatusSubtitle.css';

const CPRStatusSubtitle = () => (
    <section className='CPRStatusSubtitle-container'>
        <div className='CPRStatusSubtitle__status CPRStatusSubtitle__status--stable'>
            <span>Stable</span>
        </div>
        <div className='CPRStatusSubtitle__status CPRStatusSubtitle__status--urgent'>
            <span>Urgent</span>
        </div>
        <div className='CPRStatusSubtitle__status CPRStatusSubtitle__status--critical'>
            <span>Critical</span>
        </div>
        <div className='CPRStatusSubtitle__spike-3'>
            <ClockIcon />
            <span>Spike 3</span>
        </div>
    </section>
);

export default CPRStatusSubtitle;
