import { AutoComplete, Col, Divider, Form, Input, Row, Select } from 'antd';
import { CSSProperties, useState } from 'react';
import { useGetBreedsQuery, useGetSpeciesQuery } from 'services/specieService';
import { FormWrapper } from '../../../components/forms/FormWrapper';
import { useSubmitNewPatientMutation } from '../../../services/admissionService';
import { ExpandedPatient } from '../../../utils/dataTypes';

const { Option } = Select;

const formItemStyle: CSSProperties = {
	width: '100%',
};

interface AdmissionProps {}
export const Admission = (props: AdmissionProps) => {
	return (
		<Row gutter={[16, 16]} style={{ padding: '16px' }}>
			<Col span={24}>
				<Row gutter={[16, 16]}>
					<Col span={16}>
						<h3>Patient information:</h3>
						<PatientInfoForm />
						<Divider />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

interface PatientInfoFormProps {}

export const PatientInfoForm = (props: PatientInfoFormProps) => {
	const [patientInfo, setPatientInfo] = useState({} as ExpandedPatient);
	const [isFormDirty, setIsFormDirty] = useState(false);
	const [form] = Form.useForm();

	const [submitNewPatient] = useSubmitNewPatientMutation();

	//Have to use a setState wrapper so that we can also change the form Values
	//Otherwise you can't directly "control" the value for each field
	const setPatientInfoWrapper = (patientInfo: any) => {
		setPatientInfo(patientInfo);

		form.setFieldsValue(patientInfo);
	};

	const { data: species } = useGetSpeciesQuery(null);
	const { data: breeds } = useGetBreedsQuery(null);

	const currentSpecieId = species?.find(specie => specie.name === patientInfo.species)?.id;

	const handleSpeciesChange = (_: any, option: any) => {
		if(option) {
			setPatientInfoWrapper({
				...patientInfo,
				species: option.label,
			});
		} else {
			setPatientInfoWrapper({
				...patientInfo,
				species: null,
			});
		}
	}

	const handleBreedsChange = (_: any, option: any) => {
		if(option) {
			setPatientInfoWrapper({
				...patientInfo,
				breed: option.label,
			});
		} else {
			setPatientInfoWrapper({
				...patientInfo,
				breed: null,
			});
		}
	}

	return (
		<FormWrapper
			onFinishFailed={() => {
				console.error('new patient submit FAIL');
			}}
			onFinish={(values: any) => {
				if (isFormDirty) {
					//Submit new patient ALSO creates a visit
					submitNewPatient({ newPatientData: values });
				}
			}}
			form={form}
			onValuesChange={(changedValues: any, values: any) => {
				setIsFormDirty(true);
			}}
		>
			<Form.Item
				preserve={false}
				name='name'
				label='Name'
				style={formItemStyle}
				labelCol={{ span: 5 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 10 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input
					value={patientInfo?.name}
					onChange={(e) =>
						setPatientInfoWrapper({
							...patientInfo,
							name: e.target.value,
						})
					}
				/>
			</Form.Item>
			<Form.Item
				preserve={false}
				name='species'
				label='Species'
				style={formItemStyle}
				labelCol={{ span: 5 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 10 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<AutoComplete
					options={species?.map((specie) => ({
						value: specie.id,
						label: specie.name,
					}))}
					filterOption={(inputValue, option) =>
						(option?.label as string)
							?.toUpperCase()
							.indexOf(inputValue.toUpperCase()) !== -1
					}
					style={{ width: 200 }}
					onSelect={handleSpeciesChange}
					placeholder='Search...'
					value={patientInfo.species}
				/>
			</Form.Item>
			<Form.Item
				preserve={false}
				name='breed'
				label='Breed'
				style={formItemStyle}
				labelCol={{ span: 5 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 10 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<AutoComplete
					disabled={!patientInfo.species}
					options={breeds?.filter(breed => breed.species_id === currentSpecieId)?.map((breed) => ({
							value: breed.id,
							label: breed.name,
						}))}
					filterOption={(inputValue, option) =>
						(option?.label as string)
							?.toUpperCase()
							.indexOf(inputValue.toUpperCase()) !== -1
					}
					style={{ width: 200 }}
					onSelect={handleBreedsChange}
					placeholder='Search...'
					value={patientInfo.species}
				/>
			</Form.Item>
			<Form.Item
				preserve={false}
				name='sex'
				label='Sex'
				style={formItemStyle}
				labelCol={{ span: 5 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 10 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Select
					value={patientInfo?.sex}
					onSelect={(e) =>
						setPatientInfoWrapper({
							...patientInfo,
							sex: e,
						})
					}
				>
					<Option value={'M'} key={`sex_m`}>
						Male
					</Option>
					<Option value={'F'} key={`sex_f`}>
						Female
					</Option>
				</Select>
			</Form.Item>
		</FormWrapper>
	);
};
