import { Col, Form, InputNumber, Row, message } from 'antd';
import moment from 'moment';
import { CSSProperties, useState } from 'react';
import { roundTo, weightMultiplier } from 'utils/formatFuncs';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';
import { INITIAL_MODAL_STATE } from 'views/visit/Visit';
import { FormName } from '../../utils/constants';
import { ExistingCriInstruction, ExistingFluidInstruction, ExistingOxygenTherapyInstruction, PimsUser } from '../../utils/dataTypes';
import { FormWrapper } from './FormWrapper';
import { HiddenInput } from './fields/HiddenInput';

const formItemStyle: CSSProperties = {
    width: '100%',
};

interface AdjustFluidRateFormProps {
    instruction: ExistingFluidInstruction | ExistingCriInstruction | ExistingOxygenTherapyInstruction;
    user: PimsUser;
    modalForm: any;
    onFinish: (values: any, formName: FormName) => void;
    setModalState: Function;
    patientWeight?: number;
}

export const AdjustFluidRateForm = (props: AdjustFluidRateFormProps) => {
    const { instruction, user, modalForm, onFinish, setModalState, patientWeight } = props;
    const baseDose = getDefaultDose();
    const [dose, setDose] = useState(instruction.type_id === 'C' ? baseDose : null);

    function getDefaultDose() {
        if (instruction.type_id === 'C') {
            return instruction.default_cri_unit === 'mcg/kg/min'
                ? roundTo((instruction.dose / 60) * 1000 * weightMultiplier(instruction, patientWeight), 3)
                : roundTo(instruction.dose * weightMultiplier(instruction, patientWeight), 3);
        }

        return null;
    }

    const getSuffix = () => {
        if (instruction.type_id === 'OT') {
            return getOxygenTherapyUnit(instruction.name);
        }

        return 'mL/hour';
    };

    const getLabel = () => {
        if (instruction.type_id === 'OT') {
            return instruction.name.includes('Cage') ? 'Oxygen amount' : 'Oxygen rate';
        }

        return 'Fluid rate';
    };

    return (
        <>
            <FormWrapper
                form={modalForm}
                onFinish={(values) => {
                    const now = moment().unix();
                    const dueAt = now - (now % 60);
                    const lastCompletedAction = instruction.actions
                        .filter((item) => item.status === 'complete')
                        .sort((a, b) => ((a.completed_at || 0) < (b.completed_at || 0) ? 1 : -1))[0];
                    if (dueAt <= (lastCompletedAction?.completed_at || instruction.start_time)) {
                        message.error('Please wait 15 minutes before adjusting the rate');
                    } else if (instruction.end_time && dueAt >= instruction.end_time) {
                        message.error("Rate can't be changed after end time");
                    } else {
                        const value =
                            instruction.type_id === 'OT' ? roundTo(values.quantity * 100, 0) : roundTo(values.rate_ml_per_hr * 100, 0) * 10;

                        onFinish(
                            {
                                instructionId: instruction.id,
                                assigned_to: user.user_id,
                                due_at: dueAt,
                                note: null,
                                status: 'scheduled',
                                value: value.toString(),
                            },
                            FormName.adjust_fluid_rate,
                        );
                    }
                    setModalState({
                        ...INITIAL_MODAL_STATE,
                        form: modalForm,
                    });
                    modalForm.resetFields();
                }}
                onFinishFailed={() => {}}
                bottomBar={<></>}
            >
                <>
                    <Row justify='start' align='middle' style={{ width: '100%' }} gutter={[8, 8]}>
                        <Col span={6}>{getLabel()}:</Col>
                        <Col>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value <= 0) {
                                                return Promise.reject(new Error('Value must be greater than 0'));
                                            }

                                            if (value < 0.01) {
                                                return Promise.reject('The value cannot be smaller than 0.01');
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                preserve={false}
                                name={instruction.type_id === 'OT' ? 'quantity' : 'rate_ml_per_hr'}
                                style={formItemStyle}
                                wrapperCol={{ span: 24 }}
                                initialValue={instruction.type_id === 'OT' ? roundTo(instruction.oxygen_quantity / 100, 2) : instruction.rate_ml_per_hr}
                                noStyle
                            >
                                <InputNumber
                                    autoFocus
                                    style={formItemStyle}
                                    onChange={(value) => {
                                        const newFluidRate = value || 0;
                                        if (instruction.type_id === 'C') {
                                            const patientWeight =
                                                instruction.approx_patient_weight_kg ?? instruction.latest_patient_weight_kg;
                                            if (patientWeight && patientWeight > 0) {
                                                const ratio = newFluidRate / instruction.rate_ml_per_hr;
                                                const newDose = roundTo((baseDose ?? instruction.dose) * ratio, 3);
                                                setDose(newDose);
                                            }
                                        }
                                    }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col>{getSuffix()}</Col>
                    </Row>
                    {instruction.type_id === 'C' && (
                        <Row align='middle' style={{ width: '100%', marginTop: '1em' }}>
                            <Col span={6}>Dose:</Col>
                            <Col span={18} style={{ fontWeight: 'bold' }}>
                                {dose} {instruction.default_cri_unit ?? instruction.dose_unit}
                            </Col>
                        </Row>
                    )}
                    <HiddenInput fieldName='instructionId' initialValue={instruction.id} />
                </>
            </FormWrapper>
        </>
    );
};
