import { Select, Tag } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import {
	BaseDifferential,
	Complaint,
	Differential,
} from '../../utils/dataTypes';

const { Option } = Select;

interface DifferentialMultiSelectProps {
	differentialOptionList: BaseDifferential[];
	baseComplaint: Complaint & { existingDiffs?: Differential[] };
	value?: number[]; // Used in DetailedAddMultiComplaint
	onSelect?: Function; // Used in DetailedAddMultiComplaint
	onDeselect?: Function; // Used in DetailedAddMultiComplaint
	onClear?: Function; //Used in DetailedAddMultiComplaint
	style?: CSSProperties; //Used in DetailedAddMultiComplaint
}
export const DifferentialMultiSelect = ({
	differentialOptionList,
	baseComplaint,
	value,
	onSelect,
	onDeselect,
	onClear,
	style,
}: DifferentialMultiSelectProps) => {
	const [filteredDifferentials, setFilteredDifferentials] = useState(differentialOptionList);

	let optionalProps = {};
	if (value) {
		optionalProps = { ...optionalProps, value };
	}
	if (onSelect) {
		optionalProps = { ...optionalProps, onSelect };
	}
	if (onDeselect) {
		optionalProps = { ...optionalProps, onDeselect };
	}
	if (onClear) {
		optionalProps = { ...optionalProps, onClear };
	}
	if (onClear) {
		optionalProps = { ...optionalProps, style };
	}

	useEffect(() => {
		if (!!differentialOptionList && differentialOptionList?.length > 0) {
		  	setFilteredDifferentials(differentialOptionList);
		}
	}, [differentialOptionList]);

	const handleSearch = (value: string) => {
		setFilteredDifferentials(() => {
		  	if (!value) return differentialOptionList;
	
		  	const valueRegExp = new RegExp(value, 'gi');

		  	return differentialOptionList
				?.filter(
					(comp) =>
						comp.name.match(valueRegExp) ||
						comp.alternative_name?.match(valueRegExp),
				)
				.sort((a, b) => a.name.length - b.name.length);
		})
	}

	return (
		<Select
			{...optionalProps}
			allowClear={true}
			mode='multiple'
			showSearch
			data-cy="differentialInput"
			tagRender={(props) => {
				const { label, closable, onClose } = props;
				let tagContent = label;

				//@ts-ignore
				if (props?.label?.props) {
					tagContent =
						//@ts-ignore
						props.label.props.children[0].props.children;
				}

				let className = 'diff-select-tag';

				if (baseComplaint.existingDiffs) {
					const existingDiff = baseComplaint.existingDiffs.find(
						(diff) => diff.differential_id === props.value,
					);
					if (existingDiff) {
						className = `diff-select-tag ${existingDiff.status}`;
					}
				}
				return (
					<Tag
						className={className}
						closable={closable}
						onClose={onClose}
					>
						{tagContent}
					</Tag>
				);
			}}
			onSearch={handleSearch}
			filterOption={(input, option) => {
				return (
					option?.title
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				);
			}}
		>
			{filteredDifferentials.map((differential) => {
				let optionContent;
				if (
					baseComplaint?.differentials?.some(
						(diff) => diff.id === differential.id,
					)
				) {
					optionContent = (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<div>{differential.name}</div>
							<div style={{ marginRight: '4px' }}>
								<i>Recommended</i>
							</div>
						</div>
					);
				} else {
					optionContent = differential.name;
				}
				return (
					<Option
						className='rdvm-select'
						value={differential.id}
						key={`${differential.id}_${differential.name}`}
						title={`${differential.name} ${differential.alternative_name}`}
					>
						{optionContent}
					</Option>
				);
			})}
		</Select>
	);
};
