import Icon, { DeleteOutlined, DownOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Divider, Dropdown, Menu, Modal, Popover, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as ShareIcon } from 'assets/img/share_icon.svg';
import moment from 'moment';
import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetApprovedInfoBurialFormsQuery } from 'services/consentService';
import { useGetPatientFilesQuery } from 'services/filesService';
import { useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { useGetPatientByIdQuery } from 'services/patientService';
import { useLazyDownloadMedicalRecordDocQuery } from 'services/taskService';
import {
    PrefixChar,
    useCreateConsentFormMutation,
    useDeactivateConsentFormMutation,
    useGetAllConsentDocumentsQuery,
    useGetVisitByIdQuery,
    useReplaceBurialFormMutation,
    useReplaceEstimateFormMutation,
    useReplaceEuthanasiaFormMutation,
    useSendBurialFormMutation,
    useSendEuthanasiaFormMutation,
    useSendSmsConsentFormMutation,
} from 'services/visitService';
import { ConsentDocumentsList, CustomerInfo } from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { appendRouteId, displayUser, formatPhoneNumber } from 'utils/formatFuncs';
import { checkConsentFormType, getRecentVitalsFromInstructions } from 'utils/miscFuncs';
import { BurialApprovedForm } from 'utils/types/consentFormTypes';
import { ROUTES } from 'utils/types/enums';
import DispensedMedicationConsent from './DispensedMedicationConsent';
import DownloadDocButton from './DownloadDocButton';
import SendMedicalRecordModal from './SendMedicalRecordModal';

interface DocumentsTabProps {
    visitId: number;
    customerInfo?: CustomerInfo;
    isActive: boolean;
}

interface ColumnsDataType {
    key: string;
    name: string;
    type: string;
    created_at: string;
    status: string;
    prefix_char: string;
    approvedBurialForm?: BurialApprovedForm;
}

const { confirm } = Modal;

const showConfirmSendForm = (
    confirmMutation: (visitId: number) => void,
    visitId: number,
    prefix_char: string,
    customerInfo?: CustomerInfo,
) => {
    confirm({
        title: customerInfo?.phone_number ? `Create ${checkConsentFormType(prefix_char)} Consent Form` : 'No Phone Number',
        icon: customerInfo?.phone_number ? '' : <ExclamationCircleOutlined />,
        width: 520,
        okText: 'Create Form',
        content: customerInfo?.phone_number
            ? `The ${checkConsentFormType(prefix_char)} Consent Form will be created.`
            : `This visit does not have an associated phone number to
         send a ${checkConsentFormType(prefix_char)} form. Do you want to create it anyway?`,
        onOk() {
            confirmMutation(visitId);
        },
        onCancel() {},
    });
};

const showConfirmReplaceForm = (
    replaceMutation: (id: string) => void,
    form_id: string,
    prefix_char: string,
    customerInfo?: CustomerInfo,
) => {
    confirm({
        title: customerInfo?.phone_number ? `Send ${checkConsentFormType(prefix_char)} Consent Link` : 'No Phone Number',
        icon: customerInfo?.phone_number ? '' : <ExclamationCircleOutlined />,
        okText: customerInfo?.phone_number ? 'Send' : 'OK',
        width: 520,
        content: customerInfo?.phone_number
            ? `A text message containing a link to the ${checkConsentFormType(prefix_char)} Consent 
              Form will be sent to ${displayUser(customerInfo)} at
              ${customerInfo ? formatPhoneNumber(customerInfo.phone_number) : ''}.`
            : `This visit does not have an associated phone number to send a ${checkConsentFormType(prefix_char)} form.`,
        okCancel: !!customerInfo?.phone_number,
        onOk() {
            if (customerInfo?.phone_number) {
                replaceMutation(form_id);
            }
        },
        onCancel() {},
    });
};

const showConfirmDeactivateForm = (deactivateMutation: (id: string) => void, form_id: string, prefix_char: string) => {
    confirm({
        title: `Delete ${checkConsentFormType(prefix_char)} Form`,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete it?',
        onOk() {
            deactivateMutation(form_id);
        },
        onCancel() {},
    });
};

export const DocumentsTab = ({ visitId, customerInfo, isActive }: DocumentsTabProps) => {
    const medicalRecordModalRef = useRef<ElementRef<typeof SendMedicalRecordModal>>(null);
    const [isDispensedMedicationModalOpen, setIsDispensedMedicationModalOpen] = useState(false);

    const { data: approvedBurialForms, isLoading: approvedBurialIsLoading } = useGetApprovedInfoBurialFormsQuery(visitId);

    const pollingInterval = isActive ? 60000 : 0;
    const {
        data: consentFormsList,
        isLoading: isDocumentsLoading,
        refetch: refetchConsentForms,
        isUninitialized: isDocumentsUninitialized,
    } = useGetAllConsentDocumentsQuery(visitId, { pollingInterval });
    const {
        data: currentVisit,
        refetch: refetchCurrentVisit,
        isUninitialized: isCurrentVisitUninitialized,
    } = useGetVisitByIdQuery(visitId);
    const {
        data: patientData,
        refetch: refetchPatientData,
        isUninitialized: isPatientDataUninitialized,
    } = useGetPatientByIdQuery(currentVisit?.pet_id ?? skipToken);
    const { data: existingCustomer } = useGetCustomerByIdQuery(currentVisit?.customer_id ?? '', { skip: !currentVisit?.customer_id });
    const {
        data: patientFiles,
        isLoading: isPatientFilesLoading,
        refetch: refetchPatientFiles,
        isUninitialized: isPatientFilesUninitialized,
    } = useGetPatientFilesQuery({ pet_id: currentVisit?.pet_id ?? '' });
    const [downloadMedicalRecordDoc, { isLoading: isMedicalRecordLoading }] = useLazyDownloadMedicalRecordDocQuery();
    const { data: instData, refetch: refetchInstructionData } = useGetInstructionsByVisitIdQuery({ visitId });
    const isVisitFinalized = Boolean(currentVisit?.finalized_at);
    const [createConsentForm] = useCreateConsentFormMutation();
    const [sendSmsConsentForm] = useSendSmsConsentFormMutation();

    const vitalInstructions = getRecentVitalsFromInstructions(instData);

    useEffect(() => {
        if (
            isActive &&
            !isDocumentsUninitialized &&
            !isPatientFilesUninitialized &&
            !isCurrentVisitUninitialized &&
            !isPatientDataUninitialized
        ) {
            refetchConsentForms();
            refetchPatientFiles();
            refetchCurrentVisit();
            refetchPatientData();
            refetchInstructionData();
        }
    }, [isActive]);

    const [createEuthanasiaForm] = useSendEuthanasiaFormMutation();
    const [createBurialForm] = useSendBurialFormMutation();
    const [deactivateMutation] = useDeactivateConsentFormMutation();
    const [replaceBurialForm] = useReplaceBurialFormMutation();
    const [replaceEuthanasiaForm] = useReplaceEuthanasiaFormMutation();
    const [replaceEstimateForm] = useReplaceEstimateFormMutation();

    function replaceConsentForm(formId: string) {
        if (formId[0] === 'a') {
            replaceBurialForm(formId);
            return;
        } else if (formId[0] === 'c') {
            replaceEuthanasiaForm(formId);
            return;
        } else if (formId[0] === 'b') {
            replaceEstimateForm(formId);
            return;
        } else if (formId[0] === 'f') {
            sendSmsConsentForm({ visitId, formLinkId: formId });
            return;
        }
    }

    const getFormName = (prefix_char: string) => {
        if (prefix_char === 'd') {
            return 'Self Check In Form';
        }
        if (prefix_char === 'g') {
            return 'Rabies Vaccination Form';
        }

        return `${checkConsentFormType(prefix_char)} Consent Form`;
    };

    const rowFormattedData = useMemo(
        () =>
            consentFormsList
                ?.filter((consentDocument: ConsentDocumentsList) => consentDocument.status !== 'deactivated')
                .map((consentDocument: ConsentDocumentsList) => {
                    let approvedBurialForm;
                    if (approvedBurialForms?.some((burialForm) => burialForm.id === consentDocument.id) && !approvedBurialIsLoading) {
                        approvedBurialForm = approvedBurialForms?.find((burialForm) => burialForm.id === consentDocument.id);
                    }
                    return {
                        ...consentDocument,
                        approvedBurialForm,
                    };
                })
                .map(({ status, id, created_at, prefix_char, approvedBurialForm }: ConsentDocumentsList) => ({
                    key: id,
                    prefix_char,
                    name: getFormName(prefix_char),
                    type: 'PDF',
                    created_at: moment.unix(created_at).format('L, h:mma'),
                    status: status.charAt(0).toUpperCase() + status.slice(1),
                    approvedBurialForm,
                })),
        [approvedBurialForms, approvedBurialIsLoading, consentFormsList],
    );

    const otherDocs = ['noshow', 'enroute'].includes(currentVisit?.status ?? '')
        ? []
        : [
              {
                  key: `${visitId}_discharge_summary`,
                  prefix_char: 'ds',
                  name: 'Discharge Summary',
                  type: 'PDF',
                  created_at: '-',
                  status: isVisitFinalized || ['discharged', 'checked_out'].includes(currentVisit?.status ?? '') ? 'Final' : 'Draft',
              },
          ];

    const getPatientFilesFormatted = () => {
        return !!patientFiles
            ? patientFiles?.map((file) => {
                  const pathSplit = file.s3_path.split('/');
                  const fileName = pathSplit[pathSplit.length - 1];
                  const fileType = fileName.split('.')[1].toLocaleUpperCase();

                  return {
                      key: `${file.created_at}_${file.s3_file_id}`,
                      prefix_char: 'pf',
                      name: fileName,
                      type: fileType,
                      created_at: moment.unix(file.created_at).format('L, h:mma'),
                      status: '-',
                  };
              })
            : [];
    };

    const getTooltipTitle = (prefix_char: string) => {
        if (prefix_char === 'pf') {
            return 'Patient record documents must be deleted from the Patient profile.';
        }

        return 'Delete';
    };

    const isConsentForm = (prefix_char: string) => prefix_char !== 'mr' && prefix_char !== 'ds' && prefix_char !== 'pf';
    const canDeleteFile = (prefix_char: string) =>
        prefix_char !== 'mr' && // medical record
        prefix_char !== 'ds' && // discharge summary
        prefix_char !== 'pf' && // patient files
        prefix_char !== 'b'; // estimate files

    const getConsentFormsButtonsColor = (prefix_char: string, status?: string) => {
        if (status === 'Approved') {
            return 'var(--gray-4)';
        }

        return isConsentForm(prefix_char) ? 'var(--gray-7)' : 'var(--gray-4)';
    };

    const getDeleteButtonColor = (prefix_char: string, status?: string) =>
        canDeleteFile(prefix_char) && status !== 'Approved' ? 'var(--gray-7)' : 'var(--gray-4)';

    const getRequiredFieldsForVaccinationForm = () => {
        const fields = {
            'Customer Name': existingCustomer?.first_name,
            'Customer Address': existingCustomer?.street && existingCustomer?.city && existingCustomer?.state && existingCustomer?.zipcode,
            'Customer Phone': existingCustomer?.phone_number,
            'Patient Age': patientData?.birthday,
            'Patient Weight': vitalInstructions?.find((vital) => vital.name === 'Weight')?.lastAction,
            'Patient Species': patientData?.species,
            'Patient Breed': patientData?.breed,
            'Patient Sex': patientData?.sex,
        };
        const missingFields = [];

        for (let [key, value] of Object.entries(fields)) {
            if (!value) {
                missingFields.push(key);
            }
        }

        return missingFields;
    };

    const handleCreateVaccinationForm = () => {
        const missingRequiredFields = getRequiredFieldsForVaccinationForm();

        if (missingRequiredFields.length !== 0) {
            Modal.warning({
                title: 'Missing Information',
                content: (
                    <>
                        <p>Please complete the contact and patient details prior to creating the Rabies Vaccination Form:</p>
                        <ul>
                            {missingRequiredFields.map((missingField) => (
                                <li>{missingField}</li>
                            ))}
                        </ul>
                    </>
                ),
            });
        } else {
            createConsentForm({ prefixChar: PrefixChar.RABIES_VACCINATION_FORM, visitId })
                .unwrap()
                .then(() => message.success('Rabies vaccination form has been successfully added.'));
        }
    };

    const columns: ColumnsType<ColumnsDataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 280,
            render: (_, record) => {
                if (record.prefix_char === 'pf') {
                    return (
                        <>
                            <div>{record.name}</div>
                            <div style={{ color: 'var(--gray-7)' }}>Patient Record</div>
                        </>
                    );
                }

                return record.name;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 109,
        },
        {
            title: 'Last Updated',
            dataIndex: 'created_at',
            width: 210,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 109,
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <Space size='middle'>
                    {
                        <>
                            <Tooltip title='View'>
                                <Button
                                    style={{ color: getConsentFormsButtonsColor(record.prefix_char) }}
                                    type='text'
                                    disabled={!isConsentForm(record.prefix_char)}
                                    onClick={() => {
                                        if (record.prefix_char === 'b' && record.status === 'Approved') {
                                            window.open(`/printEstimate/${visitId}/${record.key}`);
                                        } else if (record.prefix_char === 'f' && record.status === 'Approved') {
                                            window.open(`/print/${record.key}/approved`);
                                        } else if (record.prefix_char === 'a' && record.status === 'Approved') {
                                            window.open(`/print/${record.key}/approved`);
                                        } else if (record.prefix_char === 'c' && record.status === 'Approved') {
                                            window.open(`/print/${record.key}/approved`);
                                        } else if (record.prefix_char === 'g') {
                                            window.open(`/vaccination_form/${record.key}`);
                                        } else {
                                            window.open(appendRouteId(ROUTES.consentForms, String(record.key)));
                                        }
                                    }}
                                    icon={<EyeOutlined />}
                                />
                            </Tooltip>
                            <Tooltip title='Share'>
                                <Button
                                    style={{
                                        color:
                                            record.prefix_char === 'g'
                                                ? 'var(--gray-4)'
                                                : getConsentFormsButtonsColor(record.prefix_char, record.status),
                                    }}
                                    type='text'
                                    disabled={
                                        !isConsentForm(record.prefix_char) || record.prefix_char === 'g' || record.status === 'Approved'
                                    }
                                    onClick={() =>
                                        isVisitFinalized
                                            ? closedErrorMsg(isVisitFinalized)
                                            : showConfirmReplaceForm(replaceConsentForm, record.key, record.prefix_char, customerInfo)
                                    }
                                    icon={<Icon component={ShareIcon} />}
                                />
                            </Tooltip>

                            <DownloadDocButton
                                prefix_char={record.prefix_char}
                                doc_status={record.status}
                                visitId={visitId}
                                record_key={record.key}
                            />

                            <Tooltip title={getTooltipTitle(record.prefix_char)}>
                                <Button
                                    style={{ color: getDeleteButtonColor(record.prefix_char, record.status) }}
                                    type='text'
                                    disabled={
                                        !canDeleteFile(record.prefix_char) || record.status === 'Approved' || record.status === 'Finalized'
                                    }
                                    onClick={() => {
                                        if (isVisitFinalized) {
                                            closedErrorMsg(isVisitFinalized);
                                        } else {
                                            showConfirmDeactivateForm(deactivateMutation, record.key, record.prefix_char);
                                        }
                                    }}
                                    icon={<DeleteOutlined />}
                                />
                            </Tooltip>
                        </>
                    }
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Row justify='start' style={{ marginBottom: 'var(--spacing-sm)' }}>
                <Col span={24}>
                    <Row justify='space-between' style={{ marginBottom: 'var(--spacing-lg)' }}>
                        <Col>
                            <Typography.Text
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    lineHeight: '28px',
                                }}
                            >
                                Documents
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Tooltip
                                title={
                                    !existingCustomer?.email && !currentVisit?.rdvm_id ? 'No email on record for rDVM or customer.' : null
                                }
                            >
                                <Dropdown.Button
                                    disabled={!existingCustomer?.email && !currentVisit?.rdvm_id}
                                    onClick={() => medicalRecordModalRef?.current?.openModal()}
                                    overlay={
                                        <Menu>
                                            <Menu.Item
                                                onClick={() =>
                                                    currentVisit &&
                                                    downloadMedicalRecordDoc({
                                                        visitId: currentVisit.id,
                                                        isDraft:
                                                            isVisitFinalized || ['discharged', 'checked_out'].includes(currentVisit.status)
                                                                ? false
                                                                : true,
                                                    })
                                                }
                                            >
                                                Download Medical Record
                                            </Menu.Item>
                                        </Menu>
                                    }
                                    buttonsRender={([leftButton, rightButton]) => [
                                        leftButton,
                                        React.cloneElement(rightButton as React.ReactElement<any, string>, {
                                            loading: isMedicalRecordLoading,
                                        }),
                                    ]}
                                >
                                    Share Medical Record
                                </Dropdown.Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() =>
                                        isVisitFinalized
                                            ? closedErrorMsg(isVisitFinalized)
                                            : showConfirmSendForm(createBurialForm, visitId, 'a', customerInfo)
                                    }
                                >
                                    Cremation Consent Form
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() =>
                                        isVisitFinalized
                                            ? closedErrorMsg(isVisitFinalized)
                                            : showConfirmSendForm(createEuthanasiaForm, visitId, 'c', customerInfo)
                                    }
                                >
                                    Euthanasia Consent Form
                                </Menu.Item>

                                <Divider style={{ margin: 'var(--spacing-sm) 0' }} />

                                <Menu.Item
                                    onClick={() => {
                                        if (!!existingCustomer?.phone_number) {
                                            setIsDispensedMedicationModalOpen(true);
                                        } else {
                                            createConsentForm({ prefixChar: PrefixChar.MEDICATION_CONSENT, visitId })
                                                .unwrap()
                                                .then(() => message.success('Dispensed Medication Consent has been successfully added.'));
                                        }
                                    }}
                                >
                                    Dispensed Medication Consent Form
                                </Menu.Item>
                                <Menu.Item onClick={handleCreateVaccinationForm}>Rabies Vaccination Form</Menu.Item>
                            </Menu>
                        }
                        placement='bottomLeft'
                    >
                        <Button>
                            <PlusOutlined /> Document
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>

            <Col span={24}>
                <Table
                    columns={columns}
                    loading={isDocumentsLoading || isPatientFilesLoading}
                    dataSource={
                        isDocumentsLoading || isPatientFilesLoading
                            ? []
                            : [...(rowFormattedData ?? []), ...otherDocs, ...getPatientFilesFormatted()]
                    }
                    pagination={false}
                />
            </Col>

            <SendMedicalRecordModal ref={medicalRecordModalRef} />
            <DispensedMedicationConsent isOpen={isDispensedMedicationModalOpen} setIsOpen={setIsDispensedMedicationModalOpen} />
        </div>
    );
};
