import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalFuncProps } from 'antd';
import confirm from 'antd/lib/modal/confirm';

interface RequiredProps {
    title: string;
    content: React.ReactNode;
    onOk?: (...args: any[]) => any;
}

const warningConfirm = (requiredProps: RequiredProps, props?: ModalFuncProps) => {
    return confirm({
        icon: <ExclamationCircleOutlined style={{ color: 'var(--veg-red)' }} />,
        okType: 'danger',
        okButtonProps: {
            type: 'primary',
            style: { backgroundColor: 'var(--veg-red)' },
        },
        ...requiredProps,
        ...props,
    });
};

export { warningConfirm };
