import { Col, Row } from 'antd';
import {
	PatientRecordVisit,
	VisitVital,
} from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';


interface PatientSectionProps {
	currentVisit: PatientRecordVisit;
	vitals: VisitVital[];
}

export const PatientSection = ({
	vitals,
}: PatientSectionProps) => {
	
	return (
		<>
			<Row align='middle' justify='end' style={{position: "relative", color: "var(--gray-8)"}}>
				<Col span={24}>
					<SidebarPatientVitals vitals={vitals} />
				</Col>
			</Row>
		</>
	);
};

interface SidebarPatientVitalsProps {
	vitals: VisitVital[];
}
const SidebarPatientVitals = ({ vitals }: SidebarPatientVitalsProps) => {
	const getFormattedValue = (value: string | number | true) => {
		if (typeof value === 'number') {
			return roundTo(value, 3);
		}
		
		return value;
	}

	return (
		<>
			{vitals?.length > 0 && (
				<div>
					{vitals.map((vital, index) => {
						return vital.lastAction?.value ? (
							<Row gutter={[8, 4]} key={`vital-sidebar-${index}`}>
								<Col md={{ span: 24, offset: 0 }}  lg={{ span: 10, offset: 0 }}>
									<span className='sidebar-vital-name'>
										{vital.name + ": "}
									</span>
								</Col>
								<Col md={{ span: 24, offset: 0 }}  lg={{ span: 14, offset: 0 }} data-cy={`sidebarVitalValue-${vital.name}`}>
									{getFormattedValue(vital.lastAction?.value)} {vital.unit}
								</Col>
							</Row>
						) : (
							null
						);
					})}
				</div>
			)}
		</>
	);
};
