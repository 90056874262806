import { FormName } from 'utils/constants';
import { ComposeBox } from './ComposeBox';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';

interface ComposeBoxContainerProps {
	onFinish: (values: any, formName: FormName, additionalState?: {}) => Promise<any>;
}

export const ComposeBoxContainer = ({
	onFinish,
}: ComposeBoxContainerProps) => {
	const {boxState, removeComposeBox, toggleMiniComposeBox} = useComposeBoxContext();
	
	return (
		<>
			{boxState.map((composeBox, index) => {
				return (
					<ComposeBox
						key={`compose_box_${index}`}
						{...composeBox}
						toggleMiniComposeBox={toggleMiniComposeBox}
						removeComposeBox={removeComposeBox}
						onFinish={onFinish}
					/>
				);
			})}
		</>
	)
};
