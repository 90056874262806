import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd';
import { stringToHslColor, getInitials } from 'utils/formatFuncs';
import './avatar.css';

interface AvatarProps extends AntAvatarProps {
	content?: string;
	firstName?: string;
	lastName?: string;
	saturation?: number;
	lightness?: number;
	className?: string;
	marginRight?: string;
}

const Avatar: React.FC<AvatarProps> = ({
	content,
	size = 'default',
	saturation = 60,
	lightness = 60,
	firstName,
	lastName,
	className,
	marginRight = '1rem',
	...rest
}) => {
	const initials = firstName
		? getInitials(firstName).toUpperCase() + getInitials(lastName ?? '').toUpperCase()
		: getInitials(content ?? '').toUpperCase();

	return (
		<AntAvatar
			style={{
				backgroundColor: stringToHslColor(
					firstName
						? firstName + lastName ?? ''
						: content ?? '',
					saturation,
					lightness,
				),
				verticalAlign: 'middle',
				marginRight: marginRight,
				fontSize: size === 'small' ? '12px' : '14px',
			}}
			className={className}
			size={size}
			gap={1}
			{...rest}
		>
			<div>
				{initials}
			</div>
		</AntAvatar>
	);
};

export default Avatar;
