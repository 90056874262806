import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip, Typography, message } from 'antd';
import NoChargeIcon from 'assets/img/NoChargeIcon';
import { useEffect } from 'react';
import { useRefundBillingItemMutation } from 'services/billingService';
import { BillingItem } from 'utils/types/billingTypes';

interface MarkItemAsFreeProps {
    selected: number[];
    billingItems: BillingItem[];
    setSelected: (array: []) => void;
    visitId: number;
}

export const MarkItemAsFree = ({ selected, billingItems, setSelected, visitId }: MarkItemAsFreeProps) => {
    const [refundBillingItem, { isSuccess, isError }] = useRefundBillingItemMutation();

    useEffect(() => {
        if (isSuccess) {
            message.success('Orders marked as free');
        } else if (isError) {
            message.warning('there was a problem unable to mark item as free');
        }
    }, [isSuccess, isError]);


    const filteredBillingItems = billingItems.filter((billingItem) =>
        billingItem.earned_revenue_line_items.some((er) => selected.includes(er.id) && !er.is_comped),
    );


    const billingItemsNames = filteredBillingItems.reduce((billingItemString, billingItem, index) => {
        if (index === 0) {
            return  <Typography.Text strong> {billingItem.name}</Typography.Text>;
        } else if (index === filteredBillingItems.length - 1) {
            return  <>{billingItemString}, and <Typography.Text strong>{billingItem.name}</Typography.Text></>;
        } else {
            return <>{billingItemString}, <Typography.Text strong>{billingItem.name}</Typography.Text></>;
        }
    }, <></>);

    const openModal = () => {
        if (filteredBillingItems.length > 0){
            const modal = Modal.confirm({
                icon: (
                    <ExclamationCircleOutlined
                        style={{
                            color: '#E7202E',
                        }}
                    />
                ),
                title: 'This change cannot be undone!',
                autoFocusButton: 'ok',
                okButtonProps: {
                    danger: true,
                },
                okText: 'Mark as No Charge',
                content: (
                    <>
                        <Typography.Paragraph>
                            This service and any related costs will appear on the invoice but provided free of charge.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            Are you sure you want to mark {billingItemsNames} as ‘No Charge’ ?
                        </Typography.Paragraph>
                    </>
                ),
                onCancel: () => {
                    setSelected([]);
                    modal.destroy();
                },
                onOk: () => {
                    refundBillingItem({
                        visitId: visitId,
                        body: {
                            earned_revenue_ids: selected,
                        },
                    });
    
                    setSelected([]);
                    modal.destroy();
                },
            });
    
        } else {
            const modal = Modal.confirm({
                title: 'No charges!',
                autoFocusButton: 'ok',
                content: (
                    <>
                        <Typography.Paragraph>
                            The order(s) selected cannot be marked as no charge because they are already provided to the customer at no cost.
                        </Typography.Paragraph>
                    </>
                ),
                onCancel: () => {
                    setSelected([]);
                    modal.destroy();
                },
                onOk: () => {    
                    setSelected([]);
                    modal.destroy();
                },
            });
   
        }
    };

    return (
        <Tooltip title='Mark as No Charge'>
            <Button
                disabled={selected.length === 0}
                data-cy={'markItemAsFreeButton'}
                style={{ marginLeft: 'var(--spacing-sm)' }}
                onClick={() => {
                    openModal();
                }}
                icon={<NoChargeIcon />}
            />
        </Tooltip>
    );
};
