import {
	InstructionAction,
	instructionType,
	isInstanceOfExistingFluidInstruction,
	isInstanceOfExistingCriInstruction,
	isInstanceOfExistingOxygenTherapyInstruction,
} from 'utils/dataTypes';


// TODO: Handle the case of changing frequencies
export const insertGhostActionsIntoRealActions = (
	instruction: instructionType,
	windowStart: number,
	windowEnd: number,
	hideDiscontinued?: boolean
): InstructionAction[] => {
	const { actions: realActions } = instruction;

	if (hideDiscontinued && !!instruction.discontinued_at) return [...realActions];

	const dueTimes = generateDueTimes(instruction, windowStart, windowEnd);

	const ghostActions = generateGhostActions(dueTimes, realActions, instruction.id, instruction.created_at, instruction.created_by);
	
	return [...ghostActions, ...realActions];
};

// TODO: Handle the case of changing frequencies
export const calculateNumberOfTotalActions = (
	start: number,
	end: number,
	windowStart: number,
	windowEnd: number,
	frequency?: string,
): number => {
	const instructionStart = start - (start % 60); // ensure that the startTime is even to the minute
	let dueTimes: number[];
	if (!frequency) { // case 1 continuous infusions
		// TODO implement calculations to determine interval in continuos case 
		return 1;
	} else { // case 2 discrete actions
		if (frequency.toLowerCase() === "once"){ // special case - frequency has an undefined frequency so we must handle it before
			return 1;
		}else {
			const interval = getInterval(frequency) * 60 * 60;
			const startTime = calcStartTimeExclusive(instructionStart, windowStart, interval)
			const endTime = Math.min(
				end || windowEnd, // In case the instruction has no end time
				windowEnd,
			);

			dueTimes = populateDueTimes(
				startTime,
				endTime,
				interval,
			);
	
		}
	}
	return dueTimes.length;
};


// generates the time between actions in hours
export const getInterval = (frequency: string): number => {
	const hourlyRegex = /^q([1-9][0-9]*)(?:h|hr|hour)$/i;
	const dailyRegex = /^q([1-9][0-9]*)(?:d|day)$/i;
	const predefinedFrequenciesHours: { [frequency: string]: number } = {
		qqh: 4,
		qid: 6,
		tid: 8,
		bid: 12,
		qd: 24,
		sid: 24,
		qod: 48,
	};
	const hourlyMatches = frequency.match(hourlyRegex);
	const dailyMatches = frequency.match(dailyRegex);
	let timeBetweenHours: number | undefined;
	if (hourlyMatches) {
		timeBetweenHours = parseInt(hourlyMatches[1]);
	} else if (dailyMatches) {
		timeBetweenHours = parseInt(dailyMatches[1]) * 24;
	} else if (predefinedFrequenciesHours[frequency])  {
		timeBetweenHours = predefinedFrequenciesHours[frequency];
	} else {
		throw new Error(`Frequency doesn't match any patterns, ${frequency}`)
	}
	return timeBetweenHours;
};

export const isFrequencyValid = (frequency: string): boolean => {
    const hourlyRegex = /^q([1-9][0-9]*)(?:h|hr|hour)$/i;
	const dailyRegex = /^q([1-9][0-9]*)(?:d|day)$/i;
	const predefinedFrequenciesHours: { [frequency: string]: number } = {
        once: 1,
		qqh: 4,
		qid: 6,
		tid: 8,
		bid: 12,
		qd: 24,
		sid: 24,
		qod: 48,
	};
	const hourlyMatches = frequency.match(hourlyRegex);
	const dailyMatches = frequency.match(dailyRegex);
	if (hourlyMatches || dailyMatches || predefinedFrequenciesHours[frequency]) {
		return true
	} else {
		return false;
	}

}

const calcStartTime = (
	instructionStart: number,
	windowStart: number,
	interval: number,
): number => {
	if (instructionStart >= windowStart) {
		return instructionStart;
	} else {
		const numberOfActionsSkipped = Math.floor(
			(windowStart - instructionStart) / interval,
		);
		return instructionStart + numberOfActionsSkipped * interval;
	}
};

const calcStartTimeExclusive = (
	instructionStart: number,
	windowStart: number,
	interval: number,
): number => {
	if (instructionStart >= windowStart) {
		return instructionStart;
	} else {
		const numberOfActionsSkipped = Math.ceil(
			(windowStart - instructionStart) / interval,
		);
		return instructionStart + numberOfActionsSkipped * interval;
	}
};

const doesActionExist = (
	dueTime: number,
	actions: InstructionAction[],
): boolean => {
	return actions.findIndex((action) => action.due_at === dueTime) !== -1;
};

const populateDueTimes = (
	startTime: number,
	endTime: number,
	interval: number,
): number[] => {

	const dueTimes: number[] = [];
	for (let dueAt = startTime; dueAt <= endTime; dueAt += interval) {
			dueTimes.push(dueAt);
	}
	return dueTimes;
};

const generateDueTimes = (
	instruction: instructionType,
	windowStart: number | null,
	windowEnd: number,
): number[] => {
	const {start_time, end_time, started_at} = instruction;
	const startTime = started_at ?? start_time;
	const instructionStart = startTime - (startTime % 60); // ensure that the startTime is even to the minute

	let dueTimes: number[];
	if (
		isInstanceOfExistingCriInstruction(instruction) ||
		isInstanceOfExistingFluidInstruction(instruction) ||
		isInstanceOfExistingOxygenTherapyInstruction(instruction)
	) { // case 1 continuous infusions
		// TODO implement calculations to determine interval in continuos case 
		dueTimes = [instructionStart] 
	} else { // case 2 discrete actions
		if (!instruction.frequency || instruction.frequency?.toLowerCase() === "once"){ // special case - frequency has an undefined frequency so we must handle it before
			dueTimes = [instructionStart]
		} else {
			const interval = getInterval(instruction.frequency) * 60 * 60;
			const startTime = windowStart ? calcStartTime(instructionStart, windowStart, interval) : instructionStart;
			const endTime = Math.min(
				end_time || windowEnd, // In case the instruction has no end time
				windowEnd,
			);
			dueTimes = populateDueTimes(
				startTime,
				endTime,
				interval,
			);
	
		}
	}
	return dueTimes;
}

const generateGhostActions = (
	dueTimes: number[],
	realActions:  InstructionAction[],
	instructionId: number,
	instructionCreatedAt: number,
	instructionCreatedBy: number
) => {
	const ghostActions: InstructionAction[] = [];
	const ghostActionIds = new Set<number>();
	for (const dueTime of dueTimes) {
		if (!doesActionExist(dueTime, realActions)) {
			let id = Math.floor(Math.random() * 1000000000);
			while (ghostActionIds.has(id)) {
				id = Math.floor(Math.random() * 1000000000);
			}
			ghostActionIds.add(id);
			const ghostAction: InstructionAction = {
				id, // any unique integer will do
				instruction_id: instructionId,
				due_at: dueTime,
				status: 'scheduled',
				assigned_to: null,
				status_transition_at: instructionCreatedAt,
				status_transition_by: instructionCreatedBy,
				value: null,
				note: '',
				isGhostAction: true
			};
			ghostActions.push(ghostAction);
		}
	}
	return ghostActions;
};

export const hasOverdueActions = (
	instruction: instructionType,
	presentTime: number,
): boolean => {
	const {actions: realActions} = instruction;

	const hasOverdueRealActions = realActions.filter(action => (action.scheduled_time ?? action.due_at) < presentTime)
		.some(action => action.status !== 'complete' && action.status !== 'skipped' && action.status !== 'missed');

	if (hasOverdueRealActions) {
		return true;
	}

	const dueTimes = generateDueTimes(instruction, null, presentTime);

	return dueTimes.some(dueTime => !doesActionExist(dueTime, realActions))
};
