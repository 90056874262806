import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setFluidAdditiveData, setIsFluidAdditiveModalVisible } from 'store/slices/treatmentSheetSlice';
import { getFullAdditiveName } from 'utils/formatFuncs';

const FluidAdditiveModal = () => {
    const { isFluidAdditiveModalVisible, fluidAdditiveData } = useAppSelector(({ treatmentSheetSlice }) => treatmentSheetSlice);
    const appDispatch = useAppDispatch();
    const closeModal = () => {
        appDispatch(setIsFluidAdditiveModalVisible(false));
        appDispatch(setFluidAdditiveData(undefined));
    };

    return (
        <Modal
            className='bonus-action-modal'
            style={{
                maxWidth: '700px',
                minWidth: '520px',
            }}
            centered
            okButtonProps={{ autoFocus: true }}
            cancelButtonProps={{ style: { display: 'none' } }}
            destroyOnClose
            title='Additives'
            open={isFluidAdditiveModalVisible}
            onCancel={closeModal}
            onOk={closeModal}
            okText='Ok'
        >
            {fluidAdditiveData?.map((additive) => {
                return (
                    <Row style={{ color: additive.discontinued_at ? 'var(--gray-6)' : 'inherit', padding: '4px 0' }}>
                        <Col span={2}>
                            {additive.discontinued_at ? (
                                ''
                            ) : (
                                <CheckCircleFilled style={{ fontSize: '24px', color: 'var(--green-alert-success)' }} />
                            )}
                        </Col>
                        <Col span={10}>{getFullAdditiveName(additive)}</Col>
                        <Col span={6}>{additive.dose_qty}</Col>
                        <Col span={6} style={{ color: 'var(--gray-6)' }}>
                            {additive.discontinued_at ? 'Discontinued' : ''}
                        </Col>
                    </Row>
                );
            })}
        </Modal>
    );
};

export default FluidAdditiveModal;
