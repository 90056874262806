import { FormInstance } from 'antd';
import { FormName, MAX_FLUID_ADDITIVES } from 'utils/constants';
import { ExistingFluidInstruction } from 'utils/dataTypes';
import { INITIAL_MODAL_STATE } from '../Visit';
import { FluidAdditiveForm } from 'components/forms/FluidAdditiveForm';

export const generateFluidAdditiveModalState = (group: ExistingFluidInstruction, modalForm: FormInstance<any>, setModalstate: Function) => {
    const maxAdditives = MAX_FLUID_ADDITIVES - group.fluid_additives.length;

    const onCancel = () => {
        setModalstate({
            ...INITIAL_MODAL_STATE,
            form: modalForm,
        });
        modalForm.resetFields();
    };

    const onFinish = () => {
        if (maxAdditives <= 0) {
            onCancel();
        } else {
            modalForm.submit();
        }
    };

    return {
        isVisible: true,
        okText: maxAdditives <= 0 ? 'Ok' : 'Save',
        okButtonProps: maxAdditives <= 0 ? { autoFocus: true } : undefined,
        cancelButtonProps: maxAdditives <= 0 ? { hidden: true } : undefined,
        title: group.name + ': Additive',
        width: 550,
        content: <FluidAdditiveForm instruction={group} modalForm={modalForm} setModalState={setModalstate} />,
        formName: FormName.fluid_additive_form,
        form: modalForm,
        onFinish: onFinish,
        onCancel: onCancel,
    };
};
