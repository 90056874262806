import { downloadFile } from 'utils/triggerFuncs';
import { pimsApi } from './pimsApi';

interface WrittenPrescription {
    id: number;
    medication_name: string;
    dose: string;
    unit: string;
    route: string;
    frequency: string;
    duration: string;
    quantity: string;
    refills: string;
    instructions: string;
    prescribing_vet: number;
    vet_license_number: string;
    status: 'draft' | 'finalized' | 'void';
    voided_at: number | null;
}

interface CreateWrittenPrescription extends Omit<WrittenPrescription, 'id' | 'voided_at'> {
    visit_id: number;
}

interface UpdateWrittenPrescription extends Omit<WrittenPrescription, 'voided_at'> {
    visit_id: number;
}

const writtenPrescriptionApi = pimsApi.injectEndpoints({
    endpoints: (builder) => ({
        createWrittenPrescription: builder.mutation<void, CreateWrittenPrescription>({
            query: ({ visit_id, ...body }) => ({ url: `/written_prescription/${visit_id}`, method: 'POST', body }),
            invalidatesTags: (result, error, { visit_id }) => [
                { type: 'WrittenPrescription', id: visit_id },
                { type: 'UserForHospital', id: 'List' },
            ],
        }),
        listWrittenPrescriptions: builder.query<WrittenPrescription[], number>({
            query: (visit_id) => `/list_written_prescription/${visit_id}`,
            providesTags: (result, error, visit_id) => [{ type: 'WrittenPrescription', id: visit_id }],
            transformResponse: (response: { data: WrittenPrescription[] }) => {
                return response.data;
            },
        }),
        getWrittenPrescriptions: builder.query<WrittenPrescription, number>({
            query: (id) => `/written_prescription/${id}`,
            transformResponse: (response: { data: WrittenPrescription }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: 'WrittenPrescriptionById', id }],
        }),
        updateWrittenPrescription: builder.mutation<void, UpdateWrittenPrescription>({
            query: ({ id, visit_id, ...body }) => ({ url: `/written_prescription/${id}/visit/${visit_id}`, method: 'PUT', body }),
            invalidatesTags: (result, error, { visit_id, id }) => [
                { type: 'WrittenPrescription', id: visit_id },
                { type: 'WrittenPrescriptionById', id },
                { type: 'UserForHospital', id: 'List' },
            ],
        }),
        finalizeAndPrintWrittenPrescription: builder.mutation<
            void,
            { visit_id: number; id: number; petName?: string; ownerLastName?: string | null }
        >({
            query: ({ id, visit_id, ownerLastName, petName }) => ({
                url: `/written_prescription/${id}/visit/${visit_id}/finalize_and_print`,
                method: 'POST',
                cache: 'no-cache',
                responseHandler: async (response) => {
                    if (response.status === 200) {
                        const fileName = `${petName}${ownerLastName ?? ''}_prescription_2-${id}.pdf`;

                        await downloadFile(response, fileName);
                    }

                    return response;
                },
            }),
            invalidatesTags: (result, error, { visit_id }) => [{ type: 'WrittenPrescription', id: visit_id }],
        }),
        printWrittenPrescription: builder.mutation<void, { visit_id: number; id: number; petName?: string; ownerLastName?: string | null }>(
            {
                query: ({ id, visit_id, ownerLastName, petName }) => ({
                    url: `/written_prescription/${id}/visit/${visit_id}/export`,
                    method: 'POST',
                    cache: 'no-cache',
                    responseHandler: async (response) => {
                        if (response.ok) {
                            const fileName = `${petName}${ownerLastName ?? ''}_prescription_2-${id}.pdf`;

                            await downloadFile(response, fileName);
                        }

                        return response;
                    },
                }),
                invalidatesTags: (result, error, { visit_id }) => [{ type: 'WrittenPrescription', id: visit_id }],
            },
        ),
        voidWrittenPrescription: builder.mutation<void, { visit_id: number; id: number }>({
            query: ({ id }) => ({ url: `/written_prescription/${id}/void`, method: 'POST' }),
            invalidatesTags: (result, error, { visit_id }) => [{ type: 'WrittenPrescription', id: visit_id }],
        }),
        deleteWrittenPrescription: builder.mutation<void, { visit_id: number; id: number }>({
            query: ({ id }) => ({ url: `/written_prescription/${id}`, method: 'DELETE' }),
            invalidatesTags: (result, error, { visit_id }) => [{ type: 'WrittenPrescription', id: visit_id }],
        }),
    }),
});

export const {
    useCreateWrittenPrescriptionMutation,
    useListWrittenPrescriptionsQuery,
    useGetWrittenPrescriptionsQuery,
    useUpdateWrittenPrescriptionMutation,
    useFinalizeAndPrintWrittenPrescriptionMutation,
    useVoidWrittenPrescriptionMutation,
    useDeleteWrittenPrescriptionMutation,
    usePrintWrittenPrescriptionMutation,
} = writtenPrescriptionApi;
