import { DatePicker as AntdDatePicker, DatePickerProps as AntdDatePickerProps } from 'antd';
import React from 'react';

export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';

/**
 * Custom Antd DatePicker
 * @param props AntdDatePickerProps
 * @returns Antd DatePicker with custom minuteStep and date format
 */
const DatePicker: React.FC<AntdDatePickerProps> = (props) => {
    const localProps = {
        format: DEFAULT_DATE_TIME_FORMAT,
        minuteStep: 15,
        showTime: true,
        showNow: false,
        inputReadOnly: true,
        ...props,
    };

    return <AntdDatePicker {...localProps} />;
};

export default DatePicker;
