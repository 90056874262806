import React, { useEffect } from 'react';

// Because of the weird interactions with the Drawer and Form we need to set the autofocus manaully here 
// Use only when needed its better to set autoFocus on component directly if possible
export const useSetFocus = (focusRef: React.RefObject<any>, autoFocus?: boolean) => {
    useEffect(() => {
        setTimeout(() => {
            if (autoFocus){
                focusRef?.current?.focus();
            }
        }, 150)
    }, [focusRef])

}
