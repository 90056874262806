import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Input, Skeleton, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import SkeletonTable, { SkeletonTableColumnsType } from 'components/SkeletonTable';
import { useDebounce } from 'hooks/useDebounce';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLazyGetAttributionSummaryQuery } from 'services/visitService';
import { drawerSize } from 'styles/drawer';
import { formatCurrency } from 'utils/formatFuncs';
import { AttributionSummaryVisit } from 'utils/types/billingTypes';
import useWindowDimensions from 'utils/window';
import './EarningsDrawer.css';

interface EarningsDrawerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TableVisit extends AttributionSummaryVisit {
    id: number;
    isAdjustment: boolean;
}

export const EarningsDrawer = ({ open, setOpen }: EarningsDrawerProps) => {
    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const { width } = useWindowDimensions();
    let history = useHistory();

    const stLarge = width < 768; // smaller than large
    const stMedium = width < 576; // smaller than medium

    // attributionSummaryRaw contains visits indexed by id and backend-computed metrics (case volume)
    const [getAttributionSummary, { data: attributionSummaryRaw, isFetching: isAttributionSummaryFetching }] =
        useLazyGetAttributionSummaryQuery();

    const attributionSummaryDebounced = useDebounce(
        () => {
            getAttributionSummary({ month: month + 1, year });
        },
        [month, year],
        500,
    );

    // attributionSummary contains the visits flat array and frontend-computed metrics (revenue, adjusted, doctor production)
    const attributionSummary = useMemo(() => {
        const visits: TableVisit[] = [];
        let visitsTotalRevenue = 0;
        let visitsTotalAdjusted = 0;
        !!attributionSummaryRaw &&
            Object.keys(attributionSummaryRaw?.visits).forEach((visit_id) => {
                const visit = attributionSummaryRaw.visits[Number(visit_id)];
                visits.push({ id: Number(visit_id), isAdjustment: visit.doctor_total < 0, ...visit });
                if (visit.doctor_total > 0) {
                    visitsTotalRevenue += visit.doctor_total;
                } else if (visit.doctor_total < 0) {
                    visitsTotalAdjusted += visit.doctor_total;
                }
            });
        return {
            visits: visits,
            totalRevenue: visitsTotalRevenue,
            totalAdjusted: visitsTotalAdjusted,
            totalProduction: visitsTotalRevenue + visitsTotalAdjusted,
        };
    }, [attributionSummaryRaw]);

    useEffect(() => {
        if (open) {
            attributionSummaryDebounced();
        }
    }, [month, year, open]);

    const filterInput = useRef<any>(null);

    function getAttributionSummaryColumns(isAdjustment: boolean): ColumnsType<TableVisit> {
        const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
            confirm();
        };

        const handleReset = (clearFilters: () => void) => {
            clearFilters();
        };

        return [
            {
                title: stMedium ? 'Close Date' : 'Visit Close Date',
                dataIndex: 'visit_invoice_finalized_at',
                key: 'visit_invoice_finalized_at',
                align: 'left',
                render(value) {
                    return stLarge ? moment.unix(value).format('MM/DD/YYYY') : moment.unix(value).format('MM/DD/YYYY hh:mm A');
                },
                sorter: (a, b) => a.visit_invoice_finalized_at - b.visit_invoice_finalized_at,
            },
            {
                title: 'Patient',
                dataIndex: 'pet_full_name',
                key: 'pet_full_name',
                align: 'left',
                sorter: (a, b) => a.pet_full_name.localeCompare(b.pet_full_name),
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={filterInput}
                            placeholder={'Search in filters'}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(confirm)}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                type='link'
                                size='small'
                                color='grey'
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>
                            <Button type='primary' onClick={() => handleSearch(confirm)} size='small' style={{ width: 90 }}>
                                Apply
                            </Button>
                        </Space>
                    </div>
                ),
                onFilter: (value: any, record: TableVisit) => record?.pet_full_name?.toLowerCase()?.includes((value as string)?.toLowerCase()),
                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => filterInput.current?.select(), 100);
                    }
                },
            },
            {
                title: 'Visit ID',
                dataIndex: 'id',
                key: 'id',
                align: 'left',
                responsive: ['md'],
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Invoice Total' + (isAdjustment ? ' (less tax)' : ''),
                dataIndex: 'invoice_total',
                key: 'invoice_total',
                align: 'right',
                render(value) {
                    return formatCurrency(value);
                },
                responsive: ['sm'],
                sorter: (a, b) => a.invoice_total - b.invoice_total,
            },
            {
                title: isAdjustment ? 'Adjustment' : 'Revenue',
                dataIndex: 'doctor_total',
                key: 'doctor_total',
                align: 'right',
                render(value) {
                    return formatCurrency(value);
                },
                sorter: (a, b) => a.doctor_total - b.doctor_total,
            },
            {
                title: '',
                align: 'center',
                render: (record) => {
                    return (
                        <>
                            {stMedium ? (
                                <Button type='link' style={{ color: 'rgba(0,0,0,0.85)' }} icon={<RightOutlined />} />
                            ) : (
                                <Button>
                                    <Link
                                        to={`/visit/${record.id}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOpen(false);
                                        }}
                                    >
                                        View Visit
                                    </Link>
                                </Button>
                            )}
                        </>
                    );
                },
            },
        ];
    }

    return (
        <Drawer
            title={
                <>
                    Production <InfoCircleOutlined />
                </>
            }
            className='veg-drawer earnings-drawer'
            placement='right'
            closable={true}
            width={width >= 800 ? drawerSize.extended : drawerSize.full}
            onClose={() => setOpen(false)}
            visible={open}
            key='earnings_drawer'
            destroyOnClose={true}
            mask={true}
            maskClosable={true}
            data-cy={'earningsDrawer'}
            data-pendo='earnings-drawer'
        >
            <div className='earnings-top'>
                <MonthPicker month={month} setMonth={setMonth} year={year} setYear={setYear} loading={isAttributionSummaryFetching} />
                <span className='metrics-container'>
                    <EarningsMetric
                        label='Case Volume'
                        loading={isAttributionSummaryFetching}
                        value={attributionSummaryRaw?.case_volume ?? 0}
                    />
                    <EarningsMetric
                        label='Revenue'
                        metricClass='revenue'
                        loading={isAttributionSummaryFetching}
                        value={formatCurrency(attributionSummary.totalRevenue)}
                    />
                    <EarningsMetric
                        label='Adjusted'
                        metricClass='adjusted'
                        loading={isAttributionSummaryFetching}
                        value={formatCurrency(attributionSummary.totalAdjusted)}
                    />
                    <EarningsMetric
                        label='Total Production'
                        loading={isAttributionSummaryFetching}
                        value={formatCurrency(attributionSummary.totalProduction)}
                    />
                </span>
            </div>

            <h1 className='veg-headline-2' style={{ marginTop: '10px' }}>
                Revenue
            </h1>
            <SkeletonTable
                tableLayout='fixed'
                size='small'
                loading={isAttributionSummaryFetching}
                active={true}
                rowCount={1}
                columns={getAttributionSummaryColumns(false) as SkeletonTableColumnsType[]}
            >
                <Table
                    size='small'
                    rowKey='id'
                    columns={getAttributionSummaryColumns(false)}
                    dataSource={attributionSummary.visits.filter((visit) => !visit.isAdjustment)}
                    tableLayout='fixed'
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                let selection = window.getSelection()?.toString();
                                if (!selection) {
                                    history.push(`/visit/${record.id}`);
                                    setOpen(false);
                                }
                            },
                        };
                    }}
                />
            </SkeletonTable>

            <h1 className='veg-headline-2' style={{ marginTop: '10px' }}>
                Adjustments
            </h1>
            <SkeletonTable
                tableLayout='fixed'
                size='small'
                loading={isAttributionSummaryFetching}
                active={true}
                rowCount={1}
                columns={getAttributionSummaryColumns(true) as SkeletonTableColumnsType[]}
            >
                <Table
                    size='small'
                    rowKey='id'
                    columns={getAttributionSummaryColumns(true)}
                    dataSource={attributionSummary.visits.filter((visit) => visit.isAdjustment)}
                    tableLayout='fixed'
                ></Table>
            </SkeletonTable>
        </Drawer>
    );
};

interface MonthPickerProps {
    month: number;
    setMonth: React.Dispatch<React.SetStateAction<number>>;
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>;
    loading: boolean;
}

export const MonthPicker = ({ month, setMonth, year, setYear, loading }: MonthPickerProps) => {
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);

    const currentTime = useMemo(() => moment({ month, year }), [month, year]);

    const subtractOneMonth = () => {
        const newDate = moment({ month, year }).subtract('1', 'month');
        setMonth(newDate.month());
        setYear(newDate.year());
    };
    const addOneMonth = () => {
        const newDate = moment({ month, year }).add('1', 'month');
        setMonth(newDate.month());
        setYear(newDate.year());
    };

    return (
        <>
            <span>
                <Button
                    className='left-arrow-btn'
                    icon={<ArrowLeftOutlined />}
                    onClick={subtractOneMonth}
                    disabled={moment({ month, year }).isBefore(moment().subtract('12', 'month'))}
                />
                <DatePicker
                    picker='month'
                    open={isDatePickerOpen}
                    className='hidden-picker'
                    onOpenChange={(open) => setDatePickerOpen(open)}
                    value={currentTime}
                    onChange={(value) => {
                        if (!!value) {
                            setMonth(value.month());
                            setYear(value.year());
                        }
                    }}
                    disabledDate={(current) =>
                        current && (current > moment().endOf('month') || current.isBefore(moment().subtract('12', 'month')))
                    }
                />
                <Button className='month-btn' onClick={() => setDatePickerOpen(!isDatePickerOpen)}>
                    {loading ? (
                        <Skeleton.Button active shape='square' size='small' style={{ maxWidth: '24px' }} />
                    ) : (
                        <span>{currentTime.format('MMM')}</span>
                    )}
                </Button>
                <Button
                    className='right-arrow-btn'
                    icon={<ArrowRightOutlined />}
                    onClick={addOneMonth}
                    disabled={moment({ month, year }).isSameOrAfter(moment().startOf('month'))}
                />
            </span>
        </>
    );
};

interface EarningsMetricValueProps {
    label: string;
    metricClass?: string;
    loading: boolean;
    value?: number | string;
}

export const EarningsMetric = ({ label, metricClass, loading, value }: EarningsMetricValueProps) => {
    return (
        <div className={metricClass ? `earnings-metric earnings-metric-${metricClass}` : 'earnings-metric'}>
            <span className='earnings-metric-value'>
                {loading ? <Skeleton.Button active shape='square' style={{ maxHeight: '28px' }} /> : <span>{value}</span>}
            </span>
            <span className='earnings-metric-indicator'>{label}</span>
        </div>
    );
};
