import React, { useRef, useState } from 'react';
import { Form, FormItemProps, InputNumber } from 'antd';
import { formatPhoneNumber } from 'utils/formatFuncs';
import './phonenumberfield.css';
import { useSetFocus } from 'hooks/useSetFocus';
import { PhoneOutlined } from '@ant-design/icons';

interface PhoneNumberFieldProps {
    autoFocus?: boolean;
    formItemProps: FormItemProps;
    onChange: (value: string | null) => void;
    disabled?: boolean;
    dataCy?: string;
}
const parsePhoneNumber = (phoneNumber: string | undefined): string => (phoneNumber || '').replace(/[^\d]/g, '');

export const PhoneNumberField = React.memo((props: PhoneNumberFieldProps) => {
    const { disabled, formItemProps, onChange, autoFocus } = props;
    const [localValue, setLocalValue] = useState<string>();

    const ref = useRef<any>(null);

    useSetFocus(ref, autoFocus);

    return (
        <Form.Item {...formItemProps} data-cy={props.dataCy} className='phone-number-field-component-form-item' extra={<PhoneOutlined />}>
            <InputNumber // we are using InputNumber instead of Input since it has an formatter and parser
                ref={ref}
                autoFocus={autoFocus}
                onChange={(value) => {
                    // required to keep the type string
                    if (value) {
                        onChange(`${value}`);
                    } else {
                        onChange(null);
                    }
                }}
                value={localValue}
                className='phone-number-field-component'
                disabled={disabled}
                type='phone'
                onKeyDown={(e) => {
                    // in order to prevent hanging extra keys
                    const { value } = e.currentTarget;
                    if (value) {
                        const phoneNumber = formatPhoneNumber(value);
                        setLocalValue(phoneNumber);
                        onChange(parsePhoneNumber(phoneNumber));
                    } else {
                        setLocalValue(undefined);
                        onChange(null);
                    }
                }}
                formatter={formatPhoneNumber}
                parser={parsePhoneNumber}
            ></InputNumber>
        </Form.Item>
    );
});
