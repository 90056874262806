import { Dropdown, Menu, PopconfirmProps, Popconfirm, MenuItemProps } from 'antd';
import React, { ReactElement, useState } from 'react';
import "./ConfirmDropdown.css"


interface ConfirmDropdownProps {
    items: ConfirmDropdownItems[],
}

interface BaseMenuItem {
    text: string | ReactElement,
    menuItemProps?: Omit<MenuItemProps, "onClick">,
}

export interface ConfirmMenuItem extends BaseMenuItem {
    confirmProps: Omit<PopconfirmProps, "onVisibleChange">,
}

export interface BasicMenuItem extends BaseMenuItem {
    onClick: () => void,
}

interface MenuDivider {
    menuDivider: boolean
}

export const MENU_DIVIDER: MenuDivider = {menuDivider: true}


export type ConfirmDropdownItems = MenuDivider | BasicMenuItem | ConfirmMenuItem;

const isInstanceOfMenuDivider = (
    item: any
): item is MenuDivider =>
    !!item &&
    !!(item as MenuDivider).menuDivider;

const isInstanceOfBasicMenuItem = (
    item: any
): item is BasicMenuItem =>
    !!item &&
    !!(item as BasicMenuItem).onClick;

const isInstanceOfConfirmMenuItem = (
    item: any
): item is ConfirmMenuItem =>
    !!item &&
    !!(item as ConfirmMenuItem).confirmProps;


        
export const ConfirmDropDown: React.FC<ConfirmDropdownProps> = ({
    items, children
}) => {

    const [keepDropdownOpen, setKeepDropdownOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropdownHandleOpenChange = (flag: boolean) => {
        setDropdownOpen(flag || keepDropdownOpen);
    };

    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            setKeepDropdownOpen(true);
        } else {
            setKeepDropdownOpen(false);
            setDropdownOpen(false);
        }
    }

    const menuItems = items.map(
        (item) => {
            if (isInstanceOfMenuDivider(item)) {
                return <Menu.Divider />
            }

            if (isInstanceOfBasicMenuItem(item)) {
                return (
                    <Menu.Item 
                    {...item.menuItemProps}
                    onClick={() => {
                        setDropdownOpen(false);
                        (item as BasicMenuItem).onClick();
                    }}>
                        {item.text}
                    </Menu.Item>
                )
            }

            if (isInstanceOfConfirmMenuItem(item)) {
                return (
                    <Menu.Item {...item.menuItemProps} className='confirm-menu-item'>
                        <Popconfirm {...item.confirmProps} onVisibleChange={onVisibleChange}>
                            <div className='ant-dropdown-menu-item'>
                                {item.text}
                            </div>
                        </Popconfirm>
                    </Menu.Item>
                )
            }
        }
    )

    const overlay = <Menu>{menuItems}</Menu>;

    return (
        <Dropdown
            // TODO: remove deprecated overlay
            overlay={overlay}
            open={dropdownOpen}
            onOpenChange={dropdownHandleOpenChange}
            trigger={window.innerWidth > 1200 ? ['hover', 'click'] : ['click']}
        >
            {children}
        </Dropdown>
    )
}
