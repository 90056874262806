import { CheckOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Tooltip } from 'antd';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';
import React, { useEffect, useState } from 'react';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import { PackageTaskInstruction } from 'utils/types/InstructionOrderTypes';
import OrderFrequency from 'views/visit/TreatmentSheet/OrderFrequency';
import { PackageFormInstance } from '.';
import { HiddenInput } from '../fields/HiddenInput';
import EditLineButton from './EditLineButton';
import FrequencyFormItem from './FrequencyFormItem';
import InstructionFormItem from './InstructionFormItem';
import PackageItemTitleContainer from './PackageItemTitleContainer';
import StartEndTimeFormItems from './StartEndTimeFormItems';

interface TasksFormProps {
    task: PackageTaskInstruction;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    fromEstimate?: boolean;
    durationHours?: number;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const TasksForm: React.FC<TasksFormProps> = ({
    form,
    index,
    task,
    fromEstimate,
    durationHours,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [hasError, setHasError] = useState(false);
    const lineId = `T-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const tasksFields = [
        ['tasks', index, 'frequency'],
        ['tasks', index, 'end_time'],
        ['tasks', index, 'start_time'],
        ['tasks', index, 'instruction'],
    ];
    const tasksErrorArray = form.getFieldsError(tasksFields);

    const [isSerialCheaper, setIsSerialCheaper] = useState(false);

    const setSerial = (changes: { [key in string]: string }) => {
        const fieldsValue = form.getFieldsValue();
        const taskFields = {
            ...fieldsValue.tasks[index],
            ...changes,
        };

        let startTime, endTime;
        if (fromEstimate) {
            startTime = 0;
            endTime = (durationHours ?? 0) * 3600;
        } else {
            startTime = (taskFields?.start_time ?? fieldsValue.start_time)?.unix();
            endTime = (taskFields?.end_time ?? fieldsValue.end_time)?.unix();
        }

        const serial = checkIfSerialCheaper(task, taskFields.frequency, startTime, endTime);
        const unit_cost_cents = serial ? task.price_cents_serial ?? 0 : task.cents ?? 0;
        setIsSerialCheaper(serial);
        form.setFieldsValue({
            ...fieldsValue,
            tasks: fieldsValue.tasks.map((diag: any, indx: number) => {
                return indx === index ? { ...diag, ...changes, serial, unit_cost_cents } : diag;
            }),
        });
    };

    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        return (
            prev.tasks[index].checked !== curr.tasks[index].checked ||
            prev.tasks[index].frequency !== curr.tasks[index].frequency ||
            prev.tasks[index].start_time !== curr.tasks[index].start_time ||
            prev.tasks[index].end_time !== curr.tasks[index].end_time
        );
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['tasks', index, 'frequency'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (tasksErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, tasksErrorArray]);

    useEffect(() => {
        setSerial({});
    }, []);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const checked = getFieldValue(['tasks', index, 'checked']) ?? (task.required ? task.required : task.toggled) ?? true;
                const disabledClass = !checked ? 'disabled-line' : '';
                const initialError = !(getFieldValue(['tasks', index, 'frequency']) ?? task.frequency);
                const serialName = isSerialCheaper ? `${task.name} - Serial ${task.serial_hours} Hours` : task.name;

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${disabledClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['tasks', index, 'id']} initialValue={task.task_id} />
                            <HiddenInput fieldName={['tasks', index, 'name']} initialValue={task.name} />
                            <HiddenInput fieldName={['tasks', index, 'unit_cost_cents']} initialValue={task.cents} />
                            <HiddenInput fieldName={['tasks', index, 'required']} initialValue={task.required} />
                            <HiddenInput fieldName={['tasks', index, 'serial']} initialValue={isSerialCheaper} />
                            <HiddenInput fieldName={['tasks', index, 'serial_hours']} initialValue={task.serial_hours} />
                            <HiddenInput fieldName={['tasks', index, 'no_charge']} initialValue={task.no_charge} />
                        </div>

                        <Form.Item
                            className='grid-table__sticky-column'
                            initialValue={task.required ? task.required : task.toggled}
                            valuePropName='checked'
                            name={['tasks', index, 'checked']}
                        >
                            {task.required ? (
                                <Tooltip title='Required order. Cannot be removed.'>
                                    <CheckOutlined />
                                </Tooltip>
                            ) : (
                                <Checkbox />
                            )}
                        </Form.Item>

                        <div className='grid-table__sticky-column'>
                            <PackageItemTitleContainer checked={checked} no_charge={task.no_charge}>
                                <Tooltip title={serialName.length > 45 ? serialName : null}>
                                    <span className='treatment-sheet-side-column__title'>{serialName}</span>
                                </Tooltip>
                            </PackageItemTitleContainer>

                            {!fromEstimate && checked && (
                                <div className='diagnostic-data'>
                                    <OrderFrequency>{getFieldValue(['tasks', index, 'frequency']) ?? task.frequency}</OrderFrequency>
                                </div>
                            )}
                        </div>

                        {checked ? (
                            <>
                                <div className='empty-element' />
                                <div className='empty-element' />

                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Frequency
                                </div>
                                <FrequencyFormItem
                                    formFieldName={['tasks', index, 'frequency']}
                                    initialValue={task.frequency}
                                    isEditing={isEditing}
                                    formFieldValue={form.getFieldValue(['tasks', index, 'frequency']) ?? task.frequency}
                                    onFormChange={(changes) => {
                                        setSerial(changes);
                                    }}
                                />

                                {!fromEstimate && (
                                    <>
                                        <StartEndTimeFormItems
                                            endTimeFormFieldName={['tasks', index, 'end_time']}
                                            endTimeFormFieldValue={form.getFieldValue(['tasks', index, 'end_time'])}
                                            isEditing={isEditing}
                                            startTimeFormFieldName={['tasks', index, 'start_time']}
                                            startTimeFormFieldValue={form.getFieldValue(['tasks', index, 'start_time'])}
                                        />

                                        <InstructionFormItem
                                            formFieldName={['tasks', index, 'instruction']}
                                            initialValue={null}
                                            isEditing={isEditing}
                                            formFieldValue={form.getFieldValue(['tasks', index, 'instruction'])}
                                        />
                                    </>
                                )}
                                {isEditing && isSerialCheaper && (
                                    <div
                                        className={`serial-alert-column ${
                                            !isEditing || !isSerialCheaper ? 'serial-alert-column--hide' : ''
                                        }`}
                                    >
                                        <AlertSerialPrice serialHours={task.serial_hours ?? 0} />
                                    </div>
                                )}

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />
                            </>
                        ) : (
                            <div className='disabled-line__form-inputs' />
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default TasksForm;
