import { Button, Image } from 'antd';
import { useQuery } from 'hooks';
import { useState } from 'react';
import SSOStyles from './SSO.module.css';
import { AUTH_HOST } from 'utils/constants';
import LandingPageLogo from '../../../assets/img/logo_landing_page.svg';

interface SSOPageProps { }

export const SSOPage: React.FC<SSOPageProps> = () => {

    const searchParams = useQuery();
    const errorMessage = searchParams.get('err');
    let [loading, setLoading] = useState(false);

    const redirectToSSO = () => {
        setLoading(!loading);
        window.location.href = `${AUTH_HOST}/sso-login?${searchParams.toString()}`;
    };

    return (
        <div className={SSOStyles.loginWrapper}>
            {!!errorMessage && <p style={{ 'color': 'red' }}>{errorMessage}</p>}
            <Image src={LandingPageLogo} data-cy="dogbyteLogo" wrapperStyle={{width: "100%", maxWidth: "300px"}} preview={false} alt=""/>
            <Button type='primary' data-cy="oktaLogin" style={{ 'backgroundColor': '#00297a' }} onClick={redirectToSSO} loading={errorMessage === null && loading}>
                Log in with Okta
            </Button>
            <Button type='link' href="https://veg.okta.com" target="_self">
                Back to dashboard
            </Button>
        </div>
    );
};
