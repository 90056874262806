import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { FormName } from '../../../utils/constants';
import { NotesDrawerRef } from 'components/NotesDrawer';

interface AddNotesProps {
    isFinalizedVisit?: boolean;
    showOnlyIcon?: boolean;
    openNoteDrawer?: NotesDrawerRef['openNotesDrawer'];
}

export const AddNoteButton = ({ isFinalizedVisit, showOnlyIcon, openNoteDrawer }: AddNotesProps) => {    

    const noteItems = isFinalizedVisit
        ? [
              {
                  title: 'Addendum Note',
                  formType: FormName.addendum_note,
              },
          ]
        : [
              {
                  title: 'Progress/Soap Note',
                  formType: FormName.prog_note,
              },
              {
                  title: 'Recommendation Note',
                  formType: FormName.recommendation_note,
              },
              {
                  title: 'Procedure Note',
                  formType: FormName.procedure_note,
              },
              {
                  title: 'Communication Note',
                  formType: FormName.communication_note,
              },
              {
                  title: 'Physical Exam Note',
                  formType: FormName.physical_exam,
              },
          ];

    return (
        <Dropdown
            trigger={showOnlyIcon ? ['click'] : ['hover']}
            placement='bottomRight'
            overlay={
                <Menu>
                    {noteItems.map((item, index) => {
                        return (
                            <Menu.Item
                                key={index}
                                data-cy={'latestNoteMenuItem'}
                                onClick={() => {
                                    if (openNoteDrawer) {
                                        openNoteDrawer(item.formType);
                                    }
                                }}
                            >
                                {item.title}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            }
        >
            {showOnlyIcon ? (
                <Button data-cy='addLatestNoteIconButton' icon={<PlusOutlined />}></Button>
            ) : (
                <Button data-cy='addLatestNoteButton'>
                    Add Note <DownOutlined />
                </Button>
            )}
        </Dropdown>
    );
};
