import { Col, Row, Button } from 'antd';
import { PageHeader } from 'components/lib';
import classes from './mobile.module.css';

interface AppInstallProps {}

const Mobile: React.FC<AppInstallProps> = (props) => {

	const apkUrl = "https://notouching.s3.amazonaws.com/download/veg.apk"; //this apk is for the dev server

	return (
		<Row className={classes.container}>
			<Col span={24}>
				<h1 className={classes.header}>Mobile App</h1>
				<h2 className={classes.desc}>Available for Android devices</h2>
				<p className={classes.comingSoon}>(iOS coming soon)</p>
				<Button type="primary" size={"large"} href={apkUrl} className={classes.button}>Android</Button>
				<Button type="primary" size={"large"} disabled href="#" className={classes.button}>iOS</Button>
				
				
			</Col>
		</Row>
	);
};

export default Mobile;
